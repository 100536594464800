import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  SearchOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { 
  reportVipPlayer,
 } from "../../../config/networkConfig";
import { Table } from "ant-table-extensions";
import {
  PageHeader,
  Button,
  Breadcrumb,
  Select,
  Input,
} from "antd";
import { isMobile } from "react-device-detect";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { settings } from "../../../config/appConfig";
import Highlighter from "react-highlight-words";
import { FormatDataMobile } from "../../../library/numbers";
import moment from "moment";
import { reportVipPlayerVw } from "../../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";

export default function ReportVipPlayer() {

  const { Option } = Select;
  const { userId , role, username, compCode } = useSelector((state) => state.login);
  const [report, setReport] = useState("");
  const [allReport, setAllReport] = useState("");
  const [todayDate, setTodayDate] = useState("");
  const [tableLoading ,          setTableLoading] = useState(false);
  const [trxDateFrom, setTrxDateFrom] = useState("");
  const [trxDateTo, setTrxDateTo] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchMobile,setSearchMobile] = useState("");

  useEffect(() => {
    // if (reportVipPlayerVw(role, username)){
    reportallReportList();
    // }
    //reportShareDetailList();
    // eslint-disable-next-line
  }, []);

  useEffect(()=> {
    setTrxDateFrom(trxDateFrom);
    setTrxDateTo(trxDateTo);
  },[trxDateFrom,trxDateTo]);

  // window.setTimeout( function() {
  //   window.location.reload();
  // }, 600000);

  const onClickExportBtn = ()=> {

    if (allReport.length === 0 ) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }

    document.getElementById("export-button-1").click();
  }

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
        
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });

  const reportallReportList = () => {
    setTableLoading(true);
    var count = 1;

    reportVipPlayer({data:null})
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
        const allReport = Object.keys(json.data.allFullData).length
        ? json.data.allFullData.map((data) => {
            setTableLoading(false);
            return {
              key                     : count++,
              Mobile                  : data.Mobile,
              RegisterDate            : data.RegisterDate,
              DepositCount            : data.DepositCount,
              DepositAmount           : FormatDataMobile(data.DepositAmount),
              AvgDepAmt               : FormatDataMobile(data.AvgDepAmt),
              MaxDepAmt               : FormatDataMobile(data.MaxDepAmt),
              LastDepositDate         : data.LastDepositDate,
              WithdrawCount           : data.WithdrawCount,
              WithdrawAmt             : FormatDataMobile(data.WithdrawAmt),
              LastWithdrawDate        : data.LastWithdrawDate,
              RegDiff                 : data.RegDiff,
              DepDiff                 : data.DepDiff,
              WthDiff                 : data.WthDiff,
              Remark                  : data.Remark,
            };
            })
            : [];

            let summaryDepositCount = 0, summaryDepositAmount = 0, summaryWithdrawCount = 0, summaryWithdrawAmt = 0;
            let reportVipPlayerSummary = [...allReport];

            reportVipPlayerSummary.forEach(({ DepositCount, DepositAmount, WithdrawCount, WithdrawAmt
            }) => {
              summaryDepositCount             += Number(DepositCount);
              summaryDepositAmount            += Number(FormatDataMobile(DepositAmount));
              summaryWithdrawCount            += Number(WithdrawCount);
              summaryWithdrawAmt              += Number(FormatDataMobile(WithdrawAmt));
            });

            reportVipPlayerSummary= [
              {
                key                     : "Summary",
                Mobile                  : "",
                RegisterDate            : "",
                DepositCount            : summaryDepositCount,
                DepositAmount           : FormatDataMobile(summaryDepositAmount),
                MaxDepAmt               : "",
                LastDepositDate         : "", 
                WithdrawCount           : summaryWithdrawCount,
                WithdrawAmt             : FormatDataMobile(summaryWithdrawAmt),
                LastWithdrawDate        : "",
                RegDiff                 : "",
                DepDiff                 : "",
                WthDiff                 : "",
                Remark                  : "",
              },
                ...reportVipPlayerSummary,
            ];

            // =============================================

            let todayDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
            setTodayDate(todayDate);

            setAllReport(reportVipPlayerSummary);

          if (allReport.length == 0 ) {
            alertData.title = "Error";
            alertData.msg = "No data !";
            sharedErrorAlert(alertData);
            setTableLoading(false);
            return;
          }
        } 
        else {
          alertData.title = "Error";
          alertData.msg = "Connection not stable, please try again !";
          sharedErrorAlert(alertData);
        }        
      });

      
  };

  const columns = [
    {
        align: "center",
        title: "No",
        dataIndex: "key",
        width: 100,
        ellipsis: true,
        fixed:"left",
        render:function(record) {
          return record
        }
    },
    {
        align: "center",
        title: "Mobile",
        dataIndex: "Mobile",
        width: 130,
        ellipsis: true,
        style: [{"word-wrap":"break-word"}],
        ...getColumnSearchProps("Mobile"),
    },
    {
        align: "center",
        title: "Register Date",
        dataIndex: "RegisterDate",
        width: 160,
        style: [{"word-wrap":"break-word"}],
        render:function(record, record2) {
          return record2.key == "Summary" ? "" : <p style={{marginTop: "10px"}}>{record}<br/>({record2.RegDiff} days ago)</p>
        }
    },
    {
      align: "center",
      title: "Register days ago",
      dataIndex: "RegDiff",
      width: 0,
      ellipsis: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px"}}>({record} days ago)</p>
      }
    },
    {
      align: "center",
      title: "Deposit Count",
      dataIndex: "DepositCount",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return record != 'N/A' ? record : 'N/A';
      }
      
    },
    {
      align: "center",
      title: "Total Deposit Amount",
      dataIndex: "DepositAmount",
      width: 150,
      render:function(record) {
        return <p style={{color:"blue", marginTop: "10px", fontWeight: 'bold'}}>{FormatDataMobile(record)}</p>
      }
    },
    {
      align: "center",
      title: "Average Deposit Amount",
      dataIndex: "AvgDepAmt",
      width: 130,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return record2.key == "Summary" ? "" : <p style={{marginTop: "10px"}}>{FormatDataMobile(record)}</p>
      }
    },
    {
      align: "center",
      title: "Max Deposit Amount",
      dataIndex: "MaxDepAmt",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return record2.key == "Summary" ? "" : record === "Summary" ? <b></b>: FormatDataMobile(record)
      }
    },
    {
      align: "center",
      title: "Last Deposited On",
      dataIndex: "LastDepositDate",
      width: 160,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return record2.key == "Summary" ? "" : <p style={{marginTop: "10px"}}>{record}<br/>({record2.DepDiff} days ago)</p>
      }
    },
    {
      align: "center",
      title: "Last Deposited days ago",
      dataIndex: "DepDiff",
      width: 0,
      ellipsis: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px"}}>({record} days ago)</p>
      }
    },
    {
      align: "center",
      title: "Withdraw Count",
      dataIndex: "WithdrawCount",
      width: 100,
      style: [{"word-wrap":"break-word"}],
    },
    {
      align: "center",
      title: "Total Withdraw Amount",
      dataIndex: "WithdrawAmt",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return <p style={{color:"blue", marginTop: "10px", fontWeight: 'bold'}}>{FormatDataMobile(record)}</p>
      }
    },
    {
      align: "center",
      title: "Last Withdrawal On",
      dataIndex: "LastWithdrawDate",
      width: 160,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return record2.key == "Summary" ? "" : <p style={{marginTop: "10px"}}>{record}<br/>({record2.WthDiff} days ago)</p>
      }
    },
    {
      align: "center",
      title: "Last Withdrawal days ago",
      dataIndex: "WthDiff",
      width: 0,
      ellipsis: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px"}}>({record} days ago)</p>
      }
    },
    {
        align: "center",
        title: "Remark",
        dataIndex: "Remark",
        width: 130,
        style: [{"word-wrap":"break-word"}],
        render:function(record, record2) {
          return <p style={{color:"red", marginTop: "10px"}}>{record}</p>
        }
    },

  ];

  const routes = [
    {
      path: "/",
      breadcrumbName: "Vip Player Report",
    },
    {
      //   path: "first",
      breadcrumbName: "Report",
    },
    {
      path: "vipPlayerReport",
      breadcrumbName: "Vip Player Report",
    },
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 2;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      // display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
      display: "inline",
    },
    refreshBtn: {
      marginLeft: 10,
    },
  };

  return (
    <div>
      {
        reportVipPlayerVw(role,username,compCode)?
        
        <div className="eachPageContent">
        {isMobile ? (
          
          <div>
            <PageHeader ghost={false}>
              <div>
                <Breadcrumb
                  separator=">"
                  itemRender={itemRender}
                  routes={routes}
                />
                <div style={styles.tableContainer}>
                  <Table
                    columns={columns}
                    dataSource={report}
                    className="cs_dailyTrxnStat"
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["5", "10", "20"],
                      style: styles.paginationStyle,
                    }}
                  />
                </div>
              </div>
            </PageHeader>
          </div>
        ) : (
          <div>
            <PageHeader
              ghost={false}
              // title="Vip Player Report"
            >
              <div style={styles.pageHeaderContainer}>
                <Button
                  style={styles.refreshBtn}
                  type="primary"
                  onClick={() => {
                    // reportList();
                    window.location.reload();
                  }}
                >
                  Refresh Page
                </Button>
                <Button 
                      type={"primary"} 
                      style={styles.refreshBtn} 
                      icon={<FileExcelOutlined />}
                      onClick={() => {
                          onClickExportBtn();
                      }}
                      
                  >
                    Export to Excel
                </Button>
              </div>
            </PageHeader>
            <div style={styles.tableContainer}>
              <Table
                loading={tableLoading} 
                columns={columns}
                dataSource={allReport}
                className="reportVipPlayer"
                scroll={{ x: 100, y: null }}
                exportable={true}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: settings.pageSizeOptions,
                  style: styles.paginationStyle,
                }}
                exportableProps={{
                  // feedFields,
                  fileName: "VipPlayerReport_"+todayDate,
                  btnProps: {
                    type: "primary",
                    icon: <FileExcelOutlined />,
                    id:"export-button-1",
                    children: <span>Export to Excel</span>,
                    style: {display:"none"}
                  },
                }}
              />
              <br></br>
              <p><b>REMARK :</b></p>
              <p>(1) VIP Players with Deposit &#8250;= 1000</p>
              <p>(2) Highlighted Amount is Deposit &#8250;= 5000 OR Withdraw &#8249;= -5000 (THB)</p>
              <p>(3) <span style={{color:"red",}}>Remark - No Activity from Customer, AFTER (5) days. (CS need contact customers.)</span></p>
              <p>(4) Currency in THB</p>
            </div>
          </div>
        )}
        </div>:
        <div style={{textAlign:"center",color:"red",fontSize:"150%"}}><h1 style={{color:"red"}}>Not Allowed to Access This Page</h1></div>
      }
    </div>
      
  );
}