import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Form,
  Button,
  Modal,
  Badge,
  PageHeader,
  message,
  Select,
  Spin,
  DatePicker
} from "antd";

import {
  EditOutlined,
  SearchOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
// const { Text } = Typography;
import {
  movieList,
  getMovieById,
  movieNew,
  movieDelete,
} from "../../config/networkConfig";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { FormatDateHHMM } from "../../library/dates";
import { settings } from "../../config/appConfig";
import moment from "moment";
const dateFormat = "DD MMMM, YYYY";

export default function Movie() {
  const [form] = Form.useForm();
  const modalForm = useRef();
  const [movieData, setMovieData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [tableLoadings, setTableLoadings] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const { Option } = Select;

  // movie 
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const [titleEn, setTitleEn] = useState("");
  const [titleTh, setTitleTh] = useState("");
  const [typeEn, setTypeEn] = useState("Action");
  const [typeTh, setTypeTh] = useState("หนังบู๊");
  const [subTypeEn, setSubTypeEn] = useState("Action");
  const [subTypeTh, setSubTypeTh] = useState("หนังบู๊");
  const [releaseDate, setReleaseDate] = useState("");
  const [imdb,setImdb] = useState("");
  const [rottenTomato,setRottenTomato] = useState("");
  const [metacritic, setMetacritic] = useState("");
  const [descEn, setDescEn] = useState("");
  const [descTh, setDescTh] = useState("");
  const [duration,setDuration] = useState("");
  const [boxOffice,setBoxOffice] = useState("");
  const [director, setDirector] = useState("");
  const [budget, setBudget] = useState("");
  const [cover,setCover] = useState("");
  const [contentURL, setContentURL] = useState("");
  const [status, setStatus] = useState("Enabled");
  const [option, setOption] = useState("new");
  const [google,setGoogle] = useState("");
  const [movieId, setMovieId] = useState();
  const [deleteVisible, setDeleteVisible] = useState(false);
  const refReleaseDate = useRef(null)
  useEffect(() => {
    moviesList();
  }, []);



  const clearAllFields = () => {

    // movie
    setId()
    setTitleEn("")
    setTitleTh("")
    setTypeEn("Action")
    setTypeTh("หนังบู๊")
    setSubTypeEn("Action")
    setSubTypeTh("หนังบู๊")
    setReleaseDate("")
    setImdb("")
    setRottenTomato("")
    setMetacritic("")
    setDescEn("")
    setDescTh("")
    setDuration("")
    setBoxOffice("")
    setDirector("")
    setBudget("")
    setCover("")
    setContentURL("")
    setStatus("Enabled")
    setOption("new")
    setGoogle("")
  }

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  };

  const onCreateMovieRecord = () =>{
    return(
      <Form 
        form={form}
        ref={modalForm}
        {...layout}
        name="createMovieRecord"
        initialValues={{remember: false}}
      >
        <Form.Item label="Title (EN)">
        <Input 
            allowClear 
            value={titleEn}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Movie Title (EN)"
            // rules={[{required:true, message:'Please input the title of the movie'}]}
            onChange = {(e) => {
              setTitleEn(e.target.value);
            }}
          />
          {/* <br/>
          <span>Maximum 30 characters</span> */}
        </Form.Item>
        <Form.Item label="Title (TH)" label="Title (TH)" name="title-th" rules={[{required:true, message:'Please input the title of the movie in Thai'}]} >
        <Input 
            allowClear 
            value={titleTh}
            style={{width:"470px"}}
            placeholder="Movie Title (TH)" 
            onChange = {(e) => {
              setTitleTh(e.target.value);
            }}
          />
          {/* <br/>
          <span>Maximum 30 characters</span> */}
        </Form.Item>
        <Form.Item label="Type (EN)">
            <Select value={typeEn}
              defaultValue="Action"
              onChange={(e)=>{setTypeEn(e)}}>
              <Option value="Action">Action</Option>
              <Option value="Horror">Horror</Option>
              <Option value="Fantasy">Fantasy</Option>
              <Option value="Thriller">Thriller</Option>
              <Option value="Drama">Drama</Option>
              <Option value="Adventure">Adventure</Option>
              <Option value="Romance">Romance</Option>
              <Option value="Sci-fi">Sci-fi</Option>
            </Select>
          <br/>
          <span>Default Type will be set to <b>Action</b></span>
        </Form.Item>
        <Form.Item label="Type (TH)">
            <Select value={typeTh}
                defaultValue="หนังบู๊"
                onChange={(e)=>{setTypeTh(e)}}>
                <Option value="หนังบู๊">หนังบู๊</Option>
                <Option value="สยองขวัญ">สยองขวัญ</Option>
                <Option value="แฟนตาซี">แฟนตาซี</Option>
                <Option value="ระทึกขวัญ">ระทึกขวัญ</Option>
                <Option value="ละคร">ละคร</Option>
                <Option value="การผจญภัย">การผจญภัย</Option>
                <Option value="โรแมนติก">โรแมนติก</Option>
                <Option value="ไซไฟ">ไซไฟ</Option>
            </Select>
          <br/>
          <span>Default Type will be set to <b>หนังบู๊</b></span>
        </Form.Item>
        <Form.Item label="SubType (EN)">
            <Select value={subTypeEn}
              defaultValue="Action"
              onChange={(e)=>{setSubTypeEn(e)}}>
              <Option value="Action">Action</Option>
              <Option value="Comedy">Comedy</Option>
              <Option value="Adventure">Adventure</Option>
              <Option value="Thriller">Thriller</Option>
              <Option value="Sci-fi">Sci-fi</Option>
              <Option value="Drama">Drama</Option>
              <Option value="Disaster">Disaster</Option>
              <Option value="Crime">Crime</Option>
              <Option value="Martial Arts">Martial Arts</Option>
            </Select>
          <br/>
          <span>Default SubType will be set to <b>Action</b></span>
        </Form.Item>
        <Form.Item label="SubType (TH)">
            <Select value={subTypeTh}
                defaultValue="หนังบู๊"
                onChange={(e)=>{setSubTypeTh(e)}}>
                <Option value="หนังบู๊">หนังบู๊</Option>
                <Option value="ตลก">ตลก</Option>
                <Option value="การผจญภัย">การผจญภัย</Option>
                <Option value="ระทึกขวัญ">ระทึกขวัญ</Option>
                <Option value="ไซไฟ">ไซไฟ</Option>
                <Option value="ละคร">ละคร</Option>
                <Option value="ภัยพิบัติ">ภัยพิบัติ</Option>
                <Option value="อาชญากรรม">อาชญากรรม</Option>
                <Option value="ศิลปะการต่อสู้">ศิลปะการต่อสู้</Option>
            </Select>
          <br/>
          <span>Default SubType will be set to <b>หนังบู๊</b></span>
        </Form.Item>
        <Form.Item label="Release Date" name="releasedate" rules={[{required:true, message:'Please input the release date of the movie. If not applicable, fill in today date.'}]}>
            <DatePicker
              style={{width:"470px"}}
              format={dateFormat}
              onChange={trxDatePicker}
              // value={releaseDate}
              placeholder={"Select The Movie Release Date"}
              />
              <br/>
            <span>If not applicable, select today date.</span>
        </Form.Item>
        <Form.Item label="IMDb Rating" name="imdb-rating" rules={[{required:true, message:'Please input the IMDb rating of the movie.'}]}>
        <Input 
            allowClear 
            value={imdb}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="Range from 0 to 10"
            onChange = {(e) => {
              setImdb(e.target.value);
            }}
          />
          <br/>
          <span>If not applicable input <b>N/A</b></span>
        </Form.Item>
        <Form.Item label="RottenTomatoes Rating" name="rottenTomato-rating" rules={[{required:true, message:'Please input the percentage for RottenTomatoes'}]}>
        <Input 
            allowClear 
            value={rottenTomato}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G. 90%"
            onChange = {(e) => {
              setRottenTomato(e.target.value);
            }}
          />
          <br/>
          <span>If not applicable input <b>N/A</b></span>
        </Form.Item>
        <Form.Item label="Metacritic Rating" name="metacriticRating" rules={[{required:true, message:'Please input the percentage for Metacritic'}]}>
        <Input 
            allowClear 
            value={metacritic}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G. 90%"
            
            onChange = {(e) => {
              setMetacritic(e.target.value);
            }}
          />
          <br/>
          <span>If not applicable input <b>N/A</b></span>
        </Form.Item>
        <Form.Item label="Google Rating" name="googleRating" rules={[{required:true, message:'Please input the Google rating'}]}>
        <Input 
            allowClear 
            value={google}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G. 90%"
            
            onChange = {(e) => {
              setGoogle(e.target.value);
            }}
          />
          <br/>
          <span>If not applicable input <b>N/A</b></span>
        </Form.Item>
        <Form.Item label="Description (EN)">
        <Input.TextArea
            allowClear 
            value={descEn}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Description (EN)"
            // rules={[{required:true, message:'Please input the description in English'}]}
            rows={4}
            onChange = {(e) => {
              setDescEn(e.target.value);
            }}
          />
          {/* <br/>
          <span>If not applicable input <b>N/A</b></span> */}
        </Form.Item>
        <Form.Item label="Description (TH)" name="desc-th" rules={[{required:true, message:'Please input the description of the movie in Thai'}]}>
        <Input.TextArea
            allowClear 
            value={descTh}
            // maxLength={150}
            rows={4}
            style={{width:"470px"}}
            placeholder="Description (TH)"
            
            onChange = {(e) => {
              setDescTh(e.target.value);
            }}
          />
           <br/>
          <span>If not applicable input <b>N/A</b></span> 
        </Form.Item>
        <Form.Item label="Duration">
        <Input 
            allowClear 
            value={duration}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G. 2h 19m"
            rules={[{required:true, message:'Please enter the duration'}]}
            onChange = {(e) => {
              setDuration(e.target.value);
            }}
          />
          {/* <br/>
          <span>If not applicable input <b>N/A</b></span> */}
        </Form.Item>
        <Form.Item label="Box Office">
        <Input 
            allowClear 
            value={boxOffice}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G.  $760 million"
            // rules={[{required:true, message:'Please enter the box office'}]}
            onChange = {(e) => {
              setBoxOffice(e.target.value);
            }}
          />
          {/* <br/>
          <span>If not applicable input <b>N/A</b></span> */}
        </Form.Item>
        <Form.Item label="Directors">
        <Input 
            allowClear 
            value={director}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G. Evan Moore"
            rules={[{required:true, message:'Please enter the name of the director of the movie'}]}
            onChange = {(e) => {
              setDirector(e.target.value);
            }}
          />
          {/* <br/>
          <span>If not applicable input <b>N/A</b></span> */}
        </Form.Item>
        <Form.Item label="Budget">
        <Input 
            allowClear 
            value={budget}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G.  $15 million"
            // rules={[{required:true, message:'Please enter the budget of this movie'}]}
            onChange = {(e) => {
              setBudget(e.target.value);
            }}
          />
          {/* <br/>
          <span>If not applicable input <b>N/A</b></span> */}
        </Form.Item>
        <Form.Item label="Cover" name="add-cover" rules={[{required:true, message:'Please add the url for the cover'}]}>
        <Input 
            allowClear 
            value={cover}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G.  https://upload.wikimedia.org/wikipedia/en/d/d9/Avengers_Infinity_War_soundtrack_cover.jpg"
            rules={[{required:true, message:'Please enter the link of the movie\'s cover'}]}
            onChange = {(e) => {
              setCover(e.target.value);
            }}
          />
           <br/>
          <span>Please input a valid URL, i.e. https://upload.wikimedia.org/wikipedia/en/d/d9/Avengers_Infinity_War_soundtrack_cover.jpg</span>
        </Form.Item>
        <Form.Item label="Content URL" name="add-contentURL" rules={[{required:true, message: "Please input the url for the adult movie."}]}>
        <Input.TextArea 
            allowClear 
            value={contentURL}
            // maxLength={150}
            placeholder="E.G.  https://taokaebet.club/embed/1Sg2igr/"
            // rules={[{required:true, message:'Please enter the URL of the movie.'}]}
            onChange = {(e) => {
              setContentURL(e.target.value);
            }}
            // rows={4}
          />
          <br/>
          <span>Please input a valid URL, i.e. https://taokaebet.club/embed/1Sg2igr/</span>
        </Form.Item>
        <Form.Item label="Status">
            <Select value={status}
                defaultValue="Enabled"
                onChange={(e)=>{setStatus(e)}}>
                <Option value="1">Enabled</Option>
                <Option value="0">Disabled</Option>
            </Select>
          {/* <br/>
          <span>Default SubType will be set to <b>หนังบู๊</b></span> */}
        </Form.Item>
        <Form.Item label="Label">
        <Select value={option}
                defaultValue="new"
                onChange={(e)=>{setOption(e)}}>
                <Option value="new">new</Option>
                <Option value="hot">hot</Option>
                <Option value="new, hot">new, hot</Option>
            </Select>
          {/* <br/>
          <span>If not applicable input <b>N/A</b></span> */}
        </Form.Item>
      </Form>
      
    )
  }
  

  const onEditMovieDetail = () =>{
    return(
      <Form 
        form={form}
        ref={modalForm}
        {...layout}
        name="addMovieRecord"
        initialValues={{remember: false}}
      >
        <Form.Item label="Movie ID">
          <Input value={id} disabled/>
        </Form.Item>
        <Form.Item label="Title (EN)">
        <Input 
            allowClear 
            value={titleEn}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Movie Title (EN)"
            // rules={[{required:true, message:'Please input the title of the movie'}]}
            onChange = {(e) => {
              setTitleEn(e.target.value);
            }}
          />
          {/* <br/>
          <span>Maximum 30 characters</span> */}
        </Form.Item>
        <Form.Item label={<span>Title (TH)</span>} name="title-th-edit" rules={[{required:true, message:'Please input the description of the movie in Thai'}]}>
        <Input 
            allowClear 
            value={titleTh}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Movie Title (TH)"
            rules={[{required:true, message:'Please input the title of the movie in Thai'}]}
            onChange = {(e) => {
              setTitleTh(e.target.value);
            }}
          />
           <br/>
          <span>Please input the movie title in Thai</span> 
        </Form.Item>
        <Form.Item label="Type (EN)">
            <Select value={typeEn}
              defaultValue="Action"
              onChange={(e)=>{setTypeEn(e)}}>
              <Option value="Action">Action</Option>
              <Option value="Horror">Horror</Option>
              <Option value="Fantasy">Fantasy</Option>
              <Option value="Thriller">Thriller</Option>
              <Option value="Drama">Drama</Option>
              <Option value="Adventure">Adventure</Option>
              <Option value="Romance">Romance</Option>
              <Option value="Sci-fi">Sci-fi</Option>
            </Select>
          <br/>
          <span>Default Type will be set to <b>Action</b></span>
        </Form.Item>
        <Form.Item label="Type (TH)" name="desc-th-edit" rules={[{required:true, message:'Please input the  description of the movie in Thai'}]}>
            <Select value={typeTh}
                defaultValue="หนังบู๊"
                onChange={(e)=>{setTypeTh(e)}}>
                <Option value="หนังบู๊">หนังบู๊</Option>
                <Option value="สยองขวัญ">สยองขวัญ</Option>
                <Option value="แฟนตาซี">แฟนตาซี</Option>
                <Option value="ระทึกขวัญ">ระทึกขวัญ</Option>
                <Option value="ละคร">ละคร</Option>
                <Option value="การผจญภัย">การผจญภัย</Option>
                <Option value="โรแมนติก">โรแมนติก</Option>
                <Option value="ไซไฟ">ไซไฟ</Option>
            </Select>
          {/* <br/>
          <span>Default Type will be set to <b>หนังบู๊</b></span> */}
        </Form.Item>
        <Form.Item label="SubType (EN)">
            <Select value={subTypeEn}
              defaultValue="Action"
              onChange={(e)=>{setSubTypeEn(e)}}>
              <Option value="Action">Action</Option>
              <Option value="Comedy">Comedy</Option>
              <Option value="Adventure">Adventure</Option>
              <Option value="Thriller">Thriller</Option>
              <Option value="Sci-fi">Sci-fi</Option>
              <Option value="Drama">Drama</Option>
              <Option value="Disaster">Disaster</Option>
              <Option value="Crime">Crime</Option>
              <Option value="Martial Arts">Martial Arts</Option>
            </Select>
          <br/>
          <span>Default SubType will be set to <b>Action</b></span>
        </Form.Item>
        <Form.Item label="SubType (TH)">
            <Select value={subTypeTh}
                defaultValue="หนังบู๊"
                onChange={(e)=>{setSubTypeTh(e)}}>
                <Option value="หนังบู๊">หนังบู๊</Option>
                <Option value="ตลก">ตลก</Option>
                <Option value="การผจญภัย">การผจญภัย</Option>
                <Option value="ระทึกขวัญ">ระทึกขวัญ</Option>
                <Option value="ไซไฟ">ไซไฟ</Option>
                <Option value="ละคร">ละคร</Option>
                <Option value="ภัยพิบัติ">ภัยพิบัติ</Option>
                <Option value="อาชญากรรม">อาชญากรรม</Option>
                <Option value="ศิลปะการต่อสู้">ศิลปะการต่อสู้</Option>
            </Select>
          <br/>
          <span>Default SubType will be set to <b>หนังบู๊</b></span>
        </Form.Item>
        <Form.Item label="Release Date" name="editReleaseDate" rules={[{required:true, message:'Please input the release date of the movie. If not applicable, fill in today date.'}]}>
          <DatePicker
              id="releasedateEdit"
              style={{width:"470px"}}
              format={dateFormat}
              onChange={trxDatePicker}
              ref={refReleaseDate}
              value={releaseDate}
              defaultValue={moment(releaseDate, 'DD MMMM, YYYY')}
              placeholder={"Select The Movie Release Date"}
            />
        </Form.Item>
        <Form.Item label="IMDb Rating" name='imdbRatingEdit' rules={[{required:true, message:'Please input the value of IMDb for the movie'}]}>
        <Input 
            allowClear 
            value={imdb}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="Range from 0 to 10"
            onChange = {(e) => {
              setImdb(e.target.value);
            }}
          />
          <br/>
          <span>If not applicable input <b>N/A</b></span>
        </Form.Item>
        <Form.Item label="RottenTomatoes Rating" name='rottenTomatoEdit' rules={[{required:true, message:'Please input the score of the RottenTomatoes'}]}>
        <Input 
            allowClear 
            value={rottenTomato}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G. 90%"
            
            onChange = {(e) => {
              setRottenTomato(e.target.value);
            }}
          />
          <br/>
          <span>If not applicable input <b>N/A</b></span>
        </Form.Item>
        <Form.Item label="Metacritic Rating" name='metacriticRatingEdit' rules={[{required:true, message:'Please input the Metacritic rating'}]}>
        <Input 
            allowClear 
            value={metacritic}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G. 90%"
            
            onChange = {(e) => {
              setMetacritic(e.target.value);
            }}
          />
          <br/>
          <span>If not applicable input <b>N/A</b></span>
        </Form.Item>
        <Form.Item label="Google Rating" name="googleratingedit" rules={[{required:true, message:'Please input the Google rating'}]}>
        <Input 
            allowClear 
            value={google}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G. 90%"
            
            onChange = {(e) => {
              setGoogle(e.target.value);
            }}
          />
          <br/>
          <span>If not applicable input <b>N/A</b></span>
        </Form.Item>
        <Form.Item label="Description (EN)">
        <Input.TextArea
            allowClear 
            value={descEn}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Description (EN)"
            rows={4}
            // rules={[{required:true, message:'Please input the description in English'}]}
            onChange = {(e) => {
              setDescEn(e.target.value);
            }}
          />
          {/* <br/>
          <span>If not applicable input <b>N/A</b></span> */}
        </Form.Item>
        <Form.Item label="Description (TH)" name="desc-th-edit" rules={[{required:true, message:'Please input the description in Thai'}]}>
        <Input.TextArea 
            allowClear 
            value={descTh}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Description (TH)"
            
            rows={4}
            onChange = {(e) => {
              setDescTh(e.target.value);
            }}
          />
          <br/>
          <span>If not applicable input <b>N/A</b></span>
        </Form.Item>
        <Form.Item label="Duration">
        <Input 
            allowClear 
            value={duration}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G. 2h 19m"
            // rules={[{required:true, message:'Please enter the duration'}]}
            onChange = {(e) => {
              setDuration(e.target.value);
            }}
          />
          {/* <br/>
          <span>If not applicable input <b>N/A</b></span> */}
        </Form.Item>
        <Form.Item label="Box Office">
        <Input 
            allowClear 
            value={boxOffice}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G.  $760 million"
            // rules={[{required:true, message:'Please enter the box office'}]}
            onChange = {(e) => {
              setBoxOffice(e.target.value);
            }}
          />
          {/* <br/>
          <span>If not applicable input <b>N/A</b></span> */}
        </Form.Item>
        <Form.Item label="Directors">
        <Input 
            allowClear 
            value={director}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G. Evan Moore"
            // rules={[{required:true, message:'Please enter the name of the director of the movie'}]}
            onChange = {(e) => {
              setDirector(e.target.value);
            }}
          />
          {/* <br/>
          <span>If not applicable input <b>N/A</b></span> */}
        </Form.Item>
        <Form.Item label="Budget">
        <Input 
            allowClear 
            value={budget}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G.  $15 million"
            // rules={[{required:true, message:'Please enter the budget of this movie'}]}
            onChange = {(e) => {
              setBudget(e.target.value);
            }}
          />
          {/* <br/>
          <span>If not applicable input <b>N/A</b></span> */}
        </Form.Item>
        <Form.Item label="Cover" name="edit-cover" rules={[{required:true, message:'Please add the url for the cover'}]}>
        <Input 
            allowClear 
            value={cover}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G.  https://upload.wikimedia.org/wikipedia/en/d/d9/Avengers_Infinity_War_soundtrack_cover.jpg"
            rules={[{required:true, message:'Please enter the link of the movie\'s cover'}]}
            onChange = {(e) => {
              setCover(e.target.value);
            }}
          />
           <br/>
          <span>Please input a valid URL, i.e. https://upload.wikimedia.org/wikipedia/en/d/d9/Avengers_Infinity_War_soundtrack_cover.jpg</span>
        </Form.Item>
        <Form.Item label="Content URL" name="edit-contentURL" rules={[{required:true, message: "Please input the url for the adult movie."}]}>
        <Input.TextArea 
            allowClear 
            value={contentURL}
            // maxLength={150}
            placeholder="E.G.  https://taokaebet.club/embed/1Sg2igr/"
            // rules={[{required:true, message:'Please enter the URL of the movie.'}]}
            onChange = {(e) => {
              setContentURL(e.target.value);
            }}
            // rows={4}
          />
          <br/>
          <span>Please input a valid URL, i.e. https://taokaebet.club/embed/1Sg2igr/</span>
        </Form.Item>
        <Form.Item label="Status">
            <Select value={status}
                defaultValue="Enabled"
                onChange={(e)=>{setStatus(e)}}>
                <Option value="1">Enabled</Option>
                <Option value="0">Disabled</Option>
            </Select>
          {/* <br/>
          <span>Default SubType will be set to <b>หนังบู๊</b></span> */}
        </Form.Item>
        <Form.Item label="Label">
        <Select value={option}
                defaultValue="new"
                onChange={(e)=>{setOption(e)}}>
                <Option value="new">new</Option>
                <Option value="hot">hot</Option>
                <Option value="new, hot">new, hot</Option>
            </Select>
          {/* <br/>
          <span>If not applicable input <b>N/A</b></span> */}
        </Form.Item>
      </Form>
      
    )
  }


  ////#region Populate List functions
  const moviesList = async () =>{
    let count = 0; // to set key for display 
    setTableLoadings(true);
    const resMovie = await movieList({data:null});
    let res = await resMovie.json();
    console.log("movie res", res)
    if (res.status === "ok" && res.msg != "No record."){
      if(Object.keys(res.data).length > 0){
        setTableLoadings(false);
        let data = res.data.map((x)=>{
          return{
            key: count++,
            id: x.id,
            titleEn: x.titleen,
            titleTh: x.titleth,
            descEn: x.descen,
            descTh: x.descth,
            status: x.status == 0? 
            "Disabled": "Enabled"
          }
        })
        setMovieData(data);
        setAddVisible(false);
      }else {
        setTableLoadings(false);
        message.error(res.msg);
      }
    }else{
      message.error( "An unexpected error occurred. Contact your system administrator ");
    }
  }


  ////#endregion Populate

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
        <Input allowClear={true} />

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const showModal = async () => {
    setAddVisible(true);
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };


  const confirmDel = async () => {
    setDeleteVisible(false);
    setLoading(true)
    const id = movieId;
    const tmp = await movieDelete({id: id})
    let res = await tmp.json();
    setLoading(false)
    if (res.status === "ok" ){
      moviesList()
      message.success(res.msg);
    }else{
      message.error( "An unexpected error occurred. Contact your system administrator ");
    }
  }
  const deleteMovie = async (record) =>{
    setDeleteVisible(true);
    setMovieId(record.id);
  }

  const editMovie = async (record) => {
    // console.log('record',record)
    const movieId = record.id;
    // console.log('movieId',movieId)
    setLoading(true);
    // loadingScreen(true);
    const tmp = await getMovieById({movieId: movieId});
    let res = await tmp.json();
    setLoading(false)
    
    console.log('movie detail ',res)
    if (res.status === "ok" ){
        let data = res.data.map((x)=>{
          return{
            id: x.id,
            titleEn: x.titleen,
            titleTh: x.titleth,
            typeEn: x.typeen,
            typeTh: x.typeth,
            subTypeEn: x.subTypeen,
            subTypeTh: x.subTypeth,
            releaseDate: x.releaseDate,
            imdb: x.imdb,
            rottenTomato: x.rottenTomatoes,
            metaCritic: x.metacritic,
            google: x.google,
            descEn: x.descen,
            descTh: x.descth,
            duration: x.duration,
            boxOffice: x.boxOffice,
            directors: x.directors,
            budget: x.budget,
            cover: x.cover,
            contentURL: x.contentURL,
            options: x.option,
            status: x.status == 0? 
            "Disabled": "Enabled"
          }
        })
        // console.log('data',data)
        let movie = data[0]
        setId(movie.id)
        setTitleEn(movie.titleEn)
        setTitleTh(movie.titleTh)
        setTypeEn(movie.typeEn)
        setTypeTh(movie.typeTh)
        setSubTypeEn(movie.subTypeEn)
        setSubTypeTh(movie.subTypeTh)
        setReleaseDate(movie.releaseDate)
        setImdb(movie.imdb)
        setRottenTomato(movie.rottenTomato)
        setMetacritic(movie.metaCritic)
        setDescEn(movie.descEn)
        setDescTh(movie.descTh)
        setDuration(movie.duration)
        setBoxOffice(movie.boxOffice)
        setDirector(movie.director)
        setBudget(movie.budget)
        setCover(movie.cover)
        setContentURL(movie.contentURL)
        setStatus(movie.status)
        setOption(movie.options)
        setGoogle(movie.google)
        setDirector(movie.directors)
        setBudget(movie.budget)
        
        // loadingScreen(false)
        setEditVisible(true);

      }else{
        message.error( "An unexpected error occurred. Contact your system administrator ");
      }
      
  }

  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });
  
  // classNames= {
  //   show: { display: 'block' }, 
  //   hide: { display: 'none' }
  // }

  const movie_column = [
    {
      title: "ID",
      dataIndex: "id",
      className: "hide"
    },
    {
      title: "Title (EN)",
      dataIndex: "titleEn",
      // width: "20%",
    },
    {
      title: "Title (TH)",
      dataIndex: "titleTh",
      // width: "15%",
    },
    {
      title: "Description  (EN)",
      dataIndex: "descEn",
      // className: { display: 'none' }
      // width: "15%",
    },
    {
      title: "Description (TH)",
      dataIndex: "descTh",
      // width: "15%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "20%",
      render: (text)=> {          
          if (text=="Disabled") {
            return <Badge status="error" text="Disabled"/>;
          }else{
            return <Badge status="success" text="Enabled" />;
          }
      },
      filters: [
        {
          text: 'Disabled',
          value: 'Disabled',
        },

        {
          text: 'Enabled',
          value: 'Enabled',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.status.indexOf(value) === 0
    },
    ,
    {
      title: "Action",
      dataIndex: "",
      width: "10%",
      render: (text, record)=> {   
        return (  
          <>     
          <Button 
            type="primary" 
            shape="circle" 
            icon={<EditOutlined />} 
            onClick={() => {
              // editCampaign(record);
              editMovie(record);
              
            }}
          />
          &nbsp;
          <Button 
            type="danger" 
            shape="circle" 
            icon={<DeleteOutlined />} 
            onClick={() => {
              // editCampaign(record);
              deleteMovie(record);

            }}
          />
          </>
        )
      }
    }
  ]


 const handleEdit = async(index)=> {
  if(!titleTh){
    message.error('Please fill in the movie title for Thai version');
    return;
  }

  if(!descTh){
    message.error('Please fill in the description for the movie for Thai version');
    return;
  }
  
  if(!releaseDate){
    message.error('Please fill in the release date for the movie. If not applicable, fill in today date.');
    return;
  }

  if(!imdb){
    message.error('Please fill in Imdb rating for the movie. If not applicable fill in N/A');
    return;
  }

  if(!google){
    message.error('Please fill in Google rating for the movie. If not applicable fill in N/A');
    return;
  }

  if(!metacritic){
    message.error('Please fill in the rating of metacritic for the movie. If not applicable fill in N/A');
    return;
  }

  if(!rottenTomato){
    message.error('Please fill in the rotten tomatoes score for the movie. If not applicable fill in N/A');
    return;
  }

  setLoading(true)
  let stat;
  // console.log('type of status', typeof status)
  if(!parseInt(status)){
    stat = status == 'Enabled' ? 1 : 0;
  }else{
    stat = parseInt(status);
  }
  // let stat = status == "Enabled" ? 1 : 0;
    let requestData = {
      "id": id,
      "titleen": titleEn,
      "titleth": titleTh,
      "typeen": typeEn,
      "typeth": typeTh,
      "subtypeen": subTypeEn,
      "subtypeth": subTypeTh,
      "releasedate": releaseDate,
      "imdb": imdb,
      "rottentomato": rottenTomato,
      "metacritic": metacritic,
      "google": google,
      "descen": descEn,
      "descth": descTh,
      "duration": duration,
      "boxoffice": boxOffice,
      "director": director,
      "budget": budget,
      "cover": cover,
      "contenturl": contentURL,
      "status": stat,
      "option": option
    }

    
  movieNew(requestData)
  .then((response) => response.json())
  .then((json) => {
    setEditVisible(false);
    if (json.status === "ok") {
      setTimeout(()=>{
        setLoading(false);
        moviesList()
        message.success(json.msg);
        clearAllFields();
      }, 2000)
      
    } else {
      loadingTrue(index);
      message.error(json.msg);
    }
    loadingFalse(index);
    //modalForm.current.resetFields();
    clearAllFields();
  })
  .catch((error) => {
    console.log("error: ", error);
    message.error(
      "An unexpected error occurred. Contact your system administrator "
    );
  });
 }

  const handleAdd = (index) => {
  
    if(!titleTh){
      message.error('Please fill in the movie title for Thai version');
      return;
    }
  
    if(!descTh){
      message.error('Please fill in the description for the movie for Thai version');
      return;
    }
    
    if(!releaseDate){
      message.error('Please fill in the release date for the movie. If not applicable, fill in today date.');
      return;
    }
  
    if(!imdb){
      message.error('Please fill in Imdb rating for the movie. If not applicable fill in N/A');
      return;
    }
  
    if(!google){
      message.error('Please fill in Google rating for the movie. If not applicable fill in N/A');
      return;
    }
  
    if(!metacritic){
      message.error('Please fill in the rating of metacritic for the movie. If not applicable fill in N/A');
      return;
    }
  
    if(!rottenTomato){
      message.error('Please fill in the rotten tomatoes score for the movie. If not applicable fill in N/A');
      return;
    }

    // loadingTrue(index);
    setLoading(true)

    // let fbPxStatus = FBPixelStatus =="Enabled" ? "1": "0" ;
    let stat = status == "Enabled" ? 1 : 0;
    let requestData = {
      "titleen": titleEn,
      "titleth": titleTh,
      "typeen": typeEn,
      "typeth": typeTh,
      "subtypeen": subTypeEn,
      "subtypeth": subTypeTh,
      "releasedate": releaseDate,
      "imdb": imdb,
      "rottentomato": rottenTomato,
      "metacritic": metacritic,
      "google": google,
      "descen": descEn,
      "descth": descTh,
      "duration": duration,
      "boxoffice": boxOffice,
      "director": director,
      "budget": budget,
      "cover": cover,
      "contenturl": contentURL,
      "status": stat,
      "option": option
    }

    
    movieNew(requestData)
      .then((response) => response.json())
      .then((json) => {
        setAddVisible(false);
        if (json.status === "ok") {
          // campaignList();
          // setTimeout(()=>{
            setLoading(false)
            moviesList()
            message.success(json.msg);
            clearAllFields();
            
          // }, 2000)
          
        } else {
          loadingTrue(index);
          message.error(json.msg);
          clearAllFields();
          // form.resetFields();
        }
        loadingFalse(index);
        setId()
        setTitleEn("")
        setTitleTh("")
        setTypeEn("Action")
        setTypeTh("หนังบู๊")
        setSubTypeEn("Action")
        setSubTypeTh("หนังบู๊")
        setReleaseDate("")
        setImdb("")
        setRottenTomato("")
        setMetacritic("")
        setDescEn("")
        setDescTh("")
        setDuration("")
        setBoxOffice("")
        setDirector("")
        setBudget("")
        setCover("")
        setContentURL("")
        setStatus("Enabled")
        setOption("new")
        setGoogle("")
        form.resetFields(["title-th", "desc-th", "add-cover", "add-contentURL"]);
      })
      .catch((error) => {
        console.log("error: ", error);
        message.error(
          "An unexpected error occurred. Contact your system administrator "
        );
      });
  };


    const trxDatePicker = (date, dateString) => {
      setReleaseDate(dateString);
    };


  

  const onCancel = () => {
    setAddVisible(false);
    clearAllFields();
    //modalForm.current.resetFields();
  };

  const onCancelEdit = () => {
    setEditVisible(false);
    clearAllFields();
  }
  const onCancelDelete = () => {
    setDeleteVisible(false)
  }
  const styles = {
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
    },
    refreshBtn: {
      marginLeft: 10,
    },
    tabelContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    formItem: {
      width:"80%"
    },
    datePickerStyle: {
      width: 260,
    },
    hide : {
      display:"none",
    },
    memberSearchStyle:{
      width:180,
    }
  };

  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <div>
        <Modal title="New Movie Record"  visible={addVisible} 
        onOk={handleAdd}
        onCancel={() => {
          onCancel();
        }}
        maskClosable={false}
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "danger", loading: loadings[0], title: "Save" }}
        okText="Save"
        center   
        width={850}
        >
          {/* {onCreateFBCampaign()} */}
          {onCreateMovieRecord()}
        </Modal>
        {/* <Spin></Spin> */}
        {/* {loadingScreen(loading)} */}
        <Modal 
        title='Loading'
        visible={loading} 
        center
        width={850}
        footer={null}>
          <Spin spinning={loading} className='container' tip='Loading ...'></Spin>
        </Modal>
        
        <Modal title= "Edit Movie List" visible={editVisible} 
        onOk={handleEdit}
        // onOk={handleAdd}
        onCancel={() => {
          onCancelEdit();
        }}
        maskClosable={false}
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "danger", loading: loadings[0], title: "Save" }}
        okText="Save"
        center
        width={850}
        >
          {/* {onEditFBCampaign()} */}
          
          {onEditMovieDetail()}
        </Modal>
      </div>
        <Modal title='Confirmation' 
        visible={deleteVisible} 
        onOk={confirmDel}
        onCancel={()=>{onCancelDelete();}}
        >
          <p>Are You Confirm To Delete the Movie?</p>
        </Modal>
      <PageHeader ghost={false}
      >
        <div>
          <Button key="1" type="primary" onClick={showModal}>
            Add New Movie Record
          </Button>
        </div>
      </PageHeader>
      <Form form={form} component={false}>
        <Table
          loading={tableLoadings}
          scroll={{ x: "100%", y: null }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={movieData} // originData
          columns ={movie_column} //columns
          rowClassName="editable-row"

          pagination={{
            defaultPageSize: 15,
            showSizeChanger: true,
            pageSizeOptions: settings.pageSizeOptions,
            style: styles.paginationStyle,
            alignmentBottom: 'right',
          }}
          style={{ overflow: "auto", marginLeft: 20 }}
        />
      </Form>
    </div>
  );
}
