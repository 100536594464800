import moment from 'moment'
import React, {useState, useEffect} from 'react'
import { settings } from '../../../../../config/appConfig'


const MonitorRemark = () => {
  const init = new Date()
  const [date, setDate] = useState(init)

  const tick = () => {
    setDate(new Date())
  }

  useEffect(() => {
    const timerID = setTimeout(() => tick(), settings.monitorRefreshTime)
    return () => {
      clearTimeout(timerID)
    }
  }, [date])

  return <><p><b>REMARK: Auto-Refresh every {Math.floor(settings.monitorRefreshTime/60000)} minutes. (as at {moment(date).format("YYYY-MM-DD HH:mm:ss")})</b></p></>
}

export {
    MonitorRemark
}