import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Form,
  Button,
  Modal,
  Badge,
  PageHeader,
  message,
  Select,
} from "antd";
import {
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  mktgFBCampaignList,
  mktgFBCampaignNew,
  mktgFBCampaignUpdate,
  mktgFBEventList,
} from "../../config/networkConfig";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { FormatDateHHMM } from "../../library/dates";
import { settings } from "../../config/appConfig";

export default function CampaignManagement() {
  const [form] = Form.useForm();
  const modalForm = useRef();
  const [originData, setOriginData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [searchText, setSearchText] = useState("");
  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const { userId, username } = useSelector((state) => state.login);
  const [tableLoadings, setTableLoadings] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const { Option } = Select;
  const [FBPixelId, setFBPixelId] = useState("");
  const [FBPixelAccessToken,setFBPixcelAccessToken] = useState("");
  const [FBPixelRemark,setFBPixelRemark] = useState("");
  const [FBLastRunTime, setFBLastRunTime] = useState("");
  const [FBPixelStatus,setFBPixelStatus] = useState("Disabled");
  const [FBEvents, setFBEvents]  = useState([]);
  const [FBSelectedEvent, setFBSelectedEvent] = useState([]);

  const children = [];

  useEffect(() => {
    campaignList();
    FBEventsList();
    // eslint-disable-next-line
  }, []);

  const selectFBventsList = (value) =>{
    setFBSelectedEvent(value);
  }

  const selectFBPixelStatus = (value) => {
    setFBPixelStatus(value);
  };

  const clearAllFields = () => {
    setFBPixelId("");
    setFBPixcelAccessToken("");
    setFBPixelRemark("");
    setFBLastRunTime("");
    setFBPixelStatus("Disabled");
    setFBSelectedEvent([]);
  }

  const onCreateFBCampaign = () => {
    return (
      <Form form={form} 
            ref={modalForm}
            layout="vertical" 
            name="createFBCampaignform"
            initialValues={{ remember:false }}
      >
        <Form.Item
          label="FB Pixel Id"
          required={true}
        >
            <>    
              <Input 
                allowClear 
                value={FBPixelId}
                maxLength={30}
                style={{width:"470px"}}
                placeholder="Pixel Id"
                rules={[{required:true, message:'Please input your Pixel Id'}]}
                onChange = {(e) => {
                  setFBPixelId(e.target.value);
                }}
              />
              <br/>
              <span>Maximum 30 characters</span>
            </>
        </Form.Item>
        <Form.Item
          id="Token"
          label="Access Token"
          required={true}
        >
          <>
            <Input.TextArea
                value={FBPixelAccessToken}
                onChange={(e) => {
                  setFBPixcelAccessToken(e.target.value);
                }}
                rules={[{required:true, message:'Please input your Access Token'}]}
                maxLength={250}
                rows={4}
              />
              <span>Maximum 250 characters</span>
          </>
        </Form.Item>        
        <Form.Item
          id="FBEvents"
          label="Events"
          required={true}
        >
            <Select
              mode="multiple"
              onChange={selectFBventsList}
            >
              {FBEvents.map((option) => {
                return (
                  <Option key={option.event} value={option.event}>
                    {option.event} 
                  </Option>
                );
              })}
            </Select>
        </Form.Item>
        <Form.Item
          id="Status"
          label="Status"
          required={true}
        >
            <Select value={FBPixelStatus}
              defaultValue="Disabled"
              onChange={selectFBPixelStatus}>
              <Option value="Enabled">Enabled</Option>
              <Option value="Disabled">Disabled</Option>
            </Select>
        </Form.Item>
        <Form.Item
          id="Remark"
          label="Remark"
        >
          <>
            <Input.TextArea
                value={FBPixelRemark}
                onChange={(e) => {
                  setFBPixelRemark(e.target.value);
                }}
                maxLength={100}
                rows={4}
              />
              <span>Maximum 100 characters</span>
          </>
        </Form.Item>
       
      </Form>
    );
  };

  const onEditFBCampaign = () => {
    return (
      <Form form={form} 
            ref={modalForm}
            layout="vertical" 
            name="createFBCampaignform"
            initialValues={{ remember:false }}
      >
        <Form.Item
          label="FB Pixel Id"
        >
            <>    
              <span>{FBPixelId}</span>
            </>
        </Form.Item>
        <Form.Item
          id="Token"
          label="Access Token"
          required={true}
        >
          <>
            <Input.TextArea
                value={FBPixelAccessToken}
                onChange={(e) => {
                  setFBPixcelAccessToken(e.target.value);
                }}
                rules={[{required:true, message:'Please input your Access Token'}]}
                maxLength={250}
                rows={4}
              />
              <span>Maximum 250 characters</span>
          </>
        </Form.Item>
        <Form.Item
          id="FBEvents"
          label="Events"
          required={true}
        >
            <Select
              mode="multiple"
              onChange={selectFBventsList}
              value={FBSelectedEvent}
            >
              {/* {FBEventsSelected} */}
              {FBEvents.map((option) => {
                return (
                  <Option key={option.event} value={option.event}>
                    {option.event} 
                  </Option>
                );
              })}
            </Select>
        </Form.Item>
        <Form.Item
          id="Status"
          label="Status"
          required={true}
        >
            <Select value={FBPixelStatus}
              defaultValue="Disabled"
              onChange={selectFBPixelStatus}>
              <Option value="Enabled">Enabled</Option>
              <Option value="Disabled">Disabled</Option>
            </Select>
        </Form.Item>
        <Form.Item
          id="Remark"
          label="Remark"
        >
          <>
            <Input.TextArea
                value={FBPixelRemark}
                onChange={(e) => {
                  setFBPixelRemark(e.target.value);
                }}
                maxLength={100}
                rows={4}
              />
              <span>Maximum 100 characters</span>
          </>
        </Form.Item>
        <Form.Item
          label="Last Run Time"
        >
          {FBLastRunTime.length ? FormatDateHHMM(FBLastRunTime): "N/A"}
        </Form.Item>
      </Form>
    );
  };

  ////#region Populate List functions
  const FBEventsSelected = [];

  const FBEventsList = async () => {
    var count = 0;
    const result = await mktgFBEventList({data:null});
    let respEvent = await result.json();

    if (respEvent.status ==="ok" && respEvent.msg !='No records found') {

      if (Object.keys(respEvent.data).length > 0 ) {
        setTableLoadings(false);
        let respData = respEvent.data.filter((item)=> item.status =="1");
        let newData = respData.map((obj) => {
          return {
            event: obj.events,
            remark: obj.remark,
          }
        });

        setFBEvents(newData)

      } else {
        setTableLoadings(false);
        message.error(respEvent.msg);
      }

    }else{
      message.error( "An unexpected error occurred. Contact your system administrator ");
    }

  }

  const campaignList =  async () => {
    var count = 0;
    setTableLoadings(true);

    const resCampaign = await mktgFBCampaignList({data:null});
    let respCampaign = await resCampaign.json();

    if (respCampaign.status ==="ok" && respCampaign.msg !='No records found') {

        if (Object.keys(respCampaign.data).length > 0) {
          setTableLoadings(false);
          //console.log(`array 0? >> ${JSON.stringify(respCampaign.data)}`)
          let newData = respCampaign.data.map((x) => {
                return {
                  key: count++,
                  pixelId: x.pixelid,
                  accessToken: x.accesstoken,
                  eventlist: x.eventlist, 
                  remark: x.remark,
                  status: 
                    x.status === 0? 
                      "Disabled": "Enabled",
                  sysCreateBy: x.sysCreateBy,
                  sysCreateTime: x.sysCreateTime,
                  sysUpdateBy : x.sysUpdateBy,
                  sysUpdateTime : x.sysUpdateTime,
                  lastRunTime: x.fbLastRuntime,
                  lastUpdateTime : x.sysLastUpdateTime,
                };
              });

              
          setOriginData(newData);
          setAddVisible(false);

        } else {
          setTableLoadings(false);
          message.error(respCampaign.msg);
        }
    }else{
      message.error( "An unexpected error occurred. Contact your system administrator ");
    }
  };

  ////#endregion Populate

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      // inputType === "userTypeSelect" ? (
      //   <Select
      //     dropdownStyle={{ position: "fixed" }}
      //     onSelect={(value, event) => {
      //       userTypeSelect(value, event);
      //     }}
      //   >
      //     <Option value="CS">CS</Option>
      //     {/* <Option value="CS_Senior">CS Senior</Option>
      //     <Option value="CS_Junior">CS Junior</Option> */}
      //   </Select>
      // ) : (
        <Input allowClear={true} />
      //);

    //const inputNode = <Input allowClear={true} />;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const showModal = async () => {
    setEditingKey("");
    setAddVisible(true);

    clearAllFields();

    form.resetFields();
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const editCampaign = async(record)=> {
  
    setFBPixelId(record.pixelId);
    setFBPixcelAccessToken(record.accessToken);
    setFBPixelRemark(record.remark);
    setFBLastRunTime(record.lastRunTime);
    setFBPixelStatus(record.status);

    let fbEventList = Object.keys(record.eventlist).length > 0 ? record.eventlist.split(','):[];
    setFBSelectedEvent(fbEventList);
    setEditVisible(true);

  }

  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });

  //show 6 column Pixel ID, Access Token, Event List, Remark, Edited Time, Status
  const columns = [
    {
      title: "Pixel ID",
      dataIndex: "pixelId",
      width: "15%",
      //...getColumnSearchProps("pixelId"),
      //ellipsis: true,
    },
    {
      title: "Access Token",
      dataIndex: "accessToken",
      width: "15%",
      ellipsis: true,
      //...getColumnSearchProps("nickname"),
    },
    {
      title: "Event List",
      dataIndex: "eventlist",
      width: "18%",
      // ellipsis: true,
      ...getColumnSearchProps("eventlist"),
    },
    {
      title: "Remark",
      dataIndex: "remark",
      width: "20%",
      ellipsis: true,
      ...getColumnSearchProps("remark"),
    },
    {
      title: "CAPI Last Run",
      dataIndex: "lastRunTime",
      width: "15%",
      ellipsis: true,
      render: (text) => {
        if (text.length) {
           return <span>{FormatDateHHMM(text)}</span>;
        }
      }

    },    
    {
      title: "Edited Time",
      dataIndex: "lastUpdateTime",
      width: "15%",
      render: (text) => {
        return <span>{FormatDateHHMM(text)}</span>;
      }
    },
    // {
    //   title: "A",
    //   dataIndex: "sysCreateTime",
    //   width: "15%",
    //   render: (text, record) => {
    //       let updatedTime = record.sysUpdateTime.includes("1970-01-01") ? null : record.sysUpdateTime;
    //       let latestTime = record.sysCreateTime;

    //       return <div>u:{updatedTime}<br/>c:{latestTime}</div>;
    //   }
    // },
    {
      title: "Status",
      dataIndex: "status",
      width: "10%",
      render: (text)=> {          
          if (text=="Disabled") {
            return <Badge status="error" text="Disabled"/>;
          }else{
            return <Badge status="success" text="Enabled" />;
          }
      },
      filters: [
        {
          text: 'Disabled',
          value: 'Disabled',
        },

        {
          text: 'Enabled',
          value: 'Enabled',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.status.indexOf(value) === 0
    },
    {
      title: "Action",
      dataIndex: "",
      width: "10%",
      render: (text, record)=> {   
        return (       
          <Button 
            type="primary" 
            shape="circle" 
            icon={<EditOutlined />} 
            onClick={() => {
              editCampaign(record);
            }}
          />
        )
      }
    }
  ];

  const isEditing = (record) => record.key === editingKey;

  const edit = async (record) => {
    form.setFieldsValue({
      nickname: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

 const handleEdit = (index)=> {
  if (!FBPixelId || !FBPixelAccessToken ) {
    message.error(
      "An entry is required or has an invalid value. Please correct and try again!"
    );
    return;
  }

  loadingTrue(index);

  let fbPxStatus = FBPixelStatus =="Enabled" ? "1": "0" ;
  let fbSelectEvents = (FBSelectedEvent == "" || FBSelectedEvent ==null) ? "" :FBSelectedEvent;

  let requestData = {
    pixelid: FBPixelId,
    accesstoken: FBPixelAccessToken,
    remark: FBPixelRemark,
    status: fbPxStatus,
    updateby: username,
    eventlist: fbSelectEvents,
  }
  

  mktgFBCampaignUpdate(
    requestData
  )
  .then((response) => response.json())
  .then((json) => {
    setEditVisible(false);
    if (json.status === "ok") {
      message.success(json.msg);
      campaignList();
    } else {
      loadingTrue(index);
      message.error(json.msg);
    }
    loadingFalse(index);
    //modalForm.current.resetFields();
    clearAllFields();
  })
  .catch((error) => {
    console.log("error: ", error);
    message.error(
      "An unexpected error occurred. Contact your system administrator "
    );
  });
 }

  const handleAdd = (index) => {
    
    if (!FBPixelId || !FBPixelAccessToken ) {
      message.error(
        "An entry is required or has an invalid value. Please correct and try again!"
      );
      return;
    }

    loadingTrue(index);

    let fbPxStatus = FBPixelStatus =="Enabled" ? "1": "0" ;
    let requestData = {
      pixelid: FBPixelId,
      accesstoken: FBPixelAccessToken,
      remark: FBPixelRemark,
      status: fbPxStatus,
      createby: username,
      eventlist: FBSelectedEvent
    }

    mktgFBCampaignNew(
         requestData
    )
      .then((response) => response.json())
      .then((json) => {
        setAddVisible(false);
        if (json.status === "ok") {
          campaignList();
          message.success(json.msg);
          clearAllFields();
        } else {
          loadingTrue(index);
          message.error(json.msg);
          clearAllFields();
        }
        loadingFalse(index);

        //modalForm.current.resetFields();
      })
      .catch((error) => {
        console.log("error: ", error);
        message.error(
          "An unexpected error occurred. Contact your system administrator "
        );
      });
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "userType"
            ? "userTypeSelect"
            : col.dataIndex === "text",
        // inputType: col.dataIndex === "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  // const mergedColumns = columns.map((col) => {
  //   if (!col.editable) {
  //     return col;
  //   }

  //   return {
  //     ...col,
  //     onCell: (record) => ({
  //       record,
  //       inputType: col.dataIndex === "text",
  //       dataIndex: col.dataIndex,
  //       title: col.title,
  //       editing: isEditing(record),
  //     }),
  //   };
  // });

  

  const onCancel = () => {
    setAddVisible(false);
    clearAllFields();
    //modalForm.current.resetFields();
  };

  const onCancelEdit = () => {
    setEditVisible(false);
    clearAllFields();
  }
  const styles = {
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
    },
    refreshBtn: {
      marginLeft: 10,
    },
    tabelContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    formItem: {
      width:"80%"
    },
    datePickerStyle: {
      width: 260,
    },
    hide : {
      display:"none",
    },
    memberSearchStyle:{
      width:180,
    }
  };

  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <div>
        <Modal title="New FB Account"  visible={addVisible} 
        onOk={handleAdd}
        onCancel={() => {
          onCancel();
        }}
        maskClosable={false}
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "danger", loading: loadings[0], title: "Save" }}
        okText="Save"
        >
          {onCreateFBCampaign()}
        </Modal>
        <Modal title= "Edit FB Account" visible={editVisible} 
        onOk={handleEdit}
        onCancel={() => {
          // setAddMemberModal(false);
          // clearAllFields();
          onCancelEdit();
        }}
        maskClosable={false}
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "danger", loading: loadings[0], title: "Save" }}
        okText="Save"
        >
          {onEditFBCampaign()}
        </Modal>
      </div>

      <PageHeader ghost={false}
      >
        <div>
          <Button key="1" type="primary" onClick={showModal}>
            Add New FB Account
          </Button>
        </div>
      </PageHeader>
      <Form form={form} component={false}>
        <Table
          loading={tableLoadings}
          scroll={{ x: "100%", y: null }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={originData}
          columns ={columns}
          //columns={mergedColumns}
          rowClassName="editable-row"
          // pagination={{
          //   onChange: cancel,
          // }}

          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: settings.pageSizeOptions,
            style: styles.paginationStyle,
            alignmentBottom: 'right',
          }}
          style={{ overflow: "auto", marginLeft: 20 }}
        />
      </Form>
    </div>
  );
}
