import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { reportShareHolder } from "../../../config/networkConfig";
import {
  message,
  Table,
  PageHeader,
  DatePicker,
  Button,
  Breadcrumb,
  Statistic,
  Row,
  Card,
} from "antd";
import { isMobile } from "react-device-detect";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import {settings} from "../../../config/appConfig";
import { FormatData} from "../../../library/numbers";
import moment from "moment";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";

export default function ShareholderReport() {
  const { userId } = useSelector((state) => state.login);
  const [report, setReport] = useState("");
  const [filter, setFilter] = useState("");
  const [reportDetail,setReportDetail] = useState("");
  const [filterDetail, setFilterDetail] = useState("");
  const [date, setDate] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [share, setShare] = useState(0);
  const reportDateFrom = useRef(null);
  const reportDateTo = useRef(null);

  useEffect(() => {
    //reportList();
    //reportShareDetailList();
    // eslint-disable-next-line
  }, []);

  const reportList = () => {
    var count = 0;
    var countDetail = 0;
    reportShareHolder({ userId: userId , datefrom: date.format("YYYY-MM-DD"), dateto: dateTo.format("YYYY-MM-DD")})
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
          const report = Object.keys(json.data.summary).length
            ? json.data.summary.map((data) => {
                return {
                  key: ++count,
                  dateTime: data.dateTime,
                  category: data.Category,
                  turnover:data.turnover,
                  winloss:data.winloss,
                  comm: data.comm,
                  profit: data.profit
                };
              })
            : [];

            const reportDetail = Object.keys(json.data.detail).length
            ? json.data.detail.map((data) => {
                return {
                  key: ++countDetail,
                  dateTime: data.dateTime,
                  gameType: data.GameType,
                  selfTurnover:data.SelfTurnover,
                  refererTurnover:data.RefererTurnover,
                  totalShares: data.TotalShares,
                };
              })
            : [];

          const filter = report.filter(
            (data) => data.dateTime // === report[0].dateTime
          );

          const filterDetail = reportDetail.filter(
            (data) => data.dateTime // === reportDetail[0].dateTime
          );

          const getTotalShare = filterDetail.map((data) => data.totalShares);
          var shareTotal = getTotalShare.reduce(function (previous, current) {
            return previous + current;
          }, 0);
          setShare(shareTotal);
          setReport(report);
          setFilter(filter);

          setReportDetail(reportDetail);
          setFilterDetail(filterDetail);

        } 
        else {
          alertData.title = "Failed";
          alertData.msg = "Connection not stable, please try again !";
          sharedFailAlert(alertData);
        }
      });
  };

  const reportShareDetailList = () => {
    // reportShareDetail({ userId: userId })
    //   .then((response) => response.json())
    //   .then((json) => {
    //     console.log("json: ", json);
    //   });
  };

  const numFormat = (num) => {
    num = isNaN(num)? 0 : num;
    return parseFloat(num).toFixed(settings.decimalScale);
  }


  const description = (data) => {
    return (
      <div style={styles.decContainer}>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Turnover:</p>
          <p>{data.turnover}</p>
        </div>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Position Taking Turnover:</p>
          <p>{data.ptTurnover}</p>
        </div>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Referer Turnover:</p>
          <p>{data.refererTurnover}</p>
        </div>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Share:</p>
          <p>{data.shares}</p>
        </div>
      </div>
    );
  };

  const columnsDetail = [
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "dateTime",
      width: 200,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Game Type",
      dataIndex: "gameType",
      width: 200,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Self Turnover",
      dataIndex: "selfTurnover",
      width: 200,
      ellipsis: true,
      render:function(text) {
        return FormatData(text);
      }
    },
    {
      align: "center",
      title: "Referer Turnover",
      dataIndex: "refererTurnover",
      width: 200,
      ellipsis: true,
      render:function(text) {
        return FormatData(text);
      }
    },
    {
      align: "center",
      title: "Total Share",
      dataIndex: "totalShares",
      width: 200,
      ellipsis: true,
      render:function(text) {
        return FormatData(text);
      }
    },
  ];

  const columns = [
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "dateTime",
      width: 170,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Game Type",
      dataIndex: "category",
      width: 200,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Turnover",
      dataIndex: "turnover",
      width: 200,
      ellipsis: true,
      render:function(text,record,index) {
        return numFormat(text);
      }
    },
    {
      align: "center",
      title: "Win / Loss",
      dataIndex: "winloss",
      width: 200,
      ellipsis: true,
      render:function(text,record,index) {
        return numFormat(text);
      }
    },
    {
      align: "center",
      title: "Commision",
      dataIndex: "comm",
      width: 200,
      ellipsis: true,
      render:function(text,record,index) {
        return numFormat(text);
      }
    },
    {
      align: "center",
      title: "Profit",
      dataIndex: "profit",
      width: 200,
      ellipsis: true,
      render:function(text,record,index) {
        return numFormat(text);
      }
    },
  ];

  const mobileColumns = [
    {
      align: "center",
      title: "Data / Time",
      dataIndex: "dateTime",
      width: "50%",
      ellipsis: true,
    },
    {
      title: "Game Type",
      dataIndex: "category",
      width: "50%",
      ellipsis: true,
    },
  ];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    if (report.length == 0) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }
    
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = `Shareholder Report (${date.format('YYYY-MM-DD')} to ${dateTo.format('YYYY-MM-DD')})\n\n\nShare Total : ${FormatData(share)}`;
    const titleCompany =`Company`;
    const titleDetail = `My Summary`;

    const headers = [
      [
        "ID",
        "Date / Time",
        "Game Type",
        "Turnover",
        "Win / Loss",
        "Commission",
        "Profit"
      ],
    ];   

    const headerDetail = [
      [
        "ID",
        "Date / Time",
        "Game Type",
        "Self Turnover",
        "Referer Turnover",
        "Total Share",
      ]
    ]

    var filteredReport = [...filter];
    const data = filteredReport.map((data) => {
      return [
        data.key,
        data.dateTime,
        data.category,
        FormatData(data.turnover),
        FormatData(data.winloss),
        FormatData(data.comm),
        FormatData(data.profit),
      ];
    });

    var filteredDetailReport = [...filterDetail];
    const dataDetail = filteredDetailReport.map((data) => {
    // var reportDetail = [...reportDetail];
    // const dataDetail = reportDetail.map((data) => {
       return [
         data.key,
         data.dateTime,
         data.gameType,
         FormatData(data.SelfTurnover),
         FormatData(data.refererTurnover),
         FormatData(data.totalShares),
       ];
    });


    let content = {
      startY: 150,
      head: headers,
      body: data,
      headStyles: { fillColor: "#1DA57A" },
    };


    let contentDetail = {
      startY: 150,
      head: headerDetail,
      body: dataDetail,
      headStyles: { fillColor: "#1DA57A" },
    };

    doc.text(title, marginLeft, 40);

    doc.text(titleCompany,marginLeft,140);
    doc.autoTable(content);    
    let finalY = doc.previousAutoTable.finalY; 
    contentDetail.startY = finalY+30;
    doc.text(titleDetail,marginLeft,finalY+20);
    doc.autoTable(contentDetail);
    doc.save("report.pdf");
  };

  const datePicker = (date, dateString) => {
    setDate(date);
  };

  const datePicker2 = (date, dateString) => {
    setDateTo(date);
  };

  const searchBar = () => {

    if (!date) {
      reportDateFrom.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date From !";
      sharedErrorAlert(alertData);
      return;
    }

    if (!dateTo) {
      reportDateTo.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date To !";
      sharedErrorAlert(alertData);
      return;
    }

    reportList();
    // if (!date) {
    //   return;
    // }
    // var newReport = [...report];
    // const filter = newReport.filter(
    //   (data) => data.dateTime === date.format("YYYY-MM-DD")
    // );

    // const getTotalShare = filter.map((data) => data.totalShare);
    // var shareTotal = getTotalShare.reduce(function (previous, current) {
    //   return previous + current;
    // }, 0);

    // setShare(shareTotal);
    // setFilter(filter);
  };

  const resetBtn = () => {
    setDate(null);
    setDateTo(null);
    setShare(0);
    setReport('');
    setReportDetail('');
    setFilter('');
    setFilterDetail('');
  };

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      //   path: "first",
      breadcrumbName: "Report",
    },
    {
      path: "shareholderReport",
      breadcrumbName: "Shareholder Report",
    },
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 2;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHearderContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    searchBtn: {
      marginLeft: 30,
    },
    resetBtn: {
      marginLeft: 10,
    },
    datePickerStyle: {
      width: 300,
      borderRadius: 10,
    },
    mobilePageHearderContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    eachButtonContainer: {
      alignItems: "center",
      display: "flex",
      height: 50,
      marginLeft: 15,
      marginRight: 15,
      justifyContent: "center",
    },
    mobileSearchResetBtn: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      height: 50,
      alignItems: "center",
      paddingLeft: 60,
      paddingRight: 60,
    },
    btnContainer: {
      width: "100vw",
      padding: 10,
    },
    pdfStyle: {
      borderRadius: 10,
      width: 1200,
    },
    searchResetStyle: {
      borderRadius: 10,
      marginLeft: 10,
    },
    decStyle: {
      display: "flex",
      flexDirection: "row",
    },
    decLable: {
      width: 200,
    },
    decContainer: {
      paddingLeft: 75,
    },
    webRow: {
      backgroundColor: "#FFFFFF",
      paddingTop: 50,
      paddingLeft: 30,
      paddingBottom: 50,
    },
    mobileRow: {
      backgroundColor: "#FFFFFF",
      paddingLeft: 30,
    },
    cardStyle: {
      borderRadius: 20,
      height: 160,
      width: 280,
      border: "none",
    },
    headStyles: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 25,
      height: 80,
    },
    bodyStyle: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 80,
    },
  };

  return (
    <div className="eachPageContent">
      {isMobile ? (
        <div>
          <PageHeader ghost={false}>
            <div>
              <Breadcrumb
                separator=">"
                itemRender={itemRender}
                routes={routes}
              />
              <div style={styles.btnContainer}>
                <div style={styles.eachButtonContainer}>
                  <Button
                    type={"primary"}
                    onClick={exportPDF}
                    style={styles.pdfStyle}
                  >
                    Download PDF
                  </Button>
                </div>
                <div style={styles.eachButtonContainer}>
                  <DatePicker
                    style={styles.datePickerStyle}
                    onChange={datePicker}
                    value={date}
                    ref={reportDateFrom}
                    placeholder="Select Date From"
                    disabledDate={(current)=> {
                      return current > moment().subtract(1, 'day');
                    }}
                  />
                  <DatePicker
                    style={styles.datePickerStyle}
                    onChange={datePicker2}
                    value={dateTo}
                    ref={reportDateTo}
                    placeholder="Select Date To"
                    disabledDate={(current)=> {
                      let dateFrom = date;
                      if (dateFrom) {
                        return current < dateFrom || current > moment().subtract(1, 'day');
                      }else {
                        return  current > moment().subtract(1, 'day');
                      }
                    }}
                  />
                  <Button
                    type={"primary"}
                    onClick={searchBar}
                    style={styles.searchResetStyle}
                    icon={<SearchOutlined />}
                  />

                  <Button
                    onClick={resetBtn}
                    style={styles.searchResetStyle}
                    icon={<UndoOutlined />}
                  />
                </div>
              </div>
              <Row style={styles.mobileRow}>
                <Card
                  title="Total Share"
                  style={styles.cardStyle}
                  headStyle={styles.headStyles}
                  bodyStyle={styles.bodyStyle}
                >
                  <Statistic
                    // precision={settings.decimalScale}
                    value={FormatData(share)}
                    valueStyle={{ fontSize: 40 }}
                  />
                </Card>
              </Row>
              <div style={styles.tableContainer}>
                <Table
                  columns={mobileColumns}
                  dataSource={filter}
                  expandable={{
                    expandedRowRender: (record) => (
                      <p style={{ margin: 0 }}>{record.description}</p>
                    ),
                    rowExpandable: (record) => record.name !== "Not Expandable",
                  }}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20"],
                    style: styles.paginationStyle,
                  }}
                />
              </div>
            </div>
          </PageHeader>
        </div>
      ) : (
        <div>
          <PageHeader
            ghost={false}
            title="Shareholder Report"
            extra={[
              <DatePicker
                key="1"
                style={styles.datePickerStyle}
                onChange={datePicker}
                value={date}
                ref={reportDateFrom}
                placeholder="Select Date From"
                disabledDate={(current)=> {
                  return current > moment().subtract(1, 'day');
                  //return current > moment().endOf('day');
                }}
              />,
              <DatePicker
                style={styles.datePickerStyle}
                onChange={datePicker2}
                value={dateTo}
                ref={reportDateTo}
                placeholder="Select Date To"
                disabledDate={(current)=> {
                  let dateFrom = date;
                  if (dateFrom) {
                    return current < dateFrom || current > moment().subtract(1, 'day');
                  }else {
                    return  current > moment().subtract(1, 'day');
                  }
                }}
              />,
              <Button
                key="2"
                type={"primary"}
                style={styles.searchBtn}
                onClick={searchBar}
              >
                Search
              </Button>,
              <Button key="3" style={styles.resetBtn} onClick={resetBtn}>
                Reset
              </Button>,
              <Button key="4" type={"primary"} onClick={exportPDF}>
                Download PDF
              </Button>,
            ]}
          />
          <Row style={styles.webRow}>
            <Card
              title="Total Share"
              style={styles.cardStyle}
              headStyle={styles.headStyles}
              bodyStyle={styles.bodyStyle}
            >
              <Statistic
                //precision={settings.decimalScale}
                value={FormatData(share)}
                valueStyle={{ fontSize: 40 }}
              />
            </Card>
          </Row>
          <div style={styles.tableContainer}>
            <span>Company</span>
            <Table
              columns={columns}
              dataSource={report}
              scroll={{ x: 100, y: null }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20"],
                style: styles.paginationStyle,
              }}
            />
            <span>My Summary</span>
            <Table
              columns={columnsDetail}
              dataSource={reportDetail}
              scroll={{ x: 100, y: null }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20"],
                style: styles.paginationStyle,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
