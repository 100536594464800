import React, { useState, useEffect, useRef } from "react";
import {
  // Table,
  message,
  PageHeader,
  Button,
  Input,
  DatePicker,
  Card,
  Badge,
  Row,
  Col,
  Tooltip,
  Modal,
  Form,
  Select,
  Tabs,
  Popconfirm,
} from "antd";
import {
    reportDailySales,
} from "../../../config/networkConfig";
import { Table } from "ant-table-extensions";
import { useLocation, useHistory }from "react-router-dom";
import {settings} from "../../../config/appConfig";
import Highlighter from "react-highlight-words";
import { FormatDataMobile } from "../../../library/numbers";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { 
  UserOutlined,
  SearchOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { repDownlineVw } from "../../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";
import style from "../../../resource/style/style";
import { objectOf } from "prop-types";


export default function ReportSalesDaily() {
    const {TabPane} = Tabs;
    const { companyId , username, role, userId, issuper} = useSelector((state) => state.login);


    // Daily Sales Data 
    const [dailySales1Data,       setDailySales1Data] = useState([]);
    const [dailySales2Data,       setDailySales2Data] = useState([]);
    const [dailySales3Data,       setDailySales3Data] = useState([]);

    const [tab1Title,                   setTab1Title] = useState("");
    const [tab2Title,                   setTab2Title] = useState("");
    const [tab3Title,                   setTab3Title] = useState("");

    const [table1Loading ,          setTable1Loading] = useState(false);
    const [table2Loading ,          setTable2Loading] = useState(false);
    const [table3Loading ,          setTable3Loading] = useState(false);

    const [trxLoading,                 setTrxLoading] = useState(false);
    const [trxDateFrom,               setTrxDateFrom] = useState("");
    const [trxDateTo,                   setTrxDateTo] = useState("");
      
    const reportDateFrom = useRef(null);
    const reportDateTo = useRef(null);

    useEffect(() => {
        assignTabName();
        // setTab1();
    }, []);

    //#region Functions
    const onClickExportBtn = ()=> {

      if (dailySales1Data.length === 0 ) {
        alertData.title = "Error";
        alertData.msg = "No data to download !";
        sharedErrorAlert(alertData);
        return;
      }
  
      document.getElementById("export-button-1").click();
    }

    const resetBtn = () => {
      setTrxDateFrom(null);
      setTrxDateTo(null);
      setDailySales1Data([]);
    };
  
    const trxDatePicker = (date, dateString) => {
      setTrxDateFrom(date);
    };
  
    const trxDatePicker2 = (date, dateString) => {
      setTrxDateTo(date);
    };
  
    const searchBar = () => {
      if (!trxDateFrom) {
        reportDateFrom.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Date From !";
        sharedErrorAlert(alertData);
        return;
      }
  
      if (!trxDateTo) {
        reportDateTo.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Date To !";
        sharedErrorAlert(alertData);
        return;
      }
  
      if (trxDateTo < trxDateFrom || trxDateFrom > trxDateTo) {
        alertData.title = "Error";
        alertData.msg = "Please select a valid Date Range !";
        sharedErrorAlert(alertData);
        return;
      }
  
  
      setTrxLoading(true);
      setTab1();
    }

    // const onChangeTab = (key)=> {
    //     if (key == 2) {
    //         setTab2();
    //     }

    //     if (key == 3) {
    //         setTab3();
    //     }
    // }

    const assignTabName = () => {

        const tab1Tile    = `${moment(getTab1Date().datefrom).format("MMM-YYYY")} - ${moment(getTab1Date().dateto).format("MMM-YYYY")}`;
        // const tab2Tile    = `${moment(getTab2Date(getTab1Date().datefrom).dateto).format("MMM-YYYY")}`;
        // const tab3Tile    = `${moment(getTab2Date(tab2Tile).dateto).format("MMM-YYYY")}`;

        setTab1Title(tab1Tile);
        // setTab2Title(tab2Tile);
        // setTab3Title(tab3Tile);

    }

    const getTab1Date = ()=> {
        const currdate    = new Date();
        const dateto       = new moment(currdate).format("YYYY-MM-DD");
        const datefrom     = moment(dateto).subtract(1,"month").format("YYYY-MM-01");
        return {
            dateto  : dateto,
            datefrom: datefrom
        }
    }

    // const getTab2Date = (date)=> {
    //     const newDate = new moment(date);
    //     const dateto   = moment(newDate).subtract(1,"days").format("YYYY-MM-DD");
    //     const datefrom = moment(newDate).subtract(1,"month").format("YYYY-MM-01");

    //     return {
    //         dateto  : dateto,
    //         datefrom: datefrom
    //     }
    // }

    const setTab1 = async () => {
        setTable1Loading(true);  

        // let requestData = {
        //     datefrom : getTab1Date().datefrom ,
        //     dateto   : getTab1Date().dateto,
        // }

        let requestData = {
          datefrom : moment(trxDateFrom).format("YYYY-MM-DD"),
          dateto: moment(trxDateTo).format("YYYY-MM-DD"),
        }

        let respTab1 = await reportDailySales(requestData);
        let respDailySales = await respTab1.json();

        let totalRegUser = 0, totalInvUser = 0, totalDepCnt = 0, totalUniqDep = 0 , totalDepAmt = 0, totalMaxDepAmt = 0 , totalDepFirstCnt = 0 , totalDepFirstAmt = 0;
        let totalDepRegCnt = 0, totalDepRegAmt = 0 , totalAvgDep = 0 ;
        let ctr = 0;

        if (respDailySales.status === "ok" ) {

            const dailySalesData1 = Object.keys(respDailySales.data).length ?
                respDailySales.data.map((obj)=> {
                    return{
                        key                  : ctr++,
                        trxnDate             : obj.TrxnDate,
                        registerUser         : obj.RegisterUser,
                        inviteUser           : obj.InviteUser,
                        depCount             : obj.DepCount,
                        uniqueDep            : obj.UniqueDep,
                        depositAmt           : obj.DepositAmt,
                        maxDepositAmt        : obj.MaxDepositAmt,
                        depositFirstCount    : obj.DepositFirstCount,
                        depositFirstAmount   : obj.DepositFirstAmount,
                        depositRegularCount  : obj.DepositRegularCount,
                        depositRegularAmount : obj.DepositRegularAmount,
                        avgDeposit           : FormatDataMobile(parseFloat(obj.DepositAmt)/parseFloat(obj.DepCount))
                    }
                })
            :[];

            /* Summary Row */
          let newDailySales = [...dailySalesData1];

          newDailySales.forEach(({ registerUser, inviteUser, depCount, uniqueDep, depositAmt, maxDepositAmt, depositFirstCount, depositFirstAmount, depositRegularCount, depositRegularAmount, avgDeposit }) => {
            totalRegUser      += registerUser;
            totalInvUser      += inviteUser;
            totalDepCnt       += depCount;
            totalUniqDep      += uniqueDep;
            totalDepAmt       += depositAmt;
            totalMaxDepAmt    += maxDepositAmt;
            totalDepFirstCnt  += depositFirstCount;
            totalDepFirstAmt  += depositFirstAmount;
            totalDepRegCnt    += depositRegularCount;
            totalDepRegAmt    += depositRegularAmount;
            totalAvgDep       += parseFloat(avgDeposit);
          });

          let maxKey = parseInt(Math.max.apply(Math, newDailySales.map(function(o) { return o.key; })))+1;

          newDailySales= [
              ...newDailySales,
              {
                key: maxKey,
                trxnDate: "Summary",
                registerUser         : totalRegUser,
                inviteUser           : totalInvUser,
                depCount             : totalDepCnt,
                uniqueDep            : totalUniqDep,
                depositAmt           : totalDepAmt,
                maxDepositAmt        : FormatDataMobile(totalMaxDepAmt),
                depositFirstCount    : totalDepFirstCnt,
                depositFirstAmount   : FormatDataMobile(totalDepFirstAmt),
                depositRegularCount  : totalDepRegCnt,
                depositRegularAmount : totalDepRegAmt,
                avgDeposit           : FormatDataMobile(totalAvgDep),

              },
            ];

            setDailySales1Data(newDailySales);

            
        }

        setTable1Loading(false);
    }

    // const setTab2 = async () => {
    //     setTable2Loading(true);

    //     const newDate = getTab2Date(getTab1Date().datefrom);

    //     let requestData = {
    //         datefrom : newDate.datefrom,
    //         dateto   : newDate.dateto,
    //     }

    //     let respTab1 = await reportDailySales(requestData);
    //     let respDailySales = await respTab1.json();

    //     let totalRegUser = 0, totalInvUser = 0, totalDepCnt = 0, totalUniqDep = 0 , totalDepAmt = 0, totalMaxDepAmt = 0 , totalDepFirstCnt = 0 , totalDepFirstAmt = 0;
    //     let totalDepRegCnt = 0, totalDepRegAmt = 0 , totalAvgDep = 0 ;
    //     let ctr = 0;

    //     if (respDailySales.status === "ok" ) {

    //         const dailySalesData1 = Object.keys(respDailySales.data).length ?
    //             respDailySales.data.map((obj)=> {
    //                 return{
    //                     key                  : ctr++,
    //                     trxnDate             : obj.TrxnDate,
    //                     registerUser         : obj.RegisterUser,
    //                     inviteUser           : obj.InviteUser,
    //                     depCount             : obj.DepCount,
    //                     uniqueDep            : obj.UniqueDep,
    //                     depositAmt           : obj.DepositAmt,
    //                     maxDepositAmt        : obj.MaxDepositAmt,
    //                     depositFirstCount    : obj.DepositFirstCount,
    //                     depositFirstAmount   : obj.DepositFirstAmount,
    //                     depositRegularCount  : obj.DepositRegularCount,
    //                     depositRegularAmount : obj.DepositRegularAmount,
    //                     avgDeposit           : FormatDataMobile(parseFloat(obj.DepositAmt)/parseFloat(obj.DepCount))
    //                 }
    //             })
    //         :[];

    //         /* Summary Row */
    //       let newDailySales = [...dailySalesData1];

    //       newDailySales.forEach(({ registerUser, inviteUser, depCount, uniqueDep, depositAmt, maxDepositAmt, depositFirstCount, depositFirstAmount, depositRegularCount, depositRegularAmount, avgDeposit }) => {
    //         totalRegUser      += registerUser;
    //         totalInvUser      += inviteUser;
    //         totalDepCnt       += depCount;
    //         totalUniqDep      += uniqueDep;
    //         totalDepAmt       += depositAmt;
    //         totalMaxDepAmt    += maxDepositAmt;
    //         totalDepFirstCnt  += depositFirstCount;
    //         totalDepFirstAmt  += depositFirstAmount;
    //         totalDepRegCnt    += depositRegularCount;
    //         totalDepRegAmt    += depositRegularAmount;
    //         totalAvgDep       += parseFloat(avgDeposit);
    //       });

    //       let maxKey = parseInt(Math.max.apply(Math, newDailySales.map(function(o) { return o.key; })))+1;

    //       newDailySales= [
    //           ...newDailySales,
    //           {
    //             key: maxKey,
    //             trxnDate: "Summary",
    //             registerUser         : totalRegUser,
    //             inviteUser           : totalInvUser,
    //             depCount             : totalDepCnt,
    //             uniqueDep            : totalUniqDep,
    //             depositAmt           : totalDepAmt,
    //             maxDepositAmt        : totalMaxDepAmt,
    //             depositFirstCount    : totalDepFirstCnt,
    //             depositFirstAmount   : totalDepFirstAmt,
    //             depositRegularCount  : totalDepRegCnt,
    //             depositRegularAmount : totalDepRegAmt,
    //             avgDeposit           : totalAvgDep

    //           },
    //         ];

    //         setDailySales2Data(newDailySales);
    //     }

    //     setTable2Loading(false);
    // }

    // const setTab3 = async () => {
    //     setTable3Loading(true);

    //     const newDate = getTab2Date(getTab2Date(getTab1Date().datefrom).datefrom);

    //     let requestData = {
    //         datefrom : newDate.datefrom,
    //         dateto   : newDate.dateto,
    //     }

    //     let respTab1 = await reportDailySales(requestData);
    //     let respDailySales = await respTab1.json();

    //     let totalRegUser = 0, totalInvUser = 0, totalDepCnt = 0, totalUniqDep = 0 , totalDepAmt = 0, totalMaxDepAmt = 0 , totalDepFirstCnt = 0 , totalDepFirstAmt = 0;
    //     let totalDepRegCnt = 0, totalDepRegAmt = 0 , totalAvgDep = 0 ;
    //     let ctr = 0;


    //     if (respDailySales.status === "ok" ) {

    //         const dailySalesData1 = Object.keys(respDailySales.data).length ?
    //             respDailySales.data.map((obj)=> {
    //                 return{
    //                     key                  : ctr++,
    //                     trxnDate             : obj.TrxnDate,
    //                     registerUser         : obj.RegisterUser,
    //                     inviteUser           : obj.InviteUser,
    //                     depCount             : obj.DepCount,
    //                     uniqueDep            : obj.UniqueDep,
    //                     depositAmt           : obj.DepositAmt,
    //                     maxDepositAmt        : obj.MaxDepositAmt,
    //                     depositFirstCount    : obj.DepositFirstCount,
    //                     depositFirstAmount   : obj.DepositFirstAmount,
    //                     depositRegularCount  : obj.DepositRegularCount,
    //                     depositRegularAmount : obj.DepositRegularAmount,
    //                     avgDeposit           : FormatDataMobile(parseFloat(obj.DepositAmt)/parseFloat(obj.DepCount))
    //                 }
    //             })
    //         :[];

    //         /* Summary Row */
    //       let newDailySales = [...dailySalesData1];

    //       newDailySales.forEach(({ registerUser, inviteUser, depCount, uniqueDep, depositAmt, maxDepositAmt, depositFirstCount, depositFirstAmount, depositRegularCount, depositRegularAmount, avgDeposit }) => {
    //         totalRegUser      += registerUser;
    //         totalInvUser      += inviteUser;
    //         totalDepCnt       += depCount;
    //         totalUniqDep      += uniqueDep;
    //         totalDepAmt       += depositAmt;
    //         totalMaxDepAmt    += maxDepositAmt;
    //         totalDepFirstCnt  += depositFirstCount;
    //         totalDepFirstAmt  += depositFirstAmount;
    //         totalDepRegCnt    += depositRegularCount;
    //         totalDepRegAmt    += depositRegularAmount;
    //         totalAvgDep       += parseFloat(avgDeposit);
    //       });

    //       let maxKey = parseInt(Math.max.apply(Math, newDailySales.map(function(o) { return o.key; })))+1;

    //       newDailySales= [
    //           ...newDailySales,
    //           {
    //             key: maxKey,
    //             trxnDate: "Summary",
    //             registerUser         : totalRegUser,
    //             inviteUser           : totalInvUser,
    //             depCount             : totalDepCnt,
    //             uniqueDep            : totalUniqDep,
    //             depositAmt           : totalDepAmt,
    //             maxDepositAmt        : totalMaxDepAmt,
    //             depositFirstCount    : totalDepFirstCnt,
    //             depositFirstAmount   : totalDepFirstAmt,
    //             depositRegularCount  : totalDepRegCnt,
    //             depositRegularAmount : totalDepRegAmt,
    //             avgDeposit           : totalAvgDep

    //           },
    //         ];

    //         setDailySales3Data(newDailySales);
    //     }

    //     setTable3Loading(false);
    // }
    //#endregion
  
  
    ////#region Components
    ////#endregion









    ////#endregion
    

    ////#region  Columns
    const columns = [
      {
        title: "DATE",
        dataIndex: "trxnDate",
        sorter: (a, b) => new Date(a.trxnDate) - new Date(b.trxnDate),
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(record))
        }
      },
      {
        title: "REG USER",
        dataIndex: "registerUser",
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(record))
        }
        
      },
      {
        title: "INVITE USER",
        dataIndex: "inviteUser",
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(record))
        }
      },
      {
        title:"DEP #",
        dataIndex:"depCount",
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(record))
        }
      },
      {
        title: "UNIQUE DEP",
        dataIndex:"uniqueDep",
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(record))
        }
      },
      {
        title:"DEP AMT",
        dataIndex:"depositAmt",
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:<span style={style.noStyle}>{FormatDataMobile(record)}</span>)
        }
      },
      {
        title:"MAX DEP",
        dataIndex:"maxDepositAmt",
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(FormatDataMobile(record)))
        }
      },
      {
        title: "NEW UNIQUE DEP",
        dataIndex:"depositFirstCount",
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(FormatDataMobile(record)))
        }
      },
      {
        title: "NEW UNIQUE DEP AMT",
        dataIndex:"depositFirstAmount",
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(FormatDataMobile(record)))
        }
      },
      {
        title: "AVG DEP PER TRANSACTION",
        dataIndex:"avgDeposit",
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(FormatDataMobile(record)))
        }
      }
    ];



    ////#endregion

    return (
      <div>
        <PageHeader ghost={false}>
          <div style={style.pageHeaderContainer}>
            <div style={{float:"left"}}>
              {/* <Button
                style={style.refreshBtn}
                type="primary"
                onClick={() => {
                  setTab1();
                }}
              >
                Refresh Page
              </Button> */}
              <DatePicker
                  key="1"
                  style={{...style.datePickerStyle, ...style.refreshBtn}}
                  format="YYYY-MM-DD"
                  onChange={trxDatePicker}
                  value={trxDateFrom}
                  ref={reportDateFrom}
                  refs
                  placeholder={"Select Date From"}
                  disabledDate={(current)=> {
                    return current > moment();//.subtract(1, 'day');
                  }}
              />
              <DatePicker
                key="2"
                style={{...style.datePickerStyle, ...style.refreshBtn}}
                format="YYYY-MM-DD"
                onChange={trxDatePicker2}
                value={trxDateTo}
                ref={reportDateTo}
                placeholder={"Select Date To"}
                disabledDate={(current)=> {
                  let dateFrom = trxDateFrom;
                  if (dateFrom) {
                    return current < dateFrom || current > moment();//.subtract(1, 'day');
                  }else {
                    return  current > moment();//.subtract(1, 'day');
                  }
                }}
              />
              <Button
                type={"primary"}
                style={style.searchResetStyle}
                icon={<SearchOutlined
                onClick={searchBar}
                />}
              />
              <Button style={{...style.resetBtn,...style.refreshBtn}} onClick={resetBtn}>
                Reset
              </Button>
              <Button 
                    type={"primary"} 
                    style={style.refreshBtn} 
                    icon={<FileExcelOutlined />}
                    onClick={() => {
                        onClickExportBtn();
                    }}
                    
                >
                  Export to Excel
              </Button>
            </div>
          </div>
        </PageHeader>
        <div style={style.tabelContainer}>      
        {/* <Tabs defaultActiveKey="1" onChange={onChangeTab} id="report-daily-sales-tab" className="report-daily-sales-tab">
            <TabPane tab={tab1Title} key="1" style={style.tabHeader}> */}
                <Table 
                    loading={table1Loading} 
                    columns={columns}
                    dataSource={dailySales1Data}
                    className="reportDailySales"
                    scroll={{ x: 100, y: null }}
                    exportable={true}
                    pagination={{
                      defaultPageSize: 50,
                      showSizeChanger: true,
                      pageSizeOptions: settings.pageSizeOptions,
                      style: style.paginationStyle,
                      // alignmentBottom: 'right',
                    }}
                    exportableProps={{
                      btnProps: {
                        type: "primary",
                        icon: <FileExcelOutlined />,
                        id:"export-button-1",
                        children: <span>Export to Excel</span>,
                        style: {display:"none"}
                      },
                    }}
                />
            {/* </TabPane>
            <TabPane tab={tab2Title} key="2" style={style.tabHeader}>
                <Table dataSource={dailySales2Data} 
                    loading={table2Loading} 
                    columns={bankColumns} 
                    pagination={{
                        defaultPageSize: 50,
                        showSizeChanger: true,
                        pageSizeOptions: ["50"],
                        style: style.paginationStyle,
                        alignmentBottom: 'right',
                      }}
                    />
            </TabPane>
            <TabPane tab={tab3Title} key="3" style={style.tabHeader}>
                <Table dataSource={dailySales3Data} 
                    loading={table3Loading} 
                    columns={bankColumns} 
                    pagination={{
                        defaultPageSize: 50,
                        showSizeChanger: true,
                        pageSizeOptions: ["50"],
                        style: style.paginationStyle,
                        alignmentBottom: 'right',
                      }}
                    />
            </TabPane>
         </Tabs> */}
       </div> 
      </div>        
    );
  }


