import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  message,
  PageHeader,
  Button,
  Input,
  DatePicker,
  Tabs
} from "antd";
import {
    reportApproveTrxn,
    reportApproveTrxnV3,
    getBankList,
} from "../../../config/networkConfig";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import moment from "moment";
import momentTz from "moment-timezone";
import { settings } from "../../../config/appConfig";
import { FormatNumComm} from "../../../library/numbers";
import { reportBankStmt } from "../../../library/permission";
import { SearchOutlined } from "@ant-design/icons";
import style from "../../../resource/style/style";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";
import { BANK_INFO_TYPE } from "../../../common/constants/bankType";

export default function ReportBankStatement() {
    const {TabPane} = Tabs;
    const { issuper, role } = useSelector((state) => state.login);

    const [searchText, setSearchText] = useState("");
    const [searchMobile,setSearchMobile] = useState("");
    const [searchDate, setSearchDate]    = useState("");

    const [trxDateFrom, setTrxDateFrom] = useState("");
    const [trxDateTo, setTrxDateTo] = useState("");
    const rptDateFrom = useRef(null);
    const rptDateTo = useRef(null);
    const [bankTabCount, setBankTabCount] = useState([]);
    const [defaultTab, setDefaultTab] = useState("");
    const [currentTab, setCurrentTab] = useState("");

    //Bank Statement
    const [statementData,         setStatementData] = useState("");
    const [statementPageSize, setStatementPageSize]  = useState(100);
    const [statementPageNum,  setStatementPageNum]  = useState(1);
    const [statementTotal,       setStatementTotal]  = useState(10);
    const [statTableLoading,   setStatTableLoading] = useState(false);

    useEffect(() => {
      getBankListInfo();
    }, []);

    useEffect(() => {
      if (defaultTab){
        getBankStatementV2('', '', defaultTab, statementPageNum, statementPageSize, {"bankAmount": null,"actionStatus": null,"bankDetails": null});
      }
    }, [defaultTab]);

    useEffect(()=> {
      setStatementPageNum(statementPageNum);
      setStatementPageSize(statementPageSize);
      setStatementTotal(statementTotal);
    },[statementPageNum, statementPageSize, statementTotal])

    //#region Functions
    const getBankListInfo = async() => {
      setStatTableLoading(true);
      let requestData = {
        bankType: BANK_INFO_TYPE.DEP,
        columnRequire : ['AccountNum', 'TableName'],
      }
  
      const response = await getBankList(requestData);
      let result = await response.json();
      
      if (result.status == 'ok'){
        setBankTabCount(result.data);
        setDefaultTab(result.data[0].AccountNum);
        setCurrentTab(result.data[0].AccountNum);
      }
      else
      {
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
      }
    }
  
    const getTableName = async(toacctnum) => {
      let bankTable = '';
      const findTableName = Object.keys(bankTabCount).map((x) =>{
      if (bankTabCount[x].AccountNum == toacctnum)
        {
          bankTable = bankTabCount[x].TableName
        }
      })
      return bankTable;
    }

    const onChangeBankTab = (key)=> {
      if (!key){
        alertData.title = "Error";
        alertData.msg = "Something is wrong. Please try again !";
        sharedErrorAlert(alertData);
        setStatementData([]);
      }
      else{
        viewBank(key);
        setCurrentTab(key);
      }
    }

    const viewBank = async (acctnum)=> {
        if (!acctnum){  
          alertData.title = "Error";
          alertData.msg = "Something is wrong. Please try again !";
          sharedErrorAlert(alertData);
          setStatementData([]);
        }
        else{  
        getBankStatementV2('', '', acctnum, statementPageNum, statementPageSize,  {"bankAmount":null,"actionStatus":null,"bankDetails":null});
      }
    }

    const handleTableChange = async (pagination, filters, sorter)=> {
      if (!currentTab){
        alertData.title = "Error";
        alertData.msg = "Something is wrong. Please try again3 !";
        sharedErrorAlert(alertData);
        setStatementData([]);
      }
      else{
        getBankStatementV2('','', currentTab, pagination.current, pagination.pageSize , filters);
      }
    }

    const trxDatePicker = (date, dateString) => {
      setTrxDateFrom(date);
    };
  
    const trxDatePicker2 = (date, dateString) => {
      setTrxDateTo(date);
    };

    const handleSearch = (selectedKeys, confirm) => {
      confirm();
      setSearchText(selectedKeys[0]);
    };
  
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText("");
    };
  
    let searchInput = null;
  
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : false,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) setTimeout(() => searchInput.select());
      },
      render: (text) =>
        text ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          false
        ),
    });

    const viewBankStatement = async () => {
      getBankStatementV2('', '', '4370540750', statementPageNum, statementPageSize, {"bankAmount":null,"actionStatus":null,"bankDetails":null});
    }
  
    const getBankStatementV2 = async(datefrom, dateto, toacctnum, pagenum, pagesize, filters) => {
      setStatTableLoading(true);
      let newStartDate = momentTz.tz(moment(), "Asia/Kuala_Lumpur").subtract(3,"days").format("YYYY-MM-DD HH:mm");  
      let newEndDate = momentTz.tz(moment(), "Asia/Kuala_Lumpur").format("YYYY-MM-DD HH:mm");
      let countDL = 0;

      const bankTable = await getTableName(toacctnum)

      let requestData = {
        datefrom  : newStartDate,
        dateto    : newEndDate,
        page      : pagenum,
        pagesize  : pagesize,
        filters   : filters, 
        toacctnum : toacctnum,
        banktable : bankTable,
      }

      const responseDL = await reportApproveTrxnV3(requestData);
        let resultDL = await responseDL.json();
        if (resultDL.status === "ok") {
          const dlData = Object.keys(resultDL.data.detail).length
          ? resultDL.data.detail.map((x) => {
              return {
                key           : ++countDL,
                pptTrxnId     : x.PptBankTransactionID,
                bankTrxntime  : moment.unix(x.BankTime).format("YYYY-MM-DD HH:mm:ss"),
                //bankTrxntime  : x.BankTrxTime, // old
                sysCreateTime : x.SysCreateTime,
                bankChannel   : x.BankChannel,
                bankType      : x.BankType,
                bankAmount    : x.BankAmount,
                trxnType      : x.TrxnType,
                bank          : x.BankCode,
                bankAccNo     : x.BankAccNo,
                bankDeposit   : x.BankCode+" "+x.BankAccNo,
                bankDetails   : x.BankDetails,
                status        : x.Status,
                actionStatus  : x.Status === 1 || x.Status === 3 ? "SYSTEM" : x.Status === 2 ? "MANUAL" : "-",
                approvedTo    : x.ApprovedTo,
                depositTime   : x.DepositTime,
                trxnId        : x.TransactionID,
                depReqInfo    : x.DepositRequestInfo,
                processtime   : x.processtime,
              };
            })
          : [];

          setStatementData(dlData);
          
          const dlDataSummary = Object.keys(resultDL.data.summary).length
          ? resultDL.data.summary.map((x) => {
              return {
                current : x.current,
                  pagesize: x.pagesize,
                  totalTrxn : x.totalTrxn
              };
            })
            : [];

          setStatementTotal(dlDataSummary?.[0]?.totalTrxn ?? 10 );    
          setStatementData(dlData);
        }
        else{
          alertData.title = "Failed";
          alertData.msg = resultDL.msg;
          sharedFailAlert(alertData);
        }

        setStatTableLoading(false);
    }
    ////#endregion

    ////#region  Columns
    const statementColumns = [
      {
        align: "center",
        title: "#",
        dataIndex: "key",
        width: "5%",
        //fixed:"left",
        render: function(text) {
          return (text);
        }
      },
      {
        align: "center",
        title: "Bank Trxn Time",
        dataIndex: "bankTrxntime",
        width: "8%",
        //fixed:"left",
        render: function(text, record,row) {
          return (text);
        }
      },
      {
        align: "center",
        title: <div>Deposit <br/>Bank</div>,
        dataIndex: "bank",
        width: "5%",
        fixed:"left",
        render: function(text, record,row) {
          return (text);
        }
      },
      {
        align: "center",
        title: "Deposit Bank Account",
        dataIndex: "bankAccNo",
        width: "12%",
        fixed:"left",
        render: function(text, record,row) {
          return (text);
        },
        ...getColumnSearchProps("bankAccNo"),
      },
      {
        align: "center",
        title: <div>From Bank</div>,
        dataIndex: "bankDetails",
        width: "10%",
        render: function(text,record) {
          return (text);
        },
        ...getColumnSearchProps("bankDetails"),
      },
      {
        align: "center",
        title: <div>Deposit Amount</div>,
        dataIndex: "bankAmount",
        width: "10%",
        render: function(text,record) {
          return FormatNumComm(text);
        },
        ...getColumnSearchProps("bankAmount"),
      },
      {
        align: "center",
        title: <div>Approval <br/>Type</div>,
        dataIndex: "actionStatus",
        width: "10%",
        render: function(text,record) {
          return text;
        },
        filters: [
          {
            text: 'System',
            value: 'SYSTEM',
          },
  
          {
            text: 'Manual',
            value: 'MANUAL',
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => record.actionStatus.toLowerCase().indexOf(value.toLowerCase()) === 0 ,
      },
      {
        align: "center",
        title: <div>Approved <br/> To</div>,
        dataIndex: "approvedTo",
        width: "10%",
        render: function(text,record) {
          return (text);
        },
        ...getColumnSearchProps("approvedTo"),
      },
      {
        align: "center",
        title: <div>Approved <br/>By</div>,
        dataIndex: "depReqInfo",
        width: "10%",
        render: function(text,record) {
          if (record.status === 1 || record.status === 3) {
            return "SYSTEM";
          }
          else if (record.status === 2) {
            let depReq = text?.split("||");
            let approvedBy = typeof depReq !== 'undefined' ? depReq[1] || '' : '' ;
            return approvedBy;
          }
        }
      },
      {
        align: "center",
        title: <div>System Request <br/> Time (MY)</div>,
        dataIndex: "depReqInfo",
        width: "8%",
        render: function(text,record) {
          let depReq = text?.split("||");
          let approvedTime = typeof depReq !== 'undefined' ? depReq[2] || '' : '' ;
          return approvedTime;
          
        }
      },
      {
        align: "center",
        title: <div>Processed <br/> Time (MY)</div>,
        dataIndex: "processtime",
        width: "10%",
        render: function(text) {
          return (text === "1970-01-01 08:00:00" ? "" : text);
        }
      }
    ]

    ////#endregion

   
    return (
      <div className="reportDownline0">
        {
          reportBankStmt(role)?
          (<div style={style.authorized}>
            <PageHeader ghost={false}>
              <div style={style.pageHeaderContainer}>
                <div style={{float:"left"}}>
                  <DatePicker
                    key="1"
                    style={{...style.datePickerStyle, ...style.refreshBtn,...style.hide}}
                    format="YYYY-MM-DD HH:mm"
                    showTime={{
                      defaultValue: moment("00:00", "HH:mm"),
                      format: "HH:mm"
                    }}
                    onChange={trxDatePicker}
                    value={trxDateFrom}
                    ref={rptDateFrom}
                    allowClear={false}
                    showToday={false}
                    placeholder={"Select Date From"}
                    disabledDate={(current)=> {
                      return current > moment().endOf('day');
                    }}
                  />
                  <DatePicker
                    key="2"
                    style={{...style.datePickerStyle, ...style.refreshBtn,...style.hide}}
                    format="YYYY-MM-DD HH:mm"
                    showTime={{
                      defaultValue: moment("00:00", "HH:mm"),
                      format: "HH:mm"
                    }}
                    onChange={trxDatePicker2}
                    value={trxDateTo}
                    ref={rptDateTo}
                    allowClear={false}
                    showToday={false}
                    placeholder={"Select Date From"}
                    disabledDate={(current)=> {
                      let dateFr = trxDateFrom;
                      if (dateFr) {
                        return current < dateFr || current > moment().add(1, 'day').add(-1, 'minute');
                      }else {
                        return  current > moment().subtract(1, 'day');
                      }
                    }}
                    defaultPickerValue={()=>{
                        let dateFr = trxDateFrom;
                        if (dateFr) {
                            return dateFr;
                        }
                    }}
                  />
                  <Button 
                  style={{...style.refreshBtn,...style.buttonStyle, ...style.hide}}
                  type="primary"
                  onClick={()=>{
                      viewBankStatement();
                  }}
                  >
                      Reset
                  </Button>
                  <Button
                      style={{...style.refreshBtn,...style.buttonStyle,...style.hide}}
                    type="primary"
                    onClick={() => {
                      setSearchMobile(searchMobile);
  
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </PageHeader>
          </div>)
          :<></>
        }
        <div style={{...style.tabelContainer}} className="body">
        {
          reportBankStmt(role)?
            <>
              <Tabs defaultActiveKey="1" onChange={onChangeBankTab} id="bank-list">
              {bankTabCount.map((item, index) => {
                  return(
                    <TabPane tab={`${item.AccountNum}`} key={`${item.AccountNum}`} id="tab-key">
                      <Table
                          columns={statementColumns}
                          dataSource={statementData}
                          loading={statTableLoading}
                          pagination={{
                          defaultPageSize: 100,
                          showSizeChanger: true,
                          pageSizeOptions: settings.pageSizeOptionsLarge,
                          style: style.paginationStyle,
                          alignmentBottom: 'right',
                          }}
                          onChange={handleTableChange}
                        />
                  </TabPane>
                )})}
              </Tabs>
          </>
          : <div>
            <div style={{textAlign:"center",color:"red",fontSize:"150%"}}><h1 style={{color:"red"}}>Not Allowed to Access This Page</h1></div>
            </div>
        }
        </div>

      </div>
        
    );
  }


