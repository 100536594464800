import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ExpandCollapseComponent from "../../common/components/ExpandCollapseComponent";
import "./modifyColorPage.css";
import {
  deleteCssDraft,
  modifyColorPageLoad,
  saveOrupdateCssDraft,
  updateCssVariables,
} from "../../config/networkConfig";
import { Button, Form, Input, Modal, Spin } from "antd";
import { alertData } from "../../common/constants/sharedMsgBox";
import { sharedErrorAlert } from "../../common/constants/sharedMsgBox";
import { sharedSuccessAlert } from "../../common/constants/sharedMsgBox";
import {
  CaretDownFilled,
  CaretUpFilled,
  ArrowRightOutlined,
} from "@ant-design/icons";

function ModifyColorPage() {
  const sharedElementKey = "shared_element";
  const pageElementKey = "page_element";
  const [showDraftList, setShowDraftList] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [draftListing, setDraftListing] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentRoute, setCurrentRoute] = useState("");
  const [frontendUrl, setFrontendUrl] = useState("");
  const [cssContent, setCssContent] = useState({});
  const cssContentRef = useRef(cssContent);
  const [targetElementStyle, setTargetElementStyle] = useState(null);
  const [targetElementImg, setTargetElementImg] = useState(null);
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [deletingId, setDeletingId] = useState([]);
  const [isInspectmode, setIsInpectmode] = useState(false);
  const [isLoadingTargetElement, setIsLoadingTargetElement] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const iframeRef = useRef(null);
  const [form] = Form.useForm();
  const matchedRouteKey = findFirstMatchingKey(
    cssContent?.[pageElementKey],
    currentRoute
  );
  const eachSectionLeftGap = 10;
  const headerLevelClassNames = {
    0: "modifyColor-level0-header",
    1: "modifyColor-level1-header",
    2: "modifyColor-level2-header",
  };

  const headeTxtLevelClassNames = {
    0: "modifyColor-level0-txt",
    1: "modifyColor-level1-txt",
    2: "modifyColor-level2-txt",
  };

  useEffect(() => {
    PageLoadAction();
  }, []);

  useEffect(() => {
    cssContentRef.current = cssContent;
  }, [cssContent]);

  useEffect(() => {
    if (form && showSaveModal) form.resetFields();
  }, [showSaveModal]);

  useEffect(() => {
    setIframeLoaded(false);
  }, [frontendUrl]);

  // Debounce input value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchInput);
      setIsSearching(false);
    }, 300); // 300 ms delay

    return () => {
      clearTimeout(handler);
    };
  }, [searchInput]);

  // Effect to perform search
  useEffect(() => {
    if (debouncedValue) {
      const result = searchByKey(cssContentRef.current, debouncedValue);
      console.log(result);
      setSearchResults(result);
    }
    if (debouncedValue == "") {
      setSearchResults(null);
    }
  }, [debouncedValue]);

  const FetchCssVariable = async (url) => {
    const newTimestamp = Date.now();
    const cssResult = await fetch(`${url}?v=${newTimestamp}`);
    let cssResponse = await cssResult.text();
    const cssObject = parseCssToObject(cssResponse);
    if (cssObject == null) throw new Error(`Wrong Css URL`);
    setCssContent(cssObject);
  };

  const PageLoadAction = async () => {
    try {
      const pageLoadResult = await modifyColorPageLoad();
      let pageLoadResponse = await pageLoadResult.json();
      if (pageLoadResponse?.status === "ok") {
        const responseUrl = pageLoadResponse?.data?.frontendUrl ?? "";
        const responseDraftListing = pageLoadResponse?.data?.cssDrafts ?? [];
        if (responseUrl) {
          const newUrl =
            responseUrl[responseUrl.length - 1] == "/"
              ? responseUrl.substring(0, responseUrl.length - 1)
              : responseUrl;
          const newTimestamp = Date.now();
          setFrontendUrl(newUrl + `?v=${newTimestamp}`);
          setDraftListing(responseDraftListing);
          const cssUrl = `${newUrl}/cssVariables/theme-default.css`;
          await FetchCssVariable(cssUrl);
        } else {
          throw new Error("Empty front end url");
        }
      } else {
        throw new Error("Page Load api failed");
      }
    } catch (error) {
      console.error("Error fetching CSS:", error);
      alertData.title = "Error";
      alertData.msg =
        "An unexpected error occurred. Please contact your system administrator.";
      sharedErrorAlert(alertData);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Add an event listener for messages from the iframe
    window.addEventListener("message", handleIframeMessage);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("message", handleIframeMessage);
    };
  }, []);

  useEffect(() => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(
        {
          action: "enableMouseOver",
          data: { enableMouseOver: isInspectmode },
        },
        "*"
      );
    }
  }, [isInspectmode]);

  useEffect(() => {
    if (targetElementStyle) {
      setIsLoadingTargetElement(false);
    }
  }, [targetElementStyle]);

  const handleIframeMessage = async (event) => {
    // Check if the message contains route change information
    if (event.data && event.data.type === "routeChange") {
      // Handle the route change here
      const finalRoute = event.data.route.toString().toLowerCase();
      setCurrentRoute(finalRoute);
    }

    if (event.data && event.data.action === "inspectElementCssVariable") {
      setIsLoadingTargetElement(true);
      const elementCssVariable = event.data.elementCssVariable;
      const elementImgSrc = event.data.elementImgSrc;
      await updateTarget(elementCssVariable, cssContentRef.current);
      setTargetElementStyle(elementCssVariable);
      setTargetElementImg(elementImgSrc);
    }

    if (event.data && event.data.action === "mouseOverStateChanged") {
      const mouseOverState = event.data.mouseOverState;
      setIsInpectmode(mouseOverState);
    }

    if (event.data.action === "clickedInspectElement") {
      console.log(`clicked document close loading wor`);
      setIsLoadingTargetElement(true);
    }
  };

  async function updateTarget(target, allCss) {
    for (const targetKey in target) {
      for (const item of target[targetKey]) {
        const path = await findFirstKeyPathAsync(allCss, item.value);
        item.path = path;
      }
    }
  }

  function findFirstKeyPathAsync(obj, key, path = []) {
    return new Promise((resolve) => {
      // console.log(obj);
      if (obj.hasOwnProperty(key)) {
        console.log(path, key);
        resolve(path);
        return;
      }

      const promises = [];
      for (let k in obj) {
        if (obj[k] !== null && typeof obj[k] === "object") {
          const newPath = [...path, k];
          promises.push(findFirstKeyPathAsync(obj[k], key, newPath));
        }
      }

      if (promises.length > 0) {
        Promise.race(promises).then(resolve);
      } else {
        resolve(null);
      }
    });
  }

  const handleVariableChange = (sectionPath, name, value) => {
    // Inside Project A
    const iframeWindow = iframeRef.current.contentWindow;
    iframeWindow.postMessage(
      { action: "updateCss", data: { name: name, value: value } },
      "*"
    );

    setCssContent((prevVariables) => {
      // Create a copy of the previous variables
      let updatedVariables = { ...prevVariables };

      // Traverse the section path to reach the target section
      let currentSection = updatedVariables;
      for (const sectionName of sectionPath) {
        currentSection = currentSection[sectionName];
      }

      // Update the variable value in the target section
      currentSection[name].current = value;

      // Return the updated variables directly
      return updatedVariables;
    });
  };

  function parseObjectToCssSection(content, isPage) {
    const pageKeyword = isPage ? "(page)" : "";
    let cssString = "";
    for (const contentKey in content) {
      cssString += `  /* ${contentKey}${pageKeyword} ==> start */\n`;
      const variables = content[contentKey];
      for (const variable in variables) {
        //if object have current and ori mean it is the input object
        if (
          typeof variables[variable] === "object" &&
          !variables[variable].hasOwnProperty("current")
        ) {
          cssString += parseObjectToCssSection(
            { [variable]: variables[variable] },
            false
          );
        } else {
          cssString += `  ${variable}: ${
            variables[variable]?.current ?? ""
          };\n`;
        }
      }
      cssString += `  /* ${contentKey}${pageKeyword} ==> end */\n\n`;
    }
    return cssString;
  }

  function objectToCSS(obj) {
    let cssString = ":root {\n";

    for (const section in obj) {
      const isPage = section == pageElementKey;
      if (isPage) {
        const result = parseObjectToCssSection(obj[section], isPage);
        cssString += result;
      } else {
        const result = parseObjectToCssSection(obj[section], false);
        cssString += result;
      }
    }

    cssString += "}";
    const result = parseCssToObject(cssString);
    console.log(result);
    return cssString;
  }

  function parseCssToObject(content) {
    const topLevelSectionRegex =
      /\/\*\s*([^=]+)\s*==>\s*start\s*\*\/([\s\S]*?)\/\*\s*\1\s*==>\s*end\s*\*\//g;
    const parsedVariables = { [sharedElementKey]: {}, [pageElementKey]: {} };
    let isCssContent = false;

    let match;
    while ((match = topLevelSectionRegex.exec(content)) !== null) {
      isCssContent = true;
      const [_, sectionName, sectionContent] = match;
      const sectionKey = sectionName.includes("(page)")
        ? pageElementKey
        : sharedElementKey;
      const newSectionName = sectionName
        .trim()
        .replace("(page)", "")
        .toLowerCase();
      parsedVariables[sectionKey][newSectionName] = parseSection(
        sectionContent,
        [sectionKey, newSectionName]
      );
    }

    return isCssContent ? parsedVariables : null;
  }
  function parseSection(sectionContent, path) {
    const innerSectionRegex =
      /\/\*\s*([^=]+)\s*==>\s*start\s*\*\/([\s\S]*?)\/\*\s*\1\s*==>\s*end\s*\*\//g;
    const variableRegex = /--([^:\s]+)\s*:\s*([^;]+);/g;

    const parsedSection = {};
    let withoutSectionContent = sectionContent;

    // Parse inner sections
    let innerSectionMatch;
    while (
      (innerSectionMatch = innerSectionRegex.exec(sectionContent)) !== null
    ) {
      const [matchedContent, innerSectionName, innerSectionVariables] =
        innerSectionMatch;
      const sectionName = innerSectionName.trim().toLowerCase();
      parsedSection[sectionName] = parseSection(innerSectionVariables, [
        ...path,
        sectionName,
      ]);
      withoutSectionContent = withoutSectionContent.replace(matchedContent, "");
    }

    // Parse variables within the section
    let variableMatch;
    while (
      (variableMatch = variableRegex.exec(withoutSectionContent)) !== null
    ) {
      const [, variableName, variableValue] = variableMatch;
      if (!parsedSection["--" + variableName]) {
        let targetOriCssValue = cssContent;
        for (const key of path) {
          targetOriCssValue = targetOriCssValue?.[key];
        }

        parsedSection["--" + variableName] = {
          ori: variableValue.trim(),
          current: variableValue.trim(),
        };
        const iframeWindow = iframeRef.current.contentWindow;
        const lastCssValue = targetOriCssValue?.["--" + variableName]?.current;
        if (
          lastCssValue &&
          lastCssValue != parsedSection["--" + variableName].current
        ) {
          console.log(`got different ${variableName}`);
          iframeWindow.postMessage(
            {
              action: "updateCss",
              data: { name: "--" + variableName, value: variableValue.trim() },
            },
            "*"
          );
        }
      }
    }

    return parsedSection;
  }

  function renderCssContentToComponent(obj, sectionPath = []) {
    return Object.entries(obj).map(([name, value]) => {
      //if object have current and ori mean it is the input object
      if (typeof value === "object" && !value.hasOwnProperty("current")) {
        const currentSectionPath = [...sectionPath, name];
        let color = "red";
        if (sectionPath.length == 1) {
          color = "green";
        } else if (sectionPath.length == 2) {
          color = "yellow";
        }
        return (
          <div
            key={`section${name}`}
            style={{
              paddingLeft: sectionPath.length * eachSectionLeftGap + "px",
            }}
          >
            <div>
              <ExpandCollapseComponent
                isSticky={true}
                expandedHeaderComponent={
                  <BaseHeader
                    isExpand={true}
                    text={name}
                    level={sectionPath.length}
                  />
                }
                collapsedHeaderComponent={
                  <BaseHeader
                    isExpand={false}
                    text={name}
                    level={sectionPath.length}
                  />
                }
                children={
                  <div className="modifyColor-sublisting-container">
                    {renderCssContentToComponent(value, currentSectionPath)}
                  </div>
                }
              />
            </div>
          </div>
        );
      } else {
        const curValue = value?.current ?? "";
        const oriValue = value?.ori ?? "";
        const isChanged = curValue != oriValue;
        return (
          <div key={name}>
            <label
              className="modifyColor-input-label"
              htmlFor={name}
              style={isChanged ? { color: "red" } : {}}
            >
              {name}
            </label>
            <Input
              className="modifyColor-input"
              id={name}
              value={curValue}
              onChange={(e) => {
                handleVariableChange(sectionPath, name, e.target.value);
              }}
            />
          </div>
        );
      }
    });
  }

  function findFirstMatchingKey(obj, target) {
    const matchedKey = [];
    for (const key in obj) {
      const regex = new RegExp(`^${key.replace(/\@/g, "[^/]*")}$`);
      if (regex.test(target)) {
        matchedKey.push(key);
      }
    }
    return matchedKey;
  }

  const submitAction = async (cssContent) => {
    try {
      setIsLoading(true);
      // Convert CSS content string to a Blob
      const blob = new Blob([cssContent], { type: "text/css" });
      // Create a File object from the Blob
      const file = new File([blob], "theme-default.css");
      // Create a FormData object and append the file to it
      const formData = new FormData();
      formData.append("cssFile", file);

      const response = await updateCssVariables(formData);
      const result = await response.json();
      setIsLoading(false);
      if (result.status == "ok") {
        console.log(`success `, result);
        alertData.title = `Success`;
        alertData.msg = "Css Updated.";
        sharedSuccessAlert(alertData);
      } else {
        console.error("Error update css:", result);
        alertData.title = `Error${
          result?.errcode ? ` (${result.errcode})` : ""
        }`;
        alertData.msg = result?.msg
          ? result.msg
          : "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      }
    } catch (error) {
      alertData.title = `Error`;
      alertData.msg =
        "An unexpected error occurred. Please contact your system administrator.";
      sharedErrorAlert(alertData);
    }
  };

  const convertTimeStampToDateTimeString = (timeStamp) => {
    // Create a new Date object using the timestamp
    const date = new Date(parseInt(timeStamp));

    // Get the individual components of the date (year, month, day, hour, minute, second)
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Month is zero-based, so add 1
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Format the date and time
    const formattedDateTime = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    } ${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }:${seconds < 10 ? "0" + seconds : seconds}`;

    return formattedDateTime;
  };

  const loadDraftAction = async (draftItem) => {
    try {
      setShowDraftList(false);
      setIsLoading(true);
      await FetchCssVariable(draftItem.urlPath);
      setSelectedDraft(draftItem);
    } catch (error) {
      alertData.title = `Error`;
      alertData.msg =
        "An unexpected error occurred. Please contact your system administrator.";
      sharedErrorAlert(alertData);
    } finally {
      setIsLoading(false);
    }
  };

  const saveAction = async (data, asNew = false) => {
    try {
      setShowSaveModal(false);
      setIsLoading(true);
      const cssString = objectToCSS(cssContent);

      const blob = new Blob([cssString], { type: "text/css" });
      // Create a File object from the Blob
      const file = new File([blob], "theme-default.css");

      const formData = new FormData();
      formData.append("cssFile", file);
      formData.append("draftName", data.draftName);
      if (selectedDraft && !asNew) {
        formData.append("id", selectedDraft.id);
      }

      const response = await saveOrupdateCssDraft(formData);
      const result = await response.json();
      if (result.status == "ok") {
        console.log(`success `, result);
        alertData.title = `Success`;
        alertData.msg = "Saved as css draft.";
        sharedSuccessAlert(alertData);
        const newCssDrafts = result?.data?.cssDrafts ?? [];
        const newDraftId = result?.data?.newid ?? "";
        console.log(result);
        setDraftListing(newCssDrafts);

        setSelectedDraft((curValue) => {
          //if previous not selected, and dont have newDraftid then just remain null(curValue should be null)
          if (!curValue && !newDraftId) {
            return curValue;
          }

          const finalId = newDraftId ? newDraftId : curValue.id;
          const isMatch = newCssDrafts.find((item) => item.id == finalId);
          if (isMatch) {
            return isMatch;
          }
          //if not find in latest listing, mean delete by other user so change to null
          return null;
        });
      } else {
        console.error("Error update css:", result);
        alertData.title = `Error${
          result?.errcode ? ` (${result.errcode})` : ""
        }`;
        alertData.msg = result?.msg
          ? result.msg
          : "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAction = async (data) => {
    try {
      if (!data?.id) {
        throw new Error("Missing ID");
      }

      setDeletingId((value) => {
        return [...value, data.id];
      });
      const response = await deleteCssDraft({ id: data.id });
      const result = await response.json();
      if (result.status == "ok") {
        console.log(`success `, result);
        alertData.title = `Success`;
        alertData.msg = `${data.draftName} Css Draft deleted.`;
        sharedSuccessAlert(alertData);
        setDraftListing(result?.data?.cssDrafts ?? []);
        setSelectedDraft((curValue) => {
          if (curValue && curValue.id == data.id) {
            return null;
          }

          return curValue;
        });
      } else {
        console.error("Error update css:", result);
        alertData.title = `Error${
          result?.errcode ? ` (${result.errcode})` : ""
        }`;
        alertData.msg = result?.msg
          ? result.msg
          : "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      }
    } catch (error) {
      alertData.title = `Error`;
      alertData.msg =
        "An unexpected error occurred. Please contact your system administrator.";
      sharedErrorAlert(alertData);
    } finally {
      setDeletingId((value) => {
        return value.filter((id) => {
          return id != data.id;
        });
      });
    }
  };

  // const setInspectMode = (enableMouseOver) => {
  //   if (iframeRef.current && iframeRef.current.contentWindow) {
  //     iframeRef.current.contentWindow.postMessage(
  //       {
  //         action: "enableMouseOver",
  //         data: { enableMouseOver: enableMouseOver },
  //       },
  //       "*"
  //     );
  //   }
  // };

  const getCssContentValueByPath = (path, name) => {
    //eg path: level1.level2.level3
    let tmpCssContent = { ...cssContent };

    // Traverse the section path to reach the target section
    let currentSection = tmpCssContent;
    for (const sectionName of path) {
      currentSection = currentSection[sectionName];
    }

    //name is the object key
    return currentSection[name];
  };

  const renderTargetElementStyle = (targetElementStyle) => {
    const renderTargetInput = (item, attributeName, index) => {
      const uniqueName = `${attributeName}-${index}-${item.value}`;
      const cssObject = getCssContentValueByPath(item.path, item.value);
      const curValue = cssObject?.current ?? "";
      const oriValue = cssObject?.ori ?? "";
      const isChanged = curValue != oriValue;
      return (
        <div key={uniqueName}>
          <label
            className="modifyColor-input-label"
            htmlFor={uniqueName}
            style={isChanged ? { color: "red" } : {}}
          >
            {item.value}
          </label>
          <Input
            className="modifyColor-input"
            id={uniqueName}
            value={curValue}
            onChange={(e) => {
              handleVariableChange(item.path, item.value, e.target.value);
            }}
          />
        </div>
      );
    };
    const targetElementCssInput = Object.entries(targetElementStyle).map(
      ([attributeName, attributeValues]) => {
        const activeValues = [];
        const inactiveValues = [];
        attributeValues.forEach((item) => {
          //filter out path null
          if (item.path) {
            console.log(item.path);
            if (item.active) {
              activeValues.push(item);
            } else {
              inactiveValues.push(item);
            }
          }
        });
        if (activeValues.length == 0 && inactiveValues.length == 0) {
          return null;
        }
        return (
          <div
            key={attributeName}
            style={{
              paddingLeft: 1 * eachSectionLeftGap + "px",
            }}
          >
            <ExpandCollapseComponent
              isSticky={true}
              expandedHeaderComponent={
                <BaseHeader text={attributeName} isExpand={true} level={1} />
              }
              collapsedHeaderComponent={
                <BaseHeader text={attributeName} isExpand={false} level={1} />
              }
              children={
                <div className="modifyColor-sublisting-container">
                  {activeValues.length > 0 ? (
                    <div>
                      <div className="modifyColor-targetElement-subSectionTitle">
                        Active :
                      </div>
                      <div>
                        {activeValues.map((item, index) => {
                          return renderTargetInput(item, attributeName, index);
                        })}
                      </div>
                    </div>
                  ) : null}
                  {inactiveValues.length > 0 ? (
                    <div>
                      <div className="modifyColor-targetElement-subSectionTitle">
                        Inactive :
                      </div>
                      <div>
                        {inactiveValues.map((item, index) => {
                          return renderTargetInput(item, attributeName, index);
                        })}
                      </div>
                    </div>
                  ) : null}
                </div>
              }
            />
          </div>
        );
      }
    );

    return targetElementCssInput.length > 0 ? (
      targetElementCssInput
    ) : (
      <div>No css for this element.</div>
    );
  };

  function searchByKey(obj, keyToFind) {
    const result = [];

    // Helper function to check if an object matches the expected structure
    function isValidStructure(value) {
      return (
        value &&
        typeof value === "object" &&
        "current" in value &&
        "ori" in value
      );
    }

    // Recursive function to traverse and collect values
    function traverse(object, path = "") {
      if (object !== null && typeof object === "object") {
        for (const key in object) {
          const newPath = [...path, key];
          const isValid = isValidStructure(object[key]);
          if (key.includes(keyToFind) && isValid) {
            result.push(newPath);
          }
          if (!isValid) {
            traverse(object[key], newPath);
          }
        }
      }
    }

    traverse(obj);
    return result.length === 0 ? null : result;
  }

  const renderSearchResult = (searchResults) => {
    return searchResults.map((item, index) => {
      //path included the key, need to slice out
      const targetKey = item[item.length - 1];
      const path = item.slice(0, item.length - 1);
      const cssObject = getCssContentValueByPath(path, targetKey);
      const uniqueName = `search-${index}-${targetKey}`;
      const curValue = cssObject?.current ?? "";
      const oriValue = cssObject?.ori ?? "";
      const isChanged = curValue != oriValue;

      return (
        <div key={uniqueName}>
          <label
            className="modifyColor-input-label"
            htmlFor={uniqueName}
            style={isChanged ? { color: "red" } : {}}
          >
            {targetKey}
          </label>
          <Input
            className="modifyColor-input"
            id={uniqueName}
            value={curValue}
            onChange={(e) => {
              handleVariableChange(path, targetKey, e.target.value);
            }}
          />
        </div>
      );
    });
  };

  const BaseHeader = ({ text, isExpand, level = 0 }) => {
    return (
      //this is to block some overflow content, because it have border-radius
      <div style={{ background: "white" }}>
        <div
          className={`modifyColorPage-expandCollapse-baseHeader ${headerLevelClassNames[level]}`}
        >
          {/* <div className="modifyColorPage-mainHeader-container-expanded"> */}
          <div className="modifyColorPage-expandCollapse-title-section">
            <div
              className={`modifyColorPage-expandCollapse-title  ${headeTxtLevelClassNames[level]}`}
            >
              {text}
            </div>
          </div>
          {isExpand ? (
            <CaretUpFilled
              className="modifyColorPage-expandCollapse-icon"
              style={level == 0 ? { color: "white" } : {}}
            />
          ) : (
            <CaretDownFilled
              className="modifyColorPage-expandCollapse-icon"
              style={level == 0 ? { color: "white" } : {}}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <Modal
        title="Save Progress"
        visible={showSaveModal}
        footer={null}
        onCancel={() => {
          setShowSaveModal(false);
        }}
      >
        <Form
          form={form}
          name="control-hooks"
          onFinish={saveAction}
          style={{ marginTop: 24 }}
          initialValues={{
            draftName: selectedDraft ? selectedDraft.draftName : "",
          }}
        >
          <Form.Item
            name="draftName"
            label="Draft Name"
            rules={[
              { required: true, message: "Please input the draft name." },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item style={{ justifyContent: "center" }}>
            <Button type="primary" htmlType="submit">
              {selectedDraft ? "Save as existing" : "Save"}
            </Button>
            {selectedDraft ? (
              <Button
                type="primary"
                onClick={async () => {
                  try {
                    const values = await form.validateFields();

                    saveAction(values, true);
                  } catch (errorInfo) {
                    console.log("Failed:", errorInfo);
                    // Handle form validation errors
                  }
                }}
              >
                Save as new
              </Button>
            ) : null}
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Draft Listing"
        visible={showDraftList}
        footer={null}
        onCancel={() => {
          setShowDraftList(false);
        }}
      >
        <div>
          {draftListing.map((item, index) => {
            const isDeleting = deletingId.includes(item.id);
            return (
              <div
                key={item.id}
                className="modifyColor-draftListing-main-container"
              >
                <div>
                  <div className="modifyColor-draftListing-label-section">
                    <div>Name:</div>
                    <div style={{ fontWeight: "bold" }}>{item.draftName}</div>
                  </div>
                  <div className="modifyColor-draftListing-label-section">
                    <div>Created Time:</div>
                    <div>
                      {convertTimeStampToDateTimeString(item.createdTime)}
                    </div>
                  </div>
                  <div className="modifyColor-draftListing-label-section">
                    <div>Modified Time:</div>
                    <div
                      style={
                        item.createdTime != item.modifiedTime
                          ? { color: "red" }
                          : {}
                      }
                    >
                      {convertTimeStampToDateTimeString(item.modifiedTime)}
                    </div>
                  </div>
                </div>
                <div className="modifyColor-draftListing-action-section">
                  <Button
                    className="modifyColor-draftListing-load-btn"
                    type="primary"
                    onClick={() => {
                      loadDraftAction(item);
                    }}
                  >
                    Load Draft
                  </Button>
                  <Button
                    className="modifyColor-draftListing-delete-btn"
                    type="primary"
                    onClick={() => {
                      deleteAction(item);
                    }}
                    disabled={isDeleting}
                    loading={isDeleting}
                  >
                    Delete Draft
                  </Button>
                </div>
                {index != draftListing.length - 1 ? (
                  <div className="modifyColor-draftListing-separator" />
                ) : null}
              </div>
            );
          })}
        </div>
      </Modal>
      <div className="modifyColor-container">
        <div className="modifyColor-left-container">
          <div className="modifyColor-left-action-section">
            <Button
              type="primary"
              autoFocus={false}
              className={
                isInspectmode
                  ? "modifyColor-inspectmode-on"
                  : "modifyColor-inspectmode-off"
              }
              onClick={(event) => {
                event.currentTarget.blur();
                setIsInpectmode((value) => !value);
              }}
              disabled={!iframeLoaded}
            >
              Inspect Mode: {isInspectmode ? "On" : "Off"}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                const result = objectToCSS(cssContent);
                submitAction(result);
              }}
            >
              Submit Changes
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setShowSaveModal(true);
              }}
            >
              Save as draft
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setShowDraftList(true);
              }}
            >
              Draft Listing
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setIsMobile((value) => !value);
              }}
            >
              Switch to {isMobile ? "desktop view" : "mobile view"}
            </Button>
          </div>
          {selectedDraft ? (
            <div className="modifyColor-draftname-container">
              Draft Name: {selectedDraft.draftName}
            </div>
          ) : null}
          <Input
            placeholder="Search..."
            allowClear={true}
            value={searchInput}
            onChange={(e) => {
              setIsSearching(true);
              setSearchInput(e.target.value);
            }}
          />

          <div className="modifyColor-scroll-container modifyColor-listing-container">
            {targetElementStyle ? (
              <Spin spinning={isLoadingTargetElement}>
                <ExpandCollapseComponent
                  isSticky={true}
                  expandedHeaderComponent={
                    <BaseHeader text={"Target Element:"} isExpand={true} />
                  }
                  collapsedHeaderComponent={
                    <BaseHeader text={"Target Element:"} isExpand={false} />
                  }
                  defaultExpanded={true}
                  children={
                    <div className="modifyColor-sublisting-container">
                      {targetElementImg ? <img src={targetElementImg} /> : null}
                      {renderTargetElementStyle(targetElementStyle)}
                    </div>
                  }
                />
              </Spin>
            ) : isLoadingTargetElement ? (
              <Spin
                style={{ width: "100%" }}
                spinning={isLoadingTargetElement}
              ></Spin>
            ) : null}
            {searchInput ? (
              <Spin spinning={isSearching}>
                {searchResults ? (
                  <ExpandCollapseComponent
                    isSticky={true}
                    expandedHeaderComponent={
                      <BaseHeader text={"Search Result"} isExpand={true} />
                    }
                    collapsedHeaderComponent={
                      <BaseHeader text={"Search Result"} isExpand={false} />
                    }
                    children={
                      <div className="modifyColor-sublisting-container">
                        {renderSearchResult(searchResults)}
                      </div>
                    }
                  />
                ) : (
                  <div>{isSearching ? "Loading..." : "No result."}</div>
                )}
              </Spin>
            ) : (
              <>
                <ExpandCollapseComponent
                  isSticky={true}
                  expandedHeaderComponent={
                    <BaseHeader
                      text={sharedElementKey}
                      isExpand={true}
                      level={0}
                    />
                  }
                  collapsedHeaderComponent={
                    <BaseHeader
                      text={sharedElementKey}
                      isExpand={false}
                      level={0}
                    />
                  }
                  children={
                    cssContent?.[sharedElementKey] ? (
                      <div className="modifyColor-sublisting-container">
                        {renderCssContentToComponent(
                          cssContent[sharedElementKey],
                          [sharedElementKey]
                        )}
                      </div>
                    ) : null
                  }
                />

                <ExpandCollapseComponent
                  isSticky={true}
                  expandedHeaderComponent={
                    <BaseHeader
                      text={`${pageElementKey}: ${currentRoute}`}
                      isExpand={true}
                      level={0}
                    />
                  }
                  collapsedHeaderComponent={
                    <BaseHeader
                      text={`${pageElementKey}: ${currentRoute}`}
                      isExpand={false}
                      level={0}
                    />
                  }
                  children={matchedRouteKey.map((value, _) => {
                    return cssContent?.[pageElementKey]?.[value] ? (
                      <div className="modifyColor-sublisting-container">
                        {renderCssContentToComponent(
                          cssContent[pageElementKey][value],
                          [pageElementKey, value]
                        )}
                      </div>
                    ) : null;
                  })}
                />
              </>
            )}
          </div>
        </div>
        {frontendUrl ? (
          <iframe
            className={
              isMobile
                ? `modifyColor-iframe-mobile`
                : "modifyColor-iframe-desktop"
            }
            title="embeddedContent"
            ref={iframeRef}
            src={frontendUrl}
            onLoad={() => {
              setIframeLoaded(true);
            }}
          ></iframe>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </Spin>
  );
}

// function ModifyColorPage() {
//   return (
//     <div style={{ height: "200px", overflow: "scroll" }}>
//       {/* <div>
//         <div
//           className="sticky-header"
//           style={{ position: "sticky", top: "0px", background: "pink" }}
//         >
//           <div>Header A</div>
//         </div>
//         <div className="content">
//           <div>
//             <div
//               className="sticky-header"
//               style={{ position: "sticky", top: "0px", background: "yellow" }}
//             >
//               <div>Inner Header A</div>
//             </div>
//             <div className="content">
//               <div>Innercontent...</div>
//               <div>Innercontent...</div>
//               <div>Innercontent...</div>
//               <div>Innercontent...</div>
//               <div>Innercontent...</div>
//               <div>Innercontent...</div>
//             </div>
//           </div>

//           <div>
//             <div
//               className="sticky-header"
//               style={{ position: "sticky", top: "0px", background: "green" }}
//             >
//               <div>Inner Header AB</div>
//             </div>
//             <div className="content">
//               <div>Innercontent...</div>
//               <div>Innercontent...</div>
//               <div>Innercontent...</div>
//               <div>Innercontent...</div>
//               <div>Innercontent...</div>
//               <div>Innercontent...</div>
//             </div>
//           </div>
//           <div>content...</div>
//           <div>content...</div>
//           <div>content...</div>
//           <div>content...</div>
//           <div>content...</div>
//           <div>content...</div>
//         </div>
//       </div>
//       <div>
//         <div
//           className="sticky-header"
//           style={{ position: "sticky", top: "0px", background: "red" }}
//         >
//           <div>Header B</div>
//         </div>
//         <div className="content">
//           <div>Bcontent...</div>
//           <div>Bcontent...</div>
//           <div>Bcontent...</div>
//           <div>Bcontent...</div>
//           <div>Bcontent...</div>
//           <div>Bcontent...</div>
//         </div>
//       </div>
//       <div>
//         <div
//           className="sticky-header"
//           style={{ position: "sticky", top: "0px", background: "blue" }}
//         >
//           <div>Header C</div>
//         </div>
//         <div className="content">
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//         </div>
//       </div>
//       <div>
//         <div
//           className="sticky-header"
//           style={{ position: "sticky", top: "0px", background: "blue" }}
//         >
//           <div>Header C</div>
//         </div>
//         <div className="content">
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//         </div>
//       </div>
//       <div>
//         <div
//           className="sticky-header"
//           style={{ position: "sticky", top: "0px", background: "blue" }}
//         >
//           <div>Header C</div>
//         </div>
//         <div className="content">
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//         </div>
//       </div>
//       <div>
//         <div
//           className="sticky-header"
//           style={{ position: "sticky", top: "0px", background: "blue" }}
//         >
//           <div>Header C</div>
//         </div>
//         <div className="content">
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//           <div>Ccontent...</div>
//         </div>
//       </div> */}
//       <ExpandCollapseComponent
//         expandedHeaderComponent={<div>Header A</div>}
//         collapsedHeaderComponent={<div>Header A</div>}
//         isSticky={true}
//         children={
//           <>
//             <ExpandCollapseComponent
//               expandedHeaderComponent={
//                 <div style={{ background: "green" }}>Header A</div>
//               }
//               collapsedHeaderComponent={<div>Header A</div>}
//               isSticky={true}
//               children={
//                 <>
//                   <div>content...</div>
//                   <div>content...</div>
//                   <div>content...</div>
//                   <div>content...</div>
//                   <div>content...</div>
//                   <div>content...</div>
//                 </>
//               }
//             />
//             <ExpandCollapseComponent
//               expandedHeaderComponent={<div>Header A</div>}
//               collapsedHeaderComponent={<div>Header A</div>}
//               isSticky={true}
//               children={
//                 <>
//                   <div>content...</div>
//                   <div>content...</div>
//                   <div>content...</div>
//                   <div>content...</div>
//                   <div>content...</div>
//                   <div>content...</div>
//                 </>
//               }
//             />
//             <ExpandCollapseComponent
//               expandedHeaderComponent={<div>Header A</div>}
//               collapsedHeaderComponent={<div>Header A</div>}
//               isSticky={true}
//               children={
//                 <>
//                   <div>content...</div>
//                   <div>content...</div>
//                   <div>content...</div>
//                   <div>content...</div>
//                   <div>content...</div>
//                   <div>content...</div>
//                 </>
//               }
//             />
//             <div>content...</div>
//             <div>content...</div>
//             <div>content...</div>
//             <div>content...</div>
//             <div>content...</div>
//             <div>content...</div>
//           </>
//         }
//       />
//     </div>
//   );
// }
export default ModifyColorPage;
