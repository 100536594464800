import React, {useState, useEffect} from 'react'
import {
  getBotStat, reloadBot, updateBotStat, getBotStatV2
} from '../../../../../config/networkConfig';
import { useSelector } from "react-redux";
import {
  Table,
  message,
  Button,
  Modal,
  Tooltip
} from "antd";
import { settings } from '../../../../../config/appConfig';
import { ReloadOutlined , PlayCircleOutlined} from "@ant-design/icons";
import style from '../../../../../resource/style/style';


const   MonitorBotList = () => {
  const init = new Date()
  const [date, setDate] = useState(init)

  //
  const [statementData,         setStatementData] = useState("");
  const [statementPageSize, setStatementPageSize] = useState(100);
  const [statementPageNum,   setStatementPageNum] = useState(1);
  const [statementTotal,       setStatementTotal] = useState(10);
  const [statTableLoading,   SetStatTableLoading] = useState(false);
  const [botId,                         setBotId] = useState("-1");
  const [jobName,                     setJobName] = useState("");
  const [botCnt,                       setBotCnt] = useState("");
  const [cfmBotVisible ,        setCfmBotVisible] = useState(false);
  const [reloadBotVisible,   setReloadBotVisible] = useState(false);

  const { username  } = useSelector((state) => state.login);
  

  let statColumns =  [
    {
      align: "center",
      title: <div>Id</div>,
      dataIndex:"id",
      width: "20%",
    },
    // {
    //   align: "center",
    //   title: <div>taskname</div>,
    //   dataIndex:"jobname",
    //   width: "20%",
    // },
    {
      align: "center",
      title: <div>Index</div>,
      dataIndex:"queryIndex",
      width: "20%",
      render:(text , record) => {
        return (
            <div
            style={{
              justifyContent: "center",
              textAlign: "center",
              display: "flex",
            }}
          >
            {
              <>
                <div style={{marginTop:"0.3rem", width:"4rem", textAlign:"right"}}>{record.queryIndex}</div>
                <div style={{marginLeft:"1rem", width:"2rem"}}>
                  {
                    record.queryIndex > 0 && record.editable == 'Y' ?
                    <Tooltip title="Reset to Zero">
                    <Button
                      shape="circle" 
                      type='danger'
                      icon={<ReloadOutlined />}
                      onClick={() => {
                        setBotId(record.id);
                        setJobName(record.jobname);
                        setBotCnt(record.reloadCnt);
                        setCfmBotVisible(true);
                    }}/>
                  </Tooltip>:<></>
                  }
                </div>
              </>
            }
          </div>
        )
      }
    },
    {
      align: "center",
      title: <div>Remain</div>,
      dataIndex:"reloadCnt",
      width: "20%",
      render:(text , record) => {
        return (
          <div
          style={{
            justifyContent: "center",
            textAlign: "center",
            display: "flex",
          }}
        >
          {
            <>
              <div style={style.reloadCntStyle}>{record.reloadCnt}</div>
              <div style={style.reloadCntBtnStyle}>
                {                 
                  record.reloadCnt > 0  && record.status !='Y' && record.editable == 'Y' ?
                  <Tooltip title="Reload Bot">
                  <Button
                    shape="circle" 
                    type="danger"
                    icon={<PlayCircleOutlined/>}
                    onClick={() => {
                      setBotId(record.id);
                      setJobName(record.jobname);
                      setBotCnt(record.reloadCnt);
                      setReloadBotVisible(true);
                  }}/>
                </Tooltip>:<></>
                }
              </div>
            </>
          }
        </div>
        )
      }
    },
    {
      align: "center",
      title: <div>Web Bot</div>,
      dataIndex:"status",
      width: "20%",
      render:(text , record) => {
         if(record.status =='Y') {
          return <OnStatus/>
         }else {
          return <OffStatus/>
         }
      }
    }
  ]

  const onUpdateStat = async() =>{
    if (botId == "-1"){
      message.error("Invalid Bot Id");
      return;
    }

    let remark = `${username} reset Bot : ${botId} counter to 0; Counter Before : ${botCnt}`;

    let requestData = {
        id      : botId,
        jobname : jobName,
        remark  : remark,
    }

    const result = await updateBotStat(requestData);
    let resp = await result.json();

    if (resp.status ==="ok") {        

      message.success(resp.msg);
      setCfmBotVisible(false);
      await tick();
    }else{
      message.error(resp.msg);
    }
  }

  const onReloadBot = async() =>{
    if (botId == "-1"){
      message.error("Invalid Bot Id");
      return;
    }

    let remark = `${username} reloaded Bot : ${botId}; Counter Before : ${botCnt}`;

    let requestData = {
        id      : botId,
        jobname : jobName,
        remark  : remark,
        reload  : 'Y',
    }

    // console.log(`requestData >> ${JSON.stringify(requestData)}`);

    const result = await reloadBot(requestData);
    let resp = await result.json();

    if (resp.status ==="ok") {        
      setReloadBotVisible(false);
      await tick();
      message.success(resp.msg);
      
    }else{
      message.error(resp.msg);

    }
  }

  const tick = async () => {
    setDate(new Date())
    
    SetStatTableLoading(true);

    let countDL = 0;
    const responseDL = await getBotStatV2({});
    // const responseDL = await getBotStat({});
      let resultDL = await responseDL.json();
      if (resultDL.status === "ok") {
        const dlData = Object.keys(resultDL.data).length
        ? resultDL.data.map((obj) => {
            SetStatTableLoading(false);
            return {
                key          : ++countDL,
                id           : obj.id,
                queryIndex   : obj.queryIndex == 'n/a' ? 0 : obj.queryIndex,
                jobname      : obj.taskName,
                reloadCnt    : obj.reloadCounter == 'n/a' ? 0 : obj.reloadCounter,
                status       : obj.status,
                editable     : obj.editable,
            };
          })
        : [];

        setStatementData(dlData);

        SetStatTableLoading(false);
      }else {
        message.error(resultDL.msg)
      }

      SetStatTableLoading(false);
  }

  useEffect(() => {
    const timerID = setTimeout(() => tick(), settings.monitorRefreshTime)
    return () => {
      clearTimeout(timerID)
    }
  }, [date])
  
  useEffect(()=> {
    tick();
  },[])

  //Status
  const OnStatus = () => {
    return (
      <p style={{color:"green", fontWeight:"bolder"}}>ON</p>
    )
  }

  const OffStatus = () => {
    return (
      <p style={{color:"red", fontWeight:"bolder"}}>OFF</p>
    )
  }

  // Notice 
  const BotListRemarks = () =>{
    return (
      <>
        <br/>
        &nbsp;&nbsp;<p>RELOAD Counter will reset daily at (00:00) GMT+8</p>
      </>
    )
  }

  // Modals 
  const ResetCntDialog = ()=> {
    return (
      <Modal
              visible={cfmBotVisible}   
              onCancel={()=>{
                  setCfmBotVisible(false);
              }}
              title ="Warning / เตือน "
              footer={[
                <Button 
                type="danger"
                onClick={onUpdateStat}
                >
                  Reset to Zero
                </Button>
                ,
                <Button key="back"
                align="left"
                onClick={()=>{
                  setCfmBotVisible(false)
                }}
                >
                  Cancel
                </Button>
              ]}
            >
                <div className='warning'>
                Please Stop {botId} bot before continue!!!<br/>
                กรุณหยุด {botId} บอท ก่อนดำเนินการ
                </div>
            </Modal>
    )
  }

  const RestartBotDialog = ()=> {
    return (
        <Modal
              visible={reloadBotVisible}   
              onCancel={()=>{
                  setReloadBotVisible(false);
              }}
              title ="Warning / เตือน "
              footer={[
                <Button 
                type="danger"
                onClick={onReloadBot}
                >
                  Reload
                </Button>
                ,
                <Button key="back"
                align="left"
                onClick={()=>{
                  setReloadBotVisible(false)
                }}
                >
                  Cancel
                </Button>
              ]}
            >
                <div className='warning'>
                Press "Reload" to Start {botId} bot.<br/>
                </div>
        </Modal>
    )
  }
//Modal



return   (
          <div style={{marginTop:"15px", marginBottom:"30px"}} className="cs-report-monitor">
            <p><b>Bot List</b><BotListRemarks/></p>
            <Table
              style={{marginTop:"10px"}}
              columns={statColumns}
              dataSource={statementData}
              pagination={false}
              loading={statTableLoading}
            />
            <ResetCntDialog/>
            <RestartBotDialog/>
            
          </div>)
}

export {
  MonitorBotList
}