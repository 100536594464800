import React, { useEffect, useState ,useRef} from "react";
import { useSelector } from "react-redux";
import { reportRedPacketSummary } from "../../../config/networkConfig";
import {
  message,
  Table,
  PageHeader,
  DatePicker,
  Button,
  Breadcrumb,
  Statistic,
  Row,
  Card,
} from "antd";
import { isMobile } from "react-device-detect";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import {settings} from "../../../config/appConfig";
import { FormatPt, FormatData} from "../../../library/numbers";
import moment from "moment";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";

export default function RedPacketSummaryReport() {
  const { userId } = useSelector((state) => state.login);
  const [report, setReport] = useState("");
  const [filter, setFilter] = useState("");
  const [reportDetail,setReportDetail] = useState("");
  const [filterDetail, setFilterDetail] = useState("");
  const [date, setDate] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [share, setShare] = useState(0);
  const reportDateFrom = useRef(null);
  const reportDateTo = useRef(null);

  useEffect(() => {
    reportList();
    //reportShareDetailList();
    // eslint-disable-next-line
  }, []);

  let curDate = new Date();

  const reportList = () => {
    var count = 0;
    var countDetail = 0;
    reportRedPacketSummary({data:null})
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          const report = Object.keys(json.data).length
            ? json.data.map((data) => {
                return {
                  key: ++count,
                  // dateTime             : data.TrxnDate,
                  TotalClaim           : data.TotalClaim,
                  SuccessClaim         : data.SuccessClaim,
                  SuccessAngpao        : data.SuccessAngpao,
                  PendingClaim         : data.PendingClaim,
                  PendingAngpao        : data.PendingAngpao,
                  ExpiredClaim         : data.ExpiredClaim,
                  ExpiredAngpao        : data.ExpiredAngpao,
                  AverageAngpao        : data.AverageAngpao,
                  BudgetAngpao         : data.BudgetAngpao,
                  EarlyTime            : data.EarlyTime,
                  LatestTime           : data.LatestTime,
                };
              })
            : [];

          setReport(report);

          // sort by date descending
          // var newReport = [...report];
          // const filter = newReport.sort((a,b) => b.dateTime > a.dateTime ? 1 : -1);
          // setReport(filter);

        }
        else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
      });
  };

  const columns = [
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "dateTime",
      width:0,
      ellipsis: true,
      fixed : "left",
    },
    {
      align: "center",
      title: "Total Claim",
      dataIndex: "TotalClaim",
      width: 130,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Successful Claim",
      dataIndex: "SuccessClaim",
      width: 130,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Successful Ang Pao",
      dataIndex: "SuccessAngpao",
      width: 130,
      ellipsis: true,
      render:function(record) {
        return FormatPt(record);
      }
    },
    {
      align: "center",
      title: "Pending Claim",
      dataIndex: "PendingClaim",
      width: 130,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Pending Ang Pao",
      dataIndex: "PendingAngPao",
      width: 130,
      ellipsis: true,
      render:function(record) {
        return FormatPt(record);
      }
    },
    {
      align: "center",
      title: "Expired Claim",
      dataIndex: "ExpiredClaim",
      width: 130,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Expired Ang Pao",
      dataIndex: "ExpiredAngpao",
      width: 150,
      ellipsis: true,
      render:function(record) {
        return FormatPt(record);
      }
    },
    {
      align: "center",
      title: "Average Ang Pao",
      dataIndex: "AverageAngpao",
      width: 150,
      render:function(record) {
        return FormatPt(record);
      }
    },
    {
      align: "center",
      title: "Budget Ang Pao",
      dataIndex: "BudgetAngpao",
      width: 150,
      ellipsis: true,

    },
    {
      align: "center",
      title: "Early Time",
      dataIndex: "EarlyTime",
      width: 150,
    },
    {
      align: "center",
      title: "Latest Time",
      dataIndex: "LatestTime",
      width: 200,
    },
  ];


  const mobileColumns = [
    {
      align: "center",
      title: "Data / Time",
      dataIndex: "dateTime",
      width: "50%",
      ellipsis: true,
    },
    {
      title: "Total Claim",
      dataIndex: "TotalClaim",
      width: "50%",
      ellipsis: true,
    },
  ];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    if (report.length == 0 ) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);



    const title = `Red Packet Report (${moment(curDate).format('YYYY-MM-DD')})\n\n\n`;

    const headers = [
      [
        columns[1].title,
        columns[2].title,
        columns[3].title,
        columns[4].title,
        columns[5].title,
        columns[6].title,
        columns[7].title,
        columns[8].title,
        columns[9].title,
        columns[10].title,
        columns[11].title,
      ],
    ];   


    var filteredReport = [...report];
    const data = filteredReport.map((data) => {
        return [ 
          data[columns[1].dataIndex],
          data[columns[2].dataIndex],
          FormatPt(data[columns[3].dataIndex]),               
          data[columns[4].dataIndex],
          FormatPt(data[columns[5].dataIndex]),
          data[columns[6].dataIndex],
          FormatPt(data[columns[7].dataIndex]),
          FormatPt(data[columns[8].dataIndex]),
          data[columns[9].dataIndex],
          data[columns[10].dataIndex],
          data[columns[11].dataIndex],
        ];
    });   



    let content = {
      startY: 80,
      head: headers,
      body: data,
      headStyles: { fillColor: "#1DA57A" },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);    
    doc.save("Red Packet Summary Report.pdf");
  };

  const datePicker = (date, dateString) => {
    setDate(date);
  };

  const datePicker2 = (date, dateString) => {
    setDateTo(date);
  };

  const searchBar = () => {
    if (!date) {
      reportDateFrom.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date From !";
      sharedErrorAlert(alertData);
      return;
    }
      
    if (!dateTo) {
      reportDateTo.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date To !";
      sharedErrorAlert(alertData);
      return;
    }
      
    reportList();
  };

  const resetBtn = () => {
    setDate(null);
    setDateTo(null);
    setReport([]);
  };

  const routes = [
    {
      path: "/",
      breadcrumbName: "Ang Pao",
    },
    {
      //   path: "first",
      breadcrumbName: "Report",
    },
    {
      path: "redpacketSummaryReport",
      breadcrumbName:"Red Pack Summary Report",
    },
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 2;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHeaderContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    searchBtn: {
      marginLeft: 30,
    },
    resetBtn: {
      marginLeft: 10,
    },
    datePickerStyle: {
      width: 300,
      borderRadius: 10,
    },
    mobilePageHearderContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    eachButtonContainer: {
      alignItems: "center",
      display: "flex",
      height: 50,
      marginLeft: 15,
      marginRight: 15,
      justifyContent: "center",
    },
    mobileSearchResetBtn: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      height: 50,
      alignItems: "center",
      paddingLeft: 60,
      paddingRight: 60,
    },
    btnContainer: {
      width: "100vw",
      padding: 10,
    },
    pdfStyle: {
      borderRadius: 10,
      width: 1200,
    },
    searchResetStyle: {
      borderRadius: 10,
      marginLeft: 10,
    },
    decStyle: {
      display: "flex",
      flexDirection: "row",
    },
    decLable: {
      width: 200,
    },
    decContainer: {
      paddingLeft: 75,
    },
    webRow: {
      backgroundColor: "#FFFFFF",
      paddingTop: 50,
      paddingLeft: 30,
      paddingBottom: 50,
    },
    mobileRow: {
      backgroundColor: "#FFFFFF",
      paddingLeft: 30,
    },
    cardStyle: {
      borderRadius: 20,
      height: 160,
      width: 280,
      border: "none",
    },
    headStyles: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 25,
      height: 80,
    },
    bodyStyle: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 80,
    },
  };

  return (
    <div className="eachPageContent">
      {isMobile ? (
        <div>
          <PageHeader ghost={false}>
            <div>
              <Breadcrumb
                separator=">"
                itemRender={itemRender}
                routes={routes}
              />
              <div style={styles.btnContainer}>
                <div style={styles.eachButtonContainer}>
                  <Button
                    type={"primary"}
                    onClick={exportPDF}
                    style={styles.pdfStyle}
                  >
                    Download PDF
                  </Button>
                </div>
                <div style={styles.eachButtonContainer}>
                  <DatePicker
                    style={styles.datePickerStyle}
                    onChange={datePicker}
                    value={date}
                    ref={reportDateFrom}
                    placeholder="Select Date From"
                    disabledDate={(current)=> {
                      return current > moment().subtract(1, 'day');
                    }}
                  />
                  <DatePicker
                    style={styles.datePickerStyle}
                    onChange={datePicker2}
                    value={dateTo}
                    ref={reportDateTo}
                    placeholder="Select Date To"
                    disabledDate={(current)=> {
                      let dateFrom = date;
                      if (dateFrom) {
                        return current < dateFrom || current > moment().subtract(1, 'day');
                      }else {
                        return  current > moment().subtract(1, 'day');
                      }
                    }}
                  />
                  <Button
                    type={"primary"}
                    onClick={searchBar}
                    style={styles.searchResetStyle}
                    icon={<SearchOutlined />}
                  />

                  <Button
                    onClick={resetBtn}
                    style={styles.searchResetStyle}
                    icon={<UndoOutlined />}
                  />
                </div>
              </div>
              <Row style={styles.mobileRow}>
                <Card
                  title="Total Share"
                  style={styles.cardStyle}
                  headStyle={styles.headStyles}
                  bodyStyle={styles.bodyStyle}
                >
                  <Statistic
                    // precision={settings.decimalScale}
                    value={FormatData(share)}
                    valueStyle={{ fontSize: 40 }}
                  />
                </Card>
              </Row>
              <div style={styles.tableContainer}>
                <Table
                  columns={mobileColumns}
                  dataSource={report}
                  expandable={{
                    expandedRowRender: (record) => (
                      <p style={{ margin: 0 }}>{record.description}</p>
                    ),
                    rowExpandable: (record) => record.name !== "Not Expandable",
                  }}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20"],
                    style: styles.paginationStyle,
                  }}
                />
              </div>
            </div>
          </PageHeader>
        </div>
      ) : (
        <div>
          <PageHeader
            ghost={false}
            title="Red Packet Summary Report"
          >
            <div style={styles.pageHeaderContainer}>
              <Button
                style={styles.refreshBtn}
                type="primary"
                onClick={() => {
                  reportList();
                }}
              >
                Refresh Page
              </Button>
              <Button key="2" type={"primary"} onClick={exportPDF}>
                Download PDF
              </Button>
            </div>
          </PageHeader>
          <div style={styles.tableContainer}>
            <Table
              columns={columns}
              dataSource={report}
              //dataSource={filter}
              scroll={{ x: 100, y: null }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: settings.pageSizeOptions,
                style: styles.paginationStyle,
                alignmentBottom: 'right'
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
