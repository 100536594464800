import React, { useState, useEffect } from "react";
import { csCommWitList, 
         csWithdrawApprove, 
         csVerifyMobile, 
         userBankList,
         csCreateWithdrawal,
         getReqToken
} from "../../config/networkConfig";
import { Badge, 
         message, 
         Table, 
         Button, 
         PageHeader, 
         Radio, 
         Select, 
         Modal, 
         Form, 
         Input, 
         InputNumber  
        } from "antd";
import { isMobile } from "react-device-detect";
import { settings } from "../../config/appConfig";
import { useSelector } from "react-redux";
import { CS_USER_TYPE } from "../../common/constants/csuserType";
import { wthModeText } from "../../library/texts";
import md5 from "md5";
import { witCommManCrWith, witCommManApprRej} from "../../library/permission";
import { ShowMobileNumber } from "../../library/numbers";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";


export default function CommWithdrawList() {
  const [originData, setOriginData] = useState([]);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("Pending");
  const [tableLoading, setTableLoading] = useState(false);

  const [form] = Form.useForm();
  const { companyId , role ,userId } = useSelector((state) => state.login);
  const [countryCode,setCountryCode] = useState("60");
  const [phoneNum, setPhoneNum] = useState("");

  //Dialog 
  const [manualWithdraw,setManualWithdrawVisible] = useState(false);
  //const [submitDisable, setSubmitDisable] = useState(true);
  const [withdrawDisable, setWithdrawDisable] = useState(true);
  const { Option } = Select;
  const [csAvaBankList, setCSAvaBankListOptions] = useState([]);
  const [bankcode, setBankCode] = useState("");  
  //const [acctnum, setAcctNum] = useState(""); 
  const [acctno, setAcctNo] = useState(""); 
  const [acctname,setAcctname] = useState("");
  const [withdrawAmt, setWithdrawAmt] = useState("");
  const [changeBank,setChangeBank] = useState(false);
  const [withdrUserId, setWithdrUserId] = useState("");
  const [withdrawType, setWithdrawType] = useState("");
  const [withdrawSelect, setWithdrawSelect] = useState(false);
  const [withdrawAmtDisable,setWithdrawAmtDisable] = useState(true);
  const [hideBank , setHideBank] = useState(true);
  const [minWithdrawLimit,setMinWithdrawLimit] = useState(0);
  const [minWithdrawAmtLable,setMinWithdrawAmtLable] = useState("Amount");

  useEffect(() => {
    commWithdrawList();
    // eslint-disable-next-line
  }, [status]);



  const withdrawType_CommToBank = "comm2bank";
  const withdrawType_CommToWallet = "comm2wallet";

  const minWithdrawLimit_Comm2Bank = 2000;
  const minWithdrawLimit_Comm2Wallet = 100;


  const clearAllFields = () =>{
    // setChangeBank(false);
    setWithdrawDisable(true);
    setWithdrawType("");
    setBankCode("");
    setAcctNo("");
    setAcctname("");
    setPhoneNum("");
    setWithdrawAmt("");
    setWithdrUserId("");
    form.resetFields();

  }

  //Modal Dialog //Start
  const selectCountryCode = (value) => {
    setCountryCode(value);
  };

  //Modal Dialog functions
  const handleWithdrawType = async (value) => {

    //console.log(`handleWithdrawType >> ${value}`);
    setWithdrawType(value);
    setWithdrawAmt('');

    if (withdrUserId!=''){

        if (value == withdrawType_CommToBank) {
          setMinWithdrawLimit(minWithdrawLimit_Comm2Bank)
          setMinWithdrawAmtLable(`Amount (min ${minWithdrawLimit_Comm2Bank})`)
          const resBank =  await getUserBankList(withdrUserId);
    
          if (resBank.status =="ok") {
              let bankResp = resBank.data;
              if (Object.keys(resBank.data).length == 0 ) {
                // message.error(`Please create Bank Account for user ${countryCode+phoneNum}`);
                alertData.title = "Error";
                alertData.msg = `Please create Bank Account for user ${countryCode+phoneNum}`;
                sharedErrorAlert(alertData);
                setWithdrawDisable(true);
                setWithdrawSelect(false)
              }else {
                setCSAvaBankListOptions(bankResp);
                let firstBank = bankResp.find(function(bd) { return bd.bankcode!='' });
                setBankCode(`${firstBank.bankcode}`);
                setChangeBank(false);
                setAcctNo(`${firstBank.acctnum}`);
                setAcctname(`${firstBank.acctname}`)
                setWithdrawDisable(false);
                setHideBank(false);
              }
          }
    
      }else if (value == withdrawType_CommToWallet) {
        setMinWithdrawLimit(minWithdrawLimit_Comm2Wallet);
        setMinWithdrawAmtLable(`Amount (min ${minWithdrawLimit_Comm2Wallet})`)
        setWithdrawDisable(false);
        setHideBank(true);
        setBankCode('');
        setAcctNo('');
        setAcctname('');
        setWithdrawAmt('');
      }
      
    }
  };
  
  const handleChangeMobile = async ()=> {
    setWithdrawDisable(true);
    form.resetFields(["withdrawType","withdrawAmt"])
    // setWithdrawType('');
    // setBankCode('');
    // setAcctname('');
    // setAcctNo('');
    // setWithdrawAmt(0);
  }

  const verifyMobile = async () => {

    setHideBank(true);

    await csVerifyMobile({
      mobile: countryCode+phoneNum
    })
    .then((response) => response.json())
    .then(async (json) => {
      if (json.status === "ok") {
        let resp = json.data;

        if (resp!==undefined) {
            setWithdrUserId(resp.userid);
            //setWithdrawDisable(false);
            setWithdrawSelect(false);
            form.resetFields(["withdrawAmt"]);
        }else {
          // message.error(`Invalid User`);
          alertData.title = "Error";
          alertData.msg = `User profile not found !`;
          sharedErrorAlert(alertData);
          setWithdrawSelect(true);
          setWithdrawDisable(true);
          setWithdrawAmt('');
        }
        
      } else { 
        clearAllFields();
        alertData.title = "Error";
        alertData.msg = "Member does not exist";
        sharedErrorAlert(alertData);
        setWithdrawSelect(true);
      }
    })
    .catch((error) => {
      console.log("error: ", error);
      alertData.title = "Error";
      alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
      sharedErrorAlert(alertData);
    });
  }

  const getUserBankList = async (userid) => {
    return await userBankList({userid: userid }).then(response=>response.json());
  }

  const onSubmitForm = async () => {
    //console.log("withdrUserId",withdrUserId)

    if (withdrawType == ""){
      alertData.title = "Field Required";
      alertData.msg = "Please select Withdrawal Type !";
      sharedErrorAlert(alertData);
      form.resetFields(["withdrawAmt"]);
      return;
    }

    if (withdrawAmt == ""){
      alertData.title = "Field Required";
      alertData.msg = "Please enter Withdrawal Amount !";
      sharedErrorAlert(alertData);
      return;
    }
    else {
      await csCreateWithdrawal({
        userid: withdrUserId,
        bankcode: bankcode,
        acctnum: acctno,
        acctname: acctname,
        comm: withdrawAmt,
        type: withdrawType,
        csUserId: userId,
        reqToken : md5(getReqToken(withdrawType)),
      })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          clearAllFields();
          setManualWithdrawVisible(false);
          commWithdrawList()
          alertData.title = "Success";
          alertData.msg = json.msg;
          sharedSuccessAlert(alertData);
        } else {
          if (json.errcode=="1236") {
            form.resetFields(["withdrawAmt"]);
          }else {
            clearAllFields();
          }

          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        };
      });
    }
  }
  
  //Modal Dialog //End

  const manual = `${settings.processTypeManual}`;

  const commWithdrawList = () => {
    var count = 0;
    setTableLoading(true);
    csCommWitList({processtype:manual})
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          const newData = Object.keys(json.data).length
            ? json.data.map((val) => {
                setTableLoading(false);
                return {
                  key: count++,
                  trxnId: val.trxnId,
                  dateTime: val.dateTime,
                  comm: val.comm * -1,
                  nickname: ShowMobileNumber(role, val.nickname, val.mobile),
                  trxnMode: wthModeText(val.trxnmode),
                  status:
                      val.status === 0
                      ? "Pending"
                      : val.status === 1 || val.status === 3
                      ? "Approved"
                      : val.status === 9
                      ? "Rejected"
                      : "-",
                  loading: false,
                };
              })
            : [];

          setOriginData(newData);

          if (Object.keys(json.data).length ==0) {
            setTableLoading(false);
          }

          const pending = newData.filter((val) => {
            return val.status === "Pending";
          });
          const approve = newData.filter((val) => {
            return val.status === "Approved";
          });
          const reject = newData.filter((val) => {
            return val.status === "Rejected";
          });

          const sortData =
            status === "Pending"
              ? pending
              : status === "Approved"
              ? approve
              : status === "Rejected"
              ? reject
              : [...newData];

          setData(sortData);

          // const complete = newData.filter((val) => val.status === "Completed");
          // const processing = newData.filter(
          //   (val) => val.status === "Processing"
          // );
          // const approve = newData.filter((val) => val.status === "Approved");
          // const pending = newData.filter((val) => val.status === "Pending");
          // const reject = newData.filter((val) => val.status === "Rejected");

          // const sortData =
          //   status === "Completed"
          //     ? complete
          //     : status === "Processing"
          //     ? processing
          //     : status === "Approved"
          //     ? approve
          //     : status === "Pending"
          //     ? pending
          //     : status === "Rejected"
          //     ? reject
          //     : [...complete, ...processing, ...approve, ...pending, ...reject];

          // setData(sortData);
        } else {
          setTableLoading(false);
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
  };

  const apiWitApprove = (trxnId, index, trxnMode) => {
    setLoading(index);

    let requestData = {
      trxnId : trxnId,
      userid : userId,
      reqToken   : md5(getReqToken(`${trxnMode}ManualApprove`)),
    }

    //console.log(`requestData >> ${JSON.stringify(requestData)}`);
    csWithdrawApprove(requestData)
      .then((res) => res.json())
      .then((json) => {
        if (json.status === "ok") {
          const newData = [...originData];

          newData[index].status = "Approved";
          setOriginData(newData);

          alertData.title = "Success";
          alertData.msg = json.msg;
          sharedSuccessAlert(alertData);
          commWithdrawList();
        } else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
        setLoading(index);
      })
      .catch((e) => {
        console.log(e);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
        setLoading(index);
      });
  };

  const setLoading = (index) => {
    const newLoadings = [...originData];

    newLoadings[index].loading = !newLoadings[index].loading;

    setOriginData(newLoadings);
  };

  const columns = [
    {
      title: "Data / Time",
      dataIndex: "dateTime",
      width: "200px",
      ellipsis: true,
    },
    {
      title: "Nickname",
      dataIndex: "nickname",
      width: "200px",
      ellipsis: true,
    },
    {
      title: "Commission",
      dataIndex: "comm",
      width: "200px",
      ellipsis: true,
    },
    {
      title: "Withdrawal Type",
      dataIndex: "trxnMode",
      width: "200px",
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "200px",
      ellipsis: true,
      render: (text, record) => {
        switch (record.status) {
          case "Completed":
            return <Badge status="success" text="Completed" />;
          case "Processing":
            return <Badge status="processing" text="Processing" />;
          case "Approved":
            return <Badge status="success" text="Approved" />;
          case "Pending":
            return (
              witCommManApprRej(role)?
              (
                <Button
                  style={styles.approveBtn}
                  type="primary"
                  onClick={(event) => {
                    apiWitApprove(record.trxnId, record.key);
                  }}
                  loading={record.loading}
                >
                  Approve
                </Button>
                )
              :(<Badge color="#f7b105" text="Pending" />)
            );

          default:
            return <Badge status="error" text="Rejected" />;
        }
      },
    },
  ];

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    approveBtn: {
      marginRight: 20,
      marginLeft: 20,
      backgroundColor: "#4190f7",
      borderColor: "#4190f7",
    },
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
    },
    refreshBtn: {
      marginLeft: 10,
    },
    textbox : {
        width:"81%"
    }
  };

  return (
    <div className="eachPageContent">
      <PageHeader ghost={false}>
        <div style={styles.pageHeaderContainer}>
          <div>
            <Button
              style={styles.refreshBtn}
              type="primary"
              onClick={() => {
                commWithdrawList();
              }}
            >
              Refresh Page
            </Button>
              {  witCommManCrWith(role)?
                (<>
                  <Button
                      style={styles.refreshBtn}
                      type="primary"
                      onClick={() => {
                        setManualWithdrawVisible(true);
                        clearAllFields();
                      }}
                    >
                    Create Withdrawal
                    </Button>
                </>)
                :
                (<></>)
              }
          </div>
          <Radio.Group
            buttonStyle={"solid"}
            value={status}
            onChange={(e) => {
              console.log("e: ", e.target.value);
              setStatus(e.target.value);
            }}
          >
            <Radio.Button value="All">All</Radio.Button>
            <Radio.Button value="Pending">Pending</Radio.Button>
            <Radio.Button value="Approved">Approved</Radio.Button>
            <Radio.Button value="Rejected">Reject</Radio.Button>
          </Radio.Group>
        </div>
      </PageHeader>
      <Modal title="Create Withdrawal Form"
            visible={manualWithdraw}
            footer={null}
            getContainer={false}
            onCancel ={() => {
              setManualWithdrawVisible(false);
              setHideBank(true);
              clearAllFields();
            }}
          >
          <div>
            <div>
                <Form form={form} layout="vertical" name="withdrawform">
                  <Form.Item
                  label="Phone Number"
                  required={true}
                  >
                    <Input.Group compact>
                        <Select value={countryCode}
                          onChange={selectCountryCode}>
                          <Option value="66">+66</Option>
                          <Option value="60">+60</Option>
                        </Select>
                        <Input style={{width:"66%"}} defaultValue="" placeholder="phone number" value={phoneNum}
                          onChange={(e)=> {
                            setPhoneNum(e.target.value.replace(/\D|^0/g, ""));
                            handleChangeMobile();
                          }}
                          //onPressEnter={verifyMobile}
                          onBlur={verifyMobile}
                        />
  
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                  label="Withdrawal Type"
                  name="withdrawType"
                  required={true}
                  >
                      <Select
                        dropdownStyle={{ position: "fixed" }}
                        value={withdrawType}
                        onChange = {handleWithdrawType}
                        style={styles.textbox}
                        disabled={withdrawSelect}
                      >
                        <Option value="comm2bank">Commission to Bank</Option>
                        <Option value="comm2wallet">Commission to Wallet</Option>
                      </Select>

                  </Form.Item>
                  <Form.Item
                      id="bankCode"
                      label="Bank"
                      hidden={hideBank}
                    >
                      <Select
                            style={styles.formItem}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              return option.children.includes(input);
                            }}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            value={bankcode}
                            // disabled={changeBank}
                            style={{width:"81%"}}
                            onChange={(value, event) => {
                              setBankCode(value);
                              //Search Bank Acc Name
                              for (var i = 0; i < csAvaBankList.length; i++) {
                                if (csAvaBankList[i].bankAcc === value) {
                                  setBankCode(csAvaBankList[i].bankcode);
                                  form.setFieldsValue({
                                    bankcode: csAvaBankList[i].bankcode,
                                  });
                                }
                              }
                            }}
                          >
                            {csAvaBankList.map((option) => {
                              return (
                                <Option key={option.bankcode} value={option.bankcode}>
                                  {option.bank} 
                                </Option>
                              );
                            })}
                          </Select>
                    </Form.Item>
                  <Form.Item
                  initialValue={acctno}
                    name="bankaccnum"
                    label="Bank Account Number"
                    hidden={hideBank}
                  >
                        <div>
                          {acctno}
                        </div>    
                  </Form.Item>
                  <Form.Item name="bankaccname" label="Bank Acc Name" hidden={hideBank}>
                    {acctname}
                  </Form.Item>
                  <Form.Item
                    name="withdrawAmt"
                    label={minWithdrawAmtLable}
                    rules={[
                      {
                        required: true,
                        message: "Please input the amount!",
                      },
                    ]}
                  >      
                        {/* <Input
                          type={"number"}
                          value={withdrawAmt}
                          onChange={(e) => {
                            setWithdrawAmt(Number(e.target.value));
                          }}
                          
                        />    */}
                      <InputNumber
                        onChange={(value) => {
                          setWithdrawAmt(value);
                        }}
                        min={minWithdrawLimit}
                      />
                  </Form.Item>
                  <Button type="primary" onClick={onSubmitForm} disabled={withdrawDisable}>
                    Submit
                  </Button>  
                  <Button
                    onClick={() => clearAllFields()}
                    hidden={true}
                  >
                    Reset
                  </Button>       
                </Form>
            </div>
          </div>
      </Modal>
      <div style={styles.tableContainer}>
        <Table
          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: settings.pageSizeOptions,
            style: styles.paginationStyle,
            alignmentBottom: 'right',
          }}
          columns={columns}
          dataSource={data}
          loading={tableLoading}
        />
      </div>
    </div>
  );
}
