import React, { useState, useEffect } from "react";
import {
  Table,
  Form,
  Button,
  PageHeader,
  Modal,
  Input,
  Spin,
  Select,
  Upload,
  Radio,
  Tooltip,
  Badge,
} from "antd";
import Highlighter from "react-highlight-words";
import { QuestionCircleOutlined, UploadOutlined, SearchOutlined, InfoCircleFilled } from "@ant-design/icons";
import { 
    csTopupReceiptList,
    csCreateTopUpReceipt,
    uploadReceiptV3,
    getReqToken,
    getUserWalletBal,
} from "../../config/networkConfig";
import { isMobile } from "react-device-detect";
import { settings } from "../../config/appConfig";
import md5 from "md5";
import { useSelector } from "react-redux";
import { fileDomain } from "../../config/config";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, sharedConfirmationAlert, } from "../../common/constants/sharedMsgBox";

export default function UploadTopupReceipt() {
const { confirm } = Modal;

const { role, userId, companyId } = useSelector((state) => state.login);

const [status, setStatus] = useState("Pending");
const [originData, setOriginData] = useState([]);
const [data, setData] = useState([]);
const [tableLoading, setTableLoading] = useState(false);

const [formUploadTopupReceipt] = Form.useForm();
const [fileList, setFileList] = useState([]);
const [userWalletBalance, setUserWalletBalance] = useState(0);
const [uploadReceiptVisible, setUploadReceiptVisible] = useState(false);
const [createSubmit, setCreateSubmit] = useState(true);
const [uploadTopupReceiptLoading, setUploadTopupReceiptLoading] = useState(false);

const [searchText, setSearchText] = useState("");

useEffect(() => {
    retrieveUserBal();
}, []);

useEffect(() => {
    TopupReceiptList();
}, [status]);

const retrieveUserBal = async () => {
    getUserWalletBal({userId: userId})
        .then((response) => response.json())
        .then((json) => {
            if (json.status === "ok") {
            setUserWalletBalance(json.data['userBal']);
            }
        })
        .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
        });
};

const TopupReceiptList = () => {
    var count = 0;
    setTableLoading(true);
    csTopupReceiptList({})
        .then((response) => response.json())
        .then((json) => {
        if (json.status === "ok") {
            const newData = Object.keys(json.data).length
            ? json.data.map((x) => {
                setTableLoading(false);
                return {
                    key: count++,
                    id : x.id,
                    userId : x.userId,
                    sysCreateTime: x.sysCreateTime,
                    mobile : x.mobile,
                    bankAcctNameIn : x.bank,
                    bankAcctIn : x.acc,
                    bankNameIn : x.accName,
                    bankNameOut : x.fromBankCode,
                    bankAcctOut : x.fromBankAcc,
                    bankTime : x.bankTime,
                    receipt : x.receipt,
                    actionStatus:
                    x.status === 0
                        ? "Pending"
                        : x.status === 1
                        ? "Approved"
                        : x.status === 8
                        ? "FollowUp"
                        : x.status === 9
                        ? "Rejected"
                        : "-",
                };
                })
            : [];

            setOriginData(newData);

            if (Object.keys(json.data).length == 0) {
              setTableLoading(false);
            }

            const userRelatedRecord = newData.filter((val) => {
                return val.userId === userId;
            });

            const pending = userRelatedRecord.filter((val) => {
              return val.actionStatus === "Pending";
            });
            const approve = userRelatedRecord.filter((val) => {
              return val.actionStatus === "Approved";
            });
            const followup = userRelatedRecord.filter((val) => {
              return val.actionStatus === "FollowUp";
            });
            const reject = userRelatedRecord.filter((val) => {
              return val.actionStatus === "Rejected";
            });

            const sortData =
            status === "Pending"
                ? pending
                : status === "Approved"
                ? approve
                : status === "Rejected"
                ? reject
                : status === "FollowUp"
                ? followup
                : [...userRelatedRecord];

            setData(sortData);

        }
        else {
            setTableLoading(true);
            alertData.title = "Failed";
            alertData.msg = json.msg;
            sharedFailAlert(alertData);
        }
        })
        .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
        });
}

const onSubmitForm = () => {
    if (JSON.stringify(fileList) == '[]') {
        alertData.title = "Field Required";
        alertData.msg = "Please upload Bank Receipt !";
        sharedErrorAlert(alertData);
        return;
    }
  
    confirm({
      className: "confirmationModalStyle",
      title: 'Confirmation',
      centered: true,
      icon: <QuestionCircleOutlined />,
      content: 'Are you sure you want to proceed?',
      onOk() {
        console.log('OK');
        setUploadTopupReceiptLoading(true);
        onSubmitUploadTopupReceipt();
      },
      onCancel() {},
    });
};

const uploadFile = async () => {
    const formData = new FormData();
    formData.append("fileReceipt", fileList[0]);
    if (fileList[0] !== undefined){
        const response = await uploadReceiptV3(formData);
        let result = await response.json();
        return result;
    }
};

const onSubmitUploadTopupReceipt = async () => {
    const runUploadFile = await uploadFile();
    if (runUploadFile.status === "ok"){
        let filename = runUploadFile.data !== undefined ? runUploadFile.data.filename : '';
        let requestData = {
            userid: userId,
            bankcode : "-",
            acctnum : "-",
            acctname : "-",
            fromBankCode : "-",
            fromBankAcc : "-",
            filename : filename,
            bankTime : 0,
            path : `${fileDomain}`,
            by : userId,
            reqToken     : md5(getReqToken('depositManualCreate')),
        };

        csCreateTopUpReceipt(requestData)
            .then((response) => response.json())
            .then((json) => {
            if (json.status === "ok") {
                onCancelUploadTopupReceipt();
                TopupReceiptList();
                setUploadTopupReceiptLoading(false);
                alertData.title = "Success";
                alertData.msg = json.msg;
                sharedSuccessAlert(alertData);
            }
            else {
                setUploadTopupReceiptLoading(false);
                alertData.title = "Error";
                alertData.msg = json.msg;
                sharedErrorAlert(alertData);
            }
        })
        .catch((error) => {
            console.log("error: ", error);
            alertData.title = "Error";
            alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
            sharedErrorAlert(alertData);
        });
    }
}

const onCancelUploadTopupReceipt = () => {
    setFileList([]);
    setUploadReceiptVisible(false);
}

const fileProps = {
    listType: "picture",
    onRemove: (file) => {
        setFileList([]);
    },
    fileList: fileList,
    beforeUpload: (file) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
        let newFileList = [];
        newFileList.push(file);
        setFileList(newFileList);
        });

        reader.readAsDataURL(file);
        return false;
    },
    onChange: (info) => {
        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        let newFileList = [...info.fileList];
        newFileList = newFileList.slice(-1);

        // 2. Read from response and show file link
        newFileList = newFileList.map((file) => {
        if (file.response) {
            file.url = file.response.url;
        }

        return file;
        });

        setFileList(newFileList);
        return false;
    },
};

const onCreateUploadReceipt = () => {
    return (
        <div>
            <div>
                <Form.Item
                    label="User Credit Balance"
                >
                    {userWalletBalance}
                </Form.Item>
                <Form form={formUploadTopupReceipt} layout="vertical" name="uploadTopupReceiptForm">
                    <Form.Item
                        name="bankReceipt"
                        label="Bank Receipt"
                        rules={[
                            {
                            required: true,
                            message: "Please upload Bank Receipt !",
                            },
                        ]}
                        >
                        <Upload {...fileProps} fileList={fileList}>
                            <Button icon={<UploadOutlined />}>Select File</Button>
                        </Upload>
                    </Form.Item>
                    
                    { createSubmit?
                    <Button 
                        type="primary" 
                        onClick={onSubmitForm} 
                        loading={uploadTopupReceiptLoading}>
                        Submit
                    </Button>: <Spin style={{margin:7}}></Spin>
                     } 
                </Form>
            </div>
        </div>
    )
}

let searchInput = null;

const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
};

const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
};

const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex=="newNickname"? "nickname":dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
});

const tableColumn = [
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "sysCreateTime",
      width: "30%",
      ellipsis: true,
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      fixed:"left",
    },
    {
        ellipsis: true,
        align: "center",
        title: "Receipt ID",
        dataIndex: "id",
        width :"70%",
        ...getColumnSearchProps("id"),
        render : (text) => {
            return <Tooltip title={text}>
                <label>{text}</label>
            </Tooltip>
      }
    },
    {
      align: "center",
      title: "User Mobile",
      dataIndex: "mobile",
      width: "60%",
      ellipsis: true,
      fixed:"left",
    },
    {
        ellipsis: true,
        align: "center",
        title: "Status",
        dataIndex: "action",
        width: "150px",
        render: (text, record) => {
            switch (record.actionStatus) {
            case "Pending":
                return <Badge status="processing" text="Pending" />;
  
            case "FollowUp":
                return <Badge color="orange" status="warning" text="To Be Resolve" />;
  
            case "Rejected":
                return <Badge status="error" text="Rejected" />;
  
            case "Approved":
                return <>
                    <Badge status="success" text="Approved" />&nbsp;&nbsp;
                        {/* {
                        record.pptTrxnId!='00000000-0000-0000-0000-000000000000' ?
                        (                   
                            <Tooltip title="View Details">
                            <Button type="primary" shape="circle" icon={<InfoCircleFilled />} 
                            onClick={()=>{
                                // setEditMode(false);
                                // ViewPPTDetails(record);
                            }
                            }
                            />
                        </Tooltip>
                        ):
                        ""
                        } */}
  
                    </>
            default:
                return <></>;
            }
      }
    }
];

const styles = {
    pageHeaderContainer: {
        justifyContent: isMobile ? "" : "space-between",
        display: "flex",
        flexDirection: isMobile ? "column" : "",
        alignItems: isMobile ? "center" : "",
    },
    refreshBtn: {
        marginLeft: 10,
    },
    tableContainer: {
        backgroundColor: "#FFFFFF",
        padding: 24,
    },
    paginationStyle: {
        marginTop: 50,
    },

    hide :{
        display:"none",
    }
};

return (
    <div className="UploadTopupReceipt">
        <PageHeader ghost={false}>
            <div style={styles.pageHeaderContainer}>
                <div>
                <Button
                    style={styles.refreshBtn}
                    type="primary"
                    onClick={() => {
                      TopupReceiptList();
                    }}
                >
                    Refresh Page
                </Button>

                <Button
                    type="primary"
                    style={styles.refreshBtn}
                    onClick={() => {
                        setUploadReceiptVisible(true);
                        retrieveUserBal();
                    }}
                >
                    Upload Receipt
                </Button>
                </div>
                <Radio.Group
                    buttonStyle={"solid"}
                    value={status}
                    onChange={(e) => {
                        setStatus(e.target.value);
                        console.log(`status...`,e.target.value)
                    }}
                >
                    <Radio.Button value="All">All</Radio.Button>
                    <Radio.Button value="Pending">Pending</Radio.Button>
                    <Radio.Button value="Approved">Approved</Radio.Button>
                    <Radio.Button value="FollowUp">Follow Up</Radio.Button>
                    <Radio.Button value="Rejected">Reject</Radio.Button>
                </Radio.Group>
            </div>
        </PageHeader>

    <div style={styles.tableContainer}>
        <Table
            loading={tableLoading}
            columns={tableColumn}
            dataSource={data}
            scroll={{ x: isMobile ? "110%" : "100%", y: null }}
            pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: settings.pageSizeOptions,
            style: styles.paginationStyle,
            alignmentBottom: 'right',
            }}
        >
        </Table>
    </div>

    <Modal
      title="Upload Top Up Receipt Form"
      visible={uploadReceiptVisible}
      getContainer={false}  
      onCancel= {() => {
        onCancelUploadTopupReceipt();
      }}
      okButtonProps={({style: styles.hide})}
      cancelButtonProps={{ type: "primary", loading: uploadTopupReceiptLoading}}   
      cancelText="Close"
    >
      { onCreateUploadReceipt() }
    </Modal>

  </div>
    )
}