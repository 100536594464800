import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import "./ExpandCollapseComponent.css";
import {
  ExpandCollapseProvider,
  useExpandCollapseContext,
} from "../context/ExpandCollapseContext";

function ExpandCollapseComponentInternal({
  className,
  style,
  expandedHeaderComponent,
  collapsedHeaderComponent,
  children,
  defaultExpanded = false,
  isSticky = false,
  setDuringAnimationLevel,
}) {
  const {
    level,
    cumulativeHeaderHeight,
    addHeaderHeight,
    duringAnimationLevel,
  } = useExpandCollapseContext();
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [isDuringAnimation, setIsDuringAnimation] = useState(false);
  const headerRef = useRef(null);
  const headerHeightRef = useRef(null);
  const cumulativeHeaderHeightRef = useRef(null);
  const expandCollapseContainerClassName = "expand-collapse-container";

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (headerRef.current) {
        const headerHeight = headerRef.current.getBoundingClientRect().height;
        addHeaderHeight(headerHeight);
        headerHeightRef.current = headerHeight;
      }
    });

    observer.observe(headerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [headerRef]);

  useEffect(() => {
    cumulativeHeaderHeightRef.current = cumulativeHeaderHeight;
  }, [cumulativeHeaderHeight]);

  useEffect(() => {
    // const firstPosition = headerRef.current.getBoundingClientRect().top;
    const element = document.querySelector(
      `.${expandCollapseContainerClassName}`
    );
    const style = getComputedStyle(element);
    const transitionDuration = style.transitionDuration || "0s";
    // Convert duration from seconds to milliseconds
    const durationMs = parseFloat(transitionDuration) * 1000;
    // Timeout matches the CSS transition duration
    const timer = setTimeout(() => {
      setIsDuringAnimation(false);

      // const secPosition = headerRef.current.getBoundingClientRect().top;
      // const scrollViewRef = findScrollableParent(headerRef.current);
      //       scrollViewRef.scrollTo({
      //   behavior: "smooth",
      //   top: cumulativeHeaderHeightRef.current - headerHeightRef.current,
      // });
      // scrollViewRef.scrollBy({ top: firstPosition-secPosition, left: 0, behavior: 'smooth' });
    }, durationMs); // Assuming 300ms is your transition duration
    return () => clearTimeout(timer);
  }, [expanded]);

  useLayoutEffect(() => {
    if (isDuringAnimation) {
      setDuringAnimationLevel(level);
    } else {
      setDuringAnimationLevel(null);
    }
  }, [isDuringAnimation]);

  const toggleExpand = () => {
    setIsDuringAnimation(true);
    setExpanded(!expanded);
  };

  function findScrollableParent(element) {
    while (element && element !== document.body) {
      const { overflowY } = window.getComputedStyle(element);
      if (
        (overflowY === "scroll" || overflowY === "auto") &&
        element.scrollHeight > element.clientHeight
      ) {
        return element;
      }
      element = element.parentNode;
    }
    return window; // Fallback to window if no scrollable parent is found
  }

  return (
    // <ExpandCollapseProvider parentIsDuringAnimation={isDuringAnimation}>
    <div className={className} style={style}>
      <div
        ref={headerRef}
        className={`expand-collapse-click-container ${
          (!duringAnimationLevel || duringAnimationLevel >= level) && isSticky
            ? "expand-collapse-sticky-container"
            : ""
        }`}
        style={{
          top: `${cumulativeHeaderHeight}px`,
          zIndex: `${1000 - level}`,
        }}
        onClick={toggleExpand}
      >
        {expanded ? expandedHeaderComponent : collapsedHeaderComponent}
      </div>
      <div
        className={`${expandCollapseContainerClassName} ${
          expanded ? "is-open" : ""
        }`}
      >
        <div
          className="expand-collapse-children"
          style={!isDuringAnimation && expanded ? { overflow: "initial" } : {}}
        >
          {children}
        </div>
      </div>
    </div>
    // </ExpandCollapseProvider>
  );
}

export default function ExpandCollapseComponent(props) {
  const [duringAnimationLevel, setDuringAnimationLevel] = useState(null);

  return (
    <ExpandCollapseProvider duringAnimationLevel={duringAnimationLevel}>
      <ExpandCollapseComponentInternal
        {...props}
        setDuringAnimationLevel={setDuringAnimationLevel}
      />
    </ExpandCollapseProvider>
  );
}
