import React, { useState, useEffect, useRef} from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  message,
  PageHeader,
  Badge,
  Radio,
  Divider,
  Tooltip,
  Row,
  DatePicker,
} from "antd";
import { SearchOutlined ,InfoCircleFilled} from "@ant-design/icons";
import {
  csWithdrawList,
  csWithdrawCheck,
  csWithdrawApprove,
  csWithdrawApproveV2,
  csWithdrawRejectV3,
  csWithdrawUpdate,
  getReqToken,
  csMemberWalletTrxn,
} from "../../config/networkConfig";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { settings } from "../../config/appConfig";
import { CS_USER_TYPE } from "../../common/constants/csuserType";
import { PROCESS_TYPE } from "../../common/constants/processType";
import md5 from "md5";
import moment from "moment";
import { FormatPt,ShowMobileNumber } from "../../library/numbers";
import { witMgmtAppAppr } from "../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";

export default function WithdrawManagement() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [originData, setOriginData] = useState([]);
  const [data, setData] = useState([]);
  const [statusVisible, setStatusVisible] = useState(false);
  const [rejectVisible, setRejectVisible] = useState(false);
  const { userId, username ,role ,issuper} = useSelector((state) => state.login);
  const [activeKey, setActiveKey] = useState("");
  const [confirmAmount, setConfirmAmount] = useState(0);
  const [mbBank, setMbBank] = useState("");
  const [mbBankAcct, setMBbankAcct] = useState("");
  const [searchText, setSearchText] = useState("");
  const [userBal, setUserBal] = useState("");
  const [userTurnover, setUserTurnover] = useState("");
  const [reqTurnover, setReqTurnover] = useState("");
  const [winLose, setWinLose] = useState("");
  const [maxWithdrawAmt, setMaxWithdrawAmt] = useState("");
  const [checkStatusBtn, setCheckBtn] = useState(false);
  const [reason, setReason] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [userRecord, setUserRecord] = useState([]);
  const [nickname, setNickname] = useState("");
  const [status, setStatus] = useState("Pending");

  //Customer Bank Account Details
  const [custBankCode, setCustBankCode] = useState("");
  const [custBankName, setCustBankName] = useState("");
  const [custBankAccName, setCustBankAccName] = useState("");
  const [custBankAcc, setCustBankAcc] = useState("");


  //Update Transactions
  const [updateTrxVisible,setUpdateTrxVisible ] = useState(false);
  const [withdrawTrxnid,setWithdrawTrxnId] = useState("");

  //Wallet Transaction 
  const [csSearchMemberId,setCSSearchMemberId] = useState("");
  const [viewTrx,setViewTrx] = useState("");
  const [puppetVisible, setPuppetVisible]= useState(false);
  const [csMemberNickname,setCSMemberNickname] = useState("");
  const [csMemberWalletDt,setCSMemberWalletDt] = useState("");
  const [csMemberWalletBal, setCSMemberWalletBal] = useState(0);
  const [csMemberWalletComm,setCSMemberWalletComm] = useState(0);
  const [filteredPptTrxn,setFilterPptTrxn]= useState([]);
  const [trxLoading, setTrxLoading] = useState(false);
  const [csGameUser, setCSGameUser] = useState("");
  const [trxDateFrom, setTrxDateFrom] = useState("");
  const [trxDateTo, setTrxDateTo] = useState("");
  const reportDateFrom = useRef(null);
  const reportDateTo = useRef(null);

  useEffect(() => {
    withdrawData();

    // eslint-disable-next-line
  }, [status]);


  useEffect(() => {
    setTrxDateFrom(trxDateFrom);
  }, [trxDateFrom]);

  useEffect(() => {
    setTrxDateTo(trxDateTo);
  }, [trxDateTo]);


  useEffect(() => {
    setCSSearchMemberId(csSearchMemberId);
  }, [csSearchMemberId]);

  useEffect(() => {
    setCSMemberNickname(csMemberNickname);
  }, [csMemberNickname]);

  ////#region Permissions 


  ////#endregion


  //#region Date Picker
  const DateTrxnFrom = () => {
     return (
          <DatePicker
          key="2"
          style={{...styles.datePickerStyle, ...styles.refreshBtn}}
          onChange={trxDatePicker}
          value={trxDateFrom}
          ref={reportDateFrom}
          refs
          placeholder={"Select Date From"}
          disabledDate={(current)=> {
            return current > moment().endOf('day');
            //return current > moment().subtract(1, 'day');
          }}/>
     )
  }

  const DateTrxnTo = () => {
     return (
          <DatePicker
          key="3"
          style={{...styles.datePickerStyle, ...styles.refreshBtn}}
          onChange={trxDatePicker2}
          value={trxDateTo}
          ref={reportDateTo}
          placeholder={"Select Date To"}
          disabledDate={(current)=> {
            let dateFr = trxDateFrom;
            if (dateFr) {
              return current < dateFr || current > moment().add(1, 'day');
              //return current < dateFr || current > moment().subtract(1, 'day');
            }else {
              return  current > moment().subtract(1, 'day');
            }
          }}
          defaultPickerValue={()=>{
              let dateFr = trxDateFrom;
              if (dateFr) {
                  return dateFr;
              }
          }}
          />
     )
  }
  ////#endregion

  ////#region Button
  const BtnViewTrxn = (props) => {

      const record = {
          userid   : props.userid,
          datefrom : moment(props.trxnDate).subtract(1,'day').format("YYYY-MM-DD"),
          dateto   : props.trxnDate,
          nickname : props.nickname,
      }

      return (
        <Tooltip title="View Transaction">
          <Button type="primary" shape="circle" icon={<InfoCircleFilled />} 
            onClick={()=>{
                  setCSSearchMemberId(record.userid);
                  setCSMemberNickname(record.nickname);
                  ViewPPTDetails(record);
              }
            }
            loading={loadings[3]}
            style={{...styles.refreshBtn}}
          />
       </Tooltip> 
      )
  }
  ////#region 

  //#region functions
  const searchMemberTrxn = ()=> {
    if (!trxDateFrom) {
      reportDateFrom.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date From !";
      sharedErrorAlert(alertData);
      return;
    }

    if (!trxDateTo) {
      reportDateTo.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date To !";
      sharedErrorAlert(alertData);
      return;
    }

    const requestData = {
      userid : csSearchMemberId,
      datefrom: moment(trxDateFrom).format('YYYY-MM-DD'),
      dateto: moment(trxDateTo).format('YYYY-MM-DD'),
      nickname : csMemberNickname,
    }

    ViewPPTDetails(requestData);
  }

  const clearTrxnSearch = () => {
    setTrxDateFrom(""); 
    setTrxDateTo("");

    // if (viewTrx=="trxn") {
    //   ViewPPTDetails();
    // }else {
    //   ViewGameDetails();

    // }
};

  const trxDatePicker = (date, dateString) => {
    setTrxDateFrom(date);
  };

  const trxDatePicker2 = (date, dateString) => {
    setTrxDateTo(date);
  };


  const handleCancelMemberTrxn = () => {
    setPuppetVisible(false);
    setCSSearchMemberId("");
    setCSMemberNickname("");
    setCSMemberWalletDt("N/A");
    setCSMemberWalletBal("N/A");
    setCSMemberWalletComm("N/A");
    setTrxDateFrom("");
    setTrxDateTo("");
  }; 


  const ViewPPTDetails = async (record) => {

    setPuppetVisible(true);  
    setTrxLoading(true);

    let requestData = {
      userid   : record.userid,
      datefrom : record.datefrom,
      dateto   : record.dateto,
      nickname : record.nickname
    }

    setCSMemberNickname(record.nickname);
    
    let respTrxn = await csMemberWalletTrxn(requestData);
    let resTrxn = await respTrxn.json();

    if (resTrxn.status === "ok") {
      if (resTrxn.data.detail && resTrxn.data.detail.length) {
        setFilterPptTrxn(resTrxn.data.detail);
        
        let dates = resTrxn.data.detail.map(item => moment(item.date)), maxDate = moment.max(dates) , minDate = moment.min(dates);
        setTrxLoading(false);
      }else{
        setFilterPptTrxn([]);
        setTrxLoading(false);
      }

      if (resTrxn.data.summary && resTrxn.data.summary.length) {
          let walletSumm = resTrxn.data.summary[0];
          setCSMemberWalletDt(walletSumm.Dt);
          setCSMemberWalletBal(`${FormatPt(walletSumm.Balance)}`);
          setCSMemberWalletComm(`${FormatPt(walletSumm.Comm)}`);
      }else{
        setCSMemberWalletDt("N/A");
        setCSMemberWalletBal("N/A");
        setCSMemberWalletComm("N/A");
      }
    }

    setTrxDateFrom(moment(requestData.datefrom,'YYYY-MM-DD'));
    setTrxDateTo(moment(requestData.dateto,'YYYY-MM-DD'));
    
    setTrxLoading(false);

  }

  ////#endregion

  const withdrawData = () => {
    setTableLoading(true);
    var count = 0;
    csWithdrawList({ userId: userId })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          const newData = Object.keys(json.data).length
            ? json.data.map((x) => {
                setTableLoading(false);
                return {
                  key: count++,
                  trxnId: x.trxnId,
                  userId: x.userId,
                  nickname: x.nickname,
                  mobile  : x.mobile,
                  newNickname: ShowMobileNumber(role,x.nickname, x.mobile),
                  amount: x.amount * -1,
                  mbbank: x.mbBank,
                  date: x.dateTime,
                  bankNameOut: x.bank,
                  bankAcctOut: x.bankAcc,
                  mbbankAcct: x.mbBankAcc,
                  processBy: x.processBy,
                  puppetRemark : x.puppetRemark,
                  remark : x.remark,
                  // new details
                  custBankCode : x.mbBank,
                  custBankName : x.mbBankName,
                  custBankAccName: x.mbBankAccName,
                  custBankAcc    : x.mbBankAcc,
                  // new details  
                  checkStatus:
                    x.status === "0"
                      ? "Pending"
                      : x.status === "1"
                      ? "Approved"
                      : x.status === "3"
                      ? "Completed"
                      : x.status === "9"
                      ? "Rejected"
                      : "-",
                  // x.status === "0"
                  //   ? "Pending"
                  //   : x.status === "1"
                  //   ? "Approved"
                  //   : x.status === "2"
                  //   ? "Processing"
                  //   : x.status === "3"
                  //   ? "Completed"
                  //   : "Rejected",
                  // actionStatus:
                  //   x.status === "0"
                  //     ? "Check"
                  //     : x.status === "1"
                  //     ? "Reject"
                  //     : "-",
                };
              })
            : [];
          setOriginData(newData);

          const pending = newData.filter((val) => {
            return val.checkStatus === "Pending";
          });
          const approve = newData.filter((val) => {
            return val.checkStatus === "Approved";
          });
          const reject = newData.filter((val) => {
            return val.checkStatus === "Rejected";
          });
          const sortData =
            status === "Pending"
              ? pending
              : status === "Approved"
              ? approve
              : status === "Rejected"
              ? reject
              : [...newData];

          // const pending = newData.filter((val) => {
          //   return val.checkStatus === "Pending";
          // });

          // const approved = newData.filter((val) => {
          //   return val.checkStatus === "Approved";
          // });

          // const processing = newData.filter((val) => {
          //   return val.checkStatus === "Processing";
          // });

          // const completed = newData.filter((val) => {
          //   return val.checkStatus === "Completed";
          // });

          // const rejected = newData.filter((val) => {
          //   return val.checkStatus === "Rejected";
          // });

          // const sortData =
          //   status === "Pending"
          //     ? pending
          //     : status === "Approved"
          //     ? approved
          //     : status === "Rejected"
          //     ? rejected
          //     : status === "Processing"
          //     ? processing
          //     : status === "Completed"
          //     ? completed
          //     : [
          //         ...pending,
          //         ...processing,
          //         ...approved,
          //         ...completed,
          //         ...rejected,
          //       ];

          setData(sortData);

          setStatusVisible(false);
        } else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);

          setTableLoading(true);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
  };

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });

  //#region Columns
  const wthColumns = [
    {
      title: "Data / Time",
      dataIndex: "date",
      width: "200px",
      ellipsis: true,
    },
    // {
    //   title: "userId",
    //   dataIndex: "userId",
    //   // ...getColumnSearchProps("nickname"),
    //   width: "200px",
    //   // ellipsis: true,
    // },
    // {
    //   title: "trxnId",
    //   dataIndex: "trxnId",
    //   width: "200px",
    // },
    //New Column
    // {
    //   title: "custBankCode",
    //   dataIndex: "custBankCode",
    //   width: "200px",
    // },
    // {
    //   title: "custBankAccName",
    //   dataIndex: "custBankAccName",
    //   width: "200px",
    // },

    // {
    //   title: "custBankAcc",
    //   dataIndex: "custBankAcc",
    //   width: "200px",
    // },
    //New Column

    {
      title: "MB Nickname",
      dataIndex: "newNickname",
      ...getColumnSearchProps("newNickname"),
      //dataIndex: "nickname",
      //...getColumnSearchProps("nickname"),
      width: "200px",
      ellipsis: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
      ...getColumnSearchProps("amount"),
      width: "200px",
      ellipsis: true,
    },

    {
      ellipsis: true,
      title: "Bank Name (Out)",
      dataIndex: "bankNameOut",
      width: "200px",
      ...getColumnSearchProps("bankNameOut"),
    },
    {
      // fixed: "right",
      ellipsis: true,
      title: "Status",
      dataIndex: "status",
      width: "250px",
      render: (text, record) => {
        switch (record.checkStatus) {
          case "Pending":
            return (
              <>
                <Button
                  style={styles.checkBtn}
                  type="primary"
                  onClick={(event) => {
                    check(record, event.target);
                  }}
                >
                  Check 
                </Button>
                <span>
                  &nbsp;&nbsp;
                  <Tooltip title="Update to Manual Transaction?">
                    <Button
                      style={styles.actionManualBtn}
                      name={"UpdateToManual"}
                      type="primary"
                      onClick={(event) => {
                        //pendingBtn(record, event.target);
                        updateTrx(record);
                      }}
                    >
                      Manual
                    </Button>
                  </Tooltip>
                  <BtnViewTrxn userid={record.userId} trxnDate={record.date} nickname={record.nickname}/>
                </span>
              </>

            );

          case "Rejected":
            return <>
                      <Badge status="error" text="Rejected" />
                      <BtnViewTrxn userid={record.userId} trxnDate={record.date} nickname={record.nickname}/>
                   </>;
            // return <Badge status="error" text="Rejected" />;

          case "Approved":

            return <>
                    <Badge text="Approved" color={"blue"} />
                    <BtnViewTrxn userid={record.userId} trxnDate={record.date} nickname={record.nickname}/>
                   </>;

            //return <Badge text="Approved" color={"blue"} />;

          // case "Processing":
          //   return (
          //     <Badge
          //       status="processing"
          //       text={
          //         record.processBy !== username
          //           ? `Processing by ${record.processBy}`
          //           : "Processing"
          //       }
          //     />
          //   );

          case "Completed":
            return <>
                      
                      <Badge status="success" text="Completed" />
                      <BtnViewTrxn userid={record.userId} trxnDate={record.date} nickname={record.nickname}/>
                   </>;
            //return <Badge status="success" text="Completed" />;

          default:
            return <>
                    
                    <Badge status="default" text="ERROR" />
                    <BtnViewTrxn userid={record.userId} trxnDate={record.date} nickname={record.nickname}/>
                  </>;
            // return <Badge status="default" text="ERROR" />;
        }
      },
    },
    {
      title: "Remark",
      dataIndex: "remark",
      width: "200px",
      style: [{"word-wrap":"break-word"}],
      render: (text, record) => {

        switch (record.checkStatus) {
          case "Rejected":
            return <>{text}</>;

          case "Completed":
            if (record.puppetRemark !=='') {
               return <>{record.puppetRemark}</>
            }else {
                if (text.includes("wallet2bank")){
                  return "";
                }
            }
            break;

          case "Approved":
            if (text.includes("wallet2bank")){
              return "";
            }else {
              return <>{text}</>
            }


          default:
            return <></>;
        }
      }
    },
    // {
    //   fixed: "right",
    //   ellipsis: true,
    //   title: "Action",
    //   dataIndex: "action",
    //   width: "150px",
    //   render: (text, record) => {
    //     switch (record.actionStatus) {
    //       case "Reject":
    //         return (
    //           <Tooltip title="Press Me!">
    //             <Button
    //               style={{
    //                 backgroundColor: "red",
    //                 border: "none",
    //               }}
    //               name="acceptBtn"
    //               id="acceptBtn"
    //               type="primary"
    //               loading={loadings[0]}
    //               onClick={(event) => {
    //                 reject(record, event.target);
    //               }}
    //             >
    //               <div id="acceptBtn">Reject</div>
    //             </Button>
    //           </Tooltip>
    //         );

    //       case "Check":
    //         return (
    //           <Button
    //             style={{ backgroundColor: "#0000FF", border: "none" }}
    //             type="primary"
    //             onClick={(event) => {
    //               check(record, event.target);
    //             }}
    //           >
    //             Check
    //           </Button>
    //         );

    //       default:
    //         return <div>-</div>;
    //     }
    //   },
    // },
  ];

  let pptColumns = [
    {
      align: "center",
      title: "Date Time",
      dataIndex:"date",
      width: "200px",
      ellipsis: true,
    },
    // {
    //   align: "center",
    //   title: "Trxn ID",
    //   dataIndex:"trxnID",
    //   width: "200px",
    //   // ellipsis: true,
    // },
    {
      align: "center",
      title: "Transaction Type",
      dataIndex: "trxnType",
      width: "200px",
      ellipsis: true,

      //filters: filterData(this)(i => i.trxnType),
      filters: [
        {
          text: 'Commision',
          value: 'Comm',
        },

        {
          text: 'Game-in',
          value: 'Game-In',
        },
        {
          text: 'Game-out',
          value: 'Game-Out',
        },
        {
          text: 'Promotion',
          value: 'Promotion',
        },
        {
          text: 'Recovery',
          value: 'Recovery',
        },
        {
          text: 'Topup',
          value: 'Topup',
        },
        {
          text: 'Withdraw',
          value: 'Withdraw',
        },
      ],
      filterMultiple: true,
      onFilter: (value, record) => record.trxnType.toLowerCase().indexOf(value.toLowerCase()) === 0 ,
      render: (text)=>{
         if (text.toLowerCase()=="topup") {
            return "Topup";
         }else {
           return text;
         }
      }
    },
    // {
    //   title: "Withdrawal Type",
    //   dataIndex: "trxnMode",
    //   width: "200px",
    //   ellipsis: true,
    // },
    {
      ellipsis: true,
      align: "center",
      title: "Amount",
      dataIndex: "amount",
      width: "150px",
      render : (text) => {
        return <>{FormatPt(text)}</>
      },
      //...getColumnSearchProps("amount"),
    }, 
    {
      ellipsis: true,
      align: "center",
      title: "Actual Amount",
      dataIndex: "actualAmount",
      width: "150px",
      render :(text)=> {
        if (text===0) {
            return <>-</>;
        }else {
            return <>{FormatPt(text)}</>
        }
      },
      ...getColumnSearchProps("actualAmount"),
    },   
    {
      ellipsis: true,
      align: "center",
      title: "Commission",
      dataIndex: "comm",
      width: "150px",
    },
    {
      ellipsis: true,
      align: "left",
      title: "Status",
      dataIndex: "status",
      width: "200px",
      render: (text, record) => {
        switch (text) {
            case 1:
                if (record.trxnType =="Withdraw") {
                    return <Badge text="Bank Process Complete" color={"blue"} />;
                }
                return <Badge text="Approved" color={"blue"} />;       
            case 3:
                return <Badge text="Approved" color={"blue"} />;
            case 9:
              return <Badge status="error" text="Rejected" />;
            default:
               return <span>{text}</span>;

        }
      }
    },
  ];
  ////#endregion

  // const reject = (record, event) => {
  //   setRejectVisible(true);
  //   setUserRecord(record);
  // };

  const rejectAction = () => {
    setRejectVisible(false);

    if (reason !== "") {
      var newData = [...originData];

      csWithdrawRejectV3({
        trxnId       : userRecord.trxnId,
        reason       : reason,
        userId       : userRecord.userId,
        rejectUserId : userId,
        processtype  : PROCESS_TYPE.APP,
        username     : username,
        reqToken     : md5(getReqToken('withdrawReject')),
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "ok") {
            newData[userRecord.key].actionStatus = "-";
            newData[userRecord.key].checkStatus = "Rejected";
            setOriginData(newData);
            setRejectVisible(false);
            setStatusVisible(false);
            setReason("");
            alertData.title = "Success";
            alertData.msg = json.msg;
            sharedSuccessAlert(alertData);
            form.resetFields();
            withdrawData();
          } else {
            alertData.title = "Failed";
            alertData.msg = json.msg;
            sharedFailAlert(alertData);
            form.resetFields();
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          alertData.title = "Error";
          alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
          sharedErrorAlert(alertData);
        });
    } else {
      alertData.title = "Field Required";
      alertData.msg = "Please fill in Reject Reason !";
      sharedErrorAlert(alertData);
    }
  };

  const check = (record, target) => {
    console.log(`check withdraw auto >> ${record.trxnId}`);
    //setStatusVisible(true);
    setUserRecord(record);
    setActiveKey(record.key);
    setConfirmAmount(originData[record.key].amount);
    setNickname(record.nickname);
    csWithdrawCheck({ userId: record.userId, witTrxnId: record.trxnId })
      .then((response) => response.json())
      .then((json) => {
        setUserBal(json.data.usrBal);
        setUserTurnover(json.data.usrTurnover);
        setWinLose(json.data.winLose);
        setCheckBtn(json.data.check);
        setMbBank(json.data.bankCode);
        setMBbankAcct(json.data.bankAcc);
        setReqTurnover(json.data.reqTurnover);
        setMaxWithdrawAmt(json.data.maxWit);
      });

    setCustBankCode(record.custBankCode);
    setCustBankName(record.custBankName);
    setCustBankAccName(record.custBankAccName);
    setCustBankAcc(record.custBankAcc);
    setStatusVisible(true);
  };

  const handleStatusClosed = (e) => {
    setStatusVisible(false);

    setCustBankCode("");
    setCustBankName("");
    setCustBankAccName("");
    setCustBankAcc("");

    form.resetFields();
  };

  const handleStatusApprove = async (index) => {
    var newData = [...originData];

    if (index > -1) {
      loadingTrue(index);
      csWithdrawApproveV2({
        trxnId     : newData[activeKey].trxnId,
        bankCode   : mbBank,
        bankAcc    : mbBankAcct,
        reqToken   : md5(getReqToken('withdrawApprove')),
        userid     : userId
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "ok") {
            newData[activeKey].checkStatus = "Approved";
            newData[activeKey].actionStatus = "Rejected";
            setOriginData(newData);
            setStatusVisible(false);
            form.resetFields();
            loadingFalse(index);
            alertData.title = "Success";
            alertData.msg = json.msg;
            sharedSuccessAlert(alertData);
            withdrawData();
          } 
          else {
            alertData.title = "Failed";
            alertData.msg = json.msg;
            sharedFailAlert(alertData);
            loadingFalse(index);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          alertData.title = "Error";
          alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
          sharedErrorAlert(alertData);
        });
      }
      else {
       loadingTrue(index);
       alertData.title = "Error";
       alertData.msg = "Connection not stable, please try again !";
       sharedErrorAlert(alertData);
      }
  };

  const handleStatusReject = () => {
    setRejectVisible(true);
  };

  const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 160,
    },
  };

  const statusForm = () => {
    return (
      <Form {...layout} form={form}>
        <Form.Item name={["member", "mbusername"]} label="Member Nickname">
          <div>{nickname}</div>
        </Form.Item>
        <Form.Item name={["member", "curlDepAmt"]} label="Current Balance">
          <div>{userBal}</div>
        </Form.Item>
        <Form.Item name={["member", "amount"]} label="Withdraw Amount">
          <div>{confirmAmount}</div>
        </Form.Item>
        <Form.Item name={["member", "amount"]} label="Max Withdraw Amount">
          <div>{maxWithdrawAmt}</div>
        </Form.Item>
        <Divider />
        <Form.Item name={["member", "mbbank"]} label="Bank">
          <div>{custBankName}</div>
          <div style={styles.hide}>{mbBank}</div>
        </Form.Item>
        <Form.Item name={["member", "mbbankAcct"]} label="Bank Account">
          <div>{custBankAcc}</div>
          <div style={styles.hide}>{mbBankAcct}</div>
        </Form.Item>
        <Divider />
        <Form.Item name={["member", "turnover"]} label="User Turnover">
          <div>{userTurnover}</div>
        </Form.Item>
        <Form.Item name={["member", "turnover"]} label="Require Turnover">
          <div>{reqTurnover}</div>
        </Form.Item>
        <Form.Item name={["member", "winLose"]} label="Win / Lose">
          <div>{winLose}</div>
        </Form.Item>
        <Form.Item style={styles.statusBottomBtn}>
          <Button type="primary" htmlType="submit" onClick={handleStatusClosed}>
            Cancel
          </Button>

            {   witMgmtAppAppr( role, confirmAmount)?
                     <>                     
                      <Button
                      id="approvebtn"
                      name="approvebtn"
                      style={styles.statusApproveBtn}
                      type="primary"
                      htmlType="submit"
                      loading={loadings[3]}
                      onClick={() => {
                        handleStatusApprove(3);
                      }}
                    >
                      Approve
                    </Button>
                    <Button
                      danger
                      id="rejectbtn"
                      name="rejectbtn"
                      type="primary"
                      htmlType="submit"
                      onClick={(event) => {
                        handleStatusReject(event.target);
                      }}
                    >
                      Reject
                    </Button>
                    </>:<></>
                 }
        </Form.Item>
      </Form>
    );
  };

  const rejectCancel = () => {
    setRejectVisible(false);
    setReason("");
  };

  // Update to Manual Transaction // start
  const withdrawUpdate = async()=> {

    const response = await csWithdrawUpdate({trxnid:withdrawTrxnid, processtype : PROCESS_TYPE.MANUAL , username : username });
    let result = await response.json();

    if (result.status === "ok") {
      alertData.title = "Success";
      alertData.msg = result.msg;
      sharedSuccessAlert(alertData);
      withdrawData();
    }else {
      alertData.title = "Failed";
      alertData.msg = "Unable to update transaction";
      sharedFailAlert(alertData);
    }

    setUpdateTrxVisible(false);
  }

  const updateTrx = async (record) => {
      setUpdateTrxVisible(true);
      setWithdrawTrxnId(record.trxnId);

  }
  
  const cancelUpdate = () => {
    setUpdateTrxVisible(false);
    setWithdrawTrxnId("");
  }

  const updateForm = () => {
    return (
      <Form {...layout}> 
        <Form.Item style={styles.statusBottomBtn}>
            <Button onClick={withdrawUpdate}>
                Yes
            </Button>
            &nbsp;
            <Button type="primary" onClick={cancelUpdate}>
                No
            </Button>
          </Form.Item>
      </Form>
    );
  };

  // Update to Manual Transaction //end


  const styles = {
    pageHearderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
    },
    refreshBtn: { marginLeft: 10 },
    tableContainer: { backgroundColor: "#FFFFFF", padding: 24 },
    paginationStyle: { marginTop: 50 },
    datePickerStyle: {
      width: 260,
    },
    memberTrxnStyle:{
      marginLeft:"280px",
    },
    actionCompleteBtn: {
      marginRight: 20,
      marginLeft: 20,
      backgroundColor: "#4190f7",
      borderColor: "#4190f7",
    },
    actionBottomBtn: {
      flexDirection: "row",
      display: "flex",
      textAlign: "center",
    },
    statusApproveBtn: {
      marginRight: 20,
      marginLeft: 20,
      backgroundColor: "#4190f7",
      borderColor: "#4190f7",
    },
    checkBtn:{
       backgroundColor: "#0000FF", 
       border: "none" 
    },
    statusBottomBtn: {
      flexDirection: "row",
      display: "flex",
      textAlign: "center",
    },
    actionManualBtn:{
      backgroundColor: "#000000", 
      border: "none"
    },
    hide : {
      display:"none",
    },
    alertText : {
        color: "Red",
    }
  };

  return (
    <div className="eachPageContent">
      <PageHeader ghost={false}>
        <div style={styles.pageHearderContainer}>
          <Button
            style={styles.refreshBtn}
            type="primary"
            onClick={() => {
              withdrawData();
            }}
          >
            Refresh Page
          </Button>
          <Radio.Group
            buttonStyle={"solid"}
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <Radio.Button value="All">All</Radio.Button>
            <Radio.Button value="Pending">Pending</Radio.Button>
            <Radio.Button value="Approved">Approved</Radio.Button>
            <Radio.Button value="Rejected">Reject</Radio.Button>
          </Radio.Group>
        </div>
      </PageHeader>
      <Modal
        title={"Withdraw Check Info"}
        visible={statusVisible}
        footer={null}
        closable={false}
      >
        {statusForm()}
      </Modal>
      <Modal
        title={"Reject reason"}
        visible={rejectVisible}
        onOk={() => {
          rejectAction();
        }}
        onCancel={rejectCancel}
        maskClosable={false}
        okText={"Reject"}
        okType="primary"
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "danger", loading: loadings[2] }}
      >
        <TextArea
          value={reason}
          rows={4}
          onChange={(e) => {
            setReason(e.target.value);
          }}
        />
      </Modal>
      <Modal
        title={"Update Transaction to Manual?"}
        visible={updateTrxVisible}
        footer={null}
        onCancel={cancelUpdate}
      >
        {updateForm()}
      </Modal>
      <Modal
        title={"Member Withdraw Transactions"}
        visible={puppetVisible}
        width={1400}
        style={styles.memberTrxnStyle}
        onCancel={handleCancelMemberTrxn}
        centered
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ style:{display: "none"} }}    
        cancelText="Close"  
      >
        <Row>
            <b>Member Nickname: </b>{csMemberNickname}
        </Row>
        <Row>
            <><b>Member Wallet As of :</b> {csMemberWalletDt} &nbsp;&nbsp;<b>Balance :</b> <span type="primary">{csMemberWalletBal}</span>&nbsp;&nbsp;<b>Comm:</b>{csMemberWalletComm}</>
        </Row>
        <Row style={{marginBottom:30  }}>
            <DateTrxnFrom/>
            <DateTrxnTo/>
            <Button
                type={"primary"}
                style={styles.searchResetStyle}
                icon={<SearchOutlined />}
                onClick={searchMemberTrxn}
              />
              <Button
                type={"primary"}
                style={{...styles.searchResetStyle,...styles.refreshBtn, ...styles.hide}}
                onClick={clearTrxnSearch}
                  >
                Reset   
              </Button> 
        </Row>
        <Table
            loading={trxLoading}
            columns={pptColumns}
            dataSource= {filteredPptTrxn }
            scroll={{ x: isMobile ? "110%" : "100%", y: null }}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: settings.pageSizeOptions,
              style: styles.paginationStyle,
              alignmentBottom: 'right',
            }}
            />
      </Modal>
      <div style={styles.tableContainer}>
        <Table
          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: settings.pageSizeOptions,
            style: styles.paginationStyle,
            alignmentBottom: 'right',
          }}
          columns={wthColumns}
          // dataSource={originData}
          dataSource={data}
          loading={tableLoading}
        />

      </div>
    </div>
  );
}
