import React, { useEffect, useState } from "react";
import {
  Form,
  Upload,
  Button,
  Input,
  Select,
  message,
  PageHeader,
  InputNumber,
  Table,
  Modal,
} from "antd";
import "antd/dist/antd.css";
import {
  UploadOutlined,
  SearchOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import {
  csMemberList,
  csBankList,
  puppetTransactionList,
  receiptGetList,
  receiptGetListV2,
  receiptFormNew,
  receiptFormNewV2,
  uploadReceipt,
  uploadReceiptV2,
  csVerifyMobile,
} from "../../config/networkConfig";
import { fileDomain } from "../../config/config"
import { settings } from "../../config/appConfig";
import { CS_USER_TYPE } from "../../common/constants/csuserType";


export default function ManualDepositManagement() {
  //Variables
  const { companyId , role, userId } = useSelector((state) => state.login);
  //Table
  const [tableLoading, setTableLoading] = useState(false);
  const [originData, setOriginData] = useState([]);
  const [searchText, setSearchText] = useState("");
  //Variable declare
  const [form] = Form.useForm();
  const [csMemberListOptions, setCSMemberListOptions] = useState([]);
  const [csBankListOptions, setCSBankListOptions] = useState([]);
  //Data
  //const [userId, setUserId] = useState("");
  const [bankcode, setBankCode] = useState("");
  const [acctnum, setAcctNum] = useState("");
  const [amt, setAmt] = useState(0);
  const [countryCode, setCountryCode] = useState("60");
  const [phoneNum, setPhoneNum] = useState("");
  const { username} = useSelector((state) => state.login);
  //Modal
  const [manualDepositVisible, setManualDepositVisible] = useState(false);
  const [createDepositDisable, setCreateDepositDisable] = useState(true);

  //Form
  const [fileList, setFileList] = useState([]);
  const { Option } = Select;
  



  //Action
  const onSubmit = async () => {
    form.setFieldsValue({
      bankReceipt: fileList[0],
    });
    const row = await form.validateFields();
    //---------- Validate the bank code and amount with puppet -----------
    const result = await isMatchWithPuppet(row);
    if (result.status === "ok") {
      const uploadResult = await uploadFile();
      if (uploadResult.status === "ok") {
        // ------ Insert into member transaction table -----------
        const insertResult = await dataInsert(row, uploadResult.data);
        message.success(insertResult.msg);
        setManualDepositVisible(false);
        setFileList([]);
        form.resetFields();
      }
    } else {
      message.error(result.msg);
    }
  };

  const onSubmitForm = async () => {
    form.setFieldsValue({
      bankReceipt: fileList[0],
    });
    const row = await form.validateFields();

    const uploadResult = await uploadFileV2();
    if (uploadResult.status ==="ok"){

      let filename = uploadResult.data!==undefined? uploadResult.data.filename : '';
        const insertResult = await dataInsert2(row, filename);
        if (insertResult.status ==="ok") {
          setManualDepositVisible(false);
          setFileList([]);
          form.resetFields();
          depositList();
          setPhoneNum("");
           message.success(insertResult.msg);
         }else {
           message.error(insertResult.msg);
         }

    }else {
        message.error(uploadFileV2.msg);
    }
  };

  const selectCountryCode = (value) => {
    setCountryCode(value);
  };

  useEffect(() => {
    depositList();
    memberList();
    bankcodeList();
    // eslint-disable-next-line
  }, []);

  //#region Lists
  const depositList = () => {
    var count = 0;
    receiptGetListV2({})
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          const newData = Object.keys(json.data).length
            ? json.data.map((x) => {
                setTableLoading(false);
                return {
                  key: count++,
                  date: x.dateTime,
                  nickname: x.nickname,
                  //   bankNameIn: x.bankAccName,
                  bankNameIn: `${x.bankAccName}`,
                  bankAcctIn: x.bankAcc,
                  amount: x.Amount,
                  trxnId: x.trxnId,
                  receipt: x.UploadRef,
                };
              })
            : [];

          setOriginData(newData);
        } else {
          setTableLoading(false);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        message.error(
          "An unexpected error occurred. Contact your system administrator "
        );
      });
  };

  const memberList = async () => {
    const response = await csMemberList({ userId: companyId });
    let result = await response.json();
    console.log("result: ", result);
    if (result.status === "ok") {
      setCSMemberListOptions(result.data);
    }
  };

  const bankcodeList = async () => {
    const response = await csBankList({ userId: companyId });
    let result = await response.json();

    if (result.status === "ok") {
       if (result.data && result.data.length) {
           const active = result.data.filter((item) => item.status === "1");
           setCSBankListOptions(active);
       }
    }
  };
  //#endregion

  //#region functions
  const validateUserPhone = async(phoneNum)=> {
      const response = await csVerifyMobile({mobile: countryCode+phoneNum});
      let result = await response.json();

      if (result.status === "ok") {
        setCreateDepositDisable(false);
      }else{
        setCreateDepositDisable(true);
        message.error(result.msg);
      }
  }

  ////#endregion


  //Validate puppet table
  const isMatchWithPuppet = async (data) => {
    //------- Do matching with puppet transaction table -------
    const response = await puppetTransactionList({
      bankcode: bankcode,
      bankaccnum: acctnum,
      amt: amt,
    });
    let result = await response.json();
    return result;
  };

  //Data Insert
  const dataInsert2 = async (data, path) => {
    return receiptFormNewV2({
      mobile : countryCode+phoneNum,
      bankcode: bankcode,
      bankaccnum: acctnum,
      bankaccname: data.bankaccname,
      uploadref: path,
      amt: amt,
      createby: userId,
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(`json >> ${json}`);
        return { status:'ok' , errcode:'', msg:json.msg};
      });
  };

  const dataInsert = async (data, path) => {
    //-------- Data Insert ------------
    const response = await receiptFormNew({
      userid: userId,
      bankcode: bankcode,
      bankaccnum: acctnum,
      bankaccname: data.bankaccname,
      uploadref: path,
      amt: amt,
    });
    let result = await response.json();
    return result;
  };

  //#region ------- Receipt file Uploads --------
  const fileProps = {
    listType: "picture",
    onRemove: (file) => {
      setFileList([]);
    },
    fileList: fileList,
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        let newFileList = [];
        newFileList.push(file);
        setFileList(newFileList);
      });

      reader.readAsDataURL(file);
      return false;
    },
    onChange: (info) => {
      // 1. Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      let newFileList = [...info.fileList];
      newFileList = newFileList.slice(-1);

      // 2. Read from response and show file link
      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }

        return file;
      });

      setFileList(newFileList);
      return false;
    },
  };

  const receiptUploads = () => {
    return (
      <>
        <Upload {...fileProps} fileList={fileList}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
      </>
    );
  };

  const uploadFile = async () => {
    const formData = new FormData();
    formData.append("fileReceipt", fileList[0]);

    const response = await uploadReceipt(formData);
    let result = await response.json();
    return result;
  };


  const uploadFileV2 = async () => {
    const formData = new FormData();
    formData.append("fileReceipt", fileList[0]);
    if (fileList[0]!== undefined){
         const response = await uploadReceiptV2(formData);
        let result = await response.json();
        return result;
    }

    return { status:'ok'};
  };

  //#endregion

  //#region -------- Manual Deposit Form --------
  const onManualCreateForm = () => {
    return (
      <div>
        <div>
          <Form form={form} layout="vertical" name="depositform">
             {/* Mobile Id*/}
             <Form.Item
                label="Phone Number"
                required={true}
                >
                  <Input.Group compact>
                    <Select value={countryCode}
                      onChange={selectCountryCode}>
                      <Option value="66">+66</Option>
                      <Option value="60">+60</Option>
                    </Select>
                    <Input style={{width:"66%"}} defaultValue="" placeholder="phone number" value={phoneNum}
                      onChange={(e) => {
                        setPhoneNum(e.target.value.replace(/\D|^0/g, ""));
                      }}
                      onBlur={(e) => {
                          console.log(`validate phone number`);
                          validateUserPhone(e.target.value.replace(/\D|^0/g, ""));
                      }}
                    />
                  </Input.Group>
                </Form.Item>

            {/* User Id*/}
            {/* <Form.Item
              name="userid"
              label="User ID"
              rules={[
                {
                  required: true,
                  message: "Please select user id",
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  //   return option.children.includes(input);
                  return option.value.includes(input);
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value, event) => {
                  //   setUserId(value);
                  setUserId(event.key);
                }}
              >
                {csMemberListOptions.map((option) => {
                  return (
                    <Option key={option.userId} value={option.mobile}>
                      {option.nickname}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item> */}

            {/* Upload Receipt */}
            <Form.Item
              name="bankReceipt"
              label="Bank Receipt"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please upload the receipt!!",
              //   },
              // ]}
            >
              {receiptUploads()}
            </Form.Item>

            {/* Bank Acc Name */}
            <Form.Item
              name="bankaccnum"
              label="Bank Account Number"
              rules={[
                {
                  required: true,
                  message: "Please input your bank!",
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.children.includes(input);
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value, event) => {
                  setAcctNum(value);
                  //Search Bank Acc Name
                  for (var i = 0; i < csBankListOptions.length; i++) {
                    if (csBankListOptions[i].bankAcc === value) {
                      setBankCode(csBankListOptions[i].bank);
                      form.setFieldsValue({
                        bankcode: csBankListOptions[i].bank,
                        bankaccname: csBankListOptions[i].bankAccName,
                      });
                    }
                  }
                }}
              >
                {csBankListOptions.map((option) => {
                  return (
                    <Option key={option.bankAcc} value={option.bankAcc}>
                      {option.bank} ({option.bankAcc})
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            {/* Bank Acc Num */}
            {/* <Form.Item name="bankcode" label="Bank Code">
              <Input disabled />
            </Form.Item> */}

            {/* Bank Acc Name */}
            <Form.Item name="bankaccname" label="Bank Acc Name">
              <Input disabled/>
            </Form.Item>

            {/* Amount */}
            <Form.Item
              name="amt"
              label="Amount"
              rules={[
                {
                  required: true,
                  message: "Please input the amount!",
                },
              ]}
            >
              <InputNumber
                onChange={(value) => {
                  setAmt(value);
                }}
                min={1}
              />
            </Form.Item>

            <Button type="primary" onClick={onSubmitForm} disabled={createDepositDisable}>
              Submit
            </Button>

            {/* <Button type="primary" onClick={onSubmit}>
              Submit
            </Button> */}


          </Form>
        </div>
      </div>
    );
  };
  //#endregion

  //#region ------- Table --------
  //------ Columns -------
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });

  const columns = [
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "date",
      width: "100px",
      ellipsis: true,
    },
    {
      ellipsis: true,
      align: "center",
      title: "Bank Name(In)",
      dataIndex: "bankNameIn",
      width: "100px",
      render(text, record) {
        return {
          props: {
            style: {
              whiteSpace: "pre",
            },
          },
          children: <div>{`${text}\n${record.bankAcctIn}`}</div>,
        };
      },
    },
    {
      ellipsis: true,
      align: "center",
      title: "Member nickname",
      dataIndex: "nickname",
      width: "100px",
      ...getColumnSearchProps("nickname"),
    },
    {
      ellipsis: true,
      align: "center",
      title: "Amount",
      dataIndex: "amount",
      width: "30px",
    }
  ];
  //#endregion

  //#region -------- Styles ----------
  const styles = {
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
    },
    refreshBtn: {
      marginLeft: 10,
    },
    tabelContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
  };
  //#endregion

  //#region ----- column searching ---------
  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  let searchInput = null;
  //#endregion

  //#region ------ Final View ---------
  return (
    <div>
      <PageHeader ghost={false}>
        <div style={styles.pageHeaderContainer}>
        { //role === CS_USER_TYPE.Junior || role === CS_USER_TYPE.CS ?
          (
          <>
            <Button
              style={styles.refreshBtn}
              type="primary"
              onClick={() => {
                setManualDepositVisible(true);
              }}
            >
              Create Deposit
            </Button>
          </>)
          //:(<></>)
        }
        </div>
      </PageHeader>
      <Modal
        title="Manual Deposit Form"
        visible={manualDepositVisible}
        footer={null}
        getContainer={false}
        onCancel={() => {
          form.resetFields();
          setFileList([]);
          setManualDepositVisible(false);
        }}
      >
        {onManualCreateForm()}
      </Modal>
      <div style={styles.tabelContainer}>
        <Table
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) => (
              record.receipt!=''?              
              <FileImageOutlined
              onClick={(e) => onExpand(record, e)}
              style={{ fontSize: 30 }}
              /> : null
            ),
            expandIconColumnIndex: 10,
            expandedRowRender: (record) => (
                <div style={{ textAlign: "center" }}>
                <img
                  src={`${fileDomain}${record.receipt}`}
                  style={{ height: 500 }}
                  alt="receipt"
                />
              </div>
              
            ),
            rowExpandable: (record) => record.receipt !== "",
          }}
          loading={tableLoading}
          columns={columns}
          dataSource={originData}
          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: settings.pageSizeOptions,
            style: styles.paginationStyle,
            alignmentBottom: 'right',
          }}
        />
      </div>
    </div>
  );
  //#endregion
}
