import React, { Component } from "react";
import "../../App.less";
import { Layout, Menu, Button, Dropdown} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  UserOutlined,
  LockOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { NavLink, Switch, Route , Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";

//Navigate
import Login from "../login/login";
import Logout from "../logout/logout";
import { externalFileNotFile } from "../error/error";
import { route, customerService, mainMenu, logout, changePassword } from "../../config/route";
import { copyright } from "../../library/texts";
import { Logo } from "../../common/components/Logo";
import { settings } from "../../config/appConfig";
import { unlockMember } from "../../library/permission"; //Permission
import style from "../../resource/style/style";


const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;





class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: isMobile ? true : false,
      openKeys: ["daliyOptParent"],
      title: "",
    };
  }
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  state = {
    visible: false,
    openKeys: [],
  };

  styles ={
     hide: {
       display:"none"
     }
  }

  rootSubmenuKeys = ["sub1", "sub2", "sub3"];

  location = ["/", ""];

  componentDidMount() {
    var that = this;
    // console.log(this.props)
    customerService.forEach(function (cs) {
      if (cs.path === window.location.pathname) {
        that.setState({ title: cs.title });
      }
    });

    if (
      customerService[4].path === window.location.pathname ||
      customerService[52].path === window.location.pathname ||
      customerService[53].path === window.location.pathname ||
      customerService[23].path === window.location.pathname ||
      customerService[24].path === window.location.pathname
    ) {
      that.setState({ openKeys: ["bankParent"] });
    }
    else if (
      customerService[11].path === window.location.pathname ||
      customerService[0].path === window.location.pathname ||
      customerService[46].path === window.location.pathname ||
      // customerService[1].path === window.location.pathname ||
      // customerService[31].path === window.location.pathname ||
      customerService[32].path === window.location.pathname ||
      customerService[19].path === window.location.pathname ||
      customerService[44].path === window.location.pathname
    ) {
      that.setState({ openKeys: ["daliyOptParent"] });
    }
    else if (
      customerService[33].path === window.location.pathname ||
      customerService[49].path === window.location.pathname 
      // customerService[22].path === window.location.pathname
    ) {
      that.setState({ openKeys: ["manualOptParent"] });
    }
    else if (
      customerService[27].path === window.location.pathname ||
      customerService[10].path === window.location.pathname ||
      // customerService[25].path === window.location.pathname ||
      customerService[34].path === window.location.pathname ||
      customerService[37].path === window.location.pathname ||
      customerService[38].path === window.location.pathname ||
      customerService[41].path === window.location.pathname ||
      customerService[42].path === window.location.pathname ||
      customerService[40].path === window.location.pathname ||
      customerService[35].path === window.location.pathname ||
      customerService[43].path === window.location.pathname ||
      customerService[45].path === window.location.pathname ||
      customerService[26].path === window.location.pathname ||
      customerService[15].path === window.location.pathname ||
      customerService[21].path === window.location.pathname ||
      customerService[30].path === window.location.pathname ||
      customerService[17].path === window.location.pathname ||
      customerService[54].path === window.location.pathname ||
      customerService[56].path === window.location.pathname ||
      customerService[16].path === window.location.pathname ||
      customerService[47].path === window.location.pathname
    ) {
      that.setState({ openKeys: ["ReportingParent"] });

      if (
        customerService[25].path === window.location.pathname ||
        customerService[34].path === window.location.pathname ||
        customerService[37].path === window.location.pathname ||
        customerService[38].path === window.location.pathname ||
        customerService[41].path === window.location.pathname ||
        customerService[42].path === window.location.pathname ||
        customerService[40].path === window.location.pathname ||
        customerService[35].path === window.location.pathname ||
        customerService[43].path === window.location.pathname ||
        customerService[45].path === window.location.pathname ||
        customerService[47].path === window.location.pathname
      ) {
        that.setState({ openKeys: ["ReportingParent","ReportOperation"] });        
      }
      if (
        customerService[21].path === window.location.pathname ||
        customerService[30].path === window.location.pathname ||
        customerService[16].path === window.location.pathname
      ) {
        that.setState({ openKeys: ["ReportingParent","ReportingCRM"] });        
      }
      if (
        customerService[27].path === window.location.pathname ||
        customerService[10].path === window.location.pathname ||
        customerService[26].path === window.location.pathname ||
        customerService[15].path === window.location.pathname
      ) {
        that.setState({ openKeys: ["ReportingParent","ReportMisc"] });        
      }
      if (
        customerService[17].path === window.location.pathname ||
        customerService[54].path === window.location.pathname ||
        customerService[56].path === window.location.pathname
      ) {
        that.setState({ openKeys: ["ReportingParent","ReportAccount"] });        
      }
    }
    else if (
      customerService[13].path === window.location.pathname ||
      customerService[20].path === window.location.pathname ||
      customerService[14].path === window.location.pathname ||
      customerService[48].path === window.location.pathname ||
      // customerService[28].path === window.location.pathname ||
      customerService[39].path === window.location.pathname ||
      customerService[29].path === window.location.pathname ||
      customerService[50].path === window.location.pathname ||
      customerService[51].path === window.location.pathname 
      
    ) {
      that.setState({ openKeys: ["settingsParent"] });
    }
    // else if (
    //   customerService[1].path === window.location.pathname ||
    //   customerService[8].path === window.location.pathname
    // ) {
    //   that.setState({ openKeys: ["backupParent"] });
    // }

    document.addEventListener("gesturestart", function (e) {
      e.preventDefault(); //disable iphone zooming
    });
  }

  toggle = () => {
    if (isMobile) {
      document.getElementById("menuBackground").style.display = "flex";
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  menuBgOnclick = () => {
    if (isMobile) {
      this.setState({
        collapsed: !this.state.collapsed,
      });
      document.getElementById("menuBackground").style.display = "none";
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  };

  subMenuClick = () => {
    var that = this;
    customerService.forEach(function (cs) {
      if (cs.path === window.location.pathname) {
        that.setState({ title: cs.title });
      }
    });
  };

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  menu = () => {
    return (
      <Menu>
        {(
          <Menu
            style={{
              paddingTop: 4,
              marginTop: -4,
              marginBottom: -4,
              paddingBottom: 2,
            }}
          >
            <Menu.Item 
              onClick={() => {
                window.location.reload();
              }}
              key={changePassword[0].path}
              style={{
                paddingTop: -2,
                marginTop: -5,
                marginBottom: -4,
              }}
              
            >
              <NavLink to={changePassword[0].path}>
                <div 
                  className="menu-item"
                  style={{
                    justifyContent: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <LockOutlined className="menu-icon" />
                  <span className="menu-text">Change Password</span>
                </div>
              </NavLink>
            </Menu.Item>

            <Menu.Item key={logout[0].path} className="logoutBorder"
              style={{
                marginBottom: -2,
              }}
            >
              <NavLink to={logout[0].path}>
                <div 
                  className="menu-item"
                  style={{
                    justifyContent: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <LogoutOutlined className="menu-icon" />
                  <span className="menu-text">Logout</span>
                </div>
              </NavLink>
            </Menu.Item>
          </Menu>
        )}
      </Menu>
    );
  };

  render() {
    //const success = false;
    return this.props.signIn === false ? (
      <Switch>
        <Route path={[route.Default, route.Login]} exact component={Login} />
        <Route path={route.All} component={externalFileNotFile} />
      </Switch>
    ) : (
      <Layout>
        <div
          id="menuBackground"
          className="menuBackground"
          onClick={() => this.menuBgOnclick()}
        />
        <Sider
          trigger={null}
          collapsed={this.state.collapsed}
          collapsedWidth={isMobile ? 0 : 80}
          width={"280px"}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
        >
          <div
            style={{
              height: 64,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div>
              {!this.state.collapsed ? (
                <Logo logoType="customerservice" />
              ) : null}
            </div>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[window.location.pathname]}
            openKeys={this.state.openKeys}
            onOpenChange={this.onOpenChange}
          >  
    
              <SubMenu
                key="bankParent"
                style={{ fontSize: 16 }}
                title="Bank"
                collapsed={false}
                icon={mainMenu[0].icon}
              >
              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[4].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[4].path}>
                  {customerService[4].title}
                </NavLink>
              </Menu.Item>
              
              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[52].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[52].path}>
                  {customerService[52].title}
                </NavLink>
              </Menu.Item> 
              
              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[53].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[53].path}>
                  {customerService[53].title}
                </NavLink>
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[23].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[23].path}>
                  {customerService[23].title}
                </NavLink>
              </Menu.Item>

              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[24].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[24].path}>
                  {customerService[24].title}
                </NavLink>
              </Menu.Item>
              
            </SubMenu>
            <SubMenu
              key="daliyOptParent"
              style={{ fontSize: 18 }}
              title="Daily Operations"
              collapsed={false}
              icon={mainMenu[1].icon}
            >
              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[11].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[11].path}>
                  {customerService[11].title}
                </NavLink>
              </Menu.Item>

              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[0].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[0].path}>
                  {customerService[0].title}
                </NavLink>
              </Menu.Item>

              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[46].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[46].path}>
                  {customerService[46].menutitle}
                </NavLink>
              </Menu.Item>

                <Menu.Item
                  onClick={() => {
                    window.location.reload();
                  }}
                  key={customerService[44].path}
                  style={style.menuFontSize}
                >
                  <NavLink to={customerService[44].path}>
                    {customerService[44].menutitle}
                  </NavLink>
                </Menu.Item>

                {/* <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[1].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[1].path}>
                  {customerService[1].title}
                </NavLink>
              </Menu.Item> */}
              
                {/* <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[31].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[31].path}>
                  {customerService[31].menutitle}
                </NavLink>
              </Menu.Item> */}

                {/* <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[32].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[32].path}>
                  {customerService[32].menutitle}
                </NavLink>
              </Menu.Item> */}

              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[19].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[19].path}>
                  {customerService[19].title}
                </NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="manualOptParent"
              style={{ fontSize: 18 }}
              title="Manual Operations"
              collapsed={false}
              icon={mainMenu[2].icon}
            >
              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[33].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[33].path}>
                  {customerService[33].menutitle}
                </NavLink>
              </Menu.Item>

              {/* <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[22].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[22].path}>
                  {customerService[22].menutitle}
                </NavLink>
              </Menu.Item> */}

              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[49].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[49].path}>
                  {customerService[49].menutitle}
                </NavLink>
              </Menu.Item>
              {this.props.demoUser?<Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[58].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[58].path}>
                  {customerService[58].menutitle}
                </NavLink>
              </Menu.Item>:null}
            </SubMenu>
            <SubMenu
              key="ReportingParent"
              style={{ fontSize: 18 }}
              title="Reporting"
              collapsed={false}
              icon={mainMenu[3].icon}
            >
              <SubMenu
                key="ReportOperation"
                style={{ fontSize: 16 }}
                title="Operation"
                collapsed={false}
                >
                  {/* <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[25].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[25].path}>
                      {customerService[25].menutitle}
                    </NavLink>
                  </Menu.Item> */}

                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[34].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[34].path}>
                      {customerService[34].menutitle}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[37].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[37].path}>
                      {customerService[37].menutitle}
                    </NavLink>
                  </Menu.Item>

                  {customerService[38].code.includes(this.props.compCode) == false ? (
                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[38].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[38].path}>
                      {customerService[38].menutitle}
                    </NavLink>
                  </Menu.Item>
                  ) : ''}

                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[41].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[41].path}>
                      {customerService[41].menutitle}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[42].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[42].path}>
                      {customerService[42].menutitle}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[40].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[40].path}>
                      {customerService[40].menutitle}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[35].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[35].path}>
                      {customerService[35].menutitle}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[43].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[43].path}>
                      {customerService[43].menutitle}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[45].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[45].path}>
                      {customerService[45].menutitle}
                    </NavLink>
                  </Menu.Item>
                  
                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[47].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[47].path}>
                      {customerService[47].menutitle}
                    </NavLink>
                  </Menu.Item>

              </SubMenu>
              <SubMenu
                key="ReportingCRM"
                style={{ fontSize: 16 }}
                title="CRM"
                collapsed={false}
                >
                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[21].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[21].path}>
                      {customerService[21].menutitle}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[30].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[30].path}>
                      {customerService[30].menutitle}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[16].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[16].path}>
                      {customerService[16].menutitle}
                    </NavLink>
                  </Menu.Item>
              </SubMenu>
              <SubMenu
                key="ReportAccount"
                style={{ fontSize: 16 }}
                title="Accounting"
                collapsed={false}
                >
                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[17].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[17].path}>
                      {customerService[17].menutitle}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[54].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[54].path}>
                      {customerService[54].menutitle}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[56].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[56].path}>
                      {customerService[56].menutitle}
                    </NavLink>
                  </Menu.Item>

              </SubMenu>
              <SubMenu
                key="ReportMisc"
                style={{ fontSize: 16 }}
                title="Misc"
                collapsed={false}
                >
                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[27].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[27].path}>
                      {customerService[27].menutitle}
                    </NavLink>
                  </Menu.Item>
                  
                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[10].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[10].path}>
                      {customerService[10].menutitle}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[26].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[26].path}>
                      {customerService[26].menutitle}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[15].path}
                    style={style.menuFontSize}
                  >
                    <NavLink to={customerService[15].path}>
                      {customerService[15].menutitle}
                    </NavLink>
                  </Menu.Item>
              </SubMenu>              
            </SubMenu>
            <SubMenu
              key="settingsParent"
              style={{ fontSize: 18 }}
              title="Settings"
              collapsed={false}
              icon={mainMenu[4].icon}
            >
               <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[14].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[14].path}>
                  {customerService[14].menutitle}
                </NavLink>
                </Menu.Item>  
              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[55].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[55].path}>
                  {customerService[55].menutitle}
                </NavLink>
                </Menu.Item>
              <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[51].path}
                    style={style.menuFontSize}
                  >
                <NavLink to={customerService[51].path}>
                  {customerService[51].menutitle}
                </NavLink>
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[48].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[48].path}>
                  {customerService[48].menutitle}
                </NavLink>
              </Menu.Item>
              <Menu.Item
                    onClick={() => {
                      window.location.reload();
                    }}
                    key={customerService[50].path}
                    style={style.menuFontSize}
                  >
                <NavLink to={customerService[50].path}>
                  {customerService[50].menutitle}
                </NavLink>
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[29].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[29].path}>
                  {customerService[29].menutitle}
                </NavLink>
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[13].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[13].path}>
                  {customerService[13].menutitle}
                </NavLink>
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[20].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[20].path}>
                  {customerService[20].menutitle}
                </NavLink>
              </Menu.Item>
              {/* <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[28].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[28].path}>
                  {customerService[28].menutitle}
                </NavLink>
              </Menu.Item> */}
              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[39].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[39].path}>
                  {customerService[39].menutitle}
                </NavLink>
              </Menu.Item>
            </SubMenu>
            {/* <SubMenu
              key="backupParent"
              style={{ fontSize: 18 }}
              title="Back Up"
              collapsed={false}
              icon={mainMenu[6].icon}
            >
              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[1].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[1].path}>
                  {customerService[1].menutitle}
                </NavLink>
              </Menu.Item>

              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={customerService[8].path}
                style={style.menuFontSize}
              >
                <NavLink to={customerService[8].path}>
                  {customerService[8].menutitle}
                </NavLink>
              </Menu.Item>

            </SubMenu> */}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          {isMobile ? (
            <Header
              className="site-layout-background"
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                height: 68,
              }}
            >
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: this.toggle,
                }
              )}

              <div
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {this.state.title}
              </div>

              <div>
                <div
                  style={{
                    fontSize: 18,
                  }}
                >
                  <Dropdown overlay={this.menu} placement="bottomLeft">
                    <Button
                      style={{
                        border: "none",
                        width: 80,
                        height: 65,
                      }}
                      icon={<UserOutlined />}
                      size="large"
                    ></Button>
                  </Dropdown>
                </div>
              </div>
            </Header>
          ) : (
            <Header
              className="site-layout-background"
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                marginLeft: this.state.collapsed ? 64 : 280,
                height: 68,
                alignItems: "center",
              }}
            >
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: this.toggle,
                }
              )}

              <div
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {this.state.title}
              </div>

              <div>
                <div
                  style={{
                    fontSize: 18,
                    marginRight:20,
                  }}
                >
                  <Dropdown overlay={this.menu} placement="bottomLeft">
                    <Button
                      style={{
                        border: "none",
                        width: 200,
                        height: 65,
                      }}
                      icon={<UserOutlined />}
                      size="large"
                    >
                      {this.props.role}_{this.props.username}
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </Header>
          )}
          <Content
            className="site-layout-background"
            style={{
              marginTop: "5px ",
              marginLeft: isMobile ? "" : this.state.collapsed ? 64 : 280,
              backgroundColor: "#f0f2f5",
            }}
          >
            <div
              style={{
                minHeight: 280,
              }}
            >
              <Switch>
                <Route exact path={logout[0].path} component={Logout} />
                {customerService.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                ))}
              </Switch>
            </div>
          </Content>
          {isMobile ? (
            <Footer style={{ textAlign: "center" }}>
              <div>{copyright.fullText()}</div>
              <div>V: {settings.version}</div>
            </Footer>
          ) : (
            <Footer style={{ textAlign: "center", marginLeft: 280 }}>
              <div>{copyright.fullText()}</div>
              <div>V: {settings.version}</div>
            </Footer>
          )}
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = ({ login }) => ({
  signIn: login.signIn,
  username: login.username,
  demoUser: login.demoUser,
  role: login.role,
  compCode: login.compCode,
});

const mapDispatchToProps = (dispatch) => {
  return { signOut: () => dispatch({ type: "sign_out" }) };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
