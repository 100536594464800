const BANK_TRXN_TYPE = {
    TransIn  : 'Transfer-In',
    TransOut : 'Transfer-Out',
}

const BANK_TRXN_MODE = {
    FromBank : 'From-Bank',
    ToBank   : 'To-Bank',
    Fee      : 'Fees',
    Interest : 'Interest',
    Misc     : 'Misc.',
}

const BANK_INFO_TYPE = {
    DEP : 'Deposit',
    WIT : 'Withdraw',
}

const DEP_TYPE = {
    DDEP   : 'DelayDeposit',
    ADJUST : 'Adjustment',
    RECOVER: 'Recover',
    PROMO  : 'Promo', //Fortune Wheel
}

const BANK = {
   DEPOSIT : [ 
       {
           code :"",
           acctnum:"",
           acctname:""
       }
   ],
   WITHDRAW : [ 
    {
        code :"",
        acctnum:"",
        acctname:""
    }
]      
}


export {BANK_TRXN_TYPE , BANK_INFO_TYPE, BANK_TRXN_MODE , DEP_TYPE}