import React, { useEffect, useState ,useRef} from "react";
import { useSelector } from "react-redux";
import { reportShareholderV2, reportShareholderDetailsV2 } from "../../../config/networkConfig";
import {
  message,
  Table,
  PageHeader,
  DatePicker,
  Button,
  Breadcrumb,
  Statistic,
  Row,
  Space,
  Card,
  Tabs,
  Modal,
  Form,
} from "antd";
import { isMobile } from "react-device-detect";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import {settings} from "../../../config/appConfig";
import { FormatPt, FormatData, FormatDataMobile} from "../../../library/numbers";
import moment from "moment";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";

export default function ReportShareholderV2() {
  const {TabPane} = Tabs;
  const { userId, username } = useSelector((state) => state.login);
  const [report, setReport] = useState("");
  const [shareHolder, setShareHolder] = useState("");
  const [reportDetail, setReportDetail] = useState("");
  const [profitSharingTotal, setProfitSharingTotal] = useState("");
  const [date, setDate] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [share, setShare] = useState(0);
  const reportDateFrom = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);

 

  useEffect(() => {
    //reportList();
    //reportShareDetailList();
    // eslint-disable-next-line
  }, []);

  const detailsBtn = (record) => {
    setIsModalVisible(true);
    reportDetails();
    reportShareDetail();
  }

  const reportList = () => {
    var count = 0;
    var countDetail = 0;
    reportShareholderV2({ userId: userId , date: date.format("YYYY-MM")})
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
          const report = Object.keys(json.data.allFullData).length
            ? json.data.allFullData.map((data) => {
                return {
                  key: ++count,
                  accountDate             : data.AccountDate,
                  profitSharing           : data.ProfitSharing,
                  netGamingRevenue        : data.NetGamingRevenue,
                  grossGamingRevenue      : data.GrossGamingRevenue,
                  promotionCost           : data.PromotionCost,
                  royalty                 : data.Royalty,    
                  iTCharge                : data.ITCharge,             
                  affiliateCost           : data.AffiliateCost,
                  CrmAffiliate            : data.CrmAffiliate,       
                  operationCost           : data.OperationCost,      
                  customFee               : data.CustomFee,      
                  remark                  : data.Remark, 
                };
              })
            : [];

          const filterDetail = report.filter(
            (data) => data.accountDate // == report[0].accountDate
          );
          const getProfitSharing = filterDetail.map((data) => data.profitSharing);
          var profitSharingTotal = getProfitSharing.reduce(function (previous, current) {
            return previous + current;
          }, 0);

          setProfitSharingTotal(profitSharingTotal);
          setReport(report);
        }
        else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
      });
  };

  const reportDetails = () => {
    var count = 0;
    var countDetail = 0;
    reportShareholderDetailsV2({ userId: userId, date: date.format("YYYY-MM")})
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
          const report = Object.keys(json.data).length
            ? json.data.map((data) => {
                return {
                  key: ++count,
                  accountDate             : data.AccountDate,
                  grossRevenue            : data.GrossRevenue,
                  royaltyFee              : data.RoyaltyFee,
                  inviteAngpaoCost        : data.InviteAngpaoCost,
                  missionCost             : data.MissionCost,
                  fortuneWheelCost        : data.FortuneWheelCost,    
                  permanentAngpao         : data.PermanentAngpao,             
                  playerRebate            : data.PlayerRebate,
                  topupDiscount           : data.TopupDiscount,       
                  playerAffiliate         : data.PlayerAffiliate,      
                  marketingAffiliate      : data.MarketingAffiliate,      
                  crm                     : data.Crm,
                  iTCharge                : data.ITCharge,
                  operationCost           : data.OperationCost,
                  marketingAffiliateComm  : data.MarketingAffiliateComm,
                  customFee               : data.CustomFee,
                };
              })
            : [];
          setReportDetail(report);
        }
        else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
      });
  };

  const reportShareDetail = () => {
    return (
          <Table
                  columns={columns2}
                  dataSource={reportDetail}
                  scroll={{ x: 1000, y: 1000 }}
                  pagination={{
                    defaultPageSize: 10,
                    pageSizeOptions: settings.pageSizeOptions,
                    style: styles.paginationStyle,
                    alignmentBottom: 'right'
                  }}
                />
        // </Form>
    )
  }

  const columns = [
    {
      align: "center",
      title: "Details",
      dataIndex: "remark",
      width:80,
      ellipsis: true,
      fixed : "left",
      render:function(record) {
        setShareHolder(record)
        return  record.toUpperCase() == username.toUpperCase()?
                  <Button
                    // name={"Check"}
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={(event) => {
                      detailsBtn(record, event.target);
                      //pendingBtn(record, event.target);
                    }}
                  >                  
                  </Button>
                : <p></p>
      }
    },
    {
      align: "center",
      title: "Shareholder",
      dataIndex: "remark",
      width:130,
      ellipsis: true,
      fixed : "left",
    },
    {
      align: "center",
      title: "Profit Sharing",
      dataIndex: "profitSharing",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render:function(record) {
        return FormatData(record) >= 0?
              <p style={{color:"#339966", marginTop: "15px"}}><b>{FormatData(record)}</b></p>
              : <p style={{color:"red", marginTop: "15px"}}><b>{FormatData(record)}</b></p>
      }
    },
    {
      align: "center",
      title: "Gross Game Revenue (GGR)",
      dataIndex: "grossGamingRevenue",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#3333ff", marginTop: "15px"}}><b>{FormatData(record)}</b></p>;
      }
    },
    {
      align: "center",
      title: "Royalities",
      dataIndex: "royalty",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#3333ff", marginTop: "15px"}}><b>{FormatData(record)}</b></p>;
      }
    },
    {
      align: "center",
      title: "Promotion",
      dataIndex: "promotionCost",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render:function(record, record2) {
        return <p style={{color:"#3333ff", marginTop: "15px"}}><b>{FormatData(record)}</b></p>
      }
    },
    {
      align: "center",
      title: "Net Game Revenue (NGR)",
      dataIndex: "netGamingRevenue",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render:function(record) {
        return <p style={{color:"#ff00ff", marginTop: "15px"}}><b>{FormatData(record)}</b>
                  
               </p>
      }
    },
    {
      align: "center",
      title: "Affiliate Cost",
      dataIndex: "affiliateCost",
      width: 150,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render:function(record,record2) {
        return  <p style={{color:"#996633", marginTop: "15px"}}><b>{FormatData(record)}</b></p>;
      }
    },
    {
      align: "center",
      title: "Operation Cost",
      dataIndex: "operationCost",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#996633", marginTop: "15px"}}><b>{FormatData(record)}</b></p>;
      }
    },
    {
      align: "center",
      title: "Custom Fees",
      dataIndex: "customFee",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#996633", marginTop: "15px"}}><b>{FormatData(record)}</b></p>;
      }
    },
  ];

  const columns2 = [
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "accountDate",
      width:130,
      ellipsis: true,
      fixed : "left",
    },
    {
      align: "center",
      title: "Gross Game Revenue (GGR)",
      dataIndex: "grossRevenue",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#3333ff", marginTop: "15px"}}><b>{FormatData(record)}</b></p>;
      }
    },
    {
      align: "center",
      title: "Royalities",
      dataIndex: "royaltyFee",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#3333ff", marginTop: "15px"}}><b>{FormatData(record)}</b></p>;
      }
    },
    {
      align: "center",
      title: "Invite Angpao Cost",
      dataIndex: "inviteAngpaoCost",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#9999ff", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
    {
      align: "center",
      title: "Mission Cost",
      dataIndex: "missionCost",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#9999ff", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
    {
      align: "center",
      title: "Fortune Wheel Cost",
      dataIndex: "fortuneWheelCost",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#9999ff", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
    {
      align: "center",
      title: "Permanent Angpao",
      dataIndex: "permanentAngpao",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#9999ff", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
    {
      align: "center",
      title: "Player Rebate",
      dataIndex: "playerRebate",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#9999ff", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
    {
      align: "center",
      title: "Topup Discount",
      dataIndex: "topupDiscount",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#9999ff", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
    {
      align: "center",
      title: "Player Affiliate",
      dataIndex: "playerAffiliate",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#cc9966", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
    {
      align: "center",
      title: "Marketing Affiliate",
      dataIndex: "marketingAffiliate",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#cc9966", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
    {
      align: "center",
      title: "CRM Affiliate",
      dataIndex: "crm",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#cc9966", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
  ];

  const columns3 = [
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "accountDate",
      width:130,
      ellipsis: true,
      fixed : "left",
    },
    {
      align: "center",
      title: "Player Affiliate",
      dataIndex: "playerAffiliate",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#cc9966", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
    {
      align: "center",
      title: "Marketing Affiliate",
      dataIndex: "marketingAffiliate",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#cc9966", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
    {
      align: "center",
      title: "CRM Affiliate",
      dataIndex: "crm",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#996633", marginTop: "15px"}}><b>{FormatData(record)}</b></p>;
      }
    },
  ];

  const mobileColumns = [
    {
      align: "center",
      title: "Data / Time",
      dataIndex: "dateTime",
      width: "50%",
      ellipsis: true,
    },
    {
      title: "Game Type",
      dataIndex: "category",
      width: "50%",
      ellipsis: true,
    },
  ];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    if (report.length == 0 ) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = `Operations Report (${date.format('YYYY-MM-DD')} to ${dateTo.format('YYYY-MM-DD')})\n\n\n`;
    const titleCompany =`Company`;
    const titleDetail = `My Summary`;

    console.log(`OPereation Balance >> ${JSON.stringify(columns)}`);

    const headers = [
      [
        columns[0].title,
        columns[1].title,
        columns[2].title,
        columns[3].title,
        columns[4].title,
        columns[5].title,
        columns[6].title,
        columns[7].title,
        columns[8].title,
        columns[9].title,
        columns[10].title,
        columns[11].title,
        // columns[12].title,
        // columns[13].title,
        // columns[14].title,
        // columns[15].title,
        // columns[16].title,
        // columns[17].title,
        // columns[18].title,
      ],
    ];   

    var filteredReport = [...report];
    const data = filteredReport.map((data) => {
        return [
          data[columns[0].dataIndex],    
          FormatPt(data[columns[1].dataIndex]),
          FormatPt(data[columns[2].dataIndex]),
          FormatPt(data[columns[3].dataIndex]),
          FormatPt(data[columns[4].dataIndex]),
          FormatPt(data[columns[5].dataIndex]),
          FormatPt(data[columns[6].dataIndex]),
          FormatPt(data[columns[7].dataIndex]),
          FormatPt(data[columns[8].dataIndex]),
          FormatPt(data[columns[9].dataIndex]),
          FormatPt(data[columns[10].dataIndex]),
          // FormatPt(data[columns[11].dataIndex]),
          // FormatPt(data[columns[12].dataIndex]),
          // FormatPt(data[columns[13].dataIndex]),
          // FormatPt(data[columns[14].dataIndex]),
          // FormatPt(data[columns[15].dataIndex]),
          // FormatPt(data[columns[16].dataIndex]),
          // FormatPt(data[columns[17].dataIndex]),
          // FormatPt(data[columns[18].dataIndex]),
        ];
    });   

    let content = {
      startY: 80,
      head: headers,
      body: data,
      headStyles: { fillColor: "#1DA57A" },
    };


    doc.text(title, marginLeft, 20);
    doc.autoTable(content);    
    doc.save("Shareholder Report.pdf");
  };

  const datePicker = (date, dateString) => {
    setDate(date);
  };

  const searchBar = () => {
   
    if (!date) {
      reportDateFrom.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select a Month!";
      sharedErrorAlert(alertData);
      return;
    }

    reportList();
  };

  const resetBtn = () => {
    setDate(null);
    setDateTo(null);
    setReport([]);
    setProfitSharingTotal(0);
  };

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      //   path: "first",
      breadcrumbName: "Report",
    },
    {
      path: "operationsReport",
      breadcrumbName:"Operations Report",
    },
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 2;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHearderContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    searchBtn: {
      marginLeft: 30,
    },
    resetBtn: {
      marginLeft: 10,
    },
    datePickerStyle: {
      width: 300,
      borderRadius: 10,
    },
    mobilePageHearderContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    eachButtonContainer: {
      alignItems: "center",
      display: "flex",
      height: 50,
      marginLeft: 15,
      marginRight: 15,
      justifyContent: "center",
    },
    mobileSearchResetBtn: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      height: 50,
      alignItems: "center",
      paddingLeft: 60,
      paddingRight: 60,
    },
    btnContainer: {
      width: "100vw",
      padding: 10,
    },
    pdfStyle: {
      borderRadius: 10,
      width: 1200,
    },
    searchResetStyle: {
      borderRadius: 10,
      marginLeft: 10,
    },
    decStyle: {
      display: "flex",
      flexDirection: "row",
    },
    decLable: {
      width: 200,
    },
    decContainer: {
      paddingLeft: 75,
    },
    webRow: {
      backgroundColor: "#FFFFFF",
      paddingTop: 50,
      paddingLeft: 30,
      paddingBottom: 50,
    },
    mobileRow: {
      backgroundColor: "#FFFFFF",
      paddingLeft: 30,
    },
    cardStyle: {
      borderRadius: 10,
      height: 160,
      width: 260,
      border: "none",
      marginTop: -70,
      marginLeft: -30,
      marginRight: 40,
      marginBottom: -90,
    },
    headStyles: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 20,
      height: 20,
    },
    bodyStyle: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 60,
    },
    detailsStyle:{
      marginLeft:"280px",
    },
    tabelContainer: {
      backgroundColor: "#FFFFFF",
      paddingTop: -24,
    },
    modalStyle: {
      marginTop: -300
    },
  };

  return (
    <div>
        <div className="eachPageContent">
          {isMobile ? (
            <div>
              <PageHeader ghost={false}>
                <div>
                  <Breadcrumb
                    separator=">"
                    itemRender={itemRender}
                    routes={routes}
                  />
                  <div style={styles.btnContainer}>
                    <div style={styles.eachButtonContainer}>
                      <Button
                        type={"primary"}
                        onClick={exportPDF}
                        style={styles.pdfStyle}
                      >
                        Download PDF
                      </Button>
                    </div>
                    
                    <div style={styles.eachButtonContainer}>
                      <DatePicker
                        style={styles.datePickerStyle}
                        onChange={datePicker}
                        value={date}
                        picker="month"
                        ref={reportDateFrom}
                        placeholder="Select Date From"
                      />
                      <Button
                        type={"primary"}
                        onClick={searchBar}
                        style={styles.searchResetStyle}
                        icon={<SearchOutlined />}
                      />
                      <Button onClick={() => setIsModalVisible(true)}>Open modal</Button>
                    
                      <Button
                        onClick={resetBtn}
                        style={styles.searchResetStyle}
                        icon={<UndoOutlined />}
                      />
                    </div>
                  </div>
                  <Row style={styles.mobileRow}>
                    <Card
                      title="Total Share"
                      style={styles.cardStyle}
                      headStyle={styles.headStyles}
                      bodyStyle={styles.bodyStyle}
                    >
                      <Statistic
                        // precision={settings.decimalScale}
                        value={FormatData(share)}
                        valueStyle={{ fontSize: 40 }}
                      />
                    </Card>
                  </Row>
                  <div style={styles.tableContainer}>
                    <Table
                      columns={mobileColumns}
                      dataSource={report}
                      expandable={{
                        expandedRowRender: (record) => (
                          <p style={{ margin: 0 }}>{record.description}</p>
                        ),
                        rowExpandable: (record) => record.name !== "Not Expandable",
                      }}
                      pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ["5", "10", "20"],
                        style: styles.paginationStyle,
                      }}
                      
                    />
                  </div>
                </div>
              </PageHeader>
              
            </div>
          ) : (
            <div>
              <PageHeader
                ghost={false}
                title="Shareholder Report Summary"
                extra={[
                  <DatePicker
                    key="1"
                    style={styles.datePickerStyle}
                    onChange={datePicker}
                    value={date}
                    picker="month"
                    ref={reportDateFrom}
                    placeholder="Select a Month"
                  />,
                  <Button
                    key="2"
                    type={"primary"}
                    style={styles.searchBtn}
                    onClick={searchBar}
                  >
                    Search
                  </Button>,
                  <Button key="3" style={styles.resetBtn} onClick={resetBtn}>
                    Reset
                  </Button>,
                  <Button key="4" type={"primary"} onClick={exportPDF}>
                    Download PDF
                  </Button>,
                ]}
              />
              <div style={styles.tableContainer}>
                <Table
                  columns={columns}
                  dataSource={report}
                  //dataSource={filter}
                  scroll={{ x: 100, y: null }}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: settings.pageSizeOptions,
                    style: styles.paginationStyle,
                    alignmentBottom: 'right'
                  }}
                />
                <Modal 
                  title={"Shareholder Report Details"}
                  visible={isModalVisible}
                  style={{ top : "35%", ...styles.modalStyle }}
                  onCancel={()=>{
                    setIsModalVisible(false);
                      // onCancelApproveTrxn();
                  }}
                  width={1200}
                  footer={null}
                >
                <div style={styles.tabelContainer}>
                    <Table
                      columns={columns2}
                      dataSource= {reportDetail}
                      scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                      pagination={{
                        defaultPageSize: 10,
                        pageSizeOptions: settings.pageSizeOptions,
                        style: styles.paginationStyle,
                        alignmentBottom: 'right'
                      }}
                    />
                  </div>
                </Modal>
                <Modal 
                  title={"Affiliate Details"}
                  visible={isModalVisible2}
                  style={{ top : "35%", ...styles.modalStyle }}
                  onCancel={()=>{
                    setIsModalVisible2(false);
                  }}
                  width={1200}
                  footer={null}
                >
                <div style={styles.tabelContainer}>
                    <Table
                      columns={columns3}
                      dataSource= {reportDetail}
                      scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                      pagination={{
                        defaultPageSize: 10,
                        pageSizeOptions: settings.pageSizeOptions,
                        style: styles.paginationStyle,
                        alignmentBottom: 'right'
                      }}
                    />
                  </div>
                </Modal>
                <br></br>
                  <p><b>REMARK :</b></p>
                  <p><b>(1) <span style={{color:"#ff00ff"}}>Net Game Revenue (NGR)</span> = <span style={{color:"#3333ff"}}>Gross Game Revenue(GGR)</span>&nbsp;
                            - <span style={{color:"#3333ff"}}>Royalities</span> - <span style={{color:"#3333ff"}}>Promotion</span></b></p>
                  <p><b>(2) <span style={{marginTop: "15px"}}><b>Profit Sharing</b></span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            = <span style={{color:"#ff00ff"}}>Net Game Revenue (NGR)</span> - <span style={{color:"#996633"}}>Affiliate Cost</span>&nbsp;
                            - <span style={{color:"#996633"}}>Operation Cost</span>&nbsp;
                            - <span style={{color:"#996633"}}>Custom Fees</span></b></p>
              </div>
            </div>
          )}
          </div>     
    </div>
    
  );

}
