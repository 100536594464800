import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Form,
  Button,
  Modal,
  Badge,
  PageHeader,
  message,
  Breadcrumb,
  Select,
  InputNumber,
  Popconfirm,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  RightOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  userDownlineNew,
  userShareholderList,
  userManageUpdate,
  userManageUpdateV2,
  verifyValidMobile,
  getCompanyProfileData,
  getUserData,
} from "../../config/networkConfig";
import md5 from "md5";
import { useSelector } from "react-redux";
import { isMobile, isIPad13 } from "react-device-detect";
import { Link } from "react-router-dom";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = dataIndex == 'nickname' ? <Input allowClear={true} /> 
                  : dataIndex == 'mobilenumber' ? <InputNumber maxLength={15} style={{width:"170px"}}/>
                  : dataIndex == 'pin' ? <InputNumber maxLength={6} style={{width:"100px"}}/>
                  :<></>;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `${title} cannot be empty !`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        
        children
      )}
    </td>
  );
}

export default function DownlineManagement() {
  const { Option } = Select;
  const [form] = Form.useForm();
  const modalForm = useRef();
  const [originData, setOriginData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [username, setUsername] = useState("");
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const md5Pass = md5(password);
  const [searchText, setSearchText] = useState("");
  const [addVisible, setAddVisible] = useState(false);
  const { userId } = useSelector((state) => state.login);
  const [cardVisible, setCardVisible] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const [loadings, setLoadings] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("856");
  const [mobilenumber, setMobileNumber] = useState("");
  const [createMemberDisable, setCreateMemberDisable] = useState(true);
  const [cancelDisable, setCancelDisable] = useState(false);
  const [pin,setPin] = useState("");
  const [companyCountryCode, setCompanyCountryCode] = useState("");
  const isLandscape = window.orientation === 90 || window.orientation === -90;

  useEffect(() => {
    if (window.DeviceOrientationEvent) {
      window.addEventListener(
        "orientationchange",
        function () {
          window.location.reload();
        },
        false
      );
    }
    loadCompanyProfileData();
    downlineList();
    // eslint-disable-next-line
  }, []);

  const loadCompanyProfileData = async() => {
    // Step 1: Get company id from user profile based on userId
    let requestData = {
      userID : userId,
      columnRequire : ['uuidfrombin(company) as companyid']
    };

    const response = await getUserData(requestData);
    let result = await response.json();

    if (result.status === "ok") {
      let requestData = { 
        companyID: result.data[0].companyid,
        columnRequire: ['MobileCode']
      }

      // Step 2: Get company profile based on company id from step 1
      const response2 = await getCompanyProfileData(requestData);
      let result2 = await response2.json();
      if (result2.status === "ok") {
        setCompanyCountryCode(result2.data[0].MobileCode);
      }
      else {
        alertData.title = "Failed";
        alertData.msg = "Connection not stable, please try again !";
        sharedFailAlert(alertData);
      }
    }
    else {
      alertData.title = "Failed";
      alertData.msg = "Connection not stable, please try again !";
      sharedFailAlert(alertData);
    }
  }

  const downlineList = () => {
    var count = 0;
    setTableLoading(true);
    userShareholderList({ userId: userId })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          setTableLoading(false);
          setAddVisible(false);
          const newData = Object.keys(json.data).length
            ? json.data.map((x) => {
                return {
                  key: count++,
                  userid: x.userId,
                  username: x.username,
                  pin: x.pin,
                  nickname: x.nickname,
                  mobilenumber: x.mobilenumber,
                  lastLogin: x.lastLogin,
                  registerDate: x.registerDate,
                  usertype: x.type,
                  type: `${x.shareCount}/${x.agentCount}/${x.memberCount}`,
                  status:
                    x.status === "0"
                      ? "Lock"
                      : x.status === "1"
                      ? "Active"
                      : "Inactive",
                };
              })
            : [];
          setOriginData(newData);
        } 
        else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
          setTableLoading(true);
        }
      })
      .catch((error) => {
        alertData.title = "Error";
        alertData.msg = error;
        sharedErrorAlert(alertData);
      });
  };

  const showModal = () => {
    setCancelDisable(false);
    setCreateMemberDisable(true);
    setEditingKey("");
    setAddVisible(true);
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },

    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  const columns = [
    {
      align: "center",
      title: "Username",
      dataIndex: "username",
      width: "200px",
      ...getColumnSearchProps("username"),
    },
    {
      align: "center",
      title: "Nickname",
      dataIndex: "nickname",
      width: "150px",
      editable: true,
      ...getColumnSearchProps("nickname"),
    },
    {
      ellipsis: true,
      align: "center",
      title: "Mobile Number",
      dataIndex: "mobilenumber",
      width: "230px",
      editable: true,
      ...getColumnSearchProps("mobilenumber"),
    },
    {
      ellipsis: true,
      align: "center",
      title: "PIN",
      dataIndex: "pin",
      editable: true,
      width: "150px",
    },
    {
      align: "center",
      title: "Register Date",
      dataIndex: "registerDate",
      width: "200px",
    },
    {
      ellipsis: true,
      align: "center",
      title: "No. of SH/AG/MB",
      dataIndex: "type",
      width: "150px",
    },
    {
      align: "center",
      title: "Status",
      dataIndex: "status",
      width: "100px",
      render: (text, record) => {
        switch (record.status) {
          case "Lock":
            return <Badge status="warning" text="Lock" />;
          case "Active":
            return <Badge status="success" text="Active" />;
          default:
            return <Badge status="error" text="Inactive" />;
        }
      },
    },
    {
      width: "250px",
      ellipsis: true,
      align: "center",
      title: "Action",
      dataIndex: "operation",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Input
              id={"pwd" + record.key}
              placeholder="Change Password"
              style={{ width: "auto", textAlign: "center" }}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <Button
              type="dashed"
              shape="circle"
              icon={<SaveOutlined />}
              loading={loadings[1]}
              onClick={() => {
                save(record, 1);
              }}
            />
            <div>
              <Popconfirm
                placement="topRight"
                title="Confirm cancel?"
                onConfirm={() => cancel(record.key)}
              >
                <Button
                  type="danger"
                  shape="circle"
                  icon={<CloseCircleOutlined />}
                />
              </Popconfirm>
            </div>
          </div>
        ) : (
          <span disabled={editingKey !== ""} onClick={() => edit(record)}>
            <Button type="primary" shape="circle" icon={<EditOutlined />} />
          </span>
        );
      },
    },
  ];

  const isEditing = (record) => record.key === editingKey;

  const edit = async (record) => {
    form.setFieldsValue({
      nickname: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const save = async (record, index) => {
    const row = await form.validateFields();
    const { nickname, mobilenumber, pin } = row;
    let requestData = {
      userid : record.userid
    };

    setTableLoading(true);
    
    if (nickname != record.nickname) {
      requestData.nickname = nickname;
    }

    if (mobilenumber != record.mobilenumber && String(mobilenumber).length < 10) {
      setTableLoading(false);
      alertData.title = "Format Error";
      alertData.msg = "New mobile number is too short !";
      sharedErrorAlert(alertData);
      return;
    }
    
    let tmpMobile = '';

    if (mobilenumber != record.mobilenumber && String(mobilenumber).length >= 10) {
      let substringLength = String(companyCountryCode).length;

      if (String(mobilenumber).substring(0,substringLength) != companyCountryCode) {
        tmpMobile = `${companyCountryCode}` + mobilenumber;
        // if(companyCountryCode == '60') {
          
        //   if(mobilenumber.substring(0,1) == '0') {
        //     requestData.mobile = `${String(companyCountryCode).substring(0,1)}` + mobilenumber;
        //   }
        //   else {
        //     requestData.mobile = `${companyCountryCode}` + mobilenumber;
        //   }
        // }
        // else{
        //   requestData.mobile = `${companyCountryCode}` + mobilenumber;
        // }
      }
      else {
        tmpMobile = mobilenumber;
      }
      // Verify if number is used by all type of user
      const verifyNumber = await validateUserPhoneV2(String(tmpMobile));
      if (verifyNumber == true) {
        requestData.mobile = String(tmpMobile);
      }
      else{
        setTableLoading(false);
        alertData.title = "Validation Error";
        alertData.msg = `+${tmpMobile} is used by another user !`;
        sharedFailAlert(alertData);
        return;
      }
    }

    if (String(pin).length < 6) {
      setTableLoading(false);
      alertData.title = "Format Error";
      alertData.msg = "PIN must not less than 6 digit !";
      sharedErrorAlert(alertData);
      return;
    }

    if (pin != record.pin) {
      if(Number.isInteger(pin) == true) {
        requestData.pin = pin;
      }
      else{
        setTableLoading(false);
        alertData.title = "Format Error";
        alertData.msg = "PIN must contain only digit / number !";
        sharedErrorAlert(alertData);
        return;
      }
    }

    if (document.getElementById("pwd" + record.key).value != '') {
      requestData.pass = md5Pass;
    }

    if (nickname === record.nickname && mobilenumber === record.mobilenumber && pin === record.pin && document.getElementById("pwd" + record.key).value === "") {
      setEditingKey("");
      setTableLoading(false);
      return;
    }

    try {
      // const newData = [...originData];
      // const indexSave = newData.findIndex((item) => record.key === item.key);
      // loadingTrue(index);
      // if (indexSave > -1) {
      if (requestData != {}) {
        userManageUpdateV2(requestData)
          .then((response) => response.json())
          .then((json) => {
            if (json.status === "ok") {
              alertData.title = "Success";
              alertData.msg = json.msg;
              sharedSuccessAlert(alertData);
              loadingFalse(index);
              // const item = newData[indexSave];
              // newData.splice(indexSave, 1, { ...item, ...row });
              setEditingKey("");
              downlineList();
            }
            else {
              alertData.title = "Failed";
              alertData.msg = json.msg;
              sharedFailAlert(alertData);
              loadingFalse(index);
              setTableLoading(false);
            }
          })
            .catch((error) => {
              alertData.title = "Error";
              alertData.msg = error;
              sharedErrorAlert(alertData);
          });
        }
      }
      catch (errInfo) {
      console.log("errInfo: ", errInfo);
      alertData.title = "Error";
      alertData.msg = "Connection not stable, please try again !";
      sharedErrorAlert(alertData);
    }
    // loadingFalse(index);
    // form.resetFields();
  };

  const handleAdd = (index) => {
    var reg = /^\d*[a-zA-Z]{1,}\d*/;

    if (!username || !password || !nickname || !mobilenumber || !pin) {
      alertData.title = "Field Required";
      alertData.msg = "Please fill in Mobile Number, Pin, Username, Nickname, Password !";
      sharedErrorAlert(alertData);
      return;
    }

    if (!username.match(reg)) {
      alertData.title = "Format Error";
      alertData.msg = "Username only accept lowercase characters and numbers !";
      sharedErrorAlert(alertData);
      return;
    }
    if (pin.length < 6  || pin.length > 6 ) {
      alertData.title = "Invalid Pin";
      alertData.msg = "PIN must be 6 characters !";
      sharedErrorAlert(alertData);
      return;
    }
    if (mobilenumber.length < 7 ) {
      alertData.title = "Invalid Mobile No";
      alertData.msg = "Mobile number is too short !";
      sharedErrorAlert(alertData);
      return;
    }
    

    loadingTrue(index);
    setCancelDisable(true);
    userDownlineNew({
      userId: userId,
      username: username.toLowerCase().trim(),
      nickname: nickname,
      mobilenumber: countryCode+mobilenumber,
      pin:pin,
      pass: md5Pass,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          loadingFalse(index);
          alertData.title = "Success";
          alertData.msg = json.msg;
          sharedSuccessAlert(alertData);
          downlineList();
          onCancel();
        } 
        else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
        loadingFalse(index);
        // modalForm.current.resetFields();
      })
      .catch((error) => {
        alertData.title = "Error";
        alertData.msg = error;
        sharedErrorAlert(alertData);
      });
  };

  const selectCountryCode = (value) => {
    setCountryCode(value);
  };

  const validateUserPhone = async(mobilenumber)=> {
    const response = await verifyValidMobile({mobile: countryCode+mobilenumber});
    let result = await response.json();
    if (result.status === "ok") {
      setCreateMemberDisable(true);
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
    }
    else{
      setCreateMemberDisable(false);
    }
  }

  const validateUserPhoneV2 = async(mobilenumber)=> {
    const response = await verifyValidMobile({mobile: mobilenumber});
    let result = await response.json();

    if (result.status === "ok") {
      return false;
    }
    else{
      return true;
    }
  }

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onCancel = () => {
    setAddVisible(false);
    setUsername("");
    setNickname("");
    setMobileNumber("");
    setPin("");
    setPassword("");
    modalForm.current.resetFields();
  };

  const cardStyle = () => {
    const newData = [...originData];

    // const statusBgColor = Object.values(newData).map((data) => {

    //   return data.status === "Active"
    //     ? "linear-gradient(45deg,#FFFFFF, #1DA57A)"
    //     : data.status === "Inactive"
    //     ? "linear-gradient(45deg,#FFFFFF, #FF0000)"
    //     : "linear-gradient(45deg,#FFFFFF, #FFD700)";
    // });

    const statusBgColor = (status) => {
      switch (status) {
        case "Active":
          return "#1DA57A";
        case "Inactive":
          return "#FF0000";
        default:
          return "#FFD700";
      }
    };

    const cardList = Object.values(newData).map((data) => {
      return (
        <div
          key={data.key}
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              backgroundColor: statusBgColor(data.status),
              height: 80,
              width: 10,
              boxShadow: "5px 8px 5px 5px rgba(208, 216, 243, 0.6)",
              marginBottom: 30,
              borderTopLeftRadius: 20,
              borderBottomLeftRadius: 20,
            }}
          />
          <Button
            type="primary"
            className="cardStyle"
            style={{
              // backgroundImage: statusBgColor,
              backgroundColor: "#FFFFFF",
              color: "#000000",
              marginBottom: 30,
              // border: "none",
              border: "1px solid gray",
            }}
            onClick={() => {
              setCardVisible(true);
              setActiveKey(data.key);
            }}
          >
            <div>{data.status}</div>
            <div>
              <div>{data.username}</div>
              <div>SH/AG/MB: {data.type}</div>
            </div>
            <div>
              <RightOutlined />
            </div>
          </Button>
        </div>
      );
    });

    const getUserId = Object.values(newData).map((data) => data.userid);

    const cancel = () => {
      var usernameStyle = document.getElementById("username");
      var passStyle = document.getElementById("password");
      var edit = document.getElementById("edit");

      setCardVisible(false);
      usernameStyle.style.border = "none";
      usernameStyle.style.padding = "0px";
      passStyle.style.border = "none";
      passStyle.style.padding = "0px";
      passStyle.innerHTML = "";
      edit.innerHTML = "Edit";
    };

    // const save = (index) => {
    //   var usernameStyle = document.getElementById("username");
    //   var passStyle = document.getElementById("password");
    //   var edit = document.getElementById("edit");
    //   loadingTrue(index);
    //   userManageUpdate({
    //     userId: getUserId,
    //     nickname: nickname,
    //     pass: md5Pass,
    //   })
    //     .then((response) => response.json())
    //     .then((json) => {
    //       if (json.status === "ok") {
    //         loadingFalse(index);
    //         setCardVisible(false);
    //         message.success(json.msg);
    //         usernameStyle.style.border = "none";
    //         usernameStyle.style.padding = "0px";
    //         passStyle.style.border = "none";
    //         passStyle.style.padding = "0px";
    //         passStyle.innerHTML = "";
    //         edit.innerHTML = "Edit";
    //       } else {
    //         loadingTrue(index);
    //         message.error(json.msg);
    //       }
    //     })
    //     .catch((error) => {
    //       message.error(error);
    //     });
    // };

    // const edit = () => {
    //   var edit = document.getElementById("edit");
    //   var usernameStyle = document.getElementById("username");
    //   var passStyle = document.getElementById("password");

    //   edit.innerHTML = "Save";
    //   usernameStyle.contentEditable = true;
    //   usernameStyle.style.border = "1px solid gray";
    //   usernameStyle.style.paddingLeft = "10px";
    //   usernameStyle.style.paddingRight = "10px";
    //   usernameStyle.addEventListener(
    //     "input",
    //     function (e) {
    //       setNickname(e.currentTarget.textContent);
    //     },
    //     false
    //   );

    //   passStyle.contentEditable = true;
    //   passStyle.style.border = "1px solid gray";
    //   passStyle.style.width = "100px";
    //   passStyle.style.paddingLeft = "10px";
    //   passStyle.style.paddingRight = "10px";
    //   passStyle.addEventListener(
    //     "input",
    //     function (e) {
    //       setPassword(e.currentTarget.textContent);
    //     },
    //     false
    //   );
    // };

    const ok = (e) => {
      var x = document.getElementById("edit");
      if (x.innerHTML === "Edit") {
        edit();
      } else if (x.innerHTML === "Save") {
        save(2);
      }
    };

    const details = () => {
      var newData = [...originData];
      const status = Object.values(newData).map((data) => {
        return data.status === "Active" ? (
          <Badge key="1" status="success" text="Active" />
        ) : data.status === "Inactive" ? (
          <Badge key="2" status="error" text="Inactive" />
        ) : (
          <Badge key="3" status="warning" text="Lock" />
        );
      });

      const username = Object.values(newData).map((data) => data.username);
      const type = Object.values(newData).map((data) => data.type);
      const nickname = Object.values(newData).map((data) => data.nickname);
      const mobilenumber = Object.values(newData).map((data) => data.mobilenumber);
      const pin = Object.values(newData).map((data) => data.pin);
      const registerDate = Object.values(newData).map(
        (data) => data.registerDate
      );
      return (
        <div style={styles.decContainer}>
          <div style={styles.decStyle}>
            <p style={styles.decLable}>Status:</p>
            <p>{status[activeKey]}</p>
          </div>
          <div style={styles.decStyle}>
            <p style={styles.decLable}>Username:</p>
            <p>{username[activeKey]}</p>
          </div>
          <div style={styles.decStyle}>
            <p style={styles.decLable}>Nickname:</p>
            <p id="username" style={{ border: "none" }} type="text">
              {nickname[activeKey]}
            </p>
          </div>
          <div style={styles.decStyle}>
            <p style={styles.decLable}>Mobile Number:</p>
            <p id="mobilenumber" style={{ border: "none" }} type="text">
              {mobilenumber[activeKey]}
            </p>
          </div>
          <div style={styles.decStyle}>
            <p style={styles.decLable}>Password:</p>
            <p type="text" id="password" style={{ border: "none" }} />
          </div>
          <div style={styles.decStyle}>
            <p style={styles.decLable}>SH/AG/MG:</p>
            <p>{type[activeKey]}</p>
          </div>
          <div style={styles.decStyle}>
            <p style={styles.decLable}>Registered Date:</p>
            <p>{registerDate[activeKey]}</p>
          </div>

          <div style={styles.modalBottomBtn}>
            <Button
              loading={loadings[2]}
              type="primary"
              style={styles.okBtn}
              onClick={() => {
                ok(2);
              }}
            >
              <span id="edit">Edit</span>
            </Button>
            <Button
              type="primary"
              style={{ width: 80 }}
              onClick={() => {
                cancel();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    };

    return (
      <div>
        <Modal
          key={activeKey}
          title="Detail"
          visible={cardVisible}
          footer={null}
          maskClosable={false}
        >
          {details()}
        </Modal>
        {cardList}
      </div>
    );
  };

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "downlineManagement",
      breadcrumbName: "Downline Management",
    },
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };

  const landscape = () => {
    return (
      <div>
        <PageHeader
          style={{ marginBottom: 50 }}
          ghost={false}
          title="Downline Management"
          extra={[
            <div key="1">
              <Button type="primary" onClick={showModal}>
                Add Downline
              </Button>
            </div>,
          ]}
        />

        <div style={styles.tableContainer}>
          <Form form={form} component={false}>
            <Table
              loading={tableLoading}
              scroll={{ x: "100%", y: null }}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              dataSource={originData}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={{
                onChange: cancel,
              }}
              style={{ overflow: "auto" }}
            />
          </Form>
        </div>
      </div>
    );
  };

  const portrait = () => {
    return (
      <div>
        <PageHeader>
          <Breadcrumb separator=">" itemRender={itemRender} routes={routes} />
        </PageHeader>
        <PageHeader
          style={styles.PageHeaderStyle}
          ghost={false}
          extra={[
            <div key="1" style={styles.extraStyle}>
              <Button
                type="primary"
                onClick={showModal}
                style={styles.mobileAddBtn}
              >
                Add Shareholder
              </Button>
            </div>,
          ]}
        >
          {cardStyle()}
        </PageHeader>
      </div>
    );
  };

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    mobileAddBtn: {
      borderRadius: 10,
      width: 1200,
    },
    decStyle: {
      display: "flex",
      flexDirection: "row",
    },
    decLable: {
      width: 140,
    },
    decContainer: {
      paddingLeft: 75,
    },
    extraStyle: {
      alignItems: "center",
      display: "flex",
      height: 50,
      marginLeft: 15,
      marginRight: 15,
      justifyContent: "center",
      width: "100vw",
      padding: 10,
    },
    PageHeaderStyle: {
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
    },
    okBtn: {
      marginRight: 10,
      borderColor: "#000000",
      backgroundColor: "#FFFFFF",
      color: "#000000",
      width: 80,
    },
    modalBottomBtn: {
      display: "flex",
      flexDirection: "row",
      height: 50,
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
  };

  return (
    <div>
      <Modal
        maskClosable={false}
        title="New Downline"
        visible={addVisible}
        footer={null}
        closable={false}
      >
        <Form ref={modalForm}>
          <Form.Item
          name={["user", "mobilenumber"]}
          label="Mobile No"
          required={true}
          >
          <Input.Group compact>
            <Select style={{width:"21%"}} value={countryCode}
              onChange={selectCountryCode}>
              <Option value="856">+856</Option>
              <Option value="66">+66</Option>
              <Option value="60">+60</Option>
            </Select>
            <Input style={{width:"79%"}} defaultValue="" placeholder="phone number" value={mobilenumber}
              onChange={(e) => {
                setMobileNumber(e.target.value.replace(/\D|^0/g, ""));
              }}
              onBlur={(e)=>{
                validateUserPhone(e.target.value.replace(/\D|^0/g, ""))
              }}
            />
          </Input.Group>
          </Form.Item>
          <Form.Item
          name={["user", "pin"]}
          label="Pin&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
          required
          >      
              <Input.Group compact>
                <Input
                  value={pin}
                  placeholder="6 Digit Pin"
                  maxLength={6}
                  onChange={(e) => {
                      setPin(e.target.value.replace(/\D|^0/g, ""));
                  }}
                />
              </Input.Group>
              
          </Form.Item>
          <Form.Item
            name={["user", "username"]}
            label="Username"
            required
            rules={[
              () => ({
                validator(rule, value) {
                  var regEx = new RegExp(/^\d*[a-z]{1,}\d*/);

                  if (regEx.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "Only accept lowercase characters and numbers!"
                  );
                },
              }),
            ]}
          >
            <Input
              value={username}
              placeholder="Username"
              onChange={(e) => {
                setUsername(e.target.value.trim());
              }}
            />
          </Form.Item>
          <Form.Item
            name={["user", "nickname"]}
            label="Nickname"
            required
          >
            <Input
              value={nickname}
              placeholder="Nickname"
              onChange={(e) => {
                setNickname(e.target.value.trim());
              }}
            />
          </Form.Item>
          <Form.Item
            name={["user", "password"]}
            label="Password"
            required
          >
            <Input
              value={password}
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value.trim());
              }}
            />
          </Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            <Button
              htmlType="button"
              onClick={onCancel}
              style={{ marginRight: 10 }}
              // okButtonProps={
              //   cancelDisable? 
              //   (
              //     { loading: loadings  , disabled: cancelDisable }
              //   ):
              //   ({})
              // }
              disabled={cancelDisable}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"          
              loading={loadings[0]}             
              // okButtonProps={
              //   createMemberDisable? 
              //   (
              //     { loading: loadings  , disabled: createMemberDisable }
              //   ):
              //   ({})
              // } 
              disabled={createMemberDisable}
              onClick={() => {
                handleAdd(0);
              }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {isIPad13 || isLandscape
        ? landscape()
        : isMobile
        ? portrait()
        : landscape()}
    </div>
  );
}
