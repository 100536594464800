import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  PageHeader,
  Button,
  Input,
} from "antd";
import {
    otpCheck,
} from "../../config/networkConfig";
import { useLocation }from "react-router-dom";
import { isMobile } from "react-device-detect";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";

export default function OtpCheck() {
    const { search } = useLocation();
    const searchParam = new URLSearchParams(search);
    const userid = searchParam.get('uid');

    const [tableLoading, setTableLoading] = useState(false);
    const [searchMobile,setSearchMobile] = useState("");
    const reportMobile = useRef(null);

    //OTP List
    const [otpData,         setOtpData]   = useState("");
    const [ulTableLoading, setUlTableLoading]   = useState(false);    

    useEffect(()=> {
      //  setSearchMobile();
      //  onSearchOtp();
    },[]);

    const onSearchOtp = async(mobile)=> {
      setSearchMobile(mobile);
      await getOtpData(mobile);
    }

    const getOtp = async ()=> {

      if (!searchMobile) {
        reportMobile.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please input a mobile number !";
        sharedErrorAlert(alertData);
        return;
      }
      onSearchOtp(searchMobile);
    }

    const resetBtn = () => {
      setSearchMobile("");
      setOtpData([]);
    }

    const getOtpData = async (mobile) => {
      setOtpData([]);
      let requestData = {
          mobile : mobile,
      }

      setUlTableLoading(true);

      const otpCheckData = await otpCheck(requestData);
  
      let respOtpCheck = await otpCheckData.json();

      if (respOtpCheck.status ==="ok") {

        if (Object.keys(respOtpCheck.data).length > 0 ) {
          setTableLoading(false);

          //Get Upline Data
          const newData = Object.keys(respOtpCheck.data).length
            ? respOtpCheck.data.map((obj) => {
                setTableLoading(false);
                return {
                  mobile      : obj.Mobile,
                  otp         : obj.OTP,
                  sentTime    : obj.SentTime,
                  };
              })
            : [];
  
           //console.log(`respOtpCheck.data.detail >> ${JSON.stringify(respOtpCheck.data.detail)}`);
            setOtpData(newData);

            setUlTableLoading(false);
        }

      }else{
        alertData.title = "Failed";
        alertData.msg = respOtpCheck.msg;
        sharedFailAlert(alertData);
        setUlTableLoading(false);
      }
    }

    const otpCheckColumns = [
      {
        align: "center",
        title: "Mobile",
        dataIndex: "mobile",
        width: "100",
        fixed:"left",
        render: function(record) {
          return <p>{record}</p>;
        }
      },
      {
        align: "center",
        title: <div>OTP</div>,
        dataIndex: "otp",
        width: "100",
        render: function(record) {
          return <p>{record}</p>;
        }
      },
      {
        align: "center",
        title: <div>Sent Time</div>,
        dataIndex: "sentTime",
        width: "100",
        render: function(record) {
          return <p>{record}</p>;
        }
      },
    ]

    const styles = {
      pageHeaderContainer: {
        justifyContent: isMobile ? "" : "space-between",
        display: "flex",
        flexDirection: isMobile ? "column" : "",
        alignItems: isMobile ? "center" : "",
      },
      refreshBtn: {
        marginLeft: 10,
      },
      tabelContainer: {
        backgroundColor: "#FFFFFF",
        padding: 24,
      },
      buttonStyle:{
        fontSize:"14px"
      },
      memberSearchStyle:{
        width:200,
      },
    };

    return (
      <div className="reportDownline">      
        {
          <div>
          <PageHeader ghost={false}>
            <div style={styles.pageHeaderContainer}>
              <div style={{float:"left"}}>
                  <Input
                    type="text"
                    id="searchMobileNo"
                    placeholder="Enter Mobile Number"
                    style={{...styles.refreshBtn,...styles.memberSearchStyle}}
                    onChange={(e) => {
                        setSearchMobile(e.target.value.replace(/\D|^0/g, ""));                
                    }}
                    allowClear={true}
                    value={searchMobile}
                    ref={reportMobile}
                  >
                  </Input>

                  <Button 
                  style={{...styles.refreshBtn,...styles.buttonStyle}}
                  type="primary"
                  onClick={()=>{
                      getOtp();
                  }}
                  >
                      Get OTP
                  </Button>
                
                  <Button
                      style={{...styles.refreshBtn,...styles.buttonStyle}}
                    type="primary"
                    onClick={() => {
                      resetBtn();
                    }}
                  >
                    Reset
                  </Button>
              </div>
            </div>
          </PageHeader>
          </div>
        }
        <div style={{...styles.tabelContainer}} className="body">
          <Table
              columns={otpCheckColumns}
              dataSource={otpData}
              pagination={false}
              loading={ulTableLoading}
          />
        </div>
      </div>
        
    );
  }


