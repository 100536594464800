import React, { useState, useEffect } from "react";
import {
  Table,
  message,
  PageHeader,
  Button,
  Modal,
  Input,
  Badge,
  Select,
  Form,
  DatePicker,
  Row,
  Col,
  Tooltip,
} from "antd";
import {
  csMemberList,
  userMemberLock,
  userMemberActive,
  userMemberInactive,
  csCreateMemberV2,
  csAvailBankList,
  csUpdateMember,
  userProfileBank,
  csVerifyMobile,
  csMemberWalletSummary,
  csMemberWalletTrxn,
  csMemberGameSummary,
  csMemberGameTrxn,
} from "../../config/networkConfig";
import { useSelector } from "react-redux";
import { SearchOutlined, InfoCircleFilled, DollarOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import { settings } from "../../config/appConfig";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { FormatPt} from "../../library/numbers";
import { CS_USER_TYPE } from "../../common/constants/csuserType";

export default function MemberManagement() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { Option } = Select;
  const { companyId , username, role } = useSelector((state) => state.login);
  const [originData, setOriginData] = useState("");
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(true);
  const [reason, setReason] = useState("");
  const [memberUserId, setMemberUserId] = useState("");
  const [activeKey, setActiveKey] = useState("");
  const [searchText, setSearchText] = useState("");
  const [loadings, setLoadings] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [addMemberModal, setAddMemberModal] = useState(false);
  const [editMemberModal, setEditMemberModal] = useState(false);
  const [csMemberListOptions, setCSMemberListOptions] = useState([]);
  const [csAvaBankList, setCSAvaBankListOptions] = useState([]);
  
  //Data
  const [phoneNum, setPhoneNum] = useState("");
  const [countryCode, setCountryCode] = useState("60");
  const [pin,setPin] = useState("");
  //const [userId, setUserId] = useState("");
  const [bankcode, setBankCode] = useState("");  
  const [acctnum, setAcctNum] = useState("");  
  const [date, setDate] = useState("");
  const [acctname,setAcctname] = useState("");
  const [referCode,setReferCode] = useState("");
  const [lineId, setLineId] = useState("");
  const [gender, setGender] = useState("");
  const [editMode,setEditMode] = useState(false);
  const [referEditDisable,setReferEditDisable] = useState(true);
  const [createMemberDisable,setCreateMemberDisable] = useState(true);
  const [editMemberDisable,setEditMemberDisable] = useState(false);
  const [prevbankcode, setPrevBankCode] = useState("");
  const [prevAcctnum,  setPrevAcctNum] = useState("");

  //Wallet Transaction 
  const [viewTrx,setViewTrx] = useState("");
  const [puppetVisible, setPuppetVisible]= useState(false);
  const [csMemberWalletDt,setCSMemberWalletDt] = useState("");
  const [csMemberWalletBal, setCSMemberWalletBal] = useState(0);
  const [csMemberWalletComm,setCSMemberWalletComm] = useState(0);
  const [filteredPptTrxn,setFilterPptTrxn]= useState([]);

  //Game Transaction
  const [gameVisible, setGameVisible]= useState(false);
  const [gameTrxn, setGameTrxn]= useState([]);
  const [csGameBetAmt,setCSGameBetAmt] = useState(0);
  const [csGameUser, setCSGameUser] = useState("");
  const [csGameValidAmt,setCSGameValidAmt] = useState(0);
  const [csGameWinloss,setCSGameWinloss] = useState(0);
  const [csGameTurnover,setCSGameTurnover] = useState(0);
  const [csGameMbrTrxnAudit,setCSGameMbTrnxAudit]= useState(0);
  const [viewWallet,setViewWallet] = useState(false);

  useEffect(() => {
    memberList();
    // eslint-disable-next-line
    availBankList();
  }, []);

  //#region Lists
  const memberList = () => {
    //console.log(`companyId >> ${companyId}`);
    var count = 0;
    setTableLoading(true);
    csMemberList({ userId: companyId })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          const newData = Object.keys(json.data).length
            ? json.data.map((x) => {
                setTableLoading(false);
                return {
                  key: count++,
                  userId: x.userId,
                  nickname: x.nickname,
                  registered: x.registerDate,
                  lastLogin: x.lastLogin,
                  mobile: x.mobile,
                  pin: x.pin,
                  bankcode : x.bankCode, 
                  referCode : x.referCode, 
                  acctnum : x.acctnum, 
                  acctname: x.acctname,
                  lineid: x.lineid,
                  dob : x.dob,
                  gender: x.gender,
                  status:
                    x.status === "0"
                      ? "Lock"
                      : x.status === "1"
                      ? "Active"
                      : x.status === "2"
                      ? "Withdraw Locked"
                      : x.status === "3"
                      ? "Account Locked"
                      : "Inactive",
                  action:
                    x.status === "0"
                      ? "Unlock"
                      : x.status === "1"
                      ? "Inactive"
                      : x.status === "2"
                      ? "Withdraw Locked"
                      : x.status === "3"
                      ? "Account Locked"
                      : "Lock",
                 };
              })
            : [];
          setOriginData(newData);
        } else {
          message.error(json.msg);
          setTableLoading(true);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        message.error(
          "An unexpected error occurred. Contact your system administrator "
        );
      });
  };


  const availBankList = async () => {

    csAvailBankList({companyId: companyId})
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          setCSAvaBankListOptions(json.data);
        }
    })
    .catch((error) => {
      console.log("error: ", error);
      message.error(
        "An unexpected error occurred. Contact your system administrator "
      );
    });
  };
  //#endregion Lists

  //#region functions
    const validateUserPhone = async(phoneNum)=> {
      const response = await csVerifyMobile({mobile: countryCode+phoneNum});
      let result = await response.json();

      if (result.status === "ok") {
        setCreateMemberDisable(true);
        setEditMemberDisable(true);
        message.error(result.msg);
      }else{
        setCreateMemberDisable(false);
        setEditMemberDisable(false);
      }
  }

  const onChangeAcctNum  = (e) => {
    setAcctNum(e.target.value.replace(/\D|^/g, ""));
  }

  ////#endregion //

  const datePicker = (date, dateString) => {
    setDate(dateString);
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });

  const ViewPPTDetails = async (record) => {

    setViewTrx("trxn");
     
    const response = await csMemberWalletSummary({userid:record.userId});
    const respTrxn = await csMemberWalletTrxn({userid:record.userId});
    let result = await response.json();
    let resTrxn = await respTrxn.json();

    if (result.status === "ok") {
       if (result.data && result.data.length) {
         let walletSumm = result.data[0];

         if (resTrxn.status === "ok") {
           if (resTrxn.data && resTrxn.data.length) {
             console.log(`res >> ${JSON.stringify(resTrxn.data)}`);
                setFilterPptTrxn(resTrxn.data);  
           }
         }
          
          setCSMemberWalletDt(walletSumm.Dt);
          setCSMemberWalletBal(`${FormatPt(walletSumm.Balance)}`);
          setCSMemberWalletComm(`${FormatPt(walletSumm.Comm)}`);
          
       }
       setPuppetVisible(true);  

    }else {
        message.error("Transaction not found");
    }
  }

  const ViewGameDetails = async (record) => {

    //record.userId ='5d177c0d-8016-4c72-b2cf-07f133d496a8';
    setViewTrx("turnover");
    
    const response = await csMemberGameSummary({userid:record.userId});
    const respTrxn = await csMemberGameTrxn({userid:record.userId});
    let result = await response.json();
    let resTrxn = await respTrxn.json();

    if (result.status === "ok") {
       if (result.data && result.data.length) {
         let walletSumm = result.data[0];

         if (resTrxn.status === "ok") {
           if (resTrxn.data && resTrxn.data.length) {

                setFilterPptTrxn(resTrxn.data);  
           }
         }
          setCSGameUser(walletSumm.GameUser);
          setCSGameBetAmt(walletSumm.BetAmt);
          setCSGameWinloss(walletSumm.WinLoss_AUDIT);
          setCSGameTurnover(walletSumm.SumTurnover);
          //setGameVisible(true);     
       }

       setPuppetVisible(true);   
    }else {
        message.error("Transaction not found");
    }
  }

  //#endregion


  const columns = [
    {
      ellipsis: true,
      align: "center",
      title: "MB Nickname",
      dataIndex: "nickname",
      ...getColumnSearchProps("nickname"),
      width: 200,
    },
    {
      ellipsis: true,
      align: "center",
      title: "Registered",
      dataIndex: "registered",
      width: 200,
    },
    {
      ellipsis: true,
      align: "center",
      title: "Last Login",
      dataIndex: "lastLogin",
      width: 200,
    },
    {
      width: 200,
      ellipsis: true,
      align: "center",
      title: "Status",
      dataIndex: "STATUS",
      // render: (text, record) => {
      //   switch (record.status) {
      //     case "Lock":
      //       return <Badge status="warning" text="Lock" />;

      //     case "Active":
      //       return <Badge status="success" text="Active" />;

      //     case "Withdraw Locked":
      //       return <Badge status="warning" text="Withdraw Locked" />;

      //     case "Account Locked":
      //       return <Badge status="warning" text="Account Locked" />;

      //     default:
      //       return <Badge status="error" text="Inactive" />;
      //   }
      // },
    },
    {
      ellipsis: true,
      width: 250,
      align: "center",
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {

        switch (record.action) {
          
          case "Inactive":
            return (
              <div
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  display: "flex",
                }}
              >
                {
                  //role === CS_USER_TYPE.Senior || role === CS_USER_TYPE.CS ?
                  (
                    <>
                      <Button
                      style={{
                        width: "70px",
                        marginRight: 20,
                        backgroundColor: "#F7B105",
                        border: "none",
                        color: "#FFFFFF",
                      }}
                      onClick={() => {
                        handleLock(record);
                      }}
                    >
                      Lock
                    </Button>
                    <Button
                      style={{
                        border: "none",
                        backgroundColor: "#FF0000",
                        color: "#FFFFFF",
                        display:"none",
                      }}
                      onClick={() => {
                        handleInactive(record);
                      }}
                    >
                      Inactive
                    </Button>                    
                    </>
                  )//:(<></>)
                }           
                  <Button
                    style={styles.refreshBtn}
                    type={"primary"}
                    onClick={() => {
                      handleMember(record);
                    }}
                  >
                    Edit
                  </Button>  
                  &nbsp; &nbsp;       
                  <Tooltip title="View Transaction">
                    <Button type="primary" shape="circle" icon={<InfoCircleFilled />} 
                      onClick={()=>{
                        ViewPPTDetails(record);
                      }
                      }
                      style={styles.hide}
                    />
                </Tooltip> 
                &nbsp; &nbsp;       
                <Tooltip title="View Turnover">
                    <Button type="primary" shape="circle" icon={<DollarOutlined />} 
                        onClick={()=>{
                          ViewGameDetails(record);
                        }
                      }
                      style={styles.hide}
                    />
                </Tooltip>             
              </div>
            );

          case "Unlock":
            return (
              <div
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  display: "flex",
                }}
              >
               {
                //role === CS_USER_TYPE.Senior || role === CS_USER_TYPE.CS ?
                  (
                  <>
                  <Button
                  style={{
                    marginRight: 20,
                    backgroundColor: "#0000FF",
                    border: "none",
                    color: "#FFFFFF",
                  }}
                  loading={loadings[2]}
                  onClick={() => {
                    handleUnlock(record, 2);
                  }}
                >
                  Unlock
                </Button>
                <Button
                  style={{
                    border: "none",
                    backgroundColor: "#FF0000",
                    color: "#FFFFFF",
                  }}
                  onClick={() => {
                    handleInactive(record);
                  }}
                >
                  Inactive
                </Button>
                </>)//:(<></>)
                }
                  <Button
                     style={styles.refreshBtn}
                     type={"primary"}
                     onClick={() => {
                       handleMember(record);
                     }}
                   >
                     Edit
                   </Button>   
                   &nbsp; &nbsp; 
                   <Tooltip title="View Transaction">
                     <Button type="primary" shape="circle" icon={<InfoCircleFilled />} 
                      onClick={()=>{
                        ViewPPTDetails(record);
                      }
                      }  
                      style={styles.hide}                    
                    />
                </Tooltip>  
                &nbsp; &nbsp;       
                <Tooltip title="View Turnover">
                    <Button type="primary" shape="circle" icon={<DollarOutlined />} 
                        onClick={()=>{
                          ViewGameDetails(record);
                        }
                      }
                      style={styles.hide}
                    />
                </Tooltip>             
              </div>
            );

          case "Lock":
            return (
              <>
              <Button
                loading={loadings[1]}
                type={"primary"}
                style={{ border: "none" }}
                onClick={() => {
                  handleActive(record, 1);
                }}
              >
                Active
              </Button>  
              <Button
                  style={styles.refreshBtn}
                  type={"primary"}
                  onClick={() => {
                    handleMember(record);
                  }}
                >
                Edit
              </Button> 
              &nbsp; &nbsp;  
              <Tooltip title="View Transaction">
                    <Button type="primary" shape="circle" icon={<InfoCircleFilled />} 
                      onClick={()=>{
                        ViewPPTDetails(record);
                         }
                      }
                      style={styles.hide}
                    />
                </Tooltip> 
                &nbsp; &nbsp;       
                <Tooltip title="View Turnover">
                    <Button type="primary" shape="circle" icon={<DollarOutlined />} 
                        onClick={()=>{
                          ViewGameDetails(record);
                        }
                      }
                      style={styles.hide}
                    />
                </Tooltip>                 
              </>
            );

          default:
            return <div>-</div>;
        }
      },
    },
  ];


  let pptColumns = [
    {
      align: "center",
      title: "Date Time",
      dataIndex:"Dt",
      width: "200px",
      ellipsis: true,
    },
    {
      align: "center",
      title: "Transaction Type",
      dataIndex: "TrxnType",
      width: "200px",
      ellipsis: true,
    },
    {
      ellipsis: true,
      align: "center",
      title: "Amount",
      dataIndex: "Amount",
      width: "150px",
    },    
    {
      ellipsis: true,
      align: "center",
      title: "Commission",
      dataIndex: "Comm",
      width: "150px",
    },
    {
      ellipsis: true,
      align: "center",
      title: "Status",
      dataIndex: "STATUS",
      width: "200px",
      render: (text, record) => {
        //console.log(`STATUS >> ${text} xxx ${record}`);
       // return (text);
        switch (text) {
            case 1:
                return <Badge text="Top up Approved" color={"blue"} />;
            case 3:
              return <Badge status="Withdraw Approved" color={"blue"} />;
            case 9:
              return <Badge status="error" text="Failed" />;
            default:
               return "";

        }
      }
    },
  ];

  let gameColumns = [
    {
      align: "center",
      title: "Ticket Time",
      dataIndex:"TicketTime",
      width: "200px",
      ellipsis: true,
      fixed:"left",
    },
    {
      align: "center",
      title: "Game Code",
      dataIndex: "GameCode",
      width: "200px",
      ellipsis: true,
    },
    {
      ellipsis: true,
      align: "center",
      title: "Bet Amount",
      dataIndex: "BetAmount",
      width: "150px",
      render:function(record) {
        return FormatPt(record);
      }
    },    
    {
      ellipsis: true,
      align: "center",
      title: "Win / Lose Amount",
      dataIndex: "WinLoseAmount",
      width: "150px",
      render:function(record) {
        return FormatPt(record);
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Win Loss",
      dataIndex: "WinLoss",
      width: "150px",
      render:function(record) {
        return FormatPt(record);
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Turnover",
      dataIndex: "Turnover",
      width: "150px",
      render:function(record) {
        return FormatPt(record);
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Status",
      dataIndex: "STATUS",
      width: "200px",
      render: (text, record) => {
        switch (text) {
            case "L":
                return "Lose"
            case "D":
              return "Draw"
            case "W":
              return "Win"
            default:
               return "";

        }
      }
    },
  ];

  const handleUnlock = (record, index) => {
    loadingTrue(index);
    userMemberActive({ userId: record.userId })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          var newData = [...originData];
          newData[record.key].status = "Active";
          newData[record.key].action = "Inactive";
          setOriginData(newData);
          message.success(json.msg);
          loadingFalse(index);
        } else {
          message.error(json.msg);
          loadingTrue(index);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        message.error(
          "An unexpected error occurred. Contact your system administrator "
        );
      });
  };

  const handleActive = (record, index) => {
    loadingTrue(index);
    userMemberActive({ userId: record.userId })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          var newData = [...originData];
          newData[record.key].status = "Active";
          newData[record.key].action = "Inactive";
          setOriginData(newData);
          loadingFalse(index);
          message.success(json.msg);
        } else {
          message.error(json.msg);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        message.error(
          "An unexpected error occurred. Contact your system administrator "
        );
      });
  };

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const handleLock = (record) => {
    setSelected(true);
    setVisible(true);
    setMemberUserId(record.userId);
    setActiveKey(record.key);
    setReason("");
  };

  const handleInactive = (record) => {
    setVisible(true);
    setSelected(false);
    setMemberUserId(record.userId);
    setActiveKey(record.key);
    setReason("");
  };

  const handleReasonOk = (index) => {
    if (reason === "") {
      message.error("Please enter the reason, the field should not be empty");
      return;
    }
    if (selected) {
      loadingTrue(index);
      userMemberLock({
        userId: memberUserId,
        reason: reason,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "ok") {
            var newData = [...originData];
            newData[activeKey].status = "Lock";
            newData[activeKey].action = "Unlock";
            setOriginData(newData);
            setReason("");
            setVisible(false);
            message.success(json.msg);
            loadingFalse(index);
          } else {
            loadingTrue(index);
            message.error(json.msg);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          message.error(
            "An unexpected error occurred. Contact your system administrator "
          );
        });
    } else {
      loadingTrue(index);
      userMemberInactive({ userId: memberUserId, reason: reason })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "ok") {
            var newData = [...originData];
            newData[activeKey].status = "Inactive";
            newData[activeKey].action = "Active";
            setOriginData(newData);
            setVisible(false);
            message.success(json.msg);
            loadingFalse(index);
          } else {
            message.error(json.msg);
            loadingTrue(index);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          message.error(
            "An unexpected error occurred. Contact your system administrator "
          );
        });
    }
  };

  const handleReasonCancel = () => {
    setVisible(false);
  };

  const handleMember =async (record) => {
   
    const response = await userProfileBank({userid:record.userId});
    let result = await response.json();
    if (result.status === "ok") {

      if (Object.keys(result.data).length > 0) {
        let userProfile = result.data;
        setMemberUserId(userProfile.userid);
        setCountryCode(userProfile.mobile.substring(0, 2));
        setPhoneNum(userProfile.mobile.substring(2, record.mobile.length));
        setPin(userProfile.pin);
        setBankCode(userProfile.bankcode);
        setAcctNum(userProfile.acctnum);
        setAcctname(userProfile.acctname);
        setReferCode(userProfile.uplineReferCode);
        setLineId(userProfile.lineid);
        setGender(userProfile.gender);
        setDate(userProfile.dob)
        setReferEditDisable(userProfile.referCodeDisable);
        setPrevBankCode(userProfile.bankcode);
        setPrevAcctNum(userProfile.acctnum);
        setEditMemberModal(true);
      }else {
         message.error(result.msg);
      }
    }
  };

  const selectCountryCode = (value) => {
    setCountryCode(value);
  };

  const addPhoneNumber = () => {
    return csCreateMemberV2({
      mobile: countryCode + phoneNum,
      compid: companyId,
      pin:pin,
      referCode: referCode,
      bankcode : bankcode,
      acctname : acctname,
      acctnum: acctnum,
      lineid : lineId,
      dob : date,
      gender : gender,
      createby : username,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          memberList();
          setAddMemberModal(false);
          setPhoneNum("");
          setPin("");
          setReferCode("")
          setBankCode("")
          setAcctname("")
          setAcctNum("")
          setLineId("")
          setGender("");
          setDate("");
          setPrevBankCode("");
          setPrevAcctNum("");
          message.success(json.msg);
        } else {
          message.error(json.msg);
        };
      });
  };

  const updateMemberInfo = () => {

    return csUpdateMember({
      mobile: countryCode + phoneNum,
      compid: companyId,
      pin:pin,
      referCode: referCode,
      bankcode : bankcode,
      acctname : acctname,
      acctnum: acctnum,
      lineid : lineId,
      dob : date,
      gender : gender,
      updatedby : username,
      userid : memberUserId, 
      prevbankcode : prevbankcode, 
      prevacctnum  : prevAcctnum,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
            memberList();
            clearAllFields(); 
            setEditMemberModal(false);
            message.success(json.msg);
        } else { 
             message.error(json.msg);
        };
      });
  };

  const clearAllFields = () => {
    setAddMemberModal(false);
    setPhoneNum("");
    setPin("");
    setReferCode("")
    setBankCode("")
    setAcctname("")
    setAcctNum("")
    setLineId("")
    setGender("");
    setDate("")
    setPrevBankCode("");
    setPrevAcctNum("");
  }

  const clearBankCode = () => {
     setBankCode("");
  }

  const onCreateMemberForm = () => {
    return (
      <Form form={form} 
            layout="vertical" 
            name="createMemberform"
            initialValues={{ remember:false }}
      >
        <Form.Item
          label="Phone Number"
          required={true}
        >
          <Input.Group compact>
            <Select value={countryCode}
              onChange={selectCountryCode}>
              <Option value="66">+66</Option>
              <Option value="60">+60</Option>
            </Select>
            <Input style={{width:"66%"}} defaultValue="" placeholder="phone number" value={phoneNum}
              onChange={(e) => {
                setPhoneNum(e.target.value.replace(/\D|^0/g, ""));
              }}
              onBlur={(e)=>{
                validateUserPhone(e.target.value.replace(/\D|^0/g, ""))
              }}
            />
          </Input.Group>
        </Form.Item>
        <Form.Item
          id="pin"
          label="PIN"
          required={true}
        >
          <Input
           value={pin}
           style={styles.formItem}
           placeholder="PIN"
           rules={[{required:true, message:'PLease input your PIN'}]}
           maxLength={6}
           onChange={(e) => {
              setPin(e.target.value.replace(/\D|^0/g, ""));
           }}
          />
        </Form.Item>
        <Form.Item
          id="bankCode"
          label="Bank"
        >
          <Select
                style={styles.formItem}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.children.includes(input);
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                value={bankcode}
                onChange={(value, event) => {
                  setBankCode(value);
                  //Search Bank Acc Name
                  for (var i = 0; i < csAvaBankList.length; i++) {
                    if (csAvaBankList[i].bankAcc === value) {
                      setBankCode(csAvaBankList[i].bankCode);
                      form.setFieldsValue({
                        bankcode: csAvaBankList[i].bankCode,
                      });
                    }
                  }
                }}
              >
                {csAvaBankList.map((option) => {
                  return (
                    <Option key={option.bankCode} value={option.bankCode}>
                      {option.bankName} 
                    </Option>
                  );
                })}
              </Select>
              <Button onClick={clearBankCode}>clear</Button>
        </Form.Item>
        <Form.Item
          id="bankAccountNo"
          label="Bank Account Number"
        >
          <Input
            value={acctnum}
            style={styles.formItem}
            placeholder="Bank Account Number"
            onChange={onChangeAcctNum}
          />
        </Form.Item>
        <Form.Item
          id="bankAccountName"
          label="Bank Account Name"
        >
          <Input
            value={acctname}
            style={styles.formItem}
            placeholder="Bank Account Name"
            onChange={(e) => {
                setAcctname(e.target.value);
            }}
            disabled={true}
            />
        </Form.Item>
        <Form.Item
          id="referralCode"
          label="Upline Referral Code"
        >
          <Input
            value={referCode}
            style={styles.formItem}
            placeholder="Upline Referral Code"
            onChange={(e) => {
                setReferCode(e.target.value);
            }}
            />
        </Form.Item>
        <Form.Item
          id="lineId"
          label="Line Id"
        >
          <Input
            value={lineId}
            style={styles.formItem}
            placeholder="Line Id"
            onChange={(e) => {
                setLineId(e.target.value.replace(/\D|^0/g, ""));
            }}
            />
        </Form.Item>
        <Row>
            <Col span={6}>
                <Form.Item
                  id="gender"
                  label="Gender"
                >
                  <Select
                    dropdownStyle={{ position: "fixed" }}
                    value={gender}
                    onChange = {(gender)=> {
                      setGender(gender)
                  }}
                  >
                    <Option value="M">MALE</Option>
                    <Option value="F">FEMALE</Option>
                    <Option value="O">OTHER</Option>
                  </Select>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                id="dob"
                label="Date of Birth"
                  >
                <DatePicker
                  key="1"
                  id="datePicker"
                  style={styles.datePickerStyle}
                  onChange={datePicker}
                  value={ date !== "" ? moment(date) : undefined}
                  disabledDate={(current)=> {
                    return current > moment().endOf('day');
                  }}
                />
              </Form.Item>
            </Col>
        </Row>
       
      </Form>
    );
  };

  const onEditMemberForm = () => {

    return (
      <Form form={form} 
            layout="vertical" 
            name="editMemberform"
      >
        <Form.Item
          label="Phone Number"
          required={true}
        >
          <Input.Group compact>
            <Select value={countryCode}
            onChange={selectCountryCode}>
              <Option value="66">+66</Option>
              <Option value="60">+60</Option>
            </Select>
            <Input style={{width:"66%"}} defaultValue="" placeholder="phone number" value={phoneNum}
              onChange={(e) => {
                setPhoneNum(e.target.value.replace(/\D|^0/g, ""));
              }}
            />
          </Input.Group>
        </Form.Item>
         <Form.Item
          id="pin"
          label="PIN"
          required ={true}
        >
          <Input
           value={pin}
           style={styles.formItem}
           placeholder="PIN"
           maxLength={6}
           rules={[{required:true, message:'Please input your PIN'}]}
           onChange={(e) => {
              setPin(e.target.value.replace(/\D|^0/g, ""));
           }}
          />
        </Form.Item>
        <Form.Item
          id="bankCode"
          label="Bank"
        >
          <Select
                style={styles.formItem}
                showSearch
                value={bankcode}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.children.includes(input);
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value, event) => {
                  setBankCode(value);
                  //Search Bank Acc Name
                  for (var i = 0; i < csAvaBankList.length; i++) {
                    if (csAvaBankList[i].bankAcc === value) {
                      setBankCode(csAvaBankList[i].bankCode);
                      form.setFieldsValue({
                        bankcode: csAvaBankList[i].bankCode,
                      });
                    }
                  }
                }}
              >
                {csAvaBankList.map((option) => {
                  return (
                    <Option key={option.bankCode} value={option.bankCode}>
                      {option.bankName} 
                    </Option>
                  );
                })}
              </Select>
              <Button onClick={clearBankCode}>clear</Button>
        </Form.Item>
        <Form.Item
          id="bankAccountNo"
          label="Bank Account Number"
        >
          <Input
            value={acctnum}
            style={styles.formItem}
            placeholder="Bank Account Number"
            onChange={onChangeAcctNum}
          />
        </Form.Item>
        <Form.Item
          id="bankAccountName"
          label="Bank Account Name"
        >
          <Input
            value={acctname}
            style={styles.formItem}
            placeholder="Bank Account Name"
            onChange={(e) => {
                setAcctname(e.target.value);
            }}
            disabled={true}
            />
        </Form.Item>
        <Form.Item
          id="referralCode"
          label="Upline Referral Code"
        >
          <Input
            value={referCode}
            style={styles.formItem}
            placeholder="Upline Referral Code"
            onChange={(e) => {
              setReferCode(e.target.value);
            }}
            disabled={referEditDisable}
            />
        </Form.Item>
        <Form.Item
          id="lineId"
          label="Line Id"
        >
          <Input
            value={lineId}
            style={styles.formItem}
            placeholder="Line Id"
            onChange={(e) => {
                setLineId(e.target.value.replace(/\D|^0/g, ""));
            }}
            />
        </Form.Item>
        <Row>
            <Col span={6}>
                <Form.Item
                  id="gender"
                  label="Gender"     
                  >
                  <Select
                    dropdownStyle={{ position: "fixed" }}
                    value={gender}
                    onChange = {(gender)=> {
                        setGender(gender)
                    }}
                  >
                    <Option value="M">MALE</Option>
                    <Option value="F">FEMALE</Option>
                    <Option value="O">OTHER</Option>
                  </Select>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                id="dob"
                label="Date of Birth"
                pre
                  >
                <DatePicker
                  key="1"
                  id="datePicker"
                  style={styles.datePickerStyle}
                  onChange={datePicker}
                  value={ date !== "" ? moment(date) : undefined}
                  disabledDate={(current)=> {
                    return current > moment().endOf('day');
                  }}
                />
              </Form.Item>
            </Col>
        </Row> 
       
      </Form>
    );
  };

  const styles = {
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
    },
    refreshBtn: {
      marginLeft: 10,
    },
    tabelContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    formItem: {
      width:"80%"
    },
    datePickerStyle: {
      width: 260,
    },
    hide : {
      display:"none",
    }
  };
  return (
    <div style={styles.hide}>
      <PageHeader ghost={false}>
        <div style={styles.pageHeaderContainer}>
          <Button
            style={styles.refreshBtn}
            type="primary"
            onClick={() => {
              memberList();
            }}
          >
            Refresh Page
          </Button>
          <Button
            style={styles.refreshBtn}
            type="primary"
            onClick={() => {
              setAddMemberModal(true);
              setEditMode(false);
            }}
          >
            Add Member
          </Button>
        </div>
      </PageHeader>
      <div style={styles.tabelContainer}>
        <Table
          columns={columns}
          dataSource={originData}
          loading={tableLoading}
          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: settings.pageSizeOptions,
            style: styles.paginationStyle,
            alignmentBottom: 'right',
          }}
        />
      </div>

      <Modal
        title={selected ? "Lock Reason" : "Inactive Reason"}
        visible={visible}
        onOk={() => {
          handleReasonOk(0);
        }}
        onCancel={handleReasonCancel}
        okText={selected ? "Lock" : "Inactive"}
        maskClosable={false}
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "danger", loading: loadings[0] }}
      >
        <TextArea
          rows={4}
          onChange={(e) => setReason(e.target.value)}
          value={reason}
        />
      </Modal>

      <Modal
        title={"Add Member"}
        visible={addMemberModal}
        onOk={addPhoneNumber}
        onCancel={() => {
          setAddMemberModal(false);
          clearAllFields();
        }}
        maskClosable={false}
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "danger", loading: loadings[0]  ,disabled: createMemberDisable }}
      >
        {onCreateMemberForm()}
      </Modal>
      
      <Modal 
        title={"Edit Member"}
        visible={editMemberModal}
        onOk={updateMemberInfo}
        onCancel= {() => {
          setEditMemberModal(false);
          clearAllFields();
        }}
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "danger", loading: loadings[0]  , disabled: editMemberDisable }}    
      >
        {onEditMemberForm()}
      </Modal>
      <Modal 
        title={viewTrx=="trxn" ? "Transactions Details" : "Turnover Details"}
        visible={puppetVisible}
        style={{ top : "35%" }}
        onCancel={()=>{
            setPuppetVisible(false);
            //onCancelApproveTrxn();
        }}
        width={1200}
        footer={[
          <Button key="back"
          align="left"
          //onClick={onCancelApproveTrxn}
          >
            {editMode? "Cancel": "Close"}
          </Button>
        ]}
      >
       <Row style={styles.refreshBtn}>
          {
            viewTrx =="trxn" ? 
            (<><b>Member Wallet As of :</b> {csMemberWalletDt} &nbsp;&nbsp;<b>Balance :</b> {csMemberWalletBal}&nbsp;&nbsp;<b>Comm:</b>{csMemberWalletComm}</>):
            (<><b>Game User :</b> {csGameUser} &nbsp;&nbsp;<b>Bet Amount :</b> {csGameBetAmt}&nbsp;&nbsp;<b>Win/Loss :</b>{csGameWinloss}&nbsp;&nbsp;<b>Turnover :</b>{csGameTurnover}  </>)
          }            
       </Row>
       <div style={styles.tabelContainer}>
          <Table
            loading={tableLoading}
            columns={viewTrx=="trxn" ? pptColumns : gameColumns}
            dataSource= {filteredPptTrxn }
            scroll={{ x: isMobile ? "110%" : "100%", y: null }}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: settings.pageSizeOptions,
              style: styles.paginationStyle,
              alignmentBottom: 'right',
            }}
          />
        </div>
      </Modal>
      {/* <Modal
        title={"Add Member"}
        visible={addMemberModal}
        onOk={addPhoneNumber}
        onCancel={() => {
          setAddMemberModal(false);
          setPhoneNum("");
        }}
        maskClosable={false}
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "danger", loading: loadings[0] }}
      >
        <Input.Group
          compact
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select defaultValue={`+${countryCode}`} onChange={selectCountryCode}>
            <Option value={"66"}>+66</Option>
            <Option value={"60"}>+60</Option>
          </Select>
          <Input
            value={phoneNum}
            style={{ width: "50%" }}
            placeholder="Phone number"
            onChange={(e) => {
              setPhoneNum(e.target.value.replace(/\D|^0/g, ""));
            }}
          />
        </Input.Group>
      </Modal> */}
    </div>
  );
}
