import React from "react";

export const CMemberSummary = (props) =>{
    switch (props.type) {

        case "gameuser":
            if (props.version ==="1"){
              return (
                  <div><b>Game User :</b> {props.gameuser} &nbsp;&nbsp;<b>Bet Amount :</b> {props.betAmount}&nbsp;&nbsp;<b>Win/Loss :</b>{props.winloss}&nbsp;&nbsp;<b>Turnover :</b>{props.turnover} </div>
              )
            }

            if (props.version ==="2"){
              return (
                <div>
                  <div><b>Game User :</b> {props.gameuser} &nbsp;&nbsp;<b>*Bet Amount :</b> {props.betAmount}&nbsp;&nbsp;<b>*Win/Loss :</b>{props.winloss}&nbsp;&nbsp;<b>*Turnover :</b>{props.turnover}</div>
                  <div><br/>*based on unarchived transactions</div>
                </div>
              )
            }
            break;

        default:
            break;
    }
}