const PROCESS_TYPE = {
  APP: 'A',
  MANUAL: 'M',
};

const TRANSACTION_TYPE = {
  DEP : 'Topup',
  WIT : 'Withdraw',
  TRANSFER_IN :'Transfer-In',
  TRANSFER_OUT : 'Transfer-Out',
}

export { PROCESS_TYPE, TRANSACTION_TYPE};