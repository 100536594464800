import React, { useEffect, useState , useRef } from "react";
import { Form, Button, Switch, Input, Row, Col, Table, Descriptions, Card, Divider, Tooltip, Dropdown, Select} from "antd";
import { useSelector } from "react-redux";
import { getFwSetting, updateFwSetting } from "../../config/networkConfig";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert } from "../../common/constants/sharedMsgBox";
import { mtnMgmtSwt } from "../../library/permission";
import {InfoCircleTwoTone, InfoCircleFilled } from "@ant-design/icons";

export default function FortuneWheel() {
    const [form] = Form.useForm();
    const {issuper, role, compCode } = useSelector((state) => state.login);
    const [enabledStatus, setEnabledStatus]                =   useState(false);

    const [loading, setLoading]                            =   useState(false);
    const [dailyBudget, setDailyBudget]                    =   useState(0);
    const [oldDailyBudget, setOldDailyBudget]              =   useState(0);

    const [extraBudget, setExtraBudget]                    =   useState(0);
    const [oldExtraBudget, setOldExtraBudget]              =   useState(0);

    const [staticBudget, setStaticBudget]                  =   useState(0);
    const [oldStaticBudget, setOldStaticBudget]            =   useState(0);

    const [turnoverReq, setTurnoverReq]                    =   useState(0);
    const [oldTurnoverReq, setOldTurnoverReq]              =   useState(0);

    const [minDepositAmt, setMinDepositAmt]                =   useState(0);
    const [oldMinDepositAmt, setOldMinDepositAmt]          =   useState(0);

    const [highTierCount, setHighTierCount]                =   useState(0);
    const [oldHighTierCount, setOldHighTierCount]          =   useState(0);

    const [lowTierRange, setLowTierRange]                  =   useState("");
    const [oldLowTierRange, setOldLowTierRange]            =   useState("");

    const [penaltyStatus, setPenaltyStatus]                =   useState(false);
    const [oldPenaltyStatus, setOldPenaltyStatus]          =   useState(false);

    const [penaltyTierRange, setPenaltyTierRange]          =   useState("");
    const [oldPenaltyTierRange, setOldPenaltyTierRange]    =   useState("");

    const [lowOne, setLowOne]                              =   useState(0);
    const [lowTwo, setLowTwo]                              =   useState(0);
    const [lowThree, setLowThree]                          =   useState(0);
    const [lowFour, setLowFour]                            =   useState(0);
    const [lowFive, setLowFive]                            =   useState(0);
    const [lowSix, setLowSix]                              =   useState(0);

    const [penaltyOne, setPenaltyOne]                      =   useState(0);
    const [penaltyTwo, setPenaltyTwo]                      =   useState(0);
    const [penaltyThree, setPenaltyThree]                  =   useState(0);

    const [totalLTR, setTotalLTR]                           =   useState(0);
    const [totalPTR, setTotalPTR]                           =   useState(0);
    
    
    useEffect(() => {
        getFortWhlSttg();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setTotalLTR(lowOne+lowTwo+lowThree+lowFour+lowFive+lowSix)
    }, [lowOne, lowTwo, lowThree, lowFour, lowFive, lowSix])

    useEffect(() => {
        setTotalPTR(penaltyOne+penaltyTwo+penaltyThree)
    }, [penaltyOne, penaltyTwo, penaltyThree])
    
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 12,
            },
            sm: {
                span: 12,
          },
        },
        wrapperCol: {
          xs: {
            span: 12,
          },
          sm: {
            span: 10,
          },
        },
    };

    const styles = {
        actionBtn: {
            height:"30px",
            display : issuper? "block":"none",
        },
        submitBtn: {
            marginBottom:"20px",
        },
        formItem: {
            width:"80%"
        },
        on : {
            color:"green",
        },
        off : {
            color :"red"
        }
    };

    let convertToBool = (value) => {
        if (value == null || value == '') {
          return false;
        }
        else {
           if (value.toString().toUpperCase() == '1') {
              return true;
           }
           else {
             return false;
           }
        }
    }

    let boolToInt = (value) => {
        if (value == true) {
          return 1;
        }
        else {
           return 0;
        }
    }

    const splitLowRange = (numString) => {
        let lowArr = numString.split(',');
        setLowOne(lowArr[0]/10);
        setLowTwo(lowArr[1]/10);
        setLowThree(lowArr[2]/10);
        setLowFour(lowArr[3]/10);
        setLowFive(lowArr[4]/10);
        setLowSix(lowArr[5]/10);
    }

    const splitPenaltyRange = (numString) => {
        let penArr = numString.split(',');
        setPenaltyOne(penArr[0] / 10);
        setPenaltyTwo(penArr[1] / 10);
        setPenaltyThree(penArr[2] / 10);
    }

    const getFortWhlSttg = async () => {
        setLoading(true);

        let requestData = {
            columnRequire : [
                'Status as enabledStatus',
                'DailyBudget as dailyBudget',
                'ExtraBudget as extraBudget',
                'StaticBudget as staticBudget',
                'TurnoverReq as turnoverReq',
                'MinDeptAmt as minDepositAmt',
                'HighTierCount as highTierCount',
                'LowTierOccur as lowTierRange',
                'PenaltyTierAllow as penaltyStatus',
                'PenaltyTierOccur as penaltyTierRange',
            ]
        }

        let response = await getFwSetting(requestData);
        let result = await response.json();

        if(result.status === "ok") {
            const data = result.data;
            setEnabledStatus(convertToBool(data[0].enabledStatus));

            setDailyBudget(data[0].dailyBudget);
            setOldDailyBudget(data[0].dailyBudget);
            
            setExtraBudget(data[0].extraBudget);
            setOldExtraBudget(data[0].extraBudget);
            
            setStaticBudget(data[0].staticBudget);
            setOldStaticBudget(data[0].staticBudget);

            setTurnoverReq(data[0].turnoverReq);
            setOldTurnoverReq(data[0].turnoverReq);

            setMinDepositAmt(data[0].minDepositAmt);
            setOldMinDepositAmt(data[0].minDepositAmt);

            setHighTierCount(data[0].highTierCount);
            setOldHighTierCount(data[0].highTierCount);

            setLowTierRange(data[0].lowTierRange);
            setOldLowTierRange(data[0].lowTierRange);

            setPenaltyStatus(convertToBool(data[0].penaltyStatus));
            setOldPenaltyStatus(convertToBool(data[0].penaltyStatus));

            setPenaltyTierRange(data[0].penaltyTierRange);
            setOldPenaltyTierRange(data[0].penaltyTierRange);

            splitLowRange(data[0].lowTierRange);
            splitPenaltyRange(data[0].penaltyTierRange);

            setLoading(false);
        }
        else {
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
            setLoading(false);
        }
    }

    const updateFortWhlSttg = async () => {
        setLoading(true);

        if(dailyBudget === null || dailyBudget === undefined || dailyBudget === "") {
            alertData.title = "Field Required !";
            alertData.msg = "Please fill in Daily Budget.";
            sharedErrorAlert(alertData);
            setLoading(false);
            return
        }

        if(dailyBudget > 100){
            alertData.title = "Validation Error !";
            alertData.msg = "Daily budget must be within 100%.";
            sharedErrorAlert(alertData);
            setLoading(false);
            return
        }

        if(extraBudget === null || extraBudget === undefined || extraBudget === "") {
            alertData.title = "Field Required !";
            alertData.msg = "Please fill in Exhausted Wheel.";
            sharedErrorAlert(alertData);
            setLoading(false);
            return
        }

        if(extraBudget > 100){
            alertData.title = "Validation Error !";
            alertData.msg = "Exhausted Wheel must be within 100%.";
            sharedErrorAlert(alertData);
            setLoading(false);
            return
        }

        if(staticBudget === null || staticBudget === undefined || staticBudget === "") {
            alertData.title = "Field Required !";
            alertData.msg = "Please fill in Static Budget. Min. value is 0.";
            sharedErrorAlert(alertData);
            setLoading(false);
            return
        }

        if(turnoverReq === null || turnoverReq === undefined || turnoverReq === "") {
            alertData.title = "Field Required !";
            alertData.msg = "Please fill in Turnover required. Min. value is 0.";
            sharedErrorAlert(alertData);
            setLoading(false);
            return
        }

        if(minDepositAmt === null || minDepositAmt === undefined || minDepositAmt === "") {
            alertData.title = "Field Required !";
            alertData.msg = "Please fill in Minimum Deposit Amount. Min. value is 0.";
            sharedErrorAlert(alertData);
            setLoading(false);
            return
        }

        if(highTierCount === null || highTierCount === undefined || highTierCount === "" || highTierCount === 0) {
            alertData.title = "Field Required !";
            alertData.msg = "Please fill in High Tier Count. Min. value is 1.";
            sharedErrorAlert(alertData);
            setLoading(false);
            return
        }

        let lowTierArr = [].concat(parseInt(lowOne * 10), parseInt(lowTwo * 10), parseInt(lowThree * 10), parseInt(lowFour * 10), parseInt(lowFive * 10), parseInt(lowSix * 10));
        let lowTierRange = lowTierArr.join(",");
        let totalLowRange = lowTierArr.reduce((a,b) => a + b, 0);

        let penaltyTierArr = [].concat(parseInt(penaltyOne * 10), parseInt(penaltyTwo * 10), parseInt(penaltyThree * 10));
        let penaltyTierRange = penaltyTierArr.join(",");
        let totalPenRange = penaltyTierArr.reduce((a,b) => a + b, 0);

        if(totalLowRange != 1000){
            alertData.title = "Validation Error !";
            alertData.msg = "The total for Low Tier Range must be 100.";
            sharedErrorAlert(alertData);
            setLoading(false);
            return
        } 

        if(totalPenRange != 1000){
            alertData.title = "Validation Error !";
            alertData.msg = "The total for Penalty Tier Range must be 100.";
            sharedErrorAlert(alertData);
            setLoading(false);
            return
        }

        let requestData = {
            dailyBudget : dailyBudget,
            enabledStatus : boolToInt(enabledStatus),
            extraBudget : extraBudget,
            staticBudget : staticBudget,
            turnoverReq : turnoverReq,
            minDepositAmt : minDepositAmt,
            penaltyStatus : boolToInt(penaltyStatus),
            highTierCount : highTierCount,
            lowTierRange : lowTierRange,
            penaltyTierRange : penaltyTierRange
        }

        if(!enabledStatus){
            requestData.dailyBudget = oldDailyBudget;
            requestData.extraBudget = oldExtraBudget;
            requestData.staticBudget = oldStaticBudget;
            requestData.turnoverReq = oldTurnoverReq;
            requestData.minDepositAmt = oldMinDepositAmt;
            requestData.penaltyStatus = boolToInt(oldPenaltyStatus);
            requestData.highTierCount = oldHighTierCount;
            requestData.lowTierRange = oldLowTierRange;
            requestData.penaltyTierRange = oldPenaltyTierRange;
        }
        
        if(!penaltyStatus){
            requestData.penaltyTierRange = oldPenaltyTierRange;
        }
        
        return updateFwSetting(requestData)
            .then((response) => response.json())
            .then((json) => {
                if(json.status === 'ok'){
                    setLoading(false);
                    alertData.title = "Success";
                    alertData.msg = json.msg;
                    sharedSuccessAlert(alertData);
                    getFortWhlSttg();
                }
                else {
                    setLoading(false);
                    alertData.title = "Failed";
                    alertData.msg = json.msg;
                    sharedFailAlert(alertData);
                }
            })
    }


    const turnoverStages = [
        {value: 1, label: 'x 1'},
        {value: 2, label: 'x 2'},
        {value: 3, label: 'x 3'},
        {value: 4, label: 'x 4'},
        {value: 5, label: 'x 5'},
        {value: 6, label: 'x 6'},
        {value: 7, label: 'x 7'},
        {value: 8, label: 'x 8'},
        {value: 9, label: 'x 9'},
        {value: 10, label: 'x 10'},
    ]

    const exhaustedStages = [
        {value: 1, label: '1'},
        {value: 2, label: '2'},
        {value: 3, label: '3'},
        {value: 5, label: '5'},
        {value: 7, label: '7'},
        {value: 10, label: '10'},
    ]

    const genStgCols = [
        {
            title: 'General settings',
            dataIndex: 'setting',
            width: '30%',
            key: 'setting'
        },
        {
            title: 'Values',
            width: '20%',
            dataIndex: 'input',
            key: 'input'
        },
        {
            title: 'Description',
            dataIndex: 'desc',
            key: 'desc'
        },
    ]

    const genStgData = [
        {
            key: '1',
            setting: 'Daily Budget (%)',
            input: (
                <>
                    {
                        !mtnMgmtSwt(role, compCode) ?
                        <>
                            <Input 
                                style={{width: "50%"}} 
                                value={dailyBudget} 
                                disabled={true}
                            />
                        </>
                        :
                        <Input
                            style={{width: "50%", border: dailyBudget > 100 || dailyBudget === '' ? '1px solid red' : ''}}
                            value={dailyBudget}
                            onChange={(e) => {
                                setDailyBudget(e.target.value.replace(/\D/g, ""));
                            }}
                            disabled={!enabledStatus}
                        />
                    }
                </>
            ),
            desc: (
                <>
                    This is the percentage of the previous day's total deposit amount.
                    <br />
                    [Fortune Wheel Total Daily Budget = Previous day total deposit * Daily Budget(%)]
                    <br />
                    <p style={{color:'blue'}}>
                        Eg. [Previous day total deposit = 100,000, Daily Budget(%) = {dailyBudget}%]
                        <br />
                        Fortune Wheel Total Daily Budget = 100,000 * {dailyBudget}% = {100000 * dailyBudget / 100}.
                    </p> 
                </>
            ),
        },
        {
            key: '2',
            setting: 'Exhausted Wheel (%)',
            input: (
                <>
                    {
                        !mtnMgmtSwt(role, compCode) ?
                        <>
                            <Input
                                style={{width: "50%"}}
                                value={extraBudget}
                                disabled={true}
                            />
                        </>
                        :
                        <Select 
                            value={extraBudget} 
                            style={{width:"50%"}} 
                            onChange={(e) => {
                                setExtraBudget(e)
                            }}
                            options={exhaustedStages}
                            disabled={!enabledStatus}
                        />
                    }
                </>
            ),
            desc: (
                <>
                    When Fortune Wheel Daily Budget is depleted, player will get fortune wheel but with a fixed percentage.
                    <br />
                    [FW Bonus Received = User Deposit Amount * Exhausted wheel(%)]
                    <br/>
                    <p style={{color:'blue'}}>
                        Eg. [User Deposit Amount = 100, Exhausted wheel = {extraBudget}%]
                        <br />
                        FW Bonus Received = 100 * {extraBudget}% = {100 * extraBudget / 100}.
                    </p>
                </>
            ),
        },
        {
            key: '3',
            setting: 'Static Budget',
            input: (
                <>
                    {
                        !mtnMgmtSwt(role, compCode) ?
                        <>
                            <Input
                                style={{width: "50%"}}
                                value={staticBudget}
                                disabled={true}
                            />
                        </>
                        :
                        <Input
                            style={{width: "50%", border: staticBudget === '' ? '1px solid red' : ''}}
                            value={staticBudget}
                            min={0}
                            onChange={(e) => {
                                setStaticBudget(e.target.value.replace(/\D/g, ""));
                            }}
                            disabled={!enabledStatus}
                        />
                    }
                </>
            ),
            desc: (
                <>
                    This is the fixed budget amount for Fortune Wheel Daily budget.
                    <br/>
                    {
                        staticBudget > 0 ?
                        <p style={{color:'blue'}}>
                        Eg. [Static Budget = {staticBudget}]
                        <br />
                        Fortune Wheel Total Daily budget will be set to {staticBudget}.
                    </p>
                        :
                        <></>
                    }
                    
                </>
            ),
        },
        {
            key: '4',
            setting: 'Minimum Deposit Amount',
            input: (
                <>
                    {
                        !mtnMgmtSwt(role, compCode) ?
                        <>
                            <Input
                                style={{width: "50%"}}
                                value={minDepositAmt}
                                disabled={true}
                            />
                        </>
                        :
                        <Input
                            style={{width: "50%", border: minDepositAmt === '' ? '1px solid red' : ''}}
                            value={minDepositAmt}
                            min={0}
                            onChange={(e) => {
                                setMinDepositAmt(e.target.value.replace(/\D/g, ""));
                            }}
                            disabled={!enabledStatus}
                        />
                    }
                </>
            ),
            desc: (
                <>
                    This is the minimum deposit amount for player to get fortune wheel.
                    <br/>
                    <p style={{color:'blue'}}>
                        Eg. [Minimum Deposit Amount = {minDepositAmt}]
                        <br />
                        If player deposit {minDepositAmt}, they are entitled for fortune wheel.
                    </p>
                </>
            ),
        },
        {
            key: '5',
            setting: 'Turnover Multiplier',
            input: (
                <>
                    {
                        !mtnMgmtSwt(role, compCode) ?
                        <>
                            <Input
                                style={{width: "50%"}}
                                value={turnoverReq}
                                disabled={true}
                            />
                        </>
                        :
                        <>
                            <Select 
                                value={turnoverReq} 
                                style={{width:"50%"}} 
                                onChange={(e) => {
                                    setTurnoverReq(e)
                                }}
                                options={turnoverStages}
                                disabled={!enabledStatus}
                            />
                        </>
                    }
                </>
            ),
            desc: (
                <>
                    This is the required turnover multiplier of the player's deposit.
                    <br />
                    [Turnover Required = (Player deposit + Fortune Wheel Bonus) * Turnover Multiplier]
                    <br />
                    <p style={{color:'blue'}}>
                        Eg. [Player deposit = 100, FW Bonus = 10, Turnover Multiplier = x {turnoverReq}]
                        <br />
                        Turnover Required = (100 + 10) * {turnoverReq} = {110 * turnoverReq}.
                    </p>
                </>
            ),
        }
    ]

    const highTierCols = [
        {
            title: 'Bonus',
            dataIndex: 'bonus',
            width: '30%',
            key: 'bonus'
        },
        {
            title: (<>
                High Tier Count 
                <Tooltip style={{width: '120px'}} placement="top" title="This is the frequency for players to get high tier bonus.">
                            <span style={{marginLeft: '1%', cursor:'pointer'}}><InfoCircleFilled  /></span>
                </Tooltip>
            </>),
            width: '20%',
            dataIndex: 'htBonusPct',
            key: 'htBonusPct'
        },
        {
            title: 'Description',
            dataIndex: 'desc',
            key: 'desc'
        },
    ]

    const highTierData = [
        {
            key: '1',
            bonus: '100% / 50% / 20% / 10%',
            htBonusPct: (
                <>
                    {
                        !mtnMgmtSwt(role, compCode) ?
                        <>
                            <Input
                                value={highTierCount}
                                disabled={true}
                            />
                        </>
                        :
                        <>
                            <Input
                                style={{
                                    width:'50%',
                                    border: highTierCount === '' ? '1px solid red' : ''
                                }}
                                value={highTierCount}
                                onChange={(e) => {
                                    let value = e.target.value.replace(/\D/g, "");
                                    setHighTierCount(value);
                                }}
                                disabled={!enabledStatus}
                            />
                        </>
                    }
                </>
            ),
            desc: (
                <>
                    For every <span style={{color: 'blue'}}>{highTierCount}th</span> deposit, the player will be entitled for the highest percentage of high tier bonus that is within the fortune wheel daily budget constraint. [Refer to example below]
                    <br/>
                    <p style={{color:'blue'}}>
                        Eg. [High Tier Count = {highTierCount}] <br />
                        {
                            highTierCount <= 1 ? 
                            <>
                                -The player that made the {highTierCount}st deposit will be entitled for high tier bonus. <br/>
                            </> 
                            :
                            <>
                                -The player that made the {highTierCount}th deposit will be entitled for high tier bonus. <br/>
                                -The other players that made the 1st - {highTierCount - 1}th deposit will be entitled for {penaltyStatus ? <span>low tier / penalty tier bonus.</span> : <span>low tier bonus.</span> }
                            </>
                        }
                    </p>
                </>
            ),
        }
    ]

    const lowTierCols = [
        {
            title: 'Level',
            dataIndex: 'level',
            key: 'level',
            width: '10%'
        },
        {
            title: 'Bonus',
            dataIndex: 'bonus',
            key: 'bonus',
            width: '20%'
        },
        {
            title: (<>
                Low Tier Range (%)
                <Tooltip style={{width: '120px'}} placement="top" title="This is the percentage for the player to get the low tier bonus.">
                            <span style={{marginLeft: '1%', cursor:'pointer'}}><InfoCircleFilled  /></span>
                </Tooltip>
            </>),
            dataIndex: 'ltBonusPct',
            key: 'ltBonusPct',
            width: '20%'
        },
        {
            title: 'Description',
            dataIndex: 'desc',
            key: 'desc'
        },
    ]

    const lowTierData = [
        {
            key: '1',
            level: '1',
            bonus: '1%',
            ltBonusPct: (
                <>
                {
                    !mtnMgmtSwt(role, compCode) ?
                        <Input
                            value={lowOne}
                            disabled={true}
                        />
                    :
                        <Input
                            style={{
                                width:'50%',
                            }}
                            value={lowOne}
                            min={0}
                            max={1000}
                            onChange={(e) => {
                                let value = e.target.value.replace(/\D/g, "");
                                if(value === ''){
                                    value = 0;
                                }
                                value = Math.min(parseInt(value), 100);
                                if(value.toString().length > 3){
                                    value = value.toString().slice(0,3);
                                }
                                setLowOne(value);
                            }}
                            disabled={!enabledStatus}
                        />
                }
                </>
            ),
            desc: (
                <>
                    Player will have <span style={{color: lowOne === 0 || lowOne === 100 ? 'red' : 'blue'}}>{lowOne}%</span> chance to get 1% low tier bonus.
                </>
            ),
        },
        {
            key: '2',
            level: '2',
            bonus: '2%',
            ltBonusPct: (
                <>
                {
                    !mtnMgmtSwt(role, compCode) ?
                        <Input
                            value={lowTwo}
                            disabled={true}
                        />
                        :
                        <Input
                            style={{
                                width:'50%',
                            }}
                            value={lowTwo}
                            min={0}
                            max={100}
                            onChange={(e) => {
                                let value = e.target.value.replace(/\D/g, "");
                                if(value === ''){
                                    value = 0;
                                }
                                value = Math.min(parseInt(value), 100);
                                if(value.toString().length > 3){
                                    value = value.toString().slice(0,3);
                                }
                                setLowTwo(value);
                            }}
                            disabled={!enabledStatus}
                        />
                }
                </>
            ),
            desc: (
                <>
                    Player will have <span style={{color: lowTwo === 0 || lowTwo === 100 ? 'red' : 'blue'}}>{lowTwo}%</span> chance to get 2% low tier bonus.
                </>
            ),
        },
        {
            key: '3',
            level: '3',
            bonus: '3%',
            ltBonusPct: (
                <>
                {
                    !mtnMgmtSwt(role, compCode) ?
                        <Input
                            value={lowThree}
                            disabled={true}
                        />
                        :
                        <Input
                            style={{
                                width:'50%',
                            }}
                            value={lowThree}
                            min={0}
                            max={100}
                            onChange={(e) => {
                                let value = e.target.value.replace(/\D/g, "");
                                if(value === ''){
                                    value = 0;
                                }
                                value = Math.min(parseInt(value), 100);
                                if(value.toString().length > 3){
                                    value = value.toString().slice(0,3);
                                }
                                setLowThree(value);
                            }}
                            disabled={!enabledStatus}
                        />
                }
                </>
            ),
            desc: (
                <>
                    Player will have <span style={{color: lowThree === 0 || lowThree === 100 ? 'red' : 'blue'}}>{lowThree}%</span> chance to get 3% low tier bonus.
                </>
            ),
        },
        {
            key: '4',
            level: '4',
            bonus: '5%',
            ltBonusPct: (
                <>
                {
                    !mtnMgmtSwt(role, compCode) ?
                        <Input
                            value={lowFour}
                            disabled={true}
                        />
                        :
                        <Input
                            style={{
                                width:'50%',
                            }}
                            value={lowFour}
                            min={0}
                            max={100}
                            onChange={(e) => {
                                let value = e.target.value.replace(/\D/g, "");
                                if(value === ''){
                                    value = 0;
                                }
                                value = Math.min(parseInt(value), 100);
                                if(value.toString().length > 3){
                                    value = value.toString().slice(0,3);
                                }
                                setLowFour(value);
                            }}
                            disabled={!enabledStatus}
                        />
                }
                </>
            ),
            desc: (
                <>
                    Player will have <span style={{color: lowFour === 0 || lowFour === 100 ? 'red' : 'blue'}}>{lowFour}%</span> chance to get 5% low tier bonus.
                </>
            ),
        },
        {
            key: '5',
            level: '5',
            bonus: '7%',
            ltBonusPct: (
                <>
                {
                    !mtnMgmtSwt(role, compCode) ?
                    <Input
                        value={lowFive}
                        disabled={true}
                    />
                    :
                    <Input
                        style={{
                            width:'50%',
                        }}
                        value={lowFive}
                        min={0}
                        max={100}
                        onChange={(e) => {
                            let value = e.target.value.replace(/\D/g, "");
                            if(value === ''){
                                value = 0;
                            }
                            value = Math.min(parseInt(value), 100);
                            if(value.toString().length > 3){
                                value = value.toString().slice(0,3);
                            }
                            setLowFive(value);
                        }}
                        disabled={!enabledStatus}
                    />
                }
                </>
            ),
            desc: (
                <>
                    Player will have <span style={{color: lowFive === 0 || lowFive === 100 ? 'red' : 'blue'}}>{lowFive}%</span> chance to get 7% low tier bonus.
                </>
            ),
        },
        {
            key: '6',
            level: '6',
            bonus: '10%',
            ltBonusPct: (
                <>
                {
                    !mtnMgmtSwt(role, compCode) ?
                        <Input
                            value={lowSix}
                            disabled={true}
                        />
                        :
                        <Input
                            style={{
                                width:'50%', 
                            }}
                            value={lowSix}
                            min={0}
                            max={100}
                            onChange={(e) => {
                                let value = e.target.value.replace(/\D/g, "");
                                if(value === ''){
                                    value = 0;
                                }
                                value = Math.min(parseInt(value), 100);
                                if(value.toString().length > 3){
                                    value = value.toString().slice(0,3);
                                }
                                setLowSix(value);
                            }}
                            disabled={!enabledStatus}
                        />
                }
                </>
            ),
            desc: (
                <>
                    Player will have <span style={{color: lowSix === 0 || lowSix === 100 ? 'red' : 'blue'}}>{lowSix}%</span> chance to get 10% low tier bonus.
                </>
            ),
        },
        {
            key: '7',
            level: '',
            bonus: (<p>Total Low Tier Range:</p>),
            ltBonusPct: (
                <p style={{color: totalLTR === 100 ? 'green' : 'red' }}>
                            {totalLTR}%
                </p>
            ),
            desc: (
                <>
                    {
                        totalLTR > 100 ? 
                        <p style={{color:'red', alignItems:'center', textAlign:'center'}}>
                            ERROR : Total Low Tier Range total had exceeded 100%.
                            <br/>
                            There is extra <span style={{color:'red'}}>{totalLTR - 100}%</span>!
                        </p>
                        : 
                        totalLTR < 100 ?
                        <p style={{color:'red'}}>
                            ERROR : Total Low Tier Range total must be 100%.
                            <br/>
                            There is remaining <span style={{color:'red'}}>{100 - totalLTR}%</span>!
                        </p>
                        :
                        <p style={{color:'green'}}>Total Low Tier Range is 100%, ready for submission.</p>
                        
                    }
                </>
            ),
        },
    ]

    const penaltyCols = [
        {
            title: 'Level',
            dataIndex: 'level',
            key: 'level',
            width: '10%'
        },
        {
            title: 'Bonus',
            dataIndex: 'bonus',
            key: 'bonus',
            width: '20%'
        },
        {
            title: (<>
                Penalty Tier Range (%)
                <Tooltip placement="top" title="This is the percentage for the player to get the penalty tier.">
                            <span style={{marginLeft: '1%', cursor:'pointer'}}><InfoCircleFilled  /></span>
                </Tooltip>
            </>),
            dataIndex: 'penBonusPct',
            key: 'penBonusPct',
            width: '20%'
        },
        {
            title: 'Description',
            dataIndex: 'desc',
            key: 'desc'
        },
    ]

    const penaltyData = [
        {
            key: '1',
            level: '1',
            bonus: '0%',
            penBonusPct: (
                <>
                {
                    !mtnMgmtSwt(role, compCode) ?
                        <Input
                            value={penaltyOne}
                            disabled={true}
                        />
                        :
                        <Input
                            style={{width:'50%'}}
                            value={penaltyOne}
                            min={0}
                            max={1000}
                            onChange={(e) => {
                                let value = e.target.value.replace(/\D/g, "");
                                if(value === ''){
                                    value = 0;
                                }
                                value = Math.min(parseInt(value), 100);
                                if(value.toString().length > 3){
                                    value = value.toString().slice(0,3);
                                }
                                setPenaltyOne(value);
                            }}
                            disabled={!enabledStatus}
                        />
                }
                </>
            ),
            desc: (
                <>
                    Player will have <span style={{color: penaltyOne === 0 || penaltyOne === 100 ? 'red' : 'blue'}}>{penaltyOne}%</span> chance to get 0% penalty tier bonus.
                </>
            ),
        },
        {
            key: '2',
            level: '2',
            bonus: '1%',
            penBonusPct: (
                <>
                {
                    !mtnMgmtSwt(role, compCode) ?
                        <Input
                            value={penaltyTwo}
                            disabled={true}
                        />
                        :
                        <Input
                            style={{width:'50%'}}
                            value={penaltyTwo}
                            min={0}
                            max={100}
                            onChange={(e) => {
                                let value = e.target.value.replace(/\D/g, "");
                                if(value === ''){
                                    value = 0;
                                }
                                value = Math.min(parseInt(value), 100);
                                if(value.toString().length > 3){
                                    value = value.toString().slice(0,3);
                                }
                                setPenaltyTwo(value);
                            }}
                            disabled={!enabledStatus}
                        />
                }
                </>
            ),
            desc: (
                <>
                    Player will have <span style={{color: penaltyTwo === 0 || penaltyTwo === 100 ? 'red' : 'blue'}}>{penaltyTwo}%</span> chance to get 1% penalty tier bonus.
                </>
            ),
        },
        {
            key: '3',
            level: '3',
            bonus: '2%',
            penBonusPct: (
                <>
                {
                    !mtnMgmtSwt(role, compCode) ?
                        <Input
                            value={penaltyThree}
                            disabled={true}
                        />
                        :
                        <Input
                            style={{width:'50%'}}
                            value={penaltyThree}
                            min={0}
                            max={100}
                            onChange={(e) => {
                                let value = e.target.value.replace(/\D/g, "");
                                if(value === ''){
                                    value = 0;
                                }
                                value = Math.min(parseInt(value), 100);
                                if(value.toString().length > 3){
                                    value = value.toString().slice(0,3);
                                }
                                setPenaltyThree(value);
                            }}
                            disabled={!enabledStatus}
                        />
                }
                </>
            ),
            desc: (
                <>
                    Player will have <span style={{color: penaltyThree === 0 || penaltyThree === 100 ? 'red' : 'blue'}}>{penaltyThree}%</span> chance to get 2% penalty tier bonus.
                </>
            ),
        },
        {
            key: '4',
            level: '',
            bonus: (<p>Total Penalty Tier Range:</p>),
            penBonusPct: (
                    <p style={{color: totalPTR === 100 ? 'green' : 'red' }}>
                        {totalPTR}%
                    </p>
            ),
            desc: (
                <>
                    {
                        totalPTR > 100 ? 
                        <p style={{color:'red'}}>
                            <>ERROR : Total Penalty Tier Range total had exceeded 100%.</>
                            <br/>
                            <>There is extra <span style={{color:'red'}}>{totalPTR - 100}%</span>!</>
                        </p>
                        : 
                        totalPTR < 100 ?
                        <p style={{color:'red'}}>
                            <>ERROR : Total Penalty Tier Range total must be 100%.</>
                            <br/>
                            <>There is remaining <span style={{color:'red'}}>{100 - totalPTR}%</span>!</>
                        </p>
                        :
                        <p style={{color:'green'}}>Total Penalty Tier Range is {totalPTR}%, ready for submission.</p>
                        
                    }
                </>
            ),
        },
    ]



    return (
        <Form
            {...formItemLayout}
            style={{
                backgroundColor: "white",
                paddingBottom: "5px",
                paddingLeft: "13%",
            }}
            form={form}
            >
            <Row style={{paddingTop: '1%', marginBottom:'-1%'}}>
                <Col span={12}>
                <h1 style={{paddingLeft: '18%'}}>General Settings :</h1>
                </Col>
                <Col span={6}>
                    <Form.Item label="Wheel Status">
                        {
                            !mtnMgmtSwt(role, compCode) ?
                            <Switch
                                checkedChildren="ON"
                                unCheckedChildren="OFF"
                                checked={enabledStatus}
                                loading={loading}
                                disabled={true}
                            />
                            :
                            <Switch
                                checkedChildren="ON"
                                unCheckedChildren="OFF"
                                onChange={() => {
                                    setEnabledStatus(!enabledStatus);
                                }}
                                checked={enabledStatus}
                                loading={loading}
                            />
                        }
                    </Form.Item>
                </Col>
            </Row>
            <Table dataSource={genStgData} columns={genStgCols} pagination={false} style={{maxWidth: '70%', marginLeft:'8%', marginTop: '1%'}} />
            <Divider style={{marginLeft: '-20%', width:'100vw'}}></Divider>

            <h1 style={{paddingLeft: '9%'}}>High Tier Bonus Settings : </h1>
            <Table dataSource={highTierData} columns={highTierCols} pagination={false} style={{maxWidth: '70%', marginLeft:'8%', marginTop: '1%'}} />
            <Divider style={{marginLeft: '-20%', width:'100vw'}}></Divider>

            <h1 style={{paddingLeft: '9%'}}>Low Tier Bonus Settings :</h1>
            <Table dataSource={lowTierData} columns={lowTierCols} pagination={false} style={{maxWidth: '70%', marginLeft:'8%', marginTop: '1%'}} />
            <Divider style={{marginLeft: '-20%', width:'100vw'}}></Divider>
            
            <Row>
                <Col span={12}>
                    <h1 style={{paddingLeft: '18%'}}>Penalty Tier Settings :</h1>
                </Col>
                <Col span={6}>
                    <Form.Item label="Penalty Status" >
                        {
                            !mtnMgmtSwt(role, compCode) ?
                            <Switch
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            checked={penaltyStatus}
                            disabled={true}
                            />
                            :
                            <Switch
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            onChange={(e) => {
                            setPenaltyStatus(!penaltyStatus);
                            }}
                            checked={penaltyStatus}
                            loading={loading}
                            disabled={!enabledStatus}
                            />
                        }
                    </Form.Item>
                </Col>
            </Row>
            {
                !penaltyStatus ? 
                <></> 
                : 
                <Table dataSource={penaltyData} columns={penaltyCols} pagination={false} style={{maxWidth: '70%', marginLeft:'8%'}} />
            }
            <Form.Item label=" " colon={false} style={{marginTop: '1%', width:'70%'}}>
                {
                !mtnMgmtSwt(role, compCode)?
                <></>
                :        
                <>
                    <Button 
                        type="primary" 
                        htmlType="submit" 
                        style={styles.submitBtn}
                        loading={loading}
                        onClick={() => {
                            updateFortWhlSttg();
                        }}
                    >
                        Submit
                    </Button>
                </>
                }
            </Form.Item>
        </Form>
    )    
  }