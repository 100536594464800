import React, { Component } from "react";
import "../../App.less";
import { Layout, Menu, Dropdown, Button } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { NavLink, Switch, Route, Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { route, maintenance, logout } from "../../config/route";
import Login from "../login/login";
import Logout from "../logout/logout";
import { externalFileNotFile } from "../error/error";
import { copyright } from "../../library/texts";
import { Logo } from "../../common/components/Logo";
import { settings } from "../../config/appConfig";

const { Header, Sider, Content, Footer } = Layout;
const styles = {
  menuFontSize: {
    fontSize:
      window.innerWidth === 280 || window.innerWidth === 320 ? null : 20,
  },
};

class App extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  state = {
    collapsed: isMobile ? true : false,
    openKeys: [""],
    current: "/",
  };

  rootSubmenuKeys = ["sub1", "sub2", "sub3"];

  componentDidMount() {

    var that = this;
    maintenance.forEach(function (item) {
      if (item.path === window.location.pathname) {
        that.setState({ title: item.title });
      }
    });

    document.addEventListener("gesturestart", function (e) {
      e.preventDefault(); //disable iphone zooming
    });


  }

  toggle = () => {
    if (isMobile) {
      document.getElementById("menuBackground").style.display = "flex";
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  menuBgOnclick = () => {
    if (isMobile) {
      this.setState({
        collapsed: !this.state.collapsed,
      });
      document.getElementById("menuBackground").style.display = "none";
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  };

  handleClick = (e) => {
    console.log("click ", e);
    this.setState({
      current: e.key,
    });
  };

  menu = () => {
    return (
      <Menu>
        {isMobile ? (
          <div>
            <Menu.Item>
              <div
                style={{
                  justifyContent: "flex-start",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                <UserOutlined style={{ color: "black", marginRight: 10 }} />
                {this.props.role}_{this.props.username}
              </div>
            </Menu.Item>
            <Menu.Item key={logout[0].path} className="logoutBorder">
              <NavLink
                onClick={() => window.location.reload()}
                to={logout[0].path}
                style={{ width: 500 }}
              >
                <div
                  style={{
                    justifyContent: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                >
                  <LogoutOutlined style={{ color: "black", marginRight: 10 }} />
                  <span style={{ color: "black" }}>Logout</span>
                </div>
              </NavLink>
            </Menu.Item>
          </div>
        ) : (
          <Menu.Item key={logout[0].path} className="logoutBorder">
            <NavLink
              onClick={() => window.location.reload()}
              to={logout[0].path}
            >
              <div
                style={{
                  justifyContent: "flex-start",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <LogoutOutlined style={{ color: "black", marginRight: 10 }} />
                <span style={{ color: "black" }}>Logout</span>
              </div>
            </NavLink>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  render() {
    return this.props.signIn === false ? (
      <Switch>
        <Route path={[route.Default, route.Login]} exact component={Login} />
        <Route path={route.All} component={externalFileNotFile} />
      </Switch>
    ) : (
      <Layout>
        <div
          id="menuBackground"
          className="menuBackground"
          onClick={() => this.menuBgOnclick()}
        />
        <Sider
          className="ant-layout-sider-dark"
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}
          collapsedWidth={isMobile ? 0 : 80}
          width={
            window.innerWidth === 280 || window.innerWidth === 320 ? 220 : 280
          }
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            width: "280px",
          }}
        >
          <div
            style={{
              height: 64,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div>
              <Logo logoType="shareholder" />
              {/* <img src={logo.icon} alt="Logo" style={{ maxWidth: "80%" }} />
              {!this.state.collapsed ? (
                <img src={logo.logo} alt="Logo" style={{ maxWidth: "40%" }} />
              ) : null} */}
            </div>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[window.location.pathname]}
            onClick={this.handleClick}
            openKeys={this.state.openKeys}
            onOpenChange={this.onOpenChange}
          >
            <Menu.Item
              onClick={() => {
                  window.location.reload()
              }}
              key={maintenance[0].path}
              icon={maintenance[0].icon}
              style={styles.menuFontSize}
            >
              <Link to={maintenance[0].path}>{maintenance[0].menutitle}</Link>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                  window.location.reload()
              }}
              key={maintenance[1].path}
              icon={maintenance[1].icon}
              style={styles.menuFontSize}
            >
              <Link to={maintenance[1].path}>{maintenance[1].menutitle}</Link>
            </Menu.Item>
            {/* {
              maintenance.map(function(obj){
                return (
                  <Menu.Item
                        onClick={() => {
                          window.location.reload()
                        }}
                        key={obj.path}
                        icon={obj.icon}
                        style={styles.menuFontSize}
                      >
                    <Link to={obj.path}>{obj.menutitle}</Link>
                  </Menu.Item>
                  )
                })
              } */}

          </Menu>
        </Sider>
        <Layout className="site-layout">
          {isMobile ? (
            <Header
              className="site-layout-background"
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                height: 68,
              }}
            >
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: this.toggle,
                }
              )}

              <div
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {this.state.title}
              </div>

              <div>
                <div
                  style={{
                    fontSize: 18,
                  }}
                >
                  <Dropdown overlay={this.menu} placement="bottomLeft">
                    <Button
                      style={{
                        border: "none",
                        width: 80,
                        height: 65,
                      }}
                      icon={<UserOutlined />}
                      size="large"
                    ></Button>
                  </Dropdown>
                </div>
              </div>
            </Header>
          ) : (
            <Header
              className="site-layout-background"
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                marginLeft: this.state.collapsed ? 64 : 280,
                height: 68,
                alignItems: "center",
              }}
            >
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: this.toggle,
                }
              )}

              <div
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {this.state.title}
              </div>

              <div>
                <div
                  style={{
                    fontSize: 18,
                  }}
                >
                  <Dropdown overlay={this.menu} placement="bottomLeft">
                    <Button
                      style={{
                        border: "none",
                        width: 250,
                        height: 65,
                      }}
                      icon={<UserOutlined />}
                      size="large"
                    >
                      {this.props.role}_{this.props.username}
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </Header>
          )}
          <Content
            className="site-layout-background"
            style={{
              marginTop: "5px",
              marginLeft: isMobile ? "" : this.state.collapsed ? 64 : 280,
              backgroundColor: "#f0f2f5",
            }}
          >
            <div
              className="site-layout-background"
              style={{
                marginRight: isMobile ? "" : this.state.collapsed ? 10 : "",
                marginLeft: isMobile ? "" : this.state.collapsed ? 24 : "",
                padding: isMobile ? "" : 24,
                minHeight: isMobile ? 550 : 360,
              }}
            >
              <Switch>
                <Route exact path={route.Logout} component={Logout} />
                {maintenance.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                ))}
              </Switch>
            </div>
          </Content>
          {isMobile ? (
            <Footer style={{ textAlign: "center" }}>
              <div>{copyright.fullText()}</div>
              <div>V: {settings.version}</div>
            </Footer>
          ) : (
            <Footer
              style={{
                textAlign: "center",
                marginLeft: 280,
              }}
            >
              <div>{copyright.fullText()}</div>
              <div>V: {settings.version}</div>
            </Footer>
          )}
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = ({ login }) => ({
  signIn: login.signIn,
  username: login.username,
  role: login.role,
});
const mapDispatchToProps = (dispatch) => {
  return { signOut: () => dispatch({ type: "sign_out" }) };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
