import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Form,
  Button,
  Modal,
  Badge,
  PageHeader,
  message,
  Select,
  InputNumber,
  Popconfirm,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  userShareholderNew,
  userShareholderList,
  userManageUpdate,
  userManageUpdateV2,
  verifyValidMobile,
  getCompanyProfileData,
  getUserData,
} from "../../config/networkConfig";
import md5 from "md5";
import { useSelector } from "react-redux";
import {
  alertData,
  sharedSuccessAlert,
  sharedErrorAlert,
  sharedFailAlert,
} from "../../common/constants/sharedMsgBox";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    dataIndex == "nickname" ? (
      <Input allowClear={true} />
    ) : dataIndex == "mobilenumber" ? (
      <InputNumber maxLength={15} style={{ width: "170px" }} />
    ) : dataIndex == "pin" ? (
      <InputNumber maxLength={6} style={{ width: "100px" }} />
    ) : (
      <></>
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `${title} cannot be empty !`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default function ShareholderManagement() {
  const { Option } = Select;
  const [form] = Form.useForm();
  const modalForm = useRef();
  const [originData, setOriginData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [username, setUsername] = useState("");
  const [nickname, setNickname] = useState("");
  const [password, setPassowrd] = useState("");
  const md5Pass = md5(password);
  const [searchText, setSearchText] = useState("");
  const [addVisible, setAddVisible] = useState(false);
  const { userId } = useSelector((state) => state.login);
  const [tableLoading, setTableLoading] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [countryCode, setCountryCode] = useState("856");
  const [mobilenumber, setMobileNumber] = useState("");
  const [createMemberDisable, setCreateMemberDisable] = useState(true);
  const [cancelDisable, setCancelDisable] = useState(false);
  const [pin, setPin] = useState("");
  const [companyCountryCode, setCompanyCountryCode] = useState("");

  useEffect(() => {
    loadCompanyProfileData();
    downlineList();
    // eslint-disable-next-line
  }, []);

  const loadCompanyProfileData = async () => {
    // Step 1: Get company id from user profile based on userId
    let requestData = {
      userID: userId,
      columnRequire: ["uuidfrombin(company) as companyid"],
    };

    const response = await getUserData(requestData);
    let result = await response.json();

    if (result.status === "ok") {
      let requestData = {
        companyID: result.data[0].companyid,
        columnRequire: ["MobileCode"],
      };

      // Step 2: Get company profile based on company id from step 1
      const response2 = await getCompanyProfileData(requestData);
      let result2 = await response2.json();
      if (result2.status === "ok") {
        setCompanyCountryCode(result2.data[0].MobileCode);
      } else {
        alertData.title = "Failed";
        alertData.msg = "Connection not stable, please try again !";
        sharedFailAlert(alertData);
      }
    } else {
      alertData.title = "Failed";
      alertData.msg = "Connection not stable, please try again !";
      sharedFailAlert(alertData);
    }
  };

  const downlineList = () => {
    var count = 0;
    setTableLoading(true);
    userShareholderList({ userId: userId })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          setTableLoading(false);
          const newData = Object.keys(json.data).length
            ? json.data.map((x) => {
                return {
                  key: count++,
                  userid: x.userId,
                  username: x.username,
                  pin: x.pin,
                  nickname: x.nickname,
                  mobilenumber: x.mobilenumber,
                  usertype: x.type,
                  registerDate: x.registerDate,
                  serialNumber: `${x.shareCount}/${x.agentCount}/${x.memberCount}`,
                  status:
                    x.status === "0"
                      ? "Lock"
                      : x.status === "1"
                      ? "Active"
                      : "Inactive",
                };
              })
            : [];
          setOriginData(newData);
          setAddVisible(false);
        } else {
          setTableLoading(true);
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg =
          "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
  };

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const showModal = () => {
    setCancelDisable(false);
    setCreateMemberDisable(true);
    setEditingKey("");
    setAddVisible(true);
    form.resetFields();
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },

    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  const columns = [
    {
      width: "200px",
      align: "center",
      title: "Username",
      dataIndex: "username",
      ...getColumnSearchProps("username"),
      ellipsis: true,
    },
    {
      width: "150px",
      align: "center",
      title: "Nickname",
      dataIndex: "nickname",
      editable: true,
      ...getColumnSearchProps("nickname"),
    },
    {
      width: "230px",
      align: "center",
      title: "Mobile Number",
      dataIndex: "mobilenumber",
      editable: true,
      ...getColumnSearchProps("mobilenumber"),
      ellipsis: true,
    },
    {
      width: "150px",
      ellipsis: true,
      align: "center",
      title: "PIN",
      dataIndex: "pin",
      editable: true,
    },
    {
      width: "200px",
      align: "center",
      title: "Register Date",
      dataIndex: "registerDate",
      ellipsis: true,
    },
    {
      width: "150px",
      align: "center",
      title: "No. of SH/AG/MB",
      dataIndex: "serialNumber",
      ellipsis: true,
    },
    {
      width: "100px",
      align: "center",
      ellipsis: true,
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        switch (record.status) {
          case "Lock":
            return <Badge status="warning" text="Lock" />;
          case "Active":
            return <Badge status="success" text="Active" />;
          default:
            return <Badge status="error" text="Inactive" />;
        }
      },
    },
    {
      width: "250px",
      ellipsis: true,
      align: "center",
      title: "Action",
      dataIndex: "operation",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Input
              id={"pwd" + record.key}
              placeholder="Change Password"
              style={{ width: "auto", textAlign: "center" }}
              onChange={(e) => {
                setPassowrd(e.target.value);
              }}
            />
            <Button
              loading={loadings[1]}
              type="dashed"
              shape="circle"
              icon={<SaveOutlined />}
              onClick={() => {
                save(record, 1);
              }}
            />
            <div>
              <Popconfirm
                placement="topRight"
                title="Confirm cancel?"
                onConfirm={() => cancel(record.key)}
              >
                <Button
                  type="danger"
                  shape="circle"
                  icon={<CloseCircleOutlined />}
                />
              </Popconfirm>
            </div>
          </div>
        ) : (
          <span disabled={editingKey !== ""} onClick={() => edit(record)}>
            <Button type="primary" shape="circle" icon={<EditOutlined />} />
          </span>
        );
      },
    },
  ];

  const isEditing = (record) => record.key === editingKey;

  const edit = async (record) => {
    form.setFieldsValue({
      nickname: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (record, index) => {
    const row = await form.validateFields();
    const { nickname, mobilenumber, pin } = row;
    let requestData = {
      userid: record.userid,
    };

    setTableLoading(true);

    if (nickname != record.nickname) {
      requestData.nickname = nickname;
    }

    if (
      mobilenumber != record.mobilenumber &&
      String(mobilenumber).length < 10
    ) {
      setTableLoading(false);
      alertData.title = "Format Error";
      alertData.msg = "New mobile number is too short !";
      sharedErrorAlert(alertData);
      return;
    }

    let tmpMobile = "";

    if (
      mobilenumber != record.mobilenumber &&
      String(mobilenumber).length >= 10
    ) {
      let substringLength = String(companyCountryCode).length;

      if (
        String(mobilenumber).substring(0, substringLength) != companyCountryCode
      ) {
        tmpMobile = `${companyCountryCode}` + mobilenumber;
        // if(companyCountryCode == '60') {

        //   if(mobilenumber.substring(0,1) == '0') {
        //     requestData.mobile = `${String(companyCountryCode).substring(0,1)}` + mobilenumber;
        //   }
        //   else {
        //     requestData.mobile = `${companyCountryCode}` + mobilenumber;
        //   }
        // }
        // else{
        //   requestData.mobile = `${companyCountryCode}` + mobilenumber;
        // }
      } else {
        tmpMobile = mobilenumber;
      }
      // Verify if number is used by all type of user
      const verifyNumber = await validateUserPhoneV2(String(tmpMobile));
      if (verifyNumber == true) {
        requestData.mobile = String(tmpMobile);
      } else {
        setTableLoading(false);
        alertData.title = "Validation Error";
        alertData.msg = `+${tmpMobile} is used by another user !`;
        sharedFailAlert(alertData);
        return;
      }
    }

    if (String(pin).length < 6) {
      setTableLoading(false);
      alertData.title = "Format Error";
      alertData.msg = "PIN must not less than 6 digit !";
      sharedErrorAlert(alertData);
      return;
    }

    if (pin != record.pin) {
      if (Number.isInteger(pin) == true) {
        requestData.pin = pin;
      } else {
        setTableLoading(false);
        alertData.title = "Format Error";
        alertData.msg = "PIN must contain only digit / number !";
        sharedErrorAlert(alertData);
        return;
      }
    }

    if (document.getElementById("pwd" + record.key).value != "") {
      requestData.pass = md5Pass;
    }

    if (
      nickname === record.nickname &&
      mobilenumber === record.mobilenumber &&
      pin === record.pin &&
      document.getElementById("pwd" + record.key).value === ""
    ) {
      setEditingKey("");
      setTableLoading(false);
      return;
    }

    try {
      // const newData = [...originData];
      // const indexSave = newData.findIndex((item) => record.key === item.key);
      // loadingTrue(index);
      // if (indexSave > -1) {
      if (requestData != {}) {
        userManageUpdateV2(requestData)
          .then((response) => response.json())
          .then((json) => {
            if (json.status === "ok") {
              loadingFalse(index);
              // const item = newData[indexSave];
              // newData.splice(indexSave, 1, { ...item, ...row });
              alertData.title = "Success";
              alertData.msg = json.msg;
              sharedSuccessAlert(alertData);
              setEditingKey("");
              downlineList();
            } else {
              alertData.title = "Failed";
              alertData.msg = json.msg;
              sharedFailAlert(alertData);
              loadingFalse(index);
              setTableLoading(false);
            }
          })
          .catch((error) => {
            console.log("error: ", error);
            alertData.title = "Error";
            alertData.msg =
              "An unexpected error occurred. Please contact your system administrator.";
            sharedErrorAlert(alertData);
          });
      }
    } catch (errInfo) {
      console.log("errInfo: ", errInfo);
      alertData.title = "Error";
      alertData.msg = "Connection not stable, please try again !";
      sharedErrorAlert(alertData);
    }
  };

  const handleAdd = (index) => {
    var regEx = new RegExp(/^\d*[a-z]{1,}\d*/);

    if (!username || !password || !nickname || !mobilenumber || !pin) {
      alertData.title = "Field Required";
      alertData.msg =
        "Please fill in Mobile Number, Pin, Username, Nickname, Password !";
      sharedErrorAlert(alertData);
      return;
    } else if (!regEx.test(username)) {
      alertData.title = "Format Error";
      alertData.msg = "Username only accept lowercase characters and numbers !";
      sharedErrorAlert(alertData);
      return;
    }
    if (pin.length < 6 || pin.length > 6) {
      alertData.title = "Invalid Pin";
      alertData.msg = "PIN must be 6 characters !";
      sharedErrorAlert(alertData);
      return;
    }
    if (mobilenumber.length < 7) {
      alertData.title = "Invalid Mobile No";
      alertData.msg = "Mobile number is too short !";
      sharedErrorAlert(alertData);
      return;
    }

    loadingTrue(index);
    setCancelDisable(true);
    userShareholderNew({
      userId: userId,
      username: username.toLowerCase().trim(),
      nickname: nickname,
      mobilenumber: countryCode + mobilenumber,
      pin: pin,
      pass: md5Pass,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          loadingFalse(index);
          setAddVisible(false);
          downlineList();
          alertData.title = "Success";
          alertData.msg = json.msg;
          sharedSuccessAlert(alertData);
          onCancel();
        } else {
          loadingTrue(index);
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
        loadingFalse(index);
        // modalForm.current.resetFields();
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg =
          "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
  };

  const selectCountryCode = (value) => {
    setCountryCode(value);
  };

  const validateUserPhone = async (mobilenumber) => {
    const response = await verifyValidMobile({
      mobile: countryCode + mobilenumber,
    });
    let result = await response.json();

    if (result.status === "ok") {
      setCreateMemberDisable(true);
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
    } else {
      setCreateMemberDisable(false);
    }
  };

  const validateUserPhoneV2 = async (mobilenumber) => {
    const response = await verifyValidMobile({ mobile: mobilenumber });
    let result = await response.json();

    if (result.status === "ok") {
      return false;
    } else {
      return true;
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onCancel = () => {
    setAddVisible(false);
    setUsername("");
    setNickname("");
    setMobileNumber("");
    setPin("");
    setPassowrd("");
    modalForm.current.resetFields();
  };

  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <div style={{ marginLeft: 0 }}>
        <Modal
          title="New Shareholder"
          visible={addVisible}
          footer={null}
          closable={false}
          onCancel={onCancel}
        >
          <Form ref={modalForm}>
            <Form.Item
              name={["user", "mobilenumber"]}
              label="Mobile No"
              required={true}
            >
              <Input.Group compact>
                <Select
                  style={{ width: "21%" }}
                  value={countryCode}
                  onChange={selectCountryCode}
                >
                  <Option value="856">+856</Option>
                  <Option value="66">+66</Option>
                  <Option value="60">+60</Option>
                </Select>
                <Input
                  style={{ width: "79%" }}
                  defaultValue=""
                  placeholder="phone number"
                  value={mobilenumber}
                  onChange={(e) => {
                    setMobileNumber(e.target.value.replace(/\D|^0/g, ""));
                  }}
                  onBlur={(e) => {
                    validateUserPhone(e.target.value.replace(/\D|^0/g, ""));
                  }}
                />
              </Input.Group>
            </Form.Item>
            <Form.Item
              name={["user", "pin"]}
              label="Pin&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
              required
            >
              <Input.Group compact>
                <Input
                  value={pin}
                  placeholder="6 Digit Pin"
                  maxLength={6}
                  onChange={(e) => {
                    setPin(e.target.value.replace(/\D|^0/g, ""));
                  }}
                />
              </Input.Group>
            </Form.Item>
            <Form.Item
              name={["user", "username"]}
              label="Username"
              required
              rules={[
                () => ({
                  validator(rule, value) {
                    var regEx = new RegExp(/^\d*[a-z]{1,}\d*/);

                    if (regEx.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Only accept lowercase characters and numbers!"
                    );
                  },
                }),
              ]}
            >
              <Input
                value={username}
                placeholder="Username"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item name={["user", "nickname"]} label="Nickname" required>
              <Input
                value={nickname}
                placeholder="Nickname"
                onChange={(e) => {
                  setNickname(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item name={["user", "password"]} label="Password" required>
              <Input
                value={password}
                placeholder="Password"
                onChange={(e) => {
                  setPassowrd(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "right" }}>
              <Button
                htmlType="button"
                onClick={onCancel}
                style={{ marginRight: 10 }}
                okbuttonprops={
                  cancelDisable
                    ? { loading: loadings, disabled: cancelDisable }
                    : {}
                }
                disabled={cancelDisable}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loadings[0]}
                okbuttonprops={
                  createMemberDisable
                    ? { loading: loadings[0], disabled: createMemberDisable }
                    : {}
                }
                disabled={createMemberDisable}
                onClick={() => {
                  handleAdd(0);
                }}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <PageHeader
        ghost={false}

        // extra={[
        //   <div key="1">
        //     <Button type="primary" onClick={showModal}>
        //       Add Shareholder
        //     </Button>
        //   </div>,
        // ]}
      >
        <div
          style={{
            pageHeaderContainer: {
              justifyContent: "space-between",
              display: "flex",
            },
          }}
        >
          <Button type="primary" onClick={() => showModal()}>
            New Shareholder
          </Button>
        </div>
      </PageHeader>
      <Form form={form} component={false}>
        <Table
          loading={tableLoading}
          scroll={{ x: "100%", y: null }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={originData}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
          style={{ overflow: "auto", marginLeft: 20 }}
        />
      </Form>
    </div>
  );
}
