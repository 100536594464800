import React, { useState, useEffect } from "react";
import {
  Table,
  message,
  PageHeader,
  Button,
  Modal,
  Input,
  Badge,
  Form,
  Row,
  Col,
  Tag,
} from "antd";
import {
  puppetTransactionListBankTrxn,
  csMemberWalletTrxnMobile,
  getReqToken,
  csBankConflictApprove,
} from "../../../config/networkConfig";
import { useSelector } from "react-redux";
import { SearchOutlined} from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import { settings } from "../../../config/appConfig";
import Highlighter from "react-highlight-words";
import moment from "moment";
import momentTz from "moment-timezone";
import { FormatPt } from "../../../library/numbers";
import md5 from "md5";
import style from "../../../resource/style/style";


export default function ReportBankTrxn() {
  const [form] = Form.useForm();
  const { userId } = useSelector((state) => state.login);
  const [originData, setOriginData] = useState("");
  const [bankData, setBankData] = useState("");

  //Member Transaction 
  const [memberVisible, setMemberVisible] = useState(false);
  const [memberPhoneNo, setMemberPhoneNo] = useState("");
  const [btnApproveMemberText,setBtnApproveMemberText]=useState("");
  const [filteredPptTrxn,setFilterPptTrxn]= useState([]);
  const [searchText, setSearchText] = useState("");
  const [trxDateFrom, setTrxDateFrom] = useState("");
  const [trxDateTo, setTrxDateTo] = useState("");
  const [trxLoading, setTrxLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [pptAmt, setPptAmt] = useState("");
  const [pptTrxnId,setPptTrxnId] = useState("");

  useEffect(() => {
    // eslint-disable-next-line
    processTrxnList();
  }, []);

  //#region Components
  const DepositAmt = (props) => {
     if (props.Amount!='') {
       return (<span>Deposit Amount :{FormatPt(props.Amount)}</span>);
     }
  }
  ////#endregion

   //#region Load List
  const processTrxnList = async () => {
    let count = 0 ;
    let currentTime = momentTz.tz(moment(), "Asia/Kuala_Lumpur");

    setTableLoading(true);

    let requestBankConflictData = {
       status : "8",
       dateto: currentTime.format("YYYY-MM-DD HH:mm:ss"),
       interval:"15"
    }

    let requestData = {
      status : "0",
      dateto: currentTime.format("YYYY-MM-DD HH:mm:ss"),
      interval:"15"
   }

    // console.log(`requestBankConflictData >> ${JSON.stringify(requestBankConflictData)}, requestData >> ${JSON.stringify(requestData)}`)
    
    const resBankCon = await puppetTransactionListBankTrxn(requestBankConflictData);
  
    let respBankCon = await resBankCon.json();

    if (respBankCon.status ==="ok" && respBankCon.msg !='No records found') {

      if (Object.keys(respBankCon.data).length > 0 ) {
        setTableLoading(false);

        let newBankData = respBankCon.data.map((obj) => {
              return {
                key: count++,
                pptBankTrxnId: obj.pptTrxnId,
                bankTrxTime: obj.bankTrxTime,
                bankAmount: obj.bankAmount, 
                bankDetails: obj.bankDetails,
                remark: obj.remark,
                status: obj.status=="000"?
                        "unClaim": obj.status=="8"?
                        "bankConflict": obj.status=="7"? "digitConflict":"",
                oriStatus: obj.oriStatus,
                sysCreateBy: obj.sysCreateBy,
                sysCreateTime: obj.SysCreateTime,
              };
            });

            let filterData = newBankData.filter(item=>item.status == "unClaim" || item.status =="bankConflict" || item.status == "digitConflict" );

            setBankData(filterData);

      } else {
        setTableLoading(false);
        message.error(resp.msg);
      }
    }

    const result = await puppetTransactionListBankTrxn(requestData);
    let resp = await result.json();
    if (resp.status ==="ok" && resp.msg !='No records found') {

      if (Object.keys(resp.data).length > 0 ) {
        setTableLoading(false);

        let newData = resp.data.map((obj) => {
              return {
                key: count++,
                pptBankTrxnId: obj.pptTrxnId,
                bankTrxTime: obj.bankTrxTime,
                bankAmount: obj.bankAmount, 
                bankDetails: obj.bankDetails,
                remark: obj.remark,
                status: obj.status =="0"? 
                    "Pending":"",
                oriStatus: obj.oriStatus,
                sysCreateBy: obj.sysCreateBy,
                sysCreateTime: obj.sysCreateTime,
              };
            });
        setOriginData(newData);

      } else {
        setTableLoading(false);
        message.error(resp.msg);
      }
    }
  };
  ////#endregion

  //#region Functions
  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const approvePPTTrxn1 = async (index) => {

    let approveData = {
      pptTrxnId  : pptTrxnId, 
      csUserid   : userId,
      mobile     : memberPhoneNo,
      reqToken   : md5(getReqToken('bankConflictApprove')),
    }

    if (index > -1) {
      loadingTrue(index);
      const result =  await csBankConflictApprove(approveData);
      let resp = await result.json();

      if (resp.status ==="ok") {
        message.success(resp.msg);
        setMemberVisible(false);    
        loadingFalse(index);
        processTrxnList();
      }else{
        loadingTrue(false);
        message.error(resp.msg);
      }
    }else{
      loadingFalse(index);
      message.error("Connection not stable, please try again !");
    }
  }


//   const approvePPTTrxn = async (record, index) => {

//     let approveData = {
//       // trxnId     : record.trxnID, 
//       pptTrxnId  : pptTrxnId, 
//       processtype: PROCESS_TYPE.MANUAL, 
//       csUserid   : userId,
//       userid     : record.userId,
//       //depositMode: PROCESS_TYPE.MANUAL,
//       reqToken   : md5(getReqToken('bankConflictApprove')),
//     }

//     if (index > -1) {
//       loadingTrue(index);
//       // const result =  await csBankConflictApprove(approveData);
//       // let resp = await result.json();

//       // if (resp.status ==="ok") {
//       //   message.success(resp.msg);
//       //   setMemberVisible(false);    
//       //   loadingFalse(index);
//       //   processTrxnList();
//       // }else{
//       //   loadingTrue(false);
//       //   message.error(resp.msg);
//       // }
//     }else{
//       loadingFalse(index);
//       message.error("Connection not stable, please try again !");
//     }

  const handleMemberPhone = (e) => {
    e.preventDefault();
    message.error("Copy Not Allowed");
  }

  const trxDatePicker = (date, dateString) => {
    setTrxDateFrom(date);
  };

  const trxDatePicker2 = (date, dateString) => {
    setTrxDateTo(date);
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });

  const clearModal = ()=> {
      setMemberVisible(false);
      setMemberPhoneNo("");
      setBtnApproveMemberText("");
      setFilterPptTrxn([]);
      setPptTrxnId("");
  }


  const ViewPPTDetails = async (mobile,trxnTime) => {
    
    setTrxLoading(true);

    let startDate = moment(trxnTime).subtract(1,'days').format('YYYY-MM-DD');
    let endDate = moment(trxnTime).add(1,'days').format('YYYY-MM-DD')

    let requestData = {
        mobile:mobile,
        datefrom: startDate,
        dateto: endDate,
    }

    let respTrxn = await csMemberWalletTrxnMobile(requestData);
    let resTrxn = await respTrxn.json();
    let countTrxn = 0 ;

    if (resTrxn.status === "ok") {

      if (resTrxn.data.detail && resTrxn.data.detail.length) {

        setTrxLoading(false);

        let newData = resTrxn.data.detail.map((obj) => {
          return {
            key: countTrxn++,
            date: obj.date,
            userid: obj.userid,
            trxnID: obj.trxnID,
            trxnType: obj.trxnType, 
            amount: obj.amount,
            actualAmount: obj.actualAmount,
            comm: obj.comm,
            status: obj.status,
            actionStatus :
              obj.status == "0"
                ? "Pending"
                : obj.status == "1"
                ? "Approved"
                : obj.status == "3"
                ? "Completed"
                : obj.status == "9"
                ? "Rejected"
                : "-",
          };
        });

        setFilterPptTrxn(newData);
        
        const distinctStatus = [...new Set(newData.map(item=>item.actionStatus))];
        //console.log(`distinctStatus >> ${JSON.stringify(distinctStatus)}`)

        //let dates = resTrxn.data.detail.map(item => moment(item.date)), maxDate = moment.max(dates) , minDate = moment.min(dates);
        //console.log(`minxDate >> ${minDate.format("YYYY-MM-DD")} , maxDate >> ${maxDate.format("YYYY-MM-DD")}`);
        //setTrxDateFrom(minDate);
        //setTrxDateTo(maxDate);
      }

      if (resTrxn.data.summary && resTrxn.data.summary.length) {
          // let walletSumm = resTrxn.data.summary[0];
          // setCSMemberWalletDt(walletSumm.Dt);
          // setCSMemberWalletBal(`${FormatPt(walletSumm.Balance)}`);
          // setCSMemberWalletComm(`${FormatPt(walletSumm.Comm)}`);
      }else{
        // setCSMemberWalletDt("N/A");
        // setCSMemberWalletBal("N/A");
        // setCSMemberWalletComm("N/A");
      }
    }

    setTrxLoading(false);
  }

  ////#endregion


  //#region Columns
  let memberTrxnColumns = [
    {
      align: "center",
      title: "Date Time",
      dataIndex:"date",
      width: "200px",
      //ellipsis: true,
    //   render: (text, record)=>{
    //     let statement = text+'||'+record.trxnID;
    //     return <span>{statement}</span>;
    //  }
    },
    {
      align: "center",
      title: "Transaction Type",
      dataIndex: "trxnType",
      width: "200px",
      ellipsis: true,

      //filters: filterData(this)(i => i.trxnType),
      filters: [
        {
          text: 'Commision',
          value: 'Comm',
        },

        {
          text: 'Game-in',
          value: 'Game-In',
        },
        {
          text: 'Game-out',
          value: 'Game-Out',
        },
        {
          text: 'Promotion',
          value: 'Promotion',
        },
        {
          text: 'Recovery',
          value: 'Recovery',
        },
        {
          text: 'Topup',
          value: 'Topup',
        },
        {
          text: 'Withdraw',
          value: 'Withdraw',
        },
      ],
      filterMultiple: false,
      //onFilter: (value, record) => record.trxnType.indexOf(value) === 0,
      onFilter: (value, record) => record.trxnType.toLowerCase().indexOf(value.toLowerCase()) === 0 ,
      render: (text)=>{
         if (text.toLowerCase()=="topup") {
            return "Topup";
         }else {
           return text;
         }
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Amount",
      dataIndex: "amount",
      width: "150px",
      render : (text) => {
        return <div style={style.bankAmountStyle}>{FormatPt(text)}</div>
      },
      ...getColumnSearchProps("amount"),
    }, 
    {
      ellipsis: true,
      align: "center",
      title: "Actual Amount",
      dataIndex: "actualAmount",
      width: "150px",
      render :(text,record)=> {
        if (text===0 || text=="") {
            return <>-</>;
        }else {
            return <div style={style.bankAmountStyle}>{FormatPt(text)}</div>
        }
      },
      ...getColumnSearchProps("actualAmount"),
    },   
    {
      ellipsis: true,
      align: "center",
      title: "Commission",
      dataIndex: "comm",
      width: "150px",
    },
    {
      ellipsis: true,
      align: "left",
      title: "Status",
      dataIndex: "status",
      width: "150px",
      render: (text, record) => {
        switch (text) {
            case 0:
              return <Badge text="Pending" color={"yellow"}/>;
            case 1:
                if (record.trxnType =="Withdraw") {
                    return <Badge text="Bank Process Complete" color={"blue"} />;
                }
                return <Badge text="Approved" color={"blue"} />;       
            case 3:
                return <Badge text="Approved" color={"blue"} />;
            case 9:
              return <Badge status="error" text="Rejected" />;
            default:
               return <span>{text}</span>;

        }
      }
    },
  ];


  let pptColumns = [
    {
      align: "center",
      title: "",
      dataIndex:"pptBankTrxnId",
      width: "10px",
      //width:"100px",
      ellipsis: true,
      render: (text)=> {
        return "";
      }
    },
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "bankTrxTime",
      width: "200px",
      ellipsis: true,
      render:(text) => {
        return (text);
      }
    },
    {
      align: "center",
      title: "Amount",
      dataIndex: "bankAmount",
      width: "150px",
      // ellipsis: true,
      render: (text)=> {
        return <div style={style.bankAmountStyle}>{FormatPt(text)}</div>;
      },
      ...getColumnSearchProps("bankAmount"),
    },    
    {
      align: "center",
      title: "Bank Details",
      dataIndex: "bankDetails",
      width: "200px",
      ellipsis: true,
      render: (text)=> {
        return (text);
      },
      ...getColumnSearchProps("bankDetails"),
    },
    {
      align: "center",
      title: "Status",
      dataIndex: "status",
      width: "200px",
      ellipsis: true,
      render: (text)=> {
        if (text == "unClaim"){
          return <Tag color="#FFBF00" style={style.tagStyle}><b>Un-claim</b></Tag>
        } else if (text == "bankConflict"){
          return <Tag color="red" style={style.tagStyle}>Bank Conflict</Tag>
        } else{
          return <Tag color="pink" style={style.tagStyle}>Digit Conflict</Tag>
        }

      },
      filters: [
        {
          text: 'Un-Claimed',
          value: 'unClaim',
        },

        {
          text: 'Bank Conflict',
          value: 'bankConflict',
        },
        {
          text: 'Last Digit Conflict',
          value: 'digitConflict',
        }
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.status.toLowerCase().indexOf(value.toLowerCase()) === 0 ,

    },
    {
      align: "center",
      title: "Remark",
      dataIndex: "remark",
      width: "150px",
      render: (text,record) => {

          if (text.length > 0 && text.indexOf(';') == -1) {
  
            // let index = 0;

            // const listItems =
            //           <Button 
            //               key={`${index}-${text}`}
            //               onClick={() => {
            //                 setMemberVisible(true);
            //                 setPptAmt(record.bankAmount);
            //                 setPptTrxnId(record.pptBankTrxnId);
            //                 setMemberPhoneNo(text);
            //                 setBtnApproveMemberText(`Approve to ${text}`);
            //                 ViewPPTDetails(text.trim(),record.bankTrxTime);
            //               }}
            //               style={{...style.memberBtn,...style.noHighlight}}
            //             >
            //              {`Member ${index+1}`}
            //             </Button>
                      
              
            // return (
            //   <div>
            //   {listItems }
            //   </div>
            // );
          }
          else if (text.length > 0 && text.indexOf(';') > -1) {
              let members = text.split(';');
              const listItems = members.map((item,index) => 
                       <Button 
                          key={`${index}-${item}`}
                          onClick={() => {
                            setMemberVisible(true);
                            setPptAmt(record.bankAmount);
                            setPptTrxnId(record.pptBankTrxnId);
                            setMemberPhoneNo(item);
                            setBtnApproveMemberText(`Approve to ${item}`);
                            ViewPPTDetails(item.trim(),record.bankTrxTime);
                          }}
                          style={{...style.memberBtn,...style.noHighlight}}
                        >
                         {`Member ${index+1}`}
                        </Button>
                      )
              
              return (
                <div>
                {listItems }
                </div>
              );

          }else {
            return <div></div>;
          }
          
      },
    },
  ];

  let processColumns = [
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "bankTrxTime",
      width: "200px",
      render:(text) => {
        return (text);
      }
    },
    {
      align: "center",
      title: "Amount",
      dataIndex: "bankAmount",
      width: "150px",
      render: (text)=> {
        return <div style={style.bankAmountStyle}>{FormatPt(text)}</div>;
      }
    },    
    {
      align: "center",
      title: "Bank Details",
      dataIndex: "bankDetails",
      width: "200px",
      ellipsis: true,
      render: (text)=> {
        return (text);
      }
    },
    {
      align: "center",
      title: "Status",
      dataIndex: "status",
      width: "200px",
      ellipsis: true,
      render: (text)=> {
          return <Tag color="green" style={style.tagStyle}>Processing</Tag>
      },
    },
    {
      align: "center",
      title: "Remark",
      dataIndex: "remark",
      width: "150px",
      render: (text) => {
            return <div></div>;
      },
    },
  ];

  ////#endregion

  return (
    <div>
      <PageHeader ghost={false}>
        <div style={style.pageHeaderContainer}>
          <div style={{float:"left"}}>
            <Button
              style={style.refreshBtn}
              type="primary"
              onClick={() => {
                processTrxnList();
              }}
            >
              Refresh Page
            </Button>
          </div>
        </div>
      </PageHeader>
      <div style={style.tabelContainer}>
        <div style={{height:"60%"}}>
          <p style={style.attn}>Attention</p>
          <Table
              columns={pptColumns}
              dataSource={bankData}
              loading={tableLoading}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: settings.pageSizeOptions,
                style: style.paginationStyle,
                alignmentBottom: 'right',
              }}
            />
        </div>
        <div style={{height:"40%"}}>
          <p style={style.attn}>Processing</p>
          <Table
              columns={processColumns}
              dataSource={originData}
              loading={tableLoading}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: settings.pageSizeOptions,
                style: style.paginationStyle,
                alignmentBottom: 'right',
              }}
            />
         </div>
      </div>{/*Table container ending*/}
      <Modal visible={memberVisible}
          onCancel={() => {         
            clearModal();
          }}
          width={1400}
          style={style.memberTrxnStyle}
          centered
          cancelButtonProps={{ type: "primary" }}
          okButtonProps={{ style:{display: "none"} }}    
          cancelText="Close"   
      >

          <div style={style.tabelContainer}>
          <>
            {
              // puppetVisible?
              // (
                <>
                {/* <Row style={styles.hide}>
                <DatePicker
                    key="2"
                    style={{...styles.datePickerStyle, ...styles.hide}}
                    onChange={trxDatePicker}
                    value={trxDateFrom}
                    ref={reportDateFrom}
                    refs
                    placeholder={"Select Date From"}
                    disabledDate={(current)=> {
                      return current > moment().endOf('day');
                      //return current > moment().subtract(1, 'day');
                    }}/>
                  <DatePicker
                    key="3"
                    style={{...styles.datePickerStyle, ...styles.hide}}
                    onChange={trxDatePicker2}
                    value={trxDateTo}
                    ref={reportDateTo}
                    placeholder={"Select Date To"}
                    disabledDate={(current)=> {
                      // let dateFr = trxDateFrom;
                      // if (dateFr) {
                      //   return current < dateFr || current > moment().add(1, 'day');
                      //   //return current < dateFr || current > moment().subtract(1, 'day');
                      // }else {
                      //   return  current > moment().subtract(1, 'day');
                      // }
                    }}
                    defaultPickerValue={()=>{
                        // let dateFr = trxDateFrom;
                        // if (dateFr) {
                        //     return dateFr;
                        // }
                    }}
                    />
                    <Button
                      type={"primary"}
                      style={{...styles.searchResetStyle,...styles.hide}}
                      icon={<SearchOutlined />}
                      //onClick={searchBar}
                    />
                    <Button
                      type={"primary"}
                      style={{...styles.searchResetStyle,...styles.refreshBtn, ...styles.hide}}
                      //onClick={clearTrxnSearch}
                      >
                      Reset   
                    </Button>             
              </Row> */}
              <Row gutter={[8, 8]} style={{marginBottom:"10px"}}>
                  <Col span={20}>
                      <p style={{float:"left"}} style={style.noHighlight}>User Mobile : {memberPhoneNo}&nbsp;&nbsp;&nbsp;<DepositAmt Amount={pptAmt}/></p>
                  </Col>
                  <Col span={4} style={{float:"right"}}>
                        <Button 
                        loading={loadings[1]}
                        style={style.refreshBtn,{height:"30px"}}
                        type="primary"
                        onClick={() => {
                          //approvePPTTrxn(abb,1)
                          approvePPTTrxn1(1);
                        }}
                        >
                          {btnApproveMemberText}
                        </Button>
                  </Col>     
              </Row>
              <Row style={{marginBottom:"10px"}}>
                    Transactions shown are 1 day before / after the Unclaimed / Bank Conflict "date time"
              </Row>
              <Table
                  loading={trxLoading}
                  columns ={memberTrxnColumns}
                  dataSource= {filteredPptTrxn }
                  scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                  pagination={{
                    defaultPageSize: 5,
                    showSizeChanger: true,
                    pageSizeOptions: settings.pageSizeOptions,
                    style: style.paginationStyle,
                    alignmentBottom: 'right',
                  }}
                  />
              </>
              // ):""
            }
          </>
          </div>
      </Modal>
    </div>
  );
}
