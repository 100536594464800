const PPT_RM = {
    DELAY_DEP : 'SYS_DELAYED_DEPOSIT' //Delayed Deposit
}

const OP_FILTER = {
    DEPOSIT : 'Deposit',
    LOGIN   : 'Login',
}

const PV_VALUE= {
    LAST_DEP_FROM   : 'LastDepDateFrom',
    LAST_DEP_TO     : 'LastDepDateTo',
    LAST_LOGIN_FROM : 'LastLoginFrom',
    LAST_LOGIN_TO   : 'LastLoginTo',
}

const REF_COMM_SEARCH_MOBILE = 'Mobile';

const RELOAD_CTR = 40;

const BO_OFFICE = 'Back Office'

const COMM_WIN_LOSE = 'WL'
const COMM_TURNOVER = 'TO'
const INCENTIVE_WL = 'WINLOSS'
const INCENTIVE_ALL_DEP = 'ALL_DEP'
const INCENTIVE_FIRST_DEP = 'FIRST_DEP'

const USER_TYPE_SHAREHOLDER = 'Shareholder'
const USER_TYPE_AGENT = 'Agent'

const WALLET_TO_BANK = 'wallet2bank'
const COMM_TO_BANK = 'comm2bank'
const COMM_TO_WALLET = 'comm2wallet'


export {
    PPT_RM, 
    OP_FILTER, 
    PV_VALUE, 
    REF_COMM_SEARCH_MOBILE, 
    RELOAD_CTR,
    BO_OFFICE,
    COMM_WIN_LOSE,
    COMM_TURNOVER,
    INCENTIVE_WL,
    INCENTIVE_ALL_DEP,
    INCENTIVE_FIRST_DEP,
    USER_TYPE_SHAREHOLDER,
    USER_TYPE_AGENT,
    WALLET_TO_BANK,
    COMM_TO_BANK,
    COMM_TO_WALLET
    
};
