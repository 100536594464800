import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  Form,
  DatePicker,
  Spin,
  Select,
  Button,
  Modal,
  message
} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { gameProviderVw } from "../../../library/permission";
import style from "../../../resource/style/style";
import momentTz from "moment-timezone";
import { getGameProviderList, updateGameProvider } from "../../../config/networkConfig";
import { GAMEPROVIDER_STATUS } from "../../../common/constants/gameProvider";
import { CNotAuthorized } from "../../../common/components/CNotAuthorized";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";

export default function GameProviderManagement() {
  const { role, username  } = useSelector((state) => state.login);
  const { Option } = Select;

  //useRef to prevent not insert start date or end date
  const refMaintainStartDate                        = useRef(null);
  const refMaintainEndDate                          = useRef(null);


  const [statementData, setStatementData ] = useState("");
  const [gameGroup, setGameGroup ] = useState("");
  const [gameGroupName, setGameGroupName ] = useState("");
  const [apiAgentKey, setApiAgentKey ] = useState("");
  const [maintainStatus, setMaintainStatus ] = useState("");
  const [currency, setCurrency ] = useState("");
  const [statTableLoading, setStatTableLoading ] = useState(false);
  
  // Old state
  const [maintainStartDate, setMaintainStartDate ] = useState("");
  const [maintainEndDate, setMaintainEndDate ] = useState("");

  // new state
  const [newMaintainStatus, setNewMaintainStatus ] = useState("0");
  const [newMaintainStartDate, setNewMaintainStartDate ] = useState("");
  const [newMaintainEndDate, setNewMaintainEndDate ] = useState("");
  
  // Model state
  const [modelVisible, setModelVisible ] = useState(false);
  const [showDate, setShowDate ] = useState(false);
  
  useEffect(() => {
    getGameProvider();
  }, []);

  const hideDialog = async (record) =>{
    setModelVisible(false);
  }  

  const trxDatePicker = (date, dateString) => {
    setNewMaintainStartDate(date);
  };

  const trxDatePicker1 = (date, dateString) => {
    setNewMaintainEndDate(date);
  };

  const handleMaintainStatus = (value) => {
    setNewMaintainStatus(value);
    setShowDate(value == "Active" ? true : false);
  }

  const setTimeDialog = () => {
    return ( 
      <Form >
        <Form.Item label="Game Group Name">
          <span>
            {gameGroup}
          </span>
        </Form.Item>
        <Form.Item label="Maintain Status">
          <span>
          <Select value={newMaintainStatus} onChange={handleMaintainStatus}>
            <Option value="Active">Active</Option>
            <Option value="Inactive">Inactive</Option>      
          </Select>
          </span><br/>
        </Form.Item>
        <Form.Item label="Start Date" style={{"display": newMaintainStatus=="Active"?"":"none"}}>
                <DatePicker
                  key="1"
                  style={{...style.datePickerStyle, ...style.refreshBtn}}
                  format="YYYY-MM-DD HH:mm"
                  showTime
                  ={{
                    defaultValue: moment("00:00", "HH:mm"),
                    format: "HH:mm"
                  }}
                  onChange={trxDatePicker}
                  value={newMaintainStartDate}
                  placeholder={"Select Date From"}
                  disabledDate={(current)=> {
                    return current < moment().endOf('day').subtract(1 , 'day');
                  }}
                  placement={"bottomRight"}
                  ref={refMaintainStartDate}             
                />
        </Form.Item>
        <Form.Item  label="End Date" style={{"display": newMaintainStatus=="Active"?"":"none"}}>
          <DatePicker 
                          
                          key="2"
                          style={{...style.datePickerStyle, ...style.refreshBtn}}
                          format="YYYY-MM-DD HH:mm"
                          showTime
                          ={{
                            defaultValue: moment("00:00", "HH:mm"),
                            format: "HH:mm"
                          }}
                          onChange={trxDatePicker1}
                          value={newMaintainEndDate}
                          placeholder={"Select Date To"}
                          disabledDate={(current)=> {
                            let dateFr = moment(newMaintainStartDate,'YYYY-MM-DD');
                            current =  moment(current,'YYYY-MM-DD');
                            if (dateFr) {
                              return current < dateFr;
                            }else {
                              return current < moment().subtract(1, 'day');
                            } 
                          }}
                          placement={"bottomRight"}
                          ref={refMaintainEndDate}
                          defaultPickerValue={()=>{
                            let dateFr = moment(newMaintainStartDate,'YYYY-MM-DD');
                            if (dateFr) {
                                return dateFr;
                            }
                        }}
                          
                        /> 
          
        </Form.Item>
        {/* <Form.Item>
          <Button type="primary" onClick={updateGameProviderList}>Submit</Button>
          <Button onClick={() => setModelVisible(false)}>Cancel</Button>
        </Form.Item> */}
      </Form>
    )
  }

  let statColumns =  [
    {
      align: "center",
      title: <div>Game Group Name</div>,
      dataIndex:"gameGroupName",
    },
    // {
    //   align: "center",
    //   title: <div>Currency</div>,
    //   dataIndex:"currency",
    // },
    {
      align: "center",
      title: <div>Maintain Status</div>,
      dataIndex:"maintainStatus",  
        render: (text, record)=>{
          let startDate = record.maintainStatus == "1" ? 
          record.newMaintainStatus = "Active" : "Inactive";
          return <span>{startDate}</span>;
        }  
    },
    {
      align: "center",
      title: <div>Start Date</div>,
      dataIndex:"maintainStartDate",
        render: (text, record)=>{
          let startDate = record.maintainStatus == "1" ? 
          record.maintainStartDate : "";
          return <span>{startDate}</span>;
       }
    },
    { 
      align: "center",
      title: <div>End Date</div>,
      dataIndex:"maintainEndDate",
      render: (text, record)=>{
        let endDate = record.maintainStatus == "1" ? 
          record.maintainEndDate : "";
          return <span>{endDate}</span>;
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Action",
      dataIndex: "",
      width: "200px",
      render: (text, record) => {
        return (<div><Button type="danger" onClick={ (e) => {
          getGame(record);
        }}>Set Time</Button></div>)
      }
    }
  ]
  
  const getGame = async (record) =>{
    setGameGroup(record.gameGroup)
    setMaintainStatus(record.maintainStatus)
    setMaintainStartDate((!record.maintainStartDate)? "":moment(record.maintainStartDate))
    setMaintainEndDate((!record.maintainEndDate)? "":moment(record.maintainEndDate))
    setNewMaintainStatus(record.maintainStatus=="1"? "Active":"Inactive")
    setNewMaintainStartDate((!record.maintainStartDate)? "":moment(record.maintainStartDate))
    setNewMaintainEndDate((!record.maintainEndDate)? "":moment(record.maintainEndDate))
    setCurrency(record.currency)
    setApiAgentKey(record.apiAgentKey)
    setModelVisible(true);
  }

  const getGameProvider = async () => {

    let requestData = {
      Currency : "THB",
    }
    setStatTableLoading(true);
    let countDL = 0;
    const responseDL = await getGameProviderList(requestData);
      let resultDL = await responseDL.json();
      if (resultDL.status === "ok") {
        const dlData = Object.keys(resultDL.data).length
        ? resultDL.data.map((obj) => {
             setStatTableLoading(false);
            return {
                key               : ++countDL,
                gameGroup         : obj.GameGroup,
                gameGroupName     : obj.GameGroupName,
                currency          : obj.Currency,
                apiAgentKey       : obj.ApiAgentKey,        
                maintainStatus    : obj.MaintainStatus,
                maintainStartDate : obj.MaintainStartDate,
                maintainEndDate   : obj.MaintainEndDate
            };
          })
        : [];

        let filteredGameProvider = ['AWC','GS','JOKER'];

        let newData = dlData;
        newData = newData.filter(data => filteredGameProvider.includes(data.gameGroup));

        setStatementData(newData);

      }
      else {
        alertData.title = "Failed";
        alertData.msg = resultDL.msg;
        sharedFailAlert(alertData);
      }

      setStatTableLoading(false);
  }
  
  //update Maintenance Time
  const updateGameProviderList = async () => {

    if (newMaintainStatus.toLowerCase()=="active"){
      if (!newMaintainStartDate) {
        refMaintainStartDate.current.focus();
        alertData.title = "Error";
        alertData.msg = "Please select Maintenance Date From !";
        sharedErrorAlert(alertData);
        return;
      }

      if (!newMaintainEndDate) {
        refMaintainEndDate.current.focus();
        alertData.title = "Error";
        alertData.msg = "Please select Maintenance Date To !";
        sharedErrorAlert(alertData);
        return;
      }
    }

    let StartTime = momentTz.tz(moment(newMaintainStartDate,"YYYY-MM-DD HH:mm:ss"), "Asia/Kuala_Lumpur");
    let EndTime = momentTz.tz(moment(newMaintainEndDate,"YYYY-MM-DD HH:mm:ss"), "Asia/Kuala_Lumpur");
    let MaintainStatus;
    if(newMaintainStatus.toLowerCase() == "active"){
      MaintainStatus = GAMEPROVIDER_STATUS.ACTIVE;
    }else if (newMaintainStatus.toLowerCase() == "inactive"){
      MaintainStatus = GAMEPROVIDER_STATUS.INACTIVE;
    }
    
    let requestData = {
      Username          : username,
      GameGroup         : gameGroup,
      Currency          : currency,
      ApiAgentKey       : apiAgentKey,
      NewMaintainStatus : MaintainStatus.toString(),
      NewMaintainStartDate : StartTime.format("YYYY-MM-DD HH:mm:00"),
      NewMaintainEndDate   : EndTime.format("YYYY-MM-DD HH:mm:00"),
    };

    const response = await updateGameProvider(requestData);
    let result = await response.json();
    if (result.status === "ok") {
      alertData.title = "Success";
      alertData.msg = result.msg;
      sharedSuccessAlert(alertData);
      setModelVisible(false);
      getGameProvider();
    }
    else {
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
    }
  }

  return (
    <div style={{marginTop:"15px", marginBottom:"30px"}} className="cs-report-monitor">
       {
         gameProviderVw(role) ?
         <>
            <Table
              style={{marginTop:"10px"}}
              columns={statColumns}
              dataSource={statementData}
              pagination={false}
              loading={statTableLoading}
            />
            <Modal visible={modelVisible}
              onCancel ={hideDialog}
              footer={[
                  <Button
                    type={"primary"}
                    onClick={()=> {
                      hideDialog()
                    }}
                  >
                    Cancel
                  </Button>
                  ,
                    <Button 
                        type={"danger"} 
                        onClick={() => {
                          updateGameProviderList();
                        }}
                    >
                    Submit
                  </Button>
              ]}
              maskClosable={false}
            >
                  {setTimeDialog()}
            </Modal> 
         </>:<CNotAuthorized/>
       }

      </div>

  );
}
