import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Form,
  Button,
  Modal,
  Badge,
  PageHeader,
  message,
  Select,
  Spin,
  DatePicker
} from "antd";

import {
  EditOutlined,
  SearchOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import {
  adultMovieList,
  getAdultMovieById,
  adultMovieNew,
  adultMovieDelete,
} from "../../config/networkConfig";
import { isMobile } from "react-device-detect";
import { settings } from "../../config/appConfig";
import moment from "moment";
const dateFormat = "DD MMMM, YYYY";

export default function AdultMovie() {
  const [form] = Form.useForm();
  const modalForm = useRef();
  const [movieData, setMovieData] = useState([]);
  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [tableLoadings, setTableLoadings] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const { Option } = Select;

  // movie
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const [titleEn, setTitleEn] = useState("");
  const [titleTh, setTitleTh] = useState("");
  const [attress, setAttress] = useState("");
  const [releaseDate, setReleaseDate] = useState(null);
  const [releaseDateEdit, setReleaseDateEdit] = useState(null)
  const [tag, setTag] = useState("");
  const [tagth, setTagTh] = useState("");
  const [descEn, setDescEn] = useState("");
  const [descTh, setDescTh] = useState("");
  const [duration,setDuration] = useState("");
  const [cover,setCover] = useState("");
  const [contentURL, setContentURL] = useState("");
  const [status, setStatus] = useState("Enabled");
  const [option, setOption] = useState("recent");
  const [movieId, setMovieId] = useState();
  const [deleteVisible, setDeleteVisible] = useState(false);
  const refReleaseDate = useRef(null);


  useEffect(() => {
    moviesList();
  }, []);


  const clearAllFields = () => {

    // movie
    setId()
    setTitleEn("")
    setTitleTh("")
    setAttress("")
    setTag("")
    setTagTh("")
    setReleaseDate("")
    setDescEn("")
    setDescTh("")
    setDuration("")
    setCover("")
    setContentURL("")
    setStatus("Enabled")
    setOption("recent")

  }

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  };

  const onCreateMovieRecord = () =>{
    return(
      <Form
        form={form}
        ref={modalForm}
        {...layout}
        name="createMovieRecord"
        initialValues={{remember: false}}
      >
        <Form.Item label="Title (EN)">
        <Input
            allowClear
            value={titleEn}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Movie Title (EN)"
            // rules={[{required:true, message:'Please input the title of the movie'}]}
            onChange = {(e) => {
              setTitleEn(e.target.value);
            }}
          />
          {/* <br/>
          <span>Maximum 30 characters</span> */}
        </Form.Item>
        <Form.Item label="Title (TH)" label="Title (TH)" name="title-th" rules={[{required:true, message:'Please input the title of the movie in Thai'}]} >
        <Input
            allowClear
            value={titleTh}
            style={{width:"470px"}}
            placeholder="Movie Title (TH)"
            onChange = {(e) => {
              setTitleTh(e.target.value);
            }}
          />
          {/* <br/>
          <span>Maximum 30 characters</span> */}
        </Form.Item>
        <Form.Item label="Attress">
        <Input
            allowClear
            value={attress}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Name of the atress"
            // rules={[{required:true, message:'Please input the title of the movie'}]}
            onChange = {(e) => {
              setAttress(e.target.value);
            }}
          />
         <br/>
          <span>Please Separate the name of the attress by comma i.e. Momo Sakura, Neo Akari</span>
        </Form.Item>
        <Form.Item label="Tag (En)">
        <Input
            allowClear
            value={tag}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Tag of the adult movie"
            // rules={[{required:true, message:'Please input the title of the movie'}]}
            onChange = {(e) => {
              setTag(e.target.value);
            }}
          />
        <br/>
        <span>Separate the tag with comma i.e. <span style={{color: "red"}}>Affair, Cuckold, Married Woman, Risky Mosaic, Slut, Solowork</span></span>

        </Form.Item>

        <Form.Item label="Tag (Th)">
        <Input
            allowClear
            value={tagth}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Tag of the adult movie in Thai"
            // rules={[{required:true, message:'Please input the title of the movie'}]}
            onChange = {(e) => {
              setTagTh(e.target.value);
            }}
          />
          <br/>
          <span>Separate the tag with comma i.e. <span style={{color: "red"}}>เรื่อง, สามีซึ่งภรรยามีชู้, ผู้หญิงที่แต่งงานแล้ว, โมเสกเสี่ยง, ดอกทอง</span></span>

        </Form.Item>
        <Form.Item label="Release Date" name="releasedate" rules={[{required:true, message:'Please input the release date of the movie. If not applicable, fill in today date.'}]}>
            <DatePicker
              style={{width:"470px"}}
              format={dateFormat}
              onChange={trxDatePicker}
              placeholder={"Select The Movie Release Date"}
              />
              <br/>
            <span>If not applicable, select today date.</span>
        </Form.Item>
        <Form.Item label="Description (EN)">
        <Input.TextArea
            allowClear
            value={descEn}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Description (EN)"
            // rules={[{required:true, message:'Please input the description in English'}]}
            rows={4}
            onChange = {(e) => {
              setDescEn(e.target.value);
            }}
          />
          {/* <br/>
          <span>If not applicable input <b>N/A</b></span> */}
        </Form.Item>
        <Form.Item label="Description (TH)" name="desc-th" rules={[{required:true, message:'Please input the description of the movie in Thai'}]}>
        <Input.TextArea
            allowClear
            value={descTh}
            // maxLength={150}
            rows={4}
            style={{width:"470px"}}
            placeholder="Description (TH)"

            onChange = {(e) => {
              setDescTh(e.target.value);
            }}
          />
           <br/>
          <span>If not applicable input <b>N/A</b></span>
        </Form.Item>
        <Form.Item label="Duration">
        <Input
            allowClear
            value={duration}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G. 2h 19m"
            rules={[{required:true, message:'Please enter the duration'}]}
            onChange = {(e) => {
              setDuration(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Cover" name="cover" rules={[{required:true, message:'Please add the url for the cover'}]}>
        <Input
            allowClear
            value={cover}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G.  https://upload.wikimedia.org/wikipedia/en/d/d9/Avengers_Infinity_War_soundtrack_cover.jpg"
            rules={[{required:true, message:'Please enter the link of the movie\'s cover'}]}
            onChange = {(e) => {
              setCover(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Content URL" name="contentURL" rules={[{required:true, message: "Please input the url for the adult movie."}]}>
        <Input.TextArea
            allowClear
            value={contentURL}
            // maxLength={150}
            placeholder="E.G.  https://taokaebet.club/embed/1Sg2igr/"
            // rules={[{required:true, message:'Please enter the URL of the movie.'}]}
            onChange = {(e) => {
              setContentURL(e.target.value);
            }}
            // rows={4}
          />
          <br/>
          <span>Please input a valid URL, i.e. https://taokaebet.club/embed/1Sg2igr/</span>
        </Form.Item>
        <Form.Item label="Status">
            <Select value={status}
                defaultValue="Enabled"
                onChange={(e)=>{setStatus(e)}}>
                <Option value="1">Enabled</Option>
                <Option value="0">Disabled</Option>
            </Select>
        </Form.Item>
        <Form.Item label="Label">
        <Select value={option}
                defaultValue="new"
                onChange={(e)=>{setOption(e)}}>
                <Option value="recent">recent</Option>
                <Option value="hot">hot</Option>
                <Option value="hot, recent">hot, recent</Option>
            </Select>
        </Form.Item>
      </Form>

    )
  }


  const onEditMovieDetail = () =>{
    return(
      <Form
        form={form}
        ref={modalForm}
        {...layout}
        name="editMovieRecord"
        initialValues={{remember: false}}
      >
        <Form.Item label="Movie ID">
          <Input value={id} disabled/>
        </Form.Item>
        <Form.Item label="Title (EN)">
        <Input
            allowClear
            value={titleEn}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Movie Title (EN)"
            // rules={[{required:true, message:'Please input the title of the movie'}]}
            onChange = {(e) => {
              setTitleEn(e.target.value);
            }}
          />
          {/* <br/>
          <span>Maximum 30 characters</span> */}
        </Form.Item>
        <Form.Item label={"Title (Th)"} name="title-th-edit" rules={[{required:true, message:'Please input the description of the movie in Thai'}]}>
        <Input
            allowClear
            value={titleTh}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Movie Title (TH)"
            rules={[{required:true, message:'Please input the title of the movie in Thai'}]}
            onChange = {(e) => {
              setTitleTh(e.target.value);
            }}
          />
           <br/>
          <span>Please input the movie title in Thai</span>
        </Form.Item>
        <Form.Item label="Attress">
        <Input
            allowClear
            value={attress}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Attress Name"
            // rules={[{required:true, message:'Please input the title of the movie'}]}
            onChange = {(e) => {
              setAttress(e.target.value);
            }}
          />
          <br/>
          <span>Please Separate the name of the attress by comma i.e. Momo Sakura, Neo Akari</span>
        </Form.Item>
        <Form.Item label="Tag (En)">
        <Input
            allowClear
            value={tag}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Tag of the adult movie"
            // rules={[{required:true, message:'Please input the title of the movie'}]}
            onChange = {(e) => {
              setTag(e.target.value);
            }}
          />
          <br/>
          <span>Separate the tag with comma i.e. Affair, Cuckold, Married Woman, Risky Mosaic, Slut, Solowork</span>
        </Form.Item>
        <Form.Item label="Tag (TH)">
        <Input
            allowClear
            value={tagth}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Tag of the adult movie in Thai"
            // rules={[{required:true, message:'Please input the title of the movie'}]}
            onChange = {(e) => {
              setTagTh(e.target.value);
            }}
          />
          <br/>
          <span>Separate the tag with comma i.e. เรื่อง, สามีซึ่งภรรยามีชู้, ผู้หญิงที่แต่งงานแล้ว, โมเสกเสี่ยง, ดอกทอง</span>
        </Form.Item>
        <Form.Item label="Release Date Reference">
          <Input value={releaseDate} name="referenceReleaseDate" disabled/>
        </Form.Item>
        <Form.Item label="Release Date" name="editReleaseDate" rules={[{required:true, message:'Please input the release date of the movie. If not applicable, fill in today date.'}]}>
        <DatePicker
              id="releasedateEdit"
              style={{width:"470px"}}
              format={dateFormat}
              onChange={trxDatePickerEdit}
              ref={refReleaseDate}
              value={releaseDateEdit}
              placeholder={"Select The Movie Release Date"}
            />
        </Form.Item>

        <Form.Item label="Description (EN)">
        <Input.TextArea
            allowClear
            value={descEn}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Description (EN)"
            rows={4}
            // rules={[{required:true, message:'Please input the description in English'}]}
            onChange = {(e) => {
              setDescEn(e.target.value);
            }}
          />
          {/* <br/>
          <span>If not applicable input <b>N/A</b></span> */}
        </Form.Item>
        <Form.Item label="Description (TH)" name="desc-th-edit" rules={[{required:true, message:'Please input the description in Thai'}]}>
        <Input.TextArea
            allowClear
            value={descTh}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Description (TH)"

            rows={4}
            onChange = {(e) => {
              setDescTh(e.target.value);
            }}
          />
          <br/>
          <span>Please input the description of the movie in Thai</span>
        </Form.Item>
        <Form.Item label="Duration">
        <Input
            allowClear
            value={duration}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G. 2h 19m"
            // rules={[{required:true, message:'Please enter the duration'}]}
            onChange = {(e) => {
              setDuration(e.target.value);
            }}
          />
          {/* <br/>
          <span>If not applicable input <b>N/A</b></span> */}
        </Form.Item>

        <Form.Item label="Cover" name="edit-cover" rules={[{required:true, message:'Please add the url for the cover'}]}>
        <Input
            allowClear
            value={cover}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G.  https://upload.wikimedia.org/wikipedia/en/d/d9/Avengers_Infinity_War_soundtrack_cover.jpg"
            rules={[{required:true, message:'Please enter the link of the movie\'s cover'}]}
            onChange = {(e) => {
              setCover(e.target.value);
            }}
          />
           <br/>
          <span>Please input a valid URL, i.e. https://cdn.javsts.com/wp-content/uploads/2018/05/pppd663pl.jpg</span>
        </Form.Item>
        <Form.Item label="Content URL" name="edit-contentURL" rules={[{required:true, message: "Please input the url for the adult movie."}]}>
        <Input.TextArea
            allowClear
            value={contentURL}
            // maxLength={150}
            placeholder="E.G.  https://taokaebet.club/embed/1Sg2igr/"
            // rules={[{required:true, message:'Please enter the URL of the movie.'}]}
            onChange = {(e) => {
              setContentURL(e.target.value);
            }}
            // rows={4}
          />
          <br/>
          <span>Please input a valid URL, i.e. https://taokaebet.club/embed/1Sg2igr/</span>
        </Form.Item>
        <Form.Item label="Status">
            <Select value={status}
                defaultValue="Enabled"
                onChange={(e)=>{setStatus(e)}}>
                <Option value="1">Enabled</Option>
                <Option value="0">Disabled</Option>
            </Select>
          {/* <br/>
          <span>Default SubType will be set to <b>หนังบู๊</b></span> */}
        </Form.Item>
        <Form.Item label="Label">
        <Select value={option}
                defaultValue="recent"
                onChange={(e)=>{setOption(e)}}>
                <Option value="recent">recent</Option>
                <Option value="hot">hot</Option>
                <Option value="hot, recent">hot, recent</Option>
            </Select>
          {/* <br/>
          <span>If not applicable input <b>N/A</b></span> */}
        </Form.Item>
      </Form>

    )
  }


  ////#region Populate List functions
  const moviesList = async () =>{
    let count = 0; // to set key for display
    setTableLoadings(true);
    const resMovie = await adultMovieList({data:null});
    let res = await resMovie.json();
    // console.log("movie res", res)
    if (res.status === "ok" && res.msg != "No record."){
      if(Object.keys(res.data).length > 0){
        setTableLoadings(false);
        let data = res.data.map((x)=>{
          return{
            key: count++,
            id: x.id,
            titleEn: x.titleen,
            titleTh: x.titleth,
            descEn: x.descen,
            descTh: x.descth,
            status: x.status == 0?
            "Disabled": "Enabled"
          }
        })
        setMovieData(data);
        setAddVisible(false);
      }else {
        setTableLoadings(false);
        message.error(res.msg);
      }
    }else{
      message.error( "An unexpected error occurred. Contact your system administrator ");
    }
  }


  ////#endregion Populate

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
        <Input allowClear={true} />

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const showModal = async () => {
    setAddVisible(true);
  };



  const confirmDel = async () => {
    setDeleteVisible(false);
    setLoading(true)
    const id = movieId;
    const tmp = await adultMovieDelete({id: id})
    let res = await tmp.json();
    setLoading(false)
    if (res.status === "ok" ){
      moviesList()
      message.success(res.msg);
    }else{
      message.error( "An unexpected error occurred. Contact your system administrator ");
    }
  }
  const deleteMovie = async (record) =>{
    setDeleteVisible(true);
    setMovieId(record.id);
  }

  const editMovie = async (record) => {
    // // console.log('record',record)
    const movieId = record.id;
    // // console.log('movieId',movieId)
    setLoading(true);
    // loadingScreen(true);
    const tmp = await getAdultMovieById({movieId: movieId});
    let res = await tmp.json();
    setLoading(false)
    console.log("detail movie", res)
    if (res.status === "ok" ){
        let data = res.data.map((x)=>{
          return{
            id: x.id,
            titleEn: x.titleen,
            titleTh: x.titleth,
            releaseDate: x.releaseDate,
            descEn: x.descen,
            descTh: x.descth,
            duration: x.duration,
            attress: x.attress,
            tag: x.tagen,
            tagth: x.tagth,
            cover: x.cover,
            contentURL: x.contentURL,
            options: x.option,
            status: x.status == 0?
            "Disabled": "Enabled"
          }
        })

        let movie = data[0]
        setId(movie.id)
        setTitleEn(movie.titleEn)
        setTitleTh(movie.titleTh)
        setTag(movie.tag)
        setTagTh(movie.tagth)
        setReleaseDate(movie.releaseDate)
        setAttress(movie.attress)
        setDescEn(movie.descEn)
        setDescTh(movie.descTh)
        setDuration(movie.duration)
        setCover(movie.cover)
        setContentURL(movie.contentURL)
        setStatus(movie.status)
        setOption(movie.options)
        // loadingScreen(false)
        setEditVisible(true);

      }else{
        message.error( "An unexpected error occurred. Contact your system administrator ");
      }

  }

  let searchInput = null;



  // classNames= {
  //   show: { display: 'block' },
  //   hide: { display: 'none' }
  // }

  const movie_column = [
    {
        title: "ID",
        dataIndex: "id",
        className: "hide"
    },
    {
      title: "Title (EN)",
      dataIndex: "titleEn",
      // width: "20%",
    },
    {
      title: "Title (TH)",
      dataIndex: "titleTh",
      // width: "15%",
    },
    {
      title: "Description  (EN)",
      dataIndex: "descEn",
      // className: { display: 'none' }
      // width: "15%",
    },
    {
      title: "Description (TH)",
      dataIndex: "descTh",
      // width: "15%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "20%",
      render: (text)=> {
          if (text=="Disabled") {
            return <Badge status="error" text="Disabled"/>;
          }else{
            return <Badge status="success" text="Enabled" />;
          }
      },
      filters: [
        {
          text: 'Disabled',
          value: 'Disabled',
        },

        {
          text: 'Enabled',
          value: 'Enabled',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.status.indexOf(value) === 0
    },
    ,
    {
      title: "Action",
      dataIndex: "",
      width: "10%",
      render: (text, record)=> {
        return (
          <>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => {
              // editCampaign(record);
              editMovie(record);

            }}
          />
          &nbsp;
          <Button
            type="danger"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={() => {
              // editCampaign(record);
              deleteMovie(record);

            }}
          />
          </>
        )
      }
    }
  ]


 const handleEdit = async(index)=> {
  if(!titleTh){
    message.error('Please fill in the movie title for Thai version');
    return;
  }

  if(!descTh){
    message.error('Please fill in the description for the movie for Thai version');
    return;
  }

  if(!releaseDate){
    message.error('Please fill in the release date for the movie. If not applicable, fill in today date.');
    return;
  }

  if(tag.indexOf(',') ===-1 && tag.split(' ').length > 1){
    message.error('Please separate the tag with comma i.e. a,b,c,...,z ');
    return;
  }

  if(tagth.indexOf(',') ===-1 && tagth.split(' ').length > 1){
    message.error('Please separate the tag with comma i.e. a,b,c,...,z in thai');
    return;
  }

  if(attress.indexOf(',') ===-1 && attress.split(' ').length > 2){
    message.error('Please separate the name of the attress with comma i.e. a,b,c,...,z ');
    return;
  }


  if(!isValidHttpUrl(cover)){
      message.error('Please enter the valid web address for the movie\'s cover');
    return;
  }

  if(!isValidHttpUrl(contentURL)){
      message.error('Please enter the valid web address for the movie\'s content');
    return;
  }

  setLoading(true)
  let stat;
  // // console.log('type of status', typeof status)
  if(!parseInt(status)){
    stat = status == 'Enabled' ? 1 : 0;
  }else{
    stat = parseInt(status);
  }
  // let stat = status == "Enabled" ? 1 : 0;
    let requestData = {
      "id": id,
      "titleen": titleEn,
      "titleth": titleTh,
      "releasedate": releaseDateEdit,
      "descen": descEn,
      "descth": descTh,
      "duration": duration,
      "attress": attress,
      "tag": tag,
      "tagth": tagth,
      "cover": cover,
      "contenturl": contentURL,
      "status": stat,
      "option": option
    }


  adultMovieNew(requestData)
  .then((response) => response.json())
  .then((json) => {
    setEditVisible(false);
    if (json.status === "ok") {
      setTimeout(()=>{
        setLoading(false);
        moviesList()
        message.success(json.msg);
        clearAllFields();
      }, 2000)

    } else {
      loadingTrue(index);
      message.error(json.msg);
    }
    loadingFalse(index);
    clearAllFields();
    form.resetFields(['editReleaseDate']);
  })
  .catch((error) => {
    // console.log("error: ", error);
    message.error(
      "An unexpected error occurred. Contact your system administrator "
    );
  });
 }

  const handleAdd = (index) => {

    if(!titleTh){
      message.error('Please fill in the movie title for Thai version');
      return;
    }

    if(!descTh){
      message.error('Please fill in the description for the movie for Thai version');
      return;
    }

    if(!releaseDate){
      message.error('Please fill in the release date for the movie. If not applicable, fill in today date');
      return;
    }

    if(tag.indexOf(',')===-1 && tag.split(' ').length > 1){
        message.error('Please separate the tag with comma i.e. a,b,c,...,z ');
        return;
    }

    if(tagth.indexOf(',')===-1 && tag.split(' ').length > 1){
      message.error('Please separate the tag with comma i.e. a,b,c,...,z ');
      return;
    }

    if(attress.indexOf(',') ===-1 && attress.split(' ').length > 1){
    message.error('Please separate the tag with comma i.e. a,b,c,...,z ');
    return;
  }

    if(!isValidHttpUrl(cover)){
        message.error('Please enter the valid web address for the movie\'s cover');
      return;
    }

    if(!isValidHttpUrl(contentURL)){
        message.error('Please enter the valid web address for the movie\'s content');
      return;
    }

    // loadingTrue(index);
    setLoading(true)

    // let fbPxStatus = FBPixelStatus =="Enabled" ? "1": "0" ;
    let stat = status == "Enabled" ? 1 : 0;
    let requestData = {
        "id": id,
        "titleen": titleEn,
        "titleth": titleTh,
        "releasedate": releaseDate,
        "descen": descEn,
        "descth": descTh,
        "duration": duration,
        "attress": attress,
        "tag": tag,
        "tagth": tagth,
        "cover": cover,
        "contenturl": contentURL,
        "status": stat,
        "option": option
      }


    adultMovieNew(requestData)
      .then((response) => response.json())
      .then((json) => {
        setAddVisible(false);
        if (json.status === "ok") {
          // campaignList();
          // setTimeout(()=>{
            setLoading(false)
            moviesList()
            message.success(json.msg);
            clearAllFields();

          // }, 2000)

        } else {
          loadingTrue(index);
          message.error(json.msg);
          clearAllFields();
          // form.resetFields();
        }
        loadingFalse(index);
        setId()
        setTitleEn("")
        setTitleTh("")
        setReleaseDate("")
        setDescEn("")
        setDescTh("")
        setDuration("")
        setTag("")
        setTagTh("")
        setAttress("")
        setCover("")
        setContentURL("")
        setStatus("Enabled")
        setOption("new")
        form.resetFields(["title-th", "desc-th", "cover", "contentURL"]);
      })
      .catch((error) => {
        // console.log("error: ", error);
        message.error(
          "An unexpected error occurred. Contact your system administrator "
        );
      });
  };


    const trxDatePicker = (date, dateString) => {
        // console.log("date string",dateString)
        setReleaseDate(dateString);
    };

    const trxDatePickerEdit = (date, dateString) => {
        // console.log("date string",dateString)
        setReleaseDateEdit(dateString);
    };

    const clearDescThField = () => {
      form.resetFields(["desc-th-edit"]);
    }

    function isValidHttpUrl(string) {
        let url;

        try {
          url = new URL(string);
        } catch (_) {
          return false;
        }
        // console.log(`url protocol: ${url.protocol}`)
        return url.protocol === "http:" || url.protocol === "https:";
    }


  const onCancel = () => {
    setAddVisible(false);
    clearAllFields();

  };

  const onCancelEdit = () => {
    setEditVisible(false);
    clearAllFields();
    form.resetFields(['editReleaseDate', 'referenceReleaseDate']);
  }
  const onCancelDelete = () => {
    setDeleteVisible(false)
  }
  const styles = {
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
    },
    refreshBtn: {
      marginLeft: 10,
    },
    tabelContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    formItem: {
      width:"80%"
    },
    datePickerStyle: {
      width: 260,
    },
    hide : {
      display:"none",
    },
    memberSearchStyle:{
      width:180,
    }
  };

  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <div>
        <Modal title="New Adult Movie Record"  visible={addVisible}
        onOk={handleAdd}
        onCancel={() => {
          onCancel();
        }}
        maskClosable={false}
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "danger", loading: loadings[0], title: "Save" }}
        okText="Save"
        center
        width={850}
        >
          {/* {onCreateFBCampaign()} */}
          {onCreateMovieRecord()}
        </Modal>
        {/* <Spin></Spin> */}
        {/* {loadingScreen(loading)} */}
        <Modal
        title='Loading'
        visible={loading}
        center
        width={850}
        footer={null}>
          <Spin spinning={loading} className='container' tip='Loading ...'></Spin>
        </Modal>

        <Modal title= "Edit Adult Movie List" visible={editVisible}
        onOk={handleEdit}
        onCancel={() => {
          onCancelEdit();
        }}
        maskClosable={false}
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "danger", loading: loadings[0], title: "Save" }}
        okText="Save"
        center
        width={850}
        >
          {/* {onEditFBCampaign()} */}

          {onEditMovieDetail()}
        </Modal>
      </div>
        <Modal title='Confirmation'
        visible={deleteVisible}
        onOk={confirmDel}
        onCancel={()=>{onCancelDelete();}}
        >
          <p>Are You Confirm To Delete the Movie?</p>
        </Modal>
      <PageHeader ghost={false}
      >
        <div>
          <Button key="1" type="primary" onClick={showModal}>
            Add New Adult Movie Record
          </Button>
        </div>
      </PageHeader>
      <Form form={form} component={false}>
        <Table
          loading={tableLoadings}
          scroll={{ x: "100%", y: null }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={movieData} // originData
          columns ={movie_column} //columns
          rowClassName="editable-row"

          pagination={{
            defaultPageSize: 15,
            showSizeChanger: true,
            pageSizeOptions: settings.pageSizeOptions,
            style: styles.paginationStyle,
            alignmentBottom: 'right',
          }}
          style={{ overflow: "auto", marginLeft: 20 }}
        />
      </Form>
    </div>
  );
}
