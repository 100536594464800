import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Form,
  Button,
  Modal,
  Badge,
  message,
  PageHeader,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  RightOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  userCompanyNew,
  userCompanyList,
  userManageUpdate,
} from "../../config/networkConfig";
import md5 from "md5";
import { isMobile, isIPad13 } from "react-device-detect";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = <Input allowClear={true} />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          className="EditableCell"
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default function Withdraw() {
  const [form] = Form.useForm();
  const modalForm = useRef();
  const [originData, setOriginData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [username, setUsername] = useState("");
  const [nickname, setNickname] = useState("");
  const [password, setPassowrd] = useState("");
  const md5Pass = md5(password);
  const [searchText, setSearchText] = useState("");
  const [addVisible, setAddVisible] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [cardVisible, setCardVisible] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const isLandscape = window.orientation === 90 || window.orientation === -90;

  useEffect(() => {
    if (window.DeviceOrientationEvent) {
      window.addEventListener(
        "orientationchange",
        function () {
          window.location.reload();
        },
        false
      );
    }
    downlineList();
    // eslint-disable-next-line
  }, []);

  const downlineList = () => {
    var count = 0;
    setTableLoading(true);
    userCompanyList({})
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          setTableLoading(false);
          const newData = json.data.map((data) => {
            return {
              key: count++,
              userid: data.userId,
              username: data.username,
              nickname: data.nickname,
              lastLogin: data.lastLogin,
              registerDate: data.registerDate,
              type: `${data.shareCount}/${data.agentCount}/${data.memberCount}`,
              status:
                data.status === "0"
                  ? "Lock"
                  : data.status === "1"
                  ? "Active"
                  : "Inactive",
            };
          });

          setOriginData(newData);
          setAddVisible(false);
        } else {
          message.error(json.msg);
          setTableLoading(true);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        message.error(
          "An unexpected error occurred. Contact your system administrator "
        );
      });
  };

  const showModal = () => {
    setEditingKey("");
    setAddVisible(true);
    setUsername("");
    setPassowrd("");
    setNickname("");
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="getColumnSearchProps">
        <Input
          className="getColumnSearchInput"
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Button
          className="getColumnSearchBtn"
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
        >
          Search
        </Button>
        <Button
          className="getColumnResetBtn"
          onClick={() => handleReset(clearFilters)}
          size="small"
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined className={filtered ? "getColumnFilterBtn" : undefined} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },

    render: (text) => (
      <Highlighter
        highlightClassName="getColumnHighlighter"
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  const columns = [
    {
      ellipsis: true,
      title: "Username",
      dataIndex: "username",
      width: "150px",
      ...getColumnSearchProps("username"),
    },
    {
      ellipsis: true,
      title: "Nickname",
      dataIndex: "nickname",
      width: "200px",
      editable: true,
      ...getColumnSearchProps("nickname"),
    },
    {
      ellipsis: true,
      title: "Register Date",
      dataIndex: "registerDate",
      width: "160px",
    },
    {
      ellipsis: true,
      title: "No . of SH/AG/MB",
      dataIndex: "type",
      width: "150px",
    },
    {
      ellipsis: true,
      title: "Status",
      dataIndex: "status",
      width: "100px",
      render: (text, record) => {
        switch (record.status) {
          case "Lock":
            return <Badge status="warning" text="Lock" />;
          case "Active":
            return <Badge status="success" text="Active" />;
          default:
            return <Badge status="error" text="Inactive" />;
        }
      },
    },
    {
      ellipsis: true,
      align: "center",
      title: "Action",
      dataIndex: "operation",
      width: "200px",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="editingContainer">
            <Input
              className="editingInput"
              id={"pwd" + record.key}
              placeholder="Change Password"
              onChange={(e) => {
                setPassowrd(e.target.value);
              }}
            />
            <Button
              type="dashed"
              shape="circle"
              icon={<SaveOutlined />}
              loading={loadings[1]}
              onClick={() => {
                save(record, 1);
              }}
            />
            <Button
              type="danger"
              shape="circle"
              onClick={() => cancel(record.key)}
              icon={<CloseCircleOutlined />}
            />
          </div>
        ) : (
          <span disabled={editingKey !== ""} onClick={() => edit(record)}>
            <Button type="primary" shape="circle" icon={<EditOutlined />} />
          </span>
        );
      },
    },
  ];

  const isEditing = (record) => record.key === editingKey;

  const edit = async (record) => {
    form.setFieldsValue({
      nickname: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (record, index) => {
    const row = await form.validateFields();

    if (
      nickname === record.nickname ||
      document.getElementById("pwd" + record.key).value === ""
    ) {
      setEditingKey("");
      return;
    }
    try {
      const { nickname } = row;
      const newData = [...originData];
      const indexSave = newData.findIndex((item) => record.key === item.key);
      loadingTrue(index);
      if (indexSave > -1) {
        userManageUpdate({
          userId: record.userid,
          nickname: nickname,
          pass: md5Pass,
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status === "ok") {
              const item = newData[indexSave];
              loadingFalse(index);
              newData.splice(indexSave, 1, { ...item, ...row });
              setOriginData(newData);
              setEditingKey("");
              message.success(json.msg);
            } else {
              message.error(json.msg);
              loadingTrue(index);
            }
          })
          .catch((error) => {
            console.log("error: ", error);
            message.error(
              "An unexpected error occurred. Contact your system administrator "
            );
          });
      } else {
        message.error("Connection not stable, please try again !");
      }
    } catch (errInfo) {
      console.log("errInfo: ", errInfo);
      message.error("Connection not stable, please try again !");
    }
    loadingFalse(index);
    form.resetFields();
  };

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const handleAdd = (index) => {
    var regEx = new RegExp(/^\d*[a-z]{1,}\d*/);

    if (!username || !password || !nickname) {
      message.error(
        "An entry is required or has an invalid value. Please correct and try again!"
      );
      return;
    } else if (!regEx.test(username)) {
      message.error("Only accept lowercase characters and numbers!");
      return;
    }

    loadingTrue(index);
    userCompanyNew({
      username: username.toLowerCase().trim(),
      nickname: nickname,
      pass: md5Pass,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          downlineList();
          message.success(json.msg);
        } else {
          message.error(json.msg);
        }
        loadingFalse(index);
        modalForm.current.resetFields();
      })
      .catch((error) => {
        console.log("error: ", error);
        message.error(
          "An unexpected error occurred. Contact your system administrator "
        );
      });
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onCancel = () => {
    modalForm.current.resetFields();
    setAddVisible(false);
  };

  const cardStyle = () => {
    const newData = [...originData];

    const statusBgColor = (status) => {
      switch (status) {
        case "Active":
          return "#1DA57A";
        case "Inactive":
          return "#FF0000";
        default:
          return "#FFD700";
      }
    };

    const getUserId = Object.values(newData).map((data) => data.userid);

    const cancel = () => {
      var usernameStyle = document.getElementById("username");
      var passStyle = document.getElementById("password");
      var edit = document.getElementById("edit");
      setActiveKey("");
      setCardVisible(false);
      usernameStyle.style.border = "none";
      usernameStyle.style.padding = "0px";
      usernameStyle.style.innerHTML = "";
      passStyle.style.border = "none";
      passStyle.style.padding = "0px";
      passStyle.innerHTML = "";
      edit.innerHTML = "Edit";
    };

    const save = (index) => {
      var usernameStyle = document.getElementById("username");
      var passStyle = document.getElementById("password");
      var edit = document.getElementById("edit");

      loadingTrue(index);
      userManageUpdate({
        userId: getUserId,
        nickname: nickname,
        pass: md5Pass,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "ok") {
            loadingFalse(index);
            setCardVisible(false);
            message.success(json.msg);
            usernameStyle.style.border = "none";
            usernameStyle.style.padding = "0px";
            passStyle.style.border = "none";
            passStyle.style.padding = "0px";
            passStyle.innerHTML = "";
            edit.innerHTML = "Edit";
          } else {
            loadingTrue(index);
            message.error(json.msg);
          }
        })
        .catch((error) => {
          message.error(error);
        });
    };

    const edit = () => {
      var edit = document.getElementById("edit");
      var usernameStyle = document.getElementById("username");
      var passStyle = document.getElementById("password");
      var cancel = document.getElementById("decEditBtn");
      var save = document.getElementById("decOkBtn");

      save.style.backgroundColor = "#1DA57A";
      save.style.color = "#FFFFFF";
      save.style.border = "none";
      cancel.style.backgroundColor = "#eeebeb";
      cancel.style.color = "#000000";
      cancel.style.border = "none";
      edit.innerHTML = "Save";
      usernameStyle.contentEditable = true;
      usernameStyle.style.border = "1px solid gray";
      usernameStyle.style.paddingLeft = "10px";
      usernameStyle.style.paddingRight = "10px";
      usernameStyle.addEventListener(
        "input",
        function (e) {
          setNickname(e.currentTarget.textContent);
        },
        false
      );

      passStyle.contentEditable = true;
      passStyle.style.border = "1px solid gray";
      passStyle.style.width = "120px";
      passStyle.style.paddingLeft = "10px";
      passStyle.style.paddingRight = "10px";
      passStyle.addEventListener(
        "input",
        function (e) {
          setPassowrd(e.currentTarget.textContent);
        },
        false
      );
    };

    const ok = (e) => {
      var x = document.getElementById("edit");
      if (x.innerHTML === "Edit") {
        edit();
      } else if (x.innerHTML === "Save") {
        save(2);
      }
    };

    const details = () => {
      var newData = [...originData];
      const status = Object.values(newData).map((data) => {
        return data.status === "Active" ? (
          <Badge key="1" status="success" text="Active" />
        ) : data.status === "Inactive" ? (
          <Badge key="2" status="error" text="Inactive" />
        ) : (
          <Badge key="3" status="warning" text="Lock" />
        );
      });

      const username = Object.values(newData).map((data) => data.username);
      const type = Object.values(newData).map((data) => data.type);
      const nickname = Object.values(newData).map((data) => data.nickname);
      const registerDate = Object.values(newData).map(
        (data) => data.registerDate
      );

      return (
        <div>
          <div className="decStyle">
            <p className="decLable">Status:</p>
            <p className="decTxt">{status[activeKey]}</p>
          </div>
          <div className="decStyle">
            <p className="decLable">Username:</p>
            <p className="decTxt">{username[activeKey]}</p>
          </div>
          <div className="decStyle">
            <p className="decLable">Nickname:</p>
            <p id="username" className="decTxt" type="text">
              {nickname[activeKey]}
            </p>
          </div>
          <div className="decStyle">
            <p className="decLable">Password:</p>
            <p type="text" id="password" className="decTxt" />
          </div>
          <div className="decStyle">
            <p className="decLable">SH/AG/MG:</p>
            <p className="decTxt">{type[activeKey]}</p>
          </div>
          <div className="decStyle">
            <p className="decLable">Registered Date:</p>
            <p className="decTxt">{registerDate[activeKey]}</p>
          </div>

          <div className="decBottomBtn">
            <Button
              id="decOkBtn"
              className="decOkBtn"
              loading={loadings[2]}
              type="primary"
              onClick={() => {
                ok(2);
              }}
            >
              <span id="edit">Edit</span>
            </Button>
            <Button
              id="decEditBtn"
              className="decEditBtn"
              type="primary"
              onClick={() => {
                cancel();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    };

    const cardList = Object.values(newData).map((data) => {
      return (
        <div className="cardContainer" key={data.key}>
          <div
            className="cardStatus"
            style={{
              backgroundColor: statusBgColor(data.status),
            }}
          />
          <Button
            type="primary"
            id="cardStyle"
            className="cardStyle"
            onClick={() => {
              setCardVisible(true);
              setActiveKey(data.key);
            }}
          >
            <div>{data.status}</div>
            <div>
              <div>{data.username}</div>
              <div>SH/AG/MB: {data.type}</div>
            </div>
            <div>
              <RightOutlined />
            </div>
          </Button>
        </div>
      );
    });
    return (
      <div>
        <Modal
          key={activeKey}
          title="Detail"
          visible={cardVisible}
          footer={null}
          maskClosable={false}
        >
          {details()}
        </Modal>
        {cardList}
      </div>
    );
  };

  const landscape = () => {
    return (
      <div className="RlandContainer">
        <div className="RlandHeaderContainer">
          <PageHeader ghost={false}>
            <div className="RlandHeaderTxtContainer">
              <div className="RlandHeaderTxt">Company List</div>
            </div>
          </PageHeader>
          <div className="RlandBtnContainer">
            <Button
              type="primary"
              onClick={() => {
                window.location.reload();
              }}
            >
              Refresh Page
            </Button>
            <Button type="primary" onClick={showModal}>
              Add Company
            </Button>
          </div>
        </div>
        <Form form={form} component={false}>
          <Table
            scroll={{ x: "100%", y: null }}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            dataSource={originData}
            columns={mergedColumns}
            loading={tableLoading}
          />
        </Form>
      </div>
    );
  };

  const portrait = () => {
    return (
      <div>
        <div className="RportraitTitle">Company List</div>
        <PageHeader
          className="RportraitPageContainer"
          ghost={false}
          extra={[
            <div key="1" className="RportraitBtnContainer">
              <Button
                type="primary"
                onClick={showModal}
                className="RportraitAddCompanyBtn"
              >
                Add Company
              </Button>
            </div>,
          ]}
        >
          {cardStyle()}
        </PageHeader>
      </div>
    );
  };

  return (
    <div>
      <Modal title="New Company" visible={addVisible} footer={null}>
        <Form ref={modalForm}>
          <Form.Item
            shouldUpdate
            name={["user", "username"]}
            label="Username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
                whitespace: true,
              },
              () => ({
                validator(rule, value) {
                  var regEx = new RegExp(/^\d*[a-z]{1,}\d*/);

                  if (regEx.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "Only accept lowercase characters and numbers!"
                  );
                },
              }),
            ]}
          >
            <Input
              value={username}
              onChange={(e) => {
                setUsername(e.target.value.trim());
              }}
            />
          </Form.Item>
          <Form.Item
            name={["user", "nickname"]}
            label="Nickname"
            rules={[
              {
                required: true,
                message: "Please input your nickname!",
                whitespace: true,
              },
            ]}
          >
            <Input
              value={nickname}
              onChange={(e) => {
                setNickname(e.target.value.trim());
              }}
            />
          </Form.Item>
          <Form.Item
            name={["user", "password"]}
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
                whitespace: true,
              },
            ]}
          >
            <Input
              value={password}
              onChange={(e) => {
                setPassowrd(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item className="RAddCompanyContainer">
            <Button
              className="RAddCompanyBtn"
              htmlType="button"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={loadings[0]}
              onClick={() => {
                handleAdd(0);
              }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {isIPad13 || isLandscape
        ? landscape()
        : isMobile
        ? portrait()
        : landscape()}
    </div>
  );
}
