import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  message,
  Button,
  Modal,
  Form,
  Input,
  PageHeader,
  Tooltip,
  Badge,
  Radio,
  Row,
  DatePicker,
  Tabs,
  Card,
  Col,
  Statistic,
  Spin
} from "antd";
import { SpinProps } from 'antd/lib/spin';
import { 
  SearchOutlined,
  CheckCircleOutlined,
  StopOutlined
 } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { isMobile } from "react-device-detect";
import {
  csDepositList,
  csDepositApprove,
  csDepositReject,
  puppetTransactionList,
  puppetTrxnDetails,
  userProfileBank,
  csDepositFollowupUpdate,
  csDepositListV1,
  getReqToken,
  getBankBalanceWithAPI,
  getBankBalanceWithAPIV2,
  getBankBalanceWithAPIV3,
} from "../../config/networkConfig";
import {settings} from "../../config/appConfig";
import { CS_USER_TYPE } from "../../common/constants/csuserType";
import { useSelector } from "react-redux";
import { PROCESS_TYPE } from "../../common/constants/processType";
import { FormatPt, FormatMask, ShowMobileNumber } from "../../library/numbers";
import { FormatTHADate } from "../../library/dates";
import moment from "moment";
import md5 from "md5";
import ReactDOM from "react-dom";
import { FullAccess } from "../../library/access";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";
import { witMgmtManApprRej } from "../../library/permission";
import { BANK_INFO_TYPE } from "../../common/constants/bankType";


export default function DepositManagement() {
  const { TextArea } = Input;
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [originData, setOriginData] = useState([]);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(true);
  const [depositVisible, setDepositVisible] = useState(false);
  const [rejectVisible, setRejectVisible] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const [amount, setAmount] = useState("");
  const [confirmAmount, setConfirmAmount] = useState("");
  const [reason, setReason] = useState("");
  const [searchText, setSearchText] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [status, setStatus] = useState("FollowUp");
  const { role, userId, username , issuper } = useSelector((state) => state.login);
  //Date Picker
  const [trxDateFrom, setTrxDateFrom] = useState("");
  const [trxDateTo, setTrxDateTo] = useState("");
  const reportDateFrom = useRef(null);
  const reportDateTo = useRef(null);

  //Puppet Modal
  const [puppetVisible, setPuppetVisible]= useState(false);
  const [csPptTrxnList, setCSPptTrxnList] = useState([]);
  const [filteredPptTrxn,setFilterPptTrxn]= useState([]);
  const [memberBankAcctNum,setMemberAcctNum]= useState("");
  const [memberBankCode,setMemberBankCode]  = useState("");

  // Deposit Card
  const [depositBankList, setDepositBankList] = useState([]);
  const [bankListLoading, setBankListLoading] = useState(true);
  // const now = new Date();
  const now = new Date().toLocaleTimeString();
  const [time, setTime] = useState(now);

  //const [pptTrxnId, setPPTTrxnId] = useState("");
  const [mtTrxnId, setMTTrnxId] = useState(""); //Member Transaction Id;
  const [memberNickname,setMemberNickname]= useState("");
  const [topupAmt,setTopupAmt]= useState("");
  const [editMode, setEditMode] = useState(false);
  const [pupActAmount,setPupActAmount] = useState("");
  const [processing, setProcessing] = useState(false);
  const [followupVisible,setFollowupVisible] = useState(false);
  const [followUpMemberPhone,setFollowupMemberPhone] = useState("");
  const [followupRemark, setFollowupRemark] = useState("");
  const [followUpMode, setFollowUpMode] = useState(false);
  const [pendingShow,   setPendingShow] = useState(false);

//   useEffect(()=> {
//     const interval = setInterval(() => {
//       getCompanyDepositList();
      
//         const newTime = new Date().toLocaleTimeString();
//         setTime(newTime);
//     }, 1000 * 120);

//     return () => {
//       clearInterval(interval);
//     };
// }, [time] )
  
  useEffect(() => {
    depositList();
      getCompanyDepositList();
      // eslint-disable-next-line
  }, [status]);

  useEffect(()=> {
    setTrxDate();
  },[])

  const setTrxDate = ()=> {
    let today = moment().set('hour',0).set('minutes',0);
    let yesterday = moment(today).subtract(1,'days').set('minutes',0).set('hour' , 0 );
    setTrxDateFrom(yesterday);
    setTrxDateTo(today);
  }

  ////#region Functions
  //#region Date Picker functions

  const trxDatePicker1= ( date, dateString) => {
    setTrxDateFrom(date); 
  }

  const trxDatePicker2= ( date, dateString) => {
      setTrxDateTo(date);
  }

  ////#endregion

  const searchBar = () => {
    if (!trxDateFrom) {
      reportDateFrom.current.focus();
      // message.error("Please enter date from");
      alertData.title = "Error";
      alertData.msg = "Please select Date From !";
      sharedErrorAlert(alertData);
      return;
    }

    if (!trxDateTo) {
      reportDateTo.current.focus();
      // message.error("Please enter date to");
      alertData.title = "Error";
      alertData.msg = "Please select Date To !";
      sharedErrorAlert(alertData);
      return;
    }

    depositList();
  }

  const depositList = () => {
    var count = 0;
    setTableLoading(true);
    let requestData = {
      datefrom : moment(trxDateFrom).format('YYYY-MM-DD HH:mm').toString(),
      dateto : moment(trxDateTo).format('YYYY-MM-DD HH:mm').toString()
    };

    //csDepositListV1({datefrom : moment(trxDateFrom).format('YYYY-MM-DD HH:mm').toString() , dateto : moment(trxDateTo).format('YYYY-MM-DD HH:mm').toString()})
    csDepositList({})
    .then((response) => response.json())
      .then((json) => {
      if (json.status === "ok" ){
        if (json.status === "ok" && json.msg != 'No records found') {

          const newData = Object.keys(json.data).length
            ? json.data.map((x) => {
                setTableLoading(false);
                return {
                  key: count++,
                  date: x.dateTime,
                  nickname: x.nickname,
                  mobile  : x.mobile, 
                  newNickname: ShowMobileNumber(role, x.nickname, x.mobile),
                  bankcode: x.bank,
                  bankNameIn: x.bankAccName,
                  bankAcctIn: x.bankAcc,
                  amount: x.amount,
                  actualAmount: x.actualamount,
                  trxnId: x.trxnId,
                  userId: x.userId,
                  mobile: x.mobile,
                  remark: x.remark,
                  status: x.status,
                  actionStatus:
                    x.status === "0"
                      ? "Pending"
                      : x.status === "8"
                      ? "FollowUp"
                      : x.status === "1"
                      ? "Approved"
                      : x.status === "9"
                      ? "Rejected"
                      : "-",
                };
              })
            : [];

          setOriginData(newData);

          const pending = newData.filter((val) => {
            return val.actionStatus === "Pending";
          });

          const followUp = newData.filter((val) => {
            return val.actionStatus === "FollowUp";
          });


          const approve = newData.filter((val) => {
            return val.actionStatus === "Approved";
          });

          const reject = newData.filter((val) => {
            return val.actionStatus === "Rejected";
          });

          if (status == "FollowUp" && !followUp.length){
            setStatus("Pending");
          }

          const sortData =
                  status === "FollowUp"
                  ? followUp
                  : status === "Pending"
                      ? pending
                  : status === "Approved"
                  ? approve
                  : status === "Rejected"
                  ? reject
                  : [...pending,...approve,...reject,...followUp];

          setData(sortData);
        }}
        else {
          setTableLoading(false);
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
  };

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getCompanyDepositList = async () => {
    setTableLoading(true);
    setBankListLoading(true);

    let requestData = {
        bankType : BANK_INFO_TYPE.DEP,
        columnRequire : ['AccountName','AccountNum', 'BankCode', 'apiAuthKey', 'WithdrawLimit', 'Status']
    };

    let itemList = [];

    // const resDepositBankList = await getBankBalanceWithAPIV2(requestData);
    const resDepositBankList = await getBankBalanceWithAPIV3(requestData);
    const response = await resDepositBankList.json();
    if (response.status === "ok") {
        setTableLoading(false);
        setDepositBankList(response.data.bankList.length > 0 ? response.data.bankList : []);

        // Process bank list and create as filter
        response.data.bankList.forEach((item) => {
            let bankExist = itemList.some(item2 => item2.accNo === item.accNo);
            if (!bankExist) { 
                itemList.push({ 
                    'text' : item.accNo,
                    'value' : String(item.accNo).slice(0, 10)
                })
            }
        })
        setBankListLoading(false);
    }
    else {
        alertData.title = "Failed";
        alertData.msg = response.msg;
        sharedFailAlert(alertData);
        setTableLoading(false);
        setBankListLoading(false);
        
    }
  }

  const cardList = () => {
    let convertRecord = Object.values(depositBankList);
    let sortData = [...convertRecord].sort((a, b) => a.accEnable > b.accEnable ? -1 : 1);

    if (depositBankList.length > 0 && sortData[0].accNo != 'undefined (undefined)') {
      const listItems = sortData.map((item,index) =>
          <Col key={index} span={8} style={{ width: "100%", paddingLeft: "8px", paddingRight: "8px" }}>
              <Card
                  key={index}
                  style={{
                      backgroundColor: "#e2f2f0",
                      borderRadius: 10,
                      marginTop: "3%",
                      minHeight: 300,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center"
                  }}
              >
                  {bankListLoading ? (
                  <Spin 
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyItems: "center",
                      alignItems: "center",
                    }}
                    size="large"
                  ></Spin>
                  ) : (
                    <Col>
                          {   
                              item.accEnable == true ?
                                  <div style={{ height: "25%", fontSize: "24px" }}>
                                      <span style={{ color: "green" }}>
                                          <CheckCircleOutlined /> Active
                                      </span>
                                      { witMgmtManApprRej(role) ?
                                          <span style={{ position: "absolute", top: "0px", right: "0px" }}>
                                          </span>
                                      :
                                          <></>
                                      }
                                  </div>
                              :
                                  <div style={{ height: "25%", fontSize: "24px" }}>
                                      <span style={{ color: "red" }}>
                                          <StopOutlined /> Inactive
                                      </span>
                                      { witMgmtManApprRej(role) ?
                                          <span style={{ position: "absolute", top: "0px", right: "0px" }}>
                                          </span>
                                      :
                                          <></>
                                      }
                                  </div>
                          }

                          <div style={{ height: "25%", marginTop: "2%" }}>
                              <span style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "14px" }}>
                                  Account Number
                              </span> 
                              <br/>
                              <span style={{ fontSize: "24px" }}>
                                  {item.accNo}
                              </span>
                          </div>

                          <div style={{ height: "25%", marginTop: "2%" }}>
                              <span style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "14px" }}>
                                  Account Name
                              </span>
                              <br/>
                              <span style={{ fontSize: "24px" }}>
                                  {item.accName}
                              </span>
                          </div>

                          <div style={{ marginTop: "2%" }}>
                              <div style={{ width: "50%", display: "inline-block" }}>
                                  <Statistic className="withdrawPageBankList_title" title="Account Balance (Baht)" value={item.accBal} precision={2} style={{ }} />
                              </div>
                              <div style={{ width: "50%", display: "inline-block" }}>
                                  <Statistic className="withdrawPageBankList_title" title="Deposit Counts" value={item.accDepositCount} precision={0} style={{ }} />
                              </div>
                          </div>
                      </Col>
                    )}
              </Card>
          </Col>
      )

      return listItems
    }
  }

  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
       
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });
  ////#endregion Functions

  //#region "Columns"
  const columns = [
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "date",
      width: "200px",
      ellipsis: true,
    },
    // {
    //   align: "center",
    //   title: "userId",
    //   dataIndex: "userId",
    //   width: "200px",
    // },
    // {
    //   align: "center",
    //   title: "TrxnId",
    //   dataIndex: "trxnId",
    //   width: "200px",
    //   ...getColumnSearchProps("trxnId"),
    // },
    {
      ellipsis: true,
      align: "center",
      title: "Bank Name(In)",
      dataIndex: "bankNameIn",
      width: "200px",
      ...getColumnSearchProps("bankNameIn"),
    },
    {
      ellipsis: true,
      align: "center",
      title: "Bank Account(In)",
      dataIndex: "bankAcctIn",
      width: "200px",
      ...getColumnSearchProps("bankAcctIn"),
    },
    {
      ellipsis: true,
      align: "center",
      title: "Member nickname",
      dataIndex: "newNickname",
      ...getColumnSearchProps("newNickname"),
      width: "200px",
      //dataIndex: "nickname",
      //...getColumnSearchProps("nickname"),
    },
    {
      ellipsis: true,
      align: "center",
      title: "Amount",
      dataIndex: "amount",
      width: "200px",
      render: (text) => {
        return FormatPt(text);
      },
      ...getColumnSearchProps("amount"),

    },    
    {
      ellipsis: true,
      align: "center",
      title: "Actual Amount",
      dataIndex: "actualAmount",
      width: "200px",
      render :(text, record)=> {
          return <>{record.status}</>;
          // if (text===0) {
          //     return <>-</>;
          // }else {
          //     return <>{FormatPt(text)}</>
          //}
      },
      ...getColumnSearchProps("actualAmount"),
    },
    {
      fixed: "right",
      ellipsis: true,
      align: "center",
      title: "Status",
      dataIndex: "action",
      width: "150px",
      render: (text, record) => {
        switch (record.actionStatus) {
          case "Pending":
            return (
                !FullAccess(username)? 
                <Badge color="#f7b105" text="Pending" />:           
                  <Tooltip title="Press Me!">
                    <Button
                      style={{ backgroundColor: "#F7B105", border: "none" }}
                      name={"Check"}
                      type="primary"
                      onClick={(event) => {
                        pendingBtnV2(record, event.target);
                      }}
                    >
                      Pending
                    </Button>
                  </Tooltip>
            );
          case "FollowUp":
              return (
                <Tooltip title="Press Me!">
                  <Button
                    style= {styles.followUpBtn}
                    name={"Check"}
                    type="primary"
                    onClick={(event) => {
                      followUpBtn(record, event.target);
                      //pendingBtn(record, event.target);
                    }}
                  >
                    Follow Up
                  </Button>
                </Tooltip>
              );

          case "Rejected":
            return <Badge status="error" text="Rejected" />;

          case "Approved":
              return <>
                      <Badge status="success" text="Approved" />
                    </>

          default:
            return <></>;
        }
      },
    },
  ];

//#region FollowUp
const handleMemberPhone = (e) => {
  e.preventDefault();
  alertData.title = "Error";
  alertData.msg = "Copy Not Allowed";
  sharedErrorAlert(alertData);
}

//#endregion

//#region Puppet Transactions
const onCancelDepReject = () => {
    if (selected)  { 
        setDepositVisible(false); 
    } else {
        setRejectVisible(false);
    }
  }  


let pptColumns = [
  {
    align: "center",
    title: "",
    dataIndex:"PptBankTransactionID",
    width: "10px",
    ellipsis: true,
    render: (text)=> {
      return "";
    }
  },
  {
    align: "center",
    title: "Date / Time",
    dataIndex: "BankTrxTime",
    width: "200px",
    ellipsis: true,
    // render:(text) => {
    //   return FormatTHADate(text);
    // }
  },
  {
    align: "center",
    title: "Bank Name(In)s",
    dataIndex: "BankCode",
    width: "150px",
    ellipsis: true,
  },    
  {
    align: "center",
    title: "Account Number",
    dataIndex: "BankAccNo",
    width: "150px",
    ellipsis: true,
  },
  {
    align: "center",
    title: "Bank Details",
    dataIndex: "BankDetails",
    width: "200px",
    ellipsis: true,
    render: (text)=> {
      return (text);
    }
  },
  {
    align: "center",
    title: "Amount",
    dataIndex: "BankAmount",
    width: "150px",
    ellipsis: true,
    render: (text,record) => {
        return FormatPt(text);
      }
  },
  {
    ellipsis: true,
    align: "center",
    title: "Action",
    dataIndex: "PptBankTransactionID",
    width: "200px",
    visible:false,
    render: (text, record) => {
      return (
        <div
          style={{
            justifyContent: "center",
            textAlign: "center",
            display: "flex",
          }}
        >
          {
            
            //role === CS_USER_TYPE.Senior || role === CS_USER_TYPE.CS ?
            (
              <>
                <Button
                  type="primary"
                  loading={loadings[1]}
                  onClick={() => {
                    approvePPTTrxn(record,1);
                  }}
                >
                 {processing? "Processing" : "Approve"}
                </Button>
              </>
            )//:(<></>)
          }
        </div>
      );
    }
  }
];

////#region Button functions
const pendingBtnV2 = (record) => {
  form.resetFields();
  setDepositVisible(true);
  setSelected(true);
  setActiveKey(record.key);
  setConfirmAmount(record.amount);
};

const followUpBtn = (record) => {
  form2.resetFields();
  setActiveKey(record.key);
  setFollowupMemberPhone(record.mobile);
  setFollowupRemark(record.remark);
  setFollowupVisible(true);
  //console.log(`record >> ${JSON.stringify(record)}`);
}
////#endregion Button functions

const handleUpdateRemark = async (index) => {

  var newData = [...originData];
  let matchTrxnId = newData[activeKey].trxnId, memberNick = newData[activeKey].nickname;

  let updateData = {
      trxnId : matchTrxnId,
      userId : userId,
      username: username,
      remark : followupRemark,
  }

  const response = await csDepositFollowupUpdate(updateData);
  let result = await response.json();

  if (result.status === "ok") {
    alertData.title = "Success";
    alertData.msg = result.msg;
    sharedSuccessAlert(alertData);
    depositList();
    setFollowupVisible(false);
  }else {
    alertData.title = "Failed";
    alertData.msg = result.msg;
    sharedFailAlert(alertData);
  }

}

const handleApproveV2 = async (index)=>{

    var newData = [...originData];
    let matchBank = newData[activeKey].bankcode, matchAcctno = newData[activeKey].bankAcctIn, matchAmt = newData[activeKey].amount , matchTrxnId = newData[activeKey].trxnId, memberNick = newData[activeKey].nickname;
    let matchUserId = newData[activeKey].userId;
    let trxDate = newData[activeKey].date , startDate = moment(trxDate).subtract(1,'d').format("YYYY-MM-DD HH:mm:ss");
    let endDate = moment(trxDate).add(1,'d').format("YYYY-MM-DD HH:mm:ss");

    setPupActAmount(newData[activeKey].actualAmount);
    let puppetData = {
      bankcode     : matchBank,
      bankaccnum   : matchAcctno,
      amt          : matchAmt ,
      datefrom     : startDate, 
      dateto       : endDate,
    }

    console.log(`Bankcode >> ${matchBank} ,BankAcctnum >> ${matchAcctno}, Amt >> ${matchAmt}, Transaction Id >> ${matchTrxnId} , endDate >> ${endDate} , StartDate >> ${startDate} `);
    setPuppetVisible(true);
    setMemberNickname(memberNick);
    setTopupAmt(matchAmt);

    //Get user Bank Details
    const respUser = await userProfileBank( {userid: matchUserId} );
    let resUser = await respUser.json();
    if (resUser.status ==="ok") {
        if (Object.keys(resUser.data).length > 0) {
            let userData = resUser.data;

            setMemberBankCode(userData.bankcode);
            setMemberAcctNum(FormatMask(userData.acctnum));
        }
    }

    const response = await puppetTransactionList(puppetData);
    let result = await response.json();

    if (result.status === "ok") {
       if (result.data && result.data.length) {
           const pending = result.data.filter((item) => item.Status === 0);
            setFilterPptTrxn(pending);
            setMTTrnxId(matchTrxnId);

            setEditMode(true);
       }
    }else {
      setFilterPptTrxn([]);
    }
}

const approvePPT = async(record, index) => {

    this.setState(({ loadings }) => {
      const newLoadings = [...loadings];
      newLoadings[index] = true;

      return {
        loadings: newLoadings,
      };
    });

    setTimeout(() => {
      this.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[index] = false;

        return {
          loadings: newLoadings,
        };
      });
    }, 6000);

}

const approvePPTTrxn = async (record, index) => {
    var newData = [...originData];
    let approveData = {
      trxnId     : mtTrxnId, 
      pptTrxnId  : record.PptBankTransactionID, 
      processtype: PROCESS_TYPE.MANUAL, 
      csUserid   : userId ,
      reqToken   : md5(getReqToken('depositAutoManualApprove')),
      bankAccNum : record.BankAccNo
    }

    if (index > -1) {
     loadingTrue(index);
     setProcessing(true);
      csDepositApprove(approveData)
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          setData(newData);
          newData[activeKey].actionStatus = "Approved";
          setSelected(false);
          setDepositVisible(false);
          form.resetFields();

          alertData.title = "Success";
          alertData.msg = json.msg;
          sharedSuccessAlert(alertData);
          depositList();
          setPuppetVisible(false);
          loadingFalse(index);
          setProcessing(false);
        } else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
          loadingTrue(false);
          setProcessing(false);

          if (json.errcode === "2072") {
            newData[activeKey].actionStatus = json.data.status;
            setSelected(false);
            setDepositVisible(false);
            form.resetFields();
          }
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
    }else{
      loadingTrue(index);
      setProcessing(false);
      alertData.title = "Error";
      alertData.msg = "Connection not stable, please try again !";
      sharedErrorAlert(alertData);
    }
}

const onCancelApproveTrxn =() => {
  setPuppetVisible(false);
  setMemberNickname("")
  setTopupAmt("");
  setMemberAcctNum("");
  setMemberBankCode("");
}  

const ViewPPTDetails = async (record) => {

  setFilterPptTrxn([]);

  const response = await puppetTrxnDetails({trxnId:record.pptTrxnId});
  let result = await response.json();

  if (result.status === "ok") {
     if (result.data && result.data.length) {
         setFilterPptTrxn(result.data);  
         setMemberNickname(record.nickname);
         setTopupAmt(record.amount);
         setPuppetVisible(true);     
     }
  }else {
    alertData.title = "Failed";
    alertData.msg = "Transaction not found";
    sharedFailAlert(alertData);
  }
}
//#endregion


//#region Components

//#endregion


const pendingBtn = (record) => {
    form.resetFields();
    setDepositVisible(true);
    setSelected(true);
    setActiveKey(record.key);
    setConfirmAmount(record.amount);
  };

 const formFollowUp = () => {
   return (
      <Form form={form2}
          layout="vertical" 
        >
          <Form.Item name={["member", "phoneNum"]} label="Phone Number">
            <React.Fragment>
              <p style={styles.noHighlight}>{followUpMemberPhone}</p>
            </React.Fragment>
          </Form.Item>
          <Form.Item
            id="Token"
            name={["member", "remark"]}
            label="Remark"
            required={true}
          >
            <>
              <Input.TextArea
                  value={followupRemark}
                  onChange={(e) => {
                    setFollowupRemark(e.target.value);
                  }}
                  value = {followupRemark}
                  placeholder="Please enter remarks here"
                  rules={[{required:true, message:'Please input your Remarks'}]}
                  maxLength={250}
                  rows={4}
                />
                <span>Maximum 250 characters</span>
                <div style={{textAlign:"right", marginTop:-20}}>
                  <Button
                      loading={loadings[0]}
                      style={styles.remarkBtn}
                      type="primary"
                      htmlType="submit"
                      onClick={() => {
                        handleUpdateRemark(0);
                      }}
                    >
                      Update Remark
                    </Button>
                </div>
               
            </>
                
          </Form.Item> 
          {/* style={{ textAlign: "right" , marginTop: -40, marginBottom: 8 }} */}

                <Form.Item>
                  <div style={{textAlign:"left"}}>
                    <Button
                    style={{
                      marginRight: 10,
                      backgroundColor: "#4190f7",
                      borderColor: "#4190f7",
                    }}
                    type="primary"
                    htmlType="submit"
                    onClick={() => {
                      setFollowupVisible(false);
                      handleApproveV2(0);
                    }}
                  >
                    View Trxn & Approve
                  </Button>
                  <Button
                    danger
                    style={{
                      marginLeft: 10,
                    }}
                    type="primary"
                    htmlType="submit"
                    onClick={(event) => {
                      setFollowupVisible(false);
                      setFollowUpMode(true);
                      handleRejectV2(event.target);
                      //handleReject(event.target);
                    }}
                  >
                    Reject
                  </Button>
                  <span style={{float:"right"}}>
                  <Button
                      onClick={() => {
                            followUpCancel()
                          }}
                          style= {styles.hide}
                        >
                        Cancel
                      </Button>
                  </span>
                  
                  </div>
                </Form.Item>
          <Form.Item >
               {/* <Row>
                   <Col span={6}>
                    </Col>
                    <Col span={6}>
                      <Button
                        onClick={() => {
                          followUpCancel()
                        }}
                      >
                      Cancel
                    </Button>
                    <Button
                      loading={loadings[0]}
                      style={{
                        marginRight: 20,
                        marginLeft: 20,
                        backgroundColor: "#4190f7",
                        borderColor: "#4190f7",
                        marginTop:20,
                      }}
                      type="primary"
                      htmlType="submit"
                      onClick={() => {
                        handleUpdateRemark(0);
                      }}
                    >
                      Save
                    </Button>
                    </Col>
               </Row> */}
              
          </Form.Item>
          {/* <Form.Item style={{ textAlign: "left" }}>
          {  //role === CS_USER_TYPE.Senior || role === CS_USER_TYPE.CS ? 
            (<>
                <Button
                  loading={loadings[0]}
                  style={{
                    marginRight: 20,
                    marginLeft: 20,
                    backgroundColor: "#4190f7",
                    borderColor: "#4190f7",
                  }}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    setFollowupVisible(false);
                    handleApproveV2(0);
                  }}
                >
                  Approve
                </Button>
                <Button
                  danger
                  type="primary"
                  htmlType="submit"
                  onClick={(event) => {
                    setFollowupVisible(false);
                    setFollowUpMode(true);
                    handleRejectV2(event.target);
                    //handleReject(event.target);
                  }}
                >
                  Reject
                </Button>
              </>
            )//:(<></>)
          }
      </Form.Item> */}
    </Form>
   )
 }

  const deposit = () => {
    return (
      <Form form={form}>
        <Form.Item name={["member", "amount"]} label="Deposit Amount">
          <div>{FormatPt(confirmAmount)}</div>
        </Form.Item>
        <Form.Item
          id="amount"
          name={["member", "amount"]}
          label="Confirm Amount"
          style={styles.hide}
        >
          <Input
            type={"number"}
            onChange={(e) => {
              setAmount(Number(e.target.value));
            }}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit" onClick={depositCancel}>
            Cancel
          </Button>
          {  //role === CS_USER_TYPE.Senior || role === CS_USER_TYPE.CS ? 
             (<>
                <Button
                  loading={loadings[0]}
                  style={{
                    marginRight: 20,
                    marginLeft: 20,
                    backgroundColor: "#4190f7",
                    borderColor: "#4190f7",
                  }}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    handleApproveV2(0);
                    //handleApprove(0);
                  }}
                >
                  View Trxn & Approve
                </Button>
                <Button
                  danger
                  type="primary"
                  htmlType="submit"
                  onClick={(event) => {
                    handleReject(event.target);
                  }}
                >
                  Reject
                </Button>
              </>
            )//:(<></>)
          }

        </Form.Item>
      </Form>
    );
  };

  ////#region Cancel functions
  const followUpCancel = ()=>{
    setFollowupVisible(false);
  }

  const depositCancel = () => {
    setDepositVisible(false);
    // setSelected(false);
  };

  const rejectCancel = () => {
      setRejectVisible(false);
      setSelected(true);
  };
  ////#endregion

  const handleApprove = (index) => {
    loadingTrue(index);
    var newData = [...originData];
    if (confirmAmount === amount) {
      csDepositApprove({ trxnId: newData[activeKey].trxnId })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "ok") {
            loadingFalse(index);
            // setOriginData(newData);
            setData(newData);
            newData[activeKey].actionStatus = "Approved";
            setSelected(false);
            setDepositVisible(false);
            form.resetFields();
            alertData.title = "Success";
            alertData.msg = json.msg;
            sharedSuccessAlert(alertData);
            depositList();
          } else {
            alertData.title = "Failed";
            alertData.msg = json.msg;
            sharedFailAlert(alertData);
            loadingFalse(index);
            if (json.errcode === "2072") {
              newData[activeKey].actionStatus = json.data.status;
              setSelected(false);
              setDepositVisible(false);
              form.resetFields();
            }
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          alertData.title = "Error";
          alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
          sharedErrorAlert(alertData);
        });
    } else {
      alertData.title = "Error";
      alertData.msg = "Amount incorrect, please key in again !";
      sharedErrorAlert(alertData);
      loadingFalse(index);
    }
  };

  const handleReject = () => {
    setRejectVisible(true);
    setSelected(false);
    return;
  };

  const handleRejectV2 = () => {
    setFollowupVisible(false);
    setRejectVisible(true);
    setSelected(false);
    return;
  }

  const rejectView = () => {
    return (
      <div>
        <Form form={form}>
          <Form.Item>
            <TextArea rows={4} onChange={(e) => setReason(e.target.value)} />
          </Form.Item>

          <Form.Item>
            <div style={{ textAlign: "center", marginTop: 30 }}>
              {
                //role === CS_USER_TYPE.Senior || role === CS_USER_TYPE.CS ?
                (<>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: 10 }}
                    onClick={rejectCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={loadings[1]}
                    danger
                    id="rejectbtn"
                    name="rejectbtn"
                    type="primary"
                    htmlType="submit"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      rejectBtn(1);
                    }}
                  >
                    Reject
                  </Button>
                </>)//:(<></>)
              }
            </div>
          </Form.Item>
        </Form>
      </div>
    );
  };

  const rejectBtn = (index) => {
    loadingTrue(index);
    if (reason !== "") {
      var newData = [...originData];

      let rejectData =  {
        trxnId: newData[activeKey].trxnId,
        reason: reason,
        csUserid : userId,
        process : PROCESS_TYPE.MANUAL,
        reqToken : md5(getReqToken('depositAutoManualReject')),
      }

      csDepositReject(rejectData)
      .then((response) => response.json())
        .then((json) => {
          if (json.status === "ok") {
            loadingFalse(index);
            newData[activeKey].actionStatus = "Reject";
            setRejectVisible(false);
            alertData.title = "Success";
            alertData.msg = json.msg;
            sharedSuccessAlert(alertData);
            depositList();
          } else {
            alertData.title = "Failed";
            alertData.msg = json.msg;
            sharedFailAlert(alertData);
            loadingFalse(index);
            if (json.errcode === "2072") {
              newData[activeKey].actionStatus = json.data.status;
              setRejectVisible(false);
              form.resetFields();
            }
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          alertData.title = "Error";
          alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
          sharedErrorAlert(alertData);
        });
    } else {
      loadingFalse(index);
      alertData.title = "Field Required";
      alertData.msg = "Please fill in Reject Reason !";
      sharedErrorAlert(alertData);
    }
  };

  const styles = {
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
    },
    refreshBtn: {
      marginLeft: 10,
    },
    followUpBtn: {
      backgroundColor:"#CF0808",
      border: "none"
    },
    remarkBtn:{
      backgroundColor:"#58d63c",
      border: "none"
    },
    tabelContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    hide : {
      display:"none",
    },
    noHighlight : {
      MozUserSelect: "none",
      WebkitUserSelect: "none",
      msUserSelect: "none"
    }
  };

  pptColumns = editMode? pptColumns: pptColumns.slice(0,6);
  let pptPagination = editMode ? 
                      {
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ["5", "10", "20"],
                        style: styles.paginationStyle,
                      }: false ;

  return (
    <div>
      <PageHeader ghost={false}>
        <div style={styles.pageHeaderContainer}>
        <div
            style={{
              justifyContent: "left",
              textAlign: "center",
              display: "flex"
            }}
          >
          <Button
            style={{...styles.refreshBtn}}
            type="primary"
            onClick={() => {
              depositList();
              getCompanyDepositList();
            }}
          >
            Refresh Page
          </Button>
          <DatePicker
              key="1"
              style={{...styles.datePickerStyle, ...styles.refreshBtn,...styles.hide}}
              format="YYYY-MM-DD HH:mm"
              showTime={{
                defaultValue: moment("00:00", "HH:mm"),
                format: "HH:mm"
              }}
              onChange={trxDatePicker1}
              value={trxDateFrom}
              ref={reportDateFrom}
              refs
              placeholder={"Select Date From"}
              disabledDate={(current)=> {
                return current > moment().endOf("day");
            }}/>
          <DatePicker
            key="2"
            // style={styles.hide}
            style={{...styles.datePickerStyle, ...styles.refreshBtn,...styles.hide}}
            format="YYYY-MM-DD HH:mm"
            showTime={{
              defaultValue: moment("00:00", "HH:mm"),
              format: "HH:mm"
            }}
            onChange={trxDatePicker2}
            value={trxDateTo}
            ref={reportDateTo}
            placeholder={"Select Date To"}
            disabledDate={(current)=> {
              let dateFr = trxDateFrom;
              if (dateFr) {
                //return current < dateFr;
                return current < dateFr || current > moment(dateFr).add(1 , 'day').subtract(8 , 'hours');
              }else {
                return  current > moment().subtract(1, 'day');
              }
            }}
            defaultPickerValue={()=>{
                let dateFr = trxDateFrom;
                if (dateFr) {
                    return dateFr;
                }
            }}
          />
            <Button
              type={"primary"}
              style={{...styles.searchResetStyle,...styles.hide}}
              icon={<SearchOutlined 
              onClick={searchBar}
              />}
            />
          </div>
          <Radio.Group
            buttonStyle={"solid"}
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <Radio.Button value="All">All</Radio.Button>
            <Radio.Button value="Pending">Pending</Radio.Button>
            <Radio.Button value="FollowUp">Follow Up</Radio.Button>
            <Radio.Button value="Approved" >Approved</Radio.Button>
            <Radio.Button value="Rejected">Reject</Radio.Button>
          </Radio.Group>
        </div>
      </PageHeader>

      {
            depositBankList.length > 0 ?
                <Row style={{ backgroundColor: "#FFFFFF", marginLeft: "0px", marginRight: "0px" }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        { cardList() }
                </Row>
            :
                <></>
        }

      <Modal visible={followupVisible}
        onCancel ={followUpCancel}
        footer={null}
      >
            {formFollowUp()}
      </Modal> 

      <Modal
        title={selected ? "Deposit Confirmation" : "Reject Reason"}
        visible={selected ? depositVisible : rejectVisible}
        footer={null}
        getContainer={false}
        onCancel={selected ? depositCancel : rejectCancel }
      >
        {selected ? deposit() : rejectView()}
      </Modal>
      <div style={styles.tabelContainer}>
        <Table
          loading={tableLoading}
          columns={columns}
          dataSource={data}
          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: settings.pageSizeOptions,
            style: styles.paginationStyle,
            alignmentBottom: 'right',
          }}
        />
      </div>
      <Modal 
        title={editMode? "Transactions List" : "Transaction Details"}
        visible={puppetVisible}
        style={{ top : "35%" }}
        onCancel={()=>{
            setPuppetVisible(false);
            onCancelApproveTrxn();
        }}
        width={1200}
        footer={[
          <Button key="back"
          align="left"
          onClick={onCancelApproveTrxn}
          >
            {editMode? "Cancel": "Close"}
          </Button>
        ]}
      >
       <Row style={styles.refreshBtn}>
            Member Nickname : {memberNickname} &nbsp;&nbsp;&nbsp; Deposit Amount :  {FormatPt(topupAmt)}&nbsp;&nbsp;&nbsp;   Bank Code : {memberBankCode}   &nbsp;&nbsp;&nbsp; Acct No. : {memberBankAcctNum}       
       </Row>
       <div style={styles.tabelContainer}>
          <Table
            loading={tableLoading}
            columns={pptColumns}
            dataSource= {filteredPptTrxn }
            scroll={{ x: isMobile ? "110%" : "100%", y: null }}
            pagination={{pptPagination}}
          />
        </div>
      </Modal>
    </div>
  );
}
