import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  message,
  PageHeader,
  Button,
  Input,
  DatePicker,
} from "antd";
import {
    //reportBankStatement,
    reportBankStatementV2,
} from "../../../config/networkConfig";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import moment from "moment";
import momentTz from "moment-timezone";
import { settings } from "../../../config/appConfig";
import { FormatNumComm} from "../../../library/numbers";
import { reportBankStmt } from "../../../library/permission";

import { 
  SearchOutlined,
} from "@ant-design/icons";


export default function ReportBankStatement() {
    const { issuper, role } = useSelector((state) => state.login);

    const [searchText, setSearchText] = useState("");
    const [searchMobile,setSearchMobile] = useState("");
    const [searchDate, setSearchDate]    = useState("");

    const [trxDateFrom, setTrxDateFrom] = useState("");
    const [trxDateTo, setTrxDateTo] = useState("");
    const rptDateFrom = useRef(null);
    const rptDateTo = useRef(null);


    //Bank Statement
    const [statementData,         setStatementData] = useState("");
    const [statementPageSize, setStatementPageSize]  = useState(100);
    const [statementPageNum,  setStatementPageNum]  = useState(1);
    const [statementTotal,       setStatementTotal]  = useState(10);
    const [statTableLoading,   SetStatTableLoading] = useState(false);
    

    useEffect(() => {
      viewBankStatement();
      // eslint-disable-next-line
    }, []);

    useEffect(()=> {
      setStatementPageNum(statementPageNum);
      setStatementPageSize(statementPageSize);
      setStatementTotal(statementTotal);
    },[statementPageNum, statementPageSize, statementTotal])
    ////#region Components

  
    ////#endregion

    //#region Functions
    const handleTableChange = async (pagination, filters, sorter)=> {
      
      await getBankStatement('','', pagination.current, pagination.pageSize , filters);

      await setStatementPageNum(pagination.current);

      await setStatementPageSize(pagination.pageSize);

    }

    const trxDatePicker = (date, dateString) => {
      setTrxDateFrom(date);
    };
  
    const trxDatePicker2 = (date, dateString) => {
      setTrxDateTo(date);
    };

    const handleSearch = (selectedKeys, confirm) => {
      confirm();
      setSearchText(selectedKeys[0]);
    };
  
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText("");
    };
  
    let searchInput = null;
  
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : false,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) setTimeout(() => searchInput.select());
      },
      render: (text) =>
        text ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          false
        ),
    });

    const viewBankStatement = async () => {
      // if (!trxDateFrom) {
      //   rptDateFrom.current.focus();
      //   message.error("Please enter date from");
      //   return;
      // }
  
      // if (!trxDateTo) {
      //   rptDateTo.current.focus();
      //   message.error("Please enter date to");
      //   return;
      // }

      // if (!moment(trxDateTo).isSameOrAfter(trxDateFrom)){
      //   message.error("Please choose correct date range");
      //   return;
      // }

      // let startDate = trxDateFrom.format("YYYY-MM-DD HH:mm");
      // let endDate = trxDateTo.format("YYYY-MM-DD HH:mm");

      //getBankStatement(startDate, endDate, statementPageNum, statementPageSize);

      getBankStatement('', '', statementPageNum, statementPageSize, {"bankAmount":null,"actionStatus":null,"bankDetails":null});
    }
  
    const getBankStatement = async(datefrom, dateto, pagenum, pagesize, filters) => {

      let startDate = new moment(moment(),"YYYY-MM-DD HH:mm").subtract(3,"days");
      let endDate = new moment(moment(),"YYYY-MM-DD HH:mm");
      startDate = startDate.format("YYYY-MM-DD HH:mm");
      endDate = endDate.format("YYYY-MM-DD HH:mm");

      let newStartDate = momentTz.tz(moment(), "Asia/Kuala_Lumpur").subtract(3,"days").format("YYYY-MM-DD HH:mm");  
      let newEndDate = momentTz.tz(moment(), "Asia/Kuala_Lumpur").format("YYYY-MM-DD HH:mm");

      let countDL = 0;

      let requestData = {
        datefrom  : newStartDate,
        dateto    : newEndDate,
        page      : pagenum,
        pagesize  : pagesize,
        filters   : filters, 
      }
      
      SetStatTableLoading(true);

      const responseDL = await reportBankStatementV2(requestData);
        let resultDL = await responseDL.json();
        if (resultDL.status === "ok") {
          const dlData = Object.keys(resultDL.data.detail).length
          ? resultDL.data.detail.map((x) => {
              SetStatTableLoading(false);
              return {
                key           : ++countDL,
                pptTrxnId     : x.PptBankTransactionID,
                bankTrxntime  : moment.unix(x.BankTime).format("YYYY-MM-DD HH:mm:ss"),
                //bankTrxntime  : x.BankTrxTime, // old
                sysCreateTime : x.SysCreateTime,
                bankChannel   : x.BankChannel,
                bankType      : x.BankType,
                bankAmount    : x.BankAmount,
                trxnType      : x.TrxnType,
                bank          : x.BankCode,
                bankAccNo     : x.BankAccNo,
                bankDeposit   : x.BankCode+" "+x.BankAccNo,
                bankDetails   : x.BankDetails,
                status        : x.Status,
                actionStatus  : x.Status === 1 || x.Status === 3 ? "SYSTEM" :x.Status === 2 ? "MANUAL" :"-",
                approvedTo    : x.ApprovedTo,
                depositTime   : x.DepositTime,
                trxnId        : x.TransactionID,
                depReqInfo    : x.DepositRequestInfo,
                processtime   : x.processtime,
              };
            })
          : [];

          setStatementData(dlData);

          const dlDataSummary = Object.keys(resultDL.data.summary).length
                        ? resultDL.data.summary.map((x) => {
                          return {
                              current : x.current,
                              pagesize: x.pagesize,
                              totalTrxn : x.totalTrxn
                          };
                        })
                        : [];

            
            setStatementTotal(dlDataSummary?.[0]?.totalTrxn ?? 10 );
            //console.log(`dlDataSummary?.[0]?.totalTrxn >> ${dlDataSummary?.[0]?.totalTrxn}`);    

            SetStatTableLoading(false);
        }else {
          message.error(resultDL.msg)
        }

        SetStatTableLoading(false);


    }

    ////#endregion
    


    ////#region  Columns
    const statementColumns = [
      {
        align: "center",
        title: "#",
        dataIndex: "key",
        width: "5%",
        //fixed:"left",
        render: function(text) {
          return (text);
        }
      },
      {
        align: "center",
        title: "Bank Trxn Time",
        dataIndex: "bankTrxntime",
        width: "8%",
        //fixed:"left",
        render: function(text, record,row) {
          return (text);
        }
      },
      {
        align: "center",
        title: <div>Deposit <br/>Bank</div>,
        dataIndex: "bank",
        width: "5%",
        fixed:"left",
        render: function(text, record,row) {
          return (text);
        }
      },
      {
        align: "center",
        title: "Deposit Bank Account",
        dataIndex: "bankAccNo",
        width: "12%",
        fixed:"left",
        render: function(text, record,row) {
          return (text);
        },
        ...getColumnSearchProps("bankAccNo"),
      },
      {
        align: "center",
        title: <div>From Bank</div>,
        dataIndex: "bankDetails",
        width: "10%",
        render: function(text,record) {
          return (text);
        },
        ...getColumnSearchProps("bankDetails"),
      },
      {
        align: "center",
        title: <div>Deposit Amount</div>,
        dataIndex: "bankAmount",
        width: "10%",
        render: function(text,record) {
          return FormatNumComm(text);
        },
        ...getColumnSearchProps("bankAmount"),
      },
      {
        align: "center",
        title: <div>Approval <br/>Type</div>,
        dataIndex: "actionStatus",
        width: "10%",
        render: function(text,record) {
          return text;
        },
        filters: [
          {
            text: 'System',
            value: 'SYSTEM',
          },
  
          {
            text: 'Manual',
            value: 'MANUAL',
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => record.actionStatus.toLowerCase().indexOf(value.toLowerCase()) === 0 ,
      },
      {
        align: "center",
        title: <div>Approved <br/> To</div>,
        dataIndex: "approvedTo",
        width: "10%",
        render: function(text,record) {
          return (text);
        },
        ...getColumnSearchProps("approvedTo"),
      },
      {
        align: "center",
        title: <div>Approved <br/>By</div>,
        dataIndex: "depReqInfo",
        width: "10%",
        render: function(text,record) {
          if (record.status === 1 || record.status === 3) {
            return "SYSTEM";
          }else if (record.status ===2) {
            let depReq = text?.split("||");
            let approvedBy = typeof depReq !== 'undefined' ? depReq[1] || '' : '' ;
            return approvedBy;
          }
        }
      },
      {
        align: "center",
        title: <div>System Request <br/> Time (MY)</div>,
        dataIndex: "depReqInfo",
        width: "8%",
        render: function(text,record) {
          let depReq = text?.split("||");
          let approvedTime = typeof depReq !== 'undefined' ? depReq[2] || '' : '' ;
          return approvedTime;
          
        }
      },
      {
        align: "center",
        title: <div>Processed <br/> Time (MY)</div>,
        dataIndex: "processtime",
        width: "10%",
        render: function(text) {
          return (text === "1970-01-01 08:00:00" ? "" : text);
        }
      }
    ]

    ////#endregion

    const styles = {
      pageHeaderContainer: {
        justifyContent: isMobile ? "" : "space-between",
        display: "flex",
        flexDirection: isMobile ? "column" : "",
        alignItems: isMobile ? "center" : "",
      },
      refreshBtn: {
        marginLeft: 10,
      },
      memberBtn: {
        width:"150px", 
        marginBottom:"8px"
      },
      lockMemberBtn:{
        //width: "70px",
        //marginRight: 20,
        backgroundColor: "#F7B105",
        border: "none",
        color: "#FFFFFF",
      },
      unlockMemberBtn:{
        backgroundColor: "#0000FF",
        border: "none",
        color: "#FFFFFF",
      },
      tabelContainer: {
        backgroundColor: "#FFFFFF",
        padding: 24,
      },
      tableDivide50: {
        height:"60%",
      },
      tableDivide40: {
        height:"30%",
      },
      paginationStyle: {
        marginTop: 50,
      },
      datePickerStyle: {
        width: 260,
      },
      cardContainer: {
        display: "flex",
        width: "100vw",
        overflowX: "scroll",
        overflowY: "hidden",
        flexDirection: "row",
        paddingTop: 10,
        paddingBottom: 10,
        height:"5%"
      },
      cardStyles: {
        borderRadius: 20,
        fontSize: 15,
        //fontSize: 30,
        boxShadow: "5px 8px 5px 5px rgba(208, 216, 243, 0.6)",
        marginLeft: 20,
        height: "30%",
        //height: 150,
        marginRight: 20,
      },
      cardHeadStyles: {
        backgroundColor: "#1DA57A",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        color: "#FFFFFF",
        fontSize: 25,
        width: 350,
      },
      cardTxt: {
        paddingLeft: 20,
        height: 60,
        alignItems: "center",
        display: "flex",
      },
      hide : {
        display:"none",
      },
      bankAmountStyle:{
        marginLeft:"auto",
        marginRight:0,
      },
      tagStyle:{
        width:"92px",
      },
      buttonStyle:{
        fontSize:"14px"
      },
      memberSearchStyle:{
        width:180,
      },
      memberTrxnStyle:{
        marginLeft:"280px",
      },
      actionBtnListStyle:{
        listStyleType: "none",
        
      },
      actionBtnListItemStyle:{
        display:"inline",
        width: "3%",
      },
      trxnSearchDatePicker:{
        marginBottom:"1%",
      },
      numberStyle:{
        textAlign:"right",
      },
      attn: {
        fontSize:"20px",
      },
      formItem: {
        width:"80%"
      },
      formItem2: {
        width:"83.54%"
      },
      width80:{
        width:"80%"
      },
      fullWidth:{
        width:"100%"
      },
      phoneNumStyle:{
        width:"76.58%"
      },
      downlineFontStyle: {
        fontSize:"13px",
        fontWeight:"bolder",
      },
      attnFontStyle:{
        color:"red",
        fontWeight:"bolder",
      },
      addBankTabStyle:{
        backgroundColor:"#f5f3f3"
      },
      addBankFormStyle:{
        height:"300px",
        marginLeft: "2.0%",
        marginTop: "1.1%",
      },
      editBankFormStyle:{
        marginLeft: "2.0%",
        marginTop: "1.1%",
      },
      nowrap:{
        whiteSpace:"no-wrap",
      },
      underline:{
        textDecorationLine: "underline",
      },
      noHighlight : {
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none"
      },
      rotate180Deg  : {
        transform:"rotate(180deg)"
      },
      authorized : {
        display : issuper ? "block":"none",
      },
      unauthorized: {
        display : issuper? "none":"block",
      }
    };
    return (
      <div className="reportDownline0">
        {
          reportBankStmt(role)?
          (<div style={styles.authorized}>
            <PageHeader ghost={false}>
              <div style={styles.pageHeaderContainer}>
                <div style={{float:"left"}}>
                  <DatePicker
                    key="1"
                    style={{...styles.datePickerStyle, ...styles.refreshBtn,...styles.hide}}
                    format="YYYY-MM-DD HH:mm"
                    showTime={{
                      defaultValue: moment("00:00", "HH:mm"),
                      format: "HH:mm"
                    }}
                    onChange={trxDatePicker}
                    value={trxDateFrom}
                    ref={rptDateFrom}
                    allowClear={false}
                    showToday={false}
                    placeholder={"Select Date From"}
                    disabledDate={(current)=> {
                      return current > moment().endOf('day');
                    }}
                  />
                  <DatePicker
                    key="2"
                    style={{...styles.datePickerStyle, ...styles.refreshBtn,...styles.hide}}
                    format="YYYY-MM-DD HH:mm"
                    showTime={{
                      defaultValue: moment("00:00", "HH:mm"),
                      format: "HH:mm"
                    }}
                    onChange={trxDatePicker2}
                    value={trxDateTo}
                    ref={rptDateTo}
                    allowClear={false}
                    showToday={false}
                    placeholder={"Select Date From"}
                    disabledDate={(current)=> {
                      let dateFr = trxDateFrom;
                      if (dateFr) {
                        return current < dateFr || current > moment().add(1, 'day').add(-1, 'minute');
                      }else {
                        return  current > moment().subtract(1, 'day');
                      }
                    }}
                    defaultPickerValue={()=>{
                        let dateFr = trxDateFrom;
                        if (dateFr) {
                            return dateFr;
                        }
                    }}
                  />
                  <Button 
                  style={{...styles.refreshBtn,...styles.buttonStyle, ...styles.hide}}
                  type="primary"
                  onClick={()=>{
                      viewBankStatement();
                  }}
                  >
                      Reset
                  </Button>
                  <Button
                      style={{...styles.refreshBtn,...styles.buttonStyle,...styles.hide}}
                    type="primary"
                    onClick={() => {
                      setSearchMobile(searchMobile);
  
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </PageHeader>
          </div>)
          :<></>
        }
        <div style={{...styles.tabelContainer}} className="body">
        {
          reportBankStmt(role)?
            <>
              <Table
              columns={statementColumns}
              dataSource={statementData}
              pagination={{
                defaultPageSize: 100,
                showSizeChanger: true,
                pageSizeOptions: settings.pageSizeOptionsLarge,
                style: styles.paginationStyle,
                alignmentBottom: 'right',
                total: statementTotal,
                showTotal: total => `Total ${statementTotal}  Statement(s)`,
                // onChange:handlePaginateChange,
                // pagesize:statementPageSize,
              }}
              loading={statTableLoading}
              onChange={handleTableChange}
            />
          </>
          : <div>
            <div style={{textAlign:"center",color:"red",fontSize:"150%"}}><h1 style={{color:"red"}}>Not Allowed to Access This Page</h1></div>
            </div>
        }
        </div>

      </div>
        
    );
  }


