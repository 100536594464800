import React, { useState, useEffect , useRef} from "react";
import {
    PageHeader,
    Button,
    Form,
    Input,
    message,
    DatePicker,
    InputNumber,
    Modal,
    Popconfirm,
    Tabs,
    //Typography,
    //Table,
    //Tooltip,
    Select,
    Spin,
    Switch,
  } from "antd";
import { Table } from "ant-table-extensions";
import { isMobile } from "react-device-detect";
import {
    EditOutlined,
    DeleteOutlined,
    SearchOutlined,
    FileExcelOutlined,
    //InfoCircleFilled,
  } from "@ant-design/icons";
import {
    reportAccRecon,
    reportAccReconV2,
    reportAccReconUpdate,
    reportAccReconTransferList,
    //reportAccReconDetails,
    reportAccReconBankList,
    reportAccReconBankListV2,
    reportAccReconTrxnList,
  } from "../../../config/networkConfig"
import moment from "moment";
import { FormatNumComm} from "../../../library/numbers";
import { settings } from "../../../config/appConfig";
import { BANK_TRXN_TYPE, BANK_INFO_TYPE, BANK_TRXN_MODE} from "../../../common/constants/bankType";
import { CTable } from "./components/CTable";
import { CTable2 } from "./components/CTable2";
import { CLegend } from "./components/CLegend";
import { useSelector } from "react-redux";
import { FormatDate, FormatDateHHMM } from "../../../library/dates";
import { FullAccess } from "../../../library/access";
import TextArea from "antd/lib/input/TextArea";
import { repAccRecVw } from "../../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";

export default function AccountRecon() {
    const {TabPane} = Tabs;
    const { Option } = Select;
    const [form] = Form.useForm();
    const { companyId , issuper, username , role, compCode} = useSelector((state) => state.login);
   

    //Search Account
    //const [trxLoading,     setTrxLoading] = useState(false);
    const [trxDateFrom,   setTrxDateFrom] = useState("");
    //const [transferIn,     setTransferIn] = useState("");
    const [trxDateTo,       setTrxDateTo] = useState("");
    //const [transIn,           setTransIn] = useState(0);
    //const [transOut,         setTransOut] = useState(0);
    //const [transInAmt,     setTransInAmt] = useState(0);
    const reportDateFrom                  = useRef(null);
    const reportDateTo                    = useRef(null);

    //Common
    const [tableLoading,      setTableLoading] = useState(false);
  
    //Deposit Data
    const [depositData,         setDepositData] = useState([]);
    const [depositLoading,   setDepositLoading] = useState(false);

    //Withdraw Data
    const [withdrawData,       setWithdrawData] = useState([]);
    //const [withTransIn,         setWithTransIn]  = useState(0);
    //const [withTransOut,       setWithTransOut]  = useState(0);
    const [withdrawLoading, setWithdrawLoading]= useState(false);

    //Withdraw Details
    const [withTrxnData,       setWithTrxnData]  = useState([]);
    const [withTrxnVisible, setWithTrxnVisible]  = useState(false);
    const [withTrxnLoading, setWithTrxnLoading]  = useState(false);
    const [withExpand,           setWithExpand]  = useState(true)
    const [depExpand,             setDepExpand]  = useState(true)

    //Transfer Data
    const [editTransferVisible,       setEditTransferVisible] = useState(false);
    const [transferData,                     setTransferData] = useState([]);
    const [transferLoading,               setTransferLoading] = useState(false);
    const [transferDt,                         setTransferDt] = useState("");
    //const [newTransferDt,                  setNewTransferDt]  = useState("");
    const [transferBankType,             setTransferBankType] = useState("");
    const [transferTrxnType,             setTransferTrxnType] = useState("");
    const [transferBankData,             setTransferBankData] = useState(null);
    const [transferBankCode,             setTransferBankCode] = useState("");
    const [transferAcctNum,               setTransferAcctNum] = useState("");
    const [transferAcctName,             setTransferAcctName] = useState("");
    const [transferAmount,                 setTransferAmount] = useState(0);
    const [transferBankList,             setTransferBankList] = useState([]);
    const [transferListTitle,           setTransferListTitle] = useState("");
    const [transferAddTitle,             setTransferAddTitle] = useState("");
    const [transferSubmit,                 setTransferSubmit] = useState(true);
    const [transferTrxnModeData,     setTransferTrxnModeData] = useState([]);
    const [transferTrxnMode,             setTransferTrxnMode] = useState("");
    const [transferRemark,                 setTransferRemark] = useState("");
    const refTransferRemark                                   = useRef(null);
    const refTransferAmount                                   = useRef(null);
    const refTransferDt                                       = useRef(null);
    const refTransferBankData                                 = useRef(null);
    
    //Transaction List 
    const [trxnListData,          setTrxnListData] = useState([]);
    const [trxnListLoading,    setTrxnListLoading] = useState(false);

    //Additional settings
    //const [withdrawInfoShow,    setWithdrawInfoShow] = useState(false);


    useEffect(() => {
      editTransIn  = issuper? true : false
    }, [issuper]);

    useEffect(()=> {
      if (transferBankData!=null) {
        let bankData = transferBankData.split("||");
        setTransferBankCode((bankData[0]||'').trim());
        setTransferAcctNum((bankData[1]||'').trim());
        setTransferAcctName((bankData[2]||'').trim());
      }
    },[transferBankData])


    useEffect(()=> {
      setTransferDt(transferDt);
      form.setFieldsValue({
        transferDtFields: moment(transferDt,"YYYY-MM-DD 00:00")
      });
    },[transferDt])



    //#region Enum 
    let editTransIn  = issuper? true : false;
    let editTransOut = FullAccess(username) ? true: false;
    let actionType = {
      New : "New",
      Delete: "Delete",
    }
    //#endregion

    ////#region  Components
    const CTableWithdraw = (props) => {
      
      if (!props.datasource)
         return <></>
      else 
      {

        // let dataSource = JSON.parse(props.datasource);

        let count = 0 ;
        const newReport = props.map((data) => {
            return {
              key           : count++,
              date          : data.trxndate,
              frombankcode  : data.frombankcode,
              frombankname  : data.frombankname,
              fromacctnum   : data.fromacctnum,
              fromacctname  : data.fromacctname,
              amount        : data.amount,
            };
          });

        return (<CTable2 title={`Withdraw Details (${props.trxndate})`} data={newReport} columnSource={"withdrawTrxn"} paginate={false}/>);
      }
    }

    const CTableTransfer = (props) => {
      if (!props.datasource)
         return <></>
      else 
      {
        // let dataSource = JSON.parse(props.datasource);

        let count = 0 ;
        const newReport = props.map((data) => {
            return {
              key         : count++,
              date        : data.trxndate,
              recordtime  : data.recordtime,
              bankcode    : data.bankcode,
              bankname    : data.bankname,
              acctnum     : data.acctnum,
              acctname    : data.acctname,
              trxntype    : data.trxntype,
              balance     : data.balance,
              trxnmode    : data.trxnmode,
              remark      : data.remark,
            };
          });

        return (<CTable2 title={`Transfer In / Out Details (${props.trxndate})`}  data={newReport} columnSource={"transferList"} columns={transferColumns2} paginate={false}/>);    
      }
    }

    const onEditTransferForm =(newTransferDtt) => {
      return (
        <Form
              layout="vertical" 
              name="editTransferForm"
              form={form}
              //onFinish={handleFinishSubmitTransfer}
          >
            <Tabs defaultActiveKey="1" onChange={onChangeTransferTab}>
              <TabPane tab={transferListTitle} key="1">
                <Table
                  loading={transferLoading}
                  columns ={transferColumns}
                  dataSource= {transferData}
                  scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                  pagination={settings.pagination}
                />
              </TabPane>
              <TabPane tab={transferAddTitle} key="2" style={styles.addBankTabStyle}>
                  <Form.Item style={styles.addBankFormStyle}>
                    <Form.Item label="Select Date / Time" name="transferDtFields">
                      <DatePicker
                          key="3"
                          style={{...styles.datePickerStyle}}
                          format="YYYY-MM-DD HH:mm"
                          showTime={{
                            defaultValue: moment("00:00", "HH:mm"),
                            format: "HH:mm"
                          }}
                          id="repAccReconTransferDt"
                          onChange={trxDatePicker3}
                          value={transferDt}
                          ref={refTransferDt}
                          allowClear={false}
                          showToday={false}
                          placeholder={"Select Date"}
                          defaultValue={moment(transferDt, 'YYYY-MM-DD 00:00')}
                          disabledDate={(current)=> {
                            let dateTransfer = transferDt;
                            return current < moment(dateTransfer).add(-1, 'day').add(1, 'minute') || current > moment(dateTransfer).add(1, 'day').add(-1, 'minute');
                        }}/>
                    </Form.Item>
                    <Form.Item label="Select Bank" name="bankFields">
                        <Select
                          style={styles.formItem}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return option.children.includes(input);
                          }}
                          ref={refTransferBankData}
                        placeholder="Please select a bank"
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onChange={handleChangeTransferBank}
                      >
                        {transferBankList.map((option) => {
                          return (
                            <Option key={`${option.bankdata}`} value={`${option.bankdata}`}>
                              {option.bankdata}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item label="Transfer Mode" name="trxnModeFields">
                        <Select     
                          style={styles.formItem}
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return option.children.includes(input);
                          }}
                          placeholder="Please select Transaction Mode"
                          getPopupContainer={(trigger) => trigger.parentNode}
                          onChange={handleChangeTrxnMode}
                          allowClear
                        >
                          {transferTrxnModeData.map((option) => {
                            return (
                              <Option key={`${option.key}`} value={`${option.value}`}>
                                {option.value}
                              </Option>
                            );
                          })}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Remark" name="trxnRemark">
                        {/* <Input value={transferRemark} placeholder="Please enter remark" style={styles.formItem} 
                          onChange={(e) => {
                              setTransferRemark(e.target.value);
                          }}
                          ref={refTransferRemark} maxLength={250}/> */}
                          <TextArea value placeholder="Please enter remark" style={styles.formItem}
                              onChange={(e) => {
                                setTransferRemark(e.target.value);
                            }}
                            ref={refTransferRemark}>
                          </TextArea>
                    </Form.Item>
                    <Form.Item
                    label={`${transferTrxnType} Amount`}>
                           <InputNumber
                            onChange={handleChangeTransferAmount}
                            style={styles.formItem}
                            value={transferAmount}
                            ref={refTransferAmount}
                          /> 
                    </Form.Item>
                    <Form.Item>
                      {
                        transferSubmit?
                          <Button 
                              type={"primary"} 
                              onClick={() => {
                                  handleSubmitTransfer();
                              }}
                          >
                          {`Add`}
                        </Button>: <Spin tip="Processing..."></Spin>
                      }
                    </Form.Item>
                  </Form.Item>
              </TabPane>
            </Tabs>
          </Form>
      )
    }
    //#endregion

    //#region  Columns
    let depositColumns = [
        {
            align: "center",
            title: "Date Time",
            dataIndex:"date",
            width:"10%",
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            render:(text,record) => { return (record.date ==="Summary" ? <b>{text}</b>:(text)) 
            }
        },
        {
            align: "center",
            title: <div>Total <br/> Claimed Deposit</div>,
            dataIndex:"depositAmtPPT",
            width:"13.55%",
            render: (text,record)=>{
                return (record.date === "Summary" ? <b>{FormatNumComm(text)}</b>:FormatNumComm(text))
            }
        },
        {
            align: "center",
            title: <div>Total <br/> Unclaim Deposit</div>,
            dataIndex:"unClaimedAmt",
            width:"13.55%",
            render: (text,record)=>{
              return (record.date === "Summary" ? <b>{FormatNumComm(text)}</b>:FormatNumComm(text))
            }
        },
        {
          align: "center",
          title:<div>Total <br/> Generated Deposit</div>,
          //title: () => <Tooltip placement="left" title="Total Generated Deposit = Total Deposit + Total Unclaim Deposit" >Total <br/> Generated Deposit<InfoCircleFilled style={styles.headerTooltipStyle} type="primary"/></Tooltip>,   
          dataIndex:"sysDeposit",
          width:"13.55%",
          render: (text,record)=>{
            return (record.date === "Summary" ? <b>{FormatNumComm(text)}</b>:FormatNumComm(text))
          }
        },
        {
          align: "left",
          title: "Transfer In",
          dataIndex:"depTransIn",
          width:"10%",
          render: (text, record) => {
            if ( record.isChildren ==="true" ) {
              return (
                <div style={{display:"table"}}>
                  <div style={styles.editTransferStyle}>{FormatNumComm(text)}</div>
                </div>
              )
            } else {
              return (record.date === "Summary"? <b>{FormatNumComm(text)}</b>:
                <div style={{display:"table"}}>
                  <div style={styles.editTransferStyle}>{FormatNumComm(text)}</div>
                  <div style={{display:"table-cell"}}>
                  <Button type="link" icon={<EditOutlined />} onClick={()=> {
                    handleTransfer(record.date, BANK_INFO_TYPE.DEP,BANK_TRXN_TYPE.TransIn)
                  }}/>
                  </div>
                </div>)
            }
          }
        },
        {
          align: "left",
          title: "Transfer Out",
          dataIndex:"depTransOut",
          width:"10%",
          render: (text, record) => {
            if ( record.isChildren ==="true" ) {
              return (
                <div style={{display:"table"}}>
                  <div style={styles.editTransferStyle}>{FormatNumComm(text)}</div>
                </div>
              )
            } else {
              return (record.date === "Summary"? <b>{FormatNumComm(text)}</b> :
                <div style={{display:"table"}}>
                  <div style={styles.editTransferStyle}>{FormatNumComm(text)}</div>
                    <div style={{display:"table-cell"}}>
                      {/* { 
                        editTransOut ?
                        ( */}
                          <Button type="link" icon={<EditOutlined />} onClick={()=> {
                            handleTransfer(record.date, BANK_INFO_TYPE.DEP,BANK_TRXN_TYPE.TransOut)
                          }}/>
                        {/* ):<></>
                      } */}
                  </div>
                </div>)
            }
          },
        }, 
        {
          align: "center",
          title: "Bank Opening",
          dataIndex:"bankOpen",
          width:"10%",
          render:(text,record) => {
            return (record.date === "Summary" ? <></>:FormatNumComm(text))
          }
        },
        // {
        //   align: "center",
        //   title: "Bank Balance",
        //   //title: () => <Tooltip placement="left" title="Bank Balance = Bank Balance + Transfer In - Transfer Out" >Bank Balance<InfoCircleFilled style={styles.headerTooltipStyle} type="primary"/></Tooltip>,
        //   dataIndex:"bankClose",
        //   width:"10%",
        //   render:(text,record) => {
        //     return (record.date === "Summary" ? <b>{FormatNumComm(text)}</b>:FormatNumComm(text))
        //   }
        // },
        {
          align: "center",
          title: "Bank Closing",
          //title: () => <Tooltip placement="left" title="Bank Balance = Bank Balance + Transfer In - Transfer Out" >Bank Balance<InfoCircleFilled style={styles.headerTooltipStyle} type="primary"/></Tooltip>,
          dataIndex:"bankClose",
          width:"10%",
          render:(text,record) => {
            return (record.date === "Summary" ? <></>:<>{`${FormatNumComm(text)}`}</>)
          }
        },
        {
            align: "center",
            title: <div>Diff. <br/> Total Generated Deposit / <br/>Bank Closing</div>,
            //title: <div>Diff. <br/> Total Generated Deposit / <br/>Bank Balance</div>,
            //title: () => <Tooltip placement="left" overlayStyle={styles.overlayStyle} title="Diff. Total Generated Deposit / Bank Balance = Total Generated Deposit + Bank Opening - Bank Balance" >Diff. <br/> Total Generated Deposit / <br/>Bank Balance<InfoCircleFilled style={styles.headerTooltipStyle} type="primary"/></Tooltip>,
            dataIndex:"diffClose",
            width:"12.55%",
            render:(text,record) => {
              let alert = Number(text) > 0 || Number(text) < 0 ? false: false;
              return (record.date === "Summary" ? <b><span style={{color : alert ? "red": "initial"}}>{FormatNumComm(text)}</span></b>:<span style={{color : alert ? "red": "initial"}}>{FormatNumComm(text)}</span>)
            }
        },
      ];	
      

    let withdrawColumns = [
      {
        align: "center",
        title: "Date Time",
        dataIndex:"date",
        width: "12%",
        // width: "13.55%",
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
        render:(text,record) => { return (record.date ==="Summary" ? <b>{text}</b>:(text)) }
      },
      {
        align: "center",
        title: <div>Total Generated<br/>Withdraw</div>,
        dataIndex:"withdrawAmtPPT",
        width: "9%",
        render:(text,record) => { return (record.date === "Summary" ? <div><b>{FormatNumComm(text)}</b></div>:
          (<div style={{display:"table"}}><div style={styles.editTransferStyle}>{FormatNumComm(text)}</div>
           </div>
          )
         ) 
        }
      },
      {
        align: "center",
        title: "Transfer In",
        dataIndex:"witTransIn",
        width:"10%",
        render: (text, record) => {
          if ( record.isChildren ==="true" ) {
            return (
            <div style={{display:"table"}}>
              <div style={styles.editTransferStyle}>{FormatNumComm(text)}</div>
           </div>
            )
          } else {
                return (record.date === "Summary"? <div><b>{FormatNumComm(text)}</b></div>:
                <div style={{display:"table"}}>
                  <div style={styles.editTransferStyle}>{FormatNumComm(text)}</div>
                <div style={{display:"table-cell"}}>
                    {
                      editTransIn ?
                      (
                      <Button type="link" icon={<EditOutlined />} onClick={()=> {
                        handleTransfer(record.date, BANK_INFO_TYPE.WIT,BANK_TRXN_TYPE.TransIn)
                      }}/>
                      ):<></>
                    } 
              </div>
            </div>)
          }
        },
      },
      {
        align: "center",
        title: "Transfer Out",
        dataIndex:"witTransOut",
        width:"10%",
        style: [{"word-wrap":"break-word"}],
        render: (text, record) => {
          if ( record.isChildren ==="true" ) {
            return (
            <div style={{display:"table"}}>
              <div style={styles.editTransferStyle}>{FormatNumComm(text)}</div>
           </div>
            )
          } else {
                return (record.date === "Summary"? <div><b>{FormatNumComm(text)}</b></div>:<div style={{display:"table"}}><div style={styles.editTransferStyle}>{FormatNumComm(text)}</div><div style={{display:"table-cell"}}>
                {
                  // editTransOut?
                  <Button type="link" icon={<EditOutlined />} onClick={()=> {
                    handleTransfer(record.date, BANK_INFO_TYPE.WIT,BANK_TRXN_TYPE.TransOut)
                  }}/>
                  // :<></>
                }
              </div>
            </div>)
          }
        },
      },
      {
        align: "center",
        title: "Bank Opening",
        dataIndex:"bankOpen",
        width: "13.5%",
        // render:(text,record) => {
        //     return (record.date === "Summary" ? <div style={styles.alignRight}><b>{FormatNumComm(text)}</b></div>:
        //     <div style={{display:"table"}}><div style={{...styles.editTransferStyle,...styles.alignRight}}>{FormatNumComm(text)}</div>
        //     </div>
        //     )
        // }
        render:(text,record) => {
          return (record.date === "Summary" ? <div style={styles.alignRight}></div>:
          <div style={{display:"table"}}><div style={{...styles.editTransferStyle,...styles.alignRight}}>{FormatNumComm(text)}</div>
          </div>
          )
        }
      },
      {
        align: "center",
        title: <div>Bank Closing</div>,
        dataIndex:"bankClose",
        width: "13.5%",
        // render:(text,record) => {
        //       return (record.date === "Summary" ? <div style={styles.alignRight}><b>{FormatNumComm(text)}</b></div>:
        //       <div style={{display:"table"}}><div style={{...styles.editTransferStyle,...styles.alignRight}}>{FormatNumComm(text)}</div>
        //       </div>
        //      ) 
        // }
        render:(text,record) => {
              return (record.date === "Summary" ? <div style={styles.alignRight}></div>:
              <div style={{display:"table"}}><div style={{...styles.editTransferStyle,...styles.alignRight}}>{FormatNumComm(text)}</div>
              </div>
              )
        }
      },
      {
        align: "center",
        title: <div>Total Bank <br/>Transfer Out</div>,
        dataIndex:"witBankTransOut",
        width: "13.5%",
        render:(text,record) => {
          if(record.isChildren ==="true"){
            let witBankTransOut = parseFloat(record.bankOpen) - parseFloat(record.bankClose) + parseFloat(record.witTransIn) - parseFloat(record.witTransOut);
            return FormatNumComm(witBankTransOut);
          } else {
              return (record.date === "Summary" ? <b>{FormatNumComm(text)}</b>:FormatNumComm(text)) 
          }
        }
      },
      {
        //Total Generated Withdraw - Total Bank Transfer Out
        align: "center",
        title: <div>Diff. System Generated <br/> Withdraw / <br/>Total Bank <br/>Transfer Out</div>,
        dataIndex:"diffClose",
        render:(text,record) => { 
          if ( record.isChildren ==="true" ) {
            let witTotalGenWit = parseFloat(record.withdrawAmtPPT);
            let witBankTransOut = parseFloat(record.bankOpen) - parseFloat(record.bankClose) + parseFloat(record.witTransIn) - parseFloat(record.witTransOut);
            let total = witTotalGenWit - witBankTransOut;
            return FormatNumComm(total);
          } else{
            let alert = Number(text) > 0 || Number(text) < 0 ? false: false;
            let diffCloseValue = Number(text) >= 0 ? Number(text) : -(Number(text));
            return (record.date === "Summary" ? <b><span style={{color : alert ? "red": "initial"}}>{FormatNumComm(diffCloseValue)}</span></b>:<span style={{color : alert ? "red": "initial"}}>{FormatNumComm(diffCloseValue)}</span>) }
          }
      },
    ]

    let transferColumns = [
      {
        align: "center",
        title: "Date Time",
        dataIndex:"date",
        width: "15%",
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
        render:(text) => { return (text) }
      },
      // {
      //   align: "center",
      //   title: "Record Time",
      //   dataIndex:"recordtime",
      //   width: "10%",
      //   render:(text) => { return (text) }
      // },
      {
        align: "center",
        title: "Bank Name",
        dataIndex:"bankname",
        width: "15%",
        render:(text) => { return (text) }
      },
      {
        align: "center",
        title: "Account Number",
        dataIndex:"acctnum",
        width: "15%",
        render:(text) => { return (text) }
      },
      {
        align: "center",
        title: "Account Name",
        dataIndex:"acctname",
        width: "15%",
        render:(text) => { return (text) }
      },
      // {
      //   align: "center",
      //   title: "Transaction Type",
      //   dataIndex:"trxntype",
      //   width: "25%",
      //   render:(text) => { return (text) }
      // },
      {
        align: "center",
        title: "Transaction Mode",
        dataIndex:"trxnmode",
        width: "10%",
        render:(text) => { return (text) }
      },
      {
        align: "center",
        title: "Remark",
        dataIndex:"remark",
        width: "10%",
        render:(text) => { return (text) }
      },
      {
        align: "center",
        title: "Amount",
        dataIndex:"balance",
        width: "15%",
        render:(text) => { return (FormatNumComm(text)) }
      },
      {
        align: "center",
        title: "Action",
        dataIndex:"balance",
        width: "10%",
        render:(text, record) => { 
              return (
              transferSubmit?
                  <Popconfirm title="Sure to delete?" onConfirm={() => {          
                    handleTransferDelete(record);          
                  }}>
                    <Button
                      type="danger"
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
               : <Spin tip="Processing..."></Spin>
              )
        }
      }
    ]

    let transferColumns2 = [
      {
        align: "center",
        title: "Date Time",
        dataIndex:"date",
        width: "15%",
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
        render:(text) => { return (text) }
      },
      // {
      //   align: "center",
      //   title: "Record Time",
      //   dataIndex:"recordtime",
      //   width: "10%",
      //   render:(text) => { return (text) }
      // },
      {
        align: "center",
        title: "Bank Name",
        dataIndex:"bankname",
        width: "15%",
        render:(text) => { return (text) }
      },
      {
        align: "center",
        title: "Account Number",
        dataIndex:"acctnum",
        width: "15%",
        render:(text) => { return (text) }
      },
      {
        align: "center",
        title: "Account Name",
        dataIndex:"acctname",
        width: "15%",
        render:(text) => { return (text) }
      },
      {
        align: "center",
        title: "Transaction Type",
        dataIndex:"trxntype",
        width: "25%",
        render:(text) => { return (text) }
      },
      {
        align: "center",
        title: "Transaction Mode",
        dataIndex:"trxnmode",
        width: "10%",
        render:(text) => { return (text) }
      },
      {
        align: "center",
        title: "Remark",
        dataIndex:"remark",
        width: "10%",
        render:(text) => { return (text) }
      },
      {
        align: "center",
        title: "Amount",
        dataIndex:"balance",
        width: "15%",
        render:(text) => { return (FormatNumComm(text)) }
      },
      {
        align: "center",
        title: "Action",
        dataIndex:"balance",
        width: "10%",
        render:(text, record) => { 
              return (
              transferSubmit?
                  <Popconfirm title="Sure to delete?" onConfirm={() => {           
                    handleTransferDeleteV2(record, BANK_INFO_TYPE.WIT);          
                  }}>
                    <Button
                      type="danger"
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
               : <Spin tip="Processing..."></Spin>
              )
        }
      }
    ]

    let trxnColumns = [
      {
        align: "center",
        title: "Date Time",
        dataIndex:"date",
        width: "15%",
        render:(text) => { return (text) }
      },
      {
        align: "center",
        title: "Mobile Number",
        dataIndex:"mobile",
        width: "15%",
        render:(text) => { return (text) }
      },
      {
        align: "center",
        title: "Transaction Type",
        dataIndex:"trxntype",
        width: "10%",
        render:(text) => { return (text) }
      },
      {
        align: "center",
        title: "Transaction Mode",
        dataIndex:"trxnmode",
        width: "15%",
        render:(text) => { return (text) }
      },
      {
        align: "center",
        title: "Amount",
        dataIndex:"amount",
        width: "10%",
        render:(text) => { return (FormatNumComm(text)) }
      },
      {
        align: "center",
        title: "From Bank Name",
        dataIndex:"frombankname",
        width: "10%",
        render:(text) => { return (text) }
      },
      {
        align: "center",
        title: "From Account Number",
        dataIndex:"frombankacctnum",
        width: "10%",
        render:(text) => { return (text) }
      },
      {
        align: "center",
        title: "From Account Name",
        dataIndex:"frombankacctname",
        width: "10%",
        render:(text) => { return (text) }
      },
    ]
    //#endregion

    //#region Functions
    const handleDepTableChange = async (pagination, filters, sorter) => {

      //console.log(`pagination.current ${pagination.current}, pagination.pageSize ${pagination.pageSize}`)
    }

    const onCancelEditTransfer = ()=> {
      setEditTransferVisible(false);
      setTransferListTitle("");
      setTransferTrxnType("");
      setTransferAddTitle("");

      setTransferBankCode("");
      setTransferBankType("");
      setTransferAmount("");
      setTransferBankData("");
      setTransferTrxnModeData([]);
      form.resetFields();
    }

    const onClickExportBtn = async ()=> {

      if (!trxDateFrom) {
        reportDateFrom.current.focus();
        // message.error("Please enter date from");
        alertData.title = "Field Required";
        alertData.msg = "Please select Date From !";
        sharedErrorAlert(alertData);
        return;
      }
  
      if (!trxDateTo) {
        reportDateTo.current.focus();
        // message.error("Please enter date to");
        alertData.title = "Field Required";
        alertData.msg = "Please select Date To !";
        sharedErrorAlert(alertData);
        return;
      }

      let requestData = {
        date : trxDateFrom

      }

      await SetTrxnListTable(requestData);

      document.getElementById("export-button-3").click();
     
    }

    const onCancelWithTrxn = ()=> {
      setWithTrxnVisible(false);
    }

    const handleChangeTransferAmount = (value)=> {
      setTransferAmount(value);
    }

    const handleChangeTrxnMode= (value)=> {
      setTransferTrxnMode(value);
    }
  
    const handleChangeTransferBank = (value)=> {
      setTransferBankData(value)
    }

    const handleSubmitTransfer = async () => {

      if(!transferDt) {
        refTransferDt.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select a Transfer Date !";
        sharedErrorAlert(alertData);
        return;
      }

      if (!transferBankData){
        refTransferBankData.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select a Bank !";
        sharedErrorAlert(alertData);
        return;
      }

      if (!transferTrxnMode) {
        refTransferAmount.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Transfer Mode !";
        sharedErrorAlert(alertData);
        return;
      }

      if (!transferAmount) {
        refTransferAmount.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please fill in the Transfer Amount !";
        sharedErrorAlert(alertData);
        return;
      }

      let requestData = {
        date : FormatDateHHMM(transferDt),
        trxntype : transferTrxnType,
        banktype : transferBankType,
        bankcode : transferBankCode,
        acctnum : transferAcctNum,
        acctname : transferAcctName,
        amount : transferAmount,
        actiontype : actionType.New,
        trxnmode : transferTrxnMode,
        remark : transferRemark
      }
      
      setTransferSubmit(false);

      const result = await reportAccReconUpdate(requestData);
      let resp = await result.json();

      if (resp.status === "ok") {        
        getTransferData(FormatDate(transferDt),transferBankType,transferTrxnType,"details");
        searchTrxn();
        setTransferLoading(false);
        document.getElementById("rc-tabs-1-tab-1").click(); 
        //document.getElementById("rc-tabs-0-tab-1").click();  
        
        setTransferAmount("");
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData);
        setTransferSubmit(true);
      }
      else{
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);
      }

      setTransferLoading(false);
      setTransferSubmit(true);
      setTransferBankData(null);
      setTransferAmount(0);
      setTransferRemark("");
      setTransferTrxnMode("");
      form.resetFields();
    }

    const handleTransferDeleteV2 = async (record, banktype) => {

      let requestData = {
        date       : record.recordtime, 
        amount     : record.balance, 
        trxntype   : record.trxntype,
        banktype   : banktype,
        bankcode   : record.bankcode,
        acctnum    : record.acctnum,
        acctname   : record.acctname,
        actiontype : actionType.Delete,
      }

      setTransferSubmit(false);

      const result = await reportAccReconUpdate(requestData);
      let resp = await result.json();

      if (resp.status === "ok") {        
        getTransferData(FormatDate(transferDt),banktype,record.trxntype,"");
        searchTrxn();
        setTransferLoading(false);
        setTransferAmount("");
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData);
        setTransferSubmit(true);
      }
      else{
        alertData.title = "Failed2";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);
      }

      setTransferLoading(false);
      setTransferSubmit(true);
    }
    
    const handleTransferDelete = async (record) => {

      let requestData = {
        date       : record.recordtime, 
        amount     : record.balance, 
        trxntype   : record.trxntype,
        banktype   : transferBankType,
        bankcode   : record.bankcode,
        acctnum    : record.acctnum,
        acctname   : record.acctname,
        actiontype : actionType.Delete,
      }

      setTransferSubmit(false);

      const result = await reportAccReconUpdate(requestData);
      let resp = await result.json();

      if (resp.status === "ok") {        
        getTransferData(FormatDate(transferDt),transferBankType,record.trxntype,"details");
        searchTrxn();
        setTransferLoading(false);
        setTransferAmount("");
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData);
        setTransferSubmit(true);
      }
      else{
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);
      }

      setTransferLoading(false);
      setTransferSubmit(true);
    }

    const handleTransfer = async (trxndate, banktype, trxntype) => {
      setTransferListTitle(`${banktype} ${trxntype} List`);
      setTransferAddTitle(`Add ${trxntype}`)
      setTransferBankType(banktype);
      setTransferTrxnType(trxntype);
      getTransferData(trxndate, banktype,trxntype,"details");
      setTransferDt(trxndate);

      let requestData = {
        date : trxndate,
        banktype: banktype,
        trxntype:trxntype,
        userid : companyId
      }

      SetTransferBankListOptions(requestData);

      SetTransferTrxnList(trxntype);
    }

    const onChangeTransferTab = (key)=> {
      if (key === 1) {

      }

      if (key === 2) {
      }

    }

    const getTransferData = (trxndate, banktype, trxntype, viewtype) => {
      if (viewtype === "details"){
        if (!trxndate) {
          alertData.title = "Error";
          alertData.msg = "Transaction Date not found !";
          sharedErrorAlert(alertData);
          return;
        }
  
        if ((!banktype) || (banktype !== BANK_INFO_TYPE.WIT && banktype !== BANK_INFO_TYPE.DEP) ){
          alertData.title = "Error";
          alertData.msg = "Invalid Bank Type !";
          sharedErrorAlert(alertData);
          return;
        }
  
        if ((!trxntype) || (trxntype !== BANK_TRXN_TYPE.TransIn && trxntype !== BANK_TRXN_TYPE.TransOut) ){
          alertData.title = "Error";
          alertData.msg = "Invalid Transfer Type !";
          sharedErrorAlert(alertData);
          return;
        }
  
        let transferRequestData = {
          date:trxndate,
          banktype:banktype,
          trxntype:trxntype
        }
  
        SetTransferListTable(transferRequestData);
        
        setEditTransferVisible(true);
      }
      else{
        if (!trxndate) {
          // message.error("Transaction Date not found");
          alertData.title = "Error";
          alertData.msg = "Transaction Date not found2 !";
          sharedErrorAlert(alertData);
          return;
        }
  
        if ((!banktype) || (banktype !== BANK_INFO_TYPE.WIT && banktype !== BANK_INFO_TYPE.DEP) ){
         // message.error("Invalid Bank Type");
         alertData.title = "Error";
         alertData.msg = "Invalid Bank Type2 !";
         sharedErrorAlert(alertData);
         return;
        }
  
        if ((!trxntype) || (trxntype !== BANK_TRXN_TYPE.TransIn && trxntype !== BANK_TRXN_TYPE.TransOut) ){
         // message.error("Invalid Transfer Type");
         alertData.title = "Error";
         alertData.msg = "Invalid Transfer Type !";
         sharedErrorAlert(alertData);
         return;
        }
  
        let transferRequestData = {
          date:trxndate,
          banktype:banktype,
          trxntype:trxntype
        }
  
        SetTransferListTable(transferRequestData);
      }
    }

    const searchTrxn = () => {
      if (!trxDateFrom) {
        reportDateFrom.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Date From !";
        sharedErrorAlert(alertData);
        return;
      }
  
      if (!trxDateTo) {
        reportDateTo.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Date To !";
        sharedErrorAlert(alertData);
        return;
      }
  
      ViewAccountRecon(trxDateFrom,trxDateTo, BANK_INFO_TYPE.DEP);
    }

    const SetTransferTrxnList = (trxntype)=>{
      let trxnmodeList = [];

      if (trxntype === BANK_TRXN_TYPE.TransIn) {
        trxnmodeList.push({ key : 'FromBank', value: BANK_TRXN_MODE.FromBank});
        trxnmodeList.push({ key : 'Interest', value: BANK_TRXN_MODE.Interest});
        trxnmodeList.push({key:'Misc', value:BANK_TRXN_MODE.Misc});
      }else if (trxntype === BANK_TRXN_TYPE.TransOut) {
        trxnmodeList.push({ key : 'ToBank', value: BANK_TRXN_MODE.ToBank});
        trxnmodeList.push({ key : 'Fee', value: BANK_TRXN_MODE.Fee});
        trxnmodeList.push({key:'Misc', value:BANK_TRXN_MODE.Misc});
      }

      setTransferTrxnModeData(trxnmodeList);
    }

    const SetDepositTable = async (requestData) => {
      setTableLoading(true);

      let respTrxn = await reportAccReconV2(requestData);
      //let respTrxn = await reportAccRecon(requestData);
      let resTrxn = await respTrxn.json();

      let totalDeposit    = 0;
      let totalDepositPPT = 0;
      let totalUnclaim    = 0;
      let sysDeposit      = 0;
      let totalSysDeposit = 0;
      let totalBankOpen   = 0;
      let totalBankClose  = 0;
      let totalTransIn    = 0;
      let totalTransOut   = 0;
      let totalDiffClose  = 0;
      let totalSysGen     = 0;
      let totalClosing    = 0;

      if (resTrxn.status === "ok") {
          setTableLoading(false);
          let count = 0 ;
          const newReport = Object.keys(resTrxn.data).length
          ? resTrxn.data.map((data) => {
              data.depositTrxn.map((item)=> {
                return item.key = count++
              })

              // sysDeposit = (parseFloat(data.depositAmtPPT) + parseFloat(data.unClaimedAmt))
              // totalSysGen  = (parseFloat(data.depositAmtPPT) + parseFloat(data.unClaimedAmt)) - parseFloat(data.transferOut);
              // totalClosing = parseFloat(data.bankClosing) - parseFloat(data.bankOpening);
              return {
                key          : count++,
                date         : data.trxnDate,
                depositAmt   : data.depositAmt,   //from member transaction
                depositAmtPPT: data.depositAmtPPT, //from puppet table
                unClaimedAmt : parseFloat(data.unClaimedAmt),
                //sysDeposit : data.totalDeposit,   // direct get from API
                sysDeposit   : parseFloat(data.sysDeposit),
                bankOpen     : parseFloat(data.bankOpening),
                bankClose    : parseFloat(data.bankClosing),
                depTransIn   : parseFloat(data.transferIn),
                depTransOut  : parseFloat(data.transferOut),
                diffClose    : data.diffClose,
                //diffClose    : parseFloat(data.diffClose),// direct get from API
                depositTrxn  : data.depositTrxn,
                // totalSysGen - totalClosing,
                children     : data.depositTrxn //depTrxn
                  
                
              };
            })
          : [];

          // resTrxn.data.forEach((dat, index) => {    
          //   console.log('ResTrxn Index: ' + index + ' Value: ' + JSON.stringify(dat) + ' closing:  ' + '');
          // });

          
          //New Formula as (2021-08-24)
          // Diff Total Generate Deposit / Bank balance = 
          // Total Generate Deposit - Transfer out - Bank Closing + Bank Opening

          setDepositData(newReport);

          /* Summary Row */
          let newData = [...newReport];

          newData.forEach(({ depositAmt, depositAmtPPT, unClaimedAmt, sysDeposit, bankOpen, bankClose, depTransIn, depTransOut, diffClose }) => {
            totalDeposit    += depositAmt;
            totalDepositPPT += depositAmtPPT;
            totalUnclaim    += unClaimedAmt;
            totalSysDeposit += sysDeposit;
            totalBankOpen   += bankOpen;
            totalBankClose  += bankClose;
            totalTransIn    += depTransIn;
            totalTransOut   += depTransOut;
            totalDiffClose  += diffClose;
          });

          let maxKey = parseInt(Math.max.apply(Math, newData.map(function(o) { return o.key; })))+1;

          newData= [
              ...newData,
              {
                key           : maxKey,
                date          : "Summary",
                depositAmt    : totalDeposit,
                depositAmtPPT : totalDepositPPT,
                unClaimedAmt  : totalUnclaim,
                sysDeposit    : totalSysDeposit,
                bankOpen      : totalBankOpen,
                bankClose     : totalBankClose,
                depTransIn    : totalTransIn,
                depTransOut   : totalTransOut,
                sysClose      : 0,
                diffClose     : totalDiffClose,

              },
            ];

            setDepositData(newData);
          /* Summary Row */

      }else {
        setDepositData([]);
      }

      setTableLoading(false);
    }

    const SetWithdrawTableOld = async (requestData) => {
      setTableLoading(true);

      let respTrxn = await reportAccRecon(requestData);
      let resTrxn = await respTrxn.json();

      let totalWithdraw     = 0;
      let totalWithdrawFull = 0;
      let totalWithdrawPPT  = 0;
      let totalBankOpen     = 0;
      let totalBankClose    = 0;
      let totalTransIn      = 0;
      let totalTransOut     = 0;
      let totalDiffClose    = 0;
      let witTotalGenWit    = 0;
      let witBankTransOut   = 0;


      if (resTrxn.status === "ok") {
          setTableLoading(false);
          let count = 0 ;
          const newReport = Object.keys(resTrxn.data).length
          ? resTrxn.data.map((data) => {
              data.withdrawTrxn.map((item)=> {
               return item.key = count++
              })

              witTotalGenWit  = parseFloat(data.withdrawAmtPPT);
              witBankTransOut = parseFloat(data.bankOpening) - parseFloat(data.bankClosing) + parseFloat(data.transferIn) - parseFloat(data.transferOut);

              return {
                key             : count++,
                date            : data.trxnDate,
                bankcode        : data.bankcode,
                acctnum         : data.acctnum,
                withdrawAmt     : parseFloat(data.withdrawAmt),
                withdrawAmtPPT  : parseFloat(data.withdrawAmtPPT),
                withdrawFullAmt : parseFloat(data.withdrawAmt) + parseFloat(data.commAmt),
                commAmt         : parseFloat(data.commAmt),
                bankOpen        : data.bankOpening,
                bankClose       : data.bankClosing,
                witTransIn      : parseFloat(data.transferIn),
                witTransOut     : parseFloat(data.transferOut),
                witBankTransOut : witBankTransOut,
                diffClose       : witTotalGenWit - witBankTransOut,
                //diffClose       : Math.abs(parseFloat(data.withdrawAmt)+parseFloat(data.commAmt)) - (parseFloat(data.bankOpening)  - parseFloat(data.bankClosing2) + parseFloat(data.transferIn) - parseFloat(data.transferOut)),
                withdrawTrxn    : data.withdrawTrxn,
                transferTrxn    : data.transferTrxn,
                children        : data.withdrawTrxn,

                // let witTotalGenWit = parseFloat(record.withdrawAmtPPT);
                // let witBankTransOut = parseFloat(record.bankOpen) - parseFloat(record.bankCloseOri2) + parseFloat(record.witTransIn) - parseFloat(record.witTransOut);
                // let total = witTotalGenWit - witBankTransOut;
                

              };
            })
          : [];

          setWithdrawData(newReport);

          // //console.log(`resTrxn >> ${JSON.stringify(resTrxn.data)}`)
          
          // resTrxn.data.forEach((dat, index) => {    
          //   console.log('ResTrxn Index: ' + index + ' Value: ' + JSON.stringify(dat) + ' closing:  ' + '');
          // });

          // newReport.forEach((dat, index) => {
           
          //   console.log('NewReport Index: ' + index + ' Value: ' + JSON.stringify(dat) + ' closing:  ' + '');
          // });


          /* Summary Row */
          let newData = [...newReport];

          newData.forEach(({ withdrawAmt,withdrawAmtPPT, withdrawFullAmt, bankOpen, bankClose, witTransIn, witTransOut , diffClose}) => {
            totalWithdraw      += withdrawAmt;
            totalWithdrawPPT   += withdrawAmtPPT;
            //totalWithdrawFull  += withdrawFullAmt;
            totalBankOpen      += bankOpen;
            totalBankClose     += bankClose;
            totalTransIn       += witTransIn;
            totalTransOut      += witTransOut;
            totalDiffClose     += diffClose;
          });

          setWithdrawData(newData);

          let maxKey = parseInt(Math.max.apply(Math, newData.map(function(o) { return o.key; })))+1;

          newData= [
              ...newData,
              {
                key: maxKey,
                date: "Summary",
                //sysDeposit:   totalSysDeposit,
                withdrawAmt     :   totalWithdraw,
                withdrawAmtPPT  :   totalWithdrawPPT,
                //withdrawFullAmt : totalWithdrawFull,
                bankOpen        :   totalBankOpen,
                bankClose       :   totalBankClose,
                witTransIn      :   totalTransIn,
                witTransOut     :   totalTransOut,
                sysClose        :   0,
                diffClose       :   totalDiffClose,

              },
            ];

            setWithdrawData(newData);

      }else {
        setWithdrawData([]);
      }

      setTableLoading(false);
    }

    const SetWithdrawTable= async (requestData) => {
      setTableLoading(true);

      let respTrxn = await reportAccReconV2(requestData);
      let resTrxn = await respTrxn.json();

      let totalWithdraw     = 0;
      let totalWithdrawFull = 0;
      let totalWithdrawPPT  = 0;
      let totalBankOpen     = 0;
      let totalBankClose    = 0;
      let totalTransIn      = 0;
      let totalTransOut     = 0;
      let totalDiffClose    = 0;
      let witTotalGenWit    = 0;
      let witBankTransOut   = 0;
      let totalWitBankTransOut =0;

      if (resTrxn.status === "ok") {
          setTableLoading(false);
          let count = 0 ;
          const newReport = Object.keys(resTrxn.data).length
          ? resTrxn.data.map((data) => {
              data.withdrawTrxn.map((item)=> {
               return item.key = count++
              })

              // witTotalGenWit  = parseFloat(data.withdrawAmtPPT);
              // witBankTransOut = parseFloat(data.bankOpening) - parseFloat(data.bankClosing) + parseFloat(data.transferIn) - parseFloat(data.transferOut);

              return {
                key             : count++,
                date            : data.trxnDate,
                bankcode        : data.bankcode,
                acctnum         : data.acctnum,
                withdrawAmt     : parseFloat(data.withdrawAmt),
                withdrawAmtPPT  : parseFloat(data.withdrawAmtPPT), //total generated withdraw
                withdrawFullAmt : parseFloat(data.withdrawAmt) + parseFloat(data.commAmt),
                commAmt         : parseFloat(data.commAmt),
                bankOpen        : parseFloat(data.bankOpening), //bank opening
                bankClose       : parseFloat(data.bankClosing), // bank closing
                witTransIn      : parseFloat(data.transferIn), // Transfer In
                witTransOut     : parseFloat(data.transferOut), // Transfer Out
                witBankTransOut : parseFloat(data.witBankTransOut), // total bank transfer out // wrong summary
                diffClose       : parseFloat(data.diffClose),

                //diffClose       : Math.abs(parseFloat(data.withdrawAmt)+parseFloat(data.commAmt)) - (parseFloat(data.bankOpening)  - parseFloat(data.bankClosing2) + parseFloat(data.transferIn) - parseFloat(data.transferOut)),
                withdrawTrxn    : data.withdrawTrxn,
                transferTrxn    : data.transferTrxn,
                children        : data.withdrawTrxn,

                // let witTotalGenWit = parseFloat(record.withdrawAmtPPT);
                // let witBankTransOut = parseFloat(record.bankOpen) - parseFloat(record.bankCloseOri2) + parseFloat(record.witTransIn) - parseFloat(record.witTransOut);
                // let total = witTotalGenWit - witBankTransOut;
                

              };
            })
          : [];

          setWithdrawData(newReport);

          // //console.log(`resTrxn >> ${JSON.stringify(resTrxn.data)}`)
          
          // resTrxn.data.forEach((dat, index) => {    
          //   console.log('ResTrxn Index: ' + index + ' Value: ' + JSON.stringify(dat) + ' closing:  ' + '');
          // });

          // newReport.forEach((dat, index) => {
           
          //   console.log('NewReport Index: ' + index + ' Value: ' + JSON.stringify(dat) + ' closing:  ' + '');
          // });


          /* Summary Row */
          let newData = [...newReport];

          newData.forEach(({ withdrawAmt,withdrawAmtPPT, withdrawFullAmt, bankOpen, bankClose, witTransIn, witTransOut, witBankTransOut, diffClose}) => {
            totalWithdraw      += withdrawAmt;
            totalWithdrawPPT   += withdrawAmtPPT;
            //totalWithdrawFull  += withdrawFullAmt;
            totalBankOpen      += bankOpen;
            totalBankClose     += bankClose;
            totalTransIn       += witTransIn;
            totalTransOut      += witTransOut;
            totalWitBankTransOut += witBankTransOut;
            totalDiffClose     += diffClose;
          });

          setWithdrawData(newData);

          let maxKey = parseInt(Math.max.apply(Math, newData.map(function(o) { return o.key; })))+1;

          newData= [
              ...newData,
              {
                key: maxKey,
                date: "Summary",
                //sysDeposit:   totalSysDeposit,
                withdrawAmt     :   totalWithdraw,
                withdrawAmtPPT  :   totalWithdrawPPT,
                //withdrawFullAmt : totalWithdrawFull,
                bankOpen        :   totalBankOpen,
                bankClose       :   totalBankClose,
                witTransIn      :   totalTransIn,
                witTransOut     :   totalTransOut,
                witBankTransOut :   totalWitBankTransOut,
                sysClose        :   0,
                diffClose       :   totalDiffClose,

              },
            ];

            setWithdrawData(newData);

      }else {
        setWithdrawData([]);
      }

      setTableLoading(false);
    }

    const SetTransferListTable = async (requestData)=>{
      setTransferLoading(true);
      let respTrxn = await reportAccReconTransferList(requestData);
      let resTrxn = await respTrxn.json();

      if (resTrxn.status === "ok") {
        setTransferLoading(false);
        let count = 0 ;
        const newReport = Object.keys(resTrxn.data).length
        ? resTrxn.data.map((data) => {
            return {
              key         : count++,
              date        : data.trxndate,
              recordtime  : data.recordtime,
              bankcode    : data.bankcode,
              bankname    : data.bankname,
              acctnum     : data.acctnum,
              acctname    : data.acctname,
              trxntype    : data.trxntype,
              balance     : data.balance,
              trxnmode    : data.trxnmode,
              remark      : data.remark,
            };
          })
          : [];

        setTransferData(newReport);

      } else {
        setTransferData([]);
      }

      setTransferLoading(false);
    }
 
    const SetTrxnListTable = async (requestData) => {
      setTrxnListLoading(true);
      let respTrxn = await reportAccReconTrxnList(requestData);
      let resTrxn = await respTrxn.json();

      if (resTrxn.status === "ok") {
        setTrxnListLoading(false);
        let count = 0 ;
        const newReport = Object.keys(resTrxn.data).length
        ? resTrxn.data.map((data) => {
            return {
              key              : count++,
              date             : data.trxndate,                  
              mobile           : data.mobile,            
              trxntype         : data.trxntype,               
              trxnmode         : data.trxnmode,              
              amount           : data.amount,                 
              frombankcode     : data.frombankcode,      
              frombankname     : data.frombankname,      
              frombankacctnum  : data.frombankacctnum,
              frombankacctname : data.frombankacctname,
            };
          })
          : [];

          setTrxnListData(newReport);

      } else {
        setTrxnListData([]);
      }

      setTrxnListLoading(false);
    }

    const SetTransferBankListOptions = async (requestData)=> {

      let respTrxn = await reportAccReconBankListV2(requestData);
      let resTrxn = await respTrxn.json();
      if (resTrxn.status === "ok") {
        let count = 0 ;
        const newReport = Object.keys(resTrxn.data.banklist).length
        ? resTrxn.data.banklist.map((data) => {
            return {
              key       : count++,
              bankcode  : data.bankcode,
              bankname  : data.bankname,
              acctnum   : data.acctnum,
              acctname  : data.acctname,
              bankdata  : data.bankdata
            };
          })
          : [];

        setTransferBankList(newReport);

      } else {
        setTransferBankList([]);
      }
    }

    // const SetWithdrawTrxnTable = async (requestData)=> {
    //   setWithTrxnLoading(true);
    //   let respTrxn = await reportAccReconDetails(requestData);
    //   let resTrxn = await respTrxn.json();

    //   if (resTrxn.status === "ok") {
    //     setWithTrxnLoading(false);
    //     let count = 0 ;
    //     const newReport = Object.keys(resTrxn.data).length
    //     ? resTrxn.data.map((data) => {
    //         return {
    //           key           : count++,
    //           date          : data.trxndate,
    //           frombankcode  : data.frombankcode,
    //           frombankname  : data.frombankname,
    //           fromacctnum   : data.fromacctnum,
    //           fromacctname  : data.fromacctname,
    //           amount        : data.amount,
    //         };
    //       })
    //       : [];

    //    setWithTrxnData(newReport);
    //   } else {
    //     setWithTrxnData([]);
    //   }

    //   setWithTrxnLoading(false);
    // }


    const ViewAccountRecon = async (trxDateFrom,trxDateTo, banktype) => {

      //setTrxLoading(true);
  
      let startDate = await trxDateFrom === "" || trxDateFrom === null ? "": trxDateFrom.format("YYYY-MM-DD");
      let endDate = await trxDateTo === "" || trxDateTo === null ? "": trxDateTo.format("YYYY-MM-DD");

      if (!moment(endDate).isSameOrAfter(startDate)){
        alertData.title = "Error";
        alertData.msg = "Please select a valid Date Range !";
        sharedErrorAlert(alertData);
        return;
      }

      let requestData = {
        datefrom : startDate, 
        dateto : endDate,
        banktype: BANK_INFO_TYPE.DEP,
      }

      let withdrawRequestData = {
        datefrom : startDate, 
        dateto : endDate,
        banktype: BANK_INFO_TYPE.WIT,
      }

      setTableLoading(true);
  
      await SetDepositTable(requestData);

      await SetWithdrawTable(withdrawRequestData);
    
      //setTrxLoading(false);
    }

    const trxDatePicker = (date, dateString) => {
      setTrxDateFrom(date);
    };
  
    const trxDatePicker2 = (date, dateString) => {
      setTrxDateTo(date);
    };

    const trxDatePicker3 = (date, dateString) => {
      //console.log(`date >> ${date} dateString >> ${dateString}`);
      setTransferDt(date);
      //setNewTransferDt(date);
    };

    

    //#endregion


    const styles = {
        pageHeaderContainer: {
          justifyContent: isMobile ? "" : "space-between",
          display: "flex",
          flexDirection: isMobile ? "column" : "",
          alignItems: isMobile ? "center" : "",
        },
        refreshBtn: {
          marginLeft: 10,
        },
        memberBtn: {
          width:"150px", 
          marginBottom:"8px"
        },
        tabelContainer: {
          backgroundColor: "#FFFFFF",
          padding: 24,
        },
        tableDivide50: {
          height:"60%",
        },
        tableDivide40: {
          height:"30%",
        },
        paginationStyle: {
          marginTop: 50,
        },
        formItem: {
          width:"80%"
        },
        datePickerStyle: {
          width: 260,
        },
        hide : {
          display:"none",
        },
        bankAmountStyle:{
          marginLeft:"auto",
          marginRight:0,
        },
        tagStyle:{
          width:"92px",
        },
        memberSearchStyle:{
          width:180,
        },
        memberTrxnStyle:{
          marginLeft:"280px",
        },
        editTransferStyle:{
          display: "table-cell",
          width  : "100px",
          
        },
        editTransferOutStyle:{
          display: "none"
        },
        addBankTabStyle:{
          backgroundColor:"#f5f3f3"
        },
        addBankFormStyle:{
          // height:"450px",
          marginLeft: "2.0%",
          marginTop: "1.1%",
        },
        editBankFormStyle:{
          marginLeft: "2.0%",
          marginTop: "1.1%",
        },
        headerTooltipStyle:{
          color: "#1890ff",
          marginLeft:"5px",
          fontSize:"14px",
        },
        overlayStyle:{
          marginLeft:"-40px",
        },
        bankTypeDetailsStyle:{
          backgroundColor:"green"
        },
        tableHeader:{
          fontWeight:"bolder",
        },
        marginTop5pc:{
          marginTop:"5%",
        },
        alignRight:{
          textAlign:"right"
        },
        attn: {
          fontSize:"20px",
        },
        noHighlight : {
          MozUserSelect: "none",
          WebkitUserSelect: "none",
          msUserSelect: "none"
        },
        modalWide : {
           top : "2%" , 
          marginLeft:"20.55%"
        },
        authorized : {
          display : issuper ? "block":"none",
        },
      };

    return (
        <div>
          {
            repAccRecVw(role, compCode)?
            <>
              <PageHeader ghost={false}>
              <Modal visible={withTrxnVisible}
                  maskClosable={false}
                  cancelButtonProps={{ type: "primary" }}
                  okButtonProps={{ style:{display: "none"} }}
                  cancelText={"Close"}
                  onCancel={onCancelWithTrxn}
                  title= "Withdrawal Amount By Bank"
                  style={styles.modalWide}
                  width={1300}
              >
                <CTable loading={withTrxnLoading} data={withTrxnData} columnSource={"withdrawTrxn"} paginate={false}/>
              </Modal>
              <Modal visible={editTransferVisible}
                okText={"Save"}
                maskClosable={false}
                cancelButtonProps={{ type: "primary" }}
                okButtonProps={{ style:{display: "none"} }}
                cancelText={"Close"}
                onCancel={onCancelEditTransfer}
                style={styles.modalWide}
                width={1300}
              >
                  {onEditTransferForm(transferDt)}
              </Modal>
              <div style={styles.pageHeaderContainer}>
                <div style={{float:"left"}}>
                <DatePicker
                    key="1"
                    style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                    onChange={trxDatePicker}
                    value={trxDateFrom}
                    ref={reportDateFrom}
                    refs
                    placeholder={"Select Date From"}
                    disabledDate={(current)=> {
                      return current > moment().endOf('day');
                      //return current > moment().subtract(1, 'day');
                    }}/>
                  <DatePicker
                    key="2"
                    style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                    onChange={trxDatePicker2}
                    value={trxDateTo}
                    ref={reportDateTo}
                    placeholder={"Select Date To"}
                    disabledDate={(current)=> {
                      let dateFr = trxDateFrom;
                      if (dateFr) {
                        return current < dateFr || current > moment().add(1, 'day').add(-1, 'minute');
                        //return current < dateFr || current > moment().add(1, 'day');
                      }else {
                        return  current > moment().subtract(1, 'day');
                      }
                    }}
                    defaultPickerValue={()=>{
                        let dateFr = trxDateFrom;
                        if (dateFr) {
                            return dateFr;
                        }
                    }}
                    />
                    <Button
                      type={"primary"}
                      style={styles.searchResetStyle}
                      icon={<SearchOutlined />}
                      onClick={searchTrxn}
                    />
                    {/* <Button 
                        type={"primary"} 
                        id="export-button-1"
                        style={{...styles.refreshBtn}} 
                        icon={<FileExcelOutlined />}
                        onClick={() => {
                            onClickExportBtn();
                        }}  
                    >
                    Export to Excel
                  </Button> */}
                </div>
              </div>
            </PageHeader>
            <div style={styles.tabelContainer}>
                  <Tabs defaultActiveKey="3">
                    <TabPane tab={BANK_INFO_TYPE.DEP} key="3">
                      <div>Expandable:  <Switch  checkedChildren="ON" unCheckedChildren="OFF" onChange={()=>setDepExpand(!depExpand)}/></div>
                      <Table
                          className="reconAcctWithTable"
                          rowClassName={(record)=>record.children? "reconAcctWithExpandRow" : record.date === 'Summary' ? null : "reconAcctWithTable"}
                          loading={tableLoading}
                          columns ={depositColumns}
                          dataSource= {depositData}
                          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                          showSorterTooltip
                          pagination={false}
                          expandedRowKeys={ depExpand ? null : depositData.map(item => item.key) }
                          expandable={{
                            expandIconColumnIndex: -1,
                            rowExpandable: record => record.date == 'Summary',
                          }}

                          exportableProps={{
                            depositColumns,
                            fileName: `AcctRecon_report-${moment(new Date()).format("YYYY-MM-DD")}.xlsx`,
                            btnProps: {
                              type: "primary",
                              icon: <FileExcelOutlined />,
                              id:"export-button-1",
                              children: <span>Export to Excel</span>,
                              style: {display:"none"}
                            },
                          }}
                          onChange={handleDepTableChange}
                          // summary={pageData => {
                          //   let totalDeposit = 0;
                          //   let totalUnclaimed = 0;
                          //   let totalWithdraw = 0;
                    
                          //   pageData.forEach(({ depositAmt, unClaimedAmt , withdrawAmt }) => {
                          //     totalDeposit += depositAmt;
                          //     totalUnclaimed += unClaimedAmt;
                          //     totalWithdraw += withdrawAmt;
                          //   });
                    
                          //   return (
                          //     <>
                          //       <Table.Summary.Row>
                          //         <Table.Summary.Cell>Total</Table.Summary.Cell>
                          //         <Table.Summary.Cell>
                          //           <Text type="danger">{FormatPt(totalDeposit)}</Text>
                          //         </Table.Summary.Cell>
                          //         <Table.Summary.Cell>
                          //         <Text type="danger" style={{textAlign:"right"}}>{FormatPt(totalUnclaimed)}</Text>
                          //         </Table.Summary.Cell>
                          //         <Table.Summary.Cell>
                          //         <Text type="danger">{FormatPt(totalWithdraw)}</Text>
                          //         </Table.Summary.Cell>
                          //       </Table.Summary.Row>
                          //     </>
                          //   );
                          // }}
                          />
                        <div style={{marginTop:30}}>        
                          <CLegend banktype={`${BANK_INFO_TYPE.DEP}`}/>
                        </div>
                    </TabPane>
                    <TabPane tab={BANK_INFO_TYPE.WIT}>
                        <div>Expandable:  <Switch  checkedChildren="ON" unCheckedChildren="OFF" onChange={()=>setWithExpand(!withExpand)}/></div>
                        <Table
                        className="reconAcctWithTable"
                          rowClassName={(record)=>record.children? "reconAcctWithExpandRow" : record.date === 'Summary' ? null : "reconAcctWithTable"}
                          loading={tableLoading}
                          columns ={withdrawColumns}
                          dataSource= {withdrawData}
                          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                          pagination={false}
                          expandedRowKeys={ withExpand ? null : withdrawData.map(item => item.key) }
                          expandable={{
                          expandIconColumnIndex: -1,
                            // expandedRowRender: record => <><CTableWithdraw datasource={`${record.withdrawTrxn}`} trxndate={`${record.date}`}/><CTableTransfer datasource={`${record.transferTrxn}`} trxndate={`${record.date}`}/></>,
                            rowExpandable: record => record.date !== 'Summary',
                          }}
                          // expandable={{
                          //   expandedRowRender: record =>
                          
                          //   <Table
                          //   loading={withdrawLoading}
                          //   columns ={withdrawColumns}
                          //   dataSource= {withdrawData}
                          //   scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                          //   pagination={false}
                          //   />,
                          //   rowExpandable: record => record.date !== 'Summary',
                          // }}
                          // expandable={{
                          //   expandedRowRender: record => <p style={{ margin: 0 }}>{`${record.date} || aaaa `}</p>,
                          //   rowExpandable: record => record.date !== 'Not Expandable',
                          // }}
                          exportableProps={{
                            withdrawColumns,
                            btnProps: {
                              type: "primary",
                              icon: <FileExcelOutlined />,
                              id:"export-button-2",
                              children: <span>Export to Excel</span>,
                              style: {display:"none"}
              
                            },
                          }}/>
                        <div style={{marginTop:30}}>
                          <CLegend banktype={`${BANK_INFO_TYPE.WIT}`}/>
                        </div>
                    </TabPane>
                  </Tabs>    
                {/* <div style={styles.tableHeader}>Deposit</div> */}
                {/* <Table
                  loading={depositLoading}
                  columns ={depositColumns}
                  dataSource= {depositData}
                  scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                  showSorterTooltip
                  pagination={false}
                  // pagination={{
                  //   defaultPageSize: 5,
                  //   showSizeChanger: true,
                  //   pageSizeOptions: settings.pageSizeOptions,
                  //   style: styles.paginationStyle,
                  //   alignmentBottom: 'right',
                  // }}
                  exportableProps={{
                    depositColumns,
                    btnProps: {
                      type: "primary",
                      icon: <FileExcelOutlined />,
                      id:"export-button-1",
                      children: <span>Export to Excel</span>,
                      style: {display:"none"}
      
                    },
                  }}
                  onChange={handleDepTableChange}
                  // summary={pageData => {
                  //   let totalDeposit = 0;
                  //   let totalUnclaimed = 0;
                  //   let totalWithdraw = 0;
            
                  //   pageData.forEach(({ depositAmt, unClaimedAmt , withdrawAmt }) => {
                  //     totalDeposit += depositAmt;
                  //     totalUnclaimed += unClaimedAmt;
                  //     totalWithdraw += withdrawAmt;
                  //   });
            
                  //   return (
                  //     <>
                  //       <Table.Summary.Row>
                  //         <Table.Summary.Cell>Total</Table.Summary.Cell>
                  //         <Table.Summary.Cell>
                  //           <Text type="danger">{FormatPt(totalDeposit)}</Text>
                  //         </Table.Summary.Cell>
                  //         <Table.Summary.Cell>
                  //         <Text type="danger" style={{textAlign:"right"}}>{FormatPt(totalUnclaimed)}</Text>
                  //         </Table.Summary.Cell>
                  //         <Table.Summary.Cell>
                  //         <Text type="danger">{FormatPt(totalWithdraw)}</Text>
                  //         </Table.Summary.Cell>
                  //       </Table.Summary.Row>
                  //     </>
                  //   );
                  // }}
                  />
                <div>        
                  <CLegend banktype={`${BANK_INFO_TYPE.DEP}`}/>
                </div> */}
                <div style={{...styles.tableHeader,...styles.marginTop5pc}}></div>
                  <Table
                    loading={trxnListLoading}
                    columns ={trxnColumns}
                    dataSource= {trxnListData}
                    scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                    pagination={{
                      defaultPageSize: 2000,
                    }}
                    style={{display:"none"}}
                    exportableProps={{
                      trxnColumns,
                      btnProps: {
                        type: "primary",
                        icon: <FileExcelOutlined />,
                        id:"export-button-3",
                        children: <span>Export to Excel</span>,
                        style: {display:"none"}
                      },
                    }}/>
                </div>
            </>
            :<div style={{textAlign:"center",color:"red",fontSize:"150%"}}><h1 style={{color:"red"}}>Not Allowed to Access This Page</h1></div>
          }
        </div>
    );
}