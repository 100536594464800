import React, { useEffect, useState } from "react";
import { Table, Badge, PageHeader, Button, message } from "antd";
import { useSelector } from "react-redux";
import { userMemberList } from "../../config/networkConfig";
import { isMobile } from "react-device-detect";

export default function AgentMemberManagement() {
  const { userId } = useSelector((state) => state.login);
  const [originData, setOriginData] = useState("");
  const [loadings, setLoadings] = useState(false);

  useEffect(() => {
    memberList();
    // eslint-disable-next-line
  }, []);

  const memberList = () => {
    var count = 0;
    setLoadings(true);
    userMemberList({ userId: userId })
      .then((response) => response.json())
      .then((json) => {
        console.log("userMemberList json: ", json);
        if (json.status === "ok") {
          setLoadings(false);
          const newData = Object.keys(json.data).length
            ? json.data.map((data) => {
                return {
                  key: count++,
                  nickname: data.nickname,
                  lastLogin: data.lastLogin,
                  status:
                    data.status === "0"
                      ? "Lock"
                      : data.status === "1"
                      ? "Active"
                      : "Inactive",
                  registerDate: data.registerDate,
                };
              })
            : [];
          setOriginData(newData);
          console.log("newData: ", newData);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        message.error(
          "An unexpected error occurred. Contact your system administrator "
        );
      });
  };

  const columns = [
    {
      align: "center",
      title: "Nickname",
      dataIndex: "nickname",
      width: 200,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Last Login",
      dataIndex: "lastLogin",
      width: 200,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Status",
      dataIndex: "status",
      width: 200,
      ellipsis: true,
      render: (text, record) => {
        switch (record.status) {
          case "Lock":
            return <Badge status="warning" text="Lock" />;
          case "Active":
            return <Badge status="success" text="Active" />;
          default:
            return <Badge status="error" text="Inactive" />;
        }
      },
    },
    {
      align: "center",
      title: "Register Date",
      dataIndex: "registerDate",
      width: 200,
      ellipsis: true,
    },
  ];

  const styles = {
    mainContainter: {
      padding: 30,
    },
  };

  return (
    <div style={styles.mainContainter}>
      <PageHeader
        ghost={false}
        extra={[
          <Button
            key="5"
            type="primary"
            onClick={() => {
              memberList();
            }}
          >
            Refresh Page
          </Button>,
        ]}
      >
        {/* <div style={{ textAlign: "center" }}>
          <div style={{ fontSize: 25 }}>Member List</div>
        </div> */}
      </PageHeader>
      <Table
        loading={loadings}
        columns={columns}
        dataSource={originData}
        scroll={{ x: isMobile ? "110%" : "100%", y: null }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20"],
          style: styles.paginationStyle,
        }}
      />
    </div>
  );
}
