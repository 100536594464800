import React, { useState, useEffect , useRef } from "react";
import {
  //Table,
  message,
  PageHeader,
  Button,
  Input,
} from "antd";
import { Table } from "ant-table-extensions";
import {
  reportMasterData,
  getLatestMasterFile
} from "../../../config/networkConfig";
import { useSelector } from "react-redux";
import { SearchOutlined} from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import { settings } from "../../../config/appConfig";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { FormatUNIXDate_YMDHM, FormatDate, FormatNullDate, FormatDateHHMM } from "../../../library/dates";
import { MasterDataVw } from "../../../library/permission";
import { uploadsFolder } from "../../../config/config";
import style from "../../../resource/style/style";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";

export default function ReportMasterData() {
  
  const { role, username, compCode  } = useSelector((state) => state.login);


  //Master Data
  const [statementData,         setStatementData] = useState("");
  const [statementPageSize, setStatementPageSize]  = useState(100);
  const [statementPageNum,  setStatementPageNum]  = useState(1);
  const [statementTotal,       setStatementTotal]  = useState(10);
  const [statTableLoading,   SetStatTableLoading] = useState(false);

  const [fileURL,                       setFileURL] = useState("");

  //Member Transaction 
  const [searchText, setSearchText] = useState("");
   
  
  useEffect(() => {
    // eslint-disable-next-line
    if (MasterDataVw(role, username, compCode)){
      initPage();
    }
    setDownloadURL();
  }, []);


  useEffect(()=> {
    setStatementPageNum(statementPageNum);
    setStatementPageSize(statementPageSize);
    setStatementTotal(statementTotal);
  },[statementPageNum, statementPageSize, statementTotal])

  //#region Load List
  const initPage = async () =>{
    await viewBankStatement(true);
  }

  const setDownloadURL = async ()=> {
    let fileName ='';

    const respOp = await getLatestMasterFile({report:'master_data'});
    let resp = await respOp.json(); 
    if (resp.status === "ok" && resp.data.length>0) {
        const respData    = resp.data;
        const msData = Object.keys(respData).length
          ? respData.map((obj) => {
              return {
                filename       : obj.filename,
              };
            })
          : [];

        fileName = msData[0].filename;
        setFileURL(`${uploadsFolder}/${fileName}`);
    }
    else{
        setFileURL("");
    }   
  }


  ////#endregion

  //#region Functions
  const viewBankStatement = async () => {
    getBankStatement('', '', statementPageNum, statementPageSize, {"phoneNum":null});
  }

  const getBankStatement = async(datefrom, dateto, pagenum, pagesize, filters) => {
    let countDL = 0;

    SetStatTableLoading(true);

    const responseDL = await reportMasterData({});
      let resultDL = await responseDL.json();
      if (resultDL.status === "ok") {
        const dlData = Object.keys(resultDL.data.detail).length
        ? resultDL.data.detail.map((obj) => {
            SetStatTableLoading(false);
            return {
              key               : ++countDL,
              phoneNum          : obj.phoneNum,
              gen1DL            : obj.gen1DL,
              walBal            : obj.walBal,
              commBal           : obj.commBal,
              m1AngPao          : obj.m1AngPao,
              depDiscTotal      : obj.depDiscTotal,
              depTotalAmt       : obj.depTotalAmt,
              depTotalCnt       : obj.depTotalCnt,
              depLastDate       : obj.depLastDate,
              depMaxAmt         : obj.depMaxAmt,
              witBankTotalAmt   : obj.witBankTotalAmt,
              witBankTotalCnt   : obj.witBankTotalCnt,
              witCommTotal      : obj.witCommTotal,
              selfRefCommTotal  : obj.selfRefCommTotal,
              commTotal         : obj.commTotal,
              turnoverTotal     : obj.turnoverTotal,
              lastLoginTime     : obj.lastLoginTime,
              depLastDate       : obj.depLastDate,
              gameUser          : obj.gameuser
            };
          })
        : [];

        setStatementData(dlData);

        const dlDataSummary = Object.keys(resultDL.data.summary).length
                      ? resultDL.data.summary.map((x) => {
                        return {
                            current : x.current,
                            pagesize: x.pagesize,
                            totalTrxn : x.totalTrxn
                        };
                      })
                      : [];

          
          setStatementTotal(dlDataSummary?.[0]?.totalTrxn ?? 10 );

          SetStatTableLoading(false);
        }
        else {
          alertData.title = "Failed";
          alertData.msg = resultDL.msg;
          sharedFailAlert(alertData);  
      }

      SetStatTableLoading(false);

  }

  const handleTableChange = async (pagination, filters, sorter)=> {
    
    await getBankStatement('','', pagination.current, pagination.pageSize , filters);

    await setStatementPageNum(pagination.current);

    await setStatementPageSize(pagination.pageSize);

  }



  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 6 , marginRight : "-6.1rem", background:"white" }} >
          <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });
  ////#endregion

  
  //#region Columns
  //Display Columns
  let masterDataColumns =  [
    {
      align: "center",
      title: <div>Phone<br/>No.</div>,
      dataIndex:"phoneNum",
      width: "20%",
      ...getColumnSearchProps("phoneNum"),

    },
    {
      align: "center",
      title: <div>Downline Count <br/>(1st Gen.)</div>,
      dataIndex:"gen1DL",
      width: "9%",
      //...getColumnSearchProps("gen1DL"),
    },
    {
      align: "center",
      title: <div>Wallet <br/>Bal.</div>,
      dataIndex:"walBal",
      width: "10%",
      //...getColumnSearchProps("walBal"),
    },
   {
      align: "center",
      title: <div>Comm. <br/>Bal.</div>,
      dataIndex:"commBal",
      width: "10%",
      //...getColumnSearchProps("commBal"),
    },
    {
      align: "center",
      title: <div>Success Claim <br/>Pao</div>,
      dataIndex:"m1AngPao",
      width: "10%",
      //...getColumnSearchProps("m1AngPao"),
    },
    {
      align: "center",
      title: <div>Total Dep.<br/>(Deposit Discount)</div>,
      dataIndex:"depDiscTotal",
      width: "10%",
      //...getColumnSearchProps("depDiscTotal"),
    },
    {
      align: "center",
      title: <div>Total Dep.<br/>Amt.</div>,
      dataIndex:"depTotalAmt",
      width: "10%",
      //...getColumnSearchProps("depDiscTotal"),
    },

    {
      align: "center",
      title: <div>Total Dep.<br/>Count</div>,
      dataIndex:"depTotalCnt",
      width: "10%",
      //...getColumnSearchProps("depTotalCnt"),
    },
    {
        align: "center",
        title: <div>Highest <br/>Dep.</div>,
        dataIndex:"depMaxAmt",
        width: "10%",
        //...getColumnSearchProps("depMaxAmt"),
    },
    {
      align: "center",
      title: <div>Total Withdraw <br/>Amt.</div>,
      dataIndex:"witBankTotalAmt",
      width: "10%",
    },
    {
      align: "center",
      title: <div>Total Withdraw <br/>Count</div>,
      dataIndex:"witBankTotalCnt",
      width: "10%",
    },
    {
      align: "center",
      title: <div>Highest <br/> Withdraw</div>,
      dataIndex:"witBankMaxAmt",
    },
    {
      align: "center",
      title: <div>Total <br/> Comm.</div>,
      dataIndex:"witCommTotal",
    },
    {
      align: "center",
      title: <div>Total Comm.<br/>(0.2% + 0.1%)</div>,
      dataIndex:"selfRefCommTotal",
    },
    {
      align: "center",
      title: <div>Total Comm.<br/>(0.5%)</div>,
      dataIndex:"commTotal",
    },
    {
      align: "center",
      title: <div>Total <br/>Turnover</div>,
      dataIndex:"turnoverTotal",
    },
    {
      align: "center",
      title: <div>Last <br/>Login</div>,
      dataIndex:"lastLoginTime",
    },
    {
      align: "center",
      title: <div>Last <br/>Deposit</div>,
      dataIndex:"depLastDate",
    },
    {
      align: "center",
      title: <div>Game <br/>User</div>,
      dataIndex:"gameuser",
    },
  ];
  ////#endregion

  return (
    <div>
        {
          MasterDataVw(role, username, compCode)?

          <div style={style.tabelContainer} id="page-operation-report">
              <PageHeader ghost={false}>
                      <div style={style.pageHeaderContainer}>
                        <div style={{float:"left"}}>
                          {
                            (!fileURL) ?
                              <></>
                            :                     
                            <Button
                                style={{...style.refreshBtn}}
                                type="primary"
                                onClick={() => {
                                  window.open(fileURL);
                                }}
                                >
                                Export to Excel
                            </Button>
                          }     
                        </div>
                      </div>
              </PageHeader>
              <div>
                  <Table
                    className="operationsList"
                    style={{marginTop:"10px"}}
                    columns={masterDataColumns}
                    dataSource={statementData}
                    pagination={{
                      defaultPageSize: 100,
                      showSizeChanger: true,
                      pageSizeOptions: settings.pageSizeOptionsLarge,
                      style: style.paginationStyle,
                      alignmentBottom: 'right',
                      total: statementTotal,
                      showTotal: total => `Total ${statementTotal}  Member(s)`,
                    }}
                    loading={statTableLoading}
                    onChange={handleTableChange}
                  />
              </div>
      </div>:
      <div style={{textAlign:"center",color:"red",fontSize:"150%"}}><h1 style={{color:"red"}}>Not Allowed to Access This Page</h1></div>
        }
    </div>
  );
}
