import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  SearchOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { 
  reportSmsCosting,
 } from "../../../config/networkConfig";
import { Table } from "ant-table-extensions";
import {
  PageHeader,
  Button,
  Breadcrumb,
  DatePicker,
  Select,
} from "antd";
import { isMobile } from "react-device-detect";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { settings } from "../../../config/appConfig";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { ReportSmsCostingVw } from "../../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";


export default function ReportSmsCosting() {

  const { Option } = Select;
  const { userId , role, username, compCode } = useSelector((state) => state.login);
  const [report, setReport] = useState("");
  const [allReport, setAllReport] = useState("");
  const [todayDate, setTodayDate] = useState("");
  const [tableLoading ,          setTableLoading] = useState(false);
  const [trxLoading, setTrxLoading] = useState(false);
  const [date, setDate] = useState("");
  const reportDate = useRef(null);

  useEffect(() => {
    // if (ReportSmsCostingVw(role, username)){
    // reportallReportList();
    // }
    //reportShareDetailList();
    // eslint-disable-next-line
  }, []);

  useEffect(()=> {
    setDate(date);
  },[date]);

  const onClickExportBtn = ()=> {

    if (allReport.length === 0 ) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }

    document.getElementById("export-button-1").click();
  }

  const resetBtn = () => {
    setAllReport([]);
  };

  const trxDatePicker = (date, dateString) => {
    // setType("custom");
    setDate(date);
  };

  const searchBar = () => {
    if (!date) {
      reportDate.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date!";
      sharedErrorAlert(alertData);
      return;
    }

    setTrxLoading(true);
    reportallReportList();
  }

  const reportallReportList = () => {
    setTableLoading(true);
    var count = 1;
    let requestData = {
      date : date.format("YYYY-MM")
    }

    reportSmsCosting(requestData)
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
        const allReport = Object.keys(json.data).length
        ? json.data.map((data) => {
            setTableLoading(false);
            return {
              TrxnDate              : data.TrxnDate,
              TotalSms              : data.TotalSms,
            };
            })
            : [];

            let todayDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
            setTodayDate(todayDate);
            setAllReport(allReport);

          if (allReport.length == 0 ) {
            alertData.title = "Error";
            alertData.msg = "No data !";
            sharedErrorAlert(alertData);
            setTableLoading(false);
            return;
          }
        }
        else {
          alertData.title = "Error";
          alertData.msg = json.msg;
          sharedErrorAlert(alertData);
          setTableLoading(false);
        }        
      });
  };

  const columns = [
    {
      align: "center",
      title: "Date",
      dataIndex: "TrxnDate",
      width: 80,
      ellipsis: true,
      fixed:"left",
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "Total SMS",
      dataIndex: "TotalSms",
      width: 80,
      ellipsis: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      },
    },
  ];

  const routes = [
    {
      path: "/",
      breadcrumbName: "SMS Costing Report",
    },
    {
      //   path: "first",
      breadcrumbName: "Report",
    },
    {
      path: "commBalanceReport",
      breadcrumbName: "SMS Costing Report",
    },
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 2;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    inlineBlock: {
      display: "inline-block",
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      // display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
      display: "inline",
    },
    resetBtn: {
      marginLeft: 10,
    },
    inline: {
      display: "inline",
    },
    hide: {
      marginLeft: 10000,
    },
    datePickerStyle: {
      width: 280,
    },
    datePickerSelectionStyle: {
      marginLeft: 10,
      width: 120,
    },
    refreshBtn: {
      marginLeft: 10,
    },
    headStyles: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 20,
      height: 20,
    },
    bodyStyle: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 60,
    },
    tagStyle:{
      width:"100px",
    },
    memberBtn: {
      marginTop:"10px",
      width:"150px", 
      marginBottom:"8px"
    }, 
  };

  return (
    <div>
      {
        ReportSmsCostingVw(role,username,compCode) ?
        
        <div className="eachPageContent">
        {
          <div>
            <PageHeader
              ghost={false}
              // title="Game Stats Stats Report"
            >
              <div style={styles.pageHeaderContainer}>
                <DatePicker
                    key="1"
                    style={styles.datePickerStyle}
                    onChange={trxDatePicker}
                    value={date}
                    picker="month"
                    ref={reportDate}
                    placeholder="Select a Month"
                  />
                <div style={styles.inlineBlock}>
                  <Button
                    type={"primary"}
                    icon={<SearchOutlined
                    onClick={searchBar}
                    />}
                  />
                  <Button style={{...styles.resetBtn,...styles.refreshBtn}} onClick={resetBtn}>
                    Reset
                  </Button>
                </div>
                <Button 
                      type={"primary"} 
                      style={styles.refreshBtn} 
                      icon={<FileExcelOutlined />}
                      onClick={() => {
                          onClickExportBtn();
                      }}
                      
                  >
                    Export to Excel
                </Button>
              </div>
            </PageHeader>
            <div style={styles.tableContainer}>
              <Table
                loading={tableLoading} 
                columns={columns}
                dataSource={allReport}
                // className="cs_gameStat"
                scroll={{ x: 100, y: null }}
                exportable={true}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: settings.pageSizeOptionsSmall,
                  style: styles.paginationStyle,
                }}
                exportableProps={{
                  // feedFields,
                  fileName: "SmsCostingReport_"+todayDate,
                  btnProps: {
                    type: "primary",
                    icon: <FileExcelOutlined />,
                    id:"export-button-1",
                    children: <span>Export to Excel</span>,
                    style: {display:"none"}
                  },
                }}
              />
            </div>
          </div>
        }
        </div>:
        <div style={{textAlign:"center",color:"red",fontSize:"150%"}}><h1 style={{color:"red"}}>Not Allowed to Access This Page</h1></div>
      }
    </div>
      
  );
}