import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  message,
  PageHeader,
  Button,
  Input,
  DatePicker,
  Modal,
  Spin,
  Form,
  Select,
  Radio,
  Tabs,
  Tooltip,
} from "antd";
import {
    csDownlineTopupList,
    csDownlineTopupListSummary,
    csDownlineTopupUpdate,
} from "../../config/networkConfig";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import moment from "moment";
import { settings } from "../../config/appConfig";
import { 
  SearchOutlined,
  PhoneOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import arrowRight from '../../assets/image/arrow_right.png';
import { refCommApprRej, refCommSearchApprRej, refCommSearchSuperCS } from '../../library/permission';
import style from "../../resource/style/style";
import { REF_COMM_SEARCH_MOBILE } from "../../common/constants/constant";
import { CLegendRefComm } from "./report/components/CLegendRefComm";
import { CS_USER_TYPE } from "../../common/constants/csuserType";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";

export default function RefCommApproval() {
  const { issuper , username, role, compCode } = useSelector((state) => state.login);
  const { TextArea } = Input;
  const {TabPane} = Tabs;
  const [form] = Form.useForm();
  const modalForm = useRef();
  const { Option } = Select;
  const { Search } = Input;

  const [searchText, setSearchText] = useState("");
  const [searchMobile,setSearchMobile] = useState("");
  const [searchDate, setSearchDate]    = useState("");

  const [trxDateFrom, setTrxDateFrom] = useState("");
  const [trxDateTo, setTrxDateTo] = useState("");
  const rptDateFrom = useRef(null);
  const rptDateTo = useRef(null);

  const [status, setStatus] = useState("Today");

  //Downline Top 
  const [downlineData,         setDownlineData]   = useState("");
  const [downlinePageSize, setDownlinePageSize]   = useState(10);
  const [downlinePageNum,   setDownlinePageNum]   = useState(1);
  const [downlineTotal,       setDownlineTotal]   = useState(0);
  const [statTableLoading,   SetStatTableLoading] = useState(false);
    
  //Dialog box
  const [editVisible,                       setEditVisible] = useState(false);
  const [downlineUserId,                 setDownlineUserId] = useState("");
  const [downlineUsername,             setDownlineUsername] = useState("");
  const [downlineUserMobile,         setDownlineUserMobile] = useState("");
  const [downlineReferId,               setDownlineReferId] = useState("");
  const [downlineRefername,           setDownlineRefername] = useState("");
  const [downlineReferMobile,       setDownlineReferMobile] = useState("");
  const [downlineReferBanks,         setDownlineReferBanks] = useState("");
  const [downlineStatus,                 setDownlineStatus] = useState("");
  const [newDownlineStatus,           setNewDownlineStatus] = useState("");
  const [downlineActStatus,           setDownlineActStatus] = useState("");
  const [downlineNewStatus,          setDownlineNewStatus]  = useState("");
  const [downlineRemark,                 setDownlineRemark] = useState("");
  const [downlineTopupSubmit,       setDownlineTopupSubmit] = useState(true);
  const refDownlineRemark                                   = useRef(null);
  const refActApprove                                       = useRef(null);
  const [editActionTitle,               setEditActionTitle] = useState("");
      
  //Dashboard
  const [dashToday, setDashToday] = useState(0);
  const [dashYesterday, setDashYesterday] = useState(0);
  const [dashKIV,setDashKIV] = useState(0);
  const [dashTotal, setDashTotal]   = useState(0);
  const [dashProcess,setDashProcess] = useState(" 0/0"); 
  const [dashARK, setDashARK] = useState("0 / 0 / 0"); //Approve / Rejected / KIV 
  const [dashApproved, setDashApproved] = useState(0);

  //Search Records
  const [searchStatus, setSearchStatus] = useState("Today");
  const [recMobile, setRecMobile] = useState("");

  //Search Downline Top 
  const [searchDownlineData,         setSearchDownlineData]   = useState("");
  const [searchDownlinePageSize, setSearchDownlinePageSize]   = useState(10);
  const [searchDownlinePageNum,   setSearchDownlinePageNum]   = useState(1);
  const [searchdownlineTotal,       setSearchDownlineTotal]   = useState(0);
  const [searchStatTableLoading, setSearchStatTableLoading] = useState(false);

  //Dialog box
  const [editSearchVisible,               setEditSearchVisible] = useState(false);
  const [searchDownlineUserId,         setSearchDownlineUserId] = useState("");
  const [searchDownlineUsername,     setSearchDownlineUsername] = useState("");
  const [searchDownlineUserMobile, setSearchDownlineUserMobile] = useState("");
  const [searchDownlineReferId,       setSearchDownlineReferId] = useState("");
  const [searchDownlineRefername,   setSearchDownlineRefername] = useState("");
  const [serachDownlineReferMobile, setSearchDownlineReferMobile] = useState("");
  const [searchDownlineReferBanks,   setSearchDownlineReferBanks] = useState("");
  const [searchDownlineStatus,           setSearchDownlineStatus] = useState("");
  const [searchNewDownlineStatus,           setSearchNewDownlineStatus] = useState("");
  const [searchDownlineActStatus,           setSearchDownlineActStatus] = useState("");
  const [searchDownlineNewStatus,          setSearchDownlineNewStatus]  = useState("");
  const [searchDownlineRemark,                 setSearchDownlineRemark] = useState("");
  const [searchDownlineTopupSubmit,       setSearchDownlineTopupSubmit] = useState(true);
  const refSearchDownlineRemark                                   = useRef(null);
  const refSearchActApprove                                       = useRef(null);
  const [editSearchActionTitle,               setSearchEditActionTitle] = useState("");
  const refRecMobile                                        = useRef(null);
      
  // //Dashboard
  const [searchDashToday, setSearchDashToday] = useState(0);
  const [searchDashYesterday, setSearchDashYesterday] = useState(0);
  const [searchDashKIV,setSearchDashKIV] = useState(0);
  const [searchDashTotal, setSearchDashTotal]   = useState(0);
  const [searchDashProcess,setSearchDashProcess] = useState(" 0/0"); 
  const [searchDashARK, setSearchDashARK] = useState("0 / 0 / 0"); //Approve / Rejected / KIV 


  
  let statusList = 
  [
    {
       text: 'Approved',
       value: 'approved',
    },
    {
        text: 'Reject',
        value: 'reject',
     },
     {
        text: 'Pending',
        value: 'pending',
     },
     {
      text: 'KIV',
      value: 'kiv',
   },

  ]



  useEffect(() => {
    getDownlineSummary();
    viewDownlineTopup();
  }, [status]);

  useEffect(()=> {
    setDownlinePageNum(downlinePageNum);
    setDownlinePageSize(downlinePageSize);
    setDownlineTotal(downlineTotal);
  },[downlinePageNum, downlinePageSize, downlineTotal])

  useEffect(()=> {
    setNewDownlineStatus(newDownlineStatus);
  },[newDownlineStatus])
  ////#endregion

  //#region Functions
  // Tab "Search" Records
  const handleSearchTableChange = async (pagination, filters, sorter)=> {

    if (!recMobile) {
      refRecMobile.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please enter Mobile Number !";
      sharedErrorAlert(alertData);
      return;
    }

    await searchDownlineTopup('', '', pagination.current, pagination.pageSize, { mobile: recMobile, type: REF_COMM_SEARCH_MOBILE});

    await setSearchDownlinePageNum(pagination.current);

    await setSearchDownlinePageSize(pagination.pageSize);

  }

  const vwSearchDownlineTopup = async () => {

    if (!recMobile) {
      refRecMobile.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please enter Mobile Number !";
      sharedErrorAlert(alertData);
      return;
    }

    searchDownlineTopup('', '', downlinePageNum, downlinePageSize, {mobile:recMobile, type : REF_COMM_SEARCH_MOBILE});
    searchDownlineSummary();
  }

  const vwSearchDownlineTopupStatus = async () => {
    if (searchStatus.toLowerCase()=="approved") {
      searchDownlineTopup('', '', downlinePageNum, downlinePageSize, {mobile:recMobile, type : REF_COMM_SEARCH_MOBILE});
      searchDownlineSummary();
    }
  }

  const searchDownlineSummary = async () => {

    try {
      const responseDL = await csDownlineTopupListSummary({type: REF_COMM_SEARCH_MOBILE});
      let resultDL = await responseDL.json();
      if (resultDL.status === "ok") {

        const summaryData = Object.keys(resultDL.data).length
        ? resultDL.data.map((obj) => {
            SetStatTableLoading(false);

            let unprocessed     = parseFloat(obj.inviteTotal) - ( parseFloat(obj.inviteApproved) + parseFloat(obj.inviteRejected));

            let processStatement = `${obj.inviteProcess}/${obj.inviteTotal}`;
            let arkStatement     = `${obj.inviteApproved} / ${obj.inviteRejected} / ${obj.inviteKIV} `;

            setSearchDashToday(obj.inviteToday);
            setSearchDashYesterday(obj.inviteYesterday);
            setSearchDashKIV(obj.inviteKIV);
            setSearchDashProcess(processStatement);
            setSearchDashTotal(unprocessed);
            setSearchDashARK(arkStatement);

            return {
              inviteToday:  obj.inviteToday || 0,
              inviteYesterday: obj.inviteYesterday || 0,
              inviteKIV: obj.inviteKIV || 0,
              inviteProcess: obj.inviteProcess || 0,
              inviteTotal: obj.inviteTotal || 0,
              inviteApproved: obj.inviteApproved || 0,
              inviteRejected: obj.inviteRejected || 0,
            };
          })
        : [];

        SetStatTableLoading(false);
      }
      else {
        alertData.title = "Failed";
        alertData.msg = resultDL.msg;
        sharedFailAlert(alertData);
      }

      SetStatTableLoading(false);
    }catch(ex) {
      //message.error("Page failed to load.Please try again later");
    }
  }

  const searchDownlineTopup = async(datefrom, dateto, pagenum, pagesize, filters) => {

    let startDate = new moment("2021-01-01","YYYY-MM-DD HH:mm");
    let endDate = new moment(moment(),"YYYY-MM-DD HH:mm");
    startDate = startDate.format("YYYY-MM-DD HH:mm");
    endDate = endDate.format("YYYY-MM-DD HH:mm");

    if (searchStatus!= null) {
      filters.status = searchStatus;
    }

    //   if (status.toLowerCase() =="yesterday") {
    //     startDate = new moment(moment(),"YYYY-MM-DD HH:mm").subtract(1,"days");
    //     endDate = new moment(moment(),"YYYY-MM-DD HH:mm");
    //     startDate = startDate.format("YYYY-MM-DD 00:00:00");
    //     endDate = endDate.format("YYYY-MM-DD 00:00:00");
    //   }

    //   if (status.toLowerCase() =="today") {
    //     startDate = new moment(moment(),"YYYY-MM-DD HH:mm")
    //     endDate = new moment(moment(),"YYYY-MM-DD HH:mm").add(1,"days");
    //     startDate = startDate.format("YYYY-MM-DD 00:00:00");
    //     endDate = endDate.format("YYYY-MM-DD 00:00:00");
    //   }

    //   if (status.toLowerCase() =="kiv"){
    //      filters = {
    //        ...filters,
    //        status : "kiv",
    //        uplinename : filters.uplinename,
    //      }
        
    //      startDate = new moment("2020-01-01","YYYY-MM-DD HH:mm")
    //      endDate = new moment(moment(),"YYYY-MM-DD HH:mm").add(1,"days");
    //      startDate = startDate.format("YYYY-MM-DD 00:00:00");
    //      endDate = endDate.format("YYYY-MM-DD 00:00:00");
    //   }

    //   if (status.toLowerCase() =="all"){
    //     filters = {
    //       ...filters,
    //       //status : "pending",
    //       uplinename : filters.uplinename,
    //     }
       
    //     startDate = new moment("2020-01-01","YYYY-MM-DD HH:mm")
    //     endDate = new moment(moment(),"YYYY-MM-DD HH:mm").add(1,"days");
    //     startDate = startDate.format("YYYY-MM-DD 00:00:00");
    //     endDate = endDate.format("YYYY-MM-DD 00:00:00");
    //  }

    let refDetails , dlnDetails , refStats;

      let countDL = 0;

      let requestData = {
        datefrom  : startDate,
        dateto    : endDate,
        page      : pagenum,
        pagesize  : pagesize,
        filters   : filters, 
      }

      console.log(`reqiest Data >> ${JSON.stringify(requestData)}, status >> ${status}`);

    setSearchStatTableLoading(true);

    try {
      const responseDL = await csDownlineTopupList(requestData);
      let resultDL = await responseDL.json();
      if (resultDL.status === "ok") {
        const dlData = Object.keys(resultDL.data.detail).length
        ? resultDL.data.detail.map((obj) => {
            SetStatTableLoading(false);

            refDetails = obj.refDetails;
            dlnDetails = obj.downlineDetails;
            refStats   = obj.refStats;
            
            return {
              key            : ++countDL,
              userId         : dlnDetails?.userId || '',
              username       : dlnDetails?.username || '',
              mobile         : dlnDetails?.mobile ||'',
              devId          : dlnDetails?.devId || '',
              upline         : refDetails?.userId || '',
              uplinename     : refDetails?.name || '',
              uplinemobile   : refDetails?.mobile ||'',
              refDetails     : refDetails,
              dlnDetails     : dlnDetails,
              refStats       : refStats,
              status         : obj.status,
              sysCreateTime  : obj.sysCreateTime,
              sysUpdateTime  : obj.sysUpdateTime == "1970-01-01 08:00:00" ? "" :obj.sysUpdateTime ,
              sysUpdateby    : obj.sysUpdateBy,
              sysProcessTime : obj.sysProcessTime,
              actionStatus   : obj.status === 0 ? "Pending" :obj.status === 7 ? "KIV" : obj.status === 9 ? "Reject" : obj.status === 1 ? "Approved" : "-",
              remark         : obj.remark,
              
            };
          })
        : [];

        let newDlData = dlData;

        setSearchDownlineData(newDlData);

        const dlDataSummary = Object.keys(resultDL.data.summary).length
                      ? resultDL.data.summary.map((x) => {
                        return {
                            current : x.current,
                            pagesize: x.pagesize,
                            totalTrxn : x.totalTrxn
                        };
                      })
                      : [];

        setSearchDownlineTotal(dlDataSummary?.[0]?.totalTrxn ?? 10);

        setSearchStatTableLoading(false);

      }
      else {
        alertData.title = "Failed";
        alertData.msg = resultDL.msg;
        sharedFailAlert(alertData);
      }

      setSearchStatTableLoading(false);
    }
    catch(ex) {
      alertData.title = "Error";
      alertData.msg = "Page failed to load. Please try again later.";
      sharedErrorAlert(alertData);
    }

    setSearchStatTableLoading(false);
  }

  const onChangeSearchDownlineStatus = (e) => {
    setSearchNewDownlineStatus(e.target.value);
  }
  ////#endregion

  //#region "Unfiltered" Listing
  const handleRecord = (record , action, type='') => {
    if (type == '') {
      setEditVisible(true);

      if (record.dlnDetails == null || record.refDetails == null) {
        alertData.title = "Failed";
        alertData.msg = "Unable to retrieve data";
        sharedFailAlert(alertData);
        setEditVisible(false);
        return;
      }

      setDownlineUserId(record.dlnDetails?.userId);
      setDownlineUserMobile(record.dlnDetails?.mobile);
      setDownlineUsername(record.dlnDetails?.name);
      setDownlineReferId(record.refDetails?.userId);
      setDownlineReferMobile(record.refDetails?.mobile);
      setDownlineRefername(record.refDetails?.name);
      setDownlineStatus(record.status);
      setDownlineActStatus(record.actionStatus);
      setDownlineRemark(record.remark);

      if (action == 'approve') {
        setEditActionTitle("Approve Referral Invite");
      }
      else if (action == 'reject') {
        setEditActionTitle("Reject Referral Invite");
      }
      else if (action == 'kiv') {
        setEditActionTitle('KIV Referral Invite');
      }
    }

    console.log(`action`,action)

    if (type == 'search') {
      setEditSearchVisible(true);

      if (record.dlnDetails == null || record.refDetails == null) {
        alertData.title = "Failed";
        alertData.msg = "Unable to retrieve data";
        sharedFailAlert(alertData);
        setEditSearchVisible(false);
        return;
      }
      
      setSearchDownlineUserId(record.dlnDetails?.userId);
      setSearchDownlineUserMobile(record.dlnDetails?.mobile);
      setSearchDownlineUsername(record.dlnDetails?.name);
      setSearchDownlineReferId(record.refDetails?.userId);
      setSearchDownlineReferMobile(record.refDetails?.mobile);
      setSearchDownlineRefername(record.refDetails?.name);
      setSearchDownlineStatus(record.status);
      setSearchDownlineActStatus(record.actionStatus);
      setSearchDownlineRemark(record.remark);
  
      if (action == 'approve') {
        setSearchEditActionTitle("Approve Referral Invite");
      }
      else if (action == 'reject') {
        setSearchEditActionTitle("Reject Referral Invite");
      }
      else if (action == 'kiv') {
        setSearchEditActionTitle('KIV Referral Invite');
      }
    }
  }

  const handleDownlineTopup = async (type='') => {
    if (type == '') {
      if (!newDownlineStatus) {
        refActApprove.current.focus();
        alertData.title = "Failed";
        alertData.msg = "Please select an Action !";
        sharedFailAlert(alertData);
        return;
      }
    
      let requestData = {
        userid: downlineUserId,
        status: newDownlineStatus,
        csUsername: username,
        remark: downlineRemark,
      }

      setDownlineTopupSubmit(false);

      const result =  await csDownlineTopupUpdate(requestData);
      let resp = await result.json();

      if (resp.status === "ok") {
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData);
        handleReasonCancel();
        setEditVisible(false);
        viewDownlineTopup();   
      }
      else{
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);
      }

      setDownlineTopupSubmit(true);
      viewDownlineTopup();
      getDownlineSummary();
    }

    console.log(`searchNewDownlineStatus >> ${searchNewDownlineStatus}`);

    if (type == 'search'){
      if (!searchNewDownlineStatus) {
        refSearchActApprove.current.focus();
        alertData.title = "Failed";
        alertData.msg = "Please select an Action !";
        sharedFailAlert(alertData);
        return;
      }
    
      let requestData = {
        userid: searchDownlineUserId,
        status: searchNewDownlineStatus,
        csUsername: username,
        remark: searchDownlineRemark,
      }

      setSearchDownlineTopupSubmit(false);

      const result =  await csDownlineTopupUpdate(requestData);
      let resp = await result.json();

      if (resp.status === "ok") {
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData);
        handleReasonCancel("search");
        setEditSearchVisible(false);
        vwSearchDownlineTopup();
      }
      else{
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);
      }

      setSearchDownlineTopupSubmit(true);
      viewDownlineTopup();
      getDownlineSummary();
    }
  }

  const handleReasonCancel = (type='') => {

    if (type == ''){
      setEditVisible(false);
      setDownlineUserId("");
      setDownlineUserMobile("");
      setDownlineUsername("");
      setDownlineReferId("");
      setDownlineReferMobile("");
      setDownlineRefername("");
      setDownlineActStatus("");
      setDownlineRemark("");
      setNewDownlineStatus(null);
      form.resetFields(["Action"]);
    }

    if (type == 'search') {
      setEditSearchVisible(false);
      setSearchDownlineUserId("");
      setSearchDownlineUserMobile("");
      setSearchDownlineUsername("");
      setSearchDownlineReferId("");
      setSearchDownlineReferMobile("");
      setSearchDownlineRefername("");
      setSearchDownlineActStatus("");
      setSearchDownlineRemark("");
      setSearchNewDownlineStatus(null);
      form.resetFields(["Action"]);
    }
  }

  const onChangeDownlineStatus = (e) => {
    setNewDownlineStatus(e.target.value);
  }

  const handleTableChange = async (pagination, filters, sorter)=> {
    await getDownlineTopup('','', pagination.current, pagination.pageSize , filters);    

    await setDownlinePageNum(pagination.current);

    await setDownlinePageSize(pagination.pageSize);

  }

  const trxDatePicker = (date, dateString) => {
    setTrxDateFrom(date);
  };

  const trxDatePicker2 = (date, dateString) => {
    setTrxDateTo(date);
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  
  let searchInput = null;
    
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });

  // Tab "Pending / KIV" Records
  const viewDownlineTopup = async () => {
    getDownlineTopup('', '', downlinePageNum, downlinePageSize, {"status":"pending"});
  }

  const getDownlineSummary = async () => {

    try {
      const responseDL = await csDownlineTopupListSummary({});
      let resultDL = await responseDL.json();
      if (resultDL.status === "ok") {

        const summaryData = Object.keys(resultDL.data).length
        ? resultDL.data.map((obj) => {
            SetStatTableLoading(false);

            let unprocessed     = parseFloat(obj.inviteTotal) - ( parseFloat(obj.inviteApproved) + parseFloat(obj.inviteRejected));
            let processStatement = `${obj.inviteProcess}/${obj.inviteTotal}`;
            let arkStatement     = `${obj.inviteApproved} / ${obj.inviteRejected} / ${obj.inviteKIV} `;
            let processed = `${obj.last_3_day_approved}`;

            setDashToday(obj.inviteToday);
            setDashYesterday(obj.inviteYesterday);
            setDashKIV(obj.inviteKIV);
            setDashProcess(processStatement);
            setDashTotal(unprocessed);
            setDashARK(arkStatement);
            setDashApproved(processed);
  
            return {
              inviteToday:  obj.inviteToday || 0,
              inviteYesterday: obj.inviteYesterday || 0,
              inviteKIV: obj.inviteKIV || 0,
              inviteProcess: obj.inviteProcess || 0,
              inviteTotal: obj.inviteTotal || 0,
              inviteApproved: obj.inviteApproved || 0,
              inviteRejected: obj.inviteRejected || 0,
            };
          })
        : [];

        SetStatTableLoading(false);

      }
      else {
        alertData.title = "Failed";
        alertData.msg = resultDL.msg;
        sharedFailAlert(alertData);
      }

      SetStatTableLoading(false);
    }
    catch(ex) {
      //message.error("Page failed to load.Please try again later");
    }
  }
  
  const getDownlineTopup = async(datefrom, dateto, pagenum, pagesize, filters) => {
    console.log(`pass by`, filters)

    let startDate = new moment(moment(),"YYYY-MM-DD HH:mm").subtract(3,"days");
    let endDate = new moment(moment(),"YYYY-MM-DD HH:mm");
    startDate = startDate.format("YYYY-MM-DD HH:mm");
    endDate = endDate.format("YYYY-MM-DD HH:mm");

    if (status.toLowerCase() == "yesterday") {
      startDate = new moment(moment(),"YYYY-MM-DD HH:mm").subtract(1,"days");
      endDate = new moment(moment(),"YYYY-MM-DD HH:mm");
      startDate = startDate.format("YYYY-MM-DD 00:00:00");
      endDate = endDate.format("YYYY-MM-DD 00:00:00");
    }

    if (status.toLowerCase() == "today") {
      startDate = new moment(moment(),"YYYY-MM-DD HH:mm")
      endDate = new moment(moment(),"YYYY-MM-DD HH:mm").add(1,"days");
      startDate = startDate.format("YYYY-MM-DD 00:00:00");
      endDate = endDate.format("YYYY-MM-DD 00:00:00");
    }

    if (status.toLowerCase() == "kiv"){
        filters = {
          status : "kiv",
          uplinename : filters.uplinename,
        }
      
        startDate = new moment("2020-01-01","YYYY-MM-DD HH:mm")
        endDate = new moment(moment(),"YYYY-MM-DD HH:mm").add(1,"days");
        startDate = startDate.format("YYYY-MM-DD 00:00:00");
        endDate = endDate.format("YYYY-MM-DD 00:00:00");
    }

    if (status.toLowerCase() == "all"){
      filters = {
        status : "pending",
        uplinename : filters.uplinename,
      }
      
      startDate = new moment("2020-01-01","YYYY-MM-DD HH:mm")
      endDate = new moment(moment(),"YYYY-MM-DD HH:mm").add(1,"days");
      startDate = startDate.format("YYYY-MM-DD 00:00:00");
      endDate = endDate.format("YYYY-MM-DD 00:00:00");
    }

    if (status.toLowerCase() == "last_3_day_approve"){
      filters = {
        status : "approved"
      }
      
      startDate = new moment("2020-01-01","YYYY-MM-DD HH:mm")
      endDate = new moment(moment(),"YYYY-MM-DD HH:mm").add(1,"days");
      startDate = startDate.format("YYYY-MM-DD 00:00:00");
      endDate = endDate.format("YYYY-MM-DD 00:00:00");
    }

    let refDetails , dlnDetails , refStats;

      let countDL = 0;

      let requestData = {
        datefrom  : startDate,
        dateto    : endDate,
        page      : pagenum,
        pagesize  : pagesize,
        filters   : filters, 
      }

    SetStatTableLoading(true);

    try {
      const responseDL = await csDownlineTopupList(requestData);
      let resultDL = await responseDL.json();
      if (resultDL.status === "ok") {
        const dlData = Object.keys(resultDL.data.detail).length
        ? resultDL.data.detail.map((obj) => {
            SetStatTableLoading(false);
            console.log(`loading each record`,obj)

            refDetails = obj.refDetails;
            dlnDetails = obj.downlineDetails;
            refStats   = obj.refStats;
            
            return {
              key            : ++countDL,
              userId         : dlnDetails?.userId || '',
              username       : dlnDetails?.username || '',
              mobile         : dlnDetails?.mobile ||'',
              devId          : dlnDetails?.devId || '',
              upline         : refDetails?.userId || '',
              uplinename     : refDetails?.name || '',
              uplinemobile   : refDetails?.mobile ||'',
              refDetails     : refDetails,
              dlnDetails     : dlnDetails,
              refStats       : refStats,
              status         : obj.status,
              sysCreateTime  : obj.sysCreateTime,
              sysUpdateTime  : obj.sysUpdateTime == "1970-01-01 08:00:00" ? "" : obj.sysUpdateTime ,
              sysUpdateby    : obj.sysUpdateBy,
              sysProcessTime : obj.sysProcessTime,
              actionStatus   : obj.status == 0 ? "Pending" : obj.status == 7 ? "KIV" : obj.status == 9 ? "Reject" : obj.status == 1 ? "Approved" : "-",
              remark         : obj.remark,                
            };
          })
        : [];

        let newDlData = dlData;
        //newDlData = newDlData.filter(item=>item.username !='');
        newDlData = dlData.filter(function (item) {
          return item.uplinename !='undefined';
          //return typeof item.uplinename !== 'undefined';
        });

        setDownlineData(newDlData);

        const dlDataSummary = Object.keys(resultDL.data.summary).length
                      ? resultDL.data.summary.map((x) => {
                        return {
                            current : x.current,
                            pagesize: x.pagesize,
                            totalTrxn : x.totalTrxn
                        };
                      })
                      : [];

          
          setDownlineTotal(dlDataSummary?.[0]?.totalTrxn ?? 10 );

          SetStatTableLoading(false);

        }
        else {
          alertData.title = "Failed";
          alertData.msg = resultDL.msg;
          sharedFailAlert(alertData);  
      }

      SetStatTableLoading(false);
    }catch(ex) {
      alertData.title = "Error";
      alertData.msg = "Page failed to load. Please try again later.";
      sharedErrorAlert(alertData);
    }

    SetStatTableLoading(false);
  }
    //
    //#endregion

    ////#region  Columns
    const downlineColumns = [
      // {
      //   align: "center",
      //   title: "#",
      //   dataIndex: "key",
      //   width: "5%",
      //   fixed:"left",
      //   render: function(text) {
      //     return (text);
      //   }
      // },
      {
        align: "center",
        title: "123",
        dataIndex: "username",
        width: "20%",
        fixed:"left",
        render: function(text, record,row) {
          const bankList  = record.refDetails?.banks.split(",");
          const bankListIdx = 1;
          return (
            <div style={style.referralDetailsStyle} className="cardDetails">
                   <div style={{marginLeft:"5px"}}><b>Referral Name: </b> { record.refDetails?.name || ''}</div> 
                   <div style={{marginLeft:"5px"}}><b>Device ID :</b>  { record.refDetails?.devId || ''}</div> 
                   <div style={{marginLeft:"5px"}}><b>Mobile Number:</b> { record.refDetails?.mobile || ''}</div> 
                   <div style={{marginLeft:"5px"}}><b>IP Address : </b> { record.refDetails?.userIP || ''}</div>  
                   <div style={{marginLeft:"5px"}}><b>Bank Number</b> :  {
                     (bankList.length >= 1 ) ?
                     <ul className="referralBanks">{bankList.map((p , index)=>(p !='' ? index > 1 ? <li style={{listStyleType:"none"}} className="extra collapse">{++index}.&nbsp;&nbsp;{p}</li>:<li style={{listStyleType:"none"}}>{++index}.&nbsp;&nbsp;{p}</li>:""))}
                     </ul>:""
                   }
                   </div> 
            </div>
          )
        }
      },
      {
        align: "center",
        title:"",
        width:"8%",
        render : function(text) {
            return (
              <div style={{marginLeft:"-20px"}}><img src={arrowRight} width="80" height="100"/></div>
            )
        }
      },
      {
        align: "center",
        title: "",
        dataIndex: "username",
        width: "20%",
        render: function(text, record,row) {
          const bankList  = record.dlnDetails?.banks.split(",");
          return (
            <div style={style.downlineDetailsStyle} className="cardDetails">
                   <div style={{marginLeft:"5px"}}><b>Downline Name: </b> { record.dlnDetails?.name || ''}</div> 
                   <div style={{marginLeft:"5px"}}><b>Device ID :</b>  { record.dlnDetails?.devId || ''}</div> 
                   <div style={{marginLeft:"5px"}}><b>Mobile Number:</b> { record.dlnDetails?.mobile || ''}</div> 
                   <div style={{marginLeft:"5px"}}><b>IP Address : </b> { record.dlnDetails?.userIP || ''}</div> 
                   <div style={{marginLeft:"5px"}}><b>Bank Number</b> :  {
                     (bankList.length >= 1 ) ?
                     <ul>{bankList.map((p , index)=>(<li style={{listStyleType:"none"}}>{++index}.&nbsp;&nbsp;{p}</li>))}
                     </ul>:""
                   }
                   </div> 
            </div>
          )
        }
      },
      {
        align: "center",
        title: "",
        dataIndex: "username",
        width: "20%",
        render: function(text, record,row) {
          return (
            <div style={style.referralStatsStyle} className="cardDetails">
              <div style={{marginLeft:"5px"}}><b>Referral Stats:</b></div> 
              <div style={{marginLeft:"5px"}}><b>Total Invite Today: </b> { record.refStats?.invToday || '0'}</div> 
              <div style={{marginLeft:"5px"}}><b>Total Invite 7 Days: </b> { record.refStats?.inv7Days || '0'}</div> 
              <div style={{marginLeft:"5px"}}><b>Total Approved 30 Days :</b>  { record.refStats?.invApp30Days || '0'}</div> 
              <div style={{marginLeft:"5px"}}><b>Total Reject 30 Days:</b> { record.refStats?.invRej30Days || '0'}</div> 
              <div style={{marginLeft:"5px"}}><b>Total KIV 30 Dsys : </b> { record.refStats?.invKIV30Days || '0'}</div> 
            </div>
          )
        }
      },
      {
        align: "left",
        title: <div style={{textAlign:"center"}}>Action</div>,
        dataIndex: "remark",
        width: "20%",
        ellipsis:true,
        render: function(text,record) {
          let actionStatus = record.actionStatus.toLowerCase()
          if (actionStatus == "kiv" || actionStatus == "pending") {
               return ( console.log(`pass me`,record),
                 <div>
                   <ul>
                      <li style={{listStyleType:"none"}}>
                          <Button
                          onClick={() => {
                            handleRecord(record,'approve', '');
                          }}
                          style={{...style.actionBtnStyle,...style.actionBtnApprStyle}}
                        >
                        Approve
                      </Button>
                    </li>
                    <li style={{listStyleType:"none"}}>
                          <Button
                          onClick={() => {
                            handleRecord(record,'reject','');
                          }}
                          style={{...style.actionBtnStyle,...style.actionBtnRejStyle}}
                        >
                        Reject
                      </Button>
                    </li>
                    <li style={{listStyleType:"none"}}>
                          <Button
                          onClick={() => {
                            handleRecord(record, 'kiv','');
                          }}
                          style={{...style.actionBtnStyle,...style.actionBtnKIVStyle}}
                        >
                        KIV
                      </Button>
                    </li>
                   </ul>                 
                 </div>
              )
          }
          else{
            // console.log(`did not pass me`,record)
            return (
              record.actionStatus === "Approved" ? <div className="ml-15pc" style={{textAlign:"center"}}>{record.actionStatus}<br/>by<br/><span className="smallFont nowrap">{record.sysUpdateby} <br/> {record.sysUpdateTime}</span></div>
              :  <span>{record.actionStatus}</span>
            )
          }
        }
      },
      {
        align: "center",
        title: "",
        dataIndex: "username",
        width: "12%",
        render: function() {
          return ""
        }
      }
    ]

  const searchDownlineColumns = [
    // {
    //   align: "center",
    //   title: "#",
    //   dataIndex: "key",
    //   width: "5%",
    //   fixed:"left",
    //   render: function(text) {
    //     return (text);
    //   }
    // },
    {
      align: "center",
      title: "Referral Details",
      dataIndex: "username",
      width: "20%",
      fixed:"left",
      render: function(text, record,row) {
        const bankList  = record.refDetails?.banks.split(",");
        const bankListIdx = 1;
        return (
          <div style={style.referralDetailsStyle} className="cardDetails">
                  <div style={{marginLeft:"5px"}}><b>Referral Name: </b> { record.refDetails?.name || ''}</div> 
                  <div style={{marginLeft:"5px"}}><b>Device ID :</b>  { record.refDetails?.devId || ''}</div> 
                  <div style={{marginLeft:"5px"}}><b>Mobile Number:</b> { record.refDetails?.mobile || ''}</div> 
                  <div style={{marginLeft:"5px"}}><b>IP Address : </b> { record.refDetails?.userIP || ''}</div>  
                  <div style={{marginLeft:"5px"}}><b>Bank Number</b> :  {
                    (bankList.length >= 1 ) ?
                    <ul className="referralBanks">{bankList.map((p , index)=>(p !='' ? index > 1 ? <li style={{listStyleType:"none"}} className="extra collapse">{++index}.&nbsp;&nbsp;{p}</li>:<li style={{listStyleType:"none"}}>{++index}.&nbsp;&nbsp;{p}</li>:""))}
                    </ul>:""
                  }
                  </div> 
          </div>
        )
      }
    },
    {
      align: "center",
      title:"",
      width:"8%",
      render : function(text) {
          return (
            <div style={{marginLeft:"5%"}}><img src={arrowRight} width="80" height="100"/></div>
          )
      }
    },
    {
      align: "center",
      title: "Downline Details",
      dataIndex: "username",
      width: "20%",
      render: function(text, record,row) {
        const bankList  = record.dlnDetails?.banks.split(",");
        return (
          <div style={style.downlineDetailsSearchStyle} className="cardDetails">
                  <div style={{marginLeft:"5px"}}><b>Downline Name: </b> { record.dlnDetails?.name || ''}</div> 
                  <div style={{marginLeft:"5px"}}><b>Device ID :</b>  { record.dlnDetails?.devId || ''}</div> 
                  <div style={{marginLeft:"5px"}}><b>Mobile Number:</b> { record.dlnDetails?.mobile || ''}</div> 
                  <div style={{marginLeft:"5px"}}><b>IP Address : </b> { record.dlnDetails?.userIP || ''}</div> 
                  <div style={{marginLeft:"5px"}}><b>Bank Number</b> :  {
                    (bankList.length >= 1 ) ?
                    <ul>{bankList.map((p , index)=>(<li style={{listStyleType:"none"}}>{++index}.&nbsp;&nbsp;{p}</li>))}
                    </ul>:""
                  }
                  </div> 
          </div>
        )
      }
    },
    {
      align: "center",
      title: "Referral Stats",
      dataIndex: "username",
      width: "20%",
      render: function(text, record,row) {
        return (
          <div style={style.referralStatsStyle} className="cardDetails">
            <div style={{marginLeft:"5px"}}><b>Referral Stats:</b></div> 
            <div style={{marginLeft:"5px"}}><b>Total Invite Today: </b> { record.refStats?.invToday || '0'}</div> 
            <div style={{marginLeft:"5px"}}><b>Total Invite 7 Days: </b> { record.refStats?.inv7Days || '0'}</div> 
            <div style={{marginLeft:"5px"}}><b>Total Approved 30 Days :</b>  { record.refStats?.invApp30Days || '0'}</div> 
            <div style={{marginLeft:"5px"}}><b>Total Reject 30 Days:</b> { record.refStats?.invRej30Days || '0'}</div> 
            <div style={{marginLeft:"5px"}}><b>Total KIV 30 Dsys : </b> { record.refStats?.invKIV30Days || '0'}</div> 
          </div>
        )
      }
    },
    {
      align: "center",
      title: <div style={{textAlign:"center"}}>Status</div>,
      dataIndex:"actionStatus",
      width:"20%",
      filters : statusList,
      filterMultiple: false,
      onFilter: (value, record) => record.actionStatus.toLowerCase().indexOf(value.toLowerCase()) === 0 ,
      render :(text, record)=> {
        console.log(`record`,record)
        return text?.toLowerCase() === "approved" ? <div className="ml-15pc">{text}<br/>by<br/><span className="smallFont nowrap">{record.sysUpdateby} <br/> {record.sysUpdateTime}</span></div>
                : text?.toLowerCase() === "reject" ?   <div className="ml-15pc">{text}<br/>by<br/><span className="smallFont nowrap">{record.sysUpdateby} <br/> {record.sysUpdateTime}</span></div>
                : <span>{text}</span>;
      }
    },
    {
      align: "left",
      title: <div style={{textAlign:"center"}}>Action</div>,
      dataIndex: "remark",
      width: "20%",
      ellipsis:true,
      render: function(text,record) {
        let actionStatus = record.actionStatus.toLowerCase();
          return !refCommSearchApprRej(role, actionStatus, record.uplinename, compCode)?
          <></>:
          (
            <div>
              <ul>
                  <li style={{listStyleType:"none"}}>
                        <Button
                            onClick={() => {
                              handleRecord(record,'approve','search');
                            }}
                            style={{...style.actionBtnStyle,...style.actionBtnApprStyle}}
                          >
                          {refCommSearchSuperCS(role, actionStatus) ? "Re-Approve" :"Approve"}
                        </Button>
                </li>
                <li style={{listStyleType:"none"}}>
                      <Button
                      onClick={() => {
                        handleRecord(record,'reject','search');
                      }}
                      style={{...style.actionBtnStyle,...style.actionBtnRejStyle}}
                    >
                    Reject
                  </Button>
                </li>
                <li style={{listStyleType:"none"}}>
                      <Button
                      onClick={() => {
                        handleRecord(record, 'kiv','search');
                      }}
                      style={{...style.actionBtnStyle,...style.actionBtnKIVStyle}}
                    >
                    KIV
                  </Button>
                </li>
              </ul>                 
            </div>
          )
      }
    },
    {
      align: "center",
      title: "",
      dataIndex: "username",
      width: "12%",
      render: function() {
        return ""
      }
    }
  ]
    ////#endregion
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 15,
    },
  };

  const onEditDownlineTopup = (type) => {
      return (
      <Form 
      form={form}
      ref={modalForm}
      {...layout}
      name="editDownlineInvite"
      initialValues={{remember: false}}
    >
      <Form.Item label="Downline Name">
            {type!="search"? downlineUsername: searchDownlineUsername}
      </Form.Item>
      <Form.Item label="Downline Mobile">
            {type!="search"? downlineUsername: searchDownlineUserMobile}
      </Form.Item>

      <Form.Item label="Referral Name">
            {type!="search"? downlineRefername: searchDownlineRefername}
      </Form.Item>
      <Form.Item label="Referral Mobile">
            {type!="search"? downlineReferMobile: serachDownlineReferMobile}
      </Form.Item>
      <Form.Item label="Status">
            {type!="search"? downlineActStatus: searchDownlineActStatus}
      </Form.Item>
      <Form.Item label="Action" name="Action">
          {
            type !="search"?
            <Radio.Group onChange={onChangeDownlineStatus}>
            {
              editActionTitle.toLowerCase().indexOf("approve") > -1 ?
              <Radio value={1} id={"actionApprove"} ref={refActApprove}>Approve</Radio>:""
            }
            { editActionTitle.toLowerCase().indexOf("reject") > -1 ?
              <Radio value={9} id={"actionReject"} ref={refActApprove}>Reject</Radio>: ""
            }
            { editActionTitle.toLowerCase().indexOf("kiv") > -1 ?
              <Radio value={7} id={"actionKIV"} ref={refActApprove}>KIV</Radio>:""
            }
            </Radio.Group>:
            <Radio.Group onChange={onChangeSearchDownlineStatus}>
            {
              editSearchActionTitle.toLowerCase().indexOf("approve") > -1 ?
              <Radio value={1} id={"actionApprove"} ref={refSearchActApprove}>Approve</Radio>:""
            }
            { editSearchActionTitle.toLowerCase().indexOf("reject") > -1 ?
              <Radio value={9} id={"actionReject"} ref={refSearchActApprove}>Reject</Radio>: ""
            }
            { editSearchActionTitle.toLowerCase().indexOf("kiv") > -1 ?
              <Radio value={7} id={"actionKIV"} ref={refSearchActApprove}>KIV</Radio>:""
            }
            </Radio.Group>

          }

      </Form.Item>
      <Form.Item label="Remark">
        <div>
            <TextArea
              rows={4}
              onChange={(e) => type !="search" ? setDownlineRemark(e.target.value): setSearchDownlineRemark(e.target.value)}
              value={type !="search" ? downlineRemark:searchDownlineRemark}
              ref={type !="search"? refDownlineRemark: refSearchDownlineRemark}
            />
        </div>
      </Form.Item>
    </Form>

      )
  }

  return (
    <div className="downlineInviteAppr">
      {
        refCommApprRej(role)?
      <>
        <Tabs defaultActiveKey="1" style={{marginLeft:"0px"}} id="ref-comm">
            <TabPane tab={"Pending / KIV Records"} key="1">
              <PageHeader ghost={false}>
                <div style={style.pageHeaderContainer}>
                  <div style={{float:"left"}}>
                    <div>
                    <DatePicker
                      key="1"
                      style={{...style.datePickerStyle, ...style.refreshBtn,...style.hide}}
                      format="YYYY-MM-DD HH:mm"
                      showTime={{
                        defaultValue: moment("00:00", "HH:mm"),
                        format: "HH:mm"
                      }}
                      onChange={trxDatePicker}
                      value={trxDateFrom}
                      ref={rptDateFrom}
                      allowClear={false}
                      showToday={false}
                      placeholder={"Select Date From"}
                      disabledDate={(current)=> {
                        return current > moment().endOf('day');
                      }}
                    />
                    <DatePicker
                      key="2"
                      style={{...style.datePickerStyle, ...style.refreshBtn,...style.hide}}
                      format="YYYY-MM-DD HH:mm"
                      showTime={{
                        defaultValue: moment("00:00", "HH:mm"),
                        format: "HH:mm"
                      }}
                      onChange={trxDatePicker2}
                      value={trxDateTo}
                      ref={rptDateTo}
                      allowClear={false}
                      showToday={false}
                      placeholder={"Select Date From"}
                      disabledDate={(current)=> {
                        let dateFr = trxDateFrom;
                        if (dateFr) {
                          return current < dateFr || current > moment().add(1, 'day').add(-1, 'minute');
                        }else {
                          return  current > moment().subtract(1, 'day');
                        }
                      }}
                      defaultPickerValue={()=>{
                          let dateFr = trxDateFrom;
                          if (dateFr) {
                              return dateFr;
                          }
                      }}
                    />
                    <Button 
                    style={{...style.buttonStyle, ...style.hide}}
                    type="primary"
                    onClick={()=>{
                      viewDownlineTopup();
                      getDownlineSummary();
                    }}
                    >
                        Refresh
                    </Button>
                    &nbsp;
                    <Button
                        style={{...style.refreshBtn,...style.buttonStyle,...style.hide}}
                      type="primary"
                      onClick={() => {
                        setSearchMobile(searchMobile);

                      }}
                    >
                      Reset
                    </Button>
                    <Radio.Group
                      buttonStyle={"solid"}
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                        console.log(`click me`,e.target.value)
                      }}
                    >
                      <Radio.Button 
                        value="All" 
                        style={{width:"210px", textAlign:"center"}}
                        >
                          All Unprocess ({dashTotal})
                      </Radio.Button>
                      <span style={{marginLeft:"20px"}}/>
                      <Radio.Button 
                        value="Today" 
                        style={{width:"200px", textAlign:"center"}}
                      >
                        Today Unprocess ({dashToday})
                      </Radio.Button>
                      <span style={{marginLeft:"20px"}}/>
                      <Radio.Button 
                        value="Yesterday" 
                        style={{width:"230px", textAlign:"center"}}
                      >
                        Yesterday Unprocess ({dashYesterday})
                      </Radio.Button>
                      <span style={{marginLeft:"20px"}}/> 
                      <Radio.Button 
                        value="KIV" 
                        style={{width:"180px", textAlign:"center"}}
                      >
                        KIV ({dashKIV})
                      </Radio.Button>
                      <span style={{marginLeft:"20px"}}/>
                      <Radio.Button 
                        value="last_3_day_approve"
                        style={{width:"230px", textAlign:"center"}}
                      >
                        Last 3 days Approved ({dashApproved})
                      </Radio.Button>  
                    </Radio.Group>
                    </div>
                    <div style={{float:"left"}, {marginTop:"25px"}}>
                      <Button style={{boxShadow:"2px 2px 2px 1px rgba(0, 0, 0, 0.2)", width:"210px"}}><b>Process Status</b>:{dashProcess}</Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button style={{boxShadow:"2px 2px 2px 1px rgba(0, 0, 0, 0.2)", width:"auto"}}><b>Approval / Rejected / KIV:</b>&nbsp;{dashARK}</Button>
                    </div>
                  </div>
                </div>
              </PageHeader>
              <div style={{...style.tabelContainer}} className="body">
                  <Table
                    columns={downlineColumns}
                    dataSource={downlineData}
                    id={"downlineInviteList"}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: settings.pageSizeOptions,
                      style: style.paginationStyle,
                      alignmentBottom: 'right',
                      total: downlineTotal,
                      showTotal: total => `Total ${downlineTotal}  Record(s)`,
                    }}
                    loading={statTableLoading}
                    onChange={handleTableChange}
                  />
                  {/* <CLegendRefComm/> */}
                  <Modal
                    title={editActionTitle}
                    visible={editVisible}
                    footer={[
                        <Button
                          type={"primary"}
                          onClick={()=> {
                            handleReasonCancel();
                          }}
                        >
                          Cancel
                        </Button>
                        ,
                        downlineTopupSubmit?
                          <Button 
                              type={"danger"} 
                              onClick={() => {
                                  handleDownlineTopup();
                              }}
                          >
                          OK
                        </Button>: <Spin tip="Processing..."></Spin>
                      
                    ]}
                    maskClosable={false}
                  >
                    {onEditDownlineTopup()}
                    </Modal>
              </div>
            </TabPane>
            <TabPane tab="Search Records" key="2">
              <PageHeader ghost={false}>
                <div style={style.pageHeaderContainer}>
                  <div style={{float:"right"}}>
                  <Input.Group compact>
                    <Input
                        style={{ width: '200px' }}
                        placeholder="Enter Mobile Number"
                        prefix={<PhoneOutlined className="site-form-item-icon" />}
                        text="number"
                        suffix={
                          <Tooltip title="Enter Upline or Downline Mobile Number">
                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                          </Tooltip>
                        }
                        onChange={(e) => {
                          setRecMobile(e.target.value.replace(/\D/g, ""));      
                        }}
                        ref={refRecMobile}
                    />
                    <Button 
                      type="primary"
                      onClick={()=>{
                        vwSearchDownlineTopup();
                      }}
                    >
                        Search 
                    </Button> 
                  </Input.Group>


            
                  </div>
                  
                </div>
              </PageHeader>
            <PageHeader ghost={false}>
                <div style={style.pageHeaderContainer}>
                  <div style={{float:"left"}}>
                    <div>
                    <Radio.Group
                      buttonStyle={"solid"}
                      value={searchStatus}
                      onChange={(e) => {
                        setSearchStatus(e.target.value);
                        // vwSearchDownlineTopupStatus();
                      
                      }}
                    >
                      <Radio.Button value="All" style={{width:"210px", textAlign:"center"}}>All Unprocess ({searchDashTotal})</Radio.Button>
                      <span style={{marginLeft:"20px"}}/>
                      <Radio.Button value="Today" style={{width:"200px", textAlign:"center"}}>Today Unprocess ({searchDashToday})</Radio.Button>
                      <span style={{marginLeft:"20px"}}/>
                      <Radio.Button value="Yesterday" style={{width:"230px", textAlign:"center"}}>Yesterday Unprocess ({searchDashYesterday})</Radio.Button>
                      <span style={{marginLeft:"20px"}}/> 
                      <Radio.Button value="KIV" style={{width:"180px", textAlign:"center"}}>KIV ({searchDashKIV})</Radio.Button>
                      {/* <Radio.Button value="Approved" style={{width:"230px", textAlign:"center"}}>(Approved))</Radio.Button> */}
                    </Radio.Group>

                    </div>
                    
                    <div style={{...style.hide},{float:"left"}, {marginTop:"25px"}}>
                      <Button style={{boxShadow:"2px 2px 2px 1px rgba(0, 0, 0, 0.2)", width:"210px"}}><b>Process Status</b>:{searchDashProcess}</Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button style={{boxShadow:"2px 2px 2px 1px rgba(0, 0, 0, 0.2)", width:"auto"}}><b>Approval / Rejected / KIV:</b>&nbsp;{searchDashARK}</Button>
                    </div>
                  </div>
                </div>

            </PageHeader>
            <div style={{...style.tabelContainer}} className="body">
                  <Table
                    columns={searchDownlineColumns}
                    dataSource={searchDownlineData}
                    id={"downlineSearchList"}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: settings.pageSizeOptions,
                      style: style.paginationStyle,
                      alignmentBottom: 'right',
                      total: searchdownlineTotal,
                      showTotal: total => `Total ${searchdownlineTotal}  Record(s)`,
                    }}
                    loading={searchStatTableLoading}
                    onChange={handleSearchTableChange}
                  />
                  <CLegendRefComm/>
                  <Modal
                    title={editSearchActionTitle}
                    visible={editSearchVisible}
                    footer={[
                        <Button
                          type={"primary"}
                          onClick={()=> {
                            handleReasonCancel("search");
                          }}
                        >
                          Cancel
                        </Button>
                        ,
                        searchDownlineTopupSubmit?
                          <Button 
                              type={"danger"} 
                              onClick={() => {
                                  handleDownlineTopup("search");
                              }}
                          >
                          OK
                        </Button>: <Spin tip="Processing..."></Spin>
                      
                    ]}
                    maskClosable={false}
                  >
                    {onEditDownlineTopup("search")}
                    </Modal>
              </div>
              
            </TabPane>
        </Tabs>

      </>
      : <div style={style.notAuthorizedContainer}><h1 style={{color:"red"}}>Not Allowed to Access This Page</h1></div>
      }
    </div>

      
  );
}



