import React, { Component } from "react";
import "../../App.less";
import { Layout, Menu, Dropdown, Button } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
  LockOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { NavLink, Switch, Route, Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { route, shareholder, mainMenu, logout, changePassword } from "../../config/route";
import Login from "../login/login";
import Logout from "../logout/logout";
import { externalFileNotFile } from "../error/error";
import { copyright } from "../../library/texts";
import { Logo } from "../../common/components/Logo";
import { settings } from "../../config/appConfig";

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

const styles = {
  menuFontSize: {
    fontSize:
      window.innerWidth === 280 || window.innerWidth === 320 ? null : 20,
  },
};

class App extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  state = {
    collapsed: isMobile ? true : false,
    openKeys: [""],
    current: "/",
  };

  rootSubmenuKeys = ["sub1", "sub2", "sub3"];

  componentDidMount() {
    var that = this;
    shareholder.forEach(function (share) {
      if (share.path === window.location.pathname) {
        that.setState({ title: share.title });
      }
    });

    if (
      shareholder[10].path === window.location.pathname ||
      shareholder[3].path === window.location.pathname
    ) {
      that.setState({ openKeys: ["userManagementParent"] });
    }
    else if (
      shareholder[0].path === window.location.pathname ||
      shareholder[11].path === window.location.pathname ||
      shareholder[12].path === window.location.pathname ||
      shareholder[13].path === window.location.pathname ||
      shareholder[4].path === window.location.pathname ||
      shareholder[5].path === window.location.pathname ||
      shareholder[6].path === window.location.pathname ||
      shareholder[7].path === window.location.pathname ||
      shareholder[14].path === window.location.pathname
    ) {
      that.setState({ openKeys: ["ReportingParent"] });
    }
    else if (
      shareholder[9].path === window.location.pathname ||
      shareholder[8].path === window.location.pathname
    ) {
      that.setState({ openKeys: ["creditManagementParent"] });
    }
    // else if (
    //   shareholder[12].path === window.location.pathname
    // ) {
    //   that.setState({ openKeys: ["settingsParent"] });
    // }
    
    document.addEventListener("gesturestart", function (e) {
      e.preventDefault(); //disable iphone zooming
    });
  }

  toggle = () => {
    if (isMobile) {
      document.getElementById("menuBackground").style.display = "flex";
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  menuBgOnclick = () => {
    if (isMobile) {
      this.setState({
        collapsed: !this.state.collapsed,
      });
      document.getElementById("menuBackground").style.display = "none";
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  };

  subMenuClick = () => {
    var that = this;
    shareholder.forEach(function (share) {
      if (share.path === window.location.pathname) {
        that.setState({ title: share.title });
      }
    });
  };

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  menuBgOnclick = () => {
    if (isMobile) {
      this.setState({
        collapsed: !this.state.collapsed,
      });
      document.getElementById("menuBackground").style.display = "none";
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  };

  handleClick = (e) => {
    console.log("click ", e);
    this.setState({
      current: e.key,
    });
  };

  menu = () => {
    return (
      <Menu>
        {(
          <Menu
            style={{
              paddingTop: 4,
              marginTop: -4,
              marginBottom: -4,
              paddingBottom: 2,
            }}
          >
            <Menu.Item 
              onClick={() => {
                window.location.reload();
              }}
              key={changePassword[0].path}
              style={{
                paddingTop: -2,
                marginTop: -5,
                marginBottom: -4,
              }}
              
            >
              <NavLink to={changePassword[0].path}>
                <div 
                  className="menu-item"
                  style={{
                    justifyContent: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <LockOutlined className="menu-icon" />
                  <span className="menu-text">Change Password</span>
                </div>
              </NavLink>
            </Menu.Item>

            <Menu.Item key={logout[0].path} className="logoutBorder"
              style={{
                marginBottom: -2,
              }}
            >
              <NavLink to={logout[0].path}>
                <div 
                  className="menu-item"
                  style={{
                    justifyContent: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <LogoutOutlined className="menu-icon" />
                  <span className="menu-text">Logout</span>
                </div>
              </NavLink>
            </Menu.Item>
          </Menu>
        )}
      </Menu>
    );
  };

  render() {
    return this.props.signIn === false ? (
      <Switch>
        <Route path={[route.Default, route.Login]} exact component={Login} />
        <Route path={route.All} component={externalFileNotFile} />
      </Switch>
    ) : (
      <Layout>
        <div
          id="menuBackground"
          className="menuBackground"
          onClick={() => this.menuBgOnclick()}
        />
        <Sider
          className="ant-layout-sider-dark"
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}
          collapsedWidth={isMobile ? 0 : 80}
          width={
            window.innerWidth === 280 || window.innerWidth === 320 ? 220 : 280
          }
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            width: "280px",
          }}
        >
          <div
            style={{
              height: 64,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div>
              <Logo logoType="shareholder" />
              {/* <img src={logo.icon} alt="Logo" style={{ maxWidth: "80%" }} />
              {!this.state.collapsed ? (
                <img src={logo.logo} alt="Logo" style={{ maxWidth: "40%" }} />
              ) : null} */}
            </div>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[window.location.pathname]}
            onClick={this.handleClick}
            openKeys={this.state.openKeys}
            onOpenChange={this.onOpenChange}
          >
            <SubMenu
                key="userManagementParent"
                style={{ fontSize: 18 }}
                title="User Management"
                collapsed={false}
                icon={mainMenu[5].icon}
            >
              
              {/* Downline Management V2 - TKB, 555, MKB, 777 */}
              <Menu.Item
                onClick={() => window.location.reload()}
                key={shareholder[10].path}
                style={styles.menuFontSize}
              >
                <NavLink to={shareholder[10].path}>{shareholder[10].title}</NavLink>
              </Menu.Item>

              <Menu.Item
                onClick={() => window.location.reload()}
                key={shareholder[3].path}
                style={styles.menuFontSize}
              >
                <NavLink to={shareholder[3].path}>{shareholder[3].title}</NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu
                key="ReportingParent"
                style={{ fontSize: 18 }}
                title="Reporting"
                collapsed={false}
                icon={mainMenu[3].icon}
            >
              {/* <Menu.Item
                onClick={() => window.location.reload()}
                key={shareholder[0].path}
                // icon={shareholder[0].icon}
                style={styles.menuFontSize}
              >
                <Link to={shareholder[0].path}>{shareholder[0].title}</Link>
              </Menu.Item> */}
                <Menu.Item
                  onClick={() => window.location.reload()}
                  key={shareholder[11].path}
                  // icon={shareholder[11].icon}
                  style={styles.menuFontSize}
                >
                  <NavLink to={shareholder[11].path}>{shareholder[11].title}</NavLink>
                </Menu.Item>
            
              {/* <Menu.Item
                onClick={() => window.location.reload()}
                key={shareholder[1].path}
                icon={shareholder[1].icon}
                style={styles.menuFontSize}
              >
                <Link to={shareholder[1].path}>{shareholder[1].title}</Link>
              </Menu.Item> */}
              <Menu.Item
                onClick={() => window.location.reload()}
                key={shareholder[4].path}
                // icon={shareholder[4].icon}
                style={styles.menuFontSize}
              >
                <NavLink to={shareholder[4].path}>{shareholder[4].title}</NavLink>
              </Menu.Item>
                <Menu.Item
                  onClick={() => window.location.reload()}
                  key={shareholder[12].path}
                  // icon={shareholder[12].icon}
                  style={styles.menuFontSize}
                >
                  <NavLink to={shareholder[12].path}>{shareholder[12].title}</NavLink>
                </Menu.Item>
                <Menu.Item
                  onClick={() => window.location.reload()}
                  key={shareholder[13].path}
                  // icon={shareholder[13].icon}
                  style={styles.menuFontSize}
                >
                  <NavLink to={shareholder[13].path}>{shareholder[13].title}</NavLink>
                </Menu.Item>
              {shareholder[5].code.includes(this.props.compCode) == true ? (
                <Menu.Item
                  onClick={() => window.location.reload()}
                  key={shareholder[5].path}
                  // icon={shareholder[5].icon}
                  style={styles.menuFontSize}
                >
                  <NavLink to={shareholder[5].path}>{shareholder[5].title}</NavLink>
                </Menu.Item>
              ) : ''}
              {shareholder[6].code.includes(this.props.compCode) == true ? (
                <Menu.Item
                  onClick={() => window.location.reload()}
                  key={shareholder[6].path}
                  // icon={shareholder[6].icon}
                  style={styles.menuFontSize}
                >
                  <NavLink to={shareholder[6].path}>{shareholder[6].title}</NavLink>
                </Menu.Item>
              ) : ''}
              <Menu.Item
                  onClick={() => window.location.reload()}
                  key={shareholder[14].path}
                  // icon={shareholder[12].icon}
                  style={styles.menuFontSize}
                >
                  <NavLink to={shareholder[14].path}>{shareholder[14].title}</NavLink>
                </Menu.Item>
            </SubMenu>
            {/* <SubMenu
                key="creditManagementParent"
                style={{ fontSize: 18 }}
                title="Credit Management"
                collapsed={false}
                icon={mainMenu[3].icon}
            > */}
              {/* Upload Receipt - MKB */}
              {/* <Menu.Item
                onClick={() => window.location.reload()}
                key={shareholder[9].path}
                style={styles.menuFontSize}
              >
                <NavLink to={shareholder[9].path}>{shareholder[9].title}</NavLink>
              </Menu.Item> */}
              {/* Transfer Credit - MKB */}
              {/* <Menu.Item
                onClick={() => window.location.reload()}
                key={shareholder[8].path}
                style={styles.menuFontSize}
              >
                <NavLink to={shareholder[8].path}>{shareholder[8].title}</NavLink>
              </Menu.Item> */}
            {/* </SubMenu> */}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          {isMobile ? (
            <Header
              className="site-layout-background"
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                height: 68,
              }}
            >
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: this.toggle,
                }
              )}

              <div
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "black",
                }}
              ></div>

              <div>
                <div
                  style={{
                    fontSize: 18,
                  }}
                >
                  <Dropdown overlay={this.menu} placement="bottomLeft">
                    <Button
                      style={{
                        border: "none",
                        width: 80,
                        height: 65,
                      }}
                      icon={<UserOutlined />}
                      size="large"
                    ></Button>
                  </Dropdown>
                </div>
              </div>
            </Header>
          ) : (
            <Header
              className="site-layout-background"
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                marginLeft: this.state.collapsed ? 64 : 280,
                height: 68,
                alignItems: "center",
              }}
            >
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: this.toggle,
                }
              )}

              <div
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "black",
                }}
              ></div>

              <div>
                <div
                  style={{
                    fontSize: 18,
                  }}
                >
                  <Dropdown overlay={this.menu} placement="bottomLeft">
                    <Button
                      style={{
                        border: "none",
                        width: 250,
                        height: 65,
                      }}
                      icon={<UserOutlined />}
                      size="large"
                    >
                      {this.props.role}_{this.props.username}
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </Header>
          )}
          <Content
            className="site-layout-background"
            style={{
              marginTop: "5px",
              marginLeft: isMobile ? "" : this.state.collapsed ? 64 : 280,
              backgroundColor: "#f0f2f5",
            }}
          >
            <div
              className="site-layout-background"
              style={{
                marginRight: isMobile ? "" : this.state.collapsed ? 10 : "",
                marginLeft: isMobile ? "" : this.state.collapsed ? 24 : "",
                padding: isMobile ? "" : 24,
                minHeight: isMobile ? 550 : 360,
              }}
            >
              <Switch>
                <Route exact path={route.Logout} component={Logout} />
                {shareholder.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                ))}
              </Switch>
            </div>
          </Content>
          {isMobile ? (
            <Footer style={{ textAlign: "center" }}>
              <div>{copyright.fullText()}</div>
              <div>V: {settings.version}</div>
              {/* <div>V: {version}</div> */}
            </Footer>
          ) : (
            <Footer
              style={{
                textAlign: "center",
                marginLeft: 280,
              }}
            >
              <div>{copyright.fullText()}</div>
              <div>V: {settings.version}</div>
              {/* <div>V: {version}</div> */}
            </Footer>
          )}
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = ({ login }) => ({
  signIn: login.signIn,
  username: login.username,
  role: login.role,
  compCode: login.compCode,
});
const mapDispatchToProps = (dispatch) => {
  return { signOut: () => dispatch({ type: "sign_out" }) };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
