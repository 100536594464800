import React, { Component } from "react";
import "../../App.less";
import { Layout, Menu, Button, Dropdown } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
  LockOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { NavLink, Switch, Route } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { route, company, mainMenu, logout, changePassword } from "../../config/route";

//Navigate
import Login from "../login/login";
import Logout from "../logout/logout";
import { externalFileNotFile } from "../error/error";
import { copyright } from "../../library/texts";
import { Logo } from "../../common/components/Logo";
import { settings } from "../../config/appConfig";

const styles = {
  menuFontSize: {
    fontSize:
      window.innerWidth === 280 || window.innerWidth === 320 ? null : 20,
  },
};

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

class App extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  state = {
    collapsed: isMobile ? true : false,
    openKeys: [""],
    title: "",
  };

  rootSubmenuKeys = ["sub1", "sub2", "sub3"];

  componentDidMount() {
    var that = this;
    company.forEach(function (comp) {
      if (comp.path === window.location.pathname) {
        that.setState({ title: comp.title });
      }
    });

    if (
      company[1].path === window.location.pathname ||
      company[8].path === window.location.pathname ||
      company[2].path === window.location.pathname
    ) {
      that.setState({ openKeys: ["userManagementParent"] });
    } else if (
      // company[0].path === window.location.pathname ||
      company[6].path === window.location.pathname ||
      company[9].path === window.location.pathname
    ) {
      that.setState({ openKeys: ["ReportingParent"] });
    } else if (
      company[3].path === window.location.pathname ||
      company[15].path === window.location.pathname ||
      company[4].path === window.location.pathname ||
      company[12].path === window.location.pathname ||
      company[5].path === window.location.pathname ||
      company[7].path === window.location.pathname ||
      company[10].path === window.location.pathname ||
      // company[11].path === window.location.pathname ||
      company[14].path === window.location.pathname
    ) {
      that.setState({ openKeys: ["settingsParent"] });
    }

    document.addEventListener("gesturestart", function (e) {
      e.preventDefault(); //disable iphone zooming
    });
  }

  toggle = () => {
    if (isMobile) {
      document.getElementById("menuBackground").style.display = "flex";
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  menuBgOnclick = () => {
    if (isMobile) {
      this.setState({
        collapsed: !this.state.collapsed,
      });
      document.getElementById("menuBackground").style.display = "none";
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  };

  subMenuClick = () => {
    var that = this;
    company.forEach(function (comp) {
      if (comp.path === window.location.pathname) {
        that.setState({ title: comp.title });
      }
    });
  };

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  menu = () => {
    return (
      <Menu>
        {(
          <Menu
            style={{
              paddingTop: 4,
              marginTop: -4,
              marginBottom: -4,
              paddingBottom: 2,
            }}
          >
            <Menu.Item 
              onClick={() => {
                window.location.reload();
              }}
              key={changePassword[0].path}
              style={{
                paddingTop: -2,
                marginTop: -5,
                marginBottom: -4,
              }}
              
            >
              <NavLink to={changePassword[0].path}>
                <div 
                  className="menu-item"
                  style={{
                    justifyContent: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <LockOutlined className="menu-icon" />
                  <span className="menu-text">Change Password</span>
                </div>
              </NavLink>
            </Menu.Item>

            <Menu.Item key={logout[0].path} className="logoutBorder"
              style={{
                marginBottom: -2,
              }}
            >
              <NavLink to={logout[0].path}>
                <div 
                  className="menu-item"
                  style={{
                    justifyContent: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <LogoutOutlined className="menu-icon" />
                  <span className="menu-text">Logout</span>
                </div>
              </NavLink>
            </Menu.Item>
          </Menu>
        )}
      </Menu>
    );
  };

  render() {
    return this.props.signIn === false ? (
      <Switch>
        <Route path={[route.Default, route.Login]} exact component={Login} />
        <Route path={route.All} component={externalFileNotFile} />
      </Switch>
    ) : (
      <Layout>
        <div
          id="menuBackground"
          className="menuBackground"
          onClick={() => this.menuBgOnclick()}
        />
        <Sider
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}
          collapsedWidth={isMobile ? 0 : 80}
          width={250}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
        >
          <div
            style={{
              height: 64,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div>
              {/* <img src={logo.icon} alt="Logo" style={{ maxWidth: "80%" }} /> */}

              <Logo logoType="company" />

              {/* {!this.state.collapsed ? (
                <img src={logo.logo} alt="Logo" style={{ maxWidth: "40%" }} />
              ) : (
                <img src={logo.icon} alt="Logo" style={{ maxWidth: "80%" }} />
              )} */}
            </div>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[window.location.pathname]}
            openKeys={this.state.openKeys}
            onOpenChange={this.onOpenChange}
          >
            <SubMenu
              key="userManagementParent"
              style={{ fontSize: 18 }}
              title="User Management"
              collapsed={false}
              icon={mainMenu[5].icon}
            >
              {/* Shareholder V2 - TKB, 555, 777 */}
              <Menu.Item
                onClick={() => {
                  this.setState({ title: company[8].title });
                  window.location.reload();
                }}
                key={company[8].path}
                // icon={company[8].icon}
                style={styles.menuFontSize}
              >
                <NavLink to={company[8].path}>{company[8].title}</NavLink>
              </Menu.Item>

              <Menu.Item
                onClick={() => {
                  this.setState({ title: company[2].title });
                  window.location.reload();
                }}
                key={company[2].path}
                // icon={company[2].icon}
                style={styles.menuFontSize}
              >
                <NavLink to={company[2].path}>{company[2].title}</NavLink>
              </Menu.Item>

              {this.props.demoUser?<Menu.Item
                onClick={() => {
                  this.setState({ title: company[18].title });
                  window.location.reload();
                }}
                key={company[18].path}
                // icon={company[2].icon}
                style={styles.menuFontSize}
              >
                <NavLink to={company[18].path}>{company[18].title}</NavLink>
              </Menu.Item>:null}
            </SubMenu>
            {/* <SubMenu
                key="ReportingParent"
                style={{ fontSize: 18 }}
                title="Reporting"
                collapsed={false}
                icon={mainMenu[3].icon}
            >
              <Menu.Item
                onClick={() => {
                  window.location.reload();
                }}
                key={company[0].path}
                // icon={company[0].icon}
                style={styles.menuFontSize}
              >
                <NavLink to={company[0].path} style={{ fontSize: 18 }}>
                  {company[0].title}
                </NavLink>
              </Menu.Item>
            

            <Menu.Item
              key={company[6].path}
              // icon={company[6].icon}
              style={styles.menuFontSize}
            >
              <NavLink to={company[6].path}>{company[6].title}</NavLink>
            </Menu.Item>
            {company[9].code.includes(this.props.compCode) == false ? (              
              <Menu.Item
                onClick={() => {
                  // this.setState({ title: company[1].title });
                  window.location.reload();
                }}
                key={company[9].path}
                // icon={company[9].icon}
                style={styles.menuFontSize}
              >
                <NavLink to={company[9].path}>{company[9].title}</NavLink>
              </Menu.Item>
            ) : ''}
            </SubMenu> */}
            <SubMenu
              key="settingsParent"
              style={{ fontSize: 18 }}
              title="Settings"
              collapsed={false}
              icon={mainMenu[4].icon}
            >
              <Menu.Item
                onClick={() => {
                  this.setState({ title: company[3].title });
                  window.location.reload();
                }}
                key={company[3].path}
                // icon={company[3].icon}
                style={styles.menuFontSize}
              >
                <NavLink to={company[3].path}>{company[3].title}</NavLink>
              </Menu.Item>

              <Menu.Item
                onClick={() => {
                  this.setState({ title: company[15].title });
                  window.location.reload();
                }}
                key={company[15].path}
                // icon={company[3].icon}
                style={styles.menuFontSize}
              >
                <NavLink to={company[15].path}>{company[15].title}</NavLink>
              </Menu.Item>

              <Menu.Item
                onClick={() => {
                  this.setState({ title: company[16].title });
                  window.location.reload();
                }}
                key={company[16].path}
                // icon={company[16].icon}
                style={styles.menuFontSize}
              >
                <NavLink to={company[16].path}>{company[16].title}</NavLink>
              </Menu.Item>
              {/* <Menu.Item
              onClick={() => {
                this.setState({ title: company[4].title });
                window.location.reload();
              }}
              key={company[4].path}
              // icon={company[4].icon}
              style={styles.menuFontSize}
            >
              <NavLink to={company[4].path}>{company[4].title}</NavLink>
            </Menu.Item> */}

              <Menu.Item
                onClick={() => {
                  this.setState({ title: company[13].title });
                  window.location.reload();
                }}
                key={company[13].path}
                // icon={company[4].icon}
                style={styles.menuFontSize}
              >
                <NavLink to={company[13].path}>{company[13].title}</NavLink>
              </Menu.Item>

              <Menu.Item
                onClick={() => {
                  this.setState({ title: company[5].title });
                  window.location.reload();
                }}
                key={company[5].path}
                // icon={company[5].icon}
                style={styles.menuFontSize}
              >
                <NavLink to={company[5].path}>{company[5].title}</NavLink>
              </Menu.Item>

              <Menu.Item
                onClick={() => {
                  this.setState({ title: company[12].title });
                  window.location.reload();
                }}
                key={company[12].path}
                // icon={company[4].icon}
                style={styles.menuFontSize}
              >
                <NavLink to={company[12].path}>{company[12].title}</NavLink>
              </Menu.Item>

              {/* {company[7].code.includes(this.props.compCode) == false ? (
              <Menu.Item
                onClick={() => {
                  this.setState({ title: company[7].title });
                  window.location.reload();
                }}
                key={company[7].path}
                // icon={company[7].icon}
                style={styles.menuFontSize}
              >
                <NavLink to={company[7].path}>{company[7].title}</NavLink>
              </Menu.Item>
            ) : ''} */}

              {/* {company[10].code.includes(this.props.compCode) == false ? (
              <Menu.Item
                onClick={() => {
                  this.setState({ title: company[10].title });
                  window.location.reload();
                }}
                key={company[10].path}
                style={styles.menuFontSize}
              >
                <NavLink to={company[10].path}>{company[10].title}</NavLink>
              </Menu.Item>
            ) : ''} */}

              {/* {company[11].code.includes(this.props.compCode) == false ? (
              <Menu.Item
                onClick={() => {
                  this.setState({ title: company[11].title });
                  window.location.reload();
                }}
                key={company[11].path}
                style={styles.menuFontSize}
              >
                <NavLink to={company[11].path}>{company[11].title}</NavLink>
              </Menu.Item>
            ) : ''} */}

              <Menu.Item
                onClick={() => {
                  this.setState({ title: company[14].title });
                  window.location.reload();
                }}
                key={company[14].path}
                style={styles.menuFontSize}
              >
                <NavLink to={company[14].path}>{company[14].title}</NavLink>
              </Menu.Item>

            </SubMenu>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          {isMobile ? (
            <Header
              className="site-layout-background"
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                height: 68,
              }}
            >
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: this.toggle,
                }
              )}

              <div
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {this.state.title}
              </div>

              <div>
                <div
                  style={{
                    fontSize: 18,
                  }}
                >
                  <Dropdown overlay={this.menu} placement="bottomLeft">
                    <Button
                      style={{
                        border: "none",
                        width: 80,
                        height: 65,
                      }}
                      icon={<UserOutlined />}
                      size="large"
                    />
                  </Dropdown>
                </div>
              </div>
            </Header>
          ) : (
            <Header
              className="site-layout-background"
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                marginLeft: this.state.collapsed ? 80 : 250,
                height: 68,
                alignItems: "center",
              }}
            >
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: this.toggle,
                }
              )}

              <div>
                <div
                  style={{
                    fontSize: 18,
                  }}
                >
                  <Dropdown overlay={this.menu} placement="bottomLeft">
                    <Button
                      style={{
                        border: "none",
                        width: 200,
                        height: 65,
                      }}
                      icon={<UserOutlined />}
                      size="large"
                    >
                      {this.props.role}_{this.props.username}
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </Header>
          )}
          <Content
            className="site-layout-background"
            style={{
              marginTop: "5px ",
              marginLeft: isMobile ? "" : this.state.collapsed ? 64 : 250,
              backgroundColor: "#f0f2f5",
            }}
          >
            <div
              className="site-layout-background"
              style={{
                marginRight: isMobile ? "" : this.state.collapsed ? 10 : "",
                marginLeft: isMobile ? "" : this.state.collapsed ? 24 : "",
                minHeight: isMobile ? 550 : 360,
                padding: isMobile ? "" : 24,
              }}
            >
              <Switch>
                <Route exact path={route.Logout} component={Logout} />
                {company.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                ))}
              </Switch>
            </div>
          </Content>
          {isMobile ? (
            <Footer style={{ textAlign: "center" }}>
              <div>{copyright.fullText()}</div>
              <div>V: {settings.version}</div>
              {/* <div>V: {version}</div> */}
            </Footer>
          ) : (
            <Footer style={{ textAlign: "center", marginLeft: 280 }}>
              <div>{copyright.fullText()}</div>
              <div>V: {settings.version}</div>
              {/* <div>V: {version}</div> */}
            </Footer>
          )}
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = ({ login }) => ({
  signIn: login.signIn,
  username: login.username,
  demoUser: login.demoUser,
  role: login.role,
  compCode: login.compCode,
});

const mapDispatchToProps = (dispatch) => {
  return { signOut: () => dispatch({ type: "sign_out" }) };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
