const GAME = {
    AWC:'awc',
    HABA:'haba',
    JOKER:'joker',
    SA:'sa',
    UFA:'ufa',
    WSSPORT:'wssport'
}

const GAMEPROVIDER_STATUS ={
    ACTIVE : 1,
    INACTIVE : 0
}

export {GAME, GAMEPROVIDER_STATUS}