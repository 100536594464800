import { Modal, Typography } from "antd";
import React from "react";
import { ExclamationCircleOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

const { Paragraph, Text } = Typography;

const sharedSuccessAlert = (alertData) => {
    Modal.confirm({
      className: "successModalStyle",
      title: alertData.title,
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
      content: alertData.msg,
      centered: true,
      okText: 'Close',
      cancelButtonProps: { style: { display: 'none' }},
    });
  };

  const sharedErrorAlert = (alertData) => {
    Modal.confirm({
      className: "errorModalStyle",
      title: alertData.title,
      icon: <ExclamationCircleOutlined />,
      content: alertData.msg,
      centered: true,
      okText: 'Close',
      cancelButtonProps: { style: { display: 'none' }},
    });
  };

  const sharedErrorAlertV2 = (alertData) => {
    Modal.confirm({
      className: "errorModalStyle",
      title: alertData.title,
      icon: <ExclamationCircleOutlined />,
      content: 
      <Typography>
        <Paragraph>
          <ul>
            <li>
              {alertData.msg}
            </li>
            <li>
              {alertData.redirectMsg} <a href={alertData.redirectUrl} target="_blank">here</a>.
            </li>
          </ul>
        </Paragraph>
      </Typography>,
      centered: true,
      okText: 'Close',
      cancelButtonProps: { style: { display: 'none' }},
    });
  };

  const sharedFailAlert = (alertData) => {
    Modal.confirm({
      className: "failModalStyle",
      title: alertData.title,
      icon: <CloseCircleTwoTone twoToneColor="#FF0000" />,
      content: alertData.msg,
      centered: true,
      okText: 'Close',
      cancelButtonProps: { style: { display: 'none' }},
    });
  };

  const alertData = {
    title: "",
    msg: "",
    redirectMsg: "",
    redirectUrl: "",

  };

  export {
    alertData,
    sharedSuccessAlert,
    sharedErrorAlert,
    sharedErrorAlertV2,
    sharedFailAlert,
};
