//#region ---------- Modules ----------

import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
} from "antd";

//#endregion

//#region ---------- Imports ----------
import {
  msgBroadcastPushnotify,
  msgBroadcastList,
  getCompanyProfileData,
} from "../../config/networkConfig";
import { CS_USER_TYPE } from "../../common/constants/csuserType";
import { CNotAuthorized } from "../../common/components/CNotAuthorized";
import { useSelector } from "react-redux";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";

//#endregion

export default function Notification() {
  //#region ---------- Variables ----------
  const { Option } = Select;
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [title, setTitle] = useState("");
  const [msg, setMsg] = useState("");
  const [originData, setOriginData] = useState("");
  const [platform, setPlatform] = useState("");
  const [tableLoadings, setTableLoadings] = useState(false);
  const { role, username, companyId  } = useSelector((state) => state.login);
  const [topicList, setTopicList] = useState([]);
  const [defaultTopic, setDefaultTopic] = useState("");
  const [formLoading, setFormLoading] = useState(false);

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 100 },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  //#endregion

  useEffect(() => {
    getCompanyProfile();
  }, []);

  useEffect(() => {
    if(platform != ''){
      notifList();
    }
  }, [platform]);

  //#region ---------- Functions ----------
  const getCompanyProfile = async () => {
    let requestData = {
      companyID: companyId,
      columnRequire: ['pushNotificationTopic as pushNotificationTopic']
    }

    getCompanyProfileData(requestData)
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          if(json.data[0].pushNotificationTopic != '') {
            // let tmpData = String(json.data[0].pushNotificationTopic).split(',');
            // setTopicList(tmpData);
            // setDefaultTopic(tmpData[0]);
            setPlatform(json.data[0].pushNotificationTopic);
          }
          else{
            alertData.title = "Failed";
            alertData.msg = "Push notification topic not found, please configure at company_profile via db !";
            sharedFailAlert(alertData);   
          }
        }
        else{ 
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);  
        }
    })
    .catch((error) => {
      console.log("error: ", error);
      alertData.title = "Error";
      alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
      sharedErrorAlert(alertData);
    });
  };

  const onReset = () => {
    form.resetFields();
    setTitle("");
    setMsg("");
    setVisible(false);
  };

  const notifList = () => {
    var count = 0;
    setTableLoadings(true);

    msgBroadcastList({ topic: platform })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          setTableLoadings(false);
          const newData = Object.keys(json.data).length
            ? json.data.map((x) => {
                return {
                  key: count++,
                  date: x.datetime,
                  title: x.title,
                  content: x.msg,
                };
              })
            : [];
          setOriginData(newData);
        }
        else {
          setTableLoadings(false);
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
  };

  const columns = [
    {
      title: "Create Date",
      dataIndex: "date",
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Content",
      dataIndex: "content",
    },
  ];

  const newNotif = () => {
    setFormLoading(true);
    if (platform == '') {
      alertData.title = "Field Required";
      alertData.msg = "Push notification topic not found, please configure at company_profile via db !";
      sharedFailAlert(alertData);
      setFormLoading(false);
      return;
    }

    if (title == '') {
      alertData.title = "Field Required";
      alertData.msg = "Please fill in Title !";
      sharedFailAlert(alertData);
      setFormLoading(false);
      return;
    }

    if (msg == '') {
      alertData.title = "Field Required";
      alertData.msg = "Please fill in Content !";
      sharedFailAlert(alertData);
      setFormLoading(false);
      return;
    }

    msgBroadcastPushnotify({
      title: title,
      msg: msg,
      topic: platform,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          notifList();
          onReset();
          alertData.title = "Success";
          alertData.msg = "Notification sent";
          sharedSuccessAlert(alertData);
          setFormLoading(false);
        } 
        else {
          alertData.title = "Failed";
          alertData.msg = "Connection poor, please try later !";
          sharedFailAlert(alertData);
          setFormLoading(false);
        }
      });
  };

  //#endregion

  //#region ---------- Renders ----------

  return (
    <div>
      <Modal
        title="Send Notification"
        visible={visible}
        footer={null}
        onCancel={onReset}
      >
        <Form 
        {...layout} 
        form={form}
        initialValues={{
          platform : platform,
        }}>
          <Form.Item
            label="Platform"
            rules={[{ required: true, whitespace: true, max: 200 }]}
            name="platform"
          >
            {/* <Select
              placeholder="Select which platform want to send"
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option.children.includes(input);
              }}
              onSelect={(value, event) => {
                setPlaftform(value, event);
              }}
            >
              {topicList.map((option) => {
                return (
                  <Option key={option} value={option}>
                    {option} 
                  </Option>
                );
              })}
            </Select> */}
            <Input
              disabled={true}
              value={platform}
            />
          </Form.Item>
          <Form.Item
            label="Title"
            name="Title"
            rules={[{ required: true, whitespace: true, max: 200 }]}
          >
            <Input
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Content"
            name="Content"
            rules={[
              {
                required: true,
                whitespace: true,
                max: 1024,
              },
            ]}
          >
            <Input.TextArea
              onChange={(e) => {
                setMsg(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item {...tailLayout} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: 8 }}
              onClick={newNotif}
              loading={formLoading}
            >
              Submit
            </Button>
            <Button 
              htmlType="button" 
              onClick={onReset}
              loading={formLoading}
              >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {
        (role == CS_USER_TYPE.Junior) ? <CNotAuthorized/>:
        (
          <>
            <PageHeader>
              <Button
                type="primary"
                onClick={() => {
                  setVisible(true);
                }}
              >
                Send Notification
              </Button>
            </PageHeader>
            <Table
              style={{ overflow: "auto", marginLeft: 20 }}
              loading={tableLoadings}
              columns={columns}
              dataSource={originData}
            />
        </>
        )
        
      }

    </div>
  );
}

//#endregion
