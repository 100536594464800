import React, { Component } from "react";
import "../../App.less";
import { Layout, Menu, Dropdown } from "antd";
import { NavLink, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";

//Navigate
import Login from "../login/login";
import { externalFileNotFile } from "../error/error";
import Logout from "../logout/logout";
import CompanyManagement from "../root/companyManagement";
import { route, logout } from "../../config/route";
import { copyright } from "../../library/texts";
import { Logo } from "../../common/components/Logo";
import { settings } from "../../config/appConfig";

const { Header, Content, Footer } = Layout;

class App extends Component {
  state = {
    collapsed: false,
  };
  componentDidMount() {
    document.addEventListener("gesturestart", function (e) {
      e.preventDefault(); //disable iphone zooming
    });
  }

  menu = () => {
    return isMobile ? (
      <div
        onClick={() => {
          this.setState({
            collapsed: false,
          });
          document.getElementsByTagName("body")[0].style.overflow = "auto";
        }}
        className="headerDropdownMenu"
      >
        <Menu>
          <div>
            <Menu.Item>
              <div className="rightTopMenuBtn">
                <UserOutlined className="rightTopMenuIcon" />
                {this.props.role}_{this.props.username}
              </div>
            </Menu.Item>
            <Menu.Item key={logout[0].path}>
              <NavLink to={logout[0].path}>
                <div className="rightTopDropdownMenu">
                  <LogoutOutlined className="logoutIconStyle" />
                  <span className="logoutTxt">Logout</span>
                </div>
              </NavLink>
            </Menu.Item>
          </div>
        </Menu>
      </div>
    ) : (
      <Menu>
        <Menu.Item key={logout[0].path}>
          <NavLink to={logout[0].path}>
            <div className="DRightTopDropdownMenu">
              <LogoutOutlined className="logoutIconStyle" />
              <span className="logoutTxt">Logout</span>
            </div>
          </NavLink>
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    return this.props.signIn === false ? (
      <Switch>
        <Route path={[route.Default, route.Login]} exact component={Login} />
        <Route path={route.All} component={externalFileNotFile} />
      </Switch>
    ) : isMobile ? (
      <Layout>
        <Header className="Mheader">
          <div className="logoContainer">
            <Logo logoType="root" />
            {/* <img src={logo.logo} alt="Logo" className="logo" /> */}
          </div>

          <Dropdown
            getPopupContainer={(trigger) => trigger.parentNode}
            visible={this.state.collapsed}
            overlay={this.menu}
            trigger={["click"]}
            onVisibleChange={() => {
              this.setState({
                collapsed: !this.state.collapsed,
              });
              document.getElementsByTagName("body")[0].style.overflow =
                "hidden";
            }}
          >
            <span className="MRightHeaderBtn" size="large">
              <UserOutlined className="logoutLogo" />
            </span>
          </Dropdown>
        </Header>
        <Content className="MContentContainer">
          <Switch>
            <Route exact path={logout[0].path} component={Logout} />
          </Switch>
          <CompanyManagement />
        </Content>
        <Footer className="ant-layout-footer">
          <div>{copyright.fullText()}</div>
          <div className="footerTxt">V: {settings.version}</div>
        </Footer>
      </Layout>
    ) : (
      <Layout>
        <Header className="Dheader">
          <div>
            <Logo logoType="root" />
          </div>
          <Dropdown
            overlay={this.menu}
            placement="bottomLeft"
            trigger={["hover"]}
          >
            <span onClick className="DRightHeaderBtn" size="large">
              <UserOutlined className="DRightHeaderIcon" />
              <div className="DLogoutTxt">
                {this.props.role}_{this.props.username}
              </div>
            </span>
          </Dropdown>
        </Header>
        <Content className="DContentContainer">
          <Switch>
            <Route exact path={logout[0].path} component={Logout} />
          </Switch>
          <CompanyManagement />
        </Content>
        <Footer className="ant-layout-footer">
          <div>{copyright.fullText()}</div>
          <div>V: {settings.version}</div>
        </Footer>
      </Layout>
    );
  }
}

const mapStateToProps = ({ login }) => ({
  signIn: login.signIn,
  username: login.username,
  role: login.role,
});

const mapDispatchToProps = (dispatch) => {
  return { signOut: () => dispatch({ type: "sign_out" }) };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
