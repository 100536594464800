import React, { useEffect, useState , useRef } from "react";
import { Col, Row, Form, Button, Switch, Input, Select, Spin, Table } from "antd";
import { Modal, Typography } from "antd";
import { useSelector } from "react-redux";
import { getSevenGameList, bindSevenGameUser, csVerifyMobile, getBindGameUserList } from "../../config/networkConfig";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert } from "../../common/constants/sharedMsgBox";
import { settings } from "../../config/appConfig";
import style from "../../resource/style/style";

const { TextArea } = Input;
const { Title } = Typography;

export default function BindGameUser() {
    const [form] = Form.useForm();
    const { Option } = Select;
  
    const { issuper, userId, username , role, companyId, compCode } = useSelector((state) => state.login);

    const [pageSetting, setPageSetting] = useState("");
    const [gameList, setGameList] = useState([]);
    const [gameOption, setGameOption] = useState([]);
    const [gameMobileNumber, setGameMobileNumber] = useState("");
    const [validMobile, setValidMobile] = useState(false);
    const [gameUsername, setGameUsername] = useState("");
    const [gamePassword, setGamePassword] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        getGameList();
    }, []);

    useEffect(() => {
        submitBindUserChecklist();
    }, [gameOption,validMobile,gameUsername]);

    const getGameList = async (condition) => {
        let requestData = {}
        let count = 0;

        const getGameList = await getSevenGameList(requestData); 
        const response = await getGameList.json();

        if (response.status == "ok") {
            setGameList(response.data);
        }
        else {
            alertData.title = "Failed";
            alertData.msg = response.msg;
            sharedFailAlert(alertData);
        }

        setTableLoading(true);

        const responseDL = await getBindGameUserList(response.data);
            let resultDL = await responseDL.json();
            if (resultDL.status === "ok") {
            const dlData = Object.keys(resultDL.data.bindGameUserData).length
            ? resultDL.data.bindGameUserData.map((obj) => {
                setTableLoading(false);
                return {
                    key             : ++count,
                    GameGroup       : obj.GameGroup,
                    UserSecret      : obj.UserSecret,
                    Username        : obj.Username,
                };
              })
            : [];

            setData(dlData);            
            setTableLoading(false);
        }
        else {
            alertData.title = "Failed";
            alertData.msg = resultDL.msg;
            sharedFailAlert(alertData);
        }
  
        setTableLoading(false);

    }

    const submitBindUserChecklist = async () => {
        let gameOptionCheck = false, gameMobileNumberCheck = false, gameUsernameCheck = false, gamePasswordCheck = false;
    
        if (gameOption) { 
            gameOptionCheck = true; 
        }
      
        if (validMobile) { 
            gameMobileNumberCheck = true; 
        }
    
        if (gameUsername) {
            gameUsernameCheck = true; 
        }

        if (gameOptionCheck && gameMobileNumberCheck && gameUsernameCheck) {
            setDisableSubmit(false);
        }
        else {
            setDisableSubmit(true);
        }
    }

    const onSubmit = async (data) => {
        setLoading(true);

        let requestData = {
            mobile: gameMobileNumber,
            username: gameUsername,
            provider: gameOption,
            password: gamePassword
        }

        const result = await bindSevenGameUser(requestData);
        const response = await result.json();

        if (response.status == "ok") {
            alertData.title = "Success";
            alertData.msg = response.msg;
            sharedSuccessAlert(alertData);
            clearAllFields();
            getGameList();
        }
        else {
            alertData.title = "Failed";
            alertData.msg = response.msg;
            sharedFailAlert(alertData);
        }
        
        setLoading(false);
    }

    const clearAllFields = () => {
        setGameOption("");
        setGameMobileNumber("");
        setValidMobile(false);
        setGameUsername("");
        setGamePassword("");
      }

    const validateUserPhone = async(phoneNum)=> {
        const response = await csVerifyMobile({ mobile: phoneNum });
        let result = await response.json();
      
        if (result.status == "ok") {
            setValidMobile(true);
        }
        else {
          setGameMobileNumber("");
          setValidMobile(false);
          alertData.title = "Validation Error";
          alertData.msg = "Invalid mobile number!";
          sharedErrorAlert(alertData);
        }
    }

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 10 },
    };

    const taillayout= {
        wrapperCol: { span: 2}
    }

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
    };

    ////#region  Columns
    const columns = [
        {
            ellipsis: true,
            align: "center",
            title: "Game Group",
            dataIndex: "GameGroup",
            width: 120,
            fixed:"left",
            render: function(text, record) {
                return text;
            }
        },
        {
            align: "center",
            title: "User ID",
            dataIndex: "UserSecret",
            width: "150px",
            render: function(text, record) {
                return (text);
            },
        },
        {
            align: "center",
            title: "Username",
            dataIndex: "Username",
            width: "150px",
            render: function(text, record) {
                return (text);
            }
        },
    ]

    const styles = {
        tableContainer: {
          backgroundColor: "#FFFFFF",
          padding: 24,
        },
    };

    return (
        <div>
            {       
            <div style={styles.tableContainer}>          
                <Form 
                    {...formItemLayout} 
                    form={form} 
                    name="control-hooks" 
                    onFinish={onSubmit} 
                    style={{
                        marginTop: 24
                    }}>

                    <Form.Item label="Target Game">
                        <Select 
                            placeholder="Select Game" 
                            style={{ 
                                width: 150
                            }}
                            onChange={(value, event) => {
                                setGameOption(value);
                            }}
                        >
                            { gameList.map((option) => {
                                return (
                                <Option key={option.provider}>
                                    {option.provider}
                                </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Mobile Number">
                        <Input
                            type="text"
                            placeholder="Enter Mobile Number"
                            value={gameMobileNumber}
                            style={{ 
                                width: 250
                            }}
                            onChange={(e) => {       
                                setGameMobileNumber(e.target.value);
                            }}
                            onBlur={(e) => {
                                validateUserPhone(e.target.value.replace(/\D|^0/g, ""));
                            }}
                        />
                    </Form.Item>

                    <Form.Item label="Game Username" >
                        <Input
                            type="text"
                            placeholder="Enter Seven Game Username"
                            onChange={(e) => {       
                                setGameUsername(e.target.value);
                            }}
                            value={gameUsername}
                            style={{ 
                                width: 250
                            }}
                        />
                    </Form.Item>

                    {/* <Form.Item label="Game Password" >
                        <Input
                            type="password"
                            placeholder="Enter Seven Game Password"
                            onChange={(e) => {       
                                setGamePassword(e.target.value);
                            }}
                            value={gamePassword}
                            style={{ 
                                width: 250
                            }}
                        />
                    </Form.Item> */}

                    {/* <Form.Item {...taillayout} style={{justifyContent: 'center'}}>
                        <Button type="primary" loading={isLoading} disabled={isDisabled} htmlType="submit"> Submit </Button>
                    </Form.Item> */}

                    <Form.Item
                        label=" "
                        colon={false}
                    >
                        <Button 
                            type="primary" 
                            htmlType="submit"
                            style={{
                                marginLeft: 50,
                            }}
                            disabled={disableSubmit}
                        >
                                Submit
                        </Button>
                    </Form.Item>
                    <Table
                        style={{ overflow: "auto", marginLeft: 20 }}
                        columns={columns}
                        dataSource={data}
                        pagination={{
                        defaultPageSize: 100,
                        showSizeChanger: true,
                        pageSizeOptions: settings.pageSizeOptionsLarge,
                        style: style.paginationStyle,
                        alignmentBottom: 'right',
                        }}
                        loading={tableLoading}
                    />
                </Form>
                </div>
            }
        </div>
    )    
  }