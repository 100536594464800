import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  message,
  PageHeader,
  Button,
  Modal,
  Input,
  Form,
  Row,
  Tooltip,
} from "antd";
import {
    newsList,
    newsUpdate,
    newsDelete,
    newsNew
} from "../../config/networkConfig";
import { useSelector } from "react-redux";
import { EditOutlined,DeleteOutlined} from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import { marqMgmtEdit , marqMgmtCr} from "../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";

export default function MarqueeManagement() {
    const { userId , role } = useSelector((state) => state.login);
    const { TextArea } = Input;
    const [form] = Form.useForm();
    const modalForm = useRef();
    const [originData, setOriginData] = useState("");
    const [newVisible , setNewVisible]  = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const { issuper} = useSelector((state) => state.login);
    const [tableLoading, setTableLoading] = useState(false);
    const [loadings, setLoadings] = useState([]);

    //Modal Dialog
    const [headline,setHeadline]       = useState("");
    const [headlineId,setHeadlineId]   = useState("");
    const [headlineKey,setHeadlineKey] = useState("");

    //
    const [enableDelete,setEnableDelete] = useState(false);


    useEffect(() => {
        // eslint-disable-next-line
        newsHeadlineList();
    }, []);

    useEffect(()=> {
      setHeadline(headline)
      setHeadlineId(headlineId)
    },[headline,headlineId])


    useEffect(() => {
      originData.length >  1 ? setEnableDelete(true):setEnableDelete(false);
    }, [originData])
    //#region action Button

    //Edit button
    const BtnEditMarquee = (props) => { 
        let record =  {
              id : props.headlineId,
              headline: props.headline,
        }

        return (                
          <Tooltip title="Edit Marquee">
              <Button type="primary" shape="circle" icon={<EditOutlined />} 
                  onClick={()=>{
                    editHeadline(record);
                  }
                }
                style={{...styles.actionBtn,...styles.refreshBtn}}
              />
          </Tooltip> 
        )
    }


    //Delete button
    const BtnDeleteMarquee = (props) => {   
      let record =  {
        id : props.headlineId,
        headline : props.headline,
        headlineKey: props.headlineKey
      }

      return (                
        <Tooltip title="Delete Marquee" style={{display:"none"}}>
            <Button type="danger" shape="circle" icon={<DeleteOutlined />} 
                onClick={()=>{
                  deleteHeadline(record);
                }
              }
              style={{...styles.actionBtn,...styles.refreshBtn,...styles.deleteBtn}}
            />
        </Tooltip> 
      )
    }



    ////#endregion

    //#region Load List
    const newsHeadlineList = async () => {
        setTableLoading(true);
        let count = 0 ;
        const result = await newsList({});
        let resp = await result.json();
        if (resp.status ==="ok") {
    
          if (Object.keys(resp.data).length > 0 ) {
    
            let newData = resp.data.map((obj) => {
                  return {
                    key: count++,
                    id: obj.id,
                    headline: obj.headline,
                  };
                });

            setOriginData(newData);
              
            setTableLoading(false);
    
          }
          else {
            setTableLoading(false);
            alertData.title = "Failed";
            alertData.msg = resp.msg;
            sharedFailAlert(alertData);
          }
        }
    }
    //#endregion


   //#region Columns
  let newsColumns = [
    // {
    //   align: "center",
    //   title: "Id",
    //   dataIndex:"id",
    //   width: "200px",
    //   //ellipsis: true,
    //   render :(text)=> {
    //     return "";
    //   }
    // },
    {
        align:"right",
        title:"Order",
        dataIndex:"key",
        width:"50px",
        render :(text)=> {
            return <div style={{textAlign:"right"}}>{parseInt(text)+1}</div>
        }
    },
    {
      align: "center",
      title: "Headline",
      dataIndex:"headline",
      width:"700px",
      render :(text)=> {
          return <div style={{textAlign:"left"}}>{text}</div>
      }
    },
    {
      align: "left",
      title: "Action",
      dataIndex: "status",
      width: "40px",
      render:(text,record)=> {
          return (
              <div             
              style={{
                flexDirection: "row",
                display: "flex",
                float:"left"
              }}>
                { marqMgmtEdit(role) ? <BtnEditMarquee headlineId={record.id} headline={record.headline}/>:<></>}
                <BtnDeleteMarquee headlineId={record.id} headline={record.headline} headlineKey={record.key+1}/>
              </div>
          )
      }
    }
  ];
  //#endregion

  //#region functions
  const deleteHeadline = (record) => {
    setHeadlineId(record.id);
    setHeadline(record.headline);
    setHeadlineKey(record.headlineKey);
    setDeleteVisible(true);
  }

  const editHeadline = (record) => {
    setHeadlineId(record.id);
    setHeadline(record.headline);
    setEditVisible(true);
  }

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const handleNewMarquee = async (index) => {
    setTableLoading(true);
    loadingTrue(index);

    let requestData = {
        news: headline,
      }

    const result = await newsNew(requestData);
    let resp = await result.json();
    if (resp.status === "ok") {
        loadingFalse(index);
        setNewVisible(false);
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData);
        //setTableLoading(false);
        newsHeadlineList();
    }else{
        loadingFalse(index);
        //setTableLoading(false);
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);
    }
    clearAllFields();  
  }

  const handleDeleteMarquee = async (index) => {
    
    setTableLoading(true);

    loadingTrue(index);

    let requestData = {
        newid: headlineId,
        news: headline,
      }

    const result = await newsDelete(requestData);
    let resp = await result.json();
    if (resp.status === "ok") {
        loadingFalse(index);
        setDeleteVisible(false);
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData);
        newsHeadlineList();
    }
    else{
        loadingFalse(index);
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);
    } 
  
    clearAllFields();
  }

  const handleEditMarquee = async (index)=> {
    setTableLoading(true);
    loadingTrue(index);

    if (headline == ""){
      alertData.title = "Field Required";
      alertData.msg = "Please fill in Headline !";
      sharedErrorAlert(alertData);
      return;
    }

    let requestData = {
        newid: headlineId,
        news: headline,
      }

    const result = await newsUpdate(requestData);
    let resp = await result.json();
    if (resp.status === "ok") {
        loadingFalse(index);
        setEditVisible(false);
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData);
        //setTableLoading(false);
        newsHeadlineList();
    }
    else{
        loadingFalse(index);
        //setTableLoading(false);
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);
    }  
    
    clearAllFields();
  }


  const onCancelNew = () => {
    setNewVisible(false);
    clearAllFields();
  }

  const onCancelDelete = () => {
    setDeleteVisible(false);
    clearAllFields();
  }

  const onCancelEdit = () => {
    setEditVisible(false);
    clearAllFields();
  }

  const clearAllFields = () => {
    setHeadline("");
    setHeadlineId("");
    setHeadlineKey("");
    setTableLoading(false);
  }
  //#endregion

  //#region Forms
  const onNewMarquee = () => {
    return (
      <Form form={form} 
            ref={modalForm}
            layout="vertical" 
            name="newMarquee"
            initialValues={{ remember:false }}
      >
        <Form.Item
          id="headline"
          label="Headline"
        >
          <>
            <Input.TextArea
                value={headline}
                onChange={(e) => {
                   setHeadline(e.target.value);
                }}
                maxLength={255}
                rows={4}
              />
              <span>Maximum 255 characters</span>
          </>
        </Form.Item>
      </Form>
    );
  };


  const onEditMarquee = () => {
    return (
      <Form form={form} 
            ref={modalForm}
            layout="vertical" 
            name="editMarquee"
            initialValues={{ remember:false }}
      >
        <Form.Item
          id="headline"
          label="Headline"
        >
          <>
            <Input.TextArea
                value={headline}
                onChange={(e) => {
                   setHeadline(e.target.value);
                }}
                maxLength={255}
                rows={4}
              />
              <span>Maximum 255 characters</span>
          </>
        </Form.Item>
      </Form>
    );
  };

  ////#endregion


    const styles = {
        pageHeaderContainer: {
          justifyContent: isMobile ? "" : "space-between",
          display: "flex",
          flexDirection: isMobile ? "column" : "",
          alignItems: isMobile ? "center" : "",
        },
        refreshBtn: {
          marginLeft: 10,
        },
        actionBtn: {
            height:"30px",
            display : "block",
        },
        deleteBtn: {
          display: enableDelete?  "block":"none",
        },
        tabelContainer: {
          backgroundColor: "#FFFFFF",
          padding: 24,
        },
        paginationStyle: {
          marginTop: 50,
        },
        formItem: {
          width:"80%"
        },
        datePickerStyle: {
          width: 260,
        },
        memberSearchStyle:{
          width:180,
        },
        inlineBlockStyle:{
          display:"inline-block"
        },
        hide : {
          display:"none",
        },

      };
      return (
        <div>
            <Modal title="Delete Headline" visible={deleteVisible}
                  onOk={handleDeleteMarquee}
                  onCancel={() => {
                    onCancelDelete();
                  }}
                  maskClosable={false}
                  cancelButtonProps={{ type: "primary" }}
                  okButtonProps={{ type: "danger", loading: loadings[0], title: "Delete" }}
                  okText="Delete"
                  >
                  <p style={{textAlign:"center"}}><b>Do you want to delete Headline?</b></p>

                  <Form form={form} 
                  layout="vertical" 
                  name="deleteHeadline"
                  initialValues={{ remember:false }}
                  >
                  <Form.Item
                    label="Order"
                    style={{fontWeight:"normal"}}
                  >
                      <p style={{fontWeight:"bolder"}}>{headlineKey}</p>
                  </Form.Item>
                  <Form.Item
                    label="Headline"
                    style={{fontWeight:"normal"}}
                  >
                      <p style={{fontWeight:"bolder"}}>{headline}</p>
                  </Form.Item>
                </Form>
            </Modal>
            <Modal title= "New Headline" visible={newVisible} 
                onOk={handleNewMarquee}
                onCancel={() => {
                  onCancelNew();
                }}
                maskClosable={false}
                cancelButtonProps={{ type: "primary" }}
                okButtonProps={{ type: "danger", loading: loadings[0], title: "Save" }}
                okText="Save"
                >
                {onNewMarquee()}
          </Modal>
            <Modal title= "Edit Headline" visible={editVisible} 
                onOk={handleEditMarquee}
                onCancel={() => {
                // setAddMemberModal(false);
                // clearAllFields();
                onCancelEdit();
                }}
                maskClosable={false}
                cancelButtonProps={{ type: "primary" }}
                okButtonProps={{ type: "danger", loading: loadings[0], title: "Save" }}
                okText="Save"
                >
                {onEditMarquee()}
          </Modal>
          <PageHeader ghost={false}>
            <div style={styles.pageHeaderContainer}>
                <div
                    style={{
                    justifyContent: "left",
                    textAlign: "center",
                    display: "flex"
                    }}
                >
                <Button
                    style={{...styles.refreshBtn}}
                    type="primary"
                    onClick={() => {
                    newsHeadlineList();
                    }}
                >
                    Refresh Page
                </Button>
                {
                  marqMgmtCr(role)?
                  (
                    <Button
                    style={{...styles.refreshBtn,...styles.actionBtn}}
                    type="primary"
                    onClick={() => {
                        setNewVisible(true);
                    }}
                    >
                        Create Headline
                    </Button>
                  ):<></>
                }

              </div>
            </div>
          </PageHeader>
          <div style={styles.tabelContainer}>
            <Row>
                  <b>Items on top will appear first</b>
            </Row>
            <Table
              columns={newsColumns}
              dataSource={originData}
              loading={tableLoading}
              scroll={{ x: isMobile ? "110%" : "100%", y: null }}
              pagination={false}
              // pagination={{
              //   defaultPageSize: 10,
              //   showSizeChanger: true,
              //   pageSizeOptions: settings.pageSizeOptions,
              //   style: styles.paginationStyle,
              //   alignmentBottom: 'right',
              // }}
            />
           </div>    
        </div>
      );
    }