import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { reportBankBalance } from "../../../config/networkConfig";
import {
  message,
  Table,
  PageHeader,
  DatePicker,
  Button,
  Breadcrumb,
} from "antd";
import { isMobile } from "react-device-detect";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import { settings } from "../../../config/appConfig";
import { FormatData, FormatPt } from "../../../library/numbers";
import moment from "moment";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";

export default function BankBalanceReport() {
  const { userId } = useSelector((state) => state.login);
  const [report, setReport] = useState("");
  const [filter, setFilter] = useState("");
  const [reportDetail, setReportDetail] = useState("");
  const [filterDetail, setFilterDetail] = useState("");
  const [date, setDate] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [share, setShare] = useState(0);
  const reportDateFrom = useRef(null);
  const reportDateTo = useRef(null);

  useEffect(() => {
    //reportList();
    //reportShareDetailList();
    // eslint-disable-next-line
  }, []);

  const reportList = () => {
    var count = 0;
    var countDetail = 0;
    reportBankBalance({
      userId: userId,
      datefrom: date.format("YYYY-MM-DD"),
      dateto: dateTo.format("YYYY-MM-DD"),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          const report = Object.keys(json.data).length
            ? json.data.map((data) => {
                return {
                  key: ++count,
                  dateTime: data.TrxnDate,
                  startTime: data.StartTime,
                  endTime: data.EndTime,
                  bankCode: data.BankCode,
                  bankAcc: data.BankAcc,
                  bankAccName: data.BankAccName,
                  bankOpening: data.BankOpening,
                  depositCount: data.DepositCount,
                  depositAmount: data.DepositAmount,
                  withdrawCount: data.WithdrawCount,
                  withdrawAmount: data.WithdrawAmount,
                  commWithdrawCount: data.CommWithdrawCount,
                  commWithdrawAmount: data.CommWithdrawAmount,
                  bankClosing: data.BankClosing,
                };
              })
            : [];

          // const filter = report.filter(
          //   (data) => data.dateTime === report[0].dateTime
          // );

          setReport(report);
          //setFilter(filter);
        }
        else {
          alertData.title = "Failed";
          alertData.msg = "Connection not stable, please try again !";
          sharedFailAlert(alertData);
        }
      });
  };

  const reportShareDetailList = () => {
    // reportShareDetail({ userId: userId })
    //   .then((response) => response.json())
    //   .then((json) => {
    //     console.log("json: ", json);
    //   });
  };

  const numFormat = (num) => {
    num = isNaN(num) ? 0 : num;
    return parseFloat(num).toFixed(settings.decimalScale);
  };

  const description = (data) => {
    return (
      <div style={styles.decContainer}>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Turnover:</p>
          <p>{data.turnover}</p>
        </div>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Position Taking Turnover:</p>
          <p>{data.ptTurnover}</p>
        </div>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Referer Turnover:</p>
          <p>{data.refererTurnover}</p>
        </div>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Share:</p>
          <p>{data.shares}</p>
        </div>
      </div>
    );
  };

  const columns = [
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "dateTime",
      width: 150,
      ellipsis: true,
      fixed: "left",
    },
    {
      align: "center",
      title: "Bank",
      dataIndex: "bankCode",
      width: 100,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Bank Acc",
      dataIndex: "bankAcc",
      width: 100,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Bank Acc. Name",
      dataIndex: "bankAccName",
      width: 100,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
    },
    {
      align: "center",
      title: "Bank Opening",
      dataIndex: "bankOpening",
      width: 100,
      //ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function (record) {
        return FormatPt(record);
      },
    },
    {
      align: "center",
      title: "Bank Closing",
      dataIndex: "bankClosing",
      width: 100,
      //ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function (record) {
        return FormatPt(record);
      },
    },
    {
      align: "center",
      title: "Deposit Count",
      dataIndex: "depositCount",
      width: 100,
      //ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
    },
    {
      align: "center",
      title: "Deposit Amount",
      dataIndex: "depositAmount",
      width: 100,
      //ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function (record) {
        return FormatPt(record);
      },
    },
    {
      align: "center",
      title: "Withdraw Count",
      dataIndex: "withdrawCount",
      width: 100,
      //ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
    },
    {
      align: "center",
      title: "Withdraw Amount",
      dataIndex: "withdrawAmount",
      width: 100,
      //ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function (record) {
        return FormatPt(record);
      },
    },
    {
      align: "center",
      title: "Comm. Withdraw Count",
      dataIndex: "commWithdrawCount",
      width: 100,
      //ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
    },
    {
      align: "center",
      title: "Comm. Withdraw Amount",
      dataIndex: "commWithdrawAmount",
      width: 100,
      //ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function (record) {
        return FormatPt(record);
      },
    },
  ];

  const mobileColumns = [
    {
      align: "center",
      title: "Data / Time",
      dataIndex: "dateTime",
      width: "50%",
      ellipsis: true,
    },
    {
      title: "Bank Code",
      dataIndex: "bankCode",
      width: "50%",
      ellipsis: true,
    },
  ];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    if (report.length == 0) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = `Bank Balance Report (${date.format(
      "YYYY-MM-DD"
    )} to ${dateTo.format("YYYY-MM-DD")})\n\n\n`;

    const headers = [
      [
        columns[0].title,
        columns[1].title,
        columns[2].title,
        columns[3].title,
        columns[4].title,
        columns[5].title,
        columns[6].title,
        columns[7].title,
        columns[8].title,
        columns[9].title,
        columns[10].title,
        columns[11].title,
      ],
    ];

    var filteredReport = [...report];
    const data = filteredReport.map((data) => {
      return [
        data[columns[0].dataIndex],
        data[columns[1].dataIndex],
        data[columns[2].dataIndex],
        data[columns[3].dataIndex],
        FormatPt(data[columns[4].dataIndex]),
        FormatPt(data[columns[5].dataIndex]),
        data[columns[6].dataIndex],
        FormatPt(data[columns[7].dataIndex]),
        data[columns[8].dataIndex],
        FormatPt(data[columns[9].dataIndex]),
        data[columns[10].dataIndex],
        FormatPt(data[columns[11].dataIndex]),
      ];
    });

    let content = {
      startY: 80,
      head: headers,
      body: data,
      headStyles: { fillColor: "#1DA57A" },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`Bank Balance report.pdf`);
  };

  // const datePicker = (date, dateString) => {
  //   setDate(date);
  // };

  const datePicker = (date, dateString) => {
    setDate(date);
  };

  const datePicker2 = (date, dateString) => {
    setDateTo(date);
  };

  const searchBar = () => {
    if (!date) {
      reportDateFrom.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date From !";
      sharedErrorAlert(alertData);
      return;
    }

    if (!dateTo) {
      reportDateTo.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date To !";
      sharedErrorAlert(alertData);
      return;
    }

    reportList();
    // if (!date) {
    //   return;
    // }
    // var newReport = [...report];
    // const filter = newReport.filter(
    //   (data) => data.dateTime === date.format("YYYY-MM-DD")
    // );

    // const getTotalShare = filter.map((data) => data.totalShare);
    // var shareTotal = getTotalShare.reduce(function (previous, current) {
    //   return previous + current;
    // }, 0);

    // setShare(shareTotal);
    // setFilter(filter);
  };

  const resetBtn = () => {
    setDate(null);
    setDateTo(null);
    setReport([]);
  };

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      //   path: "first",
      breadcrumbName: "Report",
    },
    {
      path: "bankBalanceReport",
      breadcrumbName: "Bank Balance Report",
    },
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 2;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHearderContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    searchBtn: {
      marginLeft: 30,
    },
    resetBtn: {
      marginLeft: 10,
    },
    datePickerStyle: {
      width: 300,
      borderRadius: 10,
    },
    mobilePageHearderContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    eachButtonContainer: {
      alignItems: "center",
      display: "flex",
      height: 50,
      marginLeft: 15,
      marginRight: 15,
      justifyContent: "center",
    },
    mobileSearchResetBtn: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      height: 50,
      alignItems: "center",
      paddingLeft: 60,
      paddingRight: 60,
    },
    btnContainer: {
      width: "100vw",
      padding: 10,
    },
    pdfStyle: {
      borderRadius: 10,
      width: 1200,
    },
    searchResetStyle: {
      borderRadius: 10,
      marginLeft: 10,
    },
    decStyle: {
      display: "flex",
      flexDirection: "row",
    },
    decLable: {
      width: 200,
    },
    decContainer: {
      paddingLeft: 75,
    },
    webRow: {
      backgroundColor: "#FFFFFF",
      paddingTop: 50,
      paddingLeft: 30,
      paddingBottom: 50,
    },
    mobileRow: {
      backgroundColor: "#FFFFFF",
      paddingLeft: 30,
    },
    cardStyle: {
      borderRadius: 20,
      height: 160,
      width: 280,
      border: "none",
    },
    headStyles: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 25,
      height: 80,
    },
    bodyStyle: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 80,
    },
  };

  return (
    <div className="eachPageContent">
      {isMobile ? (
        <div>
          <PageHeader ghost={false}>
            <div>
              <Breadcrumb
                separator=">"
                itemRender={itemRender}
                routes={routes}
              />
              <div style={styles.btnContainer}>
                <div style={styles.eachButtonContainer}>
                  <Button
                    type={"primary"}
                    onClick={exportPDF}
                    style={styles.pdfStyle}
                  >
                    Download PDF
                  </Button>
                </div>
                <div style={styles.eachButtonContainer}>
                  <DatePicker
                    style={styles.datePickerStyle}
                    onChange={datePicker}
                    value={date}
                    ref={reportDateFrom}
                    placeholder="Select Date From"
                    disabledDate={(current) => {
                      return current > moment().subtract(1, "day");
                    }}
                  />
                  <DatePicker
                    style={styles.datePickerStyle}
                    onChange={datePicker2}
                    value={dateTo}
                    ref={reportDateTo}
                    placeholder="Select Date To"
                    disabledDate={(current) => {
                      let dateFrom = date;
                      if (dateFrom) {
                        return (
                          current < dateFrom || current > moment().endOf("day")
                        );
                      } else {
                        return current;
                      }
                    }}
                  />
                  {/* <DatePicker
                    style={styles.datePickerStyle}
                    onChange={datePicker}
                    value={date}
                  /> */}
                  <Button
                    type={"primary"}
                    onClick={searchBar}
                    style={styles.searchResetStyle}
                    icon={<SearchOutlined />}
                  />

                  <Button
                    onClick={resetBtn}
                    style={styles.searchResetStyle}
                    icon={<UndoOutlined />}
                  />
                </div>
              </div>
              <div style={styles.tableContainer}>
                <Table
                  columns={mobileColumns}
                  dataSource={report}
                  // expandable={{
                  //   expandedRowRender: (record) => (
                  //     <p style={{ margin: 0 }}>{record.description}</p>
                  //   ),
                  //   rowExpandable: (record) => record.name !== "Not Expandable",
                  // }}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20"],
                    style: styles.paginationStyle,
                  }}
                />
              </div>
            </div>
          </PageHeader>
        </div>
      ) : (
        <div>
          <PageHeader
            ghost={false}
            title="Bank Balance Report"
            extra={[
              <DatePicker
                key="1"
                style={styles.datePickerStyle}
                onChange={datePicker}
                value={date}
                ref={reportDateFrom}
                placeholder="Select Date From"
                disabledDate={(current) => {
                  //return current > moment().subtract(1, 'day');
                  return current > moment().endOf("day");
                }}
              />,
              <DatePicker
                style={styles.datePickerStyle}
                onChange={datePicker2}
                value={dateTo}
                ref={reportDateTo}
                placeholder="Select Date To"
                disabledDate={(current) => {
                  let dateFrom = date;
                  if (dateFrom) {
                    return (
                      current < dateFrom || current > moment().endOf("day")
                    );
                  } else {
                    return current;
                  }
                }}
              />,
              // <DatePicker
              //   key="1"
              //   style={styles.datePickerStyle}
              //   onChange={datePicker}
              //   value={date}
              // />,
              <Button
                key="2"
                type={"primary"}
                style={styles.searchBtn}
                onClick={searchBar}
              >
                Search
              </Button>,
              <Button key="3" style={styles.resetBtn} onClick={resetBtn}>
                Reset
              </Button>,
              <Button key="4" type={"primary"} onClick={exportPDF}>
                Download PDF
              </Button>,
            ]}
          />
          <div style={styles.tableContainer}>
            {/* <span>Bank Balance</span> */}
            <Table
              columns={columns}
              dataSource={report}
              scroll={{ x: 100, y: null }}
              className="bankBalance"
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: settings.pageSizeOptions,
                style: styles.paginationStyle,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
