import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Table,
  Input,
  Form,
  Button,
  Modal,
  Badge,
  PageHeader,
  message,
  Select,
  Spin,
} from "antd";

import {
  EditOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import {
  newsModalRecord,
  newsModalNew,
  newsModaldetail
} from "../../config/networkConfig";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { FormatDateHHMM } from "../../library/dates";
import { settings } from "../../config/appConfig";
import { newsModuleEdit } from "../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";

export default function NewsModal() {
  const [form] = Form.useForm();
  const modalForm = useRef();
  const { role } = useSelector((state) => state.login);

  const [newsData, setNewsData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [tableLoadings, setTableLoadings] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const { Option } = Select;

  // movie
  const [loading, setLoading] = useState(false);
  const [titleEn, setTitleEn] = useState("");
  const [titleTh, setTitleTh] = useState("");
  const [cta, setCTA] = useState("Okay");
  const [buttonEn, setButtonEn] = useState("");
  const [buttonth, setButtonTh] = useState("");
  const [descEn, setDescEn] = useState("");
  const [descTh, setDescTh] = useState("");
  const [cover,setCover] = useState("");
  const [status, setStatus] = useState("Enabled");
  const [newsId, setNewsId] = useState(1);


  useEffect(() => {
    newsModalData();
  }, []);



  const clearAllFields = () => {

    setNewsId(1)
    setTitleEn("")
    setTitleTh("")
    setButtonEn("")
    setButtonTh("")
    setCTA("Okay")
    setDescEn("")
    setDescTh("")
    setStatus("Enabled")
    setCover("")
  }

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  };

  const onCreateNewsRecord = () =>{
    return(
      <Form
        form={form}
        ref={modalForm}
        {...layout}
        name="createNewsRecord"
        initialValues={{remember: false}}
      >
        <Form.Item label="Title (EN)">
        <Input
            allowClear
            value={titleEn}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="News Title (EN)"
            // rules={[{required:true, message:'Please input the title of the movie'}]}
            onChange = {(e) => {
              setTitleEn(e.target.value);
            }}
          />
          {/* <br/>
          <span>Maximum 30 characters</span> */}
        </Form.Item>
        <Form.Item label="Title (TH)" name="title-th" rules={[{required:true, message:'Please input the title of the news in Thai'}]} >
        <Input
            allowClear
            value={titleTh}
            style={{width:"470px"}}
            placeholder="News Title (TH)"
            onChange = {(e) => {
              setTitleTh(e.target.value);
            }}
          />
          <br/>
          <span>This field is required</span>
        </Form.Item>
        <Form.Item label="Description (EN)">
        <Input.TextArea
            allowClear
            value={descEn}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Description (EN)"
            // rules={[{required:true, message:'Please input the description in English'}]}
            rows={4}
            onChange = {(e) => {
              setDescEn(e.target.value);
            }}
          />
          {/* <br/>
          <span>If not applicable input <b>N/A</b></span> */}
        </Form.Item>
        <Form.Item label="Description (TH)"  name="decth">
        <Input.TextArea
            allowClear
            value={descTh}
            // maxLength={150}
            rows={4}
            style={{width:"470px"}}
            placeholder="Description (TH)"
            ules={[{required:true, message:'Please input the description in Thai'}]}
            onChange = {(e) => {
              setDescTh(e.target.value);
            }}
          />
          <br/>
          <span>The description for the news in Thai</span>
        </Form.Item>
        <Form.Item label="Button (EN)">
        <Input
            allowClear
            value={buttonEn}
            // maxLength={150}
            style={{width:"470px"}}
            placeholder="Display text for button (EN)"
            // rules={[{required:true, message:'Please input the title of the movie'}]}
            onChange = {(e) => {
              setButtonEn(e.target.value);
            }}
          />
          <br/>
          <span>The label for the button i.e. Invite Now</span>
        </Form.Item>
        <Form.Item label="Button (TH)" name="buttonth" rules={[{required:true, message:'Please input the title of the news in Thai'}]} >
        <Input
            allowClear
            value={buttonth}
            style={{width:"470px"}}
            placeholder="Display text for button (TH)"
            onChange = {(e) => {
              setButtonTh(e.target.value);
            }}
          />
          <br/>
          <span>The label for the button in Thai i.e. ภารกิจ</span>
        </Form.Item>
        <Form.Item label="Cover" name="cover-create" rules={[{required:true, message:'Please enter the path to the news\'s cover'}]}>
        <Input
            allowClear
            value={cover}
            maxLength={150}
            style={{width:"470px"}}
            placeholder="E.G.  /public/img/news_list/Avengers_Infinity_War_soundtrack_cover.jpg"
            onChange = {(e) => {
              setCover(e.target.value);
            }}
          />
          <br/>
          <span>Cover image for news.</span>
        </Form.Item>
        <Form.Item label="Call To Action">
            <Select value={cta}
              defaultValue="okay"
              onChange={(e)=>{setCTA(e)}}>
              <Option value="okay">Okay</Option>
              <Option value="Mission">Mission</Option>
              <Option value="Topup">Topup</Option>
              <Option value="InviteFriend">InviteFriend</Option>
            </Select>
          <br/>
          <span>The page that user will be navigate to after clicking the button</span>
          <ul>
            <li>Okay - Close current news</li>
            <li>Mission - Open up the mission page in the app</li>
            <li>Topup - Open up the topup page in the app</li>
            <li>InviteFriend - Open up the invite friend page in the app</li>
          </ul>
        </Form.Item>
        <Form.Item label="Status">
            <Select value={status}
                defaultValue="Enabled"
                onChange={(e)=>{setStatus(e)}}>
                <Option value="1">Enabled</Option>
                <Option value="0">Disabled</Option>
            </Select>
          <br/>
          <span>To enable or disable the news </span>
        </Form.Item>
      </Form>
    )
  }


  const onEditNewsDetail = () =>{
    return(
      <Form
      form={form}
      ref={modalForm}
      {...layout}
      name="editNewsRecord"
      initialValues={{remember: false}}
    >
      <Form.Item label="Title (EN)">
      <Input
          allowClear
          value={titleEn}
          // maxLength={150}
          style={{width:"470px"}}
          placeholder="News Title (EN)"
          // rules={[{required:true, message:'Please input the title of the movie'}]}
          onChange = {(e) => {
            setTitleEn(e.target.value);
          }}
        />
        {/* <br/>
        <span>Maximum 30 characters</span> */}
      </Form.Item>
      <Form.Item label="Title (TH)" name="title-th" rules={[{required:true, message:'Please input the title of the news in Thai'}]} >
      <Input
          allowClear
          value={titleTh}
          style={{width:"470px"}}
          placeholder="News Title (TH)"
          onChange = {(e) => {
            setTitleTh(e.target.value);
          }}
        />
        <br/>
        <span>The title for the news </span>
      </Form.Item>
      <Form.Item label="Description (EN)">
      <Input.TextArea
          allowClear
          value={descEn}
          // maxLength={150}
          style={{width:"470px"}}
          placeholder="Description (EN)"
          // rules={[{required:true, message:'Please input the description in English'}]}
          rows={4}
          onChange = {(e) => {
            setDescEn(e.target.value);
          }}
        />
        {/* <br/>
        <span>If not applicable input <b>N/A</b></span> */}
      </Form.Item>
      <Form.Item label="Description (TH)"  >
      <Input.TextArea
          allowClear
          value={descTh}
          // maxLength={150}
          rows={4}
          style={{width:"470px"}}
          placeholder="Description (TH)"
          rules={[{required:true, message:'Please input the description in Thai'}]}
          onChange = {(e) => {
            setDescTh(e.target.value);
          }}
        />
        <br/>
        <span>The description for news in Thai</span>
      </Form.Item>
      <Form.Item label="Button (EN)">
      <Input
          allowClear
          value={buttonEn}
          // maxLength={150}
          style={{width:"470px"}}
          placeholder="Display text for button (EN)"
          // rules={[{required:true, message:'Please input the title of the movie'}]}
          onChange = {(e) => {
            setButtonEn(e.target.value);
          }}
        />
        <br/>
        <span>The label for the button i.e. Invite Now</span>
      </Form.Item>
      <Form.Item label="Button (TH)" name="buttonth" rules={[{required:true, message:'Please input the title of the news in Thai'}]} >
      <Input
          allowClear
          value={buttonth}
          style={{width:"470px"}}
          placeholder="Display text for button (TH)"
          onChange = {(e) => {
            setButtonTh(e.target.value);
          }}
        />
        <br/>
        <span>The label for the button in Thai i.e. ภารกิจ</span>
      </Form.Item>
      <Form.Item label="Cover">
      <Input
          allowClear
          value={cover}
          maxLength={150}
          style={{width:"470px"}}
          placeholder="E.G.  /public/img/news_list/Avengers_Infinity_War_soundtrack_cover.jpg"
          rules={[{required:true, message:'Please enter the path to the news\'s cover'}]}
          onChange = {(e) => {
            setCover(e.target.value);
          }}
        />
        <br/>
        <span>Cover image for news.</span>
      </Form.Item>
      <Form.Item label="Call To Action">
          <Select value={cta}
            defaultValue="okay"
            onChange={(e)=>{setCTA(e)}}>
            <Option value="okay">Okay</Option>
            <Option value="Mission">Mission</Option>
            <Option value="Topup">Topup</Option>
            <Option value="InviteFriend">InviteFriend</Option>
          </Select>
          <br/>
          <span>The page that user will be navigate to after clicking the button</span>
          <ul>
            <li>Okay - Close current news</li>
            <li>Mission - Open up the mission page in the app</li>
            <li>Topup - Open up the topup page in the app</li>
            <li>InviteFriend - Open up the invite friend page in the app</li>
          </ul>
      </Form.Item>
      <Form.Item label="Status">
          <Select value={status}
              defaultValue="Enabled"
              onChange={(e)=>{setStatus(e)}}>
              <Option value="1">Enabled</Option>
              <Option value="0">Disabled</Option>
          </Select>
        <br/>
        <span>To enable or disable the news </span>
      </Form.Item>
    </Form>

    )
  }


  ////#region Populate List functions
  const newsModalData = async () =>{
    let count = 0; // to set key for display
    setTableLoadings(true);
    const tmp = await newsModalRecord({data:null});
    let res = await tmp.json();

    if (res.status === "ok" && res.msg != "No record."){
      if(Object.keys(res.data).length > 0){
        setTableLoadings(false);
        let data = res.data.map((x)=>{
          return{
            key: count++,
            id: x.id,
            titleEn: x.titleen,
            titleTh: x.titleth,
            descEn: x.descen,
            descTh: x.descth,
            cta: x.cta,
            buttonen: x.buttonen,
            buttonth: x.buttonth,
            status: x.status == 0?
            "Disabled": "Enabled"
          }
        })
        setNewsData(data)
        setAddVisible(false);
      }
      else {
        setTableLoadings(false);
        alertData.title = "Failed";
        alertData.msg = res.msg;
        sharedFailAlert(alertData);
      }
    }
    else{
      alertData.title = "Error";
      alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
      sharedErrorAlert(alertData);
    }
  }


  ////#endregion Populate

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
        <Input allowClear={true} />

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const showModal = async () => {
    setAddVisible(true);
  };


  const editNews = async (record) => {
    // console.log('record',record)
    const newsId = record.id.toString();
    // console.log('movieId',movieId)
    setLoading(true);
    // loadingScreen(true);
    const tmp = await newsModaldetail({id: newsId});
    let res = await tmp.json();
    setLoading(false)

    if (res.status === "ok" ){
        let data = res.data.map((x)=>{
          return{
            id: x.id,
            titleEn: x.titleen,
            titleTh: x.titleth,
            buttonEn: x.buttonen,
            buttonTh: x.buttonth,
            cta: x.cta,
            descEn: x.descen,
            descTh: x.descth,
            cover: x.cover,
            status: x.status == 0?
            "Disabled": "Enabled"
          }
        })

        let news = data[0]
        setNewsId(news.id)
        setTitleEn(news.titleEn)
        setTitleTh(news.titleTh)
        setCTA(news.cta)
        setDescEn(news.descEn)
        setDescTh(news.descTh)
        setCover(news.cover)
        setButtonEn(news.buttonEn)
        setButtonTh(news.buttonTh)
        setStatus(news.status)
        // loadingScreen(false)
        setEditVisible(true);

      }
      else{
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      }

  }
  let searchInput = null;

  const movie_column = [
    {
      title: "ID",
      dataIndex: "id",
      className: "hide"
    },
    {
      title: "Title (EN)",
      dataIndex: "titleEn",
      // width: "20%",
    },
    {
      title: "Title (TH)",
      dataIndex: "titleTh",
      // width: "15%",
    },
    {
      title: "Description  (EN)",
      dataIndex: "descEn",
      // className: { display: 'none' }
      // width: "15%",
    },
    {
      title: "Description (TH)",
      dataIndex: "descTh",
      // width: "15%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "20%",
      render: (text)=> {
          if (text=="Disabled") {
            return <Badge status="error" text="Disabled"/>;
          }else{
            return <Badge status="success" text="Enabled" />;
          }
      },
      filters: [
        {
          text: 'Disabled',
          value: 'Disabled',
        },

        {
          text: 'Enabled',
          value: 'Enabled',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.status.indexOf(value) === 0
    },
    ,
    {
      title: "Action",
      dataIndex: "",
      width: "10%",
      render: (text, record)=> {
        return (
          <>
          {
            newsModuleEdit(role)?
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                // editCampaign(record);
                editNews(record);

              }}
            />:<></>
          }
          </>
        )
      }
    }
  ]


 const handleEdit = async(index)=> {
  if(!titleTh){
    alertData.title = "Field Required";
    alertData.msg = "Please fill in the News Title for Thai version !";
    sharedErrorAlert(alertData);
    return;
  }

  if(!descTh){
    alertData.title = "Field Required";
    alertData.msg = "Please fill in the Description of the news for Thai version !";
    sharedErrorAlert(alertData);
    return;
  }

  if(!buttonth){
    alertData.title = "Field Required";
    alertData.msg = "Please fill in the label of the Button for Thai version !";
    sharedErrorAlert(alertData);
    return;
  }

  setLoading(true)
  let stat;

  if(!parseInt(status)){
    stat = status == 'Enabled' ? 1 : 0;
  }
  else{
    stat = parseInt(status);
  }
  // let stat = status == "Enabled" ? 1 : 0;
    let requestData = {
      "id": newsId,
      "titleen": titleEn,
      "titleth": titleTh,
      "cta": cta,
      "buttonen": buttonEn,
      "buttonth": buttonth,
      "descen": descEn,
      "descth": descTh,
      "cover": cover,
      "status": stat,
    }

// console.log('update data',requestData)
  newsModalNew(requestData)
  .then((response) => response.json())
  .then((json) => {
    setEditVisible(false);
    if (json.status === "ok") {
      setTimeout(()=>{
        setLoading(false);
        newsModalData();
        alertData.title = "Success";
        alertData.msg = json.msg;
        sharedSuccessAlert(alertData);
        clearAllFields();
      }, 2000)

    } 
    else {
      setLoading(false);
      alertData.title = "Failed";
      alertData.msg = json.msg;
      sharedFailAlert(alertData);
    }
    loadingFalse(index);
    //modalForm.current.resetFields();
    clearAllFields();
    form.resetFields(["title-th", "descth", "cover-create", "buttonth"]);
  })
  .catch((error) => {
    // console.log("error: ", error);
    alertData.title = "Error";
    alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
    sharedErrorAlert(alertData);
  });
 }

  const handleAdd = (index) => {

    if(!titleTh){
      alertData.title = "Field Required";
      alertData.msg = "Please fill in the Movie Title for Thai version !";
      sharedErrorAlert(alertData);
      return;
    }

    if(!descTh){
      alertData.title = "Field Required";
      alertData.msg = "Please fill in the Description of the news for Thai version !";
      sharedErrorAlert(alertData);
      return;
    }

    setLoading(true)
    let stat;

    if(!parseInt(status)){
      stat = status == 'Enabled' ? 1 : 0;
    }
    else{
      stat = parseInt(status);
    }
    // let stat = status == "Enabled" ? 1 : 0;
      let requestData = {
        "id": newsId,
        "titleen": titleEn,
        "titleth": titleTh,
        "cta": cta,
        "buttonen": buttonEn,
        "buttonth": buttonth,
        "descen": descEn,
        "descth": descTh,
        "cover": cover,
        "status": stat
      }

    newsModalNew(requestData)
    .then((response) => response.json())
    .then((json) => {
      setEditVisible(false);
      if (json.status === "ok") {
        setTimeout(()=>{
          setLoading(false);
          newsModalData();
          alertData.msg = json.msg;
          sharedSuccessAlert(alertData);
          clearAllFields();
          // clearAllFields();
        }, 2000)

      }
      else {
        setLoading(false);
        alertData.title = "Failed";
        alertData.msg = json.msg;
        sharedFailAlert(alertData);
      }
      loadingFalse(index);
      //modalForm.current.resetFields();
      clearAllFields();
      form.resetFields(["title-th", "descth", "cover-create", "buttonth"]);
    })
    .catch((error) => {
      // console.log("error: ", error);
      alertData.title = "Error";
      alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
      sharedErrorAlert(alertData);
    });
  };

  const onCancel = () => {
    setAddVisible(false);
    clearAllFields();
    //modalForm.current.resetFields();
  };

  const onCancelEdit = () => {
    setEditVisible(false);
    clearAllFields();
  }

  const styles = {
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
    },
    refreshBtn: {
      marginLeft: 10,
    },
    tabelContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    formItem: {
      width:"80%"
    },
    datePickerStyle: {
      width: 260,
    },
    hide : {
      display:"none",
    },
    memberSearchStyle:{
      width:180,
    }
  };

  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <div>
        <Modal title="New News Record"  visible={addVisible}
        onOk={handleAdd}
        onCancel={() => {
          onCancel();
        }}
        maskClosable={false}
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "danger", loading: loadings[0], title: "Save" }}
        okText="Save"
        center
        width={850}
        >
          {onCreateNewsRecord()}
        </Modal>
        <Modal
        title='Loading'
        visible={loading}
        center
        width={850}
        footer={null}>
          <Spin spinning={loading} className='container' tip='Loading ...'></Spin>
        </Modal>

        <Modal title= "Edit News" visible={editVisible}
        onOk={handleEdit}
        // onOk={handleAdd}
        onCancel={() => {
          onCancelEdit();
        }}
        maskClosable={false}
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "danger", loading: loadings[0], title: "Save" }}
        okText="Save"
        center
        width={850}
        >
          {/* {onEditFBCampaign()} */}

          {onEditNewsDetail()}
        </Modal>
      </div>
      <PageHeader ghost={false}
      >

      </PageHeader>
      <Form form={form} component={false}>
        <Table
          loading={tableLoadings}
          scroll={{ x: "100%", y: null }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={newsData} // originData
          columns ={movie_column} //columns
          rowClassName="editable-row"

          pagination={{
            defaultPageSize: 15,
            showSizeChanger: true,
            pageSizeOptions: settings.pageSizeOptions,
            style: styles.paginationStyle,
            alignmentBottom: 'right',
          }}
          style={{ overflow: "auto", marginLeft: 20 }}
        />
      </Form>
    </div>
  );
}
