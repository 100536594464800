import React, { useState, useEffect, useRef} from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  PageHeader,
  Select,
  InputNumber,
} from "antd";
import { 
  SearchOutlined, 
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { isMobile } from "react-device-detect";
import {
  getBankList,
  getPuppetJobList,
  transferFund,
} from "../../config/networkConfig";
import { settings } from "../../config/appConfig";
import { useSelector } from "react-redux";
import style from "../../resource/style/style";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";
import { BANK_INFO_TYPE } from "../../common/constants/bankType";
import { fundTransfer } from "../../library/permission";
import { CNotAuthorized } from "../../common/components/CNotAuthorized";

export default function FundTransfer() {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [tableLoading, setTableLoading] = useState(false);
    const { role, userId, companyId, issuper, compCode } = useSelector((state) => state.login);

    //Fund Transfer
    const [formFundTransfer]    = Form.useForm();
    const [fundTransferVisible, setFundTransferVisible] = useState(false);
    const [transferFromBankList, setTransferFromBankList] = useState([]);
    const [transferToBankList, setTransferToBankList] = useState([]);

    const [fromBankData, setFromBankData] = useState("");
    const [fromBankCode, setFromBankCode] = useState("");
    const [fromAcctNum, setFromAcctNum] = useState("");
    const [fromAcctName, setFromAcctName] = useState("");

    const [toBankData, setToBankData] = useState("");
    const [toBankCode, setToBankCode] = useState("");
    const [toAcctNum, setToAcctNum] = useState("");
    const [toAcctName, setToAcctName] = useState("");  

    const [transferAmt, setTransferAmt] = useState(1);

    //To Prevent Multiple Create
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    //Form
    const { Option } = Select;

    // Onload function
    useEffect(() => {
        puppetJobList();
        onLoadTransferFromBankList();
        onLoadTransferToBankList();
    }, []);

    useEffect(() => {
        submitFundTransferCheckList();
    }, [fromBankData,toBankData,transferAmt]);

    useEffect(()=> {
        if (fromBankData != null) {
            let FromBankData = fromBankData.split("||");
            setFromBankCode((FromBankData[0]||'').trim());
            setFromAcctNum((FromBankData[1]||'').trim());
            setFromAcctName((FromBankData[2]||'').trim());
        }

        if (toBankData != null) {
            let ToBankData = toBankData.split("||");
            setToBankCode((ToBankData[0]||'').trim());
            setToAcctNum((ToBankData[1]||'').trim());
            setToAcctName((ToBankData[2]||'').trim());
        }
    },[fromBankData, toBankData])
    
    // Function
    const submitFundTransferCheckList = async () => {
        let transferFromBankCheck = true, transferToBankCheck = true, transferAmtCheck = true;
    
        if (!fromBankData) { 
            transferFromBankCheck = false; 
        }
      
        if (!toBankData) { 
            transferToBankCheck = false; 
        }
    
        if (transferAmt <= 0) {
            transferAmtCheck = false; 
        }
        
        if (transferFromBankCheck && transferToBankCheck && transferAmtCheck) {
            setDisableSubmit(false);
        }
    }

    const puppetJobList = async () => {
        setTableLoading(true);

        var count = 0;
        let requestData = {
            pptJobType: 'InternalTransfer'
        }
        
        const resPuppetJoblist = await getPuppetJobList(requestData);
        let result = await resPuppetJoblist.json();

        if (result.status == "ok") {
            const newData = Object.keys(result.data).length
            ? result.data.map((x) => {
                return {
                    key : count++,
                    SysCreateTime : x.SysCreateTime,
                    FromAccNo : x.FromAccNo,
                    FromAccName : x.FromAccName,
                    ToBankAcc : x.ToBankAcc,
                    ToAccName : x.ToAccName,
                    Amount : x.Amount,
                    Remark : x.Remark
                };
            })
            : [];

            setData(newData);
            setTableLoading(false);
        }
        else {
            setTableLoading(false);
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
        }
    };

    const onLoadTransferFromBankList = async () => {
        let requestData = {
            bankType: BANK_INFO_TYPE.DEP,
            columnRequire : ['AccountNum', 'BankCode', 'AccountName', 'BankType'],
        }
    
        const response = await getBankList(requestData);
        let result = await response.json();

        if (result.status ==  'ok'){
            setTransferFromBankList(result.data);
        }
        else {
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
        }
    };
    
    const onLoadTransferToBankList = async () => {
        let requestData = {
            columnRequire : ['AccountNum', 'BankCode', 'AccountName', 'BankType'],
        }

        const response = await getBankList(requestData);
        let result = await response.json();

        if (result.status ==  'ok'){

            // remove redundant bank
            let filter_redundant_bank = result.data.filter((item, index) => index === result.data.findIndex( elem => elem.AccountNum === item.AccountNum));
            setTransferToBankList(filter_redundant_bank);
        }
        else {
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
        }
    };

    const transferFromBankOnchange = async (value) => {
        let requestData = {
            columnRequire : ['AccountNum', 'BankCode', 'AccountName', 'BankType'],
        }

        const response = await getBankList(requestData);
        let result = await response.json();

        if (result.status ==  'ok'){
            // remove redundant bank
            let filter_redundant_bank = result.data.filter((item, index) => index === result.data.findIndex( elem => elem.AccountNum === item.AccountNum));
            let extractTransferFromBankData = value.split("||");

            // filter out selected transferFrom bank to prevent user transfer to same bank
            let filter_selected_bank = filter_redundant_bank.filter(e => e.AccountNum != extractTransferFromBankData[1].trim());
            setTransferToBankList(filter_selected_bank);
        }
        else {
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
        }
    };

    const transferToBankOnchange = async (value) => {
        let requestData = {
            bankType: BANK_INFO_TYPE.DEP,
            columnRequire : ['AccountNum', 'BankCode', 'AccountName', 'BankType'],
        }

        const response = await getBankList(requestData);
        let result = await response.json();

        if (result.status ==  'ok'){
            // remove redundant bank
            let extractTransferToBankData = value.split("||");

            // filter out selected transferFrom bank to prevent user transfer to same bank
            let filter_selected_bank = result.data.filter(e => e.AccountNum != extractTransferToBankData[1].trim());
            setTransferFromBankList(filter_selected_bank);
        }
        else {
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
        }
    };

    // const handleSubmitTransfer = async () => {
    //     let currentBalance = result.data[0].curBal;
    //     let afterBalance = currentBalance - removeCreditAmt;
    //     let msg  = `Are you sure you want to proceed to remove `+removeCreditAmt+`B from user?`;
    //         msg += afterBalance < 0 ? ` (User balance will be negative if you do so.)` : "" ;

    //         confirm({
    //         className: "confirmationModalStyle",
    //         title: 'Confirmation',
    //         centered: true,
    //         icon: <QuestionCircleOutlined />,
    //         width: "500px",
    //         content: 
    //         <Paragraph>{msg}
    //             <ul>
    //             <p> 
    //                 <li>
    //                 Current Balance : {Number(currentBalance).toFixed(2)}
    //                 </li>
    //                 <li>
    //                 After Balance : {Number(afterBalance).toFixed(2)}
    //                 </li>
    //             </p>  
    //             </ul>
    //         </Paragraph>,
    //         onOk() {
    //             onSubmitOKRemoveCreditForm();
    //         },
    //         onCancel() {},
    //         });
    // }

    const handleSubmitTransfer = async () => {
        setDisableSubmit(false);
        setLoadingSubmit(true);

        if (fromBankData == '') {
            alertData.title = "Field Required";
            alertData.msg = "Please select Transfer From Bank Account !";
            sharedErrorAlert(alertData);
            setDisableSubmit(true);
            return;
        }

        if (toBankData == '') {
            alertData.title = "Field Required";
            alertData.msg = "Please select Transfer To Bank Account !";
            sharedErrorAlert(alertData);
            setDisableSubmit(true);
            return;
        }

        if (!transferAmt) {
            alertData.title = "Field Required";
            alertData.msg = "Amount must not less than 0 !";
            sharedErrorAlert(alertData);
            setDisableSubmit(true);
            return;
        }

        let requestData = {
            fromBankAcc : fromAcctNum, 
            toBankAcc : toAcctNum, 
            amount : transferAmt, 
            csUserId : userId, 
            transferType : 'InternalTransfer'
        }

        const resTransfer = await transferFund(requestData);
        let result = await resTransfer.json();
        
        if (result.status == "ok") {
            setLoadingSubmit(false);
            puppetJobList();
            onCancel();
            alertData.title = "Success";
            alertData.msg = result.msg;
            sharedSuccessAlert(alertData);
        }
        else {
            setLoadingSubmit(false);
            puppetJobList();
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
        }

        setDisableSubmit(true);
    };

    const onCancel = ()=>{    
        setFromBankData("");
        setToBankData("");
        setTransferAmt(1);

        formFundTransfer.resetFields();
        setFundTransferVisible(false);
    }

    const onFundTransferForm = () => {
        return (
            <div>
                <div>
                    <Form 
                        form={formFundTransfer} 
                        layout="vertical" 
                        name="fundTransferForm"
                    >
                        {/* Bank Acc Name */}
                        <Form.Item
                            label="Transfer From Bank"
                            required={true}
                        >
                            <Select
                                style={styles.datePickerStyle2}
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    return option.children.includes(input);
                                }}
                                getPopupContainer={(trigger) => trigger.parentNode}
                                onChange={(value, event) => {
                                    setFromBankData(value);
                                    transferFromBankOnchange(value);
                                    //Search Bank Acc Name
                                    for (var i = 0; i < transferFromBankList.length; i++) {
                                        if (transferFromBankList[i].bankAcc === value) {
                                        }
                                    }
                                }}
                                >
                                { transferFromBankList.map((option) => {
                                    return (
                                    <Option key={option.AccountNum + option.BankType} value={`${option.BankCode} || ${option.AccountNum} || ${option.AccountName}`}>
                                        {option.BankCode} ({option.AccountNum}) - {option.AccountName}
                                    </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Transfer To Bank"
                            required={true}
                        >
                            <Select
                                style={styles.datePickerStyle2}
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    return option.children.includes(input);
                                }}
                                getPopupContainer={(trigger) => trigger.parentNode}
                                onChange={(value, event) => {
                                    setToBankData(value);
                                    transferToBankOnchange(value);
                                    //Search Bank Acc Name
                                    for (var i = 0; i < transferToBankList.length; i++) {
                                    if (transferToBankList[i].bankAcc === value) {
                                    }
                                    }
                                }}
                                >
                                { transferToBankList.map((option) => {
                                    return (
                                    <Option key={option.AccountNum + option.BankType} value={`${option.BankCode} || ${option.AccountNum} || ${option.AccountName}`}>
                                        {option.BankCode} ({option.AccountNum}) - {option.AccountName}
                                    </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        {/* Amount */}
                        <Form.Item
                            label="Amount"
                            required={true}
                        >
                            <InputNumber
                                style={{width:"150px"}}
                                onChange={(value) => {
                                    setTransferAmt(value);
                                }}
                                min={1}
                                max={49999}
                                type="number"
                                value={transferAmt}
                            />
                        </Form.Item>

                        <Button 
                            type="primary" 
                            onClick={handleSubmitTransfer}
                            disabled={disableSubmit}
                            loading={loadingSubmit}
                        >
                            Submit
                        </Button>
                    </Form>
                </div>
            </div>
        );
    };

    let searchInput = null;

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
            <Input
                ref={(node) => {
                searchInput = node;
                }}
                placeholder={`Search ${dataIndex=="newNickname"? "nickname":dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90, marginRight: 8 }}
            >
                Search
            </Button>
            <Button
                onClick={() => handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
            >
                Reset
            </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
            ? record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
            : false,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) setTimeout(() => searchInput.select());
        },
        render: (text) =>
            text ? (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
            ) : (
            false
            ),
    });

    const columns = [
        {
            title: "Date / Time",
            dataIndex: "SysCreateTime",
            width: "180px",
            align: "center",
            ellipsis: true,
        },
        {
            title: "From Bank Acc",
            dataIndex: "FromAccNo",
            ...getColumnSearchProps("FromAccNo"),
            width: "200px",
            align: "center",
            ellipsis: true,
        },
        {
            title: "From Bank Name",
            dataIndex: "FromAccName",
            ...getColumnSearchProps("FromAccName"),
            width: "200px",
            align: "center",
            // ellipsis: true,
        },
        {
            title: "To Bank Acc",
            dataIndex: "ToBankAcc",
            ...getColumnSearchProps("ToBankAcc"),
            width: "200px",
            align: "center",
            ellipsis: true,
        },
        {
            title: "To Bank Name",
            dataIndex: "ToAccName",
            ...getColumnSearchProps("ToAccName"),
            width: "200px",
            align: "center",
            // ellipsis: true,
        },
        {
            title: "Amount",
            dataIndex: "Amount",
            ...getColumnSearchProps("Amount"),
            width: "130px",
            align: "center",
        },
        {
            title: "Remark",
            dataIndex: "Remark",
            ...getColumnSearchProps("Remark"),
            width: "200px",
            align: "center",
            // ellipsis: true,
        },
    ];

    const styles = {
        pageHeaderContainer: {
            justifyContent: isMobile ? "" : "space-between",
            display: "flex",
            flexDirection: isMobile ? "column" : "",
            alignItems: isMobile ? "center" : "",
        },

        refreshBtn: {
            marginLeft: 10,
        },

        tableContainer: {
            backgroundColor: "#FFFFFF",
            padding: 24,
        },
        paginationStyle: {
            marginTop: 50,
        },

        hide :{
            display:"none",
        }
    };
    
    return (
        <div>
            {
                fundTransfer(role, compCode) ?
                <div className="fundTransfer">
                    <PageHeader ghost={false}>
                        <div style={styles.pageHeaderContainer}>
                            <div>
                            <Button
                                style={styles.refreshBtn}
                                type="primary"
                                onClick={() => {
                                    puppetJobList();
                                }}
                            >
                                Refresh Page
                            </Button>
                            {
                                <Button
                                    type="primary"
                                    style={styles.refreshBtn}
                                    onClick={() => {
                                        setFundTransferVisible(true);
                                        onLoadTransferFromBankList();
                                    }}
                                >
                                    Create Transfer
                                </Button>
                            }
                            </div>
                        </div>
                    </PageHeader>

                    <Modal
                        title="Fund Transfer Form"
                        visible={fundTransferVisible}
                        closable={false}
                        maskClosable={false}
                        getContainer={false}
                        okButtonProps={({style:style.hide})} 
                        cancelText="Close"
                        onCancel= {() => {
                            onCancel();
                        }}
                    cancelButtonProps={{ type: "primary" }}           
                    >
                        {onFundTransferForm()}
                    </Modal>

                    <div style={styles.tableContainer}>
                        <Table
                            loading={tableLoading}
                            columns={columns}
                            dataSource={data}
                            scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                            pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: settings.pageSizeOptions,
                            style: styles.paginationStyle,
                            alignmentBottom: 'right',
                        }}
                        />
                    </div>
                </div>
            :
                <CNotAuthorized/>
            }
        </div>
    );
}