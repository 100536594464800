import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Spin,
  Select,
  Divider,
  DatePicker,
} from "antd";
import { useSelector } from "react-redux";
import {
  getCommSettingProfile,
  updateCommSettingProfile,
  getPromotionData,
  updatePromotionSetting,
} from "../../config/networkConfig";
import { COMM_WIN_LOSE, COMM_TURNOVER } from "../../common/constants/constant";
import {
  alertData,
  sharedSuccessAlert,
  sharedErrorAlert,
  sharedFailAlert,
} from "../../common/constants/sharedMsgBox";
import { Typography } from "antd";
import moment from "moment";

const { Title } = Typography;

export default function PromotionPage() {
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { userId, companyId } = useSelector((state) => state.login);
  const [form] = Form.useForm();

  const [playerRebate, setPlayerRebate] = useState("");
  const [fortuneWheelPercent, setFortuneWheelPercent] = useState("");
  const [bonusWallet, setBonusWallet] = useState("");
  const [promoDuration, setPromoDuration] = useState("");
  const [maxPromoAmount, setMaxPromoAmount] = useState("");
  const [spinLoading, setSpinLoading] = useState(true);

  useEffect(() => {
    loadPageData();
  }, []);

  const loadPageData = async () => {
    let finalPlayerRebate = "",
      bonusWallet = "",
      promoStart = "",
      promoEnd = "",
      maxPromoAmount = "";

    let requestData = {
      companyID: userId,
      columnRequire: [
        "CommSetMember as playerRebate",
        "FortuneWheelPercent as fortuneWheelPercent",
      ],
    };

    // Get player rebate from comm_setting
    const response = await getCommSettingProfile(requestData);
    let result = await response.json();
    if (result.status === "ok") {
      if (result.data[0].playerRebate != "") {
        finalPlayerRebate = String(result.data[0].playerRebate).split(":");
      }
    } else {
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
      setSpinLoading(false);
      return;
    }

    let requestData2 = {
      companyID: userId,
      promoCode: "ANGPAO-1",
      columnRequire: [
        "BonusWallet as bonusWallet",
        "PromoStart as promoStart",
        "PromoEnd as promoEnd",
        "MaxPromoAmount as maxPromoAmount",
      ],
    };

    const response2 = await getPromotionData(requestData2);
    let result2 = await response2.json();
    if (result2.status === "ok") {
      bonusWallet = String(result2.data[0].bonusWallet);
      promoStart = String(result2.data[0].promoStart);
      promoEnd = String(result2.data[0].promoEnd);
      maxPromoAmount = Number(result2.data[0].maxPromoAmount).toFixed(2);
    } else {
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
      setSpinLoading(false);
      return;
    }

    form.setFieldsValue({
      playerRebate:
        finalPlayerRebate != "" ? Number(finalPlayerRebate[1]).toFixed(2) : 0,
      fortuneWheelPercent:
        fortuneWheelPercent >= 0
          ? Number(result.data[0].fortuneWheelPercent).toFixed(2)
          : 0,
      bonusWallet: bonusWallet != "" ? bonusWallet : "",
      promoDuration: [moment(promoStart), moment(promoEnd)],
      maxPromoAmount: maxPromoAmount >= 0 ? maxPromoAmount : 0,
    });
    setSpinLoading(false);
  };

  const onFinish = async (values) => {
    setSpinLoading(true);

    let requestData = {
      companyID: userId,
      // playerRebate : finalPlayerRebate,
      // fortuneWheelPercent : Number(values.fortuneWheelPercent).toFixed(2)
    };

    let finalPlayerRebate = "",
      finalPromoStartDate = "",
      finalPromoEndDate = "";
    let comm_setting_updated = false,
      promotion_setting_updated = false,
      validBonusWallet = false;

    if (values.maxPromoAmount <= 0) {
      alertData.title = "Validation Error";
      alertData.msg = "Promo Max Amount cannot be 0 !";
      sharedErrorAlert(alertData);
      setSpinLoading(false);
      return;
    }

    if (String(values.bonusWallet).length >= 0) {
      if (String(values.bonusWallet).includes("-") == false) {
        alertData.title = "Format Error";
        alertData.msg =
          "Please provide a proper range for Angpao Reward Range ! E.g. 1.00 - 10.00";
        sharedErrorAlert(alertData);
        setSpinLoading(false);
        return;
      } else {
        let count = 0;
        for (let i = 0; i < String(values.bonusWallet).length; i++) {
          if (values.bonusWallet[i] == "-") {
            count++;
          }
        }

        if (count > 1) {
          alertData.title = "Format Error";
          alertData.msg =
            "Please provide a proper range for Angpao Reward Range ! E.g. 1.00 - 10.00";
          sharedErrorAlert(alertData);
          setSpinLoading(false);
          return;
        }
      }

      let convertedBonusWallet = "";
      convertedBonusWallet = String(values.bonusWallet).split("-");

      if (Number(convertedBonusWallet[1]) <= Number(convertedBonusWallet[0])) {
        alertData.title = "Validation Error";
        alertData.msg =
          "Invalid Angpao Reward Range, please fill in another range !";
        sharedErrorAlert(alertData);
        setSpinLoading(false);
        return;
      }

      validBonusWallet = true;
    }

    if (values.playerRebate >= 0) {
      finalPlayerRebate =
        "L0:" +
        Number(values.playerRebate).toFixed(2) +
        ":" +
        `${COMM_TURNOVER}`;
      requestData.playerRebate = finalPlayerRebate;
    }

    if (values.fortuneWheelPercent >= 0) {
      requestData.fortuneWheelPercent = Number(
        values.fortuneWheelPercent
      ).toFixed(2);
    }

    // Update comm_setting
    const response = await updateCommSettingProfile(requestData);
    let result = await response.json();

    if (result.status == "ok") {
      comm_setting_updated = true;
    } else {
      comm_setting_updated = false;
    }

    let requestData2 = {
      companyID: userId,
      promoCode: "ANGPAO-1",
    };

    if (validBonusWallet == true) {
      requestData2.bonusWallet = String(values.bonusWallet);
    }

    if (promoDuration != "") {
      requestData2.promoStartDate = promoDuration[0];
      requestData2.promoEndDate = promoDuration[1];
    }

    if (values.maxPromoAmount > 0) {
      requestData2.promoMaxAmount = Number(values.maxPromoAmount).toFixed(2);
    }

    // Update promotion
    const response2 = await updatePromotionSetting(requestData2);
    let result2 = await response2.json();

    if (result2.status == "ok") {
      promotion_setting_updated = true;
    } else {
      promotion_setting_updated = false;
    }

    if (comm_setting_updated == true && promotion_setting_updated == true) {
      alertData.title = "Success";
      alertData.msg = "Promotion Setting is updated.";
      sharedSuccessAlert(alertData);
      setSpinLoading(false);
      loadPageData();
    } else {
      alertData.title = "Failed";
      alertData.msg = "Something went wrong, please try again later !";
      sharedFailAlert(alertData);
      setSpinLoading(false);
      return;
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 6,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
  };

  return (
    <Spin spinning={spinLoading} size="large">
      <Form
        {...formItemLayout}
        onFinish={onFinish}
        form={form}
        initialValues={
          {
            // playerTier: playerAffiliate,
          }
        }
      >
        <Form.Item>
          <Title level={4}>Fortune Wheel Setting</Title>
        </Form.Item>

        <Form.Item name="fortuneWheelPercent" label="Fortune Wheel (%)">
          <InputNumber
            value={fortuneWheelPercent}
            min={0}
            max={100}
            style={{ width: "300px", textAlign: "center" }}
            placeholder="E.g. 0.00% - 100.00%"
            onChange={(e) => {
              setFortuneWheelPercent(e);
            }}
          />
        </Form.Item>

        <Divider></Divider>

        <Form.Item style={{ align: "center" }}>
          <Title level={4}>Invite Angpao Setting</Title>
        </Form.Item>

        <Form.Item name="bonusWallet" label="Angpao Reward Range">
          <Input
            value={bonusWallet}
            style={{ width: "300px" }}
            placeholder="E.g. 0.00 - 100.00"
            onChange={(e) => {
              setBonusWallet(e);
            }}
          />
        </Form.Item>

        <Form.Item name="promoDuration" label="Promo Date Duration">
          <RangePicker
            style={{ width: "300px" }}
            value={promoDuration}
            onChange={(e, value) => {
              setPromoDuration(value);
            }}
          />
        </Form.Item>

        <Form.Item name="maxPromoAmount" label="Promo Max Amount">
          <InputNumber
            value={maxPromoAmount}
            min={0}
            // max={100}
            style={{ width: "300px" }}
            placeholder="E.g. 100.00"
            onChange={(e) => {
              setMaxPromoAmount(e);
            }}
          />
        </Form.Item>

        <Divider></Divider>

        <Form.Item style={{ align: "center" }}>
          <Title level={4}>Player Rebate Setting</Title>
        </Form.Item>

        <Form.Item name="playerRebate" label="Player Rebate (%)">
          <InputNumber
            value={playerRebate}
            min={0}
            max={100}
            style={{ width: "300px" }}
            placeholder="E.g. 0.00% - 100.00%"
            onChange={(e) => {
              setPlayerRebate(e);
            }}
          />
        </Form.Item>

        <Form.Item label=" " colon={false}>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              marginLeft: 100,
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
}
