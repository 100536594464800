import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  message,
  PageHeader,
  Badge,
  Radio,
  Select,
  InputNumber,
  Divider,
  Tooltip,
  Row,
} from "antd";
import { SearchOutlined, InfoCircleFilled} from "@ant-design/icons";
import {
  csWithdrawList,
  csWithdrawCheck,
  //csWithdrawApprove,
  csWithdrawComplete,
  csVerifyMobile,
  userBankList,
  csCreateWithdrawal,
  csWithdrawRejectV3,
  csWithdrawUpdate,
  csWithdrawManualApprove,
  csWithdrawManualApproveV2,
  getReqToken,
  csMemberWalletTrxn
} from "../../config/networkConfig";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { settings } from "../../config/appConfig";
import { PROCESS_TYPE } from "../../common/constants/processType";
import { FormatPt , ShowMobileNumber} from "../../library/numbers";
import md5 from "md5";
import moment from "moment";
import { witMgmtManCrWit , witMgmtManApprRej } from "../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";

export default function WithdrawManagement2() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [originData, setOriginData] = useState([]);
  const [data, setData] = useState([]);
  const [statusVisible, setStatusVisible] = useState(false);
  const [rejectVisible, setRejectVisible] = useState(false);
  const { userId, username, companyId, role ,issuper} = useSelector((state) => state.login);
  const [activeKey, setActiveKey] = useState("");
  const [confirmAmount, setConfirmAmount] = useState(0);
  const [mbBank, setMbBank] = useState("");
  const [mbBankAcct, setMBbankAcct] = useState("");
  const [mbBankAcctname, setMBbankAcctname] = useState("");
  const [searchText, setSearchText] = useState("");
  const [userBal, setUserBal] = useState("");
  const [userTurnover, setUserTurnover] = useState("");
  const [depositBal, setDepositBal] = useState("");
  const [depTime, setDepTime] = useState("");
  const [depAmt, setDepAmt] = useState("");
  const [winLose, setWinLose] = useState("");
  const [tranInAmt, setTranInAmt] = useState("");
  const [tranOutAmt, setTranOutAmt] = useState("");
  const [checkStatusBtn, setCheckBtn] = useState(false);
  const [reason, setReason] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [userRecord, setUserRecord] = useState([]);
  const [nickname, setNickname] = useState("");
  const [status, setStatus] = useState("Pending");
  const [countryCode,setCountryCode] = useState("60");
  const [phoneNum, setPhoneNum] = useState("");
  const [reqTurnover, setReqTurnover] = useState("");
  const [maxWithdrawAmt, setMaxWithdrawAmt] = useState("");
  //Customer Bank Account Details
  const [custBankCode, setCustBankCode] = useState("");
  const [custBankName, setCustBankName] = useState("");
  const [custBankAccName, setCustBankAccName] = useState("");
  const [custBankAcc, setCustBankAcc] = useState("");

  //Dialog 
  const [manualWithdraw,setManualWithdrawVisible] = useState(false);
  //const [submitDisable, setSubmitDisable] = useState(true);
  const [withdrawDisable, setWithdrawDisable] = useState(true);
  const { Option } = Select;
  const [csAvaBankList, setCSAvaBankListOptions] = useState([]);
  const [bankcode, setBankCode] = useState("");  
  //const [acctnum, setAcctNum] = useState(""); 
  const [acctno, setAcctNo] = useState(""); 
  const [acctname,setAcctname] = useState("");
  const [withdrawAmt, setWithdrawAmt] = useState(0);
  const [changeBank,setChangeBank] = useState(false);
  const [withdrUserId, setWithdrUserId] = useState("");
  // const [userAccess, setUserAccess] = useState({});

  //Update Transactions
  const [updateTrxVisible,setUpdateTrxVisible ] = useState(false);
  const [withdrawTrxnid,setWithdrawTrxnId] = useState("");

  //Wallet Transaction 
  const [puppetVisible, setPuppetVisible]= useState(false);
  const [csMemberNickname,setCSMemberNickname] = useState("");
  const [cstMemberPhone, setCSMemberPhone] = useState("");
  const [csMemberWalletDt,setCSMemberWalletDt] = useState("");
  const [csMemberWalletBal, setCSMemberWalletBal] = useState(0);
  const [csMemberWalletComm,setCSMemberWalletComm] = useState(0);
  const [filteredPptTrxn,setFilterPptTrxn]= useState([]);
  const [trxLoading, setTrxLoading] = useState(false);


  useEffect(() => {
    withdrawData();
    //availBankList();
    // eslint-disable-next-line
  }, [status]);

  const withdrawData = () => {
    setTableLoading(true);
    var count = 0;
    csWithdrawList({ userId: userId , processtype: PROCESS_TYPE.MANUAL})
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok"  ) {
     
          const newData = Object.keys(json.data).length
            ? json.data.map((x) => {
                setTableLoading(false);
                return {
                  key: count++,
                  trxnId: x.trxnId,
                  userId: x.userId,
                  nickname: x.nickname,
                  mobile  : x.mobile,
                  newNickname: ShowMobileNumber(role, x.nickname, x.mobile),
                  amount: x.amount * -1,
                  mbbank: x.mbBank,
                  date: x.dateTime,
                  bankNameOut: x.bank,
                  bankAcctOut: x.bankAcc,
                  mbbankAcct: x.mbBankAcc,
                  processBy: x.processBy,
                  puppetRemark : x.puppetRemark,
                  remark :x.remark,
                  // new details
                  custBankCode : x.mbBank,
                  custBankName : x.mbBankName,
                  custBankAccName: x.mbBankAccName,
                  custBankAcc    : x.mbBankAcc,
                  // new details
                  checkStatus:
                  x.status === "0"
                    ? "Pending"
                    : x.status === "1"
                    ? "Approved"
                    : x.status === "3"
                    ? "Completed"
                    : x.status === "9"
                    ? "Rejected"
                    : "-",
                  // actionStatus:
                  //   x.status === "0"
                  //     ? "Check"
                  //     : x.status === "1"
                  //     ? "Reject"
                  //     : "-",
                  // x.status === "0"
                  // ? "Pending"
                  // : x.status === "1"
                  // ? "Approved"
                  // // : x.status === "3"
                  // // ? "Completed"
                  // : x.status === "9"
                  // ? "Rejected"
                  // : "-",
                };
              })
            : [];
          setOriginData(newData);

          if (Object.keys(json.data).length ==0) {
              setTableLoading(false);
          }

          const pending = newData.filter((val) => {
            return val.checkStatus === "Pending";
          });
          const approve = newData.filter((val) => {
            return val.checkStatus === "Approved";
          });
          const reject = newData.filter((val) => {
            return val.checkStatus === "Rejected";
          });

          const sortData =
            status === "Pending"
              ? pending
              : status === "Approved"
              ? approve
              : status === "Rejected"
              ? reject
              : [...newData];

          setData(sortData);

          setStatusVisible(false);
        } 
        else {
          setTableLoading(false);
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg =  "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
  };

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });

  ////#region Columns
  const wthColumns = [
    {
      title: "Data / Time",
      dataIndex: "date",
      width: "200px",
      ellipsis: true,
    },
    // {
    //   title: "trxnId",
    //   dataIndex: "trxnId",
    //   width: "200px",
    // },
    //New Column
    // {
    //   title: "custBankCode",
    //   dataIndex: "custBankCode",
    //   width: "200px",
    // },
    // {
    //   title: "custBankAccName",
    //   dataIndex: "custBankAccName",
    //   width: "200px",
    // },

    // {
    //   title: "custBankAcc",
    //   dataIndex: "custBankAcc",
    //   width: "200px",
    // },
    //New Column
    {
      title: "MB Nickname",
      dataIndex: "newNickname",
      ...getColumnSearchProps("newNickname"),
      // dataIndex: "nickname",
      // ...getColumnSearchProps("nickname"),
      width: "200px",
      ellipsis: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
      ...getColumnSearchProps("amount"),
      width: "200px",
      ellipsis: true,
    },

    {
      ellipsis: true,
      title: "Bank Name (Out)",
      dataIndex: "bankNameOut",
      width: "200px",
      ...getColumnSearchProps("bankNameOut"),
    },
    {
      ellipsis: true,
      title: "Bank Account (Out)",
      dataIndex: "bankAcctOut",
      width: "200px",
    },
    {
      fixed: "right",
      ellipsis: true,
      title: "Status",
      dataIndex: "status",
      width: "250px",
      render: (text, record) => {
        switch (record.checkStatus) {
          case "Pending":
            return (
              <>
              <Button
                style={{ backgroundColor: "#0000FF", border: "none" }}
                type="primary"
                onClick={(event) => {
                  check(record, event.target);
                }}
              >
                Check
              </Button>
              <span>
              &nbsp;&nbsp;
                <Tooltip title="Update to App Transaction?">
                  <Button
                    style={{ backgroundColor: "#000000", border: "none" }}
                    name={"UpdateToAuto"}
                    type="primary"
                    onClick={(event) => {
                      //pendingBtn(record, event.target);
                      updateTrx(record);
                    }}
                  >
                    Auto
                  </Button>
                </Tooltip>
                <BtnViewTrxn userid={record.userId} trxnDate={record.date} nickname={record.nickname}/>
              </span>
              </>
            );

          case "Rejected":
            return <>
                     <Badge status="error" text="Rejected" />
                     <BtnViewTrxn userid={record.userId} trxnDate={record.date} nickname={record.nickname}/>
                   </>

          case "Approved":
            return <>
                      <Badge text="Approved" color={"blue"} />
                      <BtnViewTrxn userid={record.userId} trxnDate={record.date} nickname={record.nickname}/>
                   </>

          case "Processing":
            return (
              <>              
                <Badge
                  status="processing"
                  text={
                    record.processBy !== username
                      ? `Processing by ${record.processBy}`
                      : "Processing"
                  }
                />
                <BtnViewTrxn userid={record.userId} trxnDate={record.date} nickname={record.nickname}/>
              </>
            );

          case "Completed":
            return <>
                 <Badge status="success" text="Completed" />
                 <BtnViewTrxn userid={record.userId} trxnDate={record.date} nickname={record.nickname}/>
                </>;
          default:
            return <>
                    <Badge status="default" text="ERROR" />
                    <BtnViewTrxn userid={record.userId} trxnDate={record.date} nickname={record.nickname}/>
                   </>
        }
      },
    },
    {
      title: "Remark",
      dataIndex: "remark",
      width: "200px",
      style: [{"word-wrap":"break-word"}],
      render: (text, record) => {
        switch (record.checkStatus) {
          case "Rejected":
              return <>{text}</>;
              
          case "Completed":
            if (text.includes("wallet2bank")){
              return "";
            }
            return <>{text}</>;

          case "Approved":
            if (record.puppetRemark!='') {
               return <>{record.puppetRemark}</>
            }else {
                if (text.includes("wallet2bank")){
                  return "";
                }
            }


          default:
            return <></>;
        }
      }
    },
    // {
    //   fixed: "right",
    //   ellipsis: true,
    //   title: "Action",
    //   dataIndex: "action",
    //   width: "150px",
    //   render: (text, record) => {
    //     switch (record.actionStatus) {
    //       case "Reject":
    //         return (
    //           <Tooltip title="Press Me!">
    //             <Button
    //               style={{
    //                 backgroundColor: "red",
    //                 border: "none",
    //               }}
    //               name="acceptBtn"
    //               id="acceptBtn"
    //               type="primary"
    //               loading={loadings[0]}
    //               onClick={(event) => {
    //                 reject(record, event.target);
    //               }}
    //             >
    //               <div id="acceptBtn">Reject</div>
    //             </Button>
    //           </Tooltip>
    //         );

    //       case "Check":
    //         return (
    //           <Button
    //             style={{ backgroundColor: "#0000FF", border: "none" }}
    //             type="primary"
    //             onClick={(event) => {
    //               check(record, event.target);
    //             }}
    //           >
    //             Check
    //           </Button>
    //         );

    //       default:
    //         return <div>-</div>;
    //     }
    //   },
    // },
  ];


  let pptColumns = [
    {
      align: "center",
      title: "Date Time",
      dataIndex:"date",
      width: "200px",
      ellipsis: true,
    },
    // {
    //   align: "center",
    //   title: "Trxn ID",
    //   dataIndex:"trxnID",
    //   width: "200px",
    //   // ellipsis: true,
    // },
    {
      align: "center",
      title: "Transaction Type",
      dataIndex: "trxnType",
      width: "200px",
      ellipsis: true,

      //filters: filterData(this)(i => i.trxnType),
      filters: [
        {
          text: 'Commision',
          value: 'Comm',
        },

        {
          text: 'Game-in',
          value: 'Game-In',
        },
        {
          text: 'Game-out',
          value: 'Game-Out',
        },
        {
          text: 'Promotion',
          value: 'Promotion',
        },
        {
          text: 'Recovery',
          value: 'Recovery',
        },
        {
          text: 'Topup',
          value: 'Topup',
        },
        {
          text: 'Withdraw',
          value: 'Withdraw',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.trxnType.toLowerCase().indexOf(value.toLowerCase()) === 0 ,
      render: (text)=>{
         if (text.toLowerCase()=="topup") {
            return "Topup";
         }else {
           return text;
         }
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Amount",
      dataIndex: "amount",
      width: "150px",
      render : (text) => {
        return <>{FormatPt(text)}</>
      },
      //...getColumnSearchProps("amount"),
    }, 
    {
      ellipsis: true,
      align: "center",
      title: "Actual Amount",
      dataIndex: "actualAmount",
      width: "150px",
      render :(text)=> {
        if (text===0) {
            return <>-</>;
        }else {
            return <>{FormatPt(text)}</>
        }
      },
      ...getColumnSearchProps("actualAmount"),
    },   
    {
      ellipsis: true,
      align: "center",
      title: "Commission",
      dataIndex: "comm",
      width: "150px",
    },
    {
      ellipsis: true,
      align: "left",
      title: "Status",
      dataIndex: "status",
      width: "200px",
      render: (text, record) => {
        switch (text) {
            case 1:
                if (record.trxnType =="Withdraw") {
                    return <Badge text="Bank Process Complete" color={"blue"} />;
                }
                return <Badge text="Approved" color={"blue"} />;       
            case 3:
                return <Badge text="Approved" color={"blue"} />;
            case 9:
              return <Badge status="error" text="Rejected" />;
            default:
               return <span>{text}</span>;

        }
      }
    },
  ];
  ////#endregion

  // const reject = (record, event) => {
  //   setRejectVisible(true);
  //   setUserRecord(record);
  // };

  //Modal Dialog //Start
  const selectCountryCode = (value) => {
    setCountryCode(value);
  };

  //Modal Dialog //End

  ////#region  Buttons 
  const BtnViewTrxn = (props) => {
    const record = {
        userid : props.userid,
        trxnDate: props.trxnDate,
        nickname: props.nickname,
    }

    return (
      <Tooltip title="View Transaction">
        <Button type="primary" shape="circle" icon={<InfoCircleFilled />} 
          onClick={()=>{
                ViewPPTDetails(record);
            }
          }
          loading={loadings[3]}
          style={{...styles.refreshBtn,...styles.hide}}
        />
     </Tooltip> 
    )
  }

  const BtnRefresh = () => {
    return (
      <Button
        style={styles.refreshBtn}
        type="primary"
        onClick={() => {
          withdrawData();
        }}
      >
      Refresh Page
      </Button>
    );
  }

  const BtnCreateWithdraw = ()=> {

      return (
          <Button
          style={styles.refreshBtn}
          type="primary"
          onClick={() => {
            setManualWithdrawVisible(true);
          }}
        >
        Create Withdrawal
        </Button>
      )
  }

  const RadioGrpStatus = () => {
      return (
          <Radio.Group
          buttonStyle={"solid"}
          value={status}
          onChange={(e) => {
            setStatus(e.target.value);
          }}
          styles={styles.hide}
        >
          <Radio.Button value="All">All</Radio.Button>
          <Radio.Button value="Pending">Pending</Radio.Button>
          <Radio.Button value="Approved">Approved</Radio.Button>
          <Radio.Button value="Rejected">Reject</Radio.Button>
        </Radio.Group>
      )
  }
  ////#endregion Buttons 

  const rejectAction = () => {
    setRejectVisible(false);

    if (reason !== "") {
      var newData = [...originData];

      csWithdrawRejectV3({
        trxnId: userRecord.trxnId,
        reason: reason,
        userId: userRecord.userId,
        username: username,
        rejectUserId: userId,
        processtype: PROCESS_TYPE.MANUAL,
        reqToken   : md5(getReqToken('withdrawManualReject')),
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "ok") {
            newData[userRecord.key].actionStatus = "-";
            newData[userRecord.key].checkStatus = "Rejected";
            setOriginData(newData);
            setRejectVisible(false);
            setStatusVisible(false);
            setReason("");
            alertData.title = "Success";
            alertData.msg = json.msg;
            sharedSuccessAlert(alertData);
            form.resetFields();
            withdrawData();
          } else {
            alertData.title = "Failed";
            alertData.msg = json.msg;
            sharedFailAlert(alertData);
            form.resetFields();
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          alertData.title = "Error";
          alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
          sharedErrorAlert(alertData);
        });
    } else {
      alertData.title = "Field Required";
      alertData.msg = "Please fill in Reject Reason !";
      sharedErrorAlert(alertData);
    }
  };

  const check = (record, target) => {
    console.log(`check withdraw manual >> ${record.trxnId}`);
    setUserRecord(record);

    setActiveKey(record.key);
    setConfirmAmount(originData[record.key].amount);
    setNickname(record.nickname);
    csWithdrawCheck({ userId: record.userId, witTrxnId: record.trxnId })
      .then((response) => response.json())
      .then((json) => {
        setUserBal(json.data.usrBal)
        //setUserBal(json.data.curBal);
        setUserTurnover(json.data.usrTurnover);
        //setUserTurnover(json.data.turnover);
        setDepositBal(json.data.depBal);
        setDepTime(json.data.depTime);
        setDepAmt(json.data.depAmt);
        setWinLose(json.data.winLose);
        setTranInAmt(json.data.tranInAmt);
        setTranOutAmt(json.data.tranOutAmt);
        setCheckBtn(json.data.check); 
        setMbBank(json.data.bankCode);
        setMBbankAcct(json.data.bankAcc);
        setMBbankAcctname(json.data.bankAccName)
        setReqTurnover(json.data.reqTurnover);
        setMaxWithdrawAmt(json.data.maxWit);

      });

      setCustBankCode(record.custBankCode);
      setCustBankName(record.custBankName);
      setCustBankAccName(record.custBankAccName);
      setCustBankAcc(record.custBankAcc);
       setStatusVisible(true);
  };

  const handleStatusClosed = (e) => {
    setStatusVisible(false);

    setCustBankCode("");
    setCustBankName("");
    setCustBankAccName("");
    setCustBankAcc("");

    form.resetFields();
  };

  const handleStatusApprove = async (index) => {
    var newData = [...originData];

    let approveData = {
      trxnId: newData[activeKey].trxnId,
      userid: userId,
      username:username,      
      bankCode: mbBank,
      bankAcc: mbBankAcct,
      bankAccName : mbBankAcctname,
      // reqToken   : md5(getReqToken('withdrawManualApprove')),
    }

    if (index > -1) {
      loadingTrue(index);
      csWithdrawManualApproveV2(approveData)
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          newData[activeKey].checkStatus = "Approved";
          newData[activeKey].actionStatus = "Rejected";
          setOriginData(newData);
          setStatusVisible(false);
          form.resetFields();
          loadingFalse(index);
          alertData.title = "Success";
          alertData.msg = json.msg;
          sharedSuccessAlert(alertData);
          withdrawData();
        } 
        else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
          loadingFalse(index);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
    }
    else{
      loadingTrue(index);
      alertData.title = "Error";
      alertData.msg = "Connection not stable, please try again !";
      sharedErrorAlert(alertData);
    }
  };

  const handleStatusReject = () => {
    setRejectVisible(true);
  };

  const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 160,
    },
  };

  const statusForm = () => {
    return (
      <Form {...layout} form={form}>
        <Form.Item name={["member", "mbusername"]} label="Member Nickname">
          <div>{nickname}</div>
        </Form.Item>
        <Form.Item name={["member", "curlDepAmt"]} label="Current Balance">
          <div>{userBal}</div>
        </Form.Item>
        <Form.Item name={["member", "amount"]} label="Withdraw Amount">
          <div>{confirmAmount}</div>
        </Form.Item>
        <Form.Item name={["member", "amount"]} label="Max Withdraw Amount">
          <div>{maxWithdrawAmt}</div>
        </Form.Item>
        <Divider />
        <Form.Item name={["member", "mbbank"]} label="Bank">
          <div>{custBankName}</div>
          <div style={styles.hide}>{mbBank}</div>
        </Form.Item>
        <Form.Item name={["member", "mbbankAcct"]} label="Bank Account">
        <div>{custBankAcc}</div>
          <div style={styles.hide}>{mbBankAcct}</div>
        </Form.Item>
        <Divider />
        <Form.Item name={["member", "turnover"]} label="Require Turnover">
          <div>{reqTurnover}</div>
        </Form.Item>
        <Form.Item name={["member", "turnover"]} label="User Turnover">
          <div>{userTurnover}</div>
        </Form.Item>
        <Form.Item name={["member", "winLose"]} label="Win / Lose">
          <div>{winLose}</div>
        </Form.Item>
        <Form.Item style={styles.statusBottomBtn}>
          <Button type="primary" htmlType="submit" onClick={handleStatusClosed}>
            Cancel
          </Button>
          {  witMgmtManApprRej(role) ?
              <>
                <Button
                  id="approvebtn"
                  name="approvebtn"
                  style={styles.statusApproveBtn}
                  type="primary"
                  htmlType="submit"
                  loading={loadings[3]}
                  onClick={() => {
                    handleStatusApprove(3);
                  }}
                >
                  Complete
                  {/* Approve */}
                </Button>
                <Button
                  danger
                  id="rejectbtn"
                  name="rejectbtn"
                  type="primary"
                  htmlType="submit"
                  onClick={(event) => {
                    handleStatusReject(event.target);
                  }}
                >
                  Reject
                </Button>
              </>:<></>
          }
        </Form.Item>
      </Form>
    );
  };

  const rejectCancel = () => {
    setRejectVisible(false);
    setReason("");
  };

  const onCheckBankCode = (value)=> {
    console.log(`value >> ${value}`);

    //const resBank =  await getUserBankList(resp.userid);

    // if (resBank.status =="ok") {
    //     let bankResp = resBank.data;
    //     if (Object.keys(resBank.data).length == 0 ) {
    //       message.error(`Please create Bank Account for user ${countryCode+phoneNum}`);
    //       clearAllFields();
    //     }else {
    //       setCSAvaBankListOptions(bankResp);
    //       let firstBank = bankResp.find(function(bd) { return bd.bankcode!='' });
    //       setBankCode(`${firstBank.bankcode}`);
    //       //console.log(`firstBank.bankcode >> ${firstBank.bankcode}`);
          
    //       setChangeBank(false);
    //       setAcctNo(`${firstBank.acctnum}`);
    //       setAcctname(`${firstBank.acctname}`)
    //       setWithdrUserId(resp.userid);
    //     }
    // }

  }

  const onSubmitForm = ()=> {

    return csCreateWithdrawal({
      userid: withdrUserId,
      bankcode: bankcode,
      acctnum: acctno,
      acctname: acctname,
      amt: withdrawAmt,
      type:"wallet2bank",
      csUserId: userId,
      reqToken : md5(getReqToken("wallet2bankManualCreate")),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          withdrawData()
          setManualWithdrawVisible(false);
          clearAllFields();
          alertData.title = "Success";
          alertData.msg = json.msg;
          sharedSuccessAlert(alertData);
        } else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        };
      });
  }

  const clearAllFields = () =>{
    setChangeBank(false);
    setWithdrawDisable(true);
    setBankCode('');
    setAcctNo('');
    setAcctname('');
    setPhoneNum('');
    setWithdrawAmt(0);
    setWithdrUserId('');
  }

  //#region functions
  const handleCancelMemberTrxn = () => {
    setPuppetVisible(false);
    setCSMemberNickname("");
    setCSMemberWalletDt("N/A");
    setCSMemberWalletBal("N/A");
    setCSMemberWalletComm("N/A");
  }; 


  const ViewPPTDetails = async (record) => {
    let trxnDate = record.trxnDate;
    //setViewTrx("trxn");
    //setLoadings(true);
    setPuppetVisible(true);  
    setTrxLoading(true);

    let endDate = moment(trxnDate).format("YYYY-MM-DD");

    let requestData = {
      userid   : record.userid,
      datefrom : moment(endDate).subtract(1,'day').format('YYYY-MM-DD'),
      dateto   : endDate
    }

    let respTrxn = await csMemberWalletTrxn(requestData);
    let resTrxn = await respTrxn.json();


    if (resTrxn.status === "ok") {
      if (resTrxn.data.detail && resTrxn.data.detail.length) {
        let withdrawData = resTrxn.data.detail.filter(item=> item.trxnType=="Withdraw");
        setFilterPptTrxn(withdrawData);
        setCSMemberNickname(record.nickname);
        let dates = resTrxn.data.detail.map(item => moment(item.date)), maxDate = moment.max(dates) , minDate = moment.min(dates);
        setTrxLoading(false);
      }

      if (resTrxn.data.summary && resTrxn.data.summary.length) {
          let walletSumm = resTrxn.data.summary[0];
          setCSMemberWalletDt(walletSumm.Dt);
          setCSMemberWalletBal(`${FormatPt(walletSumm.Balance)}`);
          setCSMemberWalletComm(`${FormatPt(walletSumm.Comm)}`);
      }else{
        setCSMemberWalletDt("N/A");
        setCSMemberWalletBal("N/A");
        setCSMemberWalletComm("N/A");
      }
    }

    setTrxLoading(false);

  }

  const verifyMobile = async () => {
    await csVerifyMobile({
      mobile: countryCode+ phoneNum
    })
    .then((response) => response.json())
    .then(async (json) => {
      if (json.status === "ok") {
        let resp = json.data;

        if (resp!==undefined) {

            const resBank =  await getUserBankList(resp.userid);

            if (resBank.status =="ok") {
                let bankResp = resBank.data;
                if (Object.keys(resBank.data).length == 0 ) {
                  // message.error(`Please create Bank Account for user ${countryCode+phoneNum}`);
                  alertData.title = "Error";
                  alertData.msg = `Please create Bank Account for user ${countryCode+phoneNum}`;
                  sharedErrorAlert(alertData);
                  clearAllFields();
                }else {

                  setCSAvaBankListOptions(bankResp);
                  let firstBank = bankResp.find(function(bd) { return bd.bankcode!='' });
                  setBankCode(`${firstBank.bankcode}`);
                  //console.log(`firstBank.bankcode >> ${firstBank.bankcode}`);
                  
                  setChangeBank(false);
                  setAcctNo(`${firstBank.acctnum}`);
                  setAcctname(`${firstBank.acctname}`)
                  setWithdrUserId(resp.userid);
                }
            }

            setWithdrawDisable(false);
        }else {
          clearAllFields();
          // message.error(`Invalid User`);
          alertData.title = "Error";
          alertData.msg = `User profile not found !`;
          sharedErrorAlert(alertData);
        }
        
      } else { 
        clearAllFields();
        alertData.title = "Error";
        alertData.msg = `Member does not exist !`;
        sharedErrorAlert(alertData);
      }
    })
    .catch((error) => {
      console.log("error: ", error);
      alertData.title = "Error";
      alertData.msg = `An unexpected error occurred. Please contact your system administrator.`;
      sharedErrorAlert(alertData);
    });
  }

  const getUserBankList = async (userid) => {
    return await userBankList({userid: userid }).then(response=>response.json());
  }
  ////#endregion

  // Update to Auto Transaction // start
  const withdrawUpdate = async()=> {

    const response = await csWithdrawUpdate({trxnid:withdrawTrxnid, processtype : PROCESS_TYPE.APP , username : username });
    let result = await response.json();

    if (result.status === "ok") {
      alertData.title = "Success";
      alertData.msg = result.msg;
      sharedSuccessAlert(alertData);
      withdrawData();
    }else {
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
    }

    setUpdateTrxVisible(false);
  }

  const updateTrx = async (record) => {
      setUpdateTrxVisible(true);
      setWithdrawTrxnId(record.trxnId);

  }
  
  const cancelUpdate = () => {
    setUpdateTrxVisible(false);
    setWithdrawTrxnId("");
  }
  
  const updateForm = () => {
    return (
      <Form {...layout}> 
        <Form.Item style={styles.statusBottomBtn}>
            <Button onClick={withdrawUpdate}>
                Yes
            </Button>
            &nbsp;
            <Button type="primary" onClick={cancelUpdate}>
                No
            </Button>
          </Form.Item>
      </Form>
    );
  };
    // Update to Auto Transaction // end

  const styles = {
    pageHearderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
    },
    refreshBtn:      { marginLeft: 10 },
    tableContainer:  { backgroundColor: "#FFFFFF", padding: 24 },
    paginationStyle: { marginTop: 50 },
    memberTrxnStyle: { marginLeft:"280px"},
    actionCompleteBtn: {
      marginRight: 20,
      marginLeft: 20,
      backgroundColor: "#4190f7",
      borderColor: "#4190f7",
    },
    actionBottomBtn: {
      flexDirection: "row",
      display: "flex",
      textAlign: "center",
    },
    statusApproveBtn: {
      marginRight: 20,
      marginLeft: 20,
      backgroundColor: "#4190f7",
      borderColor: "#4190f7",
    },
    statusBottomBtn: {
      flexDirection: "row",
      display: "flex",
      textAlign: "center",
    },
    hide : {
      display:"none",
    },
    alertText : {
        color: "Red",
    }
  };

  return (
    <div className="eachPageContent">
      <PageHeader ghost={false}>
        <div style={styles.pageHearderContainer}>
          <div>
              <BtnRefresh/>
              {  witMgmtManCrWit(role) ?
                (<><BtnCreateWithdraw/></>):<></>
              }
          </div>
          <RadioGrpStatus/>
        </div>
      </PageHeader>
      <Modal
        title={"Withdraw Check Info"}
        visible={statusVisible}
        footer={null}
        closable={false}
      >
        {statusForm()}
      </Modal>
      <Modal
        title={"Reject reason"}
        visible={rejectVisible}
        onOk={() => {
          rejectAction();
        }}
        onCancel={rejectCancel}
        maskClosable={false}
        okText={"Reject"}
        okType="primary"
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "danger", loading: loadings[2] }}
      >
        <TextArea
          value={reason}
          rows={4}
          onChange={(e) => {
            setReason(e.target.value);
          }}
        />
      </Modal>
      <Modal title="Create Withdrawal Form"
            visible={manualWithdraw}
            footer={null}
            getContainer={false}
            onCancel ={() => {
              setManualWithdrawVisible(false);
              clearAllFields();
            }}
          >
          <div>
            <div>
                <Form form={form} layout="vertical" name="withdrawform">
                  <Form.Item
                  label="Phone Number"
                  required={true}
                  >
                    <Input.Group compact>
                        <Select value={countryCode}
                          onChange={selectCountryCode}>
                          <Option value="66">+66</Option>
                          <Option value="60">+60</Option>
                        </Select>
                        <Input style={{width:"66%"}} defaultValue="" placeholder="phone number" value={phoneNum}
                          onChange={(e) => {
                            setPhoneNum(e.target.value.replace(/\D|^0/g, ""));
                            setWithdrawDisable(true);
                          }}
                          onBlur={(e)=> {
                            verifyMobile();
                        }}
                        />
  
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                      id="bankcode"
                      label="Bank"
                      required={true}
                    >
                      <Select
                            style={styles.formItem}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              return option.children.includes(input);
                            }}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            value={bankcode}
                            disabled={changeBank}
                            style={{width:"81%"}}
                            onChange={(value, event) => {
                              setBankCode(value);
                              //Search Bank Acc Name
                              for (var i = 0; i < csAvaBankList.length; i++) {
                                if (csAvaBankList[i].bankAcc === value) {
                                  setBankCode(csAvaBankList[i].bankcode);
                                  form.setFieldsValue({
                                    bankcode: csAvaBankList[i].bankcode,
                                  });
                                }
                              }
                              onCheckBankCode(value);
                            }}
                          >
                            {csAvaBankList.map((option) => {
                              return (
                                <Option key={option.bankcode} value={option.bankcode}>
                                   {option.bank} 
                                </Option>
                              );
                            })}
                          </Select>
                    </Form.Item>
                  <Form.Item
                    name="bankaccnum"
                    label="Bank Account Number"
                  >
                    <div>
                      {acctno}
                    </div>                    
                  </Form.Item>
                  <Form.Item name="bankaccname" label="Bank Acc Name">
                    {acctname}
                  </Form.Item>
                  <Form.Item
                    name="amt"
                    label="Amount"
                    required={true}> 
                      <InputNumber
                        onChange={(value) => {
                          setWithdrawAmt(value);
                        }}
                        min={1}
                      />
                  </Form.Item>
                  <Button type="primary" onClick={onSubmitForm} disabled={withdrawDisable}>
                    Submit
                  </Button>
                  <div style={{float:"right"}}>
                      <Button type="primary"  onClick={() => {
                            clearAllFields();
                            setManualWithdrawVisible(false);
                      }}>
                        Cancel
                      </Button>  
                  </div>
                  <Button
                    onClick={() => clearAllFields()
                    }
                    hidden={true}
                  >
                    Reset
                  </Button>       
                </Form>
            </div>
          </div>
      </Modal>
      <Modal
        title={"Member Withdraw Transactions of Yesterday & Today"}
        visible={puppetVisible}
        width={1400}
        style={styles.memberTrxnStyle}
        onCancel={handleCancelMemberTrxn}
        centered
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ style:{display: "none"} }}    
        cancelText="Close"  
      >
        <Row>
            <b>Member Nickname: </b>{csMemberNickname}
        </Row>
        <Row>
            <><b>Member Wallet As of :</b> {csMemberWalletDt} &nbsp;&nbsp;<b>Balance :</b> <span type="primary">{csMemberWalletBal}</span>&nbsp;&nbsp;<b>Comm:</b>{csMemberWalletComm}</>
        </Row>
        <Table
            loading={trxLoading}
            columns={pptColumns}
            dataSource= {filteredPptTrxn }
            scroll={{ x: isMobile ? "110%" : "100%", y: null }}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: settings.pageSizeOptions,
              style: styles.paginationStyle,
              alignmentBottom: 'right',
            }}
            />
      </Modal>
      <div style={styles.tableContainer}>
        <Table
          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: settings.pageSizeOptions,
            style: styles.paginationStyle,
            alignmentBottom: 'right',
          }}
          columns={wthColumns}
          // dataSource={originData}
          dataSource={data}
          loading={tableLoading}
        />
        <Modal
          title={"Update Transaction to Auto?"}
          visible={updateTrxVisible}
          footer={null}
          onCancel={cancelUpdate}
        >
          {updateForm()}
        </Modal>
      </div>
    </div>
  );
}
