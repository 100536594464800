import React, { Component } from "react";
import "../../App.less";
import { Layout, Menu, Button, Dropdown} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { NavLink, Switch, Route , Link} from "react-router-dom";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";

//Navigate
import Login from "../login/login";
import Logout from "../logout/logout";
import { externalFileNotFile } from "../error/error";
import { route, customerServiceWithdraw, logout } from "../../config/route";
import { copyright } from "../../library/texts";
import { Logo } from "../../common/components/Logo";
import { settings } from "../../config/appConfig";
import style from "../../resource/style/style";

const { Header, Sider, Content, Footer } = Layout;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: isMobile ? true : false,
      openKeys: [""],
      title: "",
    };
  }
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  state = {
    visible: false,
    openKeys: [],
  };

  styles ={
     hide: {
       display:"none"
     }
  }

  rootSubmenuKeys = ["sub1", "sub2", "sub3"];

  location = ["/", ""];

  componentDidMount() {
    var that = this;
    customerServiceWithdraw.forEach(function (cs) {
      if (cs.path === window.location.pathname) {
        that.setState({ title: cs.title });
      }
    });

    document.addEventListener("gesturestart", function (e) {
      e.preventDefault(); //disable iphone zooming
    });
  }

  toggle = () => {
    if (isMobile) {
      document.getElementById("menuBackground").style.display = "flex";
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  menuBgOnclick = () => {
    if (isMobile) {
      this.setState({
        collapsed: !this.state.collapsed,
      });
      document.getElementById("menuBackground").style.display = "none";
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  };

  subMenuClick = () => {
    var that = this;
    customerServiceWithdraw.forEach(function (cs) {
      if (cs.path === window.location.pathname) {
        that.setState({ title: cs.title });
      }
    });
  };

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  menu = () => {
    return (
      <Menu>
        {isMobile ? (
          <div>
            <Menu.Item>
              <div
                style={{
                  justifyContent: "flex-start",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                <UserOutlined style={{ color: "black", marginRight: 10 }} />
                {this.props.role}_{this.props.username}
              </div>
            </Menu.Item>
            <Menu.Item key={logout[0].path} className="logoutBorder">
              <NavLink to={logout[0].path} style={{ width: 500 }}>
                <div
                  style={{
                    justifyContent: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                >
                  <LogoutOutlined style={{ color: "black", marginRight: 10 }} />
                  <span style={{ color: "black" }}>Logout</span>
                </div>
              </NavLink>
            </Menu.Item>
          </div>
        ) : (
          <Menu.Item key={logout[0].path} className="logoutBorder">
            <NavLink to={logout[0].path}>
              <div
                style={{
                  justifyContent: "flex-start",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <LogoutOutlined style={{ color: "black", marginRight: 10 }} />
                <span style={{ color: "black" }}>Logout</span>
              </div>
            </NavLink>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  render() {
    //const success = false;
    return this.props.signIn === false ? (
      <Switch>
        <Route path={[route.Default, route.Login]} exact component={Login} />
        <Route path={route.All} component={externalFileNotFile} />
      </Switch>
    ) : (
      <Layout>
        <div
          id="menuBackground"
          className="menuBackground"
          onClick={() => this.menuBgOnclick()}
        />
        <Sider
          trigger={null}
          collapsed={this.state.collapsed}
          collapsedWidth={isMobile ? 0 : 80}
          width={"280px"}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
        >
          <div
            style={{
              height: 64,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div>
              {!this.state.collapsed ? (
                <Logo logoType="customerservice" />
              ) : null}
            </div>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[window.location.pathname]}
            openKeys={this.state.openKeys}
            onOpenChange={this.onOpenChange}
          >
            {
              customerServiceWithdraw.map(function(obj){
                return (
                  <Menu.Item
                        onClick={() => {
                          window.location.reload()
                        }}
                        key={obj.path}
                        icon={obj.icon}
                        style={style.menuFontSize}
                      >
                    <Link to={obj.path}>{obj.menutitle}</Link>
                  </Menu.Item>
                  )
                })
            }
          </Menu>
        </Sider>
        <Layout className="site-layout">
          {isMobile ? (
            <Header
              className="site-layout-background"
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                height: 68,
              }}
            >
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: this.toggle,
                }
              )}

              <div
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {this.state.title}
              </div>

              <div>
                <div
                  style={{
                    fontSize: 18,
                  }}
                >
                  <Dropdown overlay={this.menu} placement="bottomLeft">
                    <Button
                      style={{
                        border: "none",
                        width: 80,
                        height: 65,
                      }}
                      icon={<UserOutlined />}
                      size="large"
                    ></Button>
                  </Dropdown>
                </div>
              </div>
            </Header>
          ) : (
            <Header
              className="site-layout-background"
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                marginLeft: this.state.collapsed ? 64 : 280,
                height: 68,
                alignItems: "center",
              }}
            >
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: this.toggle,
                }
              )}

              <div
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {this.state.title}
              </div>

              <div>
                <div
                  style={{
                    fontSize: 18,
                  }}
                >
                  <Dropdown overlay={this.menu} placement="bottomLeft">
                    <Button
                      style={{
                        border: "none",
                        width: 200,
                        height: 65,
                      }}
                      icon={<UserOutlined />}
                      size="large"
                    >
                      {this.props.role}_{this.props.username}
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </Header>
          )}
          <Content
            className="site-layout-background"
            style={{
              marginTop: "5px ",
              marginLeft: isMobile ? "" : this.state.collapsed ? 64 : 280,
              backgroundColor: "#f0f2f5",
            }}
          >
            <div
              style={{
                minHeight: 280,
              }}
            >
              <Switch>
                <Route exact path={logout[0].path} component={Logout} />
                {customerServiceWithdraw.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                ))}
              </Switch>
            </div>
          </Content>
          {isMobile ? (
            <Footer style={{ textAlign: "center" }}>
              <div>{copyright.fullText()}</div>
              <div>V: {settings.version}</div>
              {/* <div>V: {version}</div> */}
            </Footer>
          ) : (
            <Footer style={{ textAlign: "center", marginLeft: 280 }}>
              <div>{copyright.fullText()}</div>
              <div>V: {settings.version}</div>
              {/* <div>V: {version}</div> */}
            </Footer>
          )}
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = ({ login }) => ({
  signIn: login.signIn,
  username: login.username,
  role: login.role,
});

const mapDispatchToProps = (dispatch) => {
  return { signOut: () => dispatch({ type: "sign_out" }) };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
