import React from "react";

export const CLegendRefComm = () => {
    return (<>
                <h2>Legend:</h2>
                <p>Only "Super CS" user are allowed to Approve / Reject Upline who has "undefined" name on the following terms:</p>
                <p>1. The Status of the Referral Case must be in following status: </p>
                <p>(a) Pending</p>
                <p>(b) Reject</p>
                <p>(c) KIV</p>
            </>);
}