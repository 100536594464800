import React, { useEffect, useState ,useRef} from "react";
import { useSelector } from "react-redux";
import { reportShareholderDownline, reportShareholderV2, reportShareholderDetailsV2 } from "../../../config/networkConfig";
import { Table } from "ant-table-extensions";
import {
  message,
  // Table,
  PageHeader,
  DatePicker,
  Button,
  Breadcrumb,
  Statistic,
  Row,
  Space,
  Card,
  Tabs,
  Modal,
  Form,
  Switch,
  Tag,
  Select,
} from "antd";
import { isMobile } from "react-device-detect";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { SearchOutlined, UndoOutlined, ArrowLeftOutlined, FileExcelOutlined } from "@ant-design/icons";
import {settings} from "../../../config/appConfig";
import { FormatPt, FormatData, FormatDataMobile} from "../../../library/numbers";
import moment from "moment";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";

export default function ReportShareholderV2() {
  const { Option } = Select;
  const {TabPane} = Tabs;
  const { userId, username } = useSelector((state) => state.login);
  const [tableLoading ,          setTableLoading] = useState(false);
  const [report, setReport] = useState("");
  const [user, setUser] = useState(userId);
  const [downlineUser, setDownlineUser] = useState("");
  const [shareHolder, setShareHolder] = useState("");
  const [reportDetail, setReportDetail] = useState("");
  const [downline, setDownline] = useState("");
  const [nickname, setNickname] = useState("");
  const [profitSharingTotal, setProfitSharingTotal] = useState("");

  const [type, setType] = useState("custom");
  const [trxDateFrom, setTrxDateFrom] = useState("");
  const [trxDateTo, setTrxDateTo] = useState("");
  const [todayDate, setTodayDate] = useState("");

  const [date, setDate] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [share, setShare] = useState(0);
  const reportDateFrom = useRef(null);
  const reportDateTo = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [expand, setExpand]  = useState(false);
  const [noUpline, setNoUpline]  = useState(false);
  // const [uplinePtPercent, setUplinePtPercent]  = useState("");
  const [haveDownline, setHaveDownline] = useState(false);
  const [trigger, setTrigger] = useState("");
  const [income, setIncome] = useState(0);
  const [totalProfitSharing, setTotalProfitSharing] = useState(0);
  // const [totalNetGamingRevenue, setTotalNetGamingRevenue] = useState(0);
  const [totalGrossGamingRevenue, setTotalGrossGamingRevenue] = useState(0);
  const [totalPromotionCost, setTotalPromotionCost] = useState(0);
  const [totalRoyalty, setTotalRoyalty] = useState(0);
  const [totalITCharge, setTotalITCharge] = useState(0);
  const [totalAffiliateCost, setTotalAffiliateCost] = useState(0);
  const [totalCrmAffiliate, setTotalCrmAffiliate] = useState(0);
  const [totalOperationCost, setTotalOperationCost] = useState(0);
  const [totalCustomFee, setTotalCustomFee] = useState(0);
  const [totalPtPercent, setTotalPtPercent] = useState("");


  useEffect(() => {
    //reportList();
    //reportShareDetailList();
    reportDownline();
    // searchBar2();
    // eslint-disable-next-line
  }, []);

  useEffect(()=> {
    setTrxDateFrom(trxDateFrom);
    setTrxDateTo(trxDateTo);
  },[trxDateFrom,trxDateTo]);

  useEffect(() => {
    if (trigger){
      // setNoUpline(true);
      setUser(user);
      // setSelectedNickname(selectedNickname);
      // setUplinePtPercent(uplinePtPercent);
      // searchBar();
      // reportList();
    }
  }, [trigger]);

  useEffect(()=> {
    // setDownlineUser(downlineUser);
    setUser(user);
  },[user]);
  
  // const detailsBtn = (record) => {
  //   setIsModalVisible(true);
  //   reportDetails(record);
  //   reportShareDetail();
  // }
  
  const onClickExportBtn = ()=> {

    if (report.length === 0 ) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }

    document.getElementById("export-button-1").click();
  }

  const expandButton = (record) => {

    if(expand == false){
      setExpand(true);

    }else if (expand == true){
      setExpand(false);
    }
  }

  // const searchBack = () => {
  //   setNoUpline(false);
  //   reportDownline();
  // }

  const searchDownline = async (record, selectedNickname) => {
    setDownlineUser(record);
    if (!trxDateFrom) {
      reportDateFrom.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date From !";
      sharedErrorAlert(alertData);
      return;
    }

    if (!trxDateTo) {
      reportDateTo.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date To !";
      sharedErrorAlert(alertData);
      return;
    }

    if (trxDateTo < trxDateFrom || trxDateFrom > trxDateTo) {
      alertData.title = "Error";
      alertData.msg = "Please select a valid Date Range !";
      sharedErrorAlert(alertData);
      return;
    }
    
    setTrigger(true);
    setUser(record);
    // setSelectedNickname(selectedNickname);

    // console.log("user >>>", user)
    // console.log("userId >>>", userId)
    // console.log("record2.PtPercent >>>", record)     
    // console.log("uplinePtPercent1 >>>", uplinePtPercent)

    // if(user != userId){
    //   console.log("here1")
    //   setUplinePtPercent(record)
    // }else{
    //   console.log("here2")
    // }

    // console.log("uplinePtPercent2 >>>", uplinePtPercent)
    // setUplinePtPercent(record);
    reportList(record, selectedNickname);
  }

  const reportDownline = (record) => {
    setTableLoading(true);
    var count = 0;
    reportShareholderDownline({ userId: userId, datefrom: moment(trxDateFrom).format("YYYY-MM-DD"), dateto: moment(trxDateTo).format("YYYY-MM-DD")})
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
          const dline = Object.keys(json.data).length
            ? json.data.map((data) => {
              
                return {
                  key: ++count,
                  UserID              : data.UserID,
                  PtPercent           : data.PtPercent,
                  Username            : data.Username,
                  Nickname            : data.Nickname,
                  NGR                 : (data.NGR).toFixed(4),
                  Income              : (data.Income).toFixed(4),
                };
              })
            : [];

            let summaryNGR = 0, summaryIncome = 0;
            let reportDownlineSummary = [...dline];

            reportDownlineSummary.forEach(({ NGR, Income
            }) => {
              summaryNGR              += Number(NGR);
              summaryIncome           += Number(Income);
            });

            reportDownlineSummary= [
                {
                  key: "Summary",
                  UserID              : "",
                  PtPercent           : "",
                  Username            : "",
                  Nickname            : "",
                  NGR                 : summaryNGR,
                  Income              : summaryIncome,
                },
                ...reportDownlineSummary,
              ];
           
            console.log("reportDownlineSummary >>>", reportDownlineSummary)
            setIncome(reportDownlineSummary[0].Income)

          setHaveDownline(true);
          setTableLoading(false);
          setDownline(dline);
        }
        else {
          setTableLoading(false);
          if(noUpline == true){
            alertData.title = "Failed";
            alertData.msg = json.msg;
            sharedFailAlert(alertData);
          }
          setNoUpline(true);
          
        }
      });
  };
  
  const reportDownline2 = (record) => {
    setTableLoading(true);
    var count = 0;
    reportShareholderDownline({ userId: userId, datefrom: moment(trxDateFrom).format("YYYY-MM-DD"), dateto: moment(trxDateTo).format("YYYY-MM-DD")})
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
          const dline = Object.keys(json.data).length
            ? json.data.map((data) => {
              
                return {
                  key: ++count,
                  UserID              : data.UserID,
                  PtPercent           : data.PtPercent,
                  Username            : data.Username,
                  Nickname            : data.Nickname,
                  NGR                 : (data.NGR).toFixed(4),
                  Income              : (data.Income).toFixed(4),
                };
              })
            : [];

            let summaryNGR = 0, summaryIncome = 0;
            let reportDownlineSummary = [...dline];

            reportDownlineSummary.forEach(({ NGR, Income
            }) => {
              summaryNGR              += Number(NGR);
              summaryIncome           += Number(Income);
            });

            reportDownlineSummary= [
                {
                  key: "Summary",
                  UserID              : "",
                  PtPercent           : "",
                  Username            : "",
                  Nickname            : "",
                  NGR                 : summaryNGR,
                  Income              : summaryIncome,
                },
                ...reportDownlineSummary,
              ];
           
            console.log("reportDownlineSummary >>>", reportDownlineSummary)
            setIncome(reportDownlineSummary[0].Income)

          setHaveDownline(true);
          setTableLoading(false);
          setDownline(dline);
        }
        else {
          setTableLoading(false);
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
          setTrxDateFrom(null);
          setTrxDateTo(null);
          setType("custom");
          // setNoUpline(true);
          
        }
      });
  };

  const reportList = (record, selectedNickname) => {
    console.log("setHaveDownline >>>", haveDownline)
    console.log("userId >>>", userId)
    setTableLoading(true);
    var count = 0;
    var countDetail = 0;
    reportShareholderV2({ userId: record, haveDownline: haveDownline, searchDownlineUser: userId, datefrom: moment(trxDateFrom).format("YYYY-MM-DD"), dateto: moment(trxDateTo).format("YYYY-MM-DD")})
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
          const report = Object.keys(json.data.allFullData).length
            ? json.data.allFullData.map((data) => {
              setTableLoading(false);
                return {
                  key: ++count,
                  accountDate             : data.AccountDate,
                  ptPercent               : data.PtPercent,
                  profitSharing           : data.ProfitSharing,
                  netGamingRevenue        : data.NetGamingRevenue,
                  grossGamingRevenue      : data.GrossGamingRevenue,
                  promotionCost           : data.PromotionCost,
                  royalty                 : (data.Royalty)+(data.ITCharge),    
                  iTCharge                : data.ITCharge,             
                  affiliateCost           : data.AffiliateCost,
                  crmAffiliate            : data.CrmAffiliate,       
                  operationCost           : data.OperationCost,      
                  customFee               : data.CustomFee,      
                  nickname                : data.Nickname,
                  inviteAngpaoCost        : data.InviteAngpaoCost, 
                  missionCost             : data.MissionCost, 
                  fortuneWheelCost        : data.FortuneWheelCost, 
                  permanentAngpao         : data.PermanentAngpao, 
                  playerRebate            : data.PlayerRebate, 
                  selectedNickname        : selectedNickname,
                };

              })
            : [];
            console.log("report >>>", report)

            let summaryProfitSharing = 0, summaryNetGamingRevenue = 0, summaryGrossGamingRevenue = 0;
            let summaryPromotionCost = 0, summaryRoyalty = 0, summaryITCharge = 0;
            let summaryAffiliateCost = 0, summaryCrmAffiliate = 0, summaryOperationCost = 0;
            let summaryCustomFee = 0, summaryInviteAngpaoCost = 0, summaryMissionCost = 0;
            let summaryFortuneWheelCost = 0, summaryPermanentAngpao = 0, summaryPlayerRebate = 0;
            let summaryPtPercent = 0, summaryNickname = "";
            let reportShareholderSummary = [...report];

            reportShareholderSummary.forEach(({ profitSharing, netGamingRevenue, grossGamingRevenue,
                                                promotionCost, royalty, iTCharge,
                                                affiliateCost, crmAffiliate, operationCost,
                                                customFee, inviteAngpaoCost, missionCost,
                                                fortuneWheelCost, permanentAngpao, playerRebate,
                                                ptPercent, nickname
            }) => {
              summaryProfitSharing            += Number(profitSharing);
              summaryNetGamingRevenue         += Number(netGamingRevenue);
              summaryGrossGamingRevenue       += Number(grossGamingRevenue);
              summaryPromotionCost            += Number(promotionCost);          
              summaryRoyalty                  += Number(royalty);
              summaryITCharge                 += Number(iTCharge);
              summaryAffiliateCost            += Number(affiliateCost);
              summaryCrmAffiliate             += Number(crmAffiliate);
              summaryOperationCost            += Number(operationCost);
              summaryCustomFee                += Number(customFee);
              summaryInviteAngpaoCost         += Number(inviteAngpaoCost);
              summaryMissionCost              += Number(missionCost); 
              summaryFortuneWheelCost         += Number(fortuneWheelCost);
              summaryPermanentAngpao          += Number(permanentAngpao);
              summaryPlayerRebate             += Number(playerRebate);
              summaryPtPercent                 = Number(ptPercent);
              summaryNickname                  = nickname;
            });

            reportShareholderSummary= [
                {
                  key: "Summary",
                  accountDate             : "",
                  profitSharing           : summaryProfitSharing,
                  netGamingRevenue        : summaryNetGamingRevenue,
                  grossGamingRevenue      : summaryGrossGamingRevenue,
                  promotionCost           : summaryPromotionCost,
                  royalty                 : summaryRoyalty,    
                  iTCharge                : summaryITCharge,             
                  affiliateCost           : summaryAffiliateCost,
                  crmAffiliate            : summaryCrmAffiliate,       
                  operationCost           : summaryOperationCost,      
                  customFee               : summaryCustomFee,      
                  nickname                : summaryNickname,
                  inviteAngpaoCost        : summaryInviteAngpaoCost,
                  missionCost             : summaryMissionCost,
                  fortuneWheelCost        : summaryFortuneWheelCost,
                  permanentAngpao         : summaryPermanentAngpao,
                  playerRebate            : summaryPlayerRebate,
                  ptPercent               : summaryPtPercent,
                  // selectedNickname        : summarySelectedNickname,
                },
                ...reportShareholderSummary,
              ];
            
            let todayDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
            setTodayDate(todayDate);
            console.log("reportShareholderSummary >>>", reportShareholderSummary)
            console.log("reportShareholderSummary[0].ptPercent >>>", reportShareholderSummary[0].ptPercent)
            console.log("reportShareholderSummary[0].selectedNickname >>>", reportShareholderSummary[0].nickname)

            setTotalProfitSharing(reportShareholderSummary[0].profitSharing)
            // setTotalNetGamingRevenue(reportShareholderSummary[0].netGamingRevenue)
            setTotalGrossGamingRevenue(reportShareholderSummary[0].grossGamingRevenue)
            setTotalPromotionCost(reportShareholderSummary[0].promotionCost)
            setTotalRoyalty(reportShareholderSummary[0].royalty)
            setTotalITCharge(reportShareholderSummary[0].iTCharge)
            setTotalAffiliateCost(reportShareholderSummary[0].affiliateCost)
            setTotalCrmAffiliate(reportShareholderSummary[0].crmAffiliate)
            setTotalOperationCost(reportShareholderSummary[0].operationCost)
            setTotalCustomFee(reportShareholderSummary[0].customFee)
            setTotalPtPercent(reportShareholderSummary[0].ptPercent)
            setNickname(reportShareholderSummary[0].nickname)
            
    
          
          const filterDetail = report.filter(
            (data) => data.accountDate // == report[0].accountDate
          );
          const getProfitSharing = filterDetail.map((data) => data.profitSharing);
          var profitSharingTotal = getProfitSharing.reduce(function (previous, current) {
            return previous + current;
          }, 0);

          setProfitSharingTotal(profitSharingTotal);
          setReport(report);
          setTableLoading(false);
          if (report.length != 0 ) {
            setNoUpline(true);
          }
          if (report.length == 0 ) {
            alertData.title = "Error";
            alertData.msg = "No data !";
            sharedErrorAlert(alertData);
            setTableLoading(false);
            // setNoUpline(false);
          return;
          }
        }
        else {
          // alertData.title = "Failed";
          // alertData.msg = json.msg;
          // sharedFailAlert(alertData);
          return
        }
      });
  };

  const reportList2 = (record, selectedNickname) => {

    // setType("custom");
    // setTotalProfitSharing(0)
    // setTotalGrossGamingRevenue(0)
    // setTotalPromotionCost(0)
    // setTotalRoyalty(0)
    // setTotalITCharge(0)
    // setTotalAffiliateCost(0)
    // setTotalCrmAffiliate(0)
    // setTotalOperationCost(0)
    // setTotalCustomFee(0)
    // setTrxDateFrom(null);
    // setTrxDateTo(null);
    // setProfitSharingTotal(0);
    // setTotalPtPercent(0);

    console.log("setHaveDownline >>>", haveDownline)
    console.log("userId >>>", userId)
    setTableLoading(true);
    var count = 0;
    var countDetail = 0;
    reportShareholderV2({ userId: user, haveDownline: haveDownline, searchDownlineUser: userId, datefrom: moment(trxDateFrom).format("YYYY-MM-DD"), dateto: moment(trxDateTo).format("YYYY-MM-DD")})
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
          const report = Object.keys(json.data.allFullData).length
            ? json.data.allFullData.map((data) => {
              setTableLoading(false);
                return {
                  key: ++count,
                  accountDate             : data.AccountDate,
                  ptPercent               : data.PtPercent,
                  profitSharing           : data.ProfitSharing,
                  netGamingRevenue        : data.NetGamingRevenue,
                  grossGamingRevenue      : data.GrossGamingRevenue,
                  promotionCost           : data.PromotionCost,
                  royalty                 : (data.Royalty)+(data.ITCharge),    
                  iTCharge                : data.ITCharge,             
                  affiliateCost           : data.AffiliateCost,
                  crmAffiliate            : data.CrmAffiliate,       
                  operationCost           : data.OperationCost,      
                  customFee               : data.CustomFee,      
                  nickname                : data.Nickname,
                  inviteAngpaoCost        : data.InviteAngpaoCost, 
                  missionCost             : data.MissionCost, 
                  fortuneWheelCost        : data.FortuneWheelCost, 
                  permanentAngpao         : data.PermanentAngpao, 
                  playerRebate            : data.PlayerRebate, 
                  selectedNickname        : selectedNickname,
                };

              })
            : [];
            console.log("report >>>", report)

            let summaryProfitSharing = 0, summaryNetGamingRevenue = 0, summaryGrossGamingRevenue = 0;
            let summaryPromotionCost = 0, summaryRoyalty = 0, summaryITCharge = 0;
            let summaryAffiliateCost = 0, summaryCrmAffiliate = 0, summaryOperationCost = 0;
            let summaryCustomFee = 0, summaryInviteAngpaoCost = 0, summaryMissionCost = 0;
            let summaryFortuneWheelCost = 0, summaryPermanentAngpao = 0, summaryPlayerRebate = 0;
            let summaryPtPercent = 0, summaryNickname = "";
            let reportShareholderSummary = [...report];

            reportShareholderSummary.forEach(({ profitSharing, netGamingRevenue, grossGamingRevenue,
                                                promotionCost, royalty, iTCharge,
                                                affiliateCost, crmAffiliate, operationCost,
                                                customFee, inviteAngpaoCost, missionCost,
                                                fortuneWheelCost, permanentAngpao, playerRebate,
                                                ptPercent, nickname
            }) => {
              summaryProfitSharing            += Number(profitSharing);
              summaryNetGamingRevenue         += Number(netGamingRevenue);
              summaryGrossGamingRevenue       += Number(grossGamingRevenue);
              summaryPromotionCost            += Number(promotionCost);          
              summaryRoyalty                  += Number(royalty);
              summaryITCharge                 += Number(iTCharge);
              summaryAffiliateCost            += Number(affiliateCost);
              summaryCrmAffiliate             += Number(crmAffiliate);
              summaryOperationCost            += Number(operationCost);
              summaryCustomFee                += Number(customFee);
              summaryInviteAngpaoCost         += Number(inviteAngpaoCost);
              summaryMissionCost              += Number(missionCost); 
              summaryFortuneWheelCost         += Number(fortuneWheelCost);
              summaryPermanentAngpao          += Number(permanentAngpao);
              summaryPlayerRebate             += Number(playerRebate);
              summaryPtPercent                 = Number(ptPercent);
              summaryNickname                  = nickname;
            });

            reportShareholderSummary= [
                {
                  key: "Summary",
                  accountDate             : "",
                  profitSharing           : summaryProfitSharing,
                  netGamingRevenue        : summaryNetGamingRevenue,
                  grossGamingRevenue      : summaryGrossGamingRevenue,
                  promotionCost           : summaryPromotionCost,
                  royalty                 : summaryRoyalty,    
                  iTCharge                : summaryITCharge,             
                  affiliateCost           : summaryAffiliateCost,
                  crmAffiliate            : summaryCrmAffiliate,       
                  operationCost           : summaryOperationCost,      
                  customFee               : summaryCustomFee,      
                  nickname                : summaryNickname,
                  inviteAngpaoCost        : summaryInviteAngpaoCost,
                  missionCost             : summaryMissionCost,
                  fortuneWheelCost        : summaryFortuneWheelCost,
                  permanentAngpao         : summaryPermanentAngpao,
                  playerRebate            : summaryPlayerRebate,
                  ptPercent               : summaryPtPercent,
                  // selectedNickname        : summarySelectedNickname,
                },
                ...reportShareholderSummary,
              ];
            
            let todayDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
            setTodayDate(todayDate);
            console.log("reportShareholderSummary >>>", reportShareholderSummary)
            console.log("reportShareholderSummary[0].ptPercent >>>", reportShareholderSummary[0].ptPercent)
            console.log("reportShareholderSummary[0].selectedNickname >>>", reportShareholderSummary[0].nickname)

            setTotalProfitSharing(reportShareholderSummary[0].profitSharing)
            // setTotalNetGamingRevenue(reportShareholderSummary[0].netGamingRevenue)
            setTotalGrossGamingRevenue(reportShareholderSummary[0].grossGamingRevenue)
            setTotalPromotionCost(reportShareholderSummary[0].promotionCost)
            setTotalRoyalty(reportShareholderSummary[0].royalty)
            setTotalITCharge(reportShareholderSummary[0].iTCharge)
            setTotalAffiliateCost(reportShareholderSummary[0].affiliateCost)
            setTotalCrmAffiliate(reportShareholderSummary[0].crmAffiliate)
            setTotalOperationCost(reportShareholderSummary[0].operationCost)
            setTotalCustomFee(reportShareholderSummary[0].customFee)
            setTotalPtPercent(reportShareholderSummary[0].ptPercent)
            setNickname(reportShareholderSummary[0].nickname)
            
    
          
          const filterDetail = report.filter(
            (data) => data.accountDate // == report[0].accountDate
          );
          const getProfitSharing = filterDetail.map((data) => data.profitSharing);
          var profitSharingTotal = getProfitSharing.reduce(function (previous, current) {
            return previous + current;
          }, 0);

          setProfitSharingTotal(profitSharingTotal);
          setReport(report);
          setTableLoading(false);
          if (report.length != 0 ) {
            setNoUpline(true);
          }
          if (report.length == 0 ) {
            alertData.title = "Error";
            alertData.msg = "No data !";
            sharedErrorAlert(alertData);
            setTableLoading(false);
            // setNoUpline(false);
          return;
          }
        }
        else {
          // alertData.title = "Failed";
          // alertData.msg = json.msg;
          // sharedFailAlert(alertData);
          return
        }
      });
  };

  // const reportDetails = (record) => {
  //   var count = 0;
  //   var countDetail = 0;
  //   reportShareholderDetailsV2({ userId: userId, date: record})
  //     .then((response) => response.json())
  //     .then((json) => {
  //       console.log("json -----", json);
  //       if (json.status === "ok") {
  //         const report = Object.keys(json.data).length
  //           ? json.data.map((data) => {
  //               return {
  //                 key: ++count,
  //                 accountDate             : data.AccountDate,
  //                 grossRevenue            : data.GrossRevenue,
  //                 royaltyFee              : data.RoyaltyFee,
  //                 inviteAngpaoCost        : data.InviteAngpaoCost,
  //                 missionCost             : data.MissionCost,
  //                 fortuneWheelCost        : data.FortuneWheelCost,    
  //                 permanentAngpao         : data.PermanentAngpao,             
  //                 playerRebate            : data.PlayerRebate,
  //                 topupDiscount           : data.TopupDiscount,       
  //                 playerAffiliate         : data.PlayerAffiliate,      
  //                 marketingAffiliate      : data.MarketingAffiliate,      
  //                 crm                     : data.Crm,
  //                 iTCharge                : data.ITCharge,
  //                 operationCost           : data.OperationCost,
  //                 marketingAffiliateComm  : data.MarketingAffiliateComm,
  //                 customFee               : data.CustomFee,
  //               };
  //             })
  //           : [];

  //           // let summaryGrossRevenue = 0, summaryRoyaltyFee = 0, summaryInviteAngpaoCost = 0;
  //           // let summaryMissionCost = 0, summaryFortuneWheelCost = 0, summaryPermanentAngpao = 0;
  //           // let summaryPlayerRebate = 0, summaryTopupDiscount = 0, summaryPlayerAffiliate = 0;
  //           // let summaryMarketingAffiliate = 0, summaryCrm = 0, summaryITCharge = 0;
  //           // let summaryOperationCost = 0, summaryMarketingAffiliateComm = 0, summaryCustomFee = 0;
  //           // let reportShareholderSummary = [...report];

  //           // reportShareholderSummary.forEach(({ GrossRevenue, RoyaltyFee, InviteAngpaoCost,
  //           //                                     MissionCost, FortuneWheelCost, PermanentAngpao,
  //           //                                     PlayerRebate, TopupDiscount, PlayerAffiliate,
  //           //                                     MarketingAffiliate, Crm, ITCharge,
  //           //                                     OperationCost, MarketingAffiliateComm, CustomFee
  //           // }) => {
  //           //   summaryGrossRevenue             += Number(GrossRevenue);
  //           //   summaryRoyaltyFee               += Number(RoyaltyFee);
  //           //   summaryInviteAngpaoCost         += Number(InviteAngpaoCost);
  //           //   summaryMissionCost              += Number(MissionCost);          
  //           //   summaryFortuneWheelCost         += Number(FortuneWheelCost);
  //           //   summaryPermanentAngpao          += Number(PermanentAngpao);
  //           //   summaryPlayerRebate             += Number(PlayerRebate);
  //           //   summaryTopupDiscount            += Number(TopupDiscount);
  //           //   summaryPlayerAffiliate          += Number(PlayerAffiliate);
  //           //   summaryMarketingAffiliate       += Number(MarketingAffiliate);
  //           //   summaryCrm                      += Number(Crm);
  //           //   summaryITCharge                 += Number(ITCharge);
  //           //   summaryOperationCost            += Number(OperationCost);
  //           //   summaryMarketingAffiliateComm   += Number(MarketingAffiliateComm);
  //           //   summaryCustomFee                += Number(CustomFee);
  //           // });

  //           // reportShareholderSummary= [
  //           //     {
  //           //       key: "Summary",
  //           //       accountDate             : "",
  //           //       grossRevenue            : summaryGrossRevenue,
  //           //       royaltyFee              : summaryRoyaltyFee,
  //           //       inviteAngpaoCost        : summaryInviteAngpaoCost,
  //           //       missionCost             : summaryMissionCost,
  //           //       fortuneWheelCost        : summaryFortuneWheelCost,    
  //           //       permanentAngpao         : summaryPermanentAngpao,             
  //           //       playerRebate            : summaryPlayerRebate,
  //           //       topupDiscount           : summaryTopupDiscount,       
  //           //       playerAffiliate         : summaryPlayerAffiliate,      
  //           //       marketingAffiliate      : summaryMarketingAffiliate,      
  //           //       crm                     : summaryCrm,
  //           //       iTCharge                : summaryITCharge,
  //           //       operationCost           : summaryOperationCost,
  //           //       marketingAffiliateComm  : summaryMarketingAffiliateComm,
  //           //       customFee               : summaryCustomFee,
  //           //     },
  //           //     ...reportShareholderSummary,
  //           //   ];
           
  //         setReportDetail(report);
  //       }
  //       else {
  //         alertData.title = "Failed";
  //         alertData.msg = json.msg;
  //         sharedFailAlert(alertData);
  //       }
  //     });
  // };

  const reportShareDetail = () => {
    return (
          <Table
                  columns={columns2}
                  dataSource={reportDetail}
                  scroll={{ x: 1000, y: 1000 }}
                  pagination={{
                    defaultPageSize: 10,
                    pageSizeOptions: settings.pageSizeOptions,
                    style: styles.paginationStyle,
                    alignmentBottom: 'right'
                  }}
                />
        // </Form>
    )
  }

  const downlineColumns = [
    {
      align: "center",
      title: "My Line",
      dataIndex: "UserID",
      width:150,
      // ellipsis: true,
      render: (record, record2)=>{
        return  ( record2.UserID == userId ?
                  <a><u href color="blue" 
                    onClick={() => {
                      if (!trxDateFrom) {
                        reportDateFrom.current.focus();
                        alertData.title = "Field Required";
                        alertData.msg = "Please select Date From !";
                        sharedErrorAlert(alertData);
                        return;
                      }
                  
                      if (!trxDateTo) {
                        reportDateTo.current.focus();
                        alertData.title = "Field Required";
                        alertData.msg = "Please select Date To !";
                        sharedErrorAlert(alertData);
                        return;
                      }
                  
                      if (trxDateTo < trxDateFrom || trxDateFrom > trxDateTo) {
                        alertData.title = "Error";
                        alertData.msg = "Please select a valid Date Range !";
                        sharedErrorAlert(alertData);
                        return;
                      }

                                       
                      
                      // setUplinePtPercent(record2.PtPercent)
                      // noUpline != true ? setUplinePtPercent(record2.PtPercent) : setUplinePtPercent(uplinePtPercent);
                      setTrigger(true);
                      searchDownline(record, record2.Nickname);

                      // setUplinePtPercent(record2.PtPercent)
                      // console.log("userId >>>", userId)
                      // console.log("record >>>", record)
                      // console.log("uplinePtPercent >>>", uplinePtPercent)
                      // if (record != userId) {
                      //   console.log("here1")
                      //   searchDownline(record)
                      // }
                      // else{
                      //   console.log("here2")
                      //   searchDownline(record)
                      // }
                      // console.log("record2.PtPercent >>>", record2.PtPercent)
                      
                      
                    }}
                  >
                  {record2.Username} ({record2.Nickname})</u></a>
                  : <a style={{display: 'flex', justifyContent: 'flex-end'}}>|------------</a>
                )
      }
    },
    {
      align: "center",
      title: "Downline",
      dataIndex: "UserID",
      width:150,
      // ellipsis: true,
      render: (record, record2)=>{
        return  ( record2.UserID != userId ?
                  <a style={{display: 'flex', justifyContent: 'flex-start'}}><u href color="blue" 
                    onClick={() => {
                      if (!trxDateFrom) {
                        reportDateFrom.current.focus();
                        alertData.title = "Field Required";
                        alertData.msg = "Please select Date From !";
                        sharedErrorAlert(alertData);
                        return;
                      }
                  
                      if (!trxDateTo) {
                        reportDateTo.current.focus();
                        alertData.title = "Field Required";
                        alertData.msg = "Please select Date To !";
                        sharedErrorAlert(alertData);
                        return;
                      }
                  
                      if (trxDateTo < trxDateFrom || trxDateFrom > trxDateTo) {
                        alertData.title = "Error";
                        alertData.msg = "Please select a valid Date Range !";
                        sharedErrorAlert(alertData);
                        return;
                      }

                                       
                      
                      // setUplinePtPercent(record2.PtPercent)
                      // noUpline != true ? setUplinePtPercent(record2.PtPercent) : setUplinePtPercent(uplinePtPercent);
                      setTrigger(true);
                      searchDownline(record, record2.Nickname);

                      // setUplinePtPercent(record2.PtPercent)
                      // console.log("userId >>>", userId)
                      // console.log("record >>>", record)
                      // console.log("uplinePtPercent >>>", uplinePtPercent)
                      // if (record != userId) {
                      //   console.log("here1")
                      //   searchDownline(record)
                      // }
                      // else{
                      //   console.log("here2")
                      //   searchDownline(record)
                      // }
                      // console.log("record2.PtPercent >>>", record2.PtPercent)
                      
                      
                    }}
                  >
                  {record2.Username} ({record2.Nickname})</u></a>
                  : ""
                )
      }
    },
    {
      align: "center",
      title: "NGR",
      dataIndex: "NGR",
      width:130,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Position Taking (%)",
      dataIndex: "PtPercent",
      width:130,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Income",
      dataIndex: "Income",
      width:130,
      ellipsis: true,
    },
  ];

  const columns = [
    // {
    //   align: "center",
    //   title: "Details",
    //   dataIndex: "nickname",
    //   width:80,
    //   ellipsis: true,
    //   fixed : "left",
    //   render:function(record, record2) {
    //     setShareHolder(record)
    //     return  record.toUpperCase() == username.toUpperCase()?
    //               <Button
    //                 // name={"Check"}
    //                 type="primary"
    //                 icon={<SearchOutlined />}
    //                 onClick={(event) => {
    //                   detailsBtn(record2.accountDate);
    //                   //pendingBtn(record, event.target);
    //                 }}
    //               >                  
    //               </Button>
    //             : <p></p>
    //   }
    // },
    {
      align: "center",
      title: "Date",
      dataIndex: "accountDate",
      width:130,
      ellipsis: true,
      fixed : "left",
    },
    {
      align: "center",
      title: "Shareholder",
      dataIndex: "nickname",
      width:130,
      ellipsis: true,
      // fixed : "left",
    },
    {
      align: "center",
      title: "Profit Sharing",
      dataIndex: "profitSharing",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render:function(record) {
        return FormatData(record) >= 0?
              <p style={{color:"#339966", marginTop: "15px"}}><b>{FormatData(record)}</b></p>
              : <p style={{color:"red", marginTop: "15px"}}><b>{FormatData(record)}</b></p>
      }
    },
    {
      align: "center",
      title: "Gross Game Revenue (GGR)",
      dataIndex: "grossGamingRevenue",
      width: 140,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#3333ff", marginTop: "15px"}}><b>{FormatData(record)}</b></p>;
      }
    },
    {
      align: "center",
      title: "Royalities",
      dataIndex: "royalty",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#3333ff", marginTop: "15px"}}><b>{FormatData(record)}</b></p>;
      }
    },
    {
      align: "center",
      title: "Promotion",
      dataIndex: "promotionCost",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render:function(record, record2) {
        return <p style={{color:"#3333ff", marginTop: "15px"}}><b>{FormatData(record)}</b></p>
      }
    },
    {
      align: "center",
      title: "Invite Angpao Cost",
      dataIndex: "inviteAngpaoCost",
      // width: 130,
      width: expand == true ? 160 : 0,
      // enableHiding: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#9999ff"}}>{FormatData(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Mission Cost",
      dataIndex: "missionCost",
      // width: 130,
      width: expand == true ? 130 : 0,
      // enableHiding: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#9999ff"}}>{FormatData(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Fortune Wheel Cost",
      dataIndex: "fortuneWheelCost",
      // width: 130,
      width: expand == true ? 170 : 0,
      // enableHiding: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#9999ff"}}>{FormatData(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Permanent Angpao",
      dataIndex: "permanentAngpao",
      // width: 130,
      width: expand == true ? 160 : 0,
      // enableHiding: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#9999ff"}}>{FormatData(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Player Rebate",
      dataIndex: "playerRebate",
      // width: 130,
      width: expand == true ? 130 : 0,
      // enableHiding: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#9999ff"}}>{FormatData(record)}</p>
      },
      ellipsis: true,
    },
    // {
    //   align: "center",
    //   title: "OGCT Charges",
    //   dataIndex: "iTCharge",
    //   width: 130,
    //   // ellipsis: true,
    //   style: [{ "word-wrap": "break-word" }],
    //   render:function(record, record2) {
    //     return <p style={{color:"#3333ff", marginTop: "15px"}}><b>{FormatData(record)}</b></p>
    //   }
    // },
    // {
    //   align: "center",
    //   title: "Net Game Revenue (NGR)",
    //   dataIndex: "netGamingRevenue",
    //   width: 130,
    //   // ellipsis: true,
    //   style: [{ "word-wrap": "break-word" }],
    //   render:function(record) {
    //     return <p style={{color:"#ff00ff", marginTop: "15px"}}><b>{FormatData(record)}</b>
                  
    //            </p>
    //   }
    // },
    {
      align: "center",
      title: "Affiliate Cost",
      dataIndex: "affiliateCost",
      width: 150,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render:function(record,record2) {
        return  <p style={{color:"#996633", marginTop: "15px"}}><b>{FormatData(record)}</b></p>;
      }
    },
    {
      align: "center",
      title: "CRM Cost",
      dataIndex: "crmAffiliate",
      width: 150,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render:function(record,record2) {
        return  <p style={{color:"#996633", marginTop: "15px"}}><b>{FormatData(record)}</b></p>;
      }
    },
    {
      align: "center",
      title: "Operation Cost",
      dataIndex: "operationCost",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#996633", marginTop: "15px"}}><b>{FormatData(record)}</b></p>;
      }
    },
    {
      align: "center",
      title: "Custom Fees",
      dataIndex: "customFee",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#996633", marginTop: "15px"}}><b>{FormatData(record)}</b></p>;
      }
    },
  ];

  const columns2 = [
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "accountDate",
      width:130,
      ellipsis: true,
      fixed : "left",
    },
    // {
    //   align: "center",
    //   title: "Gross Game Revenue (GGR)",
    //   dataIndex: "grossRevenue",
    //   width: 130,
    //   // ellipsis: true,
    //   style: [{ "word-wrap": "break-word" }],
    //   render: function(record) {
    //     return <p style={{color:"#3333ff", marginTop: "15px"}}><b>{FormatData(record)}</b></p>;
    //   }
    // },
    // {
    //   align: "center",
    //   title: "Royalities",
    //   dataIndex: "royaltyFee",
    //   width: 130,
    //   // ellipsis: true,
    //   style: [{ "word-wrap": "break-word" }],
    //   render: function(record) {
    //     return <p style={{color:"#3333ff", marginTop: "15px"}}><b>{FormatData(record)}</b></p>;
    //   }
    // },
    {
      align: "center",
      title: "Invite Angpao Cost",
      dataIndex: "inviteAngpaoCost",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#9999ff", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
    {
      align: "center",
      title: "Mission Cost",
      dataIndex: "missionCost",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#9999ff", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
    {
      align: "center",
      title: "Fortune Wheel Cost",
      dataIndex: "fortuneWheelCost",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#9999ff", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
    {
      align: "center",
      title: "Permanent Angpao",
      dataIndex: "permanentAngpao",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#9999ff", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
    {
      align: "center",
      title: "Player Rebate",
      dataIndex: "playerRebate",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#9999ff", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
    // {
    //   align: "center",
    //   title: "Topup Discount",
    //   dataIndex: "topupDiscount",
    //   width: 130,
    //   // ellipsis: true,
    //   style: [{ "word-wrap": "break-word" }],
    //   render: function(record) {
    //     return <p style={{color:"#9999ff", marginTop: "15px"}}>{FormatData(record)}</p>;
    //   }
    // },
    // {
    //   align: "center",
    //   title: "Player Affiliate",
    //   dataIndex: "playerAffiliate",
    //   width: 130,
    //   // ellipsis: true,
    //   style: [{ "word-wrap": "break-word" }],
    //   render: function(record) {
    //     return <p style={{color:"#cc9966", marginTop: "15px"}}>{FormatData(record)}</p>;
    //   }
    // },
    // {
    //   align: "center",
    //   title: "Marketing Affiliate",
    //   dataIndex: "marketingAffiliate",
    //   width: 130,
    //   // ellipsis: true,
    //   style: [{ "word-wrap": "break-word" }],
    //   render: function(record) {
    //     return <p style={{color:"#cc9966", marginTop: "15px"}}>{FormatData(record)}</p>;
    //   }
    // },
    // {
    //   align: "center",
    //   title: "CRM Affiliate",
    //   dataIndex: "crm",
    //   width: 130,
    //   // ellipsis: true,
    //   style: [{ "word-wrap": "break-word" }],
    //   render: function(record) {
    //     return <p style={{color:"#cc9966", marginTop: "15px"}}>{FormatData(record)}</p>;
    //   }
    // },
  ];

  const columns3 = [
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "accountDate",
      width:130,
      ellipsis: true,
      fixed : "left",
    },
    {
      align: "center",
      title: "Player Affiliate",
      dataIndex: "playerAffiliate",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#cc9966", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
    {
      align: "center",
      title: "Marketing Affiliate",
      dataIndex: "marketingAffiliate",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#cc9966", marginTop: "15px"}}>{FormatData(record)}</p>;
      }
    },
    {
      align: "center",
      title: "CRM Affiliate",
      dataIndex: "crm",
      width: 130,
      // ellipsis: true,
      style: [{ "word-wrap": "break-word" }],
      render: function(record) {
        return <p style={{color:"#996633", marginTop: "15px"}}><b>{FormatData(record)}</b></p>;
      }
    },
  ];

  const mobileColumns = [
    {
      align: "center",
      title: "Data / Time",
      dataIndex: "dateTime",
      width: "50%",
      ellipsis: true,
    },
    {
      title: "Game Type",
      dataIndex: "category",
      width: "50%",
      ellipsis: true,
    },
  ];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    if (report.length == 0 ) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = `Operations Report (${date.format('YYYY-MM-DD')} to ${dateTo.format('YYYY-MM-DD')})\n\n\n`;
    const titleCompany =`Company`;
    const titleDetail = `My Summary`;

    console.log(`OPereation Balance >> ${JSON.stringify(columns)}`);

    const headers = [
      [
        columns[0].title,
        columns[1].title,
        columns[2].title,
        columns[3].title,
        columns[4].title,
        columns[5].title,
        columns[6].title,
        columns[7].title,
        columns[8].title,
        columns[9].title,
        columns[10].title,
        columns[11].title,
        // columns[12].title,
        // columns[13].title,
        // columns[14].title,
        // columns[15].title,
        // columns[16].title,
        // columns[17].title,
        // columns[18].title,
      ],
    ];   

    var filteredReport = [...report];
    const data = filteredReport.map((data) => {
        return [
          data[columns[0].dataIndex],    
          FormatPt(data[columns[1].dataIndex]),
          FormatPt(data[columns[2].dataIndex]),
          FormatPt(data[columns[3].dataIndex]),
          FormatPt(data[columns[4].dataIndex]),
          FormatPt(data[columns[5].dataIndex]),
          FormatPt(data[columns[6].dataIndex]),
          FormatPt(data[columns[7].dataIndex]),
          FormatPt(data[columns[8].dataIndex]),
          FormatPt(data[columns[9].dataIndex]),
          FormatPt(data[columns[10].dataIndex]),
          // FormatPt(data[columns[11].dataIndex]),
          // FormatPt(data[columns[12].dataIndex]),
          // FormatPt(data[columns[13].dataIndex]),
          // FormatPt(data[columns[14].dataIndex]),
          // FormatPt(data[columns[15].dataIndex]),
          // FormatPt(data[columns[16].dataIndex]),
          // FormatPt(data[columns[17].dataIndex]),
          // FormatPt(data[columns[18].dataIndex]),
        ];
    });   

    let content = {
      startY: 80,
      head: headers,
      body: data,
      headStyles: { fillColor: "#1DA57A" },
    };


    doc.text(title, marginLeft, 20);
    doc.autoTable(content);    
    doc.save("Shareholder Report.pdf");
  };

  const datePicker = (date, dateString) => {
    setDate(date);
  };

  const searchBar = () => {
   
    // if (!date) {
    //   reportDateFrom.current.focus();
    //   alertData.title = "Field Required";
    //   alertData.msg = "Please select a Month!";
    //   sharedErrorAlert(alertData);
    //   return;
    // }

    if (!trxDateFrom) {
      reportDateFrom.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date From !";
      sharedErrorAlert(alertData);
      return;
    }

    if (!trxDateTo) {
      reportDateTo.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date To !";
      sharedErrorAlert(alertData);
      return;
    }

    if (trxDateTo < trxDateFrom || trxDateFrom > trxDateTo) {
      alertData.title = "Error";
      alertData.msg = "Please select a valid Date Range !";
      sharedErrorAlert(alertData);
      return;
    }

    reportList2();
  };
  
  const searchBar2 = () => {

    if (!trxDateFrom) {
      reportDateFrom.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date From !";
      sharedErrorAlert(alertData);
      return;
    }

    if (!trxDateTo) {
      reportDateTo.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date To !";
      sharedErrorAlert(alertData);
      return;
    }

    if (trxDateTo < trxDateFrom || trxDateFrom > trxDateTo) {
      alertData.title = "Error";
      alertData.msg = "Please select a valid Date Range !";
      sharedErrorAlert(alertData);
      return;
    }

    reportDownline2();
  };

  const resetBtn = () => {
    setType("custom");
    setTotalProfitSharing(0)
    setTotalGrossGamingRevenue(0)
    setTotalPromotionCost(0)
    setTotalRoyalty(0)
    setTotalITCharge(0)
    setTotalAffiliateCost(0)
    setTotalCrmAffiliate(0)
    setTotalOperationCost(0)
    setTotalCustomFee(0)
    setTrxDateFrom(null);
    setTrxDateTo(null);
    setReport([]);
    setProfitSharingTotal(0);
    setTotalPtPercent(0);
  };

  const trxDatePicker = (date, dateString) => {
    setType("custom");
    setTrxDateFrom(date);
  };

  const trxDatePicker2 = (date, dateString) => {
    setType("custom");
    setTrxDateTo(date);
  };
  
  const PickerWithType = ({ type, onChange }) => {
    if (type === 'custom'){
        setTrxDateFrom(trxDateFrom);
        setTrxDateTo(trxDateTo);
    }
    if (type === 'today'){
      let today = new Date().toLocaleDateString("en-CA", {day: "numeric"});
      setTrxDateFrom(moment().set('date', today))
      setTrxDateTo(moment().set('date', today))
    } 
    if (type === 'yesterday'){
      let yesterday = new Date(new Date().getTime());
      yesterday.setDate(yesterday.getDate()-1);
      let yestDay = yesterday.toLocaleDateString("en-CA", {day: "numeric"});
      let yestMonth = yesterday.toLocaleDateString("en-CA", {month: "numeric"});
      let yestYear = yesterday.toLocaleDateString("en-CA", {year: "numeric"});
      yestMonth = yestMonth - 1

      setTrxDateFrom(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
      setTrxDateTo(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
    }
    if (type === 'thisweek'){
      let today = new Date();
      let firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));
      let firstDayOfWeek = firstDay.toLocaleDateString("en-CA", {day: "numeric"});
      let firstMonthOfWeek = firstDay.toLocaleDateString("en-CA", {month: "numeric"});
      let firstYearOfWeek = firstDay.toLocaleDateString("en-CA", {year: "numeric"});
          firstMonthOfWeek = firstMonthOfWeek - 1; 
      let lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));
      let lastDayOfWeek = lastDay.toLocaleDateString("en-CA", {day: "numeric"});
      let lastMonthOfWeek = lastDay.toLocaleDateString("en-CA", {month: "numeric"});
      let lastYearOfWeek = lastDay.toLocaleDateString("en-CA", {year: "numeric"});
          lastMonthOfWeek = lastMonthOfWeek - 1;
      setTrxDateFrom(moment().set({'year': firstYearOfWeek, 'month': firstMonthOfWeek, 'date': firstDayOfWeek}));
      setTrxDateTo(moment().set({'year': lastYearOfWeek, 'month': lastMonthOfWeek, 'date': lastDayOfWeek}));
    }
    if (type === 'thismonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m, 1);
      let lastDay = new Date(y, m + 1, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})

      let year = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom(moment().set({'year': year, 'month': month, 'date': 1}))
      setTrxDateTo(moment().set({'year': year, 'month': month, 'date': lastDay}))
    }
    if (type === 'lastmonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m-1, 1);
      let lastDay = new Date(y, m, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})
      let yearOfFirstDay = firstDay.toLocaleDateString("en-CA", {year: "numeric"})

      let yearOfLastDay = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom(moment().set({'year': yearOfFirstDay, 'month': month, 'date': 1}))
      setTrxDateTo(moment().set({'year': yearOfLastDay, 'month': month, 'date': lastDay}))
    }
    return <DatePicker picker={type} onChange={onChange} />;
  };

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      //   path: "first",
      breadcrumbName: "Report",
    },
    {
      path: "operationsReport",
      breadcrumbName:"Operations Report",
    },
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 2;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHearderContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    searchBtn: {
      marginLeft: 10,
    },
    resetBtn: {
      marginLeft: 10,
    },
    datePickerStyle: {
      width: 200,
      borderRadius: 10,
    },
    mobilePageHearderContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    eachButtonContainer: {
      alignItems: "center",
      display: "flex",
      height: 50,
      marginLeft: 15,
      marginRight: 15,
      justifyContent: "center",
    },
    mobileSearchResetBtn: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      height: 50,
      alignItems: "center",
      paddingLeft: 60,
      paddingRight: 60,
    },
    btnContainer: {
      width: "100vw",
      padding: 10,
    },
    pdfStyle: {
      borderRadius: 10,
      width: 1200,
    },
    searchResetStyle: {
      borderRadius: 10,
      marginLeft: 10,
    },
    decStyle: {
      display: "flex",
      flexDirection: "row",
    },
    decLable: {
      width: 200,
    },
    decContainer: {
      paddingLeft: 75,
    },
    webRow: {
      backgroundColor: "#FFFFFF",
      paddingTop: 50,
      paddingLeft: 30,
      paddingBottom: 50,
    },
    mobileRow: {
      backgroundColor: "#FFFFFF",
      paddingLeft: 30,
    },
    cardStyle: {
      borderRadius: 10,
      height: 160,
      width: '100%',
      border: "none",
      marginTop: -30,
      // marginLeft: 0,
      // marginRight: 40,
      marginBottom: -40,
    },
    cardStyle2: {
      borderRadius: 10,
      height: 160,
      width: 200,
      border: "none",
      marginTop: -30,
      // marginLeft: 0,
      // marginRight: 40,
      marginBottom: -40,
    },
    headStyles: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 15,
      height: 20,
    },
    bodyStyle: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 60,
    },
    statistic1: {
      width: 100,
    },
    equal: {
      width: 13,
    },
    openBracket: {
      width: 10,
    },
    statistic2: {
      width: 100,
    },
    minus1: {
      width: 10,
    },
    statistic3: {
      width: 90,
    },
    statistic4: {
      width: 90,
    },
    statistic5: {
      width: 110,
    },
    statistic6: {
      width: 95,
    },
    statistic7: {
      width: 90,
    },
    statistic8: {
      width: 110,
    },
    statistic9: {
      width: 105,
    },
    multiple: {
      width: 10,
    },
    percent: {
      width: 72,
    },
    detailsStyle:{
      marginLeft:"280px",
    },
    tabelContainer: {
      backgroundColor: "#FFFFFF",
      paddingTop: -24,
    },
    modalStyle: {
      marginTop: -300
    },
    datePickerSelectionStyle: {
      marginLeft: 0,
      width: 120,
    },
    inline: {
      display: "inline",
    },
    hide: {
      marginLeft: 10000,
    },
  };

  return (
    <div>
      <PageHeader
                // style={{ display: "inline" }}
                ghost={false}
                title="Shareholder Report Summary"
                ></PageHeader>
        <div className="eachPageContent">
          {
          // isMobile ? (
          //   <div>
          //     <PageHeader ghost={false}>
          //       <div>
          //         <Breadcrumb
          //           separator=">"
          //           itemRender={itemRender}
          //           routes={routes}
          //         />
          //         <div style={styles.btnContainer}>
          //           <div style={styles.eachButtonContainer}>
          //             <Button
          //               type={"primary"}
          //               onClick={exportPDF}
          //               style={styles.pdfStyle}
          //             >
          //               Download PDF
          //             </Button>
          //           </div>

          //           <div style={styles.eachButtonContainer}>
          //             <DatePicker
          //               style={styles.datePickerStyle}
          //               onChange={datePicker}
          //               value={date}
          //               picker="month"
          //               ref={reportDateFrom}
          //               placeholder="Select Date From"
          //             />
                      
          //             {/* <DatePicker
          //               key="1"
          //               style={{...styles.datePickerStyle, ...styles.refreshBtn}}
          //               format="YYYY-MM-DD"
          //               onChange={trxDatePicker}
          //               value={trxDateFrom}
          //               ref={reportDateFrom}
          //               refs
          //               placeholder={"Select Date From"}
          //               disabledDate={(current)=> {
          //                 return current > moment().subtract(1, 'day');
          //               }}
          //             />
          //             <DatePicker
          //               key="2"
          //               style={{...styles.datePickerStyle, ...styles.refreshBtn}}
          //               format="YYYY-MM-DD"
          //               onChange={trxDatePicker2}
          //               value={trxDateTo}
          //               ref={reportDateTo}
          //               placeholder={"Select Date To"}
          //               disabledDate={(current)=> {
          //                 let dateFrom = trxDateFrom;
          //                 if (dateFrom) {
          //                   return current < dateFrom || current > moment().subtract(1, 'day');
          //                 }else {
          //                   return  current > moment().subtract(1, 'day');
          //                 }
          //               }}
          //             /> */}
          //           <Button
          //               type={"primary"}
          //               onClick={searchBar}
          //               style={styles.searchResetStyle}
          //               icon={<SearchOutlined />}
          //             />
          //             <Button onClick={() => setIsModalVisible(true)}>Open modal</Button>
                    
          //             <Button
          //               onClick={resetBtn}
          //               style={styles.searchResetStyle}
          //               icon={<UndoOutlined />}
          //             />
          //           </div>
          //         </div>
          //         <Row style={styles.mobileRow}>
          //           <Card
          //             title="Total Share"
          //             style={styles.cardStyle}
          //             headStyle={styles.headStyles}
          //             bodyStyle={styles.bodyStyle}
          //           >
          //             <Statistic
          //               // precision={settings.decimalScale}
          //               value={FormatData(share)}
          //               valueStyle={{ fontSize: 40 }}
          //             />
          //           </Card>
          //         </Row>
          //         <div style={styles.tableContainer}>
          //           <Table
          //             columns={mobileColumns}
          //             dataSource={report}
          //             expandable={{
          //               expandedRowRender: (record) => (
          //                 <p style={{ margin: 0 }}>{record.description}</p>
          //               ),
          //               rowExpandable: (record) => record.name !== "Not Expandable",
          //             }}
          //             pagination={{
          //               defaultPageSize: 10,
          //               showSizeChanger: true,
          //               pageSizeOptions: ["5", "10", "20"],
          //               style: styles.paginationStyle,
          //             }}
                      
          //           />
          //         </div>
          //       </div>
          //     </PageHeader>
              
          //   </div>
          // ) : 
          (
            <div>
              {/* {noUpline == true ?
              <ArrowLeftOutlined style={{ display: "inline" }}
                // onClick={searchBack()}
              ></ArrowLeftOutlined>
              : ""} */}
              <PageHeader
                // style={{ display: "inline" }}
                ghost={false}
                title={nickname}
                
                extra={[
                  // <DatePicker
                  //   key="1"
                  //   style={styles.datePickerStyle}
                  //   onChange={datePicker}
                  //   value={date}
                  //   picker="month"
                  //   ref={reportDateFrom}
                  //   placeholder="Select a Month"
                  // />,
                  <DatePicker
                    key="1"
                    style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                    format="YYYY-MM-DD"
                    onChange={trxDatePicker}
                    value={trxDateFrom}
                    ref={reportDateFrom}
                    refs
                    placeholder={"Select Date From"}
                    disabledDate={(current)=> {
                      return current > moment();
                    }}
                  />,
                  <DatePicker
                    key="2"
                    style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                    format="YYYY-MM-DD"
                    onChange={trxDatePicker2}
                    value={trxDateTo}
                    ref={reportDateTo}
                    placeholder={"Select Date To"}
                    disabledDate={(current)=> {
                      let dateFrom = trxDateFrom;
                      if (dateFrom) {
                        return current < dateFrom || current > moment();
                      }else {
                        return  current > moment();
                      }
                    }}
                  />,
                  <Space>
                    <Select style={{...styles.datePickerSelectionStyle}} value={type} type={type} onChange={setType}>
                      <Option value="custom">Custom</Option>
                      <Option value="today">Today</Option>
                      <Option value="yesterday">Yesterday</Option>
                      <Option value="thisweek">This Week</Option>
                      <Option value="thismonth">This Month</Option>
                      <Option value="lastmonth">Last Month</Option>
                    </Select>
                  </Space>,
                  noUpline == true ?
                    <Button
                      key="2"
                      type={"primary"}
                      style={styles.searchBtn}
                      onClick={searchBar}
                    >
                      Search
                    </Button>
                  : "",
                  noUpline == false ?
                    <Button
                      key="2"
                      type={"primary"}
                      style={styles.searchBtn}
                      onClick={searchBar2}
                    >
                      Search
                    </Button>
                  : "",
                  noUpline == true ?
                    <Button key="3" style={styles.resetBtn} onClick={resetBtn}>
                      Reset
                    </Button>
                  : "",
                  // <Button key="4" type={"primary"} onClick={exportPDF}>
                  //   Download PDF
                  // </Button>,
                  noUpline == true ?
                  <Button 
                      type={"primary"} 
                      style={styles.refreshBtn} 
                      icon={<FileExcelOutlined />}
                      onClick={() => {
                          onClickExportBtn();
                      }}
                  >
                    Export to Excel
                  </Button>
                  : "",
                  noUpline == true ?
                    <span style={styles.expandStyle}>Expandable:  <Switch  checkedChildren="ON" unCheckedChildren="OFF" onClick={(event) => { expandButton() }}/></span>
                  : ""  
                ]}
              />
              <div style={styles.tableContainer}>
                {noUpline == true ?
                <Card
                  title="Profit Sharing &nbsp;&nbsp;= ( GGR &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Royalities &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Promotion &nbsp;&nbsp;&nbsp;- Affiliate Cost - CRM Cost &nbsp;&nbsp;&nbsp;&nbsp;- Operation Cost - Custome Fees ) * PtPercent %"
                  style={styles.cardStyle}
                  headStyle={styles.headStyles}
                  bodyStyle={styles.bodyStyle}
                  scroll={{ x: 100, y: null }}
                >
                  <Statistic
                    // precision={settings.decimalScale}
                    style={styles.statistic1}
                    value={totalProfitSharing.toFixed(2)}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    style={styles.equal}
                    value={"="}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    style={styles.openBracket}
                    value={"("}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    // precision={settings.decimalScale}
                    style={styles.statistic2}
                    value={(totalGrossGamingRevenue.toFixed(2))}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    style={styles.minus1}
                    value={"-"}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    // precision={settings.decimalScale}
                    style={styles.statistic3}
                    value={(totalRoyalty.toFixed(2))}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    style={styles.minus1}
                    value={"-"}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    // precision={settings.decimalScale}
                    style={styles.statistic4}
                    value={(totalPromotionCost.toFixed(2))}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    style={styles.minus1}
                    value={"-"}
                    valueStyle={{ fontSize: 15 }}
                  />
                  {/* <Statistic
                    // precision={settings.decimalScale}
                    style={styles.statistic5}
                    value={(totalITCharge.toFixed(2))}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    style={styles.minus1}
                    value={"-"}
                    valueStyle={{ fontSize: 15 }}
                  /> */}
                  <Statistic
                    // precision={settings.decimalScale}
                    style={styles.statistic6}
                    value={(totalAffiliateCost.toFixed(2))}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    style={styles.minus1}
                    value={"-"}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    // precision={settings.decimalScale}
                    style={styles.statistic7}
                    value={(totalCrmAffiliate.toFixed(2))}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    style={styles.minus1}
                    value={"-"}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    // precision={settings.decimalScale}
                    style={styles.statistic8}
                    value={(totalOperationCost.toFixed(2))}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    style={styles.minus1}
                    value={"-"}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    // precision={settings.decimalScale}
                    style={styles.statistic9}
                    value={(totalCustomFee.toFixed(2))}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    style={styles.openBracket}
                    value={")"}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    style={styles.multiple}
                    value={"*"}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    // precision={settings.decimalScale}
                    style={styles.percent}
                    value={(totalPtPercent)}
                    valueStyle={{ fontSize: 15 }}
                  />
                  <Statistic
                    // style={styles.openBracket}
                    value={"%"}
                    valueStyle={{ fontSize: 15 }}
                  />
                </Card>: ""
                }
                {noUpline == false ?
                <Card
                  title="Total Earning"
                  style={styles.cardStyle2}
                  headStyle={styles.headStyles}
                  bodyStyle={styles.bodyStyle}
                >
                  <Statistic
                    // precision={settings.decimalScale}
                    style={styles.statistic1}
                    value={income.toFixed(4)}
                    valueStyle={{ fontSize: 16 }}
                  />
                </Card>: ""
                }
                {noUpline == true ?
                  <Table
                    loading={tableLoading}
                    columns={columns}
                    dataSource={report}
                    //dataSource={filter}
                    scroll={{ x: 100, y: null }}
                    exportable={true}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: settings.pageSizeOptions,
                      style: styles.paginationStyle,
                      alignmentBottom: 'right'
                    }}
                    exportableProps={{
                      // feedFields,
                      fileName: "SharholderReport_"+todayDate,
                      btnProps: {
                        type: "primary",
                        icon: <FileExcelOutlined />,
                        id:"export-button-1",
                        children: <span>Export to Excel</span>,
                        style: {display:"none"}
                      },
                    }}
                  />: ""
                }
                {noUpline == false ?
                  <Table
                    loading={tableLoading}
                    columns={downlineColumns}
                    dataSource={downline}
                    //dataSource={filter}
                    style={{ width : "65%" }}
                    scroll={{ x: 10, y: null }}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: settings.pageSizeOptions,
                      style: styles.paginationStyle,
                      alignmentBottom: 'right'
                    }}
                  />: ""
                }
                <Modal 
                  title={"Shareholder Report Details"}
                  visible={isModalVisible}
                  style={{ top : "35%", ...styles.modalStyle }}
                  onCancel={()=>{
                    setIsModalVisible(false);
                      // onCancelApproveTrxn();
                  }}
                  width={1200}
                  footer={null}
                >
                <div style={styles.tabelContainer}>
                    <Table
                      columns={columns2}
                      dataSource= {reportDetail}
                      scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                      pagination={{
                        defaultPageSize: 10,
                        pageSizeOptions: settings.pageSizeOptions,
                        style: styles.paginationStyle,
                        alignmentBottom: 'right'
                      }}
                    />
                  </div>
                </Modal>
                <Modal 
                  title={"Affiliate Details"}
                  visible={isModalVisible2}
                  style={{ top : "35%", ...styles.modalStyle }}
                  onCancel={()=>{
                    setIsModalVisible2(false);
                  }}
                  width={1200}
                  footer={null}
                >
                <div style={styles.tabelContainer}>
                    <Table
                      columns={columns3}
                      dataSource= {reportDetail}
                      scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                      pagination={{
                        defaultPageSize: 10,
                        pageSizeOptions: settings.pageSizeOptions,
                        style: styles.paginationStyle,
                        alignmentBottom: 'right'
                      }}
                    />
                  </div>
                </Modal>
                <br></br>
                  {/* <p><b>REMARK :</b></p>
                  <p><b>(1) <span style={{color:"#ff00ff"}}>Net Game Revenue (NGR)</span> = <span style={{color:"#3333ff"}}>Gross Game Revenue(GGR)</span>&nbsp;
                            - <span style={{color:"#3333ff"}}>Royalities</span> - <span style={{color:"#3333ff"}}>Promotion Cost</span> - <span style={{color:"#3333ff"}}>OGCT Charges (eg: 2%)</span></b></p>
                  <p><b>(2) <span style={{marginTop: "15px"}}><b>Profit Sharing</b></span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            = <span style={{color:"#ff00ff"}}>Net Game Revenue (NGR)</span>&nbsp;
                            - <span style={{color:"#996633"}}>Affiliate Cost</span>&nbsp;
                            - <span style={{color:"#996633"}}>CRM Cost</span>&nbsp;
                            - <span style={{color:"#996633"}}>Operation Cost</span>&nbsp;
                            - <span style={{color:"#996633"}}>Custom Fees</span></b></p> */}
                  <div style={{...styles.inline, ...styles.hide}}>
                    <PickerWithType type={type} onChange={(value) => console.log(value)} />
                  </div>
              </div>
            </div>
          )}
          </div>     
    </div>
    
  );

}
