import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Form,
  Button,
  Modal,
  Badge,
  PageHeader,
  message,
  Select,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  userCSNew,
  userCSList,
  userManageUpdate,
  getBoUserType,
} from "../../config/networkConfig";
import md5 from "md5";
import { useSelector } from "react-redux";
import { CS_USER_TYPE, MARKETING_USER_TYPE, REPORT_USER_TYPE } from "../../common/constants/csuserType";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";
import { boUserVw } from "../../library/permission";
import { CNotAuthorized } from "../../common/components/CNotAuthorized";

// const EditableCell = ({
//   editing,
//   dataIndex,
//   title,
//   inputType,
//   record,
//   index,
//   children,
//   ...restProps
// }) => {
//   const inputNode = <Input allowClear={true} />;

//   return (
//     <td {...restProps}>
//       {editing ? (
//         <Form.Item
//           name={dataIndex}
//           style={{
//             margin: 0,
//           }}
//           rules={[
//             {
//               required: true,
//               message: `Please Input ${title}!`,
//             },
//           ]}
//         >
//           {inputNode}
//         </Form.Item>
//       ) : (
//         children
//       )}
//     </td>
//   );
// };

export default function CustomerServiceManagement() {
  const [form] = Form.useForm();
  const modalForm = useRef();
  const { role, userId, companyId } = useSelector((state) => state.login);
  const { Option } = Select;
  const [originData, setOriginData]           =     useState([]);
  const [editingKey, setEditingKey]           =     useState("");
  const [username, setUsername]               =     useState("");
  const [nickname, setNickname]               =     useState("");
  const [password, setPassowrd]               =     useState("");
  const [searchText, setSearchText]           =     useState("");
  const [addVisible, setAddVisible]           =     useState(false);
  const [tableLoadings, setTableLoadings]     =     useState(false);
  const [loadings, setLoadings]               =     useState([]);
  const [csUserType, setCSUserType]           =     useState("");
  const [boUserTypeList, setBoUserTypeList]   =     useState([]);
  
  const md5Pass = md5(password);
  // const [userTypeSelect] = useState("");
  useEffect(() => {
    downlineList();
    retrieveUserType();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log("originData>>>", originData);
  },[originData])

  const downlineList = () => {
    var count = 0;
    setTableLoadings(true);
    userCSList({ userId: role == 'CS_Super' ? companyId : userId  })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          setTableLoadings(false);
          const newData = Object.keys(json.data).length
            ? json.data.map((x) => {
                return {
                  key: count++,
                  userid: x.userId,
                  username: x.username,
                  nickname: x.nickname,
                  type:x.type,
                  userType: x.type,
                  lastLogin: x.lastLogin,
                  registerDate: x.registerDate,
                  serialNumber: `${x.shareCount}/${x.agentCount}/${x.memberCount}`,
                  status:
                    x.status === "0"
                      ? "Lock"
                      : x.status === "1"
                      ? "Active"
                      : "Inactive",
                };
              })
            : [];
          if(role === 'CS_Super'){
            let filteredNewData = newData.filter(obj => obj.userType !== 'CS_Super');
            console.log('filteredNewData >>', filteredNewData);
            setOriginData(filteredNewData);
          }else {
            console.log('newData >>', newData);
            setOriginData(newData);
          }
          setAddVisible(false);
        }
        else {
          setTableLoadings(true);
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
  };

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "setCSUserType" ? (
        <Select
          dropdownStyle={{ position: "fixed" }}
          onSelect={(value, event) => {
            setCSUserType(value, event);
          }}
        >
          {boUserTypeList.map((data) => (
            <Option key={data.key} value={data.userType}>{data.displayName}</Option>
          ))}
        </Select>
      ) : (
        <Input allowClear={true} />
      );

    //const inputNode = <Input allowClear={true} />;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const showModal = () => {
    setEditingKey("");
    setAddVisible(true);
    form.resetFields();
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },

    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      width: "14%",
      //width: "20%",
      ...getColumnSearchProps("username"),
      ellipsis: true,
    },
    {
      title: "Nickname",
      dataIndex: "nickname",
      width: "14%",
      // width: "20%",
      editable: true,
      ...getColumnSearchProps("nickname"),
    },
    {
      title: "User Type",
      dataIndex: "userType",
      width: "15%",
      editable: true,
      render :(text) => {
        return (
          text === CS_USER_TYPE.SuperCS || text.toLowerCase() === "cs super"
          ? "Super CS"
          : text === CS_USER_TYPE.Senior || text.toLowerCase() === "cs senior"
          ? "Senior CS"
          : text === CS_USER_TYPE.Junior || text.toLowerCase() === "cs junior"
          ? "Junior CS"
          : text === CS_USER_TYPE.CS || text.toLowerCase() === "cs"
          ? "Basic CS"
          : text === REPORT_USER_TYPE.Reporter || text.toLowerCase() === "reporter"
          ? "Reporter User"
          : text === REPORT_USER_TYPE.JR_Reporter || text.toLowerCase() === "junior reporter"
          ? "Junior Reporter User"
          : text === MARKETING_USER_TYPE.Marketing || text.toLowerCase() === "mktg"
          ? "Marketing User"
          : text.toLowerCase() === "uiux"
          ? "UI Team User"
          : "-"
        )
      }
    },
    {
      ellipsis: true,
      title: "Register Date",
      dataIndex: "registerDate",
      width: "20%",
      sorter: (a,b) => new Date(a.registerDate) - new Date(b.registerDate),
    },
    {
      width: "15%",
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        switch (record.status) {
          case "Lock":
            return <Badge status="warning" text="Lock" />;
          case "Active":
            return <Badge status="success" text="Active" />;
          default:
            return <Badge status="error" text="Inactive" />;
        }
      },
    },
    {
      align: "center",
      ellipsis: true,
      title: "Action",
      width: "20%",
      dataIndex: "operation",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Input
              id={"pwd" + record.key}
              placeholder="Change Password"
              style={{ width: "auto", textAlign: "center" }}
              // onChange={(e) => {
              //   setPassowrd(e.target.value);
              // }}
            />
            <Button
              loading={loadings[1]}
              type="dashed"
              shape="circle"
              icon={<SaveOutlined />}
              onClick={() => {
                save(record, 1);
              }}
            />
            <Button
              type="danger"
              shape="circle"
              icon={<CloseCircleOutlined />}
              onClick={() => {
                cancel(record.key);
              }}
            />
          </div>
        ) : (
          <span disabled={editingKey !== ""} onClick={() => edit(record)}>
            <Button type="primary" shape="circle" icon={<EditOutlined />} />
          </span>
        );
      },
    },
  ];

  const isEditing = (record) => record.key === editingKey;

  const edit = async (record) => {
    form.setFieldsValue({
      nickname: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (record, index) => {
    var pwd = document.getElementById("pwd" + record.key).value;

    const row = await form.validateFields();
    const { nickname, userType } = row;

    //console.log(`record userType ${record.type} , record password >> ${pwd} ,  selected userType >> ${userType} ,  new userType >> ${newUserType} , row >> ${JSON.stringify(row)}`);

    // if (
    //   nickname === record.nickname &&
    //   document.getElementById("pwd" + record.key).value === "" && 
    //   userType === record.type
    // ) {
    //   setEditingKey("");
    //   return;
    // }

    if (
      nickname === record.nickname && userType === record.userType &&
      document.getElementById("pwd" + record.key).value === "" && 
      userType === record.type
    ) {
      setEditingKey("");
      return;
    }

    try {
      const { nickname } = row;

      const newData = [...originData];
      const indexSave = newData.findIndex((item) => record.key === item.key);

      if (index > -1) {
        loadingTrue(index);
        // let newUserType = userType.toLowerCase()==='cs senior' || userType == CS_USER_TYPE.Senior ? CS_USER_TYPE.Senior : 
        // userType.toLowerCase()==='cs junior' || userType == CS_USER_TYPE.Junior ? CS_USER_TYPE.Junior : CS_USER_TYPE.CS ;
        // console.log('save >>', newUserType);
        userManageUpdate({
          userid: record.userid,
          nickname: nickname,
          pass: md5(pwd),
          // usertype : newUserType,
          usertype: userType,
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status === "ok") {
              loadingFalse(index);
              const item = newData[indexSave];
              // item.type = item.type.toLowerCase()=='cs_senior' ? CS_USER_TYPE.Senior.replace("_"," ") : userType.toLowerCase()=='cs_junior' ? CS_USER_TYPE.Junior.replace("_"," ") : CS_USER_TYPE.CS ;
              newData.splice(indexSave, 1, { ...item, ...row });
              setOriginData(newData);
              setEditingKey("");
              alertData.title = "Success";
              alertData.msg = json.msg;
              sharedSuccessAlert(alertData);
              setOriginData([]);
              setTableLoadings(true);
              downlineList();
            }
            else {
              loadingFalse(index);
              alertData.title = "Failed";
              alertData.msg = json.msg;
              sharedFailAlert(alertData);
            }
          })
          .catch((error) => {
            console.log("error: ", error);
            alertData.title = "Error";
            alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
            sharedErrorAlert(alertData);
          });
      } else {
           loadingTrue(index);
      //   message.error("Connection not stable, please try again !");
         }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleAdd = (index) => {
    if (!username || !password || !nickname | !csUserType) {
      alertData.title = "Field Required";
      alertData.msg = "Please fill in Username, Nickname, Password, User Type !";
      sharedErrorAlert(alertData);
      return;
    }
    loadingTrue(index);
    userCSNew({
      userid: role == 'CS_Super' ? companyId : userId,
      username: username,
      nickname: nickname,
      pass: md5Pass,
      usertype: csUserType,
    })
      .then((response) => response.json())
      .then((json) => {
        setAddVisible(false);
        if (json.status === "ok") {
          downlineList();
          alertData.title = "Success";
          alertData.msg = json.msg;
          sharedSuccessAlert(alertData);
        }
        else {
          loadingTrue(index);
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
        loadingFalse(index);
        modalForm.current.resetFields();
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "nickname"
          ? "nickname"
          : col.dataIndex === "userType"
          ? "setCSUserType"
          : col.dataIndex === "text",
        // inputType: col.dataIndex === "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  // const mergedColumns = columns.map((col) => {
  //   if (!col.editable) {
  //     return col;
  //   }

  //   return {
  //     ...col,
  //     onCell: (record) => ({
  //       record,
  //       inputType: col.dataIndex === "text",
  //       dataIndex: col.dataIndex,
  //       title: col.title,
  //       editing: isEditing(record),
  //     }),
  //   };
  // });

  const onCancel = () => {
    setAddVisible(false);
    modalForm.current.resetFields();
  };

  const retrieveUserType = async () => {
    let requestData = {
      columnRequire : [
        'displayName',
        'access',
      ],
      condition: [`WHERE status = 1`]
    }
    let response = await getBoUserType(requestData);
    let result = await response.json();
    if(result.status ==='ok'){
      if(Object.keys(result.data).length > 0){
        let count = 0;
        let boUserTypeListData = result.data.map((obj) => {
          return {
              key           :   count++,
              displayName   :   obj.displayName,
              userType      :   obj.access,
            }
        })
        if(boUserTypeListData.length > 0){
          if(role === 'CS_Super' ){
            let newBoUserTypeListData = boUserTypeListData.filter(obj => obj.displayName !== 'Super CS');
            setBoUserTypeList(newBoUserTypeListData);
          }else {
            setBoUserTypeList(boUserTypeListData)
          }
        }
      }
    } else {
        alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
          }
  }

  return (
    <div >
      {
        boUserVw(role) ?
        <div style={{ backgroundColor: "#FFFFFF" }}>
      <div>
        <Modal title="New BO User" visible={addVisible} footer={null}>
          <Form ref={modalForm}>
            <Form.Item
              name={["user", "username"]}
              label="Username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                  whitespace: true,
                },
              ]}
            >
              <Input
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              name={["user", "nickname"]}
              label="Nickname"
              rules={[
                {
                  required: true,
                  message: "Please input your nickname!",
                  whitespace: true,
                },
              ]}
            >
              <Input
                value={nickname}
                onChange={(e) => {
                  setNickname(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              name={["user", "password"]}
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                  whitespace: true,
                },
              ]}
            >
              <Input.Password
                value={password}
                onChange={(e) => {
                  setPassowrd(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              name={["user", "userType"]}
              label="User Type"
              rules={[
                {
                  required: true,
                  message: "Please select CS User Type!",
                  whitespace: true,
                },
              ]}
            >
              <Select
                dropdownStyle={{ position: "fixed" }}
                value={csUserType}
                onChange={(userType) => {
                  setCSUserType(userType);
                }}
              >
                {boUserTypeList.map((data) => (
                  <Option key={data.key} value={data.userType}>{data.displayName}</Option>
                ))}
                {/* <Option value={CS_USER_TYPE.Senior}>CS Senior</Option>
                <Option value={CS_USER_TYPE.Junior}>CS Junior</Option>
                <Option value="CS">CS</Option> */}
              </Select>
            </Form.Item>
            <Form.Item style={{ textAlign: "right" }}>
              <Button
                htmlType="button"
                loading={loadings[0]}
                onClick={onCancel}
                style={{ marginRight: 10 }}
              >
                Cancel
              </Button>
              <Button
                loading={loadings[0]}
                type="primary"
                htmlType="submit"
                onClick={() => {
                  handleAdd(0);
                }}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>

      <PageHeader ghost={false}>
        <div>
          <Button key="1" type="primary" onClick={showModal}>
            Add
          </Button>
        </div>
      </PageHeader>
      <Form form={form} component={false}>
        <Table
          loading={tableLoadings}
          scroll={{ x: "100%", y: null }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={originData}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
          style={{ overflow: "auto", marginLeft: 20 }}
        />
      </Form>
      </div>:<CNotAuthorized />
      }
    </div>
  );
}
