import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { reportDashMonitor } from "../../../config/networkConfig";
import {
  message,
  Table,
  PageHeader,
  Button,
  Breadcrumb,
} from "antd";
import { isMobile } from "react-device-detect";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import {settings} from "../../../config/appConfig";
import { FormatData} from "../../../library/numbers";
import moment from "moment";
import { dashMonDLReport } from "../../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";

export default function DashboardMonitor() {
  const { userId , role } = useSelector((state) => state.login);
  const [report, setReport] = useState("");
  const [date, setDate] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [share, setShare] = useState(0);

  useEffect(() => {
    reportList();
    //reportShareDetailList();
    // eslint-disable-next-line
  }, []);

  const reportList = () => {
    var count = 0;
    reportDashMonitor({data:null})
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
          const report = Object.keys(json.data).length
            ? json.data.map((data) => {
                return {
                  key: ++count,
                  dateTime        : data.TrxnDate,
                  totalUser       : data.TotalUser,
                  registerUser    : data.RegisterUser,
                  customerInvite  : data.CustomerInvite,
                  activeUser      : data.ActiveUser,
                  depCount        : data.DepCount,
                  uniqueDep       : data.UniqueDep,
                  depositAmt      : data.DepositAmt,
                  maxDepositAmt   : data.MaxDepositAmt,
                  wthdrawCount    : data.WthdrawCount,
                  uniqueWth       : data.UniqueWth,
                  withdrawAmt     : data.WithdrawAmt,
                  maxWthAmt       : data.MaxWthAmt,
                  commToAgent     : data.CommToAgent,
                  commToUser      : data.CommToUser,
                  commToWallet    : data.CommToWallet,
                  commToBank      : data.CommToBank,
                };
              })
            : [];

          // const filter = report.filter(
          //   (data) => data.dateTime === report[0].dateTime
          // );

          setReport(report);
          //setFilter(filter);

        } 
        else {
          alertData.title = "Error";
          alertData.msg = "Connection not stable, please try again !";
          sharedErrorAlert(alertData);
        }
      });
  };

  const description = (data) => {
    return (
      <div style={styles.decContainer}>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Turnover:</p>
          <p>{data.turnover}</p>
        </div>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Position Taking Turnover:</p>
          <p>{data.ptTurnover}</p>
        </div>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Referer Turnover:</p>
          <p>{data.refererTurnover}</p>
        </div>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Share:</p>
          <p>{data.shares}</p>
        </div>
      </div>
    );
  };

  const columns = [
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "dateTime",
      width: 150,
      ellipsis: true,
      fixed:"left",
    },
    {
      align: "center",
      title: "Total User",
      dataIndex: "totalUser",
      width: 100,
      //ellipsis: true,
      style: [{"word-wrap":"break-word"}],
    },
    {
      align: "center",
      title: "Registered User(s)",
      dataIndex: "registerUser",
      width: 100,
      headStyles:[{"font-size":"2px"}],
    
      // ellipsis: true,
    },
    {
      align: "center",
      title: "Invited User(s)",
      dataIndex: "customerInvite",
      width: 100,
      style: [{"word-wrap":"break-word"}],
    },
    {
      align: "center",
      title: "Active User",
      dataIndex: "activeUser",
      width: 100,
      style: [{"word-wrap":"break-word"}],
    },
    {
      align: "center",
      title: "Total Deposit",
      dataIndex: "depCount",
      width: 100,
      style: [{"word-wrap":"break-word"}],
    },
    {
      align: "center",
      title: "Unique Deposits #",
      dataIndex: "uniqueDep",
      width: 100,
      style: [{"word-wrap":"break-word"}],
    },
    {
      align: "center",
      title: "Deposit Amount",
      dataIndex: "depositAmt",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatData(record);
      }
    },
    {
      align: "center",
      title: "Highest Deposit Amt.",
      dataIndex: "maxDepositAmt",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatData(record);
      }
    },
    {
      align: "center",
      title: "Total Withdrawals",
      dataIndex: "wthdrawCount",
      width: 100,
      style: [{"word-wrap":"break-word"}],
    },
    {
      align: "center",
      title: "Unique Withdrawals #",
      dataIndex: "uniqueWth",
      width: 100,
      style: [{"word-wrap":"break-word"}],
    },
    {
      align: "center",
      title: "Total Withdraw Amt.",
      dataIndex: "withdrawAmt",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatData(record);
      }
    },
    {
      align: "center",
      title: "Highest Withdraw Amt.",
      dataIndex: "maxWthAmt",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatData(record);
      }
    },
    {
      align: "center",
      title: "Comm. Agent",
      dataIndex: "commToAgent",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatData(record);
      }
    },
    {
      align: "center",
      title: "Comm. User",
      dataIndex: "commToUser",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatData(record);
      }
    },
    {
      align: "center",
      title: "Comm. Wallet",
      dataIndex: "commToWallet",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatData(record);
      }
    },
    {
      align: "center",
      title: "Comm. Bank",
      dataIndex: "commToBank",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatData(record);
      }
    },
  ];

  const mobileColumns = [
    {
      align: "center",
      title: "Data / Time",
      dataIndex: "dateTime",
      width: "50%",
      ellipsis: true,
    },
    {
      title: "Comm. Opening",
      dataIndex: "commOpening",
      width: "50%",
      ellipsis: true,
    },
  ];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    if (report.length === 0 ) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);
    let curDate = new Date();

    const title = `Dashboard Monitor Report (${moment(curDate).format('YYYY-MM-DD')})\n\n\n`;

    const headers = [
      [
        columns[0].title,
        columns[1].title,
        columns[2].title,
        columns[3].title,
        columns[4].title,
        columns[5].title,
        columns[6].title,
        columns[7].title,
        columns[8].title,
        columns[9].title,
        columns[10].title,
        columns[11].title,
        columns[12].title,
        columns[13].title,
        columns[14].title,
        columns[15].title,
        columns[16].title,
      ],
    ]; 

    var filteredReport = [...report];
    const data = filteredReport.map((data) => {
      return [
        data[columns[0].dataIndex],   
        data[columns[1].dataIndex],
        data[columns[2].dataIndex],
        data[columns[3].dataIndex],
        data[columns[4].dataIndex],
        data[columns[5].dataIndex],
        FormatData(data[columns[6].dataIndex]),  //Deposit Amount
        FormatData(data[columns[7].dataIndex]),
        data[columns[8].dataIndex],
        data[columns[9].dataIndex],
        FormatData(data[columns[10].dataIndex]),
        FormatData(data[columns[11].dataIndex]),
        FormatData(data[columns[12].dataIndex]),
        FormatData(data[columns[13].dataIndex]),
        FormatData(data[columns[14].dataIndex]),
        FormatData(data[columns[15].dataIndex]),
        FormatData(data[columns[16].dataIndex]),
      ];
    });

    let content = {
      startY: 80,
      head: headers,
      body: data,
      headStyles: { fillColor: "#1DA57A"},
      styles:{fontSize:7}
    };


    doc.text(title, marginLeft, 40);

    doc.autoTable(content);   
    doc.save(`Dashboard Monitor report.pdf`);
  };

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      //   path: "first",
      breadcrumbName: "Report",
    },
    {
      path: "commBalanceReport",
      breadcrumbName: "Comm. Balance Report",
    },
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 2;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
    },

    searchBtn: {
      marginLeft: 30,
    },
    resetBtn: {
      marginLeft: 10,
    },
    datePickerStyle: {
      width: 300,
      borderRadius: 10,
    },
    mobilePageHearderContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    eachButtonContainer: {
      alignItems: "center",
      display: "flex",
      height: 50,
      marginLeft: 15,
      marginRight: 15,
      justifyContent: "center",
    },
    mobileSearchResetBtn: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      height: 50,
      alignItems: "center",
      paddingLeft: 60,
      paddingRight: 60,
    },
    btnContainer: {
      width: "100vw",
      padding: 10,
    },
    pdfStyle: {
      borderRadius: 10,
      width: 1200,
    },
    searchResetStyle: {
      borderRadius: 10,
      marginLeft: 10,
    },
    decStyle: {
      display: "flex",
      flexDirection: "row",
    },
    decLable: {
      width: 200,
    },
    decContainer: {
      paddingLeft: 75,
    },
    webRow: {
      backgroundColor: "#FFFFFF",
      paddingTop: 50,
      paddingLeft: 30,
      paddingBottom: 50,
    },
    mobileRow: {
      backgroundColor: "#FFFFFF",
      paddingLeft: 30,
    },
    cardStyle: {
      borderRadius: 20,
      height: 160,
      width: 280,
      border: "none",
    },
    headStyles: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 25,
      height: 80,
    },
    bodyStyle: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 80,
    },
  };

  return (
    <div className="eachPageContent">
      {isMobile ? (
        <div>
          <PageHeader ghost={false}>
            <div>
              <Breadcrumb
                separator=">"
                itemRender={itemRender}
                routes={routes}
              />
              <div style={styles.btnContainer}>
                <div style={styles.eachButtonContainer}>
                  {
                    dashMonDLReport(role)?
                    <Button
                    type={"primary"}
                    onClick={exportPDF}
                    style={styles.pdfStyle}
                  >
                    Download PDF
                  </Button>:<></>
                  }
                </div>
                <div style={styles.eachButtonContainer}>
                 
                </div>
              </div>
              <div style={styles.tableContainer}>
                <Table
                  columns={mobileColumns}
                  dataSource={report}
                  // expandable={{
                  //   expandedRowRender: (record) => (
                  //     <p style={{ margin: 0 }}>{record.description}</p>
                  //   ),
                  //   rowExpandable: (record) => record.name !== "Not Expandable",
                  // }}
                  className="cs_dashboard"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20"],
                    style: styles.paginationStyle,
                  }}
                />
              </div>
            </div>
          </PageHeader>
        </div>
      ) : (
        <div>
          <PageHeader
            ghost={false}
            title="Dashboard Monitor Report"
          >
            <div style={styles.pageHeaderContainer}>
              <Button
                style={styles.refreshBtn}
                type="primary"
                onClick={() => {
                  reportList();
                }}
              >
                Refresh Page
              </Button>
              {
                  dashMonDLReport(role)?
                  (
                    <Button key="2" type={"primary"} onClick={exportPDF}>
                      Download PDF
                    </Button>
                  ):<></>
              }
            </div>
            {/* extra={[
              <Button
                key="1"
                type="primary"
                onClick={() => {
                  reportList();
                }}
              >
                Refresh Page
              </Button>,
              <Button key="4" type={"primary"} onClick={exportPDF}>
                Download PDF
              </Button>,
            ]} */}
          </PageHeader>
          <div style={styles.tableContainer}>
            {/* <span>Bank Balance</span> */}
            <Table
              columns={columns}
              dataSource={report}
              className="cs_dashboard"
              scroll={{ x: 100, y: null }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: settings.pageSizeOptions,
                style: styles.paginationStyle,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
