import React, { useEffect, useState , useRef } from "react";
import { Col, Row, Form, Button, Switch, Input, Select, Spin } from "antd";
import { Modal, Typography } from "antd";
import { ExclamationCircleOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { checkResetPasswordDetail, updateResetPasswordDetail } from "../../config/networkConfig";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert } from "../../common/constants/sharedMsgBox";
import { CNotAuthorized } from "../../common/components/CNotAuthorized";
import md5 from "md5";


const { TextArea } = Input;

export default function ChangePassword() {
    const [form] = Form.useForm();
    
    const {issuper, userId, username , role, companyId, compCode } = useSelector((state) => state.login);

    let [isLoading,       setLoading] = useState(false);
    let [isDisabled,      setDisable] = useState(false);
    let [buttonLoading,   setButtonLoading] = useState(false);
    
    useEffect(() => {

    }, []);

    const onSubmit = async (data) => {
        setDisable(true);
        setLoading(true);

        if(!data.oldPassword){
            alertData.title = "Field Required";
            alertData.msg = "Please fill in old password!";
            sharedErrorAlert(alertData);
            setButtonLoading(false);
            setDisable(false);
            setLoading(false);
            return;
        }

        if(!data.newPassword){
            alertData.title = "Field Required";
            alertData.msg = "Please fill in new password!";
            sharedErrorAlert(alertData);
            setButtonLoading(false);
            setDisable(false);
            setLoading(false);
            return;
        }

        if(!data.confirmPassword){
            alertData.title = "Field Required";
            alertData.msg = "Please fill in confirm password!";
            sharedErrorAlert(alertData);
            setButtonLoading(false);
            setDisable(false);
            setLoading(false);
            return;
        }

        let md5Pass;
        data.username = username;
        md5Pass = md5(data.oldPassword);
        
        let requestData = {
            userId : userId,
            username : username,
            oldPassword : md5Pass
        }

        const result = await checkResetPasswordDetail(requestData);
        const response = await result.json();
        if (response.status == "ok") {
            if (data.newPassword.length <= 7){
                alertData.title = "Validation Error";
                alertData.msg = "Password must be at least 8 characters!";
                sharedErrorAlert(alertData);
                setDisable(false);
                setLoading(false);
                return;
            }

            if (data.oldPassword == data.newPassword || data.oldPassword == data.confirmPassword){
                alertData.title = "Validation Error";
                alertData.msg = "Old password and new password cannot be the same!";
                sharedErrorAlert(alertData);
                setDisable(false);
                setLoading(false);
                return;
            }

            if (data.newPassword != data.confirmPassword){
                alertData.title = "Validation Error";
                alertData.msg = "Confirm password does not match with new password!";
                sharedErrorAlert(alertData);
                setDisable(false);
                setLoading(false);
            }
            else {
                let resetRequestData = {
                    username : username,
                    newPassword : md5(data.newPassword)
                }
    
                const resetResult = await updateResetPasswordDetail(resetRequestData);
                const resetResponse = await resetResult.json();
    
                if (resetResponse.status == "ok") {
                    window.localStorage.clear();
                    window.location.href = "/logout";
                    return alert("Password is updated succesfully.");   
                }
            }
        }
        else if (response.status == "err") {
            alertData.title = "Failed";
            alertData.msg = response.msg;
            sharedFailAlert(alertData);
        }
        else if (response.errcode == "1101") {
            window.localStorage.clear();
            window.location.href = "/logout";
            return alert("Session expired ! please login again !");
        }
        else {
            alertData.title = "Failed";
            alertData.msg = response.msg;
            sharedFailAlert(alertData);
        }

        setLoading(false);
        setDisable(false);
    }

    const layout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 4 },
    };

    return (
        <div>

        {                 
            <Form {...layout} form={form} name="control-hooks" onFinish={onSubmit} style={{marginTop: 24}}>
                <Form.Item name="oldPassword" label="Old Password">
                    <Input disabled={isDisabled} rows={4} />
                </Form.Item>
                <Form.Item name="newPassword" label="New Password">
                    <Input disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item name="confirmPassword" label="Confirm Password">
                    <Input disabled={isDisabled} rows={4} />                    
                </Form.Item>
                <Form.Item style={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // width: 300
                }}>
                    <Button rows={4} type="primary" loading={isLoading} disabled={isDisabled} htmlType="submit"> Submit </Button>
                </Form.Item>
            </Form>
        }

        </div>
    )


    
  }