import React, { useState, useEffect } from "react";
import {
  Table,
  message,
  PageHeader,
  Button,
  Modal,
  Input,
  Badge,
  Form,
  Row,
  Col,
  Tag,
  Tabs,
  Select,
  Upload,
  InputNumber,
} from "antd";
import { QuestionCircleOutlined, UploadOutlined,ArrowRightOutlined } from "@ant-design/icons";
import {
  puppetTransactionListBankAppTrxn,
  puppetTransactionListBankAppTrxnV5,
  puppetTransactionListBankAppTrxnV6,
  csMemberWalletTrxnMobile,
  getReqToken,
  csBankConflictApproveBankApp,
  getBankList,
  csBankConflictApproveBankAppV3,
  csBankConflictApproveBankAppV4,
  csBankConflictApproveBankAppV5,
  csBankConflictApproveBankAppV6,
  getUserBankInfo,
  clearBankTransaction,
  csVerifyMobile,
  userBankAll,
  uploadReceiptV2,
  csResolveUnclaimDeposit,
  csUpdateBankAccName,
} from "../../../config/networkConfig";
import { useSelector } from "react-redux";
import { SearchOutlined} from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import { settings } from "../../../config/appConfig";
import Highlighter from "react-highlight-words";
import moment from "moment";
import momentTz from "moment-timezone";
import { FormatPt } from "../../../library/numbers";
import md5 from "md5";
import style from "../../../resource/style/style";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";
import { STATUS_CONFLICT, STATUS_PENDING, STATUS_UNATTEND, RECORD_INTERVAL } from "../../../common/constants/bankTransaction";
import { BANK_INFO_TYPE } from "../../../common/constants/bankType";
import { removePuppetBankTrxn } from "../../../library/permission";

export default function ReportBankTrxnBankApp() {
  const { confirm } = Modal;
  
  const [form] = Form.useForm();
  const {TabPane} = Tabs;
  const { userId, compCode, role, username } = useSelector((state) => state.login);
  const [originData, setOriginData] = useState("");
  const [bankData, setBankData] = useState("");
  const [unattendBankData, setUnattendBankData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  //Member Transaction 
  const [memberVisible, setMemberVisible] = useState(false);
  const [memberPhoneNo, setMemberPhoneNo] = useState("");
  const [btnApproveMemberText,setBtnApproveMemberText]=useState("");
  const [filteredPptTrxn,setFilterPptTrxn]= useState([]);
  const [searchText, setSearchText] = useState("");
  const [trxDateFrom, setTrxDateFrom] = useState("");
  const [trxDateTo, setTrxDateTo] = useState("");
  const [trxLoading, setTrxLoading] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [pptAmt, setPptAmt] = useState("");
  const [pptTrxnId, setPptTrxnId] = useState("");
  const [bankTime, setBankTime] = useState("");
  const [bankTabCount, setBankTabCount] = useState([]);
  const [defaultTab, setDefaultTab] = useState("");
  const [currentTab, setCurrentTab] = useState("");
  const [toAcctNum, setToAcctNum] = useState("");
  const [bankList, setBankList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [dataCount, setDataCount] = useState(0);


  //Manual Deposit
  const [formDelayDep] = Form.useForm();
  const [delayDepVisible, setDelayDepVisible] = useState(false);
  const [formDelayDepLoading, setFormDelayDepLoading] = useState(false);
  const [createDelayDepDisable, setCreateDelayDepDisable] = useState(false);
  const [csBankListOptions, setCSBankListOptions] = useState([]);
  const [delayDepPhoneNum, setDelayDepPhoneNum] = useState("");
  const [validatePhonePass, setValidatePhonePass] = useState(false)
  const [delayDepCountryCode, setDelayDepCountryCode] = useState("66");
  const [delayDepBankLisOptions, setDelayDepBankLisOptions] = useState([]);

  const [delayDepFromBankData, setDelayDepFromBankData] = useState("");
  const [delayDepFromBankCode, setDelayDepFromBankCode] = useState("");
  const [delayDepFromBankAcc, setDelayDepFromBankAcc] = useState("");
  const [delayDepFromBankAccName, setDelayDepFromBankAccName] = useState("");

  const [delayDepToBankDisplay, setDelayDepToBankDisplay] = useState("");
  const [delayDepToBankData, setDelayDepToBankData] = useState("");
  const [delayDepToBankCode, setDelayDepToBankcode] = useState("");
  const [delayDepToBankAcc, setDelayDepToBankAcc] = useState("");
  const [delayDepToBankAccName, setDelayDepToBankAccName] = useState("");

  const [delayDepAmt, setDelayDepAmt] = useState("");

  const [fileList, setFileList] = useState([]);
  const { Option } = Select;

  useEffect(() => {
    getBankListInfo();
  }, []);

  useEffect(() => {
    if (defaultTab && !currentTab) {
      processTrxnListV2(defaultTab);
    } else if (currentTab) {
      processTrxnListV2(currentTab)
    }
  }, [defaultTab, currentTab, currentPage, currentPageSize]);

  useEffect(()=> {
    setToAcctNum(toAcctNum);
  },[toAcctNum]);

  useEffect(()=> {
    if (delayDepFromBankData != null) {
      let depBankData = delayDepFromBankData.split("||");
      setDelayDepFromBankCode((depBankData[0]||'').trim());
      setDelayDepFromBankAcc((depBankData[1]||'').trim());
      setDelayDepFromBankAccName((depBankData[2]||'').trim());
    }
  },[delayDepFromBankData])

  useEffect(()=> {
    if (delayDepToBankData != null) {
      let depToBankData = delayDepToBankData.split("||");
      setDelayDepToBankcode((depToBankData[0]||'').trim());
      setDelayDepToBankAcc((depToBankData[1]||'').trim());
      setDelayDepToBankAccName((depToBankData[2]||'').trim());
    }
  },[delayDepToBankData])

  useEffect(() => {
    submitDelayDepCheckList();
  }, [validatePhonePass,fileList,delayDepFromBankData]);

  // Onchange for checking all delay deposit field before unlock submit button
  const submitDelayDepCheckList = async () => {
    let phoneCheck = true, bankSlipCheck = false, depositFromBankCheck = true;

    if (!validatePhonePass) { 
      phoneCheck = false; 
    }

    if (fileList[0] != undefined) {
      bankSlipCheck = true; 
    }

    if (!delayDepFromBankData) { 
      depositFromBankCheck = false;
    }

    if (phoneCheck && bankSlipCheck && depositFromBankCheck) {
      setCreateDelayDepDisable(false);
    }
    else { 
      setCreateDelayDepDisable(true);
    }
  }

  //#region Components
  const DepositAmt = (props) => {
     if (props.Amount!='') {
       return (<span>Deposit Amount :{FormatPt(props.Amount)}</span>);
     }
  }
  ////#endregion

   //#region Load List
  const getBankListInfo = async() => {
    setTableLoading(true);
    let requestData = {
      bankType: BANK_INFO_TYPE.DEP,
      columnRequire : ['AccountNum', 'UnclaimTableName as TableName', 'BankCode', 'AccountName'],
    }

    const response = await getBankList(requestData);
    let result = await response.json();
    
    if (result.status == 'ok'){
      setBankTabCount(result.data);
      setDefaultTab(result.data[0].AccountNum);
      setCSBankListOptions(result.data);
    }
    else
    {
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
    }
  }

  const getTableName = async(toacctnum) => {
    let bankTable = '';
    const findTableName = Object.keys(bankTabCount).map((x) =>{
    if (bankTabCount[x].AccountNum == toacctnum)
      {
        bankTable = bankTabCount[x].TableName
      }
    })
    return bankTable;
  }

  const processTrxnListV2 = async (toacctnum) => {
    let count = (currentPage - 1) * currentPageSize;
    let currentTime = momentTz.tz(moment(), "Asia/Kuala_Lumpur");
    let fromDate = momentTz.tz(moment(), "Asia/Kuala_Lumpur").subtract(1,"day").format("YYYY-MM-DD");
    setTableLoading(true);
    const bankTable = await getTableName(toacctnum)
    const startIndex = (currentPage - 1) * currentPageSize;
    
    let requestBankConflictData = {
      status : STATUS_CONFLICT,
      datefrom : fromDate,
      dateto : currentTime.format("YYYY-MM-DD HH:mm:ss"),
      interval : RECORD_INTERVAL,
      toacctnum : toacctnum,
      banktable : bankTable,
      recordLimit : currentPageSize,
      startIndex: startIndex.toString(),
    }

    // Filling Attention List
    const resBankCon = await puppetTransactionListBankAppTrxnV6(requestBankConflictData);
    let respBankCon = await resBankCon.json();

    if (respBankCon.status == "ok") {

      const bankDataCount = respBankCon.data[0]?.totalCount || 0;
      setDataCount(bankDataCount);

      const bankData = Object.keys(respBankCon.data).length
      ? respBankCon.data.map((obj) => {
        return {
          key: count++,
          pptBankTrxnId: obj.pptTrxnId,
          bankTime: obj.BankTime,
          bankTrxTime: obj.bankTrxTime,
          bankAccNo: obj.bankAccNo,
          bankAmount: obj.bankAmount, 
          bankDetails: obj.bankDetails,
          reqUserName: obj.ReqUserName,
          remark: obj.remark,
          status: obj.status == "0" ? "unClaim" 
                  : obj.status == "8" ? "bankConflict"
                  : obj.status == "7" ? "digitConflict" 
                  : "",
          oriStatus: obj.oriStatus,
          sysCreateBy: obj.sysCreateBy,
          sysCreateTime: obj.SysCreateTime,
          conflictRemark : [],
        };
      }): [];

      let filterData = bankData.filter(item=>item.status == "unClaim" || item.status == "bankConflict" || item.status == "digitConflict" );
      let filterBankConflict = bankData.filter(item=>item.status == "bankConflict");

      let remark_list = '';

      if (filterBankConflict.length > 0) {
        for (let i = 0; i < filterBankConflict.length; i++) {
          if (filterBankConflict[i].remark != '') {
              remark_list = filterBankConflict[i].remark.split(';');
              filterBankConflict[i].conflictRemark['bankList'] = [];

              for (let j = 0; j < remark_list.length; j++) {

                let requestData = {
                    mobile_no : remark_list[j]
                }
                
                // for getting user bank info + last deposit
                let resGetUserBankInfo = await getUserBankInfo(requestData)
                let response = await resGetUserBankInfo.json();
                  if (response.status === "ok" && response.msg != 'Bank account not found') {
                      let bankExist = filterBankConflict[i].conflictRemark['bankList'].some(item => item.mobileNo === remark_list[j]);
                      if (!bankExist) {
                        filterBankConflict[i].conflictRemark['bankList'].push(response.data);
                      }
                  }
              }
            }
        }
      }
      setBankData(filterData)
    }
    else {
      alertData.title = "Failed";
      alertData.msg = respBankCon.msg;
      sharedFailAlert(alertData);
      setBankData([]);
    }

    // let requestData = {
    //   status : STATUS_PENDING,
    //   dateto : currentTime.format("YYYY-MM-DD HH:mm:ss"),
    //   interval : RECORD_INTERVAL,
    //   toacctnum : toacctnum,
    //   banktable : bankTable
    // }

    // Filling Processing List
    // const result = await puppetTransactionListBankAppTrxnV5(requestData);
    // let resp = await result.json();

    // if (resp.status === "ok" ) {
    //   const newData = Object.keys(resp.data).length
    //   ? resp.data.map((obj) => {
    //     return {
    //       key: count++,
    //       pptBankTrxnId: obj.pptTrxnId,
    //       bankTrxTime: obj.bankTrxTime,
    //       bankAmount: obj.bankAmount, 
    //       bankDetails: obj.bankDetails,
    //       reqUserName: obj.reqUserName,
    //       remark: obj.remark,
    //       status: obj.status == "0" ? "Pending" : "",
    //       oriStatus: obj.oriStatus,
    //       sysCreateBy: obj.sysCreateBy,
    //       sysCreateTime: obj.sysCreateTime,
    //     };
    //   }) : [];

    //   setOriginData(newData);
    // }
    // else{
    //   alertData.title = "Failed";
    //   alertData.msg = resp.msg;
    //   sharedFailAlert(alertData);
    //   setOriginData([]);
    // }

    // let requestUnattendData = {
    //   status : STATUS_UNATTEND,
    //   datefrom : fromDate,
    //   dateto : currentTime.format("YYYY-MM-DD HH:mm:ss"),
    //   interval : RECORD_INTERVAL,
    //   toacctnum : toacctnum,
    //   banktable : bankTable
    // }
    // // Filling Unattend List
    // const result = await puppetTransactionListBankAppTrxnV5(requestUnattendData);
    // let resp = await result.json();

    // if (resp.status === "ok" ) {
    //   const newData = Object.keys(resp.data).length
    //     ? resp.data.map((obj) => {
    //       return {
    //         key: count++,
    //         pptBankTrxnId: obj.pptTrxnId,
    //         bankTime: obj.BankTime,
    //         bankTrxTime: obj.bankTrxTime,
    //         bankAccNo: obj.bankAccNo,
    //         bankAmount: obj.bankAmount, 
    //         bankDetails: obj.bankDetails,
    //         remark: obj.remark,
    //         status: obj.status == STATUS_UNATTEND ? "Unattend" : "",
    //         oriStatus: obj.oriStatus,
    //         sysCreateBy: obj.sysCreateBy,
    //         sysCreateTime: obj.sysCreateTime,
    //         conflictRemark : [],
    //       };
    //   }) : [];

    //   let filterBankConflict_p2 = newData.filter(item=>item.status == "Unattend"), remark_list_p2 = '';

    //   if (filterBankConflict_p2.length > 0) {
    //     for (let i = 0; i < filterBankConflict_p2.length; i++) {
    //       if (filterBankConflict_p2[i].remark != '') {
    //           remark_list_p2 = filterBankConflict_p2[i].remark.split(';');
    //           filterBankConflict_p2[i].conflictRemark['bankList'] = [];

    //           for (let j = 0; j < remark_list_p2.length; j++) {

    //             let requestData_p2 = {
    //                 mobile_no : remark_list_p2[j]
    //             }
                
    //             // for getting user bank info + last deposit
    //             let resGetUserBankInfo = await getUserBankInfo(requestData_p2)
    //             let response = await resGetUserBankInfo.json();
    //               if (response.status === "ok" && response.msg != 'Bank account not found') {
    //                   let bankExist = filterBankConflict_p2[i].conflictRemark['bankList'].some(item => item.mobileNo === remark_list_p2[j]);
    //                   if (!bankExist) {
    //                     filterBankConflict_p2[i].conflictRemark['bankList'].push(response.data);
    //                   }
    //               }
    //           }
    //         }
    //     }
    //   }

    //   setUnattendBankData(newData);
    // }
    // else{
    //   alertData.title = "Failed";
    //   alertData.msg = resp.msg;
    //   sharedFailAlert(alertData);
    //   setUnattendBankData([]);
    // }

    setTimeout( () => {
      setTableLoading(false);
    }, 2000);
  }

  const processTrxnList = async () => {

    let count = 0 ;
    let currentTime = momentTz.tz(moment(), "Asia/Kuala_Lumpur");

    setTableLoading(true);

    let requestBankConflictData = {
        status : "8",
        dateto : currentTime.format("YYYY-MM-DD HH:mm:ss"),
        interval : "15",
        type : "bank",
    }

   let requestData = {
      status : "0",
      dateto : currentTime.format("YYYY-MM-DD HH:mm:ss"),
      interval : "15",
      type : "bank",
    }

    const resBankCon = await puppetTransactionListBankAppTrxn(requestBankConflictData);
    let respBankCon = await resBankCon.json();

    if (respBankCon.status === "ok") {

      const bankData = Object.keys(respBankCon.data).length
      ? respBankCon.data.map((obj) => {
               return {
                 key: count++,
                 pptBankTrxnId: obj.pptTrxnId,
                 bankTrxTime: obj.bankTrxTime,
                 bankAmount: obj.bankAmount, 
                 bankDetails: obj.bankDetails,
                 remark: obj.remark,
                 status: obj.status == "000" ?
                         "unClaim" : obj.status == "8"?
                         "bankConflict" : obj.status == "7" ? "digitConflict" : "",
                 oriStatus: obj.oriStatus,
                 sysCreateBy: obj.sysCreateBy,
                 sysCreateTime: obj.SysCreateTime,
               };
             }): [];
       
       let filterData = bankData.filter(item=>item.status == "unClaim" || item.status == "bankConflict" || item.status == "digitConflict");
       setBankData(filterData);
    }

    const result = await puppetTransactionListBankAppTrxn(requestData);

    let resp = await result.json();
    if (resp.status === "ok" ) {

      const newData = Object.keys(resp.data).length
      ? resp.data.map((obj) => {
        return {
          key: count++,
          pptBankTrxnId: obj.pptTrxnId,
          bankTrxTime: obj.bankTrxTime,
          bankAmount: obj.bankAmount, 
          bankDetails: obj.bankDetails,
          remark: obj.remark,
          status: obj.status == "0"? 
              "Pending" : "",
          oriStatus: obj.oriStatus,
          sysCreateBy: obj.sysCreateBy,
          sysCreateTime: obj.sysCreateTime,
        };
      }) : [];

      setOriginData(newData);

    }

    setTimeout( () => {
      setTableLoading(false);
    }, 3000);

  }

  const getCustomerBankList = async (memberUserId)=>{
    let respUserBank = await userBankAll({ userid: memberUserId });
    let result = await respUserBank.json();

    if (result.status == "ok" ) {
      if (Object.keys(result.data).length > 0 ) {
        
        let count = 0;
        let newBankData = result.data.map((obj) => {
          return {
            key: count++,
            userid : memberUserId,
            bankcode : obj.bankcode,
            bank : obj.bank,
            acctnum : obj.acctnum,
            acctname : obj.acctname,
            status : obj.status,
          };
        });
  
        let filter_active_bank = newBankData.filter(e => e.status == 1);
        setDelayDepBankLisOptions(filter_active_bank);

        if (filter_active_bank.length < 1) {
          clearAllFields();
          alertData.title = "Error";
          alertData.msg = `Please create Bank Account for user +${delayDepCountryCode+delayDepPhoneNum}`;
          sharedErrorAlert(alertData);
        }
      }
      else {
          clearAllFields();
          alertData.title = "Error";
          alertData.msg = `Please create Bank Account for user +${delayDepCountryCode+delayDepPhoneNum}`;
          sharedErrorAlert(alertData);
      }
    }
  }
  ////#endregion

  //#region Functions
  const onChangeBankTab = (key)=> {
    if (!key){
      alertData.title = "Error";
      alertData.msg = "Something is wrong. Please try again !";
      sharedErrorAlert(alertData);
      setBankData([]);
      setOriginData([]);
    }
    else{
      setCurrentPageSize(10);
      setCurrentPage(1);
      // processTrxnListV2(key);
      setCurrentTab(key);
    }
  }

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const approvePPTTrxn1V2 = async (index) => {
    const bankTable = await getTableName(toAcctNum)
    
    let approveData = {
      pptTrxnId  : pptTrxnId, 
      csUserid   : userId,
      mobile     : memberPhoneNo,
      type       : 'bank',
      reqToken   : md5(getReqToken('bankConflictApprove')),
      toacctnum  : toAcctNum,
      banktable  : bankTable,
    }

    if (index > -1) {
      loadingTrue(index);
      const result =  compCode != 'TKH' ? await csBankConflictApproveBankAppV3(approveData) : await csBankConflictApproveBankAppV4(approveData);
      let resp = await result.json();

      if (resp.status === "ok") {
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData);
        setMemberVisible(false);    
        loadingFalse(index);
        // processTrxnListV2(toAcctNum);
        if (dataCount !== 1 && dataCount === ((currentPage - 1) * currentPageSize) + 1) {
          setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        } else {
          processTrxnListV2(toAcctNum);
        }
      }
      else{
        loadingTrue(false);
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);
      }
    }
    else{
      loadingFalse(index);
      alertData.title = "Error";
      alertData.msg = "Connection not stable, please try again !";
      sharedErrorAlert(alertData);
    }
  }
  
  // Last updated on 2023-04-26, remark: added new param amt, bankTime
  const approvePPTTrxn1V3 = async (record, mobile) => {
    setTableLoading(true);
    
    const bankTable = await getTableName(record.bankAccNo)
    
    let requestData = {
      pptTrxnId  : record.pptBankTrxnId, 
      amt        : record.bankAmount.toFixed(2),
      bankTime   : record.bankTime,
      csUserid   : userId,
      mobile     : mobile,
      type       : 'bank',
      reqToken   : md5(getReqToken('bankConflictApprove')),
      toacctnum  : record.bankAccNo,
      banktable  : bankTable,
      compCode   : compCode
    }
    
    const result = await csBankConflictApproveBankAppV6(requestData);
    let resp = await result.json();

    if (resp.status === "ok") {
      alertData.title = "Success";
      alertData.msg = resp.msg;
      sharedSuccessAlert(alertData); 
      setTableLoading(false);
      setButtonLoading(false);
      // processTrxnListV2(record.bankAccNo);
      if (dataCount !== 1 && dataCount === ((currentPage - 1) * currentPageSize) + 1) {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
      } else {
        processTrxnListV2(record.bankAccNo);
      }
    }
    else{
      setTableLoading(false);
      setButtonLoading(false);
      // processTrxnListV2(record.bankAccNo);
      alertData.title = "Failed";
      alertData.msg = resp.msg;
      sharedFailAlert(alertData);
    }
  }
  
  // Added on 2024-08-05, remark: added update user bank acc name 
  const approvePPTTrxn1V4 = async (record, mobile, bankCode, endNumber) => {
    setTableLoading(true);

    const bankTable = await getTableName(record.bankAccNo)
    
    let requestData = {
      pptTrxnId  : record.pptBankTrxnId, 
      amt        : record.bankAmount.toFixed(2),
      bankTime   : record.bankTime,
      csUserid   : userId,
      mobile     : mobile,
      type       : 'bank',
      reqToken   : md5(getReqToken('bankConflictApprove')),
      toacctnum  : record.bankAccNo,
      banktable  : bankTable,
      compCode   : compCode
    }
    
    const result = await csBankConflictApproveBankAppV6(requestData);
    let resp = await result.json();

    if (resp.status === "ok") {
      if (dataCount !== 1 && dataCount === ((currentPage - 1) * currentPageSize) + 1) {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
      } else {
        processTrxnListV2(record.bankAccNo);
      }

      let requestData2 = {
        reqUserName    : record.reqUserName,
        mobile         :  mobile,
        bankCode       :  bankCode,
        endNumber      :  endNumber,
        username       :  username,
      }

      const result2 = await csUpdateBankAccName(requestData2);
      let response2 = await result2.json();
      
      if(response2.status === 'ok'){

        alertData.title = "Success";
        alertData.msg = 'Approved done and bank details updated successfully. ';
        sharedSuccessAlert(alertData); 
        setTableLoading(false);
        setButtonLoading(false);
        processTrxnListV2(record.bankAccNo);
        
      } else {
        alertData.title = 'Failed'
        alertData.msg = 'Approved done successfully but failed to Update user bank account name! ';
        sharedFailAlert(alertData);
      }
    }
    else{
      setTableLoading(false);
      setButtonLoading(false);
      processTrxnListV2(record.bankAccNo);
      alertData.title = "Failed";
      alertData.msg = resp.msg;
      sharedFailAlert(alertData);
    }
  }

  // Added on 2023-05-24, remark: new feature to change bank transaction into status 5 + update remark
  const clearPPTTrxn = async (record) => {
    setTableLoading(true);

    const bankTable = await getTableName(record.bankAccNo)
    
    let requestData = {
      pptBankTrxnId  : record.pptBankTrxnId,
      bankTable  : bankTable,
      csUserId   : userId,
    }
    const result = await clearBankTransaction(requestData);
    let resp = await result.json();

    if (resp.status === "ok") {
      alertData.title = "Success";
      alertData.msg = resp.msg;
      sharedSuccessAlert(alertData); 
      setTableLoading(false);
      setButtonLoading(false);
      // processTrxnListV2(record.bankAccNo);
      if (dataCount !== 1 && dataCount === ((currentPage - 1) * currentPageSize) + 1) {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
      } else {
        processTrxnListV2(record.bankAccNo);
      }
    }
    else{
      setTableLoading(false);
      setButtonLoading(false);
      // processTrxnListV2(record.bankAccNo);
      alertData.title = "Failed";
      alertData.msg = resp.msg;
      sharedFailAlert(alertData);
    }
  }

  // const handleMemberPhone = (e) => {
  //   e.preventDefault();
  //   alertData.title = "Error";
  //   alertData.msg = "Copy Not Allowed";
  //   sharedErrorAlert(alertData);
  // }

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });

  const clearModal = ()=> {
      setMemberVisible(false);
      setToAcctNum("");
      setMemberPhoneNo("");
      setBtnApproveMemberText("");
      setFilterPptTrxn([]);
      setPptTrxnId("");
  }

  const ViewPPTDetails = async (mobile,trxnTime) => {
    
    setTrxLoading(true);

    let startDate = moment(trxnTime).subtract(1,'days').format('YYYY-MM-DD');
    let endDate = moment(trxnTime).add(1,'days').format('YYYY-MM-DD')

    let requestData = {
        mobile:mobile,
        datefrom: startDate,
        dateto: endDate,
    }

    let respTrxn = await csMemberWalletTrxnMobile(requestData);
    let resTrxn = await respTrxn.json();
    let countTrxn = 0 ;

    if (resTrxn.status === "ok") {

      if (resTrxn.data.detail && resTrxn.data.detail.length) {

        setTrxLoading(false);

        let newData = resTrxn.data.detail.map((obj) => {
          return {
            key: countTrxn++,
            date: obj.date,
            userid: obj.userid,
            trxnID: obj.trxnID,
            trxnType: obj.trxnType, 
            amount: obj.amount,
            actualAmount: obj.actualAmount,
            comm: obj.comm,
            status: obj.status,
            actionStatus :
              obj.status == "0"
                ? "Pending"
                : obj.status == "1"
                ? "Approved"
                : obj.status == "3"
                ? "Completed"
                : obj.status == "9"
                ? "Rejected"
                : "-",
          };
        });

        setFilterPptTrxn(newData);
        
        const distinctStatus = [...new Set(newData.map(item=>item.actionStatus))];

        //let dates = resTrxn.data.detail.map(item => moment(item.date)), maxDate = moment.max(dates) , minDate = moment.min(dates);
        //console.log(`minxDate >> ${minDate.format("YYYY-MM-DD")} , maxDate >> ${maxDate.format("YYYY-MM-DD")}`);
        //setTrxDateFrom(minDate);
        //setTrxDateTo(maxDate);
      }

      if (resTrxn.data.summary && resTrxn.data.summary.length) {
          // let walletSumm = resTrxn.data.summary[0];
          // setCSMemberWalletDt(walletSumm.Dt);
          // setCSMemberWalletBal(`${FormatPt(walletSumm.Balance)}`);
          // setCSMemberWalletComm(`${FormatPt(walletSumm.Comm)}`);
      }
      else{
        // setCSMemberWalletDt("N/A");
        // setCSMemberWalletBal("N/A");
        // setCSMemberWalletComm("N/A");
      }
    }

    setTrxLoading(false);
  }
  
  const handleResolveConflict = (record, mobile) => {
    setButtonLoading(true);

    confirm({
        className: "confirmationModalStyle",
        title: "Confirmation",
        centered: true,
        icon: <QuestionCircleOutlined />,
        content: `Are you sure you want to Approve to this customer +${mobile}?`,
        onOk() {
            approvePPTTrxn1V3(record, mobile);
        },
        onCancel() {   
          setButtonLoading(false);     
        },
      });
  }
 
  // Added on 2024-08-05, remark: added update user bank acc name 
  const handleResolveConflictV2 = (record, mobile) => {
    setButtonLoading(true);
    let bankCode = record.bankDetails.split(' ')[1];
    let endNumber = record.bankDetails.split(' ')[2];
    let foundBank = record.conflictRemark.bankList.find(item => item.mobileNo == mobile);
    let toBankAccNo = foundBank.bank[0].acctnum;
    let oldAccName = foundBank.bank[0].acctname;

    confirm({
        className: "confirmationModalStyle",
        title: "Confirmation",
        centered: true,
        width: '550px',
        icon: <QuestionCircleOutlined />,
        content: <div>
          <p>Are you sure you want to Approve to this customer +{mobile} & Update the following details? </p>
          <ul style={{marginTop: '-2px'}}>
            <li>Bank Code : {bankCode}</li>
            <li>Bank Account : {toBankAccNo}</li>
              <li>Bank Account Name :</li >
            <ul>
              <li ><span style={{color:'red'}}>Old </span><ArrowRightOutlined /> {oldAccName}</li>
              <li ><span style={{color:'green'}}>New </span><ArrowRightOutlined /> {record.reqUserName}</li>
            </ul>
          </ul>
        </div>,
        onOk() {
            approvePPTTrxn1V4(record, mobile, bankCode, endNumber);
        },
        onCancel() {   
          setButtonLoading(false);     
        },
      });
  }

  const handleClearUnattend = (record) => {
    setButtonLoading(true);

    confirm({
        className: "confirmationModalStyle",
        title: "Confirmation",
        centered: true,
        icon: <QuestionCircleOutlined />,
        content: `Are you sure you want to Remove this bank transaction?`,
        onOk() {
            clearPPTTrxn(record);
        },
        onCancel() {   
          setButtonLoading(false);     
        },
      });
  }

  const handleCreateDelayDep = async () => {
    setFormDelayDepLoading(true);
    formDelayDep.setFieldsValue({
      bankReceipt: fileList[0],
    });
  
    const row = await formDelayDep.validateFields();
    const uploadResult = await uploadFile();
      
    if (uploadResult.status == "ok") {
      let filename = uploadResult.data !== undefined ? uploadResult.data.filename : '';
      let requestData = {
        mobile : delayDepCountryCode + delayDepPhoneNum,
        depFrom : 
          {
            fromBankCode     : delayDepFromBankCode,
            fromBankAcc      : delayDepFromBankAcc,
            fromBankAccName  : delayDepFromBankAccName,
          },
        depTo : 
          {
            toBankCode     : delayDepToBankCode,
            toBankAcc      : delayDepToBankAcc,
            toBankAccName  : delayDepToBankAccName,
          },
  
        uploadRef : filename,
        amt       : Number(delayDepAmt).toFixed(2),
        csId      : userId,
        pptTrxnId : pptTrxnId,
        bankTime  : bankTime,
        // reqToken  : md5(getReqToken('delayDepositCreate')),
      }
  
      let resResolveUnclaim = await csResolveUnclaimDeposit(requestData);
      let result = await resResolveUnclaim.json();
      if (result.status == "ok") {
        onCancelDelayDep();
        setFormDelayDepLoading(false);
        // processTrxnListV2(delayDepToBankAcc);
        if (dataCount !== 1 && dataCount === ((currentPage - 1) * currentPageSize) + 1) {
          setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        } else {
          processTrxnListV2(delayDepToBankAcc);
        }
        alertData.title = "Success";
        alertData.msg = result.msg;
        sharedSuccessAlert(alertData);
      }
      else {
        onCancelDelayDep();
        setFormDelayDepLoading(false);
        processTrxnListV2(delayDepToBankAcc);
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
      }
    }
    else {
      alertData.title = "Failed";
      alertData.msg = uploadResult.msg;
      sharedFailAlert(alertData);
    }
  };

  const handleResolveUnclaim = (record) => {
    let filterDepToBank = csBankListOptions.filter(e => e.AccountNum == record.bankAccNo);
    setButtonLoading(true);
    setDelayDepVisible(true);
    setDelayDepToBankDisplay(filterDepToBank[0].BankCode + ' (' + filterDepToBank[0].AccountNum + ') - ' + filterDepToBank[0].AccountName);
    setDelayDepToBankData(filterDepToBank[0].BankCode + ' || ' + record.bankAccNo + ' || ' + filterDepToBank[0].AccountName);
    setDelayDepAmt(record.bankAmount);
    setPptTrxnId(record.pptBankTrxnId);
    setBankTime(record.bankTime);
  }
  
  const receiptUploads = () => {
    return (
      <>
        <Upload {...fileProps} fileList={fileList}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
      </>
    );
  };

  const uploadFile = async () => {
    const formData = new FormData();
    formData.append("fileReceipt", fileList[0]);
    if (fileList[0]!== undefined){
          const response = await uploadReceiptV2(formData);
        let result = await response.json();
        return result;
    }
    return { status:'ok'};
  };

  const fileProps = {
    listType: "picture",
    onRemove: (file) => {
      setFileList([]);
    },
    fileList: fileList,
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        let newFileList = [];
        newFileList.push(file);
        setFileList(newFileList);
      });
  
      reader.readAsDataURL(file);
      return false;
    },
    onChange: (info) => {
      // 1. Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      let newFileList = [...info.fileList];
      newFileList = newFileList.slice(-1);
  
      // 2. Read from response and show file link
      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }
  
        return file;
      });
  
      setFileList(newFileList);
      return false;
    },
  };

  const onCancelDelayDep = () => {
    clearAllFields();
    setDelayDepAmt(""); // Put here to refrain it from getting clear when phone validation not pass
    setDelayDepToBankDisplay(""); // Put here to refrain it from getting clear when phone validation not pass
    setValidatePhonePass(false); 
    setCreateDelayDepDisable(true);
    setFormDelayDepLoading(false);
    setDelayDepVisible(false);
    setButtonLoading(false);
  }

  const clearAllFields = () => {
    setFileList([]);
    setPptTrxnId("");
    setDelayDepFromBankData(null);
    setDelayDepFromBankAccName("");
    setDelayDepFromBankAcc("");
    setDelayDepFromBankCode("");
    setDelayDepCountryCode("66");
    setDelayDepPhoneNum("");
    formDelayDep.resetFields();
  }

  const selectDelayDepCountryCode = (value) => {
    setDelayDepCountryCode(value);
  };

  const validateUserPhone = async(phoneNum)=> {
    const response = await csVerifyMobile({ mobile: delayDepCountryCode + phoneNum });
    let result = await response.json();
  
    if (result.status == "ok") {
      getCustomerBankList(result.data.userid);
      setValidatePhonePass(true);
    }
    else {
      setCreateDelayDepDisable(true);
      clearAllFields();
      alertData.title = "Error";
      alertData.msg = "User account not found !";
      sharedErrorAlert(alertData);
    }
  }
  ////#endregion


  //#region Columns
  let memberTrxnColumns = [
    {
      align: "center",
      title: "Date Time",
      dataIndex:"date",
      width: "200px",
      //ellipsis: true,
    //   render: (text, record)=>{
    //     let statement = text+'||'+record.trxnID;
    //     return <span>{statement}</span>;
    //  }
    },
    {
      align: "center",
      title: "Transaction Type",
      dataIndex: "trxnType",
      width: "200px",
      ellipsis: true,

      //filters: filterData(this)(i => i.trxnType),
      filters: [
        {
          text: 'Commision',
          value: 'Comm',
        },

        {
          text: 'Game-in',
          value: 'Game-In',
        },
        {
          text: 'Game-out',
          value: 'Game-Out',
        },
        {
          text: 'Promotion',
          value: 'Promotion',
        },
        {
          text: 'Recovery',
          value: 'Recovery',
        },
        {
          text: 'Topup',
          value: 'Topup',
        },
        {
          text: 'Withdraw',
          value: 'Withdraw',
        },
      ],
      filterMultiple: false,
      //onFilter: (value, record) => record.trxnType.indexOf(value) === 0,
      onFilter: (value, record) => record.trxnType.toLowerCase().indexOf(value.toLowerCase()) === 0 ,
      render: (text)=>{
         if (text.toLowerCase() == "topup") {
            return "Topup";
         }else {
           return text;
         }
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Amount",
      dataIndex: "amount",
      width: "150px",
      render : (text) => {
        return <div style={style.bankAmountStyle}>{FormatPt(text)}</div>
      },
      ...getColumnSearchProps("amount"),
    }, 
    {
      ellipsis: true,
      align: "center",
      title: "Actual Amount",
      dataIndex: "actualAmount",
      width: "150px",
      render :(text,record)=> {
        if (text===0 || text=="") {
            return <>-</>;
        }else {
            return <div style={style.bankAmountStyle}>{FormatPt(text)}</div>
        }
      },
      ...getColumnSearchProps("actualAmount"),
    },   
    {
      ellipsis: true,
      align: "center",
      title: "Commission",
      dataIndex: "comm",
      width: "150px",
    },
    {
      ellipsis: true,
      align: "left",
      title: "Status",
      dataIndex: "status",
      width: "150px",
      render: (text, record) => {
        switch (text) {
            case 0:
              return <Badge text="Pending" color={"yellow"}/>;
            case 1:
                if (record.trxnType =="Withdraw") {
                    return <Badge text="Bank Process Complete" color={"blue"} />;
                }
                return <Badge text="Approved" color={"blue"} />;       
            case 3:
                return <Badge text="Approved" color={"blue"} />;
            case 9:
              return <Badge status="error" text="Rejected" />;
            default:
               return <span>{text}</span>;

        }
      }
    },
  ];

  let pptColumns = [
    {
      align: "center",
      title: "",
      dataIndex:"pptBankTrxnId",
      width: "10px",
      //width:"100px",
      ellipsis: true,
      render: (text)=> {
        return "";
      }
    },
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "bankTrxTime",
      width: "200px",
      ellipsis: true,
      render:(text) => {
        return (text);
      }
    },
    {
      align: "center",
      title: "Amount",
      dataIndex: "bankAmount",
      width: "150px",
      // ellipsis: true,
      render: (text)=> {
        return <div style={style.bankAmountStyle}>{FormatPt(text)}</div>;
      },
      ...getColumnSearchProps("bankAmount"),
    },    
    {
      align: "center",
      title: "Bank Details",
      dataIndex: "bankDetails",
      width: "200px",
      ellipsis: true,
      render: (text)=> {
        return (text);
      },
      ...getColumnSearchProps("bankDetails"),
    },
    {
      align: "center",
      title: "Request Username",
      dataIndex: "reqUserName",
      width: "200px",
      ellipsis: true,
      render: (text)=> {
        return (text);
      },
      ...getColumnSearchProps("reqUserName"),
    },
    {
      align: "center",
      title: "Status",
      dataIndex: "status",
      width: "200px",
      ellipsis: true,
      render: (text)=> {
        if (text == "unClaim") {
          return <Tag color="#FFBF00" style={style.tagStyle}><b>Un-claim</b></Tag>
        }
        else if (text == "bankConflict") {
          return <Tag color="red" style={style.tagStyle}>Bank Conflict</Tag>
        } 
        else {
          return <Tag color="pink" style={style.tagStyle}>Digit Conflict</Tag>
        }

      },
      filters: [
        {
          text: 'Un-Claimed',
          value: 'unClaim',
        },

        {
          text: 'Bank Conflict',
          value: 'bankConflict',
        },
        {
          text: 'Last Digit Conflict',
          value: 'digitConflict',
        }
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.status.toLowerCase().indexOf(value.toLowerCase()) === 0 ,

    },
    {
      align: "center",
      title: "Action",
      dataIndex: "action",
      width: "200px",
      ellipsis: true,
      render: (text, record)=> {
          if (record.status == 'bankConflict') {
              return (
                removePuppetBankTrxn(role, compCode) ?
                  <div>
                    <Button
                      loading={buttonLoading}
                      onClick={() => {
                        handleClearUnattend(record);
                      }}
                      style={{
                        width:"100px", 
                        marginBottom:"8px" 
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                :
                <></>
              )
          }
          else if (record.status == 'unClaim') {
            return (
                  <div>
                    <Button
                      loading={buttonLoading}
                      onClick={() => {
                        handleResolveUnclaim(record);
                      }}
                      style={{
                        width:"100px", 
                        marginBottom:"8px" 
                      }}
                    >
                      Resolve
                    </Button>
                  </div>
            )
          }
          else {
            return <div></div>
          }
      },
    },
    {
      title: "Remark",
      dataIndex: "remark",
      width: "800px",
      render: (text,record) => {
          if (text.length > 0 && text.indexOf(';') > -1) {
            if (Object.keys(record.conflictRemark['bankList']).length > 0) {
              const listItems = record.conflictRemark['bankList'].map((item,index) =>
                  <div key={`${index}-${item}`} style={{border: "none"}}>
                    <div style={{display:'flex', flexDirection:'column'}}>
                      {/* Tp */}
                      <Button
                          loading={buttonLoading}
                          key={`${index}-${item.mobileNo}`}
                          onClick={() => {
                            handleResolveConflict(record,item.mobileNo);
                          }}
                          style={{ width:"300px", marginBottom:"8px", color:'white', backgroundColor:'#008000' }}
                        >
                          {`Approve to ${item.mobileNo}`}
                        </Button>
                        {record.reqUserName ? 
                        
                        <Button
                        loading={buttonLoading}
                        style={{ width:"300px", marginBottom:"8px",color:'white',backgroundColor:'#F98401' }}
                        onClick={() => {
                          handleResolveConflictV2(record,item.mobileNo);
                        }}
                        >
                        {`Approve & Update to ${item.mobileNo}`}
                        </Button>
                      :
                      <></>
                      }
                        {(record.conflictRemark['bankList'][index].bank_trxn_info.split(';')).map((item2) =>
                          <ul key={`${item2}`}>
                            <li key={`${item2}`}>{item2}</li>
                          </ul>
                        )}
                    </div>
                  </div>
              )

              return (
                <div>
                  { listItems }
                </div>
              );
            }

          }
          else {
            return <div></div>
          }
          
          // will be remove in future if new version is stable
          //   if (text.length > 0 && text.indexOf(';') > -1) {
          //       let members = text.split(';');
          //       console.log(`record type`, record.conflictRemark['bankList'])

          //       const listItems = members.map((item,index) =>
          //           <div key={`${index}-${item}`} style={{border: "none"}}>
          //             <div>
          //               <Button 
          //                   key={`${index}-${item}`}
          //                   onClick={() => {
          //                     setMemberVisible(true);
          //                     setPptAmt(record.bankAmount);
          //                     setPptTrxnId(record.pptBankTrxnId);
          //                     setToAcctNum(record.bankAccNo);
          //                     setMemberPhoneNo(item);
          //                     setBtnApproveMemberText(`Approve to ${item}`);
          //                     ViewPPTDetails(item.trim(),record.bankTrxTime);
          //                   }}
          //                   style={{...style.memberBtn,...style.noHighlight}}
          //                 >
          //                   {`Members ${index+1}`}
          //                 </Button>
          //             </div>
          //           </div>
          //       )

          //       return (
          //         <div>
          //           { listItems }
          //         </div>
          //       );

          //   }
          
          // else {
          //   return <div></div>;
          // }
          
      },
    },
  ].filter(item => !item.hidden);;

  let processColumns = [
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "bankTrxTime",
      width: "200px",
      render:(text) => {
        return (text);
      }
    },
    {
      align: "center",
      title: "Amount",
      dataIndex: "bankAmount",
      width: "150px",
      render: (text)=> {
        return <div style={style.bankAmountStyle}>{FormatPt(text)}</div>;
      }
    },    
    {
      align: "center",
      title: "Bank Details",
      dataIndex: "bankDetails",
      width: "200px",
      ellipsis: true,
      render: (text)=> {
        return (text);
      }
    },
    {
      align: "center",
      title: "Request Username",
      dataIndex: "reqUserName",
      width: "200px",
      ellipsis: true,
      render: (text)=> {
        return (text);
      }
    },
    {
      align: "center",
      title: "Status",
      dataIndex: "status",
      width: "200px",
      ellipsis: true,
      render: (text)=> {
          return <Tag color="green" style={style.tagStyle}>Processing</Tag>
      },
    },
    {
      align: "center",
      title: "Remark",
      dataIndex: "remark",
      width: "150px",
      render: (text) => {
            return <div></div>;
      },
    },
  ];

  // let unattendColumns = [
  //   {
  //     align: "center",
  //     title: "Date / Time",
  //     dataIndex: "bankTrxTime",
  //     width: "200px",
  //     render:(text) => {
  //       return (text);
  //     }
  //   },
  //   {
  //     align: "center",
  //     title: "Amount",
  //     dataIndex: "bankAmount",
  //     width: "150px",
  //     render: (text)=> {
  //       return <div style={style.bankAmountStyle}>{FormatPt(text)}</div>;
  //     }
  //   },    
  //   {
  //     align: "center",
  //     title: "Bank Details",
  //     dataIndex: "bankDetails",
  //     width: "200px",
  //     ellipsis: true,
  //     render: (text)=> {
  //       return (text);
  //     }
  //   },
  //   {
  //     align: "center",
  //     title: "Status",
  //     dataIndex: "status",
  //     width: "200px",
  //     ellipsis: true,
  //     render: (text)=> {
  //         return <Tag color="red" style={style.tagStyle}>Bank Conflict</Tag>
  //     },
  //   },
  //   {
  //     align: "center",
  //     title: "Action",
  //     dataIndex: "action",
  //     width: "200px",
  //     ellipsis: true,
  //     render: (text, record)=> {
  //         return (
  //           <div>
  //             <Button
  //               loading={buttonLoading}
  //               onClick={() => {
  //                 handleClearUnattend(record);
  //               }}
  //               style={{
  //                 width:"100px", 
  //                 marginBottom:"8px" 
  //               }}
  //             >
  //               Clear
  //             </Button>
  //           </div>
  //         )
  //     },
  //   },
  //   {
  //     // align: "center",
  //     title: "Remark",
  //     dataIndex: "remark",
  //     width: "800px",
  //     render: (text, record) => {
  //       if (text.length > 0 && text.indexOf(';') > -1) {
  //         if (Object.keys(record.conflictRemark['bankList']).length > 0) {

  //           const listItems = record.conflictRemark['bankList'].map((item,index) =>
  //               <div key={`${index}-${item}`} style={{border: "none"}}>
  //                 <div>
  //                   <Button
  //                       loading={buttonLoading}
  //                       key={`${index}-${item.mobileNo}`}
  //                       onClick={() => {
  //                         handleResolveConflict(record,item.mobileNo);
  //                       }}
  //                       style={{ width:"250px", marginBottom:"8px" }}
  //                     >
  //                       {`Approve to ${item.mobileNo}`}
  //                     </Button>
  //                     <Button
  //                     style={{ width:"250px", marginBottom:"8px" }}
  //                     >
  //                     {`Approve & Update ${item.mobileNo}`}
  //                     </Button>
  //                     {(record.conflictRemark['bankList'][index].bank_trxn_info.split(';')).map((item2) =>
  //                       <ul key={`${item2}`}>
  //                         <li key={`${item2}`}>{item2}</li>
  //                       </ul>
  //                     )}
  //                 </div>
  //               </div>
  //           )

  //           return (
  //             <div>
  //               { listItems }
  //             </div>
  //           );
  //         }

  //       }
  //       else {
  //         return <div></div>
  //       }
  //       return <div></div>
  //     },
  //   },
  // ];

  ////#endregion

  const styles = {
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
    },
    refreshBtn: {
      marginLeft: 10,
    },
    tabelContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    // dropdownButtonStyle:{
    //   marginLeft:"-16px",
    //   marginRight:"-16px",
    // },
    hide :{
      display:"none",
    }
  };

  const onDelayDepositCreateForm = () => {
    return (
      <div>
        <div>
            <Form 
              form={formDelayDep} 
              layout="vertical" 
              name="depositform">
              {/* Mobile Id*/}
                <Form.Item
                  label="Phone Number"
                  required={true}
                >
                  <Input.Group compact>
                    <Select value={delayDepCountryCode} 
                      onChange={selectDelayDepCountryCode}>
                      <Option value="66">+66</Option>
                      <Option value="60">+60</Option>
                    </Select>
                    <Input style={{width:"66%"}} defaultValue="" placeholder="phone number" value={delayDepPhoneNum}
                      onChange={(e) => {
                        setDelayDepPhoneNum(e.target.value.replace(/\D|^0/g, ""));
                        setValidatePhonePass(false);
                      }}
                      onBlur={(e) => {
                        validateUserPhone(e.target.value.replace(/\D|^0/g, ""));
                      }}
                    />
                  </Input.Group>
                </Form.Item>  
              {/* Upload Receipt */}
              <Form.Item
                // name="bankReceipt"
                label="Bank Receipt"
                required={true}
              >
                {receiptUploads()}
              </Form.Item>
              {/* Bank Acc Name */}
              <Form.Item
                name="depToBank"
                label="Deposit To Bank"
              >
                <div>
                  {delayDepToBankDisplay}
                </div>    
              </Form.Item>
  
              <Form.Item
                // name="depFromBank"
                label="Customer Bank Account"
                rules={[
                  {
                    required: true,
                    message: "Please input your bank!",
                  },
                ]}
              >
                <Select
                  style={styles.datePickerStyle2}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.children.includes(input);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={(value, event) => {
                    setDelayDepFromBankData(value);
                    for (var i = 0; i < delayDepBankLisOptions.length; i++) {
                      if (delayDepBankLisOptions[i].bankAcc === value) {
                        formDelayDep.setFieldsValue({
                          bankcode: delayDepBankLisOptions[i].bankcode,
                          bankaccname: delayDepBankLisOptions[i].acctname,
                        });
                      }
                    }
                  }}
                >
                  {
                    delayDepBankLisOptions.map((option) => {
                      return (
                        <Option key={option.acctnum} value={`${option.bankcode} || ${option.acctnum} || ${option.acctname}`}>
                          {option.bankcode} ({option.acctnum}) - {option.acctname}
                        </Option>
                      );
                    })
                  }
                </Select>
              </Form.Item> 
  
              {/* Amount */}
              <Form.Item
                // name="amt"
                label="Amount"
              >
                {/* <InputNumber
                  min={1}
                  type="number"
                  value={delayDepAmt}
                  onChange={(value) => {
                    setDelayDepAmt(value);
                  }}
                /> */}
                <div>
                  {delayDepAmt}
                </div>
              </Form.Item>
  
              <Button 
                type="primary" 
                onClick={handleCreateDelayDep} 
                disabled={createDelayDepDisable} 
                loading={formDelayDepLoading}
                >
                  Submit
              </Button>
  
            </Form>
        </div>
      </div>
    );
  };

 // Function to handle page change and page size change
const handlePageAndSizeChange = (page, pageSize) => {
  setCurrentPage(page);
  setCurrentPageSize(pageSize);
};

  return (
    <div>
      <PageHeader ghost={false}>

      </PageHeader>
      <div style={style.tabelContainer}>
        <Tabs defaultActiveKey="1" onChange={onChangeBankTab} id="bank-list">
          {bankTabCount.map((item, index) => {
            return(
              <TabPane tab={`${item.AccountNum}`} key={`${item.AccountNum}`} id="tab-key">
                <div style={{height:"100%", overflowX:"scroll"}}>
                  <div>
                    <Button
                      style={style.refreshBtn}
                      type="primary"
                      onClick={() => {
                        if (!currentTab) {
                          processTrxnListV2(defaultTab);
                        }
                        else {
                          processTrxnListV2(currentTab);
                        }
                      }}
                    >
                      Refresh Page
                    </Button>
                      <br/>
                      <br/>
                  </div>

                  <p style={style.attn}>Attention</p>
                    <Table
                      columns={pptColumns}
                      dataSource={bankData}
                      loading={tableLoading}
                      pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        // total: 20,
                        pageSizeOptions: settings.pageSizeOptionsSmall,
                        style: style.paginationStyle,
                        alignmentBottom: 'right',
                        pageSize: currentPageSize,
                        current: currentPage,
                        total: dataCount,
                        onChange: handlePageAndSizeChange,
                        onShowSizeChange: handlePageAndSizeChange,
                      }}
                    />
                  </div>

                  {/* <div style={{height:"50%", overflowX:"scroll"}}>
                    <p style={style.attn}>Unattend</p>
                      <Table
                        columns={unattendColumns}
                        dataSource={unattendBankData}
                        loading={tableLoading}
                        pagination={{
                          defaultPageSize: 10,
                          showSizeChanger: true,
                          pageSizeOptions: settings.pageSizeOptions,
                          style: style.paginationStyle,
                          alignmentBottom: 'right',
                        }}
                      />
                  </div> */}
                  
                  {/* <div style={{height:"50%"}}>
                    <p style={style.attn}>Processing</p>
                      <Table
                        columns={processColumns}
                        dataSource={originData}
                        loading={tableLoading}
                        pagination={{
                          defaultPageSize: 10,
                          showSizeChanger: true,
                          pageSizeOptions: settings.pageSizeOptions,
                          style: style.paginationStyle,
                          alignmentBottom: 'right',
                        }}
                      />
                  </div> */}

                </TabPane>
          )})}
        </Tabs>
      </div>{/*Table container ending*/}
      <Modal visible={memberVisible}
          onCancel={() => {         
            clearModal();
          }}
          width={1400}
          style={style.memberTrxnStyle}
          centered
          cancelButtonProps={{ type: "primary" }}
          okButtonProps={{ style:{display: "none"} }}    
          cancelText="Close"   
      >

          <div style={style.tabelContainer}>
          <>
            {
              // puppetVisible?
              // (
              <>
               
              <Row gutter={[8, 8]} style={{marginBottom:"10px"}}>
                  <Col span={20}>
                      <p style={style.noHighlight} >User Mobile : {memberPhoneNo}&nbsp;&nbsp;&nbsp;<DepositAmt Amount={pptAmt}/></p>
                  </Col>
                  <Col span={4} style={{float:"right"}}>
                        <Button 
                        loading={loadings[1]}
                        style={style.refreshBtn}
                        type="primary"
                        onClick={() => {
                          approvePPTTrxn1V2(1);
                        }}
                        >
                          {btnApproveMemberText}
                        </Button>
                  </Col>     
              </Row>
              <Row style={{marginBottom:"10px"}}>
                    Transactions shown are 1 day before / after the Unclaimed / Bank Conflict "date time"
              </Row>
              <Table
                loading={trxLoading}
                columns ={memberTrxnColumns}
                dataSource= {filteredPptTrxn }
                scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                pagination={{
                  defaultPageSize: 5,
                  showSizeChanger: true,
                  pageSizeOptions: settings.pageSizeOptionsSmall,
                  style: style.paginationStyle,
                  alignmentBottom: 'right',
                }}
                />
              </>
              // ):""
            }
          </>
          </div>
      </Modal>
      <Modal
        title="Delay Deposit Form"
        visible={delayDepVisible}
        getContainer={false}
        cancelButtonProps={{ 
          type: "primary", 
          loading: formDelayDepLoading
        }}  
        okButtonProps={({
          style: style.hide
        })}
        cancelText={"Close"}
        onCancel= {() => {
          onCancelDelayDep();
        }}
    >
      {onDelayDepositCreateForm()}
    </Modal>
    </div>
  );
}