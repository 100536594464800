import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { companyReport } from "../../../config/networkConfig";
import {
    message,
    Table,
    PageHeader,
    DatePicker,
    Button,
    Breadcrumb,
    Statistic,
    Row,
    Card,
  } from "antd";
  import { isMobile } from "react-device-detect";
  import jsPDF from "jspdf";
  import "jspdf-autotable";
  import { Link } from "react-router-dom";
  import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
  import { FormatData,FormatPt } from "../../../library/numbers";
  import moment from "moment";
  import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";

export default function CompanyReport(){
    const { userId } = useSelector((state) => state.login);
    const [report, setReport] = useState("");
    const [filter, setFilter] = useState("");
    const [date, setDate] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [share, setShare] = useState(0);
    const reportDateFrom = useRef(null);
    const reportDateTo = useRef(null);

    useEffect(() => {
        //reportList();
        //reportShareDetailList();
        // eslint-disable-next-line
      }, []);



    const reportList = () => {
        var count = 0;
        if (!date) {
          alertData.title = "Error";
          alertData.msg = "Please select a Date";
          sharedErrorAlert(alertData);
          return;
        }

        companyReport({ userId: userId , datefrom: date.format("YYYY-MM-DD"), dateto: dateTo.format("YYYY-MM-DD")})
          .then((response) => response.json())
          .then((json) => {
            console.log("json -----", json);
            if (json.status === "ok") {
              const report = Object.keys(json.data).length
                ? json.data.map((data) => {
                    return {
                      key: ++count,
                      dateTime: data.dateTime,
                      userName: data.Username,
                      refererName:data.RefererName,
                      generation:data.Generation,
                      turnover: data.TotalTurnover,
                      position: data.PositionTaking,
                      selfTurnover:data.SelfTurnover,
                      selfEarning: data.SelfEarning,
                      refererTurnover: data.RefererTurnover,
                      refererEarning: data.RefererEarning,
                      share:data.TotalShares
                    };
                  })
                : [];
                   
              const filter = report.filter(
                (data) => data.dateTime // === report[0].dateTime
              );
        
              const getTotalShare = filter.map((data) => data.share);
              var shareTotal = getTotalShare.reduce(function (previous, current) {
                return previous + current;
              }, 0);
              setShare(shareTotal);
              setReport(report);
              setFilter(filter);
    
            } 
            else {
              alertData.title = "Failed";
              alertData.msg = "Connection not stable, please try again !";
              sharedFailAlert(alertData);
            }
          });
      };

    const columns = [
        {
            align: "center",
            title: "Date / Time",
            dataIndex: "dateTime",
            width: 60,
            ellipsis: true,
        },
        {
            align: "center",
            title: "Username",
            dataIndex: "userName",
            width: 50,
            ellipsis: true,
        },
        {
            align: "center",
            title: "Referer",
            dataIndex: "refererName",
            width: 50,
            ellipsis: true,
        },
        {
            align: "center",
            title: "Generation",
            dataIndex: "generation",
            width: 50,
            ellipsis: true,
            render:function(text,record,index) {
                return text===null? '-': text;
            }
        },
        {
            align: "center",
            title: "Turnover",
            dataIndex: "turnover",
            width: 50,
            ellipsis: true,
            render:function(text) {
                return FormatData(text);
            }
        },
        {
            align: "center",
            title: "PT (%)",
            dataIndex: "position",
            width: 50,
            ellipsis: true,
            render:function(text) {
                return FormatPt(text);
            }
        },
        {
            align: "center",
            title: "Self Turnover (-%)",
            dataIndex: "selfTurnover",
            width: 80,
            ellipsis: true,
            render:function(text) {
                return FormatData(text);
            }
        },
        {
            align: "center",
            title: "Self Earning",
            dataIndex: "selfEarning",
            width: 50,
            ellipsis: true,
            render:function(text) {
                return FormatData(text);
            }
        },
        {
            align: "center",
            title: "Referer Turnover(5%)",
            dataIndex: "refererTurnover",
            width: 80,
            ellipsis: true,
            render:function(text) {
                return FormatData(text);
            }
        },
        {
            align: "center",
            title: "Referer Earning",
            dataIndex: "refererEarning",
            width: 50,
            ellipsis: true,
            render:function(text,record,index) {
                return FormatData(text);
            }
        },
        {
            align: "center",
            title: "Share",
            dataIndex: "share",
            width: 50,
            ellipsis: true,
            render:function(text,record,index) {
                return FormatData(text);
            }
        },
    ];


    const mobileColumns = [
        {
          align: "center",
          title: "Data / Time",
          dataIndex: "dateTime",
          width: "50%",
          ellipsis: true,
        },
        {
          title: "Share",
          dataIndex: "share",
          width: "50%",
          ellipsis: true,
        },
      ];

    const exportPDF = () => {

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
    
        if (report.length == 0) {
          alertData.title = "Error";
          alertData.msg = "No data to download !";
          sharedErrorAlert(alertData);
          return;
        }
        
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);
    
        const title = `Shareholder Report (${date.format('YYYY-MM-DD')} to ${dateTo.format('YYYY-MM-DD')})\n\n\nShare Total : ${FormatData(share)}`;

        const headers = [
          [
            "ID",
            columns[0].title,
            columns[1].title,
            columns[2].title,
            columns[3].title,
            columns[4].title,
            columns[5].title,
            columns[6].title,
            columns[7].title,
            columns[8].title,
            columns[9].title,
            columns[10].title,
          ],
        ];   
    
        var filteredReport = [...filter];
         const data = filteredReport.map((data) => {
            return [
               data.key,
               data[columns[0].dataIndex],   
               data[columns[1].dataIndex],    
               data[columns[2].dataIndex],
               data[columns[3].dataIndex],
               FormatData(data[columns[4].dataIndex]),               
               FormatPt(data[columns[5].dataIndex]),
               FormatData(data[columns[6].dataIndex]),
               FormatData(data[columns[7].dataIndex]),
               FormatData(data[columns[8].dataIndex]),
               FormatData(data[columns[9].dataIndex]),
               FormatData(data[columns[10].dataIndex])
            ];
        });   
    
        let content = {
           startY: 150,
           head: headers,
           body: data,
           headStyles: { fillColor: "#1DA57A" },
        };
           
        doc.text(title, marginLeft, 40);

        doc.autoTable(content);    

        doc.save("companyReport.pdf");
    };

    const datePicker = (date, dateString) => {
        setDate(date);
    };

    const datePicker2 = (date, dateString) => {
        setDateTo(date);
    };
  
    const searchBar = () => {
      
      if (!date) {
        reportDateFrom.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Date From !";
        sharedErrorAlert(alertData);
        return;
      }
  
      if (!dateTo) {
        reportDateTo.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Date To !";
        sharedErrorAlert(alertData);
        return;
      }
      
      reportList();
    }

    const resetBtn = () => {
        setDate(null);
        setDateTo(null);
        setShare(0);
        setReport('');
        setFilter('');
        // reportList();
    };

    const routes = [
        {
          path: "/",
          breadcrumbName: "Dashboard",
        },
        {
          breadcrumbName: "Report",
        },
        {
          path: "companyReport",
          breadcrumbName: "Company Report",
        },
      ];
    
      const itemRender = (route, params, routes, paths) => {
        const last = routes.indexOf(route) === routes.length - 2;
        return last ? (
          <span>{route.breadcrumbName}</span>
        ) : (
          <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
        );
      };


    const styles = {
        tableContainer: {
          backgroundColor: "#FFFFFF",
          padding: 24,
        },
        paginationStyle: {
          marginTop: 50,
        },
        pageHearderContainer: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        },
        searchBtn: {
          marginLeft: 30,
        },
        resetBtn: {
          marginLeft: 10,
        },
        datePickerStyle: {
          width: 300,
          borderRadius: 10,
        },
        mobilePageHearderContainer: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        },
        eachButtonContainer: {
          alignItems: "center",
          display: "flex",
          height: 50,
          marginLeft: 15,
          marginRight: 15,
          justifyContent: "center",
        },
        mobileSearchResetBtn: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          height: 50,
          alignItems: "center",
          paddingLeft: 60,
          paddingRight: 60,
        },
        btnContainer: {
          width: "100vw",
          padding: 10,
        },
        pdfStyle: {
          borderRadius: 10,
          width: 1200,
        },
        searchResetStyle: {
          borderRadius: 10,
          marginLeft: 10,
        },
        decStyle: {
          display: "flex",
          flexDirection: "row",
        },
        decLable: {
          width: 200,
        },
        decContainer: {
          paddingLeft: 75,
        },
        webRow: {
          backgroundColor: "#FFFFFF",
          paddingTop: 50,
          paddingLeft: 30,
          paddingBottom: 50,
        },
        mobileRow: {
          backgroundColor: "#FFFFFF",
          paddingLeft: 30,
        },
        cardStyle: {
          borderRadius: 20,
          height: 160,
          width: 280,
          border: "none",
        },
        headStyles: {
          backgroundColor: "#e2f2f0",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          fontSize: 25,
          height: 80,
        },
        bodyStyle: {
          backgroundColor: "#e2f2f0",
          paddingLeft: 30,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
          justifyContent: "flex-start",
          display: "flex",
          height: 80,
        },
      };

    return (
        <div className="eachPageContent">
            {isMobile ? (
                <div>
                    <PageHeader ghost={false}>
                        <div>
                        <Breadcrumb
                        separator=">"
                        itemRender={itemRender}
                        routes={routes}
                        />
                    <div style={styles.btnContainer}>
                    <div style={styles.eachButtonContainer}>
                        <Button
                        type={"primary"}
                        onClick={exportPDF}
                        style={styles.pdfStyle}
                        >
                        Download PDF
                        </Button>
                    </div>
                    <div style={styles.eachButtonContainer}>
                        <DatePicker
                        style={styles.datePickerStyle}
                        onChange={datePicker}
                        value={date}
                        />

                        <DatePicker
                          style={styles.datePickerStyle}
                          onChange={datePicker}
                          value={date}
                          ref={reportDateFrom}
                          placeholder="Select Date From"
                          disabledDate={(current)=> {
                            return current > moment().subtract(1, 'day');
                          }}
                        />
                        <DatePicker
                          style={styles.datePickerStyle}
                          onChange={datePicker2}
                          value={dateTo}
                          ref={reportDateTo}
                          placeholder="Select Date To"
                          disabledDate={(current)=> {
                            let dateFrom = date;
                            if (dateFrom) {
                              return current < dateFrom || current > moment().subtract(1, 'day');
                            }else {
                              return  current > moment().subtract(1, 'day');
                            }
                          }}
                        />
                        <Button
                        type={"primary"}
                        onClick={searchBar}
                        style={styles.searchResetStyle}
                        icon={<SearchOutlined />}
                        />

                        <Button
                        onClick={resetBtn}
                        style={styles.searchResetStyle}
                        icon={<UndoOutlined />}
                        />
                    </div>
                </div>
                <Row style={styles.mobileRow}>
                <Card
                    title="Total Share"
                    style={styles.cardStyle}
                    headStyle={styles.headStyles}
                    bodyStyle={styles.bodyStyle}
                >
                    <Statistic
                    value={FormatData(share)}
                    valueStyle={{ fontSize: 40 }}
                    />
                </Card>
                </Row>
                <div style={styles.tableContainer}>
                <Table
                    columns={mobileColumns}
                    dataSource={filter}
                    expandable={{
                    expandedRowRender: (record) => (
                        <p style={{ margin: 0 }}>{record.description}</p>
                    ),
                    rowExpandable: (record) => record.name !== "Not Expandable",
                    }}
                    pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20"],
                    style: styles.paginationStyle,
                    }}
                />
                </div>
            </div>
                    </PageHeader>
                </div>
            ):
            (
               <div>
                    <PageHeader
                        ghost={false}
                        title="Company Report"
                        extra={[
                          <DatePicker
                            key="1"
                            style={styles.datePickerStyle}
                            onChange={datePicker}
                            value={date}
                            ref={reportDateFrom}
                            placeholder="Select Date From"
                            disabledDate={(current)=> {
                              return current > moment().subtract(1, 'day');
                              //return current > moment().endOf('day');
                            }}
                          />,
                          <DatePicker
                            style={styles.datePickerStyle}
                            onChange={datePicker2}
                            value={dateTo}
                            ref={reportDateTo}
                            placeholder="Select Date To"
                            disabledDate={(current)=> {
                              let dateFrom = date;
                              if (dateFrom) {
                                return current < dateFrom || current > moment().subtract(1, 'day');
                              }else {
                                return  current > moment().subtract(1, 'day');
                              }
                            }}
                        />,
                        <Button
                            key="2"
                            type={"primary"}
                            style={styles.searchBtn}
                            onClick={searchBar}
                        >
                            Search
                        </Button>,
                        <Button key="3" style={styles.resetBtn} onClick={resetBtn}>
                            Reset
                        </Button>,
                        <Button key="4" type={"primary"} onClick={exportPDF}>
                            Download PDF
                        </Button>,
                        ]}
                    />
                    <Row style={styles.webRow}>
                        <Card
                        title="Total Share"
                        style={styles.cardStyle}
                        headStyle={styles.headStyles}
                        bodyStyle={styles.bodyStyle}
                        >
                        <Statistic
                            // precision={settings.decimalScale}
                            value={FormatData(share)}
                            valueStyle={{ fontSize: 40 }}
                        />
                        </Card>
                    </Row>                     
                    <div style={styles.tableContainer}>
                        <Table
                        columns={columns}
                        dataSource={report}
                        scroll={{ x: 100, y: null }}
                        pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: ["5", "10", "20"],
                            style: styles.paginationStyle,
                        }}
                        />
                    </div>
               </div>
            )}
        </div>
    );
}