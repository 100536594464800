import React from "react";
import { settings } from "../../../../config/appConfig";
import { Table } from "ant-table-extensions";
import { isMobile } from "react-device-detect";
import { FormatPt } from "../../../../library/numbers";
import {
  FileExcelOutlined,
} from "@ant-design/icons";

const styles = {
  paginationStyle: {
    marginTop: 50,
  },
}

let columns =[{}] , pagination;

let withdrawTrxnColumns = [
  {
    align: "center",
    title: "Date Time",
    dataIndex:"date",
    width: "11%",
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
    render:(text) => { return text; }
  },
  {
    align: "center",
    title: "Bank",
    dataIndex:"frombankname",
    render:(text) => { return text; }
  },
  {
    align: "center",
    title: "Account Number",
    dataIndex:"fromacctnum",
    render:(text) => { return text; }
  },
  {
    align: "center",
    title: "Account Name",
    dataIndex:"fromacctname",
    render:(text) => { return text; }
  },
  {
    align: "center",
    title: "Amount",
    dataIndex:"amount",
    render:(text) => { return FormatPt(text); }
  },
]

let transferColumns = [
  {
    align: "center",
    title: "Date Time",
    dataIndex:"date",
    width: "15%",
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
    render:(text) => { return (text) }
  },
  {
    align: "center",
    title: "Bank Name",
    dataIndex:"bankname",
    width: "15%",
    render:(text) => { return (text) }
  },
  {
    align: "center",
    title: "Account Number",
    dataIndex:"acctnum",
    width: "15%",
    render:(text) => { return (text) }
  },
  {
    align: "center",
    title: "Account Name",
    dataIndex:"acctname",
    width: "15%",
    render:(text) => { return (text) }
  },
  {
    align: "center",
    title: "Transaction Type",
    dataIndex:"trxntype",
    width: "25%",
    render:(text) => { return (text) }
  },
  {
    align: "center",
    title: "Amount",
    dataIndex:"balance",
    width: "15%",
    render:(text) => { return (FormatPt(text)) }
  },
]

let trxnColumns = [
  {
    align: "center",
    title: "Date Time",
    dataIndex:"date",
    width: "15%",
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
    render:(text) => { return (text) }
  },
  {
    align: "center",
    title: "Mobile Number",
    dataIndex:"mobile",
    width: "15%",
    render:(text) => { return (text) }
  },
  {
    align: "center",
    title: "Transaction Type",
    dataIndex:"trxntype",
    width: "10%",
    render:(text) => { return (text) }
  },
  {
    align: "center",
    title: "Transaction Mode",
    dataIndex:"trxnmode",
    width: "15%",
    render:(text) => { return (text) }
  },
  {
    align: "center",
    title: "Amount",
    dataIndex:"amount",
    width: "10%",
    render:(text) => { return (FormatPt(text)) }
  },
  {
    align: "center",
    title: "From Bank Name",
    dataIndex:"frombankname",
    width: "10%",
    render:(text) => { return (text) }
  },
  {
    align: "center",
    title: "From Account Number",
    dataIndex:"frombankacctnum",
    width: "10%",
    render:(text) => { return (text) }
  },
  {
    align: "center",
    title: "From Account Name",
    dataIndex:"frombankacctname",
    width: "10%",
    render:(text) => { return (text) }
  },
]



export const CTable = (props) => {

    let exportEnable = (!props.exportEnable)?false: props.exportEnable;

    if (props.columnSource === "withdrawTrxn") {
      columns = withdrawTrxnColumns;
    } else if (props.columnSource === "transferList") {
      columns = transferColumns;
    } else if (props.columnSource === "trxnList") {
      columns = trxnColumns;
    }

    if (!props.paginate) {
      pagination = {
        defaultPageSize: 5,
        showSizeChanger: true,
        pageSizeOptions: settings.pageSizeOptions,
        style: styles.paginationStyle,
        alignmentBottom: 'right',
      };
    }else{
      pagination = props.paginate;
    }

    return (
        !exportEnable ?
        (<>
         <Table
          loading={props.loading}
          columnSource={props.columnSource}
          columns ={columns}
          dataSource= {props.data}
          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
          pagination={pagination}
        /></>):
        (
          <Table
          loading={props.loading}
          columnSource={props.columnSource}
          columns ={columns}
          dataSource= {props.data}
          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
          pagination={pagination}
          // style={{display:"none"}}
          exportableProps={{
            columns,
            btnProps: {
              type: "primary",
              icon: <FileExcelOutlined />,
              id:`${props.exportBtnId}`,
              children: <span>`${props.exportLabel}`</span>,
              style: {display:"none"}
            },
          }}/>
        )
    )
}