import React, { useState, useEffect } from "react";
import {
  Table,
  Form,
  Button,
  PageHeader,
  Modal,
  Input,
  InputNumber,
  Spin,
  Select,
  Badge,
  Radio
} from "antd";
import Highlighter from "react-highlight-words";
import { QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { 
  verifyValidMobile,
  agentTransferCredit,
  getUserWalletBal,
  depositList
} from "../../config/networkConfig";
import { TRANSACTION_TYPE } from "../../common/constants/processType";
import { isMobile } from "react-device-detect";
import { settings } from "../../config/appConfig";
import { useSelector } from "react-redux";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";

export default function TransferCreditByMobileNo() {
const { Option } = Select;  
const { confirm } = Modal;
const { userId } = useSelector((state) => state.login);

const [originData, setOriginData] = useState([]);
const [data, setData] = useState([]);
const [tableLoading, setTableLoading] = useState(false);

const [formCreditTransferByMobileNo] = Form.useForm();
const [userWalletBalance, setUserWalletBalance] = useState(0);
const [transferCreditVisible, setTransferCreditVisible] = useState(false);
const [createSubmit, setCreateSubmit] = useState(true);
const [createTransferCreditDisable, setCreateTransferCreditDisable] = useState(true);
const [createTransferCreditLoading, setCreateTransferCreditLoading] = useState(false);

const [transferCreditAmt, setTransferCreditAmt] = useState(0);
const [countryCode, setCountryCode] = useState("856");
const [phoneNum, setPhoneNum] = useState("");

const [filter, setFilter] = useState(TRANSACTION_TYPE.TRANSFER_OUT);
const [searchText, setSearchText] = useState("");

useEffect(() => {
  transferCreditHistory();
  retrieveUserBal();
}, []);

useEffect(() => {
  transferCreditHistory();
}, [filter]);

useEffect(() => {
  setCountryCode(countryCode);
}, [countryCode]);

const retrieveUserBal = async () => {
  getUserWalletBal({userId: userId})
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          setUserWalletBalance(json.data['userBal']);
        }
    })
    .catch((error) => {
      console.log("error: ", error);
      alertData.title = "Error";
      alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
      sharedErrorAlert(alertData);
    });
};

const transferCreditHistory = () => {
  var count = 0;
  setTableLoading(true);
  let requestData = {
    userId : userId,
    trxnType : [`${TRANSACTION_TYPE.DEP}`, `${TRANSACTION_TYPE.TRANSFER_IN}`, `${TRANSACTION_TYPE.TRANSFER_OUT}`],
    maxRecord : "2000"
  }
  
  depositList(requestData)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === "ok") {
        const newData = Object.keys(json.data).length
        ? json.data.map((x) => {
            setTableLoading(false);
            return {
              key: count++,
              sysCreateTime: x.dateTime,
              trxnType: x.trxnType,
              sender:
                x.trxnType == TRANSACTION_TYPE.TRANSFER_OUT
                ? "-"
                : x.trxnType == TRANSACTION_TYPE.TRANSFER_IN
                ? x.transferFrom
                : '-',
              receiver: 
                x.trxnType == TRANSACTION_TYPE.TRANSFER_OUT
                ? x.transferTo 
                : '-',
              amt: x.amount.toFixed(2),
              actionStatus:
              x.status === 0
                ? "Pending"
                : x.status === 1
                ? "Completed"
                : x.status === 9
                ? "Rejected"
                : "-",
            };
              })
          : [];

          setOriginData(newData);

          const topUp = newData.filter((val) => {
            return val.trxnType === "Topup";
          });
          const transferIn = newData.filter((val) => {
            return val.trxnType === TRANSACTION_TYPE.TRANSFER_IN;
          });
          const transferOut = newData.filter((val) => {
            return val.trxnType === TRANSACTION_TYPE.TRANSFER_OUT;
          });

          const sortData =
              filter === "Topup"
              ? topUp
              : filter === TRANSACTION_TYPE.TRANSFER_IN
              ? transferIn
              : filter === TRANSACTION_TYPE.TRANSFER_OUT
              ? transferOut
              : [...newData];

          setData(sortData);

        }
        else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
      }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
}

const onSubmitForm = () => {
  if (!phoneNum){
    setCreateSubmit(true);
    alertData.title = "Field Required";
    alertData.msg = "Please fill in Phone Number !";
    sharedErrorAlert(alertData);
    return;
  }

  confirm({
    className: "confirmationModalStyle",
    title: 'Confirmation',
    centered: true,
    icon: <QuestionCircleOutlined />,
    content: 'Are you sure you want to proceed?',
    onOk() {
      console.log('OK');
      setCreateTransferCreditLoading(true);
      onSubmitTransferCredit();
    },
    onCancel() {},
  });
  };

  const onSubmitTransferCredit = async () => {
    let requestData = {
      userid : userId,
      mobile : countryCode+phoneNum,
      amount : transferCreditAmt
    }
  
    const response = await agentTransferCredit(requestData);
    let result = await response.json();
    if (result.status == 'ok'){
      onCancelTransferCredit();
      setCreateTransferCreditLoading(false);
      alertData.title = "Success";
      alertData.msg = result.msg;
      sharedSuccessAlert(alertData);
    }
    else{
      setCreateSubmit(true);
      setCreateTransferCreditLoading(false);
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
    }
  }

const selectCountryCode = (value) => {
  setCountryCode(value);
};

const validateUserPhone = async(phoneNum)=> {
  const response = await verifyValidMobile({mobile: countryCode+phoneNum});
  let result = await response.json();

  if (result.status === "ok") {}
  else {
    alertData.title = "Error";
    alertData.msg = result.msg;
    sharedErrorAlert(alertData);
  }
}

const handleChangeTransferCreditAmt = (value) => {
  setTransferCreditAmt(value);
  setCreateTransferCreditDisable(false);
}

const onCancelTransferCredit = () => {
  setPhoneNum("");
  setCountryCode("856");
  setTransferCreditAmt(0);
  setCreateTransferCreditDisable(true);
  setCreateSubmit(true);
  formCreditTransferByMobileNo.resetFields();
  setTransferCreditVisible(false);
}

const onCreateTransferCreditFormByMobileNo = () => {
    return (
      <div>
        <div>
          <Form.Item
            label="User Credit Balance"
          >
            {userWalletBalance}
          </Form.Item>

          <Form form={formCreditTransferByMobileNo} layout="vertical" name="creditTransferForm">
            {/* Member Mobile */}
            <Form.Item name="memberMobileNo" label="Phone Number" required={true}>
              <Input.Group compact>
                <Select value={countryCode}
                  onChange={selectCountryCode}>
                  <Option value="856">+856</Option>
                  <Option value="66">+66</Option>
                  <Option value="60">+60</Option>
                </Select>
                <Input style={{width:"66%"}} defaultValue="" placeholder="phone number" value={phoneNum}
                  onChange={(e) => {
                    setPhoneNum(e.target.value.replace(/\D|^0/g, ""));
                  }}
                  onBlur={(e) => {
                    validateUserPhone(e.target.value.replace(/\D|^0/g, ""));
                  }}
                />
              </Input.Group>
              
            </Form.Item>
            {/* Amount */}
            <Form.Item
              name="amt"
              label="Amount"
              required={true}
            >
              <InputNumber
                min={1}
                type="number"
                value={transferCreditAmt}
                onChange={handleChangeTransferCreditAmt}
              />
            </Form.Item>
            { createSubmit?
              <Button 
                type="primary" 
                onClick={onSubmitForm} 
                disabled={createTransferCreditDisable}
                loading={createTransferCreditLoading}>
                Submit
              </Button>: <Spin style={{margin:7}}></Spin>
            } 
          </Form>
        </div>
      </div>
    );
  };

let searchInput = null;

const handleSearch = (selectedKeys, confirm) => {
  confirm();
  setSearchText(selectedKeys[0]);
};

const handleReset = (clearFilters) => {
  clearFilters();
  setSearchText("");
};
  
const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          searchInput = node;
        }}
        placeholder={`Search ${dataIndex=="newNickname"? "nickname":dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Button
        type="primary"
        onClick={() => handleSearch(selectedKeys, confirm)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button
        onClick={() => handleReset(clearFilters)}
        size="small"
        style={{ width: 90 }}
      >
        Reset
      </Button>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      : false,
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) setTimeout(() => searchInput.select());
  },
  render: (text) =>
    text ? (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      false
    ),
});

const tableColumn = [
  {
    align: "center",
    title: "Date / Time",
    dataIndex: "sysCreateTime",
    width: "20%",
    ellipsis: true,
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
    fixed:"left",
  },
  {
    align: "center",
    title: "Trxn Type",
    dataIndex: "trxnType",
    width: "15%",
    ellipsis: true,
    fixed:"left",
    ...getColumnSearchProps("trxnType"),
  },
  {
    align: "center",
    title: "Sender (Mobile No)",
    dataIndex: "sender",
    width: "20%",
    ellipsis: true,
    fixed:"left",
    hidden: 
      filter == TRANSACTION_TYPE.TRANSFER_OUT
      ? true 
      : filter == "Topup"
      ? true
      : false,
    ...getColumnSearchProps("sender"),
  },
  {
    align: "center",
    title: "Receiver (Mobile No)",
    dataIndex: "receiver",
    width: "20%",
    ellipsis: true,
    fixed:"left",
    hidden: 
      filter == TRANSACTION_TYPE.TRANSFER_IN
      ? true 
      : filter == "Topup"
      ? true
      : false,
    ...getColumnSearchProps("receiver"),
  },
  {
    align: "center",
    title: "Amount",
    dataIndex: "amt",
    width: "10%",
    ellipsis: true,
    fixed:"left",
    ...getColumnSearchProps("amt"),
  },
  {
    ellipsis: true,
    align: "center",
    title: "Status",
    dataIndex: "action",
    width: "15%",
    render: (text, record) => {
      switch (record.actionStatus) {
      case "Pending":
        return <Badge status="processing" text="Pending" />;

      case "Rejected":
        return <Badge status="error" text="Rejected" />;

      case "Completed":
        return <Badge status="success" text="Completed" />;

      default:
          return <></>;
      }
  },
  }
].filter(item => !item.hidden);

const styles = {
  pageHeaderContainer: {
    justifyContent: isMobile ? "" : "space-between",
    display: "flex",
    flexDirection: isMobile ? "column" : "",
    alignItems: isMobile ? "center" : "",
  },
  refreshBtn: {
    marginLeft: 10,
  },
  tableContainer: {
    backgroundColor: "#FFFFFF",
    padding: 24,
  },
  paginationStyle: {
    marginTop: 50,
  },

  hide :{
    display:"none",
  }
};

return (
  <div className="TransferCreditByMobileNo">
    <PageHeader ghost={false}>
      <div style={styles.pageHeaderContainer}>
        <div>
          <Button
            style={styles.refreshBtn}
            type="primary"
            onClick={() => {
              transferCreditHistory();
            }}
          >
            Refresh Page
          </Button>

          <Button
          type="primary"
          style={styles.refreshBtn}
          onClick={() => {
              setTransferCreditVisible(true);
              retrieveUserBal();
          }}
          >
          Create Transfer Credit
          </Button>
        </div>
        <Radio.Group
          buttonStyle={"solid"}
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        >
          <Radio.Button value="All">All</Radio.Button>
          <Radio.Button value="Topup">Topup</Radio.Button>
          <Radio.Button value="Transfer-In">Transfer-In</Radio.Button>
          <Radio.Button value="Transfer-Out">Transfer-Out</Radio.Button>
        </Radio.Group>
      </div>
    </PageHeader>

    <div style={styles.tableContainer}>
      <Table
        loading={tableLoading}
        columns={tableColumn}
        dataSource={data}
        scroll={{ x: isMobile ? "110%" : "100%", y: null }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: settings.pageSizeOptions,
          style: styles.paginationStyle,
          alignmentBottom: 'right',
        }}
      >
      </Table>
    </div>

    <Modal
      title="Transfer Credit Form"
      visible={transferCreditVisible}
      getContainer={false}  
      onCancel= {() => {
        onCancelTransferCredit();
      }}
      okButtonProps={({style: styles.hide})}
      cancelButtonProps={{ type: "primary", loading: createTransferCreditLoading}}   
      cancelText="Close"
    >
      { onCreateTransferCreditFormByMobileNo() }
    </Modal>

  </div>
  );
}