import React, { useEffect, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Form,
  InputNumber,
  Button,
  Spin,
  Select,
  Space,
  Divider,
} from "antd";
import { useSelector } from "react-redux";
import {
  getCommSettingProfile,
  updateCommSettingProfile
} from "../../config/networkConfig";
import { COMM_WIN_LOSE, COMM_TURNOVER, INCENTIVE_WL, INCENTIVE_ALL_DEP, INCENTIVE_FIRST_DEP, } from "../../common/constants/constant";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";
import { Typography } from 'antd';

const { Title } = Typography;

export default function AdminstrationPage() {
  const { Option } = Select;
  const { userId, companyId } = useSelector((state) => state.login);
  const [form] = Form.useForm();
  const [playerAffiliate, setPlayerAffiliate] = useState([]);
  const [marketAffiliateIncentiveType, setMarketAffiliateIncentiveType] = useState("");
  const [marketIncentivePercent, setMarketIncentivePercent] = useState("");
  const [crmAffiliateIncentiveType, setCrmAffiliateIncentiveType] = useState("");
  const [crmIncentivePercent, setCrmIncentivePercent] = useState("");
  const [spinLoading, setSpinLoading] = useState(true);
  
  const tierList = [
    {display: 'Tier 1', value: 'L1' },
    {display: 'Tier 2', value: 'L2' },
    {display: 'Tier 3', value: 'L3' },
    {display: 'Tier 4', value: 'L4' },
    {display: 'Tier 5', value: 'L5' },  
    {display: 'Tier 6', value: 'L6' },
    {display: 'Tier 7', value: 'L7' },
    {display: 'Tier 8', value: 'L8' },
    {display: 'Tier 9', value: 'L9' },
    {display: 'Tier 10', value: 'L10' },
  ];

  useEffect(() => {
    loadCompanyCommSettingData();
  }, []);

  const loadCompanyCommSettingData = async() => {
    let initialPlayerTier = '', playerTierArr = [], initialMarketTier = '', marketTierArr = [], finalPlayerRebate = '';
    let requestData = {
      companyID : userId,
      columnRequire : ['CommSetAffiliate as playerTier', 'CommSetAgent as marketTier', 'CommSetAgentType as marketIncentiveType','CommCrmType as crmIncentiveType','CommSetAgentPercent as marketIncentivePercent','CommCrmPercent as crmIncentivePercent']
    };

    const response = await getCommSettingProfile(requestData);
    let result = await response.json();
    if (result.status === "ok") {
      if (result.data[0].playerRebate != '') {
        finalPlayerRebate = String(result.data[0].playerRebate).split(':');
      }

      if (result.data[0].playerTier != '') {
        initialPlayerTier = result.data[0].playerTier.replace(/\s+/g, ''); // clear space
        initialPlayerTier = initialPlayerTier.split('###');
        let arrangePlayerTier = initialPlayerTier.map((item, index) => (
          item = item.split(':'),
          playerTierArr.push({'playerTier_tier' : item[0] , 'playerTier_percentage' : item[1]})
        ))
      }

      if (result.data[0].marketTier != '') {
        initialMarketTier = result.data[0].marketTier.replace(/\s+/g, ''); // clear space
        initialMarketTier = initialMarketTier.split('###');
        let arrangeMarketTier = initialMarketTier.map((item, index) => (
          item = item.split(':'),
          marketTierArr.push({'marketTier_tier' : item[0] , 'marketTier_percentage' : item[1]})
        ))
      }

      setMarketAffiliateIncentiveType(result.data[0].marketIncentiveType)
      setCrmAffiliateIncentiveType(result.data[0].crmIncentiveType)

      form.setFieldsValue({
        playerTier : playerTierArr,
        marketAffiliateIncentiveType : result.data[0].marketIncentiveType,
        marketTier : marketTierArr,
        marketIncentivePercent : Number(result.data[0].marketIncentivePercent).toFixed(2),
        crmAffiliateIncentiveType : result.data[0].crmIncentiveType,
        crmIncentivePercent : Number(result.data[0].crmIncentivePercent).toFixed(2),
      });

      setPlayerAffiliate(playerTierArr);
      setSpinLoading(false);
    }
    else {
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
      setSpinLoading(false);
    }
  }

  const onFinish = async (values) => {
    setSpinLoading(true);

    let requestData = {
      companyID : userId,
    }

    let finalPlayerRebate = 0, playerAffiliate_total = 0, marketAffiliate_total = 0, finalPlayerAffiliate = '', finalMarketAffiliate = '';
    // Checking for player rebate
    if (values.playerRebate >= 0) {
      finalPlayerRebate = 'L0:' + Number(values.playerRebate).toFixed(2) + ':' + `${COMM_TURNOVER}`; 
    }

    // Checking for player affiliate
    if (values.playerTier != undefined && values.playerTier.length > 0){
      for (let i = 0; i < values.playerTier.length; i++) {
        playerAffiliate_total += Number(values.playerTier[i].playerTier_percentage);
        finalPlayerAffiliate += values.playerTier[i].playerTier_tier + ':' + Number(values.playerTier[i].playerTier_percentage).toFixed(2) + `:${COMM_WIN_LOSE} ### `;
      }
        finalPlayerAffiliate = finalPlayerAffiliate.slice(0,-5);
        requestData.playerAffiliate = finalPlayerAffiliate;
    }

    // Checking for market affiliate
    if (values.marketTier != undefined && values.marketTier.length > 0){
      for (let i = 0; i < values.marketTier.length; i++) {
        marketAffiliate_total += Number(values.marketTier[i].marketTier_percentage);
        finalMarketAffiliate += values.marketTier[i].marketTier_tier + ':' + Number(values.marketTier[i].marketTier_percentage).toFixed(2) + `:${COMM_WIN_LOSE} ### `;
      }
        finalMarketAffiliate = finalMarketAffiliate.slice(0,-5);
        requestData.marketAffiliate = finalMarketAffiliate;
    }

    if (playerAffiliate_total > 100 || marketAffiliate_total > 100) {
      alertData.title = "Percentage Exceed";
      alertData.msg = playerAffiliate_total > 100 ? "Player Affiliate percentage cannot allocate more than 100% !" : "Market Affiliate percentage cannot allocate more than 100% !"  ;
      sharedErrorAlert(alertData);
      setSpinLoading(false);
      return;
    }

    if (values.marketAffiliateIncentiveType == '') {
      alertData.title = "Field Required";
      alertData.msg = "Please select Market Affiliate Incentive Type !" ;
      sharedErrorAlert(alertData);
      setSpinLoading(false);
      return;
    }
    else {
      requestData.marketAffiliateIncentiveType = values.marketAffiliateIncentiveType;
    }

    if (values.marketIncentivePercent >= 0) {
      requestData.marketIncentivePercent = Number(values.marketIncentivePercent).toFixed(2);
    }

    if (values.crmAffiliateIncentiveType == '') {
      alertData.title = "Field Required";
      alertData.msg = "Please select CRM Affiliate Incentive Type !"   ;
      sharedErrorAlert(alertData);
      setSpinLoading(false);
      return;
    }
    else {
      requestData.crmAffiliateIncentiveType = values.crmAffiliateIncentiveType;
    }

    if (values.crmIncentivePercent >= 0) {
      requestData.crmIncentivePercent = Number(values.crmIncentivePercent).toFixed(2);
    }

    const response = await updateCommSettingProfile(requestData);
    let result = await response.json();

    if (result.status == "ok") {
      alertData.title = "Success";
      alertData.msg = "Affiliate Setting is updated.";
      sharedSuccessAlert(alertData);
      setSpinLoading(false);
      loadCompanyCommSettingData();
      setMarketAffiliateIncentiveType("");
    }
    else {
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
      setSpinLoading(false);
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: {
      span: 6,
      },
      sm: {
      span: 6,
      },
    },
    wrapperCol: {
      xs: {
      span: 24,
      },
      sm: {
      span: 8,
      },
    },
  };

  return (
    <Spin 
      spinning={spinLoading}
      size="large"
    >
      <Form
        {...formItemLayout}
        onFinish={onFinish}
        form={form}
        initialValues={{
          playerTier: playerAffiliate,
        }}
      >
          <Form.Item
            // style={{align:"center"}}
          >
            <Title level={4}>Player Affiliate Setting</Title>
          </Form.Item>

          <Form.Item
              name={["user", "playerAffiliate"]}
              label="Player Affiliate"
          >
              <Form.List 
                name="playerTier"
                >
                {(fields, { add, remove }) => (
                    <>
                    {fields.map((field) => (
                        <Space key={field.key} align="baseline">
                          <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, curValues) =>
                                  // prevValues.playerTier_percentage !== curValues.playerTier_percentage || prevValues.playerTier_percentage !== curValues.tier
                                  prevValues.area !== curValues.area || prevValues.tier !== curValues.tier
                              }
                              key={field.key}
                          >
                            {() => (
                            <Form.Item
                                {...field}
                                label="Tier"
                                name={[field.name, 'playerTier_tier']}
                                required
                                rules={[
                                {
                                    required: true,
                                    message: 'Missing tier !',
                                },
                                ]}
                            >
                                <Select
                                    style={{
                                        width: 130,
                                    }}
                                >
                                    {(tierList || []).map((item) => (
                                      <Option key={item.display} value={item.value}>
                                        {item.display}
                                      </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            // <Form.Item
                            //     {...field}
                            //     label="Tier"
                            //     name={[field.name, 'playerTier_tier']}
                            // >
                            //     <Input
                            //       style={{
                            //           width: 130,
                            //       }}
                            // />
                            // </Form.Item>
                            // <Form.Item
                            //     {...field}
                            //     label="Percentage"
                            //     name={[field.name, 'playerTier_percentage']}
                            //     // name={"playerAffiliate"}
                            //     required
                            //     rules={[
                            //       {
                            //         required: true,
                            //         message: 'Missing percentage !',
                            //       },
                            //     ]}
                            // >
                            //     {/* <InputNumber */}
                            //     <Input
                            //         style={{
                            //             width: 130,
                            //         }}
                            //         onChange={(e) => {
                            //              console.log(`onChange`,playerAffiliate)
                            //         }}
                            //         value={playerAffiliate}
                            //     />
                            // </Form.Item>
                            )}
                            </Form.Item>
                            <Form.Item
                            {...field}
                            label="Percentage (%)"
                            name={[field.name, 'playerTier_percentage']}
                            // name='playerTier_percentage'
                            required
                            rules={[
                              {
                                  required: true,
                                  message: 'Missing percentage !',
                              },
                            ]}
                          >
                            <InputNumber
                                min={0}
                                max={100}
                                style={{
                                  width: 130,
                                }}
                            />
                          </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                        </Space>
                    ))}
                    
                    <Form.Item>
                        <Button type="dashed" 
                            style={{
                                width: 500,
                            }}
                            onClick={() => 
                                {
                                  if(fields.length < 10) {
                                    add();
                                  }
                                  else {
                                    alertData.title = "Limit Reached";
                                    alertData.msg = "Maximum 10 player tier only !";
                                    sharedErrorAlert(alertData);
                                    return;
                                  }
                                }
                        } block icon={<PlusOutlined />}>
                          Add tier
                        </Button>
                    </Form.Item>
                    </>
                )}
              </Form.List>
          </Form.Item>

          <Divider></Divider>

          <Form.Item
            style={{align:"center"}}
          >
            <Title level={4}>Market Affiliate Setting</Title>
          </Form.Item>

          <Form.Item
            name="marketAffiliateIncentiveType"
            label="Market Affiliate Type"
          >
            <Select
              value={marketAffiliateIncentiveType}
              onChange={(e) => {
                setMarketAffiliateIncentiveType(e);
              }}
              style={{
                width: 500,
              }}
            >
              <Option value={INCENTIVE_WL}>Win Loss</Option>
              <Option value={INCENTIVE_FIRST_DEP}>Incentive - First Deposit</Option>
              <Option value={INCENTIVE_ALL_DEP}>Incentive - All Deposit</Option>
            </Select>
          </Form.Item>
          
          { marketAffiliateIncentiveType == INCENTIVE_WL ?
          <Form.Item
            name={["user", "marketAffiliate"]}
            label="Market Affiliate"
          >
            <Form.List 
              name="marketTier"
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Space key={field.key} align="baseline">
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.area !== curValues.area || prevValues.tier !== curValues.tier
                          
                        }
                      >
                        {() => (
                          <Form.Item
                            {...field}
                            label="Tier"
                            name={[field.name, 'marketTier_tier']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing tier !',
                              },
                            ]}
                          >
                            <Select
                              style={{
                                width: 145,
                              }}
                            >
                              {(tierList || []).map((item) => (
                                <Option key={item.display} value={item.value}>
                                  {item.display}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label="Percentage (%)"
                        name={[field.name, 'marketTier_percentage']}
                        rules={[
                          {
                            required: true,
                            message: 'Missing percentage !',
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          max={100}
                          style={{
                            width: 145,
                          }}/>
                      </Form.Item>

                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button 
                      style={{
                          width: 500,
                      }}
                      type="dashed" 
                      onClick={() => 
                          {
                          if(fields.length<10) {
                              add();
                          }
                          else{
                              alertData.title = "Limit Reached";
                              alertData.msg = "Maximum 10 market tier only !";
                              sharedErrorAlert(alertData);
                              return;
                          }
                          }
                      } block icon={<PlusOutlined />}>
                      Add tier
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          :
          <Form.Item
            name="marketIncentivePercent"
            label="Market Incentive (%)"
          >
            <InputNumber
              value={marketIncentivePercent}
              min={0}
              max={100}
              style={{
                width: 500
              }}
              placeholder="E.g. 0.00% - 100.00%"
              onChange={(e) => {
                setMarketIncentivePercent(e);
              }}
            />
          </Form.Item>
          }

          <Form.Item
            style={{align:"center"}}
          >
            <Title level={4}>CRM Affiliate Setting</Title>
          </Form.Item>

          <Form.Item
            name="crmAffiliateIncentiveType"
            label="CRM Affiliate Type"
          >
            <Select
              value={crmAffiliateIncentiveType}
              onChange={(e) => {
                setCrmAffiliateIncentiveType(e);
              }}
              style={{
                width: 500,
              }}
            >
              <Option value={INCENTIVE_WL}>Win Loss</Option>
              <Option value={INCENTIVE_FIRST_DEP}>Incentive - First Deposit</Option>
              <Option value={INCENTIVE_ALL_DEP}>Incentive - All Deposit</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="crmIncentivePercent"
            label="CRM Incentive (%)"
          >
            <InputNumber
              value={crmIncentivePercent}
              min={0}
              max={100}
              style={{
                width: 500
              }}
              placeholder="E.g. 0.00% - 100.00%"
              onChange={(e) => {
                setCrmIncentivePercent(e);
              }}
            />
          </Form.Item>


      <Form.Item 
        label=" " 
        colon={false}
        style={{
          textAlign:"center"
        }}
        >
          <Button 
            type="primary" 
            htmlType="submit"
            >
              Submit
          </Button>
      </Form.Item>
  </Form>
  </Spin>
)
}