import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Form,
  Button,
  Modal,
  Badge,
  PageHeader,
  message,
  Select,
  InputNumber,
  Popconfirm,
  Divider,
  Space,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  createSystemUserV2,
  userShareholderList,
  userShareholderListV2,
  userManageUpdate,
  userManageUpdateV2,
  verifyValidMobile,
  getCompanyProfileData,
  getUserData,
  getUserCommSharePT,
  checkUsernameRegisteredV2
} from "../../config/networkConfig";
import md5 from "md5";
import { useSelector } from "react-redux";
import { USER_TYPE_SHAREHOLDER } from "../../common/constants/constant";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = dataIndex == 'nickname' ? <Input allowClear={true} /> 
                  : dataIndex == 'mobilenumber' ? <InputNumber maxLength={15} style={{width:"170px"}}/>
                  : dataIndex == 'pin' ? <InputNumber maxLength={6} style={{width:"100px"}}/>
                  :<></>;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `${title} cannot be empty !`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default function DownlineManagementV2() {
  const { Option } = Select;
  const [form] = Form.useForm();
  const modalForm = useRef();
  const [originData, setOriginData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [username, setUsername] = useState("");
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const md5Pass = md5(password);
  const [searchText, setSearchText] = useState("");
  const [addVisible, setAddVisible] = useState(false);
  const { userId, companyId, compCode } = useSelector((state) => state.login);
  const [tableLoading, setTableLoading] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [countryCode, setCountryCode] = useState("856");
  const [mobilenumber, setMobileNumber] = useState("");
  const [lineId, setLineId] = useState("");
  const [createMemberDisable, setCreateMemberDisable] = useState(true);
  const [cancelDisable, setCancelDisable] = useState(false);
  const [pin,setPin] = useState("");
  const [companyCountryCode, setCompanyCountryCode] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [customFee, setCustomFee] = useState(0);
  const [uplineShareholder, setUplineShareholder] = useState("");
  const [profitSharingLimit, setProfitSharingLimit] = useState(0);
  const [userGeneration, setUserGeneration] = useState(0);
  const [profitSharing, setProfitSharing] = useState(0);
  const [createButtonLoading, setCreateButtonLoading] = useState(false);
  // const [playerAffiliate, setPlayerAffiliate] = useState([]);
  // const [marketAffiliate, setMarketAffiliate] = useState([]);
  // const [crm, setCrm] = useState("");
  const [operationCost, setOperationCost] = useState("");

  const modalEditForm = useRef();
  const [editVisible, setEditVisible] = useState(false);
  const [editUserID, setEditUserID] = useState("");
  const [editNickname, setEditNickname] = useState("");
  const [editCountryCode, setEditCountryCode] = useState("");
  const [editMobileNumber, setEditMobileNumber] = useState("");
  const [editLineId, setEditLineId] = useState("");
  const [editPassword, setEditPassword] = useState("");
  const [editPin, setEditPin] = useState("");
  const [editProfitSharing, setEditProfitSharing] = useState(0);
  const [editButtonLoading, setEditButtonLoading] = useState(false);

useEffect(() => {
    loadCompanyProfileData();
    loadUserCommSettingData();
    loadUplineProfileData();
    downlineList();
}, []);

const loadCompanyProfileData = async() => {
    let requestData = { 
        companyID: companyId,
        columnRequire: ['MobileCode']
    }

    const response = await getCompanyProfileData(requestData);
    let result = await response.json();

    if (result.status === "ok") {
        setCompanyCountryCode(result.data[0].MobileCode);
    }
    else {
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
    }
}

const loadUserCommSettingData = async() => {
    let requestData = {
        userID : userId,
        columnRequire : ['CustomFee as customFee', 'uuidFromBin(Shareholder) as uplineShareholder']
    };

    const response = await getUserCommSharePT(requestData);
    let result = await response.json();

    if (result.status === "ok") {
        setCustomFee(Number(result.data[0].customFee) >= 0 ? Number(result.data[0].customFee).toFixed(4) : 0);
        setUplineShareholder(result.data[0].uplineShareholder);
    }
    else {
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
    }
}

const loadUplineProfileData = async() => {
    let requestData = { 
        userID: userId,
        columnRequire : ['PositionTaking as positionTaking','Generation as generation']
    }

    const response = await getUserData(requestData);
    let result = await response.json();

    if (result.status === "ok") {
        setProfitSharingLimit(result.data[0].positionTaking);
        // setUserGeneration(result.data[0].generation);
        // setCustomFee(result.data[0].customFee.toFixed(4));
    }
    else {
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
    }
}

const downlineList = async() => {
    var count = 0;
    let response = '';
    setTableLoading(true);

    response = await userShareholderListV2({userId: userId});
    let result = await response.json();

    if (result.status == "ok") {
        setTableLoading(false);

        const newData = Object.keys(result.data).length
        ? result.data.map((x) => {
            return {
                key: count++,
                userid: x.userId,
                username: x.username,
                pin: x.pin,
                referral_code : x.referralCode,
                nickname: x.nickname,
                mobilenumber: x.mobilenumber,
                lineid: x.lineID,
                usertype: x.type,
                registerDate: x.registerDate,
                serialNumber: `${x.shareCount}/${x.agentCount}/${x.memberCount}`,
                profit_sharing : x.profitSharing >= 0 ? x.profitSharing.toFixed(2) + '%' : '0%',
                custom_fee : x.customFee != null ? x.customFee.toFixed(4) : 0.0000,
                status:
                x.status === "0"
                    ? "Lock"
                    : x.status === "1"
                    ? "Active"
                    : "Inactive",
            };
            })
        : [];
        
      setOriginData(newData);
    }
    else {
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
      setTableLoading(false);
      return;
    }


    // userShareholderListV2({ userId: userId })
    //     .then((response) => response.json())
    //     .then((json) => {
    //     if (json.status === "ok") {
    //         setTableLoading(false);
    //         const newData = Object.keys(json.data).length
    //         ? json.data.map((x) => {
    //             return {
    //                 key: count++,
    //                 userid: x.userId,
    //                 username: x.username,
    //                 pin: x.pin,
    //                 referral_code : x.referralCode,
    //                 nickname: x.nickname,
    //                 mobilenumber: x.mobilenumber,
    //                 usertype: x.type,
    //                 registerDate: x.registerDate,
    //                 serialNumber: `${x.shareCount}/${x.agentCount}/${x.memberCount}`,
    //                 profit_sharing : x.profitSharing.toFixed(2) + '%',
    //                 custom_fee : x.customFee != null ? x.customFee.toFixed(4) : 0.0000,
    //                 status:
    //                 x.status === "0"
    //                     ? "Lock"
    //                     : x.status === "1"
    //                     ? "Active"
    //                     : "Inactive",
    //             };
    //             })
    //         : [];
    //         setOriginData(newData);
    //         setAddVisible(false);
    //     }
    //     else {
    //         setTableLoading(true);
    //         alertData.title = "Failed";
    //         alertData.msg = json.msg;
    //         sharedFailAlert(alertData);
    //     }
    //     })
    //     .catch((error) => {
    //     console.log("error: ", error);
    //     alertData.title = "Error";
    //     alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
    //     sharedErrorAlert(alertData);
    //     });
};

const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
};

const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
};

const showModal = () => {
    setCancelDisable(false);
    setEditingKey("");
    setAddVisible(true);
    form.resetFields();
};

const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
};

const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
};

let searchInput = null;

const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }) => (
        <div style={{ padding: 8 }}>
        <Input
            ref={(node) => {
            searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
        >
            Search
        </Button>
        <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
        >
            Reset
        </Button>
        </div>
    ),
    filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
        if (visible) setTimeout(() => searchInput.select());
    },

    render: (text) => (
        <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
        />
    ),
});

const columns = [
    {
        width: "200px",
        align: "center",
        title: "Username",
        dataIndex: "username",
        // ...getColumnSearchProps("username"),
        ellipsis: true,
    },
    {
        width: "200px",
        align: "center",
        title: "Nickname",
        dataIndex: "nickname",
        // ...getColumnSearchProps("nickname"),
    },
    // {
    //     width: "230px",
    //     align: "center",
    //     title: "Mobile Number",
    //     dataIndex: "mobilenumber",
    //     ...getColumnSearchProps("mobilenumber"),
    //     ellipsis: true,
    // },
    {
        width: "220px",
        ellipsis: true,
        align: "center",
        title: "Line ID",
        dataIndex: "lineid",
        editable: true,
        render: (text) => (
            <Tooltip title={text}>
              <span>{text}</span>
            </Tooltip>
          ),
    },
    // {
    //     width: "150px",
    //     ellipsis: true,
    //     align: "center",
    //     title: "PIN",
    //     dataIndex: "pin",
    //     editable: true,
    // },
    {
        width: "150px",
        ellipsis: true,
        align: "center",
        title: "Affiliate Code",
        dataIndex: "referral_code",
    },
    {
        width: "150px",
        align: "center",
        title: "Custom Fee",
        dataIndex: "custom_fee",
        ellipsis: true,
    },
    {
        width: "150px",
        align: "center",
        title: "Profit Sharing",
        dataIndex: "profit_sharing",
        ellipsis: true,
    },
    {
        width: "200px",
        align: "center",
        title: "Creation Date",
        dataIndex: "registerDate",
        ellipsis: true,
    },
    {
        width: "150px",
        align: "center",
        title: "No. of SH/AG/MB",
        dataIndex: "serialNumber",
        ellipsis: true,
    },
    {
        width: "100px",
        align: "center",
        ellipsis: true,
        title: "Status",
        dataIndex: "status",
        render: (text, record) => {
        switch (record.status) {
            case "Lock":
            return <Badge status="warning" text="Lock" />;
            case "Active":
            return <Badge status="success" text="Active" />;
            default:
            return <Badge status="error" text="Inactive" />;
        }
        },
    },
    {
        width: "100px",
        ellipsis: true,
        align: "center",
        title: "Action",
        dataIndex: "operation",
        render: (text, record) => {
            return (
                <Button
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                        showEditModal(record);
                    }}
                />
            )
            },
    },
].filter(item => !item.hidden);

const isEditing = (record) => record.key === editingKey;

const edit = async (record) => {
    form.setFieldsValue({
        nickname: "",
        ...record,
    });
    setEditingKey(record.key);
};

const cancel = () => {
    setEditingKey("");
};

const showEditModal = async (record) => {
    setMobileNumber(record.mobilenumber);
    setNickname(record.nickname);
    setLineId(record.lineid);
    setPin(record.pin);
    // setProfitSharing(record.profit_sharing);

    let countryCodeLength = String(companyCountryCode).length;
    setEditUserID(record.userid);
    setEditCountryCode("+"+record.mobilenumber.slice(0,countryCodeLength));
    setEditMobileNumber((record.mobilenumber).slice(countryCodeLength));
    setEditNickname(record.nickname);
    setEditLineId(record.lineid);
    setNickname(record.nickname);
    setEditPin(record.pin);
    setEditProfitSharing(record.profit_sharing);
    setEditVisible(true);
  }

const handleEdit = async (record, index) => {
    setTableLoading(true);
    setEditButtonLoading(true);

    let requestData = {
        userid : editUserID,
        usertype : USER_TYPE_SHAREHOLDER,
    };

    let tmpMobile = editCountryCode.slice(1) + editMobileNumber;

    if (tmpMobile != mobilenumber && String(editMobileNumber).length < 9) {
      alertData.title = "Format Error";
      alertData.msg = "New mobile number is too short !";
      sharedErrorAlert(alertData);
      setTableLoading(false);
      setEditButtonLoading(false);
      return;
    }
    
    if (tmpMobile != mobilenumber && String(editMobileNumber).length >= 9) {
      // Verify if number is used by all type of user
      const verifyNumber = await validateUserPhoneV2(String(tmpMobile));
      if (verifyNumber == true) {
          requestData.mobile = String(tmpMobile);
      }
      else{
          alertData.title = "Validation Error";
          alertData.msg = `+${tmpMobile} is used by another user !`;
          sharedFailAlert(alertData);
          setTableLoading(false);
          setEditButtonLoading(false);
          return;
      }
    }
    
    if (editNickname != '' && editNickname != nickname) {
        requestData.nickname = editNickname;
    }

    if (editLineId != lineId) {
        requestData.lineId = editLineId;
    }

    if (String(editPin).length < 6) {
        alertData.title = "Format Error";
        alertData.msg = "PIN must not less than 6 digit !";
        sharedErrorAlert(alertData);
        setTableLoading(false);
        setEditButtonLoading(false);
        return;
    }

    if (editPin != pin) {
        requestData.pin = editPin;
    }

    if (editPassword != '') {
        requestData.pass = md5(editPassword);
    }

    // if (editProfitSharing.length < 1 || Number(editProfitSharing) < 0.5) {
    //     alertData.title = "Field Required";
    //     alertData.msg = "Profit Sharing cannot less than 0.5 or empty !";
    //     sharedErrorAlert(alertData);
    //     setTableLoading(false);
    //     setEditButtonLoading(false);
    //     return;
    //   }
    // else {
    //     if (editProfitSharing > profitSharingLimit) {
    //         alertData.title = "Percentage Exceed";
    //         alertData.msg = `Profit Sharing percentage cannot more than ${profitSharingLimit}%, please re-adjust !`;
    //         sharedErrorAlert(alertData);
    //         setTableLoading(false);
    //         setEditButtonLoading(false);
    //         return;
    //     }
    //     if (editProfitSharing != profitSharing) {
    //         requestData.profitsharing = editProfitSharing;
    //     }
    // } 

    userManageUpdateV2(requestData)
        .then((response) => response.json())
        .then((json) => {
        if (json.status === "ok") {
            setTableLoading(false);
            setEditButtonLoading(false);
            alertData.title = "Success";
            alertData.msg = "Downline's user profile is updated.";
            sharedSuccessAlert(alertData);
            onCancelEdit();
            downlineList();
        } 
        else {
            setTableLoading(false);
            setEditButtonLoading(false);
            alertData.title = "Failed";
            alertData.msg = json.msg;
            sharedFailAlert(alertData);
        }
    })
    .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
    });
  };

const handleAdd = (index) => {
    var regEx = new RegExp(/^\d*[a-z]{1,}\d*/);
    setCreateButtonLoading(true);

    // if (!mobilenumber) {
    //     alertData.title = "Field Required";
    //     alertData.msg = "Please fill in Mobile No !";
    //     sharedErrorAlert(alertData);
    //     setCreateButtonLoading(false);
    //     return;
    // }

    // if (mobilenumber.length < 9 ) {
    //     alertData.title = "Invalid Mobile No";
    //     alertData.msg = "Mobile number is too short !";
    //     sharedErrorAlert(alertData);
    //     setCreateButtonLoading(false);
    //     return;
    // }
    
    // if (!pin) {
    //     alertData.title = "Field Required";
    //     alertData.msg = "Please fill in 6 Digit Pin !";
    //     sharedErrorAlert(alertData);
    //     setCreateButtonLoading(false);
    //     return;
    // }
    // else{
    //     if (pin.length < 6) {
    //         alertData.title = "Invalid Pin";
    //         alertData.msg = "PIN at least 6 characters !";
    //         sharedErrorAlert(alertData);
    //         setCreateButtonLoading(false);
    //         return;
    //     }
    // }

    if (!username || !password || !nickname) {
        alertData.title = "Field Required";
        alertData.msg = "Please fill in Username, Nickname, Password !";
        sharedErrorAlert(alertData);
        setCreateButtonLoading(false);
        return;
    } 
    else if (!regEx.test(username)) {
        alertData.title = "Format Error";
        alertData.msg = "Username only accept lowercase characters and numbers !";
        sharedErrorAlert(alertData);
        setCreateButtonLoading(false);
        return;
    }

    if (referralCode && referralCode.length < 6 ) {
        alertData.title = "Invalid Referral Code";
        alertData.msg = "Referral code requires at least 6 characters !";
        sharedErrorAlert(alertData);
        setCreateButtonLoading(false);
        return;
      }

    if (profitSharing.length < 1 || profitSharing < 0.5) {
        alertData.title = "Field Required";
        alertData.msg = "Profit Sharing cannot less than 0.5 or empty !";
        sharedErrorAlert(alertData);
        setCreateButtonLoading(false);
        return;
    }
    else {
        if (profitSharing > profitSharingLimit) {
            alertData.title = "Percentage Exceed";
            alertData.msg = `Profit Sharing percentage cannot more than ${profitSharingLimit}%, please re-adjust !`;
            sharedErrorAlert(alertData);
            setCreateButtonLoading(false);
            return;
        }
    }
  
    let requestData = {
        userId : userId,
        // upline : userGeneration,
        userType : USER_TYPE_SHAREHOLDER,
        username : username.toLowerCase().trim(),
        nickname : nickname,
        mobilenumber : mobilenumber != '' ? countryCode+mobilenumber : 'xxxxxxxxxx',
        lineId : lineId,
        pin : pin != '' ? pin : '111111',
        pass : md5Pass,
        customFee : Number(customFee).toFixed(4),
        profitSharing : Number(profitSharing).toFixed(2),
        referralCode : referralCode,
        uplineShareholder : uplineShareholder
    }
    
    createSystemUserV2(requestData)
        .then((response) => response.json())
        .then((json) => {
            if (json.status === "ok") {
                setCreateButtonLoading(false);
                downlineList();
                onCancel();
                setAddVisible(false);
                alertData.title = "Success";
                alertData.msg = json.msg;
                sharedSuccessAlert(alertData);
            } 
            else {
                setCreateButtonLoading(false);
                alertData.title = "Failed";
                alertData.msg = json.msg;
                sharedFailAlert(alertData);
            }
        })
        .catch((error) => {
            console.log("error: ", error);
            setCreateButtonLoading(false);
            alertData.title = "Error";
            alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
            sharedErrorAlert(alertData);
        });
};

const onChangeCountryCode = (value) => {
    setCountryCode(value);
};

const onChangeCountryCodeV2 = (value) => {
    setEditCountryCode(value);
};

const validateUserPhone = async(mobilenumber)=> {
    const response = await verifyValidMobile({mobile: countryCode+mobilenumber});
    let result = await response.json();

    if (result.status === "ok") {
        alertData.title = "Validation Error";
        alertData.msg = `+${countryCode+mobilenumber} is used by another user !`;
        sharedErrorAlert(alertData);
    }
    else{
        // setCreateMemberDisable(false);
    }
}

const validateUserPhoneV2 = async(mobilenumber)=> {
    const response = await verifyValidMobile({mobile: mobilenumber});
    let result = await response.json();

    if (result.status === "ok") {
        return false;
    }
    else{
        return true;
    }
}

const validateUsername = async(username)=> {
    if (username != undefined || username != '') {

        let requestData = {
            username: username
        }

        const response = await checkUsernameRegisteredV2(requestData);
        let result = await response.json();
        if (result.status === "ok") {}
        else{
            alertData.title = "Validation Error";
            alertData.msg = result.msg;
            sharedErrorAlert(alertData);
        }
    }
}

const mergedColumns = columns.map((col) => {
    if (!col.editable) {
        return col;
    }

    return {
        ...col,
        onCell: (record) => ({
        record,
        inputType: col.dataIndex === "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        }),
    };
});

const onCancel = () => {
    setAddVisible(false);
    setUsername("");
    setNickname("");
    setMobileNumber("");
    setLineId("");
    setPin("");
    setPassword("");
    setProfitSharing(0);
    modalForm.current.resetFields();
};

const onCancelEdit = () => {
    setUsername("");
    setNickname("");
    setMobileNumber("");
    setLineId("")
    setPin("");
    setPassword("");
    setProfitSharing("");

    setEditVisible(false);
    setEditUserID("");
    setEditNickname("");
    setEditMobileNumber("");
    setEditLineId("");
    setEditPin("");
    setEditPassword("");
    setCustomFee(0);
    setEditProfitSharing(0);
    modalEditForm.current.resetFields();
  };

return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
        <div style={{ marginLeft: 0 }}>
        <Modal
            title="New Downline (Shareholder)"
            visible={addVisible}
            footer={null}
            closable={false}
            maskClosable={false}
            onCancel={onCancel}
        >
            <Form 
                ref={modalForm}
                layout="vertical"
                initialValues={{
                    customFee : customFee
                }}
            >
            
            {/* <Form.Item
                name={["user", "mobilenumber"]}
                label="Mobile No"
                required={true}
            >
                <Input.Group compact>
                    <Select style={{width:"20%"}} value={countryCode}
                        onChange={onChangeCountryCode}
                    >
                        <Option value="856">+856</Option>
                        <Option value="66">+66</Option>
                        <Option value="60">+60</Option>
                    </Select>
                    <Input style={{width:"80%"}} defaultValue="" placeholder="phone number" value={mobilenumber}
                        onChange={(e) => {
                            setMobileNumber(e.target.value.replace(/\D|^0/g, ""));
                        }}
                        onBlur={(e)=>{
                            validateUserPhone(e.target.value.replace(/\D|^0/g, ""))
                        }}
                    />
                </Input.Group>
            </Form.Item> */}

            {/* <Form.Item
                name={["user", "pin"]}
                label="Pin&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                required
            >      
                <Input.Group compact>
                    <Input
                        value={pin}
                        placeholder="6 Digit Pin"
                        maxLength={40}
                        onChange={(e) => {
                            const newValue = e.target.value.toLowerCase().replace(/\s/g, ''); // Convert to lowercase and remove whitespace
                            // Regular expression to match only lowercase letters and numbers
                            const isValid = /^[a-z0-9]*$/.test(newValue);
                            if (isValid) {
                              setPin(newValue);
                            }
                          }}
                    />
                </Input.Group>       
            </Form.Item> */}

            <Form.Item
                name={["user", "username"]}
                label="Username"
                required
                rules={[
                () => ({
                    validator(rule, value) {
                    var regEx = new RegExp(/^\d*[a-z]{1,}\d*/);

                    if (regEx.test(value)) {
                        return Promise.resolve();
                    }
                    return Promise.reject(
                        "Only accept lowercase characters and numbers!"
                    );
                    },
                }),
                ]}
            >
                <Input.Group compact>
                    <Input
                        value={username}
                        placeholder="Username"
                        maxLength={20}
                        onChange={(e) => {
                            setUsername(e.target.value);
                        }}
                        onBlur={(e)=>{
                            validateUsername(e.target.value)
                        }}
                    />
                </Input.Group>
            </Form.Item>

            <Form.Item
                name={["user", "nickname"]}
                label="Nickname"
                required
            >
                <Input
                    value={nickname}
                    placeholder="Nickname"
                    onChange={(e) => {
                        setNickname(e.target.value);
                    }}
                />
            </Form.Item>

            <Form.Item
                name={["user", "lineid"]}
                label="Line ID"
                // required
            >
                <Input.Group compact>
                    <Input
                        value={lineId}
                        placeholder="Line Id"
                        maxLength={50}
                        onChange={(e) => {
                        setLineId(e.target.value);
                        }}
                    />
                </Input.Group>
            </Form.Item>

            <Form.Item
                name={["user", "password"]}
                label="Password"
                required
            >
                <Input.Password
                    value={password}
                    placeholder="Password"
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                />
            </Form.Item>

            <Form.Item
                // name="referralCode"
                label="Affiliate Code"
            >
                <Input
                    value={referralCode}
                    maxLength={12}
                    minLength={6}
                    style={{width:"200px"}}
                    placeholder="E.g. tkhxxxxx"
                    onChange={(e) => {
                        setReferralCode(e.target.value.toLowerCase());
                    }}
                />
                <div style={{color: "blue", fontSize: "10pt"}}><i>If this field is left empty, a random affiliate code will be generate for user.</i></div>
            </Form.Item>

            <Divider></Divider>

            <Form.Item
                name="customFee"
                label="Custom Fees"
            >
                <InputNumber
                    value={customFee}
                    style={{width:"200px"}}
                    disabled={true}
                />
            </Form.Item>

            <Form.Item
                name={["user", "profitSharing"]}
                label="Profit Sharing"
                required
            >
                <InputNumber
                    value={profitSharing}
                    min={0}
                    max={100}
                    style={{width:"200px"}}
                    placeholder="E.g. 0.00% - 100.00%"
                    onChange={(e) => {
                        setProfitSharing(e);
                    }}
                />
            </Form.Item>

            {/* <Form.Item
                name={["user", "playerAffiliate"]}
                label="Player Affiliate"
            >
                <Form.List name="playerTier">
                {(fields, { add, remove }) => (
                    <>
                    {fields.map((field) => (
                        <Space key={field.key} align="baseline">
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                            prevValues.area !== curValues.area || prevValues.tier !== curValues.tier
                            }
                        >
                            {() => (
                            <Form.Item
                                {...field}
                                label="Tier"
                                name={[field.name, 'playerTier_Tier']}
                                rules={[
                                {
                                    required: true,
                                    message: 'Missing tier',
                                },
                                ]}
                            >
                                <Select
                                style={{
                                    width: 130,
                                }}
                                onChange={(e) => {
                                    setPlayerAffiliate(e)
                                }}
                                >
                                {(tierList['Player'] || []).map((item) => (
                                    <Option key={item} value={item}>
                                    {item}
                                    </Option>
                                ))}
                                </Select>
                            </Form.Item>
                            )}
                        </Form.Item>
                        <Form.Item
                            {...field}
                            label="Percentage"
                            name={[field.name, 'playerTier_percentage']}
                            rules={[
                            {
                                required: true,
                                message: 'Missing percentage',
                            },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                        </Space>
                    ))}
                    <Form.Item>
                        <Button type="dashed" onClick={() => 
                        {
                            if(fields.length<10) {
                            add();
                            }
                            else{
                            alertData.title = "Limit Reached";
                            alertData.msg = "Maximum 10 player tier only !";
                            sharedErrorAlert(alertData);
                            return;
                            }
                        }
                        } block icon={<PlusOutlined />}>
                        Add tier
                        </Button>
                    </Form.Item>
                    </>
                )}
                </Form.List>
            </Form.Item> */}

            {/* <Form.Item
                name={["user", "marketAffiliate"]}
                label="Market Affiliate"
            >
                <Form.List name="marketTier">
                {(fields, { add, remove }) => (
                    <>
                    {fields.map((field) => (
                        <Space key={field.key} align="baseline">
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                            prevValues.area !== curValues.area || prevValues.tier !== curValues.tier
                            
                            }
                        >
                            {() => (
                            <Form.Item
                                {...field}
                                label="Tier"
                                name={[field.name, 'marketTier_tier']}
                                rules={[
                                {
                                    required: true,
                                    message: 'Missing tier',
                                },
                                ]}
                            >
                                <Select
                                style={{
                                    width: 130,
                                }}
                                >
                                {(tierList['Market'] || []).map((item) => (
                                    <Option key={item} value={item}>
                                    {item}
                                    </Option>
                                ))}
                                </Select>
                            </Form.Item>
                            )}
                        </Form.Item>
                        <Form.Item
                            {...field}
                            label="Percentage"
                            name={[field.name, 'marketTier_percentage']}
                            rules={[
                            {
                                required: true,
                                message: 'Missing percentage',
                            },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                        </Space>
                    ))}
                    <Form.Item>
                        <Button type="dashed" onClick={() => 
                        {
                            if(fields.length<10) {
                            add();
                            }
                            else{
                            alertData.title = "Limit Reached";
                            alertData.msg = "Maximum 10 market tier only !";
                            sharedErrorAlert(alertData);
                            return;
                            }
                        }
                        } block icon={<PlusOutlined />}>
                        Add tier
                        </Button>
                    </Form.Item>
                    </>
                )}
                </Form.List>
            </Form.Item>

            <Form.Item
                name={["user", "crm"]}
                label="CRM"
                required
            >
                <InputNumber
                value={crm}
                min={0}
                max={100}
                style={{width:"200px"}}
                placeholder="E.g. 0.00% - 100.00%"
                onChange={(e) => {
                    setCrm(e);
                }}
                />
            </Form.Item> */}

            <Form.Item style={{ textAlign: "right" }}>
                <Button
                    htmlType="button"
                    onClick={onCancel}
                    style={{ marginRight: 10 }}
                    loading={createButtonLoading}
                    disabled={cancelDisable}
                >
                    Cancel
                </Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={createButtonLoading}
                    onClick={() => {
                        handleAdd(0);
                    }}
                >
                    Submit
                </Button>
            </Form.Item>
            </Form>
        </Modal>
        </div>
        <PageHeader
            ghost={false}
            title="Downline Management"
        >
            <div
                style={{
                pageHeaderContainer: {
                    justifyContent: "space-between",
                    display: "flex",
                },
                }}
            >
                <Button type="primary" onClick={() => showModal()}>
                    Add
                </Button>
            </div>
        </PageHeader>
        <Form form={form} component={false}>
        <Table
            loading={tableLoading}
            scroll={{ x: "100%", y: null }}
            components={{
            body: {
                cell: EditableCell,
            },
            }}
            dataSource={originData}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={{
            onChange: cancel,
            }}
            style={{ overflow: "auto", marginLeft: 20 }}
        />
        </Form>
        <Modal 
            title="Edit Downline Detail"
            visible={editVisible}
            // style={{ top : "35%" }}
            onCancel={()=>{
                onCancelEdit();
            }}
            footer={null}
            closable={false}
            maskClosable={false}
      >
        <Form 
            layout="vertical"
            ref={modalEditForm}
            initialValues={{
            sh_nickname : editNickname,
            sh_mobilenumber : editMobileNumber,
            sh_pin : editPin,
            sh_profitSharing : editProfitSharing
          }}
          >

            {/* <Form.Item
                name="sh_mobileNumber"
                label="Mobile No"
            >
                <Input.Group compact>
                    <Select 
                        style={{width:"20%"}} 
                        value={editCountryCode} 
                        onChange={onChangeCountryCodeV2}>
                            <Option value="856">+856</Option>
                            <Option value="66">+66</Option>
                            <Option value="60">+60</Option>
                    </Select>
                <Input 
                    style={{width:"80%"}} 
                    defaultValue="" 
                    placeholder="phone number" 
                    value={editMobileNumber}
                    onChange={(e) => {
                        setEditMobileNumber(e.target.value.replace(/\D|^0/g, ""));
                    }}
                    onBlur={(e)=>{
                        validateUserPhone(e.target.value.replace(/\D|^0/g, ""))
                    }}
                />
                </Input.Group>
            </Form.Item> */}

            <Form.Item
                name="sh_nickname"
                label="Nickname"
            >
                <Input.Group compact>
                    <Input
                        value={editNickname}
                        placeholder="Nickname"
                        onChange={(e) => {
                            setEditNickname(e.target.value);
                        }}
                    />
                </Input.Group>
            </Form.Item>

            <Form.Item
              name="sh_lineid"
              label="Line ID"
            >
              <Input.Group compact>
                <Input
                  value={editLineId}
                  placeholder="Line ID"
                  maxLength={50}
                  onChange={(e) => {
                    setEditLineId(e.target.value);
                  }}
                />
              </Input.Group>
            </Form.Item>

            {/* <Form.Item
                name="sh_pin"
                label="Pin"
            >      
                <Input.Group compact>
                    <Input
                        value={editPin}
                        placeholder="6 Digit Pin"
                        maxLength={40}
                        onChange={(e) => {
                            const newValue = e.target.value.toLowerCase().replace(/\s/g, ''); // Convert to lowercase and remove whitespace
                            // Regular expression to match only lowercase letters and numbers
                            const isValid = /^[a-z0-9]*$/.test(newValue);
                            if (isValid) {
                              setEditPin(newValue);
                            }
                          }}
                    />
                    </Input.Group>       
            </Form.Item> */}

            <Form.Item
                name="sh_password"
                label="Password"
              >      
                <Input.Group compact>
                    <Input.Password
                        value={editPassword}
                        placeholder="Password"
                        onChange={(e) => {
                            setEditPassword(e.target.value);
                        }}
                    />
                </Input.Group>       
            </Form.Item>
            
            {/* <Form.Item
              name="sh_profitSharing"
              label="Profit Sharing"
              >      
                <Input.Group compact>
                <InputNumber
                    value={editProfitSharing}
                    min={0}
                    max={100}
                    style={{width:"200px"}}
                    placeholder="E.g. 0.00% - 100.00%"
                    onChange={(e) => {
                        setEditProfitSharing(e);
                    }}
                />
                </Input.Group>       
            </Form.Item> */}

            <Form.Item style={{ textAlign: "right" }}>
                <Button
                    htmlType="button"
                    onClick={onCancelEdit}
                    loading={editButtonLoading}
                    style={{ marginRight: 10 }}
                >
                    Cancel
                </Button>

                <Button
                    type="primary"
                    htmlType="submit"
                    loading={editButtonLoading}
                    onClick={() => {
                        handleEdit();
                    }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
      </Modal>
    </div>
);
}
