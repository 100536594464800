import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Form,
  Button,
  PageHeader,
  Modal,
  Input,
  Select,
  Radio,
  Row,
  Col,
  Tooltip,
  Badge,
  Upload,
  DatePicker,
  Image,
} from "antd";
import Highlighter from "react-highlight-words";
import { 
  QuestionCircleOutlined, 
  SearchOutlined, 
  InfoCircleFilled,
  UploadOutlined,
} from "@ant-design/icons";
import {
  csTopupReceiptList,
  csCreateTopUpReceipt,
  csApproveTopUpReceipt,
  csRejectTopUpReceipt,
  csVerifyMobile,
  csAvailBankList,
  getBankList,
  uploadReceiptV3,
  getReqToken,
  getMemberTransactionData,
} from "../../config/networkConfig";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { settings } from "../../config/appConfig";
import style from "../../resource/style/style";
import { useSelector } from "react-redux";
import { depMgmtManCrDep, depMgmtManAppRejDep } from "../../library/permission";
import { fileDomain, topupReceiptUploadDomain } from "../../config/config";
import { FormatPt , FormatMask , ShowMobileNumber} from "../../library/numbers";
import md5 from "md5";
import { BANK_INFO_TYPE } from "../../common/constants/bankType";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, sharedConfirmationAlert, } from "../../common/constants/sharedMsgBox";
import { arrayOf } from "prop-types";

export default function ManualDepositV3() {
const { Option } = Select;
const { confirm } = Modal;
const { TextArea } = Input;

const { role, userId, companyId } = useSelector((state) => state.login);

const [bankList, setBankList] = useState([]);
const [depositBankList, setDepositBankList] = useState([]);
const [selected, setSelected] = useState(true);

const [originData, setOriginData] = useState([]);
const [data, setData] = useState([]);
const [tableLoading, setTableLoading] = useState(false);
const [status, setStatus] = useState("Pending");

const [formManualDeposit] = Form.useForm();
const [manualDepositPhoneNum, setManualDepositPhoneNum] = useState("");
const [manualDepositCountryCode, setManualDepositCountryCode] = useState("856");
const [fileList, setFileList] = useState([]);
const [manualDepositUserID,setManualDepositUserID] = useState("");
const [manualDepFromBankAccNum,setManualDepFromBankAccNum] = useState("");
const [manualDepFromBankCode,setManualDepFromBankCode] = useState("");
const [manualDepToBank, setManualDepToBank] = useState("");
const [manualDepToBankAccNum, setManualDepToBankAccNum] = useState("");
const [manualDepToBankCode, setManualDepToBankCode] = useState("");
const [manualDepToBankData, setManualDepToBankData] = useState("");
const [manualDepBankTime, setManualDepBankTime] = useState("")
const [manualDepositVisible, setManualDepositVisible] = useState(false);
const [createDepositDisable, setCreateDepositDisable] = useState(true);
const [loadings, setLoadings] = useState([]);
const [formManualDepositLoading, setFormManualDepositLoading] = useState(false);

const [formDepositDetail] = Form.useForm();
const [approveDepositReceiptID, setApproveDepositReceiptID] = useState("");
const [approveDepositUserID, setApproveDepositUserID] = useState("");
const [approveDepositFromBankCode, setApproveDepositFromBankCode] = useState("");
const [fromBankCodeDisable, setFromBankCodeDisable] = useState(false);
const [approveDepositFromBankAccNum, setApproveDepositFromBankAccNum] = useState("");
const [fromBankAccNumDisable, setFromBankAccNumDisable] = useState(false);
const [approveDepositToDisplayBankData, setApproveDepositToDisplayBankData] = useState("");
const [approveDepositToBankCode, setApproveDepositToBankCode] = useState("");
const [approveDepositToBankAcc, setApproveDepositToBankAcc] = useState("");
const [approveDepositToBankAccNum, setApproveDepositToBankAccNum] = useState("");
const [depositToBankDisable, setDepositToBankDisable] = useState(false);
const [approveDepositReceipt, setApproveDepositReceipt] = useState("");
const [approveDepositAmt, setApproveDepositAmt] = useState(0);
const [approveDepositDisplayBankTime, setApproveDepositDisplayBankTime] = useState("")
const [approveDepositBankTime, setApproveDepositBankTime] = useState("")
const [bankTimeDisable, setBankTimeDisable] = useState(false);
const [approveConvertCurrencyFrom, setApproveConvertCurrencyFrom] = useState("");
const [approveConvertCurrencyTo, setApproveConvertCurrencyTo] = useState("");
const [formDepositDetailLoading, setFormDepositDetailLoading] = useState(false);
const [approveDepositVisible, setApproveDepositVisible] = useState(false);

const [formViewDepositDetail] = Form.useForm();
const [depositDetailVisible, setDepositDetailVisible] = useState("");
const [depositDetailReceiptID, setDepositDetailReceiptID] = useState("");

const [formRejectDeposit] = Form.useForm();
const [rejectReason, setRejectReason] = useState("");
const [rejectDepositVisible, setRejectDepositVisible] = useState(false);
const [rejectButtonLoading, setRejectButtonLoading] = useState(false);
const [depositDetailData, setDepositDetailData] = useState([]);
const [depositDetailTableLoading, setDepositDetailTableLoading] = useState(false);

const [searchText, setSearchText] = useState("");

useEffect(() => { 
  bankCodeList();
  getDepositBankList();
}, []);

useEffect(() => {
  ManualDepositList();
}, [status]);

useEffect(() => {
  manualDepositCheckList();
}, [manualDepositPhoneNum,manualDepFromBankCode,manualDepToBank,manualDepBankTime]);

useEffect(()=> {
  if (manualDepToBankData!=null) {
    let depToBankData = manualDepToBankData.split("||");
    setManualDepToBankCode((depToBankData[0]||'').trim());
    setManualDepToBankAccNum((depToBankData[1]||'').trim());
    setManualDepToBank((depToBankData[2]||'').trim());
  }
},[manualDepToBankData])

useEffect(()=> {
  if (approveDepositToDisplayBankData!=null) {
    let depToBankData2 = approveDepositToDisplayBankData.split("||");
    setApproveDepositToBankCode((depToBankData2[0]||'').trim());
    setApproveDepositToBankAccNum((depToBankData2[1]||'').trim());
    setApproveDepositToBankAcc((depToBankData2[2]||'').trim());
  }  
},[approveDepositToDisplayBankData])

const bankCodeList = async () => {
  csAvailBankList({companyId: companyId})
    .then((response) => response.json())
    .then((json) => {
      if (json.status === "ok") {
          setBankList(json.data);
      }
  })
  .catch((error) => {
    console.log("error: ", error);
    alertData.title = "Error";
    alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
    sharedErrorAlert(alertData);
  });
};

const getDepositBankList = async () => {
  let requestData = {
      bankType: BANK_INFO_TYPE.DEP,
      columnRequire : ['AccountNum', 'BankCode', 'AccountName'],
  }

  getBankList(requestData)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === "ok") {
          setDepositBankList(json.data);
      }
  })
  .catch((error) => {
    console.log("error: ", error);
    alertData.title = "Error";
    alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
    sharedErrorAlert(alertData);
  });
};

const ManualDepositList = () => {
  var count = 0;
  setTableLoading(true);
  csTopupReceiptList({})
    .then((response) => response.json())
    .then((json) => {
      if (json.status === "ok") {
        const newData = Object.keys(json.data).length
        ? json.data.map((x) => {
            setTableLoading(false);
            return {
              key: count++,
              id : x.id,
              userId : x.userId,
              sysCreateTime: x.sysCreateTime,
              newNickname: x.mobile,
              bankAcctNameIn: x.bank,
              bankAcctIn: x.acc,
              bankNameIn: x.accName,
              bankNameOut: x.fromBankCode,
              bankAcctOut: x.fromBankAcc,
              bankTime : x.bankTime,
              transactionID : x.trxnId,
              receipt: x.receipt,
              actionStatus:
              x.status === 0
                ? "Pending"
                : x.status === 1
                ? "Approved"
                : x.status === 8
                ? "FollowUp"
                : x.status === 9
                ? "Rejected"
                : "-",
            };
              })
          : [];
          setOriginData(newData);

          if (Object.keys(json.data).length == 0) {
            setTableLoading(false);
          }

          const pending = newData.filter((val) => {
            return val.actionStatus === "Pending";
          });
          const approve = newData.filter((val) => {
            return val.actionStatus === "Approved";
          });
          const followup = newData.filter((val) => {
            return val.actionStatus === "FollowUp";
          });
          const reject = newData.filter((val) => {
            return val.actionStatus === "Rejected";
          });

          const sortData =
          status === "Pending"
            ? pending
            : status === "Approved"
            ? approve
            : status === "Rejected"
            ? reject
            : status === "FollowUp"
            ? followup
            : [...newData];

          setData(sortData);

        }
        else {
          setTableLoading(true);
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
      }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
}

// Onchange for checking all manual deposit field before unlock submit button
const manualDepositCheckList = async () => {
  let phoneCheck = true, depFromBankCheck = true, depFromBankCodeCheck = true, depToBankCheck = true, depBankTimeCheck = true;
  
  if(!manualDepositPhoneNum) { 
    phoneCheck = false; 
  }
  else {
    phoneCheck = true;
  }

  // if(!manualDepFromBankAccNum) { 
  //   depFromBankCheck = false; 
  // }
  // else {
  //   depFromBankCheck = true;
  // }

  if(!manualDepFromBankCode) { 
    depFromBankCodeCheck = false; 
  }
  else {
    depFromBankCodeCheck = true;
  }

  if(!manualDepToBank) { 
    depToBankCheck = false; 
  }
  else {
    depToBankCheck = true;
  }

  if(!manualDepBankTime) { 
    depBankTimeCheck = false; 
  }
  else {
    depBankTimeCheck = true;
  }

  if (phoneCheck && depFromBankCodeCheck && depToBankCheck && depBankTimeCheck) {
    setCreateDepositDisable(false); 
  }
}

const onSubmitForm = async () => {
  if (!manualDepositPhoneNum) {
    alertData.title = "Field Required";
    alertData.msg = "Please fill in Member's Phone Number !";
    sharedErrorAlert(alertData);
    return;
  }

  if (JSON.stringify(fileList) == '[]') {
    alertData.title = "Field Required";
    alertData.msg = "Please upload Bank Receipt !";
    sharedErrorAlert(alertData);
    return;
  }

  // if (!manualDepFromBankAccNum) {
  //   alertData.title = "Field Required";
  //   alertData.msg = "Please fill in Deposit From Bank Account !";
  //   sharedErrorAlert(alertData);
  //   return;
  // }

  if (!manualDepFromBankCode) {
    alertData.title = "Field Required";
    alertData.msg = "Please select Deposit From Bank !";
    sharedErrorAlert(alertData);
    return;
  }

  if (!manualDepToBank) {
    alertData.title = "Field Required";
    alertData.msg = "Please select Deposit To Bank !";
    sharedErrorAlert(alertData);
    return;
  }

  if (!manualDepBankTime) {
    alertData.title = "Field Required";
    alertData.msg = "Please select the Deposit Transaction Time !";
    sharedErrorAlert(alertData);
    return;
  }

  confirm({
    className: "confirmationModalStyle",
    title: 'Confirmation',
    centered: true,
    icon: <QuestionCircleOutlined />,
    content: 'Are you sure you want to proceed?',
    onOk() {
      console.log('OK');
      setFormManualDepositLoading(true);
      onSubmitManualDeposit();
    },
    onCancel() {
    },
    });
}

const uploadFile = async () => {
  const formData = new FormData();
  formData.append("fileReceipt", fileList[0]);
  if (fileList[0] !== undefined){
    const response = await uploadReceiptV3(formData);
    let result = await response.json();
    return result;
  }
};

const onSubmitManualDeposit = async () => {
  const runUploadFile = await uploadFile();
  if (runUploadFile.status === "ok"){
    let filename = runUploadFile.data !== undefined ? runUploadFile.data.filename : '';
    let requestData = {
      userid: manualDepositUserID,
      bankcode : manualDepToBankCode,
      acctnum : manualDepToBankAccNum,
      acctname : manualDepToBank,
      fromBankCode : manualDepFromBankCode,
      fromBankAcc : manualDepFromBankAccNum == '' ? ' ' : manualDepFromBankAccNum,
      filename : filename,
      bankTime : manualDepBankTime,
      path : `${fileDomain}`,
      by : userId,
      reqToken : md5(getReqToken('depositManualCreate')),
    };

    // console.log(`requestData Create Deposit`,requestData)
      
    csCreateTopUpReceipt(requestData)
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          onCancelManualDeposit();
          ManualDepositList();
          setFormManualDepositLoading(false);
          alertData.title = "Success";
          alertData.msg = json.msg;
          sharedSuccessAlert(alertData);
        }
        else {
          setFormManualDepositLoading(false);
          alertData.title = "Error";
          alertData.msg = json.msg;
          sharedErrorAlert(alertData);
        }
    })
    .catch((error) => {
      console.log("error: ", error);
      alertData.title = "Error";
      alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
      sharedErrorAlert(alertData);
    });
  }
}

const onCancelManualDeposit = () => {
  setCreateDepositDisable(true);
  formManualDeposit.resetFields();
  setManualDepositCountryCode("856");
  setManualDepositPhoneNum("");
  setFileList([]);
  setManualDepFromBankAccNum("");
  setManualDepFromBankCode("");
  setManualDepToBank("");
  setManualDepBankTime("");
  setManualDepositVisible(false);
}

const validateUserPhone = async(phoneNum)=> {
  const response = await csVerifyMobile({mobile: manualDepositCountryCode+phoneNum});
  let result = await response.json();
  if (result.status === "ok") {
    setManualDepositUserID(result.data.userid);
  }
  else{
    alertData.title = "Error";
    alertData.msg = "Phone Number is Invalid !";
    sharedErrorAlert(alertData);
  }
}

const fileProps = {
  listType: "picture",
  onRemove: (file) => {
    setFileList([]);
  },
  fileList: fileList,
  beforeUpload: (file) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      let newFileList = [];
      newFileList.push(file);
      setFileList(newFileList);
    });

    reader.readAsDataURL(file);
    return false;
  },
  onChange: (info) => {
    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);

    // 2. Read from response and show file link
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }

      return file;
    });

    setFileList(newFileList);
    return false;
  },
};

const onManualDeposit = () => {
    return (
      <div>
        <div>
          <Form form={formManualDeposit} layout="vertical" name="manualDepositForm">
            {/* Mobile Id*/}
            <Form.Item
              label="Phone Number"
              required={true}
              >
              <Input.Group compact>
                <Select 
                  value={manualDepositCountryCode} 
                  onChange={(e) => {
                    setManualDepositCountryCode(e);
                }}>
                  <Option value="856">+856</Option>
                  <Option value="66">+66</Option>
                  <Option value="60">+60</Option>
                </Select>
                <Input 
                  style={{width: 275}}
                  defaultValue="" 
                  placeholder="phone number" 
                  value={manualDepositPhoneNum}
                  onChange={(e) => {
                    setManualDepositPhoneNum(e.target.value.replace(/\D|^0/g, ""));
                  }}
                  onBlur={(e) => {
                    validateUserPhone(e.target.value.replace(/\D|^0/g, ""));
                  }}
                />
              </Input.Group>
            </Form.Item>  

            {/* Upload Receipt */}
            <Form.Item
              name="bankReceipt"
              label="Bank Receipt"
              required={true}
              >
              <Upload {...fileProps} fileList={fileList}>
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>
            </Form.Item>

            {/* Member Acc */}
            <Form.Item
              name="memberBankAcc"
              label="Deposit From Bank Number"
            >
            <Input
              type="text"
              value={manualDepFromBankAccNum}
              style={{width: 350}}
              onChange={(e) => {
                setManualDepFromBankAccNum(e.target.value);
              }}
            />
            </Form.Item>

            {/* Member Bank Code */}
            <Form.Item
              name="memberBankCode"
              label="Deposit From Bank"
              required={true}
            >
              <Select
                optionFilterProp="children"
                style={{width: 350}}
                filterOption={(input, option) => {
                  return option.children.includes(input);
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value, event) => {
                  setManualDepFromBankCode(value);
                }}
              >
                {bankList.map((option) => {
                  return (
                    <Option key={option.bankCode} value={`${option.bankCode}`}>
                      {option.bankName}
                    </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            {/* Deposit To Bank */}
            <Form.Item
              name="depositToBank"
              label="Deposit To Bank"
              required={true}
            >
              <Select
                style={{width: 350}}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.children.includes(input);
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value, event) => {
                  setManualDepToBankData(value);
                }}
              >
                {depositBankList.map((option) => {
                  return (
                    <Option key={option.AccountNum} value={`${option.BankCode} || ${option.AccountNum} || ${option.AccountName}`}>
                        {option.BankCode} ({option.AccountNum})
                    </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            {/* Transaction Date Time */}
            <Form.Item
              name="bankTime"
              label="Transaction Date Time"
              required={true}
            >
              <DatePicker
              format="YYYY-MM-DD HH:mm"
              showTime
              style={{width: 350}}
              onChange={(value, dateString) => {
                let initialDate = new Date(dateString)
                let convertedDate = Math.floor(initialDate.getTime()/1000)
                setManualDepBankTime(convertedDate)
              }}
              />
            </Form.Item>

            <Button type="primary"
              loading={formManualDepositLoading}
              onClick={onSubmitForm} 
              disabled={createDepositDisable}
            >
              Submit
            </Button>
          </Form>
        </div>
      </div>
    )
}

const viewDepositDetail = (record) => {
  let receiptDomain = 'https://patuxaiapi2.site/';
  if (record.receipt.substring(0,5) == 'https') { setApproveDepositReceipt(record.receipt); }
  else { setApproveDepositReceipt(`${receiptDomain}`+ record.receipt); }
  setApproveDepositReceiptID(record.id);
  setApproveDepositUserID(record.userId);

  // Check if member bank number if have value, else will set to '' so cs has to input during approve
  if (record.bankAcctOut.length < 6) {
    setApproveDepositFromBankAccNum('');
    setFromBankAccNumDisable(false);
  }
  else { 
    setApproveDepositFromBankAccNum(record.bankAcctOut);
    setFromBankAccNumDisable(true);
    // setFromBankAccNumDisable(false);
  }

  // Check if member bank code if have value, else will set to '' so cs has to input during approve
  let fromBankValid = false;
  let fromBankCheck = bankList.map((x) => {
  if (x.bankCode == record.bankNameOut) {
    fromBankValid = true;
    return x
  }
  });

  if (fromBankValid == true) {
    setApproveDepositFromBankCode(record.bankNameOut);
    setFromBankCodeDisable(true);
  }
  else {
    setApproveDepositFromBankCode('');
    setFromBankCodeDisable(false);
  }

  // Check if deposit to bank if have value, else will set to '' so cs has to input during approve
  let toBankValid = false;
  let toBankCheck = depositBankList.map((x) => {
  if (x.BankCode == record.bankAcctNameIn) {
    toBankValid = true;
    return x;
  }
  });

  if (toBankValid == true) {
    // setApproveDepositToDisplayBankData(record.bankAcctNameIn + ' (' + record.bankAcctIn + ')');
    setApproveDepositToDisplayBankData(record.bankAcctNameIn + ' || ' + record.bankAcctIn + ' || '  + record.bankNameIn);
    setApproveDepositToBankCode(record.bankAcctNameIn);
    setApproveDepositToBankAccNum(record.bankAcctIn);
    setApproveDepositToBankAcc(record.bankNameIn);
    setDepositToBankDisable(true);
  }
  else { 
    setApproveDepositToDisplayBankData("");
    setDepositToBankDisable(false);
  }

  // Check if bank time if have value, else will set to '' so cs has to input during approve
  if (record.bankTime == '' || record.bankTime == '1970-01-01 08:00:00') {
    setApproveDepositDisplayBankTime("");
    setApproveDepositBankTime("");
    setBankTimeDisable(false);
  }
  else {
    setApproveDepositDisplayBankTime(moment(record.bankTime));
    // Convert bankTime back to unix timestamp format
    let initialDate = new Date(record.bankTime)
    let convertedDate = Math.floor(initialDate.getTime()/1000)
    setApproveDepositBankTime(convertedDate);
    setBankTimeDisable(true);
  }

  setApproveConvertCurrencyTo("THB");
  setSelected(true);
  setApproveDepositVisible(true);
};

const handleApproveManualDeposit = async ()=> {
  // if (!approveDepositFromBankAccNum) {
  //   alertData.title = "Field Required";
  //   alertData.msg = "Please fill in Deposit From Bank Account !";
  //   sharedErrorAlert(alertData);
  //   return;
  // }

  if (!approveDepositFromBankCode) {
    alertData.title = "Field Required";
    alertData.msg = "Please select Deposit From Bank !";
    sharedErrorAlert(alertData);
    return;
  }

  if (!approveDepositToBankCode) {
    alertData.title = "Field Required";
    alertData.msg = "Please select Deposit To Bank !";
    sharedErrorAlert(alertData);
    return;
  }

  if (!approveDepositBankTime) {
    alertData.title = "Field Required";
    alertData.msg = "Please select the Deposit Transaction Time !";
    sharedErrorAlert(alertData);
    return;
  }

  if (!approveDepositAmt) {
    alertData.title = "Field Required";
    alertData.msg = "Please fill in Deposit Amount !";
    sharedErrorAlert(alertData);
    return;
  }

  if (!approveConvertCurrencyFrom) {
    alertData.title = "Field Required";
    alertData.msg = "Please select the Currency !";
    sharedErrorAlert(alertData);
    return;
  }

  if (approveConvertCurrencyFrom == 'LAK') {
    if (approveDepositAmt < 500) {
      alertData.title = "Insufficient Amount (LAK)";
      alertData.msg = "Deposit Amount must not less than 500 !";
      sharedErrorAlert(alertData);
      return;
    }
  }
  else {
    if (approveDepositAmt < 1) {
      alertData.title = "Insufficient Amount";
      alertData.msg = "Deposit Amount cannot be negative or zero amount !";
      sharedErrorAlert(alertData);
      return;
  }
}

  confirm({
    className: "confirmationModalStyle",
    title: 'Confirmation',
    centered: true,
    icon: <QuestionCircleOutlined />,
    content: 'Are you sure you want to proceed?',
    onOk() {
      console.log('OK');
      setFormDepositDetailLoading(true);
      onSubmitApproveDeposit();
    },
    onCancel() {
    },
    });
}

const onSubmitApproveDeposit = async ()=> {
  let fromCur = '';
  if (approveConvertCurrencyFrom == "LAK") { 
    fromCur = approveConvertCurrencyFrom; 
  }
  else { 
    fromCur = ''; 
  }

  let requestData = {
    id : approveDepositReceiptID,
    userId : approveDepositUserID,
    amount : approveDepositAmt,
    receipt : approveDepositReceipt,
    fromCur : fromCur,
    toCur : approveConvertCurrencyTo,
    toBankCode : approveDepositToBankCode,
    toBankAcc : approveDepositToBankAccNum,
    toBankAccName : approveDepositToBankAcc,
    fromBankCode : approveDepositFromBankCode,
    fromBankAcc : approveDepositFromBankAccNum != '' ? approveDepositFromBankAccNum : '-' ,
    fromBankAccName : '-',
    bankTime : approveDepositBankTime,
    csId : userId,
  };
  // console.log(`requestData Approve Deposit>>>`, requestData)

  csApproveTopUpReceipt(requestData)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === "ok") {
        onCancelApproveDeposit();
        ManualDepositList();
        setFormDepositDetailLoading(false);
        alertData.title = "Success";
        alertData.msg = json.msg;
        sharedSuccessAlert(alertData);
      }
      else {
        setFormDepositDetailLoading(false);
        alertData.title = "Error";
        alertData.msg = json.msg;
        sharedErrorAlert(alertData);
      }
  })
  .catch((error) => {
      console.log("error: ", error);
      alertData.title = "Error";
      alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
      sharedErrorAlert(alertData);
  });
}

const onCancelApproveDeposit = () => {
  formDepositDetail.resetFields();
  setApproveDepositReceiptID("");
  setApproveDepositUserID("");
  setApproveDepositAmt(0);
  setApproveConvertCurrencyFrom("");
  setApproveConvertCurrencyTo("");
  setApproveDepositToBankCode("");
  setApproveDepositToBankAccNum("");
  setApproveDepositToBankAcc("");
  setApproveDepositFromBankCode("");
  setApproveDepositFromBankAccNum("");
  setApproveDepositBankTime("");
  setApproveDepositVisible(false);
};

const handleChangeApproveConvertCurrency = (value) => {
  setApproveConvertCurrencyFrom(value);
}

const handleChangeApproveDepositBankTime = (value) => {
  setApproveDepositDisplayBankTime((moment(value)));
}

const currencyUI = () => {

}

const depositDetail = () => {
    return (
      <Form 
        form={formDepositDetail}
        layout="vertical">

        <Form.Item
          label="Bank Receipt Attachment"> 
          <Image
            // width={"100%"}
            height={300}
            src={approveDepositReceipt}
          >
          </Image>
        </Form.Item>

        {/* Member Acc */}
        <Form.Item
          label="Deposit From Bank Number"
          >
          <Input
            type="text"
            value={approveDepositFromBankAccNum}
            disabled={fromBankAccNumDisable}
            style={{width: 350}}
            onChange={(e) => {
              setApproveDepositFromBankAccNum(e.target.value);
            }}
          />
        </Form.Item>

        {/* Member Bank Code */}
        <Form.Item
          label="Deposit From Bank"
          required={true}
          >
            <Select
              optionFilterProp="children"
              value={approveDepositFromBankCode}
              disabled={fromBankCodeDisable}
              style={{width: 350}}
              filterOption={(input, option) => {
                return option.children.includes(input);
              }}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value, event) => {
                setApproveDepositFromBankCode(value);
              }}
            >
              {bankList.map((option) => {
                return (
                  <Option key={option.bankCode} value={`${option.bankCode}`}>
                    {option.bankName}
                  </Option>
                );
                })}
            </Select>
        </Form.Item>

        {/* Deposit To Bank*/}
        <Form.Item
          label="Deposit To Bank"
          required={true}
        >
          <Select
            optionFilterProp="children"
            value={approveDepositToDisplayBankData}
            disabled={depositToBankDisable}
            style={{width: 350}}
            filterOption={(input, option) => {
              return option.children.includes(input);
            }}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(value, event) => {
              setApproveDepositToDisplayBankData(value);
            }}
          >
            {depositBankList.map((option) => {
            return (
              <Option key={option.AccountNum} value={`${option.BankCode} || ${option.AccountNum} || ${option.AccountName}`}>
                  {option.BankCode} ({option.AccountNum})
              </Option>
            );
            })}
          </Select>
        </Form.Item>

        {/* Transaction Date Time */}
        <Form.Item
          label="Transaction Date Time"
          required={true}
        >
          <DatePicker
          format="YYYY-MM-DD HH:mm"
          showTime
          value={approveDepositDisplayBankTime}
          disabled={bankTimeDisable}
          style={{width: 230}}
          onChange={(value, dateString) => {
            let initialDate = new Date(dateString)
            let convertedDate = Math.floor(initialDate.getTime()/1000)
            setApproveDepositBankTime(convertedDate);
            handleChangeApproveDepositBankTime(dateString);
          }}
          />
        </Form.Item>

        {/* Approve Deposit Amount */}
        <Form.Item
          label="Deposit Amount"
          required={true}
        >
          <Row>
            <Col span={5.5}>
              {/* <InputNumber */}
              <Input
                style={{width:"130px"}}
                onChange={(e) =>
                  setApproveDepositAmt(e.target.value)
                }
                min={1}
                type="number"
                value={approveDepositAmt}
              />
            </Col>

            <Col span={5}>
              <Select
                value={approveConvertCurrencyFrom} 
                onChange={handleChangeApproveConvertCurrency}
                style={{width: 100}}
              >
                <Option value="LAK">LAK</Option>
                <Option value="THB">THB</Option>
              </Select>
            </Col>
          </Row>
        </Form.Item>

        {/* To be convert currency */}
        {/* <Form.Item
          label="Currency"
          required={true}
        >
          <Select 
            value={approveConvertCurrencyFrom} 
            onChange={handleChangeApproveConvertCurrency}
          >
            <Option value="LAK">LAK</Option>
            <Option value="THB">THB</Option>
          </Select>
        </Form.Item> */}

        <Form.Item style={{ textAlign: "center" }}>
          <Button 
            type="primary" 
            htmlType="submit"
            loading={formDepositDetailLoading}
            onClick={onCancelApproveDeposit}>
            Cancel
          </Button>

          <>
            <Button
              style={{
                marginRight: 20,
                marginLeft: 20,
                backgroundColor: "#4190f7",
                borderColor: "#4190f7",
              }}
              type="primary"
              htmlType="submit"
              loading={formDepositDetailLoading}
              onClick={() => {
                handleApproveManualDeposit();
              }}
            >
              Approve Deposit
            </Button>
            <Button
              danger
              type="primary"
              htmlType="submit"
              loading={formDepositDetailLoading}
              onClick={() => {
                showRejectModal();
              }}
            >
              Reject
            </Button>
          </>
        </Form.Item>
      </Form>
    );
  };

const showRejectModal = () => {
  setRejectDepositVisible(true);
  setSelected(false);
  return;
};

const handleRejectManuaDeposit = () => {
  if (!rejectReason) {
    alertData.title = "Field Required";
    alertData.msg = "Please fill in Reject Reason !";
    sharedErrorAlert(alertData);
    return;
  }

  confirm({
    className: "confirmationModalStyle",
    title: 'Confirmation',
    centered: true,
    icon: <QuestionCircleOutlined />,
    content: 'Are you sure you want to proceed?',
    onOk() {
      console.log('OK');
      setRejectButtonLoading(true);
      onSubmitRejectDeposit();
    },
    onCancel() {
    },
  });
}

const onSubmitRejectDeposit = async ()=> {
  let requestData = {
    id : approveDepositReceiptID,
    remark : rejectReason,
    csId : userId,
  };
  csRejectTopUpReceipt(requestData)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === "ok") {
        onCancelRejectDeposit();
        ManualDepositList();
        setRejectButtonLoading(false);
        alertData.title = "Success";
        alertData.msg = json.msg;
        sharedSuccessAlert(alertData);
      }
      else {
        setRejectButtonLoading(false);
        alertData.title = "Error";
        alertData.msg = json.msg;
        sharedErrorAlert(alertData);
      }
    })
    .catch((error) => {
      console.log("error: ", error);
      alertData.title = "Error";
      alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
      sharedErrorAlert(alertData);
    });   
}

const onCancelRejectDeposit = () => {
  formRejectDeposit.resetFields();
  setRejectReason("");
  setRejectDepositVisible(false);
};

const rejectView = () => {
  return (
    <div>
      <Form form={formRejectDeposit}>
        <Form.Item>
          <TextArea 
            rows={4}
            onChange={(e) => setRejectReason(e.target.value)} />
        </Form.Item>

        <Form.Item>
          <div style={{ textAlign: "center", marginTop: 30 }}>
            <Button
              loading={rejectButtonLoading}
              type="primary"
              htmlType="submit"
              style={{ marginRight: 10 }}
              onClick={() => {
                  onCancelRejectDeposit();
              }}
            >
            Cancel
            </Button>
            <Button
              loading={rejectButtonLoading}
              danger
              id="rejectbtn"
              name="rejectbtn"
              type="primary"
              htmlType="submit"
              style={{ marginLeft: 10 }}
              onClick={() => {
                handleRejectManuaDeposit();
              }}
            >
            Reject
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};


const viewMemberTransactionDetail = (record, detailType) => {
  var count = 0;

  if (detailType == "approve") {
    let requestData = {
      trxnID : record.transactionID,
      columnRequire : ["from_unixtime(SysCreateTime, '%Y-%m-%d %H:%i') as bankTime", "FromBankCode","ToBankAcc", "ToBankAccName","BeforeAmount","Amount","CurrencyPair","FromBankAcc"]
    }

    getMemberTransactionData(requestData)
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          const selectedData = Object.keys(json.data).length
          ? json.data.map((x) => {
              return {
                key: count++,
                userMobile : record.newNickname,
                bankTime : x.bankTime,
                depositFromBankAcc : x.FromBankAcc,
                depositFromBank : x.FromBankCode,
                depositToBank: x.ToBankAccName + ` (${x.ToBankAcc})`,
                depositAmt: x.BeforeAmount.toFixed(4) != 0 ? x.BeforeAmount.toFixed(4) : x.Amount.toFixed(4),
                depositCurrency: x.CurrencyPair != '' ? x.CurrencyPair : 'THB',
              };
                })
            : [];
            setDepositDetailData(selectedData);
            setDepositDetailVisible(true);
        }
        else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
    });
  }

  if (detailType == "reject") {
    let dataSet = [];
    let dataSetDetail = {
      userMobile: record.newNickname,
      bankTime : record.bankTime,
      depositFromBankAcc : record.bankAcctOut,
      depositFromBank : record.bankNameOut,
      depositToBank: record.bankAcctNameIn + ` (${record.bankAcctIn})`,
      depositAmt: "N/A",
      depositCurrency: "N/A",
    };

    dataSet.push(dataSetDetail);

    const selectedData = Object.keys(dataSet).length
      ? dataSet.map((x) => {
          return {
            key: count++,
            userMobile : x.userMobile,
            bankTime : x.bankTime,
            depositFromBankAcc : x.depositFromBankAcc != ' ' ? x.depositFromBankAcc : "N/A",
            depositFromBank : x.depositFromBank,
            depositToBank: x.depositToBank,
            depositAmt: x.depositAmt,
            depositCurrency: x.depositCurrency,
          };
            })
        : [];

    setDepositDetailData(selectedData);
    setDepositDetailVisible(true);
  }

  let receiptDomain = 'https://patuxaiapi2.site/';
  if (record.receipt.substring(0,5) == 'https') { 
    setDepositDetailReceiptID(record.receipt); 
  }
  else { 
    setDepositDetailReceiptID(`${receiptDomain}`+ record.receipt); 
  }
}

let searchInput = null;

const handleSearch = (selectedKeys, confirm) => {
  confirm();
  setSearchText(selectedKeys[0]);
};

const handleReset = (clearFilters) => {
  clearFilters();
  setSearchText("");
};

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          searchInput = node;
        }}
        placeholder={`Search ${dataIndex=="newNickname"? "nickname":dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Button
        type="primary"
        onClick={() => handleSearch(selectedKeys, confirm)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button
        onClick={() => handleReset(clearFilters)}
        size="small"
        style={{ width: 90 }}
      >
        Reset
      </Button>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      : false,
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) setTimeout(() => searchInput.select());
  },
  render: (text) =>
    text ? (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      false
    ),
  });

const columns = [
  {
    align: "center",
    title: "Date / Time",
    dataIndex: "sysCreateTime",
    width: "200px",
    ellipsis: true,
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
    fixed:"left",
  },
  {
    ellipsis: true,
    align: "center",
    title: "Receipt ID",
    dataIndex: "id",
    width :"200px",
    ...getColumnSearchProps("id"),
    render : (text) => {
      return <Tooltip title={text}>
        <label>{text}</label>
      </Tooltip>
  }
  },
  {
    ellipsis: true,
    align: "center",
    title: "User Mobile No",
    dataIndex: "newNickname",
    ...getColumnSearchProps("newNickname"),
    width: "200px",
  },
  {
    ellipsis: true,
    align: "center",
    title: "Status",
    dataIndex: "action",
    width: "150px",
    render: (text, record) => {
      switch (record.actionStatus) {
      case "Pending":
        return (
        <Tooltip title="Press Me!">
          <Button
            style={{ backgroundColor: "#F7B105", border: "none" }}
            name={"Check"}
            type="primary"
            onClick={(event) => {
                viewDepositDetail(record, event.target);
            }}
          >
            Process
          </Button>
        </Tooltip>
        );

      case "FollowUp":
        return (
          <Tooltip title="Press Me!">
            <Button
              style= {styles.followUpBtn}
              name={"Check"}
              type="primary"
              onClick={(event) => {
                viewDepositDetail(record, event.target);
              }}
            >
              Follow Up
            </Button>
          </Tooltip>
        );

      case "Rejected":
        return <>
          <Badge status="error" text="Rejected" />&nbsp;&nbsp;                
          <Tooltip title="View Details">
            <Button type="primary" shape="circle" icon={<InfoCircleFilled />} 
              onClick={()=>{
                let detailType = "reject";
                viewMemberTransactionDetail(record, detailType);
              }
            }
            />
          </Tooltip>
        </>

      case "Approved":
        return <>
          <Badge status="success" text="Approved" />&nbsp;&nbsp;                
              <Tooltip title="View Details">
                <Button type="primary" shape="circle" icon={<InfoCircleFilled />} 
                  onClick={()=>{
                    let detailType = "approve";
                    viewMemberTransactionDetail(record, detailType);
                  }
                }
                />
              </Tooltip>
            </>

      default:
          return <></>;
      }
  },
  },
  ];

  let depositDetailColumns = [
    {
      align: "center",
      title: "User Mobile",
      dataIndex: "userMobile",
      width: "150px",
      ellipsis: true,
    },
    {
      align: "center",
      title: "Transaction Date Time",
      dataIndex: "bankTime",
      width: "200px",
      ellipsis: true,
    },
    {
      align: "center",
      title: "User Bank Number",
      dataIndex: "depositFromBankAcc",
      width: "200px",
      ellipsis: true,
    },
    {
      align: "center",
      title: "User Bank Code",
      dataIndex: "depositFromBank",
      width: "200px",
      ellipsis: true,
    },
    {
      align: "center",
      title: "Deposit To Bank",
      dataIndex: "depositToBank",
      width: "200px",
      ellipsis: true,
    },
    {
      align: "center",
      title: "Deposit Amount",
      dataIndex: "depositAmt",
      width: "150px",
      ellipsis: true,
    },
    {
      align: "center",
      title: "Currency",
      dataIndex: "depositCurrency",
      width: "100px",
      ellipsis: true,
    },
  ];

const styles = {
  pageHeaderContainer: {
    justifyContent: isMobile ? "" : "space-between",
    display: "flex",
    flexDirection: isMobile ? "column" : "",
    alignItems: isMobile ? "center" : "",
  },
  refreshBtn: {
    marginLeft: 10,
  },
  followUpBtn: {
    backgroundColor:"#CF0808",
    border: "none"
  },
  tableContainer: {
    backgroundColor: "#FFFFFF",
    padding: 24,
  },
  paginationStyle: {
    marginTop: 50,
  },
  hide :{
    display:"none",
  }
};

return (
    <div className="manualDepositV3">
      <PageHeader ghost={false}>
        <div style={styles.pageHeaderContainer}>
          <div>
            <Button
              style={styles.refreshBtn}
              type="primary"
              onClick={() => {
                ManualDepositList();
              }}
            >
              Refresh Page
            </Button>
            <Button
            type="primary"
            style={styles.refreshBtn}
            onClick={() => {
              setManualDepositVisible(true);
            }}
            >
              Create Deposit
            </Button>
          </div>
          <Radio.Group
            buttonStyle={"solid"}
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <Radio.Button value="All">All</Radio.Button>
            <Radio.Button value="Pending">Pending</Radio.Button>
            <Radio.Button value="Approved">Approved</Radio.Button>
            <Radio.Button value="FollowUp">Follow Up</Radio.Button>
            <Radio.Button value="Rejected">Reject</Radio.Button>
          </Radio.Group>
        </div>
      </PageHeader>

    {/* Manual Deposit Modal */}
    <Modal
      title="Manual Deposit Form"
      visible={manualDepositVisible}
      width={400}
      getContainer={false}
      okButtonProps={({style:style.hide})} 
      cancelButtonProps={{ type: "primary", loading:formManualDepositLoading }}
      cancelText="Close"
      onCancel= {() => {
        onCancelManualDeposit();
      }}
      closable={false}
      maskClosable={false}
    >
      {onManualDeposit()}
    </Modal>

     {/* Approve Deposit / Reject Deposit Sharing Modal */}
    <Modal
      title={selected ? "Deposit Confirmation" : "Reject Confirmation"}
      visible={selected ? approveDepositVisible : rejectDepositVisible}
      footer={null}
      style={{ top : selected ? "1%" : "10%" }}
      closable={false}
      maskClosable={false}
      bodyStyle={{height: selected ? 860 : 250}}      
      width={500}
      getContainer={false}
      onCancel={selected ? onCancelApproveDeposit : onCancelRejectDeposit}
    >
      { selected ? depositDetail() : rejectView() }
    </Modal>
    <div style={styles.tableContainer}>
      <Table
        loading={tableLoading}
        columns={columns}
        dataSource={data}
        scroll={{ x: isMobile ? "110%" : "100%", y: null }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: settings.pageSizeOptions,
          style: styles.paginationStyle,
          alignmentBottom: 'right',
        }}
      >
      </Table>
    </div>

    <Modal 
      title="Transaction Details"
      visible={depositDetailVisible}
      style={{ top : "1%"}}
      onCancel={()=>{
        setDepositDetailVisible(false);
      }}
      closable={false}
      width={1200}
      bodyStyle={{height: 600}}
      footer={[
        <Button key="back"
        align="left"
        onClick={()=>{
          setDepositDetailVisible(false);
        }}
        >
          Close
        </Button>
      ]}
    >
     <Form 
      form={formViewDepositDetail} 
      name="viewDepositDetailform"
      layout="vertical">
        <Form.Item label="Bank Receipt Attachment">
            <Image
              // width={"100%"}
              height={300}
              src={depositDetailReceiptID}
            >
          </Image>
        </Form.Item>

        <Table
          loading={depositDetailTableLoading}
          columns={depositDetailColumns}
          dataSource={depositDetailData}
          // scroll={{ x: isMobile ? "110%" : "100%", y: null }}
          scroll={{ x:"100%"}}
      >
      </Table>

    </Form>
    </Modal>
  </div>
);
}