import React, { useState, useEffect , useRef } from "react";
import {
  //Table,
  message,
  PageHeader,
  Button,
  Modal,
  Input,
  Form,
  Tabs,
  DatePicker,
  Select,
  Spin,
} from "antd";
import { Table } from "ant-table-extensions";
import {
  opModPreview,
  opModuleNew,
  opModAssignCS,
  getCSList,
  opModuleList,
  opModuleListFilter,
  opModuleLastListFilter,
} from "../../../config/networkConfig";
import { useSelector } from "react-redux";
import { SearchOutlined,
         FileExcelOutlined} from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import { settings } from "../../../config/appConfig";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { FormatUNIXDate_YMDHM, FormatDate, FormatNullDate, FormatDateHHMM } from "../../../library/dates";
import { FormatNumComm } from "../../../library/numbers";
// import style from './components/OpModule/style/style';
import { filterCSList } from "../../../library/utils";
import TextArea from "antd/lib/input/TextArea";
import { opModuleVw } from "../../../library/permission";
import { OP_FILTER , PV_VALUE } from "../../../common/constants/constant";
import style from "../../../resource/style/style";
import { CNotAuthorized } from "../../../common/components/CNotAuthorized";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";

export default function ReportOperations() {
  const [ formPreview] = Form.useForm();
  
  const { Option } = Select;
  const {TabPane} = Tabs;
  const { role, username, compCode } = useSelector((state) => state.login);

  //Preview
  const [pvDepBefore,          setPvDepBefore] = useState("");
  const [pvData,                    setPvData] = useState([]);
  const [pvLoading,              setPvLoading] = useState(false);
  const [pvSubmit,                setPvSubmit] = useState(true);
  const [pvSaveSubmit,        setPvSaveSubmit] = useState(true);

  const [pvType,                   setPvType]  = useState("");
  const [pvLastDepFrom,      setPvLastDepFrom] = useState("");
  const [pvLastDepTo,          setPvLastDepTo] = useState("");
  const [pvLastLoginFrom,  setPvLastLoginFrom] = useState("");
  const [pvLastLoginTo,      setPvLastLoginTo] = useState("");

  const refPvLastDepFrom                     = useRef(null);
  const refPvLastDepTo                       = useRef(null);

  const refPvLastLoginFrom                   = useRef(null);
  const refPvLastLoginTo                     = useRef(null);

  const refPvType                            = useRef(null);
  const refPvDepBefore                       = useRef(null);
  //Preview Filter

  const [pvConfirmVisible, setPvConfirmVisible]= useState(false);

   const [tableLoading,       setTableLoading] = useState(false);
  //Filtered Results  

  const [filterLastDepFrom,      setFilterLastDepFrom] = useState("");
  const [filterLastDepTo,          setFilterLastDepTo] = useState("");
  const [filterLastLoginFrom,  setFilterLastLoginFrom] = useState("");
  const [filterLastLoginTo,      setFilterLastLoginTo] = useState("");
  
  const [filterDepBefore,          setFilterDepBefore] = useState("");

  const [filterData,                    setFilterData] = useState([]);
  const [filterLoading,              setFilterLoading] = useState(false);
  const [filterDeposit,              setFilterDeposit] = useState([]);

  //Filtered Results (Status Override)
  const [prevFilterLastDepFrom,      setPrevFilterLastDepFrom] = useState("");
  const [prevFilterLastDepTo,          setPrevFilterLastDepTo] = useState("");
  const [prevFilterLastLoginFrom,  setPrevFilterLastLoginFrom] = useState("");
  const [prevFilterLastLoginTo,      setPrevFilterLastLoginTo] = useState("");

  const [prevFilterDepBefore,          setPrevFilterDepBefore] = useState("");

  const [prevFilterData,                    setPrevFilterData] = useState([]);
  const [prevFilterLoading,              setPrevFilterLoading] = useState(false);
  const [prevFilterDeposit,              setPrevFilterDeposit] = useState([]);

  // Last Generated List (Status Override)
  const [lastFilterLastDepFrom,      setLastFilterLastDepFrom] = useState("");
  const [lastFilterLastDepTo,          setLastFilterLastDepTo] = useState("");
  const [lastFilterLastLoginFrom,  setLastFilterLastLoginFrom] = useState("");
  const [lastFilterLastLoginTo,      setLastFilterLastLoginTo] = useState("");

  const [lastFilterDepBefore,          setLastFilterDepBefore] = useState("");

  const [lastFilterData,                    setLastFilterData] = useState([]);
  const [lastFilterLoading,              setLastFilterLoading] = useState(false);
  const [lastFilterDeposit,              setLastFilterDeposit] = useState([]);
  

  //CS Lit
  const [csList,                            setCSList] = useState([]);
  const [ddlCSList,                      setDDLCSList] = useState([]);

  //Assign CS            
  const [assignCSVisible,          setAssignCSVisible] = useState(false);
  const [assignCSEnable,            setAssignCSEnable] = useState(false);
  const [assignCustomer,            setAssignCustomer] = useState([]); //Selected Members
  const [assignCS,                        setAssignCS] = useState("");
  const [assignCustomerSubmit,setAssignCustomerSubmit] = useState(true);
  const refAssignCS                                    = useRef(null);

  //Update Remark
  const [updateRemarkVisible,  setUpdateRemarkVisible] = useState(false);
  const [updateRemarkEnable,    setUpdateRemarkEnable] = useState(false);
  const [updateRemarkSubmit,    setUpdateRemarkSubmit] = useState(true)
  const [updateRemark,                setUpdateRemark] = useState("");

  //Update Date
  const [updateCSDateVisible,  setUpdateCSDateVisible] = useState(false);
  const [updateCSDateEnable,    setUpdateCSDateEnable] = useState(false);
  const [updateCSDate,                setUpdateCSDate] = useState("");

  //Member Transaction 
  const [searchText, setSearchText] = useState("");

  
  useEffect(() => {
    // eslint-disable-next-line
    if (opModuleVw(role, username, compCode)){
      initPage();
      csStaffList();
    }
  }, []);


  useEffect(()=> {
    setFilterData(filterData);
  },[filterData]);

  //#region Load List
  const initPage = async () =>{
    await operationList(true);

    let hasDepositList = [
      {
        text :'YES',
        value :'y',
      },
      {
        text :'NO',
        value :'n',
      }
    ]

    setFilterDeposit(hasDepositList);
  }

  const operationList = async (init=false) => {
    let count = 0 ;
    setFilterLoading(true);
    
    const respOp = await opModuleList({});
    let resp = await respOp.json();

    if (resp.status ==="ok") {
        const respData    = resp.data?.record;
        const respSummary = resp.data?.summary; 

        if (respSummary) {
            setFilterLastDepFrom(!respSummary?.lastdepositfrom?'':moment(respSummary?.lastdepositfrom).format("YYYY-MM-DD"));
            setFilterLastDepTo(!respSummary?.lastdepositto?'':moment(respSummary?.lastdepositto).format("YYYY-MM-DD"));
            setFilterLastLoginFrom(!respSummary?.lastloginfrom?'':moment(respSummary?.lastloginfrom).format("YYYY-MM-DD"));
            setFilterLastLoginTo(!respSummary?.lastloginto?'':moment(respSummary?.lastloginto).format("YYYY-MM-DD"));
            setFilterDepBefore(respSummary?.depositbefore?.toLowerCase()=='y'?'YES':respSummary?.depositbefore?.toLowerCase()=='n'? 'NO': '');
        }

        const opData = Object.keys(respData).length
          ? respData.map((obj) => {
              setTableLoading(false);
              return {
                key            : count++,
                id             : obj.id,
                userid         : obj.userid,
                phoneNum       : obj.mobile,
                dateRegister   : obj.registerdate,
                dateLastLogin  : obj.lastlogin,
                lastDepDate    : FormatNullDate(obj.lastDepDate), //obj.lastDepDate == "0" ? '': FormatUNIXDate(obj.lastDepDate),
                totalDep       : obj.totaldep,
                totalWit       : obj.totalwithdraw,
                depMax         : obj.depositamtmax,
                lastDeptAmt    : obj.lastdepositamt, // new column
                rescMission    : obj.rescuemission,
                lastUpdate     : obj.lastupdate,
                assignedTo     : obj.assignto,
                remark         : obj.remark,


                grandtotalDep  : FormatNumComm(obj.grandtotaldeposit),
                grandtotalWit  : obj.grandtotalwit,
                firstDep       : obj.firstdep,
                firstDepDate   : FormatNullDate(obj.firstdepdate),
                hasDeposit     : obj.hasdeposit,

              };
            })
          : [];

        setFilterData(opData);

        if (init){
          if (opData.length > 0 ) {
            document.getElementById("operation-report-tab-2").click();
          }else{
            document.getElementById("operation-report-tab-1").click();
          }
        }
    }

    setFilterLoading(false);
  
  }

  const operatioPrevList = async (init=false) => {
    let count = 0 ;
    
    setPrevFilterLoading(true);

    const respOp = await opModuleListFilter({ filtertype :"3"}); 
    let resp = await respOp.json();

    if (resp.status ==="ok") {
        const respData    = resp.data?.record;
        const respSummary = resp.data?.summary; 

        if (respSummary) {    
          setPrevFilterLastDepFrom(!respSummary?.lastdepositfrom?'':moment(respSummary?.lastdepositfrom).format("YYYY-MM-DD"));
          setPrevFilterLastDepTo(!respSummary?.lastdepositto?'':moment(respSummary?.lastdepositto).format("YYYY-MM-DD"));
          setPrevFilterLastLoginFrom(!respSummary?.lastloginfrom?'':moment(respSummary?.lastloginfrom).format("YYYY-MM-DD"));
          setPrevFilterLastLoginTo(!respSummary?.lastloginto?'':moment(respSummary?.lastloginto).format("YYYY-MM-DD"));
          setPrevFilterDepBefore(respSummary?.depositbefore?.toLowerCase()=='y'?'YES':respSummary?.depositbefore?.toLowerCase()=='n'? 'NO': '');
        }

        const opData = Object.keys(respData).length
          ? respData.map((obj) => {
              setTableLoading(false);
              return {
                key            : count++,
                id             : obj.id,
                userid         : obj.userid,
                phoneNum       : obj.mobile,
                dateRegister   : obj.registerdate,
                dateLastLogin  : obj.lastlogin,
                lastDepDate    : FormatNullDate(obj.lastDepDate), //obj.lastDepDate == "0" ? '': FormatUNIXDate(obj.lastDepDate),
                totalDep       : obj.totaldep,
                totalWit       : obj.totalwithdraw,
                depMax         : obj.depositamtmax,
                lastDeptAmt    : obj.lastdepositamt, // new column
                rescMission    : obj.rescuemission,
                lastUpdate     : obj.lastupdate,
                assignedTo     : obj.assignto,
                remark         : obj.remark,


                grandtotalDep  : FormatNumComm(obj.grandtotaldeposit),
                grandtotalWit  : obj.grandtotalwit,
                firstDep       : obj.firstdep,
                firstDepDate   : FormatNullDate(obj.firstdepdate),
                hasDeposit     : obj.hasdeposit,

              };
            })
          : [];

        setPrevFilterData(opData);

        // if (init){
        //   if (opData.length > 0 ) {
        //     document.getElementById("operation-report-tab-2").click();
        //   }else{
        //     document.getElementById("operation-report-tab-1").click();
        //   }
        // }
    }

    setPrevFilterLoading(false);
  
  }
  
  const operationLastList = async (init=false) => {
    let count = 0 ;
    
    setLastFilterLoading(true);

    const respOp = await opModuleLastListFilter({}); 
    let resp = await respOp.json();

    if (resp.status ==="ok") {
        const respData    = resp.data?.record;
        const respSummary = resp.data?.summary; 

        if (respSummary) {    
          setLastFilterLastDepFrom(!respSummary?.lastdepositfrom?'':moment(respSummary?.lastdepositfrom).format("YYYY-MM-DD"));
          setLastFilterLastDepTo(!respSummary?.lastdepositto?'':moment(respSummary?.lastdepositto).format("YYYY-MM-DD"));
          setLastFilterLastLoginFrom(!respSummary?.lastloginfrom?'':moment(respSummary?.lastloginfrom).format("YYYY-MM-DD"));
          setLastFilterLastLoginTo(!respSummary?.lastloginto?'':moment(respSummary?.lastloginto).format("YYYY-MM-DD"));
          setLastFilterDepBefore(respSummary?.depositbefore?.toLowerCase()=='y'?'YES':respSummary?.depositbefore?.toLowerCase()=='n'? 'NO': '');
        }

        const opData = Object.keys(respData).length
          ? respData.map((obj) => {
              setTableLoading(false);
              return {
                key            : count++,
                id             : obj.id,
                userid         : obj.userid,
                phoneNum       : obj.mobile,
                dateRegister   : obj.registerdate,
                dateLastLogin  : obj.lastlogin,
                lastDepDate    : FormatNullDate(obj.lastDepDate), //obj.lastDepDate == "0" ? '': FormatUNIXDate(obj.lastDepDate),
                totalDep       : obj.totaldep,
                totalWit       : obj.totalwithdraw,
                depMax         : obj.depositamtmax,
                lastDeptAmt    : obj.lastdepositamt, // new column
                rescMission    : obj.rescuemission,
                lastUpdate     : obj.lastupdate,
                assignedTo     : obj.assignto,
                remark         : obj.remark,


                grandtotalDep  : FormatNumComm(obj.grandtotaldeposit),
                grandtotalWit  : obj.grandtotalwit,
                firstDep       : obj.firstdep,
                firstDepDate   : FormatNullDate(obj.firstdepdate),
                hasDeposit     : obj.hasdeposit,

              };
            })
          : [];

        setLastFilterData(opData);

        // if (init){
        //   if (opData.length > 0 ) {
        //     document.getElementById("operation-report-tab-2").click();
        //   }else{
        //     document.getElementById("operation-report-tab-1").click();
        //   }
        // }
    }

    setLastFilterLoading(false);

  }
  ////#endregion

  //#region Functions
  const onChangePvType = (value) => {
    setPvType(value);

    if (value == OP_FILTER.DEPOSIT) {
      console.log('disable Login')
    }

    if (value == OP_FILTER.LOGIN) {
      console.log('disable Deposit')
    }
  }

  const onNewFilter2 = async ()=> {
    if (!pvType) {
      refPvType.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Filter Type !";
      sharedErrorAlert(alertData); 
      return;
    }

    if (pvType == OP_FILTER.DEPOSIT) {
      if (!pvLastDepFrom) {
        refPvLastDepFrom.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Last Deposit Date From !";
        sharedErrorAlert(alertData);
        return;
      }

      if (!pvLastDepTo) {
        refPvLastDepTo.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Last Deposit Date To !";
        sharedErrorAlert(alertData); 
        return;
      }
    }

    if (pvType == OP_FILTER.LOGIN) {
      if (!pvLastLoginFrom) {
        refPvLastLoginFrom.current.focus();
        alertData.title = "Error";
        alertData.msg = "Please select Last Login Date From !";
        sharedErrorAlert(alertData); 
        return;
      }

      if (!pvLastLoginTo) {
        refPvLastLoginTo.current.focus();
        alertData.title = "Error";
        alertData.msg = "Please select Last Login Date To !";
        sharedErrorAlert(alertData); 
        return;
      }
    }

    setPvConfirmVisible(true);
  }

  const onNewFilter = async ()=> {

    let count = 0;
    let depDetails;

    setPvSubmit(false);
    setPvLoading(true);

    let requestData = {
        lastdepositfrom  : !pvLastDepFrom ? '' :FormatDate(pvLastDepFrom),
        lastdepositto    : !pvLastDepTo ? '' :FormatDate(pvLastDepTo),
        lastloginfrom    : !pvLastLoginFrom ? '' :FormatDate(pvLastLoginFrom),
        lastloginto      : !pvLastLoginTo ? '' :FormatDate(pvLastLoginTo),
        type             : pvType,
        depositbefore    : pvDepBefore,
        version          : 1, 
    }

    console.log(`requestData >> ${JSON.stringify(requestData)}`);

    const respPv = await opModPreview(requestData);
    let result = await respPv.json();

    if (result.status === "ok") {

      const newData = Object.keys(result.data).length
      ? result.data.map((obj) => {
          setTableLoading(false);
          // depDetails = obj.lastdepamt2.split('||');
          return {
            key               : count++,
            userid            : obj.userid,
            phoneNum          : obj.mobile,
            dateRegister      : FormatDateHHMM(obj.registerdate),
            dateLastLogin     : FormatNullDate(obj.lastlogindate),
            // lastDepDate       : depDetails[1]==" " ? "": FormatUNIXDate_YMDHM(depDetails[1]),
            // lastDeptAmt       : depDetails[0]== 0? "":FormatNumComm(depDetails[0]),
            lastDeptAmt       : obj.lastdepamt == 0? "":FormatNumComm(obj.lastdepamt),
            lastDepDate       : FormatNullDate(obj.lasttopupdate),
            depMax            : FormatNumComm(obj.maxdep),
            // grandtotalDep     : "Save List to view figures", //obj.totaldep, //will change this column name in future
            grandtotalDep     : obj.totaldep == 0? "":FormatNumComm(obj.totaldep), //will change this column name in future
            totalWit          : obj.totalwithdraw,
            firstDep          : "",
            firstDepDate      : "",
            // qualifytotaldep  : obj.qualifytotaldep,
            totalDep          : "",
            // rescMission       : obj.rescuemission,
            // hasDeposit        : obj.hasdeposit,
            rescMission       : "N/A",
            hasDeposit        : "N/A",
          }
        })
      : [];



      setPvData(newData);
      alertData.title = "Success";
      alertData.msg = result.msg;
      sharedSuccessAlert(alertData);
      setPvConfirmVisible(false);

    } 
    else {
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
    }

    setPvLoading(false);
    setPvSubmit(true);
  }

  const onUpdateRemark = ()=> {
    setUpdateRemarkVisible(true);
  }


  const onCancelUpdateRemark = ()=> {
    setUpdateRemarkVisible(false);
    setUpdateRemark("");
  }

  const onUpdateMemberRemark = async ()=>{
    if (!assignCustomer) {
      // message.error("No Members selected!");
      alertData.title = "Error";
      alertData.msg = "No Members selected !";
      sharedErrorAlert(alertData);
      return;
    }

    let requestData = {
      id : assignCustomer,
      remark: updateRemark,
      type :'remark'
    }
    
    setUpdateRemarkSubmit(false);

    const result = await opModAssignCS(requestData);
    let resp = await result.json();

    if (resp.status ==="ok") {        

      await operationList();

      // message.success(resp.msg);
      alertData.title = "Success";
      alertData.msg = resp.msg;
      sharedSuccessAlert(alertData);
      setUpdateRemark("");
      setUpdateRemarkSubmit(true);
      setUpdateRemarkVisible(false);
    }
    else{
      // message.error(resp.msg);
      alertData.title = "Failed";
      alertData.msg = resp.msg;
      sharedFailAlert(alertData);
    }
  }

  const onAssignMemberToCS = async ()=> {
      if (!assignCS) {
        refAssignCS.current.focus();
        // message.error("Please select CS Staff");
        alertData.title = "Field Required";
        alertData.msg = "Please select CS Staff !";
        sharedErrorAlert(alertData);
        return;
      }

      if (!assignCustomer) {
        // message.error("No Members selected!");
        alertData.title = "Field Required";
        alertData.msg = "No Members selected !";
        sharedErrorAlert(alertData);
        return;
      }

      let requestData = {
        id : assignCustomer,
        assignto:assignCS,
        type :'cs'
      }
      
      setAssignCustomerSubmit(false);

      const result = await opModAssignCS(requestData);
      let resp = await result.json();

      if (resp.status === "ok") {        

        await operationList();

        // message.success(resp.msg);
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData);
        setAssignCustomerSubmit(true);
        setAssignCSVisible(false);
      }
      else{
        // message.error(resp.msg);
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);
      }
  }

  const onSavePreviewList = async () => {

      if (!pvData.length){
        alertData.title = "Error";
        alertData.msg = "No Preview Data to save !";
        sharedErrorAlert(alertData);
        return;
      }
      else {
        let requestData = {
          summary : {
            lastdepositfrom  : !pvLastDepFrom ? '' :FormatDate(pvLastDepFrom),
            lastdepositto    : !pvLastDepTo ? '' :FormatDate(pvLastDepTo),
            lastloginfrom    : !pvLastLoginFrom ? '' :FormatDate(pvLastLoginFrom),
            lastloginto      : !pvLastLoginTo ? '' :FormatDate(pvLastLoginTo),
            type             : pvType,
            depositbefore    : pvDepBefore,
            version          : 2, 
          }
        }

        // console.log(`requestData >> ${JSON.stringify(requestData)}`);

        setPvSaveSubmit(false);

        const response = await opModuleNew(requestData);
        let result = await response.json();
      
        if (result.status === "ok") {
          alertData.title = "Success";
          alertData.msg = result.msg;
          sharedSuccessAlert(alertData);
          await operationList();
          document.getElementById("operation-report-tab-2").click();
        }
        else {
          alertData.title = "Failed";
          alertData.msg = result.msg;
          sharedFailAlert(alertData);
        }
      }

      setPvSaveSubmit(true);

 }

 // rowSelection object indicates the need for row selection
 const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {

    if (selectedRows.length) {
      const selectedRowData = selectedRows.map((obj) => {
        return {
           id:obj.id,
        }
       });

      let selectedData =  Array.from(selectedRowData, x => x.id);

      setAssignCustomer(selectedData);
      setAssignCSEnable(true)
      setUpdateRemarkEnable(true);
      setUpdateCSDateEnable(true);
      }else{
        setAssignCSEnable(false);
        setUpdateRemarkEnable(false);
        setUpdateCSDateEnable(false);
      }

    },
    getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
    }),
  };

  const selectDepBefore = (value) => {
    setPvDepBefore(value);

    if (!value) {
      setPvDepBefore("");
    }

    // formPreview.setFieldsValue({
    //     pvLastDepTime: "",
    //   });
     
  }

  const csStaffList = async ()=> {  
      setCSList([]);

      var count = 0;
      setTableLoading(true);

      const respCS = await getCSList({});
      let resp = await respCS.json();
  
      if (resp.status ==="ok") {
          const newData = Object.keys(resp.data).length
            ? resp.data.map((obj) => {
                setTableLoading(false);
                return {
                  key: count++,
                  username:obj.username,
                };
              })
            : [];

          let filterCS = filterCSList(newData);

          setCSList(filterCS);

          let dropdownListCS = filterCS.map((item) => { 
            return {
              text : item.username,
              value: item.username
            };
          });

          setDDLCSList(dropdownListCS);

      }
  }

  const onFinish = async (values) => {


  }

  const onResetFilters = () => {
    setPvLastDepFrom("");
    setPvLastDepTo("");
    setPvLastLoginFrom("");
    setPvLastLoginTo("");
    //formPreview.resetFields(["pvLastDepTime", "pvLastLoginTime"])
    setPvDepBefore("");


  }

  const onChangeTab  = (key) => {
    if (key == 3) {
       operatioPrevList();
    }
    else if (key == 4) {
      operationLastList();
   }
  }

  const pvLastDepFromPicker = (date,dateString) => {
    setPvLastDepFrom(date);
  }

  const pvLastDepToPicker = (date,dateString) => {
    setPvLastDepTo(date);
  }

  const pvLastLoginFromPicker = (date,dateString) => {
    setPvLastLoginFrom(date);
  }

  const pvLastLoginToPicker = (date,dateString) => {
    setPvLastLoginTo(date);
  }


  // const pvDateFromPicker = (date, dateString) => {
  //  setPvDateFrom(date);
  // };

  // const pvDateToPicker = (date, dateString) => {
  //   setPvDateTo(date);
  //  };
 

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={dataIndex == "totalDep" ? style.searchBoxStyleMinusMarginRight : style.searchBoxStyle}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });
  ////#endregion

  
  //#region Columns
  //Excel Export
  const fields = {
    phoneNum: {
      header: "Phone \n No.",
      formatter: (_fieldValue, record) => {
        return  record?.phoneNum;
      },
    },
    dateRegister: {
      header: `Registered \n Date`,
      align : "center",
      formatter: (_fieldValue, record) => {
        return  record?.dateRegister;
      },
    },
    dateLastLogin: {
      header: `Last \n Login Date`,
      formatter: (_fieldValue, record) => {
        return  record?.dateLastLogin;
      },
    },
    lastDeptAmt: {
      header: `Last \n Deposit Amt.`,
      formatter: (_fieldValue, record) => {
        return  record?.lastDeptAmt;
      },
    },
    lastDepDate: {
      header: `Last \n Deposit Date`,
      formatter: (_fieldValue, record) => {
        return  record?.lastDepDate;
      },
    },
    depMax: {
      header: `Highest \n Deposit`,
      formatter: (_fieldValue, record) => {
        return  record?.depMax;
      },
    },
    grandtotalDep: {
      header: `Grand Total \n Deposit`,
      formatter: (_fieldValue, record) => {
        return  record?.grandtotalDep;
      },
    },
    grandtotalWit: {
      header: `Grand Total \n Withdraw`,
      formatter: (_fieldValue, record) => {
        return  record?.grandtotalWit;
      },
    },
    rescMission: {
      header: `Rescue \n Mission`,
      formatter: (_fieldValue, record) => {
        return  record?.rescMission.toLowerCase() ==='Y'? 'YES':'NO';
      },
    },
    hasDeposit:{
      header: `Has \n Deposit`,
      formatter: (_fieldValue, record) => {
        return  record?.hasDeposit.toLowerCase() ==='y'? 'YES':'NO';
      },
    },
    firstDep: {
      header: `First Deposit \n after generated`,
      formatter: (_fieldValue, record) => {
        return  record?.firstDep;
      },
    },
    firstDepDate: {
      header: `First Deposit Date\n after generated`,
      formatter: (_fieldValue, record) => {
        return  record?.firstDepDate;
      },
    },
    totalDep: {
      header: `Total Deposit \n after generated`,
      formatter: (_fieldValue, record) => {
        return  record?.totalDep;
      },
    },
    // assignedTo:{
    //   header: `Assgined \n To`,
    //   formatter: (_fieldValue, record) => {
    //     return  record?.assignedTo;
    //   },
    // },
    // remark:{
    //   header: `Remark`,
    //   formatter: (_fieldValue, record) => {
    //     return  record?.remark;
    //   },
    // },

  };


  //Display Columns
  let resultsColumns = [
    {
      align: "center",
      title: <div>Phone<br/>No.</div>,
      dataIndex:"phoneNum",
      width: "11%",
      ...getColumnSearchProps("phoneNum"),
    },
    {
      align: "center",
      title: <div>Registered <br/>Date</div>,
      dataIndex:"dateRegister",
      width: "10%",
      ...getColumnSearchProps("dateRegister"),
    },
    {
        align: "center",
        title: <div>Last <br/>Login Date</div>,
        dataIndex:"dateLastLogin",
        width: "10%",
        ...getColumnSearchProps("dateLastLogin"),
    },
    {
      align: "center",
      title: <div>Last <br/>Deposit Amount</div>,
      dataIndex:"lastDeptAmt",
      width: "10%",
      ...getColumnSearchProps("lastDeptAmt"),
    },
    {
      align: "center",
      title: <div>Last <br/>Deposit Date</div>,
      dataIndex:"lastDepDate",
      width: "10%",
      ...getColumnSearchProps("lastDepDate"),
    },
    {
      align: "center",
      title: <div>Highest <br/>Deposit</div>,
      dataIndex:"depMax",
      width: "10%",
      ...getColumnSearchProps("depMax"),
    },
    {
      align: "center",
      title: <div>Grand Total<br/>Deposit</div>,
      dataIndex:"grandtotalDep",
      width: "10%",
      ...getColumnSearchProps("grandtotalDep"),
    },
    {
      align: "center",
      title: <div>Grand Total<br/>Withdraw</div>,
      dataIndex:"grandtotalWit",
      width: "10%",
      ...getColumnSearchProps("grandtotalWit"),
    },
    {
      align: "center",
      title: <div>Rescue <br/>Mission</div>,
      dataIndex:"rescMission",
      width: "10%",
      ...getColumnSearchProps("rescMission"),
      render: (text)=>{
        return text.toLowerCase()==="y" ? (<span style={style.yesStyle}>YES</span>):(<span style={style.noStyle}>NO</span>);
      },
    },
    {
      align: "center",
      title: <div>Has <br/>Deposit?</div>,
      dataIndex:"hasDeposit",
      width: "10%",
      render: (text)=>{
        return text.toLowerCase()==="y" ? (<span style={style.yesStyle}>YES</span>):(<span style={style.noStyle}>NO</span>);
      },
      filters : filterDeposit,
      filterMultiple: false,
      onFilter: (value, record) => record.hasDeposit.toLowerCase().indexOf(value.toLowerCase()) === 0 ,
    },
    {
      align: "center",
      title: <div>First  <br/>Deposit </div>,
      dataIndex:"firstDep",
      width: "10%",
      ...getColumnSearchProps("firstDep"),

    },
    {
      align: "center",
      title: <div>First  <br/>Deposit Date</div>,
      dataIndex:"firstDepDate",
      width: "10%",
      ...getColumnSearchProps("firstDepDate"),

    },
    {
        align: "center",
        title: <div>Total <br/>Deposit</div>,
        dataIndex:"totalDep",
        width: "10%",
        ...getColumnSearchProps("totalDep"),
    },
    // {
    //   align: "center",
    //   title: <div>Assigned <br/>To</div>,
    //   dataIndex: "assignedTo",
    //   width: "10%",
    //   ellipsis: true,
    //   filters : ddlCSList,
    //   filterMultiple: false,
    //   onFilter: (value, record) => record.assignedTo.toLowerCase().indexOf(value.toLowerCase()) === 0 ,
    //   render: (text)=>{
    //     return text;
    //   },
    // },
  ];

  let previewColumns =  [
    {
      align: "center",
      title: <div>Phone<br/>No.</div>,
      dataIndex:"phoneNum",
      width: "11%",
      ...getColumnSearchProps("phoneNum"),
    },
    {
      align: "center",
      title: <div>Registered <br/>Date</div>,
      dataIndex:"dateRegister",
      width: "9%",
      ...getColumnSearchProps("dateRegister"),
    },
    {
        align: "center",
        title: <div>Last <br/>Login Date</div>,
        dataIndex:"dateLastLogin",
        width: "10%",
        ...getColumnSearchProps("dateLastLogin"),
    },
    {
      align: "center",
      title: <div>Last <br/>Deposit Amount</div>,
      dataIndex:"lastDeptAmt",
      width: "10%",
      ...getColumnSearchProps("lastDeptAmt"),
    },
    {
      align: "center",
      title: <div>Last <br/>Deposit Date</div>,
      dataIndex:"lastDepDate",
      width: "10%",
      ...getColumnSearchProps("lastDepDate"),
    },
    {
      align: "center",
      title: <div>Highest <br/>Deposit</div>,
      dataIndex:"depMax",
      width: "10%",
      ...getColumnSearchProps("depMax"),
    },
    {
      align: "center",
      title: <div>Grand Total<br/>Deposit</div>,
      dataIndex:"grandtotalDep",
      width: "10%",
      ...getColumnSearchProps("grandtotalDep"),
    },
    {
      align: "center",
      title: <div>Grand Total<br/>Withdraw</div>,
      dataIndex:"totalWit",
      width: "10%",
      ...getColumnSearchProps("totalWit"),
    },
    {
        align: "center",
        title: <div>Rescue <br/>Mission</div>,
        dataIndex:"rescMission",
        width: "10%",
        ...getColumnSearchProps("rescMission"),
        render: (text)=>{
          if (text.toLowerCase()==="y") {
            return (<span style={style.yesStyle}>YES</span>)
          }else {
            return (<span style={style.noStyle}>NO</span>)
          }
        },
    },
    {
      align: "center",
      title: <div>Has <br/>Deposit?</div>,
      dataIndex:"hasDeposit",
      width: "10%",
      render: (text)=>{
        return text.toLowerCase()==="y" ? (<span style={style.yesStyle}>YES</span>):(<span style={style.noStyle}>NO</span>);
      },
      filters : filterDeposit,
      filterMultiple: false,
      onFilter: (value, record) => record.hasDeposit.toLowerCase().indexOf(value.toLowerCase()) === 0 ,
    },
    {
      align: "center",
      title: <div>First  <br/>Deposit </div>,
      dataIndex:"firstDep",
      width: "10%",
      ...getColumnSearchProps("firstDep"),

    },
    {
      align: "center",
      title: <div>First  <br/>Deposit Date</div>,
      dataIndex:"firstDepDate",
      width: "10%",
      ...getColumnSearchProps("firstDepDate"),

    },
    {
        align: "center",
        title: <div>Total <br/>Deposit</div>,
        dataIndex:"totalDep",
        width: "10%",
        ...getColumnSearchProps("totalDep"),
    },
  ];
    
  let previewColumnsOld = [
    {
      align: "center",
      title: <div>Phone<br/>No.</div>,
      dataIndex:"mobile",
      width: "5%",
    },
    {
      align: "center",
      title: <div>Last<br/>Login Date</div>,
      dataIndex:"lastlogindate",
      width: "6%",
    },
    {
      align: "center",
      title: <div>Registered <br/> Date</div>,
      dataIndex:"registerdate",
      width: "6%",
    },
    // {
    //   align: "center",
    //   title: <div>Last Login <br/>Epoch</div>,
    //   dataIndex:"lastloginepoch",
    //   width: "5%",
    // },
    // {
    //   align: "center",
    //   title: <div>Batch Id</div>,
    //   dataIndex:"batchid",
    //   width: "5%",
    // },
    {
      align: "center",
      title: <div>Last <br/>Deposit Date</div>,
      dataIndex:"lastopupdate",
      width: "6%",
      render: (text) => {
        return text;
      }
    },
    {
      align: "center",
      title: <div>Last <br/>Withdraw Date</div>,
      dataIndex:"lastwithdrawdate",
      width: "6%",
      render: (text) => {
        return text;
      }
    },
    {
      align: "center",
      title: <div>Qualify <br/>Total Dep.</div>,
      dataIndex:"qualifytotaldep",
      width: "5%",
    },
    {
      align: "center",
      title: <div>Total Deposit</div>,
      dataIndex:"totaldep",
      width: "5%",
    },
    {
      align: "center",
      title: <div>Total Withdraw</div>,
      dataIndex:"totalwithdraw",
      width: "5%",
    },
    {
      align: "center",
      title: <div>Max. <br/>Deposit</div>,
      dataIndex:"maxdep",
      width: "5%",
    },
    {
      align: "center",
      title: <div>Last <br/>Dep. Amt.</div>,
      dataIndex:"lastdepamt",
      width: "5%",
    },
    {
      align: "center",
      title: <div>Rescue <br/>Mission</div>,
      dataIndex:"rescuemission",
      width: "5%",
    },
    {
      align: "center",
      title: <div>Has <br/>Deposit</div>,
      dataIndex:"hasdeposit",
      width: "5%",
    },
  ];
  ////#endregion

  return (
    <div>
        {
          opModuleVw(role, username, compCode)?

          <div style={style.tabelContainer} id="page-operation-report">
          <Tabs defaultActiveKey="1" onChange={onChangeTab} id="operation-report">
              <TabPane tab="Preview" key="1">
                  <PageHeader ghost={false}>
                          <div style={style.pageHeaderContainer}>
                          <div style={{float:"left"}}>

                                  <Form
                                  form={formPreview}
                                  name="basic"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 24 }}
                                      onFinish={onFinish}
                                  >
                                      <Form.Item label="Select Filter Type" required={true}>
                                        <Select value={pvType}
                                          dropdownStyle={{ position: "fixed" }}
                                          style={{...style.datePickerStyle, ...style.refreshBtn}}
                                          onChange={onChangePvType}
                                          rules={[{ required: true, message: 'Please select a Filter Type!' }]}
                                          ref={refPvType}
                                        >
                                          <Option value={OP_FILTER.DEPOSIT}>{OP_FILTER.DEPOSIT}</Option>
                                          <Option value={OP_FILTER.LOGIN}>{OP_FILTER.LOGIN}</Option>
                                        </Select>
                                      </Form.Item>
                                      <Form.Item
                                          label="Last Deposit Date From"
                                          name="Last Deposit Date To"
                                      >
                                      <DatePicker
                                          key="1"
                                          style={{...style.datePickerStyle, ...style.refreshBtn}}
                                          onChange={pvLastDepFromPicker}
                                          value={pvLastDepFrom}
                                          ref={refPvLastDepFrom}
                                          refs
                                          disabled={pvType!=OP_FILTER.DEPOSIT?true:false}
                                          placeholder={"Select Date From"}
                                          disabledDate={(current)=> {
                                          return current > moment().endOf('day');
                                          }}/>
                                      <DatePicker
                                          key="2"
                                          style={{...style.datePickerStyle, ...style.refreshBtn, ...style.dateToMarginTop}}
                                          onChange={pvLastDepToPicker}
                                          value={pvLastDepTo}
                                          ref={refPvLastDepTo}
                                          disabled={pvType!=OP_FILTER.DEPOSIT?true:false}
                                          placeholder={"Select Date To"}
                                          disabledDate={(current)=> {
                                            let dateFr = pvLastDepFrom;
                                            if (dateFr) {
                                                return current < dateFr || current > moment().add(1, 'day').add(-1, 'minute') ;
                                            }else {
                                                return  current > moment().subtract(1, 'day');
                                            }
                                          }}
                                          defaultPickerValue={()=>{
                                              let dateFr = pvLastDepFrom;
                                              if (dateFr) {
                                                  return dateFr;
                                              }
                                          }}
                                          />
                                      </Form.Item>
                                      <Form.Item
                                          label="Last Login Date From"
                                          name="Last Login Date To"
                                      >
                                      <DatePicker
                                          key="3"
                                          style={{...style.datePickerStyle, ...style.refreshBtn}}
                                          onChange={pvLastLoginFromPicker}
                                          value={pvLastLoginFrom}
                                          ref={refPvLastLoginFrom}
                                          refs
                                          disabled={pvType!=OP_FILTER.LOGIN?true:false}
                                          placeholder={"Select Date From"}
                                          disabledDate={(current)=> {
                                          return current > moment().endOf('day');
                                          }}/>
                                      <DatePicker
                                          key="4"
                                          style={{...style.datePickerStyle, ...style.refreshBtn, ...style.dateToMarginTop}}
                                          onChange={pvLastLoginToPicker}
                                          value={pvLastLoginTo}
                                          ref={refPvLastLoginTo}
                                          disabled={pvType!=OP_FILTER.LOGIN?true:false}
                                          placeholder={"Select Date To"}
                                          disabledDate={(current)=> {
                                            let dateFr = pvLastLoginFrom;
                                            if (dateFr) {
                                                return current < dateFr || current > moment().add(1, 'day').add(-1, 'minute') ;
                                            }else {
                                                return  current > moment().subtract(1, 'day');
                                            }
                                          }}
                                          defaultPickerValue={()=>{
                                              let dateFr = pvLastLoginFrom;
                                              if (dateFr) {
                                                  return dateFr;
                                              }
                                          }}
                                          />
                                      </Form.Item>
                                      <Form.Item
                                          label="Deposit Before"
                                          name="Deposit Before"
                                          // rules={[{ required: true, message: 'Please input your username!' }]}
                                      >
                                              <Select 
                                                  value={pvDepBefore}
                                                  onChange={selectDepBefore}
                                                  style={{...style.datePickerStyle, ...style.refreshBtn}}
                                                  ref={refPvDepBefore}
                                                  allowClear={true}
                                                  >
                                                  <Option value="Y">YES</Option>
                                                  <Option value="N">NO</Option>
                                              </Select>
                                              &nbsp;&nbsp;before Deposit / Login Date Range
                                      </Form.Item>

                                      <Form.Item
                                          name={<div>&nbsp;</div>}
                                          label=""
                                          style={{float:"right",marginRight:"170px"}}
                                      >
                                          <Button
                                              style={style.refreshBtn}
                                              type="primary"
                                              onClick={onResetFilters}
                                          >
                                              Reset
                                          </Button>
                                          
                                          <Button
                                              style={style.refreshBtn}
                                              type="primary"
                                              onClick={()=>{
                                                onNewFilter2();
                                                //setPvConfirmVisible(true);
                                              }}
                                          >
                                          Create New Filter
                                          </Button>
                                      </Form.Item>
                                  </Form>
                          </div>
                          </div>
                  </PageHeader>
                  <div>   
                      
                      {
                        pvSaveSubmit?
                        <Button
                          style={{...style.refreshBtn}}
                          type="primary"
                          onClick={() => {
                              onSavePreviewList();
                          }}
                      >
                          Save Preview List
                        </Button>: <Spin tip="Processing..."></Spin>
                      }

                  </div>
                  <div>
                      <Table
                          className="operationsList"
                          loading={pvLoading}
                          style={{marginTop:"10px"}}
                          columns ={previewColumns}
                          dataSource= {pvData}
                          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                          pagination={{
                            defaultPageSize: 100,
                            showSizeChanger: true,
                            pageSizeOptions: settings.pageSizeOptionsMedium,
                            style: style.paginationStyle,
                            alignmentBottom: 'right',
                          }}
                      />
                  </div>
              </TabPane>
              <TabPane tab="List" key="2">
                  <PageHeader ghost={false}>
                          <div style={style.pageHeaderContainer}>
                          <div style={{float:"left"}}>
                                  <Form
                                  name="basic"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 24 }}
                                  >
                                      <Form.Item
                                          label="Last Deposit Date"
                                          name="Last Deposit Date"
                                      >
                                          <DatePicker
                                              style={{...style.datePickerStyle, ...style.refreshBtn,...style.hide}}
                                              refs
                                              placeholder={"Select Last Deposit"}
                                              disabledDate={(current)=> {
                                              return current > moment().endOf('day');
                                              }}/>
                                          <div style={{...style.filterDataStyle}}>{filterLastDepFrom}<span style={style.filterLabelStyle}>to</span> {filterLastDepTo}</div>
                                      </Form.Item>
                                      <Form.Item
                                          label="Last Login Date"
                                          name="Last Login Date"
                                      >
                                          <div style={{...style.filterDataStyle}}>{filterLastLoginFrom}<span style={style.filterLabelStyle}>to</span> {filterLastLoginTo}</div>
                                      </Form.Item>
                                      <Form.Item
                                          label="Deposit Before"
                                          name="Deposit Before"
                                      >
                                          <div style={{...style.filterDataStyle}}>{filterDepBefore}</div>
                                      </Form.Item>
                                      <Form.Item
                                          name={<div>&nbsp;</div>}
                                          label=""
                                          style={{float:"right",marginRight:"170px"}}
                                      >
                                          <Button
                                              style={{...style.refreshBtn,visibility:"hidden"}}
                                              type="primary"
                                              onClick={onResetFilters}
                                          >
                                              Reset
                                          </Button>
                                          
                                          <Button
                                              style={{...style.refreshBtn,visibility:"hidden"}}
                                              type="primary"
                                              onClick=""
                                          >
                                          Create New Filter
                                          </Button>
                                      </Form.Item>
                                  </Form>
                          </div>
                          </div>
                  </PageHeader>
                  <div>
                      <Button
                          type="primary"
                          onClick={() => {
                            setAssignCSVisible(true);
                          }}
                          disabled={!assignCSEnable}
                          style={{display:"none"}}
                          >
                          Assign Customer Service
                      </Button>
                      <Button
                          type="primary"
                          onClick={() => {
                            setUpdateRemarkVisible(true);
                          }}
                          disabled={!updateRemarkEnable}
                          style={{display:"none"}}
                          >
                          Update Remark
                      </Button>
                      <Button
                          type="primary"                      
                          onClick={() => {
                            setUpdateCSDateVisible(true);
                            setUpdateCSDate(new moment().format("YYYY-MM-DD HH:mm:ss"));
                          }}
                            disabled={!updateCSDateEnable}
                            style={{display:"none"}}
                          >
                            Assign Contact Date
                      </Button>
                      <Button
                          style={{...style.refreshBtn}}
                          type="primary"
                          onClick={() => {
                              document.getElementById("export-button-2").click();
                          }}
                          >
                          Export to Excel
                      </Button>
                  </div>
                  <div>
                      <Table
                          className="operationsList"
                          loading={filterLoading}
                          style={{marginTop:"10px"}}
                          // rowSelection={{
                          //     type: 'checkbox',
                          //     className:'opCheckbox',
                          //     ...rowSelection,
                          //   }}
                          columns ={resultsColumns}
                          dataSource= {filterData}
                          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                          pagination={{
                            defaultPageSize: 100,
                            showSizeChanger: true,
                            pageSizeOptions: settings.pageSizeOptionsMedium,
                            style: style.paginationStyle,
                            alignmentBottom: 'right',
                          }}
                          exportableProps={{
                            fields,
                            fileName: `memberList_${moment(new Date()).format("YYYYMMDDHHmmss")}.xlsx`,
                            btnProps: {
                              type: "primary",
                              icon: <FileExcelOutlined />,
                              id:"export-button-2",
                              children: <span>Export to Excel</span>,
                              style: {display:"none"}
                            },
                          }}
                      />
                  </div>
              </TabPane>
              <TabPane tab="Previously Generated List" key="3">
                  <PageHeader ghost={false}>
                        <div style={style.pageHeaderContainer}>
                          <div style={{float:"left"}}>
                                  <Form
                                  name="basic"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 24 }}
                                  >
                                      <Form.Item
                                          label="Last Deposit Date"
                                          name="Last Deposit Date"
                                      >
                                          <DatePicker
                                              style={{...style.datePickerStyle, ...style.refreshBtn,...style.hide}}
                                              refs
                                              placeholder={"Select Last Deposit"}
                                              disabledDate={(current)=> {
                                              return current > moment().endOf('day');
                                              }}/>
                                          <div style={{...style.filterDataStyle}}>{prevFilterLastDepFrom}<span style={style.filterLabelStyle}>to</span> {prevFilterLastDepTo}</div>
                                      </Form.Item>
                                      <Form.Item
                                          label="Last Login Date"
                                          name="Last Login Date"
                                      >
                                          <div style={{...style.filterDataStyle}}>{prevFilterLastLoginFrom}<span style={style.filterLabelStyle}>to</span> {prevFilterLastLoginTo}</div>
                                      </Form.Item>
                                      <Form.Item
                                          label="Deposit Before"
                                          name="Deposit Before"
                                      >
                                          <div style={{...style.filterDataStyle}}>{prevFilterDepBefore}</div>
                                      </Form.Item>
                                      <Form.Item
                                          name={<div>&nbsp;</div>}
                                          label=""
                                          style={{float:"right",marginRight:"170px"}}
                                      >
                                          <Button
                                              style={{...style.refreshBtn,visibility:"hidden"}}
                                              type="primary"
                                              onClick={onResetFilters}
                                          >
                                              Reset
                                          </Button>
                                          
                                          <Button
                                              style={{...style.refreshBtn,visibility:"hidden"}}
                                              type="primary"
                                              onClick=""
                                          >
                                          Create New Filter
                                          </Button>
                                      </Form.Item>
                                  </Form>
                          </div>
                          </div>
                  </PageHeader>
                  <div>
                      <Button
                          type="primary"
                          onClick={() => {
                            setAssignCSVisible(true);
                          }}
                          disabled={!assignCSEnable}
                          style={{display:"none"}}
                          >
                          Assign Customer Service
                      </Button>
                      <Button
                          type="primary"
                          onClick={() => {
                            setUpdateRemarkVisible(true);
                          }}
                          disabled={!updateRemarkEnable}
                          style={{display:"none"}}
                          >
                          Update Remark
                      </Button>
                      <Button
                          type="primary"                      
                          onClick={() => {
                            setUpdateCSDateVisible(true);
                            setUpdateCSDate(new moment().format("YYYY-MM-DD HH:mm:ss"));
                          }}
                            disabled={!updateCSDateEnable}
                            style={{display:"none"}}
                          >
                            Assign Contact Date
                      </Button>
                      <Button
                          style={{...style.refreshBtn}}
                          type="primary"
                          onClick={() => {
                              document.getElementById("export-button-3").click();
                          }}
                          >
                          Export to Excel
                      </Button>
                  </div>
                  <div>
                      <Table
                          className="operationsList"
                          loading={prevFilterLoading} 
                          style={{marginTop:"10px"}}
                          // rowSelection={{
                          //     type: 'checkbox',
                          //     className:'opCheckbox',
                          //     ...rowSelection,
                          //   }}
                          columns ={resultsColumns}
                          dataSource= {prevFilterData}
                          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                          pagination={{
                            defaultPageSize: 100,
                            showSizeChanger: true,
                            pageSizeOptions: settings.pageSizeOptionsMedium,
                            style: style.paginationStyle,
                            alignmentBottom: 'right',
                          }}
                          exportableProps={{
                            fields,
                            fileName: `memberList_gen_list_${moment(new Date()).format("YYYYMMDDHHmmss")}.xlsx`,
                            btnProps: {
                              type: "primary",
                              icon: <FileExcelOutlined />,
                              id:"export-button-3",
                              children: <span>Export to Excel</span>,
                              style: {display:"none"}
                            },
                          }}
                      />
                  </div>
              </TabPane>
              <TabPane tab="Last Generated List" key="4">
                  <PageHeader ghost={false}>
                        <div style={style.pageHeaderContainer}>
                          <div style={{float:"left"}}>
                                  <Form
                                  name="basic"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 24 }}
                                  >
                                      <Form.Item
                                          label="Last Deposit Date"
                                          name="Last Deposit Date"
                                      >
                                          <DatePicker
                                              style={{...style.datePickerStyle, ...style.refreshBtn,...style.hide}}
                                              refs
                                              placeholder={"Select Last Deposit"}
                                              disabledDate={(current)=> {
                                              return current > moment().endOf('day');
                                              }}/>
                                          <div style={{...style.filterDataStyle}}>{lastFilterLastDepFrom}<span style={style.filterLabelStyle}>to</span> {lastFilterLastDepTo}</div>
                                      </Form.Item>
                                      <Form.Item
                                          label="Last Login Date"
                                          name="Last Login Date"
                                      >
                                          <div style={{...style.filterDataStyle}}>{lastFilterLastLoginFrom}<span style={style.filterLabelStyle}>to</span> {lastFilterLastLoginTo}</div>
                                      </Form.Item>
                                      <Form.Item
                                          label="Deposit Before"
                                          name="Deposit Before"
                                      >
                                          <div style={{...style.filterDataStyle}}>{lastFilterDepBefore}</div>
                                      </Form.Item>
                                      <Form.Item
                                          name={<div>&nbsp;</div>}
                                          label=""
                                          style={{float:"right",marginRight:"170px"}}
                                      >
                                          <Button
                                              style={{...style.refreshBtn,visibility:"hidden"}}
                                              type="primary"
                                              onClick={onResetFilters}
                                          >
                                              Reset
                                          </Button>
                                          
                                          <Button
                                              style={{...style.refreshBtn,visibility:"hidden"}}
                                              type="primary"
                                              onClick=""
                                          >
                                          Create New Filter
                                          </Button>
                                      </Form.Item>
                                  </Form>
                          </div>
                          </div>
                  </PageHeader>
                  <div>
                      <Button
                          type="primary"
                          onClick={() => {
                            setAssignCSVisible(true);
                          }}
                          disabled={!assignCSEnable}
                          style={{display:"none"}}
                          >
                          Assign Customer Service
                      </Button>
                      <Button
                          type="primary"
                          onClick={() => {
                            setUpdateRemarkVisible(true);
                          }}
                          disabled={!updateRemarkEnable}
                          style={{display:"none"}}
                          >
                          Update Remark
                      </Button>
                      <Button
                          type="primary"                      
                          onClick={() => {
                            setUpdateCSDateVisible(true);
                            setUpdateCSDate(new moment().format("YYYY-MM-DD HH:mm:ss"));
                          }}
                            disabled={!updateCSDateEnable}
                            style={{display:"none"}}
                          >
                            Assign Contact Date
                      </Button>
                      <Button
                          style={{...style.refreshBtn}}
                          type="primary"
                          onClick={() => {
                              document.getElementById("export-button-4").click();
                          }}
                          >
                          Export to Excel
                      </Button>
                  </div>
                  <div>
                      <Table
                          className="operationsList"
                          loading={lastFilterLoading} 
                          style={{marginTop:"10px"}}
                          columns ={resultsColumns}
                          dataSource= {lastFilterData}
                          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                          pagination={{
                            defaultPageSize: 100,
                            showSizeChanger: true,
                            pageSizeOptions: settings.pageSizeOptionsMedium,
                            style: style.paginationStyle,
                            alignmentBottom: 'right',
                          }}
                          exportableProps={{
                            fields,
                            fileName: `memberList_gen_list_${moment(new Date()).format("YYYYMMDDHHmmss")}.xlsx`,
                            btnProps: {
                              type: "primary",
                              icon: <FileExcelOutlined />,
                              id:"export-button-4",
                              children: <span>Export to Excel</span>,
                              style: {display:"none"}
                            },
                          }}
                      />
                  </div>
              </TabPane>
          </Tabs>
          <Modal 
            visible={updateCSDateVisible}
            footer={[
              <Button
                type={"primary"}
                onClick={()=> {
                  setUpdateCSDateVisible(false);
                }}
              >
                Cancel
              </Button>
              ,
              assignCustomerSubmit?
                <Button 
                    type={"danger"} 
                    onClick={() => {
                      onAssignMemberToCS();
                    }}
                >
                Submit
              </Button>: <Spin tip="Processing..."></Spin>
          ]}
          maskClosable={false}
          >
            {updateCSDate}
          </Modal>
          <Modal 
            visible={assignCSVisible}
            okText={"Submit"}
            title= "Customer Assignment"
            maskClosable={false}
            footer={[
                <Button
                  type={"primary"}
                  onClick={()=> {
                    setAssignCSVisible(false);
                  }}
                >
                  Cancel
                </Button>
                ,
                assignCustomerSubmit?
                  <Button 
                      type={"danger"} 
                      onClick={() => {
                        onAssignMemberToCS();
                      }}
                  >
                  Submit
                </Button>: <Spin tip="Processing..."></Spin>
              
            ]}
            >
              Assigned To
              <Select
                style={style.datePickerStyle2}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.children.includes(input);
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value, event) => {
                  setAssignCS(value);
                }}
                ref={refAssignCS}
              >
                {csList.map((option) => {
                  return (
                    <Option key={option.username} value={`${option.username}`}>
                      {option.username} 
                    </Option>
                  );
                })}
              </Select>       
          </Modal> 
          <Modal 
            visible={updateRemarkVisible}
            okText={"Submit"}
            title= "Update Remarks"
            maskClosable={false}
            footer={[
                <Button
                  type={"primary"}
                  onClick={()=> {
                    onCancelUpdateRemark();
                  }}
                >
                  Cancel
                </Button>
                ,
                updateRemarkSubmit?
                  <Button 
                      type={"danger"} 
                      onClick={() => {
                        onUpdateMemberRemark();
                      }}
                  >
                  Submit
                </Button>: <Spin tip="Processing..."></Spin>
              
            ]}
            >
              Update Remarks to
              <TextArea rows={4} onChange={(e) => setUpdateRemark(e.target.value)} value={updateRemark}/>
          </Modal> 
          <Modal
            visible={pvConfirmVisible}
            okText={"Proceed"}
            title= "Confirmation"
            maskClosable={false}
            footer={[
                <Button
                  type={"primary"}
                  onClick={()=> {
                      setPvConfirmVisible(false);
                  }}
                >
                  Cancel
                </Button>
                ,
                pvSubmit?
                  <Button 
                      type={"danger"} 
                      onClick={() => {
                        onNewFilter();
                      }}
                  >
                  Submit
                </Button>: <Spin tip="Processing..."></Spin>
              
            ]}
          >
              Last Generated List will be deleted when you press Submit.<br/>
              Confirm to Proceed?
          </Modal>
      </div>:
        <CNotAuthorized/>
        }

    </div>
  );
}
