const filterCSList = (newData)=> {
    let excludeUsers = ['ogcs_super','ogcs_junior','ogcs_senior','Ben','Kevin','CS1','steven','ogwithdraw','teracs01','ogtest01','ogtest02','june','ogcs102','ogcs103','ogcs104','ogcs105','ogcs107','ogcs777','ogcsmaint2','vgacs1','ogcsmaint','justin'];
    let filterCS = newData;
    filterCS = filterCS.filter(item => !excludeUsers.includes(item.username));

    return filterCS;
}

const sortBankData = (bankData) => {

    let fil = [...bankData].sort((x, y) =>
    x.actionStatus.toLowerCase().includes('active') && y.actionStatus.toLowerCase().includes('active')
      ? 0
      : x.actionStatus.toLowerCase().includes('active')
      ? -1
      : x.actionStatus.toLowerCase().includes('deleted') && y.actionStatus.toLowerCase().includes('deleted')
      ? -2
      : 1,
    );

    return fil;
  } 


export {filterCSList, sortBankData};