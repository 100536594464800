import moment from "moment";

const FormatDate = (date) => {
    return moment(date).format('YYYY-MM-DD')
}

const FormatDateHHMM = (date) => {
    return moment(date).format('YYYY-MM-DD HH:mm')
}

const FormatDateHHMMss = (date) => {
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

const FormatTHADate = (date)=> {
    //let newDate = date ===""? new Date().toLocaleDateString('en-CA'): date;
    return moment(date).subtract(1,'h').format('YYYY-MM-DD HH:mm:ss');
}

const FormatUNIXDate = (unixdate)=> {
    return moment(unixdate, 'X', true).format('YYYY-MM-DD');
}

const FormatUNIXDate_YMDHM = (unixdate)=> {
    return moment(unixdate, 'X', true).format('YYYY-MM-DD HH:mm');
}

const FormatNullDate = (date) => {
    return date == "1970-01-01 08:00:00" ? "" : date == "" ?  "" : date == null ?  "" : FormatDateHHMM(date);
}

// module.exports = {

//     FormatDate :FormatDate,
//     FormatHM   :FormatDateHHMM,
//     FormatTHADate : FormatTHADate

// }
export { FormatDate, FormatDateHHMM, FormatDateHHMMss, FormatTHADate, FormatUNIXDate , FormatUNIXDate_YMDHM, FormatNullDate};