import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { reportShareDetail } from "../../../config/networkConfig";
import {
  message,
  Table,
  PageHeader,
  DatePicker,
  Button,
  Breadcrumb,
} from "antd";
import { isMobile } from "react-device-detect";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";

export default function DownlineReport() {
  const { userId } = useSelector((state) => state.login);
  const [report, setReport] = useState("");
  const [filter, setFilter] = useState("");
  const [date, setDate] = useState(null);
  const [share, setShare] = useState(0);

  useEffect(() => {
    reportList();
    // eslint-disable-next-line
  }, []);

  const reportList = () => {
    var count = 0;
    reportShareDetail({ userId: userId })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          const newReport = Object.keys(json.data).length
            ? json.data.map((data) => {
                return {
                  key: count++,
                  dateTime: data.dateTime,
                  totalShare: data.shares,
                  turnover: data.turnover,
                  description: description(data),
                };
              })
            : [];

          const filter = newReport.filter(
            (data) => data.dateTime === newReport[0].dateTime
          );

          const getTotalShare = filter.map((data) => data.totalShare);
          var shareTotal = getTotalShare.reduce(function (previous, current) {
            return previous + current;
          }, 0);
          setShare(shareTotal);
          setReport(newReport);
          setFilter(filter);
        } else {
          message.error("Connection not stable, please try again !");
        }
      });
  };

  const description = (data) => {
    return (
      <div style={styles.decContainer}>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Turnover:</p>
          <p>{data.turnover}</p>
        </div>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Share:</p>
          <p>{data.shares}</p>
        </div>
      </div>
    );
  };

  const columns = [
    {
      align: "center",
      title: "Data / Time",
      dataIndex: "dateTime",
      width: 200,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Turnover",
      dataIndex: "turnover",
      width: 200,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Share",
      dataIndex: "totalShare",
      width: 200,
      ellipsis: true,
    },
  ];

  const mobileColumns = [
    {
      align: "center",
      title: "Data / Time",
      dataIndex: "dateTime",
      width: 120,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Turnover",
      dataIndex: "turnover",
      // width: 200,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Share",
      dataIndex: "totalShare",
      // width: 200,
      ellipsis: true,
    },
  ];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = `Shareholder Report\n\n\nShare Total => ${share.toFixed(2)}`;
    const headers = [["ID", "Date", "Share", "Turnover"]];
    var filteredReport = [...filter];
    const data = filteredReport.map((data) => {
      return [data.key, data.dateTime, data.totalShare, data.turnover];
    });

    let content = {
      startY: 150,
      head: headers,
      body: data,
      headStyles: { fillColor: "#1DA57A" },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
  };

  const datePicker = (date, dateString) => {
    setDate(date);
  };

  const searchBar = () => {
    if (!date) {
      return;
    }
    var newReport = [...report];
    const filter = newReport.filter(
      (data) => data.dateTime === date.format("YYYY-MM-DD")
    );

    const getTotalShare = filter.map((data) => data.totalShare);
    var shareTotal = getTotalShare.reduce(function (previous, current) {
      return previous + current;
    }, 0);

    setShare(shareTotal);
    setFilter(filter);
  };

  const resetBtn = () => {
    setDate(null);
    reportList();
  };

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      //   path: "first",
      breadcrumbName: "Report",
    },
    {
      path: "shareholderReport",
      breadcrumbName: "Shareholder Report",
    },
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 2;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHearderContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    searchBtn: {
      marginLeft: 30,
    },
    resetBtn: {
      marginLeft: 10,
    },
    datePickerStyle: {
      width: 300,
      borderRadius: 10,
    },
    mobilePageHearderContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    eachButtonContainer: {
      alignItems: "center",
      display: "flex",
      height: 50,
      marginLeft: 15,
      marginRight: 15,
      justifyContent: "center",
    },
    mobileSearchResetBtn: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      height: 50,
      alignItems: "center",
      paddingLeft: 60,
      paddingRight: 60,
    },
    btnContainer: {
      width: "100vw",
      padding: 10,
    },
    pdfStyle: {
      borderRadius: 10,
      width: 1200,
    },
    searchResetStyle: {
      borderRadius: 10,
      marginLeft: 10,
    },
    decStyle: {
      display: "flex",
      flexDirection: "row",
    },
    decLable: {
      width: 200,
    },
    decContainer: {
      paddingLeft: 75,
    },
    webRow: {
      backgroundColor: "#FFFFFF",
      paddingTop: 50,
      paddingLeft: 30,
      paddingBottom: 50,
    },
    mobileRow: {
      backgroundColor: "#FFFFFF",
      paddingLeft: 30,
    },
    cardStyle: {
      borderRadius: 20,
      height: 160,
      width: 280,
      border: "none",
    },
    headStyles: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 25,
      height: 80,
    },
    bodyStyle: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 80,
    },
  };

  return (
    <div className="eachPageContent">
      {isMobile ? (
        <div>
          <PageHeader ghost={false}>
            <div>
              <Breadcrumb
                separator=">"
                itemRender={itemRender}
                routes={routes}
              />
              <div style={styles.btnContainer}>
                <div style={styles.eachButtonContainer}>
                  <Button
                    type={"primary"}
                    onClick={exportPDF}
                    style={styles.pdfStyle}
                  >
                    Download PDF
                  </Button>
                </div>
                <div style={styles.eachButtonContainer}>
                  <DatePicker
                    style={styles.datePickerStyle}
                    onChange={datePicker}
                    value={date}
                  />
                  <Button
                    type={"primary"}
                    onClick={searchBar}
                    style={styles.searchResetStyle}
                    icon={<SearchOutlined />}
                  />

                  <Button
                    onClick={resetBtn}
                    style={styles.searchResetStyle}
                    icon={<UndoOutlined />}
                  />
                </div>
              </div>
              {/* <Row style={styles.mobileRow}>
                <Card
                  title="Total Share"
                  style={styles.cardStyle}
                  headStyle={styles.headStyles}
                  bodyStyle={styles.bodyStyle}
                >
                  <Statistic
                    precision={2}
                    value={share.toFixed(4)}
                    valueStyle={{ fontSize: 40 }}
                  />
                </Card>
              </Row> */}
              <div style={styles.tableContainer}>
                <Table
                  columns={mobileColumns}
                  dataSource={filter}
                  scroll={{ x: 100, y: null }}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20"],
                    style: styles.paginationStyle,
                  }}
                />
                {/* <Table
                  columns={mobileColumns}
                  dataSource={filter}
                  expandable={{
                    expandedRowRender: (record) => (
                      <p style={{ margin: 0 }}>{record.description}</p>
                    ),
                    rowExpandable: (record) => record.name !== "Not Expandable",
                  }}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20"],
                    style: styles.paginationStyle,
                  }}
                /> */}
              </div>
            </div>
          </PageHeader>
        </div>
      ) : (
        <div>
          <PageHeader
            ghost={false}
            title="Downline Report"
            extra={[
              <DatePicker
                key="1"
                style={styles.datePickerStyle}
                onChange={datePicker}
                value={date}
              />,
              <Button
                key="2"
                type={"primary"}
                style={styles.searchBtn}
                onClick={searchBar}
              >
                Search
              </Button>,
              <Button key="3" style={styles.resetBtn} onClick={resetBtn}>
                Reset
              </Button>,
              <Button key="4" type={"primary"} onClick={exportPDF}>
                Download PDF
              </Button>,
            ]}
          />
          {/* <Row style={styles.webRow}>
            <Card
              title="Total Share"
              style={styles.cardStyle}
              headStyle={styles.headStyles}
              bodyStyle={styles.bodyStyle}
            >
              <Statistic
                precision={2}
                value={share.toFixed(4)}
                valueStyle={{ fontSize: 40 }}
              />
            </Card>
          </Row> */}
          <div style={styles.tableContainer}>
            <Table
              columns={columns}
              dataSource={filter}
              scroll={{ x: 100, y: null }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20"],
                style: styles.paginationStyle,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
