const bankList = [
    {"bank":"SCB", "bankAcc":"4370540750","bankAccName":"PRAPAPORN JAN" },
    {"bank":"SCB", "bankAcc":"9212334053","bankAccName":"MR. Unnop Pongrangsee" },
    {"bank":"SCB", "bankAcc":"9342338537","bankAccName":"TANAKORN RUEKLANG" },
    {"bank":"KBANK", "bankAcc":"0938025576","bankAccName":"MR NATTHAPHAT JAINTA" }

]

const STATUS_CONFLICT = '8'
const STATUS_PENDING = '0'
const STATUS_UNATTEND = '4'
const RECORD_INTERVAL = '15'

export { 
    STATUS_CONFLICT,
    STATUS_PENDING,
    STATUS_UNATTEND,
    RECORD_INTERVAL,
};
