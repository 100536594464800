import React, { useEffect, useState } from "react";
import { reportWinlose } from "../../config/networkConfig";
import { useSelector } from "react-redux";
import {
  message,
  Table,
  PageHeader,
  DatePicker,
  Button,
  Row,
  Statistic,
  Card,
} from "antd";
import { isMobile } from "react-device-detect";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import { FormatDate } from "../../library/dates";
import { FormatData } from "../../library/numbers";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";

export default function WinloseReport() {
  const [report, setReport] = useState("");
  const { userId } = useSelector((state) => state.login);
  const [filter, setFilter] = useState("");
  const [date, setDate] = useState(null);
  const [winloseTotal, setWinloseTotal] = useState(0);
  const [profitTotal, setProfitTotal] = useState(0);
  const [commTotal, setCommTotal] = useState(0);
  const [loadings, setLoadings] = useState(false);

  useEffect(() => {
    reportList();
    // eslint-disable-next-line
  }, []);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    if (report.length == 0) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }
    
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const winLoseTotal = winloseTotal;
    const profittotal = profitTotal;
    const commtotal = commTotal;


    const title = `Win Lose Report\n\n\nWin Lose Total =>  ${FormatData(winLoseTotal)}\n\nProfit Total =>  ${FormatData(profittotal)}\n\nCommission Total => ${FormatData(commtotal)}`;

    // const title = `Win Lose Report\n\n\nWin Lose Total =>  ${winLoseTotal.toFixed(
    //   2
    // )}\n\nProfit Total =>  ${profittotal.toFixed(
    //   2
    // )}\n\nCommission Total => ${commtotal.toFixed(2)}`;
    const headers = [
      [
        "ID",
        "Date",
        "Game Code",
        "Ticket Count",
        "Turnover",
        "Win Lose",
        "Commission",
        "Profit",
      ],
    ];
    var filteredReport = [...filter];
    const data = filteredReport.map((data) => {
      return [
        data.key,
        data.dateTime,
        data.gameCode,
        data.ticketCount,
        FormatData(data.turnover),
        FormatData(data.commission),
        FormatData(data.winLose),
        FormatData(data.profit),
      ];
    });

    let content = {
      startY: 180,
      head: headers,
      body: data,
      headStyles: { fillColor: "#1890ff" },
    };

    // doc.text(title, doc.internal.pageSize.getWidth() / 2, 40, {
    //   align: "center",
    // });
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Win Lose Report.pdf");
  };

  const reportList = () => {
    var count = 0;
    setLoadings(true);
    //reportWinlose({ userId: userId })
    reportWinlose({ userId: userId , date : FormatDate(date)})
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          setLoadings(false);
          const newReport = Object.keys(json.data).length
            ? json.data.map((data) => {
                return {
                  key: ++count,
                  //key: count++,
                  dateTime: data.dateTime,
                  gameCode: data.gameCode,
                  ticketCount: data.ticketCount,
                  profit: data.profit,
                  turnover: data.turnover,
                  winLose: data.winlose,
                  commission: data.commission,
                  description: description(data),
                };
              })
            : [];
          const filter = newReport.filter(
            (data) => data.dateTime === newReport[0].dateTime
          );
          const getWinloseTotal = filter.map((data) => data.winLose);
          var winloseTotal = getWinloseTotal.reduce(function (
            previous,
            current
          ) {
            return previous + current;
          },
          0);
          const getProfitTotal = filter.map((data) => data.profit);
          var profitTotal = getProfitTotal.reduce(function (previous, current) {
            return previous + current;
          }, 0);

          const getCommTotal = filter.map((data) => data.commission);
          var commTotal = getCommTotal.reduce(function (previous, current) {
            return previous + current;
          }, 0);

          setCommTotal(commTotal);
          setProfitTotal(profitTotal);
          setWinloseTotal(winloseTotal);
          setReport(newReport);
          setFilter(filter);
        } 
        else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
  };

  const description = (data) => {
    console.log("record: ", data);
    return (
      <div style={styles.decContainer}>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Ticket Count:</p>
          <p>{data.ticketCount}</p>
        </div>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Turnover:</p>
          <p>{FormatData(data.turnover)}</p>
          {/* <p>{data.turnover}</p> */}
        </div>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Win / Lose:</p>
          <p>{FormatData(data.winlose)}</p>
          {/* <p>{data.winlose}</p> */}
        </div>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Commission:</p>
          <p>{FormatData(data.commission)}</p>
          {/* <p>{data.commission}</p> */}
        </div>
        <div style={styles.decStyle}>
          <p style={styles.decLable}>Profit:</p>
          <p>{FormatData(data.profit)}</p>
          {/* <p>{data.profit}</p> */}
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "dateTime",
      ellipsis: true,
    },
    {
      title: "Game Code",
      dataIndex: "gameCode",
    },
    {
      title: "Ticket Count",
      dataIndex: "ticketCount",
    },
    {
      title: "Turnover",
      dataIndex: "turnover",
      render:function(text) {
        return FormatData(text);
      }
    },
    {
      title: "Commission",
      dataIndex: "commission",
      render:function(text) {
        return FormatData(text);
      }
    },
    {
      title: "Win / Lose",
      dataIndex: "winLose",
      render:function(text) {
        return FormatData(text);
      }
    },
    {
      title: "Profit",
      dataIndex: "profit",
      render:function(text) {
        return FormatData(text);
      }
    },
  ];

  const mobileColumns = [
    {
      align: "center",
      title: "Date",
      dataIndex: "dateTime",
      width: "50%",
      ellipsis: true,
    },
    {
      align: "center",
      title: "Game Code",
      dataIndex: "gameCode",
      width: "50%",
    },
  ];

  const datePicker = (date, dateString) => {
    setDate(date);
  };

  const searchBar = () => {
    
    if (!date) {
      alertData.title = "Error";
      alertData.msg = "Please select a Date";
      sharedErrorAlert(alertData);
      return;
    }

    reportList();
    //old code
    // if (!date) {
    //   return;
    // }
    // var newReport = [...report];
    // const filter = newReport.filter(
    //   (data) => data.dateTime === date.format("YYYY-MM-DD")
    // );

    // const getTotal = filter.map((data) => data.winLose);
    // var total = getTotal.reduce(function (previous, current) {
    //   return previous + current;
    // }, 0);

    // const getProfitTotal = filter.map((data) => data.profit);
    // var profitTotal = getProfitTotal.reduce(function (previous, current) {
    //   return previous + current;
    // }, 0);

    // const getCommTotal = filter.map((data) => data.commission);
    // var commTotal = getCommTotal.reduce(function (previous, current) {
    //   return previous + current;
    // }, 0);

    // setCommTotal(commTotal);
    // setProfitTotal(profitTotal);
    // setWinloseTotal(total);
    // setFilter(filter);
  };

  const resetBtn = () => {
    // setFilter(report);
    setDate(null);
    reportList();
  };

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHearderContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    mobilePageHearderContainer: {
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
    },
    eachButtonContainer: {
      alignItems: "center",
      display: "flex",
      height: 50,
      marginLeft: 15,
      marginRight: 15,
      justifyContent: "center",
    },
    mobileSearchResetBtn: {
      display: "flex",
      width: "100vw",
      justifyContent: "center",
      height: 50,
      alignItems: "center",
    },
    cardContainer: {
      display: "flex",
      width: "100vw",
      overflowX: "scroll",
      overflowY: "hidden",
      flexDirection: "row",
      paddingTop: 10,
      paddingBottom: 10,
    },
    cardStyles: {
      borderRadius: 20,
      fontSize: 30,
      boxShadow: "5px 8px 5px 5px rgba(208, 216, 243, 0.6)",
      marginLeft: 20,
      height: 150,
      marginRight: 20,
    },
    cardHeadStyles: {
      backgroundColor: "#1DA57A",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      color: "#FFFFFF",
      fontSize: 25,
      width: 300,
    },
    cardTxt: {
      paddingLeft: 20,
      height: 60,
      alignItems: "center",
      display: "flex",
    },
    datePickerStyle: {
      width: 300,
    },
    searchBtn: {
      marginLeft: 30,
    },
    resetBtn: {
      marginRight: 10,
      marginLeft: 10,
    },
    extraStyle: {
      width: "100vw",
      padding: 10,
    },
    pdfStyle: {
      borderRadius: 10,
      width: 1200,
    },
    searchResetStyle: {
      borderRadius: 10,
      marginLeft: 10,
    },
    decStyle: {
      display: "flex",
      flexDirection: "row",
    },
    decLable: {
      width: 140,
    },
    decContainer: {
      paddingLeft: 75,
    },
    webRow: {
      backgroundColor: "#FFFFFF",
      paddingTop: 50,
      paddingLeft: 30,
      paddingBottom: 50,
    },
    cardStyle: {
      borderRadius: 20,
      height: 160,
      width: 280,
      border: "none",
      marginLeft: 20,
    },
    headStyles: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 25,
      height: 80,
    },
    bodyStyle: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 80,
    },
  };

  return (
    <div>
      {isMobile ? (
        <div>
          <PageHeader
            style={styles.mobilePageHearderContainer}
            ghost={false}
            extra={[
              <div key="1" style={styles.extraStyle}>
                <div style={styles.eachButtonContainer}>
                  <Button
                    type={"primary"}
                    onClick={exportPDF}
                    style={styles.pdfStyle}
                  >
                    Download PDF
                  </Button>
                </div>
                <div style={styles.eachButtonContainer}>
                  <DatePicker
                    style={styles.datePickerStyle}
                    onChange={datePicker}
                    value={date}
                  />
                  <Button
                    type={"primary"}
                    onClick={searchBar}
                    style={styles.searchResetStyle}
                    icon={<SearchOutlined />}
                  />

                  <Button
                    onClick={resetBtn}
                    style={styles.searchResetStyle}
                    icon={<UndoOutlined />}
                  />
                </div>
              </div>,
            ]}
          >
            <div>
              <div style={styles.cardContainer}>
                <Card
                  title="Total Win Lose"
                  style={styles.cardStyles}
                  headStyle={styles.cardHeadStyles}
                >
                  <p style={styles.cardTxt}>{FormatData(winloseTotal)}</p>
                  {/* <p style={styles.cardTxt}>{winloseTotal.toFixed(4)}</p> */}
                </Card>
                <Card
                  title="Total Commission"
                  style={styles.cardStyles}
                  headStyle={styles.cardHeadStyles}
                >
                  <p style={styles.cardTxt}>{FormatData(commTotal)}</p>
                  {/* <p style={styles.cardTxt}>{commTotal.toFixed(4)}</p> */}
                </Card>
                <Card
                  title="Total Profit"
                  style={styles.cardStyles}
                  headStyle={styles.cardHeadStyles}
                >
                  <p style={styles.cardTxt}>{FormatData(profitTotal)}</p>
                  {/* <p style={styles.cardTxt}>{profitTotal.toFixed(4)}</p> */}
                </Card>
              </div>
            </div>
          </PageHeader>
          <div style={styles.tableContainer}>
            <Table
              columns={mobileColumns}
              dataSource={filter}
              scroll={{ x: isMobile ? "100%" : "100%", y: null }}
              expandable={{
                expandedRowRender: (record) => (
                  <p style={{ margin: 0 }}>{record.description}</p>
                ),
                rowExpandable: (record) => record.name !== "Not Expandable",
              }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20"],
                style: styles.paginationStyle,
              }}
            />
          </div>
        </div>
      ) : (
        <div>
          {/* <PageHeader
            ghost={false}
            extra={[
              <DatePicker
                key="1"
                id="datePicker"
                style={styles.datePickerStyle}
                onChange={datePicker}
                value={date}
              />,
              <Button
                key="2"
                type={"primary"}
                style={styles.searchBtn}
                onClick={searchBar}
              >
                Search
              </Button>,
              <Button key="3" style={styles.resetBtn} onClick={resetBtn}>
                Reset
              </Button>,
              <Button key="4" type={"primary"} onClick={exportPDF}>
                Download PDF
              </Button>,
            ]}
          /> */}

          <PageHeader ghost={false}>
            <div>
              <DatePicker
                key="1"
                id="datePicker"
                style={styles.datePickerStyle}
                onChange={datePicker}
                value={date}
              />
              <Button
                key="2"
                type={"primary"}
                style={styles.searchBtn}
                onClick={searchBar}
              >
                Search
              </Button>
              <Button key="3" style={styles.resetBtn} onClick={resetBtn}>
                Reset
              </Button>
              <Button key="4" type={"primary"} onClick={exportPDF}>
                Download PDF
              </Button>
            </div>
          </PageHeader>

          <Row style={styles.webRow}>
            <Card
              title="Total Commission"
              style={styles.cardStyle}
              headStyle={styles.headStyles}
              bodyStyle={styles.bodyStyle}
            >
              <Statistic
                //precision={2}
                value = {FormatData(commTotal)}
                //value={commTotal.toFixed(4)}
                valueStyle={{ fontSize: 40 }}
              />
            </Card>
            <Card
              title="Total Win Lose"
              style={styles.cardStyle}
              headStyle={styles.headStyles}
              bodyStyle={styles.bodyStyle}
            >
              <Statistic
                //precision={2}
                value = {FormatData(winloseTotal)}
                //value={winloseTotal.toFixed(4)}
                valueStyle={{ fontSize: 40 }}
              />
            </Card>
            <Card
              title="Total Profit"
              style={styles.cardStyle}
              headStyle={styles.headStyles}
              bodyStyle={styles.bodyStyle}
            >
              <Statistic
                // precision={2}
                value = {FormatData(profitTotal)}
                // value={profitTotal.toFixed(4)}
                valueStyle={{ fontSize: 40 }}
              />
            </Card>
          </Row>
          <div style={styles.tableContainer}>
            <Table
              loading={loadings}
              columns={columns}
              dataSource={filter}
              scroll={{ x: 100, y: null }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20"],
                style: styles.paginationStyle,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
