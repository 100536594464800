import React, {useState, useEffect} from 'react'
import {
    csMonitorBankVerify
} from '../../../../../config/networkConfig';
import {
  Table,
  message,
} from "antd";
import { settings } from '../../../../../config/appConfig';
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../../../common/constants/sharedMsgBox"

const MonitorBankVerify = () => {
  const init = new Date()
  const [date, setDate] = useState(init)

  //Master Data
  const [statementData,         setStatementData] = useState("");
  const [statementPageSize, setStatementPageSize]  = useState(100);
  const [statementPageNum,  setStatementPageNum]  = useState(1);
  const [statementTotal,       setStatementTotal]  = useState(10);
  const [statTableLoading,   SetStatTableLoading] = useState(false);
  
  

  let statColumns =  [
    {
      align: "center",
      title: "",
      dataIndex:"totalJob",
      width: "10%",
      render:(text , record)=>{
         return (<div className='monitorHeader'>Bank Verify Queue -
                    <span className={record.totalJob > 5 ? "monitorAlert":"monitorNormal"}>{record.totalJob}</span>
                </div>)
      }
    },
  ]

  const tick = async () => {
    setDate(new Date())
    
    SetStatTableLoading(true);

    const responseDL = await csMonitorBankVerify({});
      let resultDL = await responseDL.json();
      if (resultDL.status === "ok") {
        let dlData = Object.keys(resultDL.data).length
        ? resultDL.data.map((obj) => {
            SetStatTableLoading(false);
            return {
                totalJob : obj.totalJob
            };
          })
        : [];

        setStatementData(dlData);

        SetStatTableLoading(false);
      }
      else {
        alertData.title = "Failed";
        alertData.msg = resultDL.msg;
        sharedFailAlert(alertData);
      }

      SetStatTableLoading(false);
  }

  const EmptyData = () =>{
    return {
        emptyText : <p className='monitorHeader'>Bank Verify Queue - <span className='monitorNormal'>0</span></p>
    }
  }

  useEffect(() => {
    const timerID = setTimeout(() => tick(), settings.monitorRefreshTime)
    return () => {
      clearTimeout(timerID)
    }
  }, [date])
  
  useEffect(()=> {
    tick();
  },[])

return   (
          <div className="cs-report-monitor">
            <Table
              columns={statColumns}
              dataSource={statementData}
              pagination={false}
              loading={statTableLoading}
              className="monitor-no-header"
              locale={EmptyData()}
            />
          </div>)
}

export {
    MonitorBankVerify
}