import React, { useState } from "react";
import { useQRCode } from "react-hook-qrcode";
import { PageHeader, Timeline, Tag, Tooltip, Button } from "antd";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

export default function QRcode() {
  const { qrCode, refCode } = useSelector((state) => state.login);
  const [copySuccess, setCopySuccess] = useState("");

  console.log("qrCode: ", qrCode);

  console.log("refCode: ", refCode);

  const [inputRef] = useQRCode({
    text: qrCode,
    options: {
      level: "L",
      margin: 0,
      scale: 10,
      width: 4,
      // color: {
      //   dark: "#010599FF",
      //   light: "#FFBF60FF",
      // },
    },
  });

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  const copyLink = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };
  const styles = {
    screenContainer: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-evenly",
      width: "100vw",
      alignItems: "center",
      height: 400,
    },
    mobileContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100vw",
      alignItems: "center",
      height: 700,
    },
    qrContainer: {
      paddingTop: 30,
      marginBottom: 50,
    },
  };
  return (
    <div>
      {isMobile ? (
        <div style={styles.mobileContainer}>
          <div style={styles.qrContainer}>
            <img ref={inputRef} alt="QRCode" src="QRCode" />
          </div>
          <div style={{ marginLeft: 20 }}>
            <Timeline>
              <Timeline.Item color="green">
                <p style={{ fontWeight: "bold", fontSize: 25 }}>Step 1</p>
                <p>
                  - Click on the right hand side of the mouse for save the QR
                  Code.
                </p>
              </Timeline.Item>
              <Timeline.Item color="green">
                <p style={{ fontWeight: "bold", fontSize: 25 }}>Step 2</p>
                <p>Share the QR Code to member.</p>
              </Timeline.Item>
            </Timeline>
          </div>
        </div>
      ) : (
        <div>
          <PageHeader ghost={false}>
            <div style={{ textAlign: "center" }}>
              <div style={{ fontSize: 25 }}>QR Code</div>
            </div>
          </PageHeader>
          <div style={styles.screenContainer}>
            <div>
              <img ref={inputRef} alt="QRCode" src="QRCode" />
            </div>
            <div>
              <Timeline>
                <Timeline.Item color="green">
                  <p style={{ fontWeight: "bold", fontSize: 25 }}>Step 1</p>
                  <p>
                    - Click on the right hand side of the mouse for save the QR
                    Code
                  </p>

                  <p>
                    - Download link:
                    <Tag style={{ marginLeft: 20 }}>
                      <Button
                        className={"copy"}
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <a href={qrCode}>{qrCode}</a>
                      </Button>
                    </Tag>
                    <Tooltip title={!copySuccess ? "Copy" : copySuccess}>
                      <Tag color="blue" style={{ fontSize: 15 }}>
                        <Button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={() => copyLink(qrCode)}
                        >
                          Copy
                        </Button>
                      </Tag>
                    </Tooltip>
                  </p>

                  <p />
                  <p>
                    - Press it and copy the referral code:
                    <Tooltip title={!copySuccess ? "Copy" : copySuccess}>
                      <Tag color="blue" style={{ marginLeft: 20 }}>
                        <Button
                          className={"copy"}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={() => copyToClipBoard(refCode)}
                        >
                          {refCode}
                        </Button>
                      </Tag>
                    </Tooltip>
                  </p>
                </Timeline.Item>
                <Timeline.Item color="green">
                  <p style={{ fontWeight: "bold", fontSize: 25 }}>Step 2</p>
                  <p>Share to member.</p>
                </Timeline.Item>
              </Timeline>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
