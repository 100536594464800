import React, {useState, useEffect} from 'react'
import ReactDOM from 'react-dom'
import {
  csMonitorWatchList
} from '../../../../../config/networkConfig';
import {
  Table,
  message
} from "antd";
import style from '../../../../../resource/style/style';
import { settings } from '../../../../../config/appConfig';

const MonitorWatchList = () => {
  const init = new Date()
  const [date, setDate] = useState(init)

  //Master Data
  const [statementData,         setStatementData] = useState("");
  const [statementPageSize, setStatementPageSize]  = useState(100);
  const [statementPageNum,  setStatementPageNum]  = useState(1);
  const [statementTotal,       setStatementTotal]  = useState(10);
  const [statTableLoading,   SetStatTableLoading] = useState(false);
  
  let statColumns =  [
    {
      align: "center",
      title: <div>#</div>,
      dataIndex:"key",
      width: "14%",
    },
    {
      align: "center",
      title: <div>Date</div>,
      dataIndex:"trxndate",
      width: "14%",
    },
    {
      align: "center",
      title: <div>Mobile</div>,
      dataIndex:"mobile",
      width: "14%",
    },
    {
      align: "center",
      title: <div>Amount</div>,
      dataIndex:"amt",
      width: "14%",
    },
    {
      align: "center",
      title: <div>Type</div>,
      dataIndex:"trxntype",
      width: "14%",
    },
    {
      align: "center",
      title: <div>Occurrence</div>,
      dataIndex:"occur",
      width: "14%",
    },
    {
      align: "center",
      title: <div>Remark</div>,
      dataIndex:"remark",
      width: "14%",
    },
  ]

  const tick = async () => {
    setDate(new Date())
    
    SetStatTableLoading(true);

    let countDL = 0;
    const responseDL = await csMonitorWatchList({});
      let resultDL = await responseDL.json();
      if (resultDL.status === "ok") {
        const dlData = Object.keys(resultDL.data).length
        ? resultDL.data.map((obj) => {
            SetStatTableLoading(false);
            return {
                key           : ++countDL,
                trxndate      : obj.trxndate,
                mobile        : obj.mobile,
                amt           : obj.amt,
                trxntype      : obj.trxntype,
                occur         : obj.occurrence,
                remark        : obj.remark
            };
          })
        : [];

        setStatementData(dlData);

        SetStatTableLoading(false);
      }else {
        message.error(resultDL.msg)
      }

      SetStatTableLoading(false);
  }

  useEffect(() => {
    const timerID = setTimeout(() => tick(), settings.monitorRefreshTime)
    return () => {
      clearTimeout(timerID)
    }
  }, [date])

  useEffect(()=> {
    tick();
  },[])

return   (<div style={{marginTop:"20px"}} className="cs-report-monitor">
          <p><b>Watch List (Last 24 Hour)</b></p>
          <Table
            style={{marginTop:"10px"}}
            columns={statColumns}
            dataSource={statementData}
            pagination={false}
            loading={statTableLoading}         
          />
          </div>)
}

export {
  MonitorWatchList
}