//#region ---------- Modules ----------

import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Badge,
  Row,
  Tooltip,
  Switch,
} from "antd";

//#endregion

//#region ---------- Imports ----------
import {
  getDomainList,
  createDomain,
  updateDomainStatus,
  updatePrimaryDomain,
  getDomainStatusDB,
  // getCompanyProfileData,
} from "../../config/networkConfig";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";
import {CopyOutlined, CheckOutlined, CloseOutlined} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { onboardDomainVw } from "../../library/permission";
import { CNotAuthorized } from "../../common/components/CNotAuthorized";

//#endregion

export default function OnboardDomain() {
  //#region ---------- Variables ----------
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [tableLoadings, setTableLoadings] =     useState(false);
  const [isValidDomain, setIsValidDomain] =     useState(true);
  const [newDomain, setNewDomain]         =     useState("");
  const [formLoading, setFormLoading]     =     useState(false);
  const [domainList, setDomainList]       =     useState([]);
  const [primaryDomLdg, setPrimaryDomLdg] =     useState(false);
  
  const now = new Date().toLocaleTimeString();
  // const [time, setTime] = useState(now);
  const {issuper, userId, username , role, companyId, compCode } = useSelector((state) => state.login);

  useEffect(() => {
    retrieveDomainList();
  }, [])

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 100 },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  //#endregion

  //#region ---------- Functions ----------
  const onReset = () => {
    setNewDomain("");
    setIsValidDomain(true);
    if(!formLoading){
      setVisible(false)
    }
  };

  const columns = [
    {
      title: "Domain",
      dataIndex: "domain",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        switch (record.status) {
          case 0: 
            return <Badge status="warning" text="Pending" />;
            case 1: 
              return <Badge status="success" text="Active" />;       
        }
      },
    },
    {
      title: "Nameserver 1",
      dataIndex: "nameservers",
      render: (text, record) => {
          return (
            <Row>
              <div style={{marginRight:10}}>{text[0]}</div>
              <Tooltip title='Copy this Nameserver'>
                <Button type="primary" shape="circle"
                      onClick={() =>  navigator.clipboard.writeText(text[0])}
                      icon={<CopyOutlined />} 
                        size="small"
                    >
                  </Button>
                </Tooltip>
            </Row>
          )
        }
      },
      {
        title: "Nameserver 2",
        dataIndex: "nameservers",
        render: (text, record) => {
            return (
              <Row>
                <div style={{marginRight:10}}>{text[1]}</div>
                <Tooltip title='Copy this Nameserver'>
                  <Button type="primary" shape="circle"
                        onClick={() =>  navigator.clipboard.writeText(text[1])}
                        icon={<CopyOutlined />} 
                        size="small"
                      >
                    </Button>
                  </Tooltip>
              </Row>
            )
          }
        },
        {
          title: "Primary Domain",
          dataIndex: "isPrimary",
          render: (text,record) => {
            return (
              <Row>
                <Switch checkedChildren="YES" unCheckedChildren="NO" onChange={() =>setPrimaryDom(record.domain)} checked={text == 1 ? true : false} id={record.domain} loading={primaryDomLdg}></Switch>
              </Row>
            )
          }
        },
    // },
  ];

  const handleInputDomain = (e) => {
    const inputDomain = e.target.value.replace(/(http:\/\/|https:\/\/|http:\/\/www\.|https:\/\/www\.|http|www\.|https|\/|\s)+/g, "")
    setNewDomain(inputDomain);
    const domainRegex = /^[^\s]+\.[^\s]+$/;
    setIsValidDomain(domainRegex.test(inputDomain))
  }

  const splitNameservers = (nameserver) => {
    let nameserverArr = nameserver.split(",");
    return nameserverArr;
  }

  const setPrimaryDom = async (domain) => {
    setPrimaryDomLdg(true);
    let requestData = {
      domain : domain,
    }
    console.log('domainList >>', domainList)
    const result1 = await getDomainStatusDB(requestData);
    let response1 = await result1.json();

    if(response1.status === 'ok' && response1.data[0].status === 1){
      console.log('response1 >>', response1);
      const result2 = await updatePrimaryDomain(requestData);
      let response2 = await result2.json();

      if(response2.status === 'ok'){
        setPrimaryDomLdg(false);
        alertData.title = "Success";
        alertData.msg = response2.msg;
        sharedSuccessAlert(alertData);  
        retrieveDomainList();
      } else{
        setPrimaryDomLdg(false);
        alertData.title = "Failed";
        alertData.msg = response2.msg;
        sharedFailAlert(alertData);  
      }
    } else{
      setPrimaryDomLdg(false);
      alertData.title = "Failed";
      alertData.msg = "Domain is not active, please wait for the domain to be active and try again.";
      sharedFailAlert(alertData);  
      retrieveDomainList();
    }
  }

  const updateDomain = async (domain) => {
    setTableLoadings(true);
    let requestData = {
        domain : domain,
      }

      const result = await updateDomainStatus(requestData);
      let response = await result.json();

      if (response.status === "ok") {
        if(response.data === 'active'){
          domainList[domainList.length - 1].status = 1;
        }
        setTableLoadings(false);
      }
      else{
          alertData.title = "Failed";
          alertData.msg = response.msg;
          sharedFailAlert(alertData);  
      }
  }

  
  const retrieveDomainList = async () => {
    setTableLoadings(true);
    let requestData = {
      columnRequire : [
        'DomainName AS domain',
        'Status AS status',
        'NameServer AS nameserver',
        'isPrimary AS isPrimary'
      ]
    }
    
    let response = await getDomainList(requestData);
    let result = await response.json();
    
    if(result.status === "ok"){
      if(Object.keys(result.data).length > 0){
        let count = 0;
        let domainListData = result.data.map((obj) => {
          return {
            key           :   count++,
            domain        :   obj.domain,
            nameservers   :   splitNameservers(obj.nameserver),
            status        :   obj.status, 
            isPrimary     :   obj.isPrimary, 
          }
        })

        if(domainListData.length > 0){
          console.log('domainListData >>', domainListData);
          for(let i = 0; i < domainListData.length; i++){
            domainList.push(domainListData[i]);
            await updateDomain(domainListData[i].domain)
          }
          setDomainList(domainListData); 
        }
      }
      
    } else {
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
    }
    setTableLoadings(false);
  }

  const addDomain = async () => {
    setFormLoading(true);
    let requestData = {
      domain : newDomain
    }

    if (newDomain == ""){
      alertData.title = "Field Required";
      alertData.msg = "Please fill in Domain!";
      sharedErrorAlert(alertData);
      setFormLoading(false);
      return;
    }

    const check1 = /^(http:\/\/|https:\/\/|http:\/\/www\.|https:\/\/www\.|http|www\.|https)$/;

    if(check1.test(newDomain)){
      console.log('check1.test(newDomain) >>>', check1.test(newDomain));
      alertData.title = "Field Error";
      alertData.msg = `Please enter domain with valid format!`;
      sharedErrorAlert(alertData);
      setFormLoading(false);
      return;
    }

    if(!isValidDomain){
      console.log('isValidDomain >>>', isValidDomain);
      alertData.title = "Field Error";
      alertData.msg = `Please enter domain with valid format!`;
      sharedErrorAlert(alertData);
      setFormLoading(false);
      return;
    }

    const result = await createDomain(requestData);
    console.log('createDomain result>>>', result);
    let response = await result.json();
    if (response.status === "ok") {
      console.log('createDomain response>>>', response);
      alertData.title = "Success";
      alertData.msg = response.msg;
      sharedSuccessAlert(alertData);
      onReset();
    }
    else{
        alertData.title = "Failed";
        alertData.msg = response.msg;
        sharedFailAlert(alertData);  
    }
    setFormLoading(false);
    retrieveDomainList();
  }

  
  //#endregion

  //#region ---------- Renders ----------

  return (
    <div>
      {
        onboardDomainVw(role) ? 
        <div>

      <Modal
        title="Add Domain"
        visible={visible}
        onCancel={onReset}
        footer={null}
        closeIcon={false}
        maskClosable={false}
      >
        <Form 
        {...layout} 
        form={form}
        >
          <Form.Item
            label="Domain"
            rules={[{ required: true, whitespace: true, max: 100 }]}
            onCancel={onReset}
          >
            <Input
              value={newDomain}
              placeholder="Enter your domain here… E.g. tkb.com"
              onChange={handleInputDomain}
              style={{borderColor : isValidDomain ? '' : 'red'}}
              disabled={formLoading}
            />
            <p><i>The domain should be in this specific format: <b>"tkb.com"</b></i></p>
          </Form.Item>
          <Form.Item {...tailLayout} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: 8 }}
              loading={formLoading}
              onClick={addDomain}
            >
              Submit
            </Button>
            <Button 
              htmlType="button" 
              onClick={onReset}
              loading={formLoading}
              >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <>
        <PageHeader title="Onboard Domain">
          <Button
            type="primary"
            onClick={() => {
              setVisible(true);
            }}
          >
            Add Domain
          </Button>
          <Button
            type="primary"
            style={{marginLeft:10}}
            onClick={retrieveDomainList}
          >
            Refresh List
          </Button>
        </PageHeader>
        <Table
          style={{ overflow: "auto", marginLeft: 20 }}
          loading={tableLoadings}
          columns={columns}
          dataSource={domainList}
          pagination={false}
        />
      </>
      </div>
    :
    <CNotAuthorized/>
      }
      </div>
  );
}

//#endregion
