import React, { Component } from "react";
import "../../App.less";
import { Layout, Menu, Dropdown, Button } from "antd";
import { 
  UserOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LockOutlined 
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { NavLink, Switch, Route, Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { route, agent, shareholder, logout, changePassword } from "../../config/route";
import { copyright } from "../../library/texts";
import { Logo } from "../../common/components/Logo";

//Navigate
import Login from "../login/login";
import Logout from "../logout/logout";
import MemberManagement from "./memberManagement";
// import Agent from "./agent";
import QrCode from "./qrCode";
import AgentReportComm from "./commission";
import { externalFileNotFile } from "../error/error";
import { settings } from "../../config/appConfig";

const { Header, Sider, Content, Footer } = Layout;
const styles = {
  menuFontSize: {
    fontSize:
      window.innerWidth === 280 || window.innerWidth === 320 ? null : 20,
  },
};
class App extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  state = {
    collapsed: isMobile ? true : false,
    openKeys: [""],
    current: "/",
  };

  componentDidMount() {
    document.addEventListener("gesturestart", function (e) {
      e.preventDefault(); //disable iphone zooming
    });

    var that = this;
    agent.forEach(function (ag) {
      if (ag.path === window.location.pathname) {
        that.setState({ title: ag.title });
      }
    });
  }

  toggle = () => {
    if (isMobile) {
      document.getElementById("menuBackground").style.display = "flex";
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };
  menuBgOnclick = () => {
    if (isMobile) {
      this.setState({
        collapsed: !this.state.collapsed,
      });
      document.getElementById("menuBackground").style.display = "none";
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  };

  handleClick = (e) => {
    console.log("click ", e);
    this.setState({
      current: e.key,
    });
  };

  menu = () => {
    return (
      <Menu>
        {(
          <Menu
            style={{
              paddingTop: 4,
              marginTop: -4,
              marginBottom: -4,
              paddingBottom: 2,
            }}
          >
            <Menu.Item 
              onClick={() => {
                window.location.reload();
              }}
              key={changePassword[0].path}
              style={{
                paddingTop: -2,
                marginTop: -5,
                marginBottom: -4,
              }}
              
            >
              <NavLink to={changePassword[0].path}>
                <div 
                  className="menu-item"
                  style={{
                    justifyContent: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <LockOutlined className="menu-icon" />
                  <span className="menu-text">Change Password</span>
                </div>
              </NavLink>
            </Menu.Item>

            <Menu.Item key={logout[0].path} className="logoutBorder"
              style={{
                marginBottom: -2,
              }}
            >
              <NavLink to={logout[0].path}>
                <div 
                  className="menu-item"
                  style={{
                    justifyContent: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <LogoutOutlined className="menu-icon" />
                  <span className="menu-text">Logout</span>
                </div>
              </NavLink>
            </Menu.Item>
          </Menu>
        )}
      </Menu>
    );
  };

  render() {
    return this.props.signIn === false ? (
      <Switch>
        <Route path={[route.Default, route.Login]} exact component={Login} />
        <Route path={route.All} component={externalFileNotFile} />
      </Switch>
    ) : (
          // <Header
          //   style={{
          //     padding: 0,
          //     flexDirection: "row",
          //     display: "flex",
          //     justifyContent: "space-between",
          //     height: 80,
          //     alignItems: "center",
          //   }}
          // >
          <Layout>
            <div
              id="menuBackground"
              className="menuBackground"
              onClick={() => this.menuBgOnclick()}
            />
            <Sider
              className="ant-layout-sider-dark"
              trigger={null}
              collapsible
              collapsed={this.state.collapsed}
              collapsedWidth={isMobile ? 0 : 80}
              width={
                window.innerWidth === 280 || window.innerWidth === 320 ? 220 : 280
              }
              style={{
                overflow: "auto",
                height: "100vh",
                position: "fixed",
                left: 0,
                width: "280px",
              }}
            >
              <div
                style={{
                  height: 64,
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div>
                  <Logo logoType="agent" />
                  {/* <img src={logo.icon} alt="Logo" style={{ maxWidth: "80%" }} />
                  {!this.state.collapsed ? (
                    <img src={logo.logo} alt="Logo" style={{ maxWidth: "40%" }} />
                  ) : null} */}
                </div>
              </div>
              <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={[window.location.pathname]}
                onClick={this.handleClick}
                openKeys={this.state.openKeys}
                onOpenChange={this.onOpenChange}
              >
                <Menu.Item
                  onClick={() => window.location.reload()}
                  key={agent[4].path}
                  icon={agent[4].icon}
                  style={styles.menuFontSize}
                >
                  <Link to={agent[4].path}>{agent[4].title}</Link>
                </Menu.Item>
                <Menu.Item
                  onClick={() => window.location.reload()}
                  key={agent[0].path}
                  icon={agent[0].icon}
                  style={styles.menuFontSize}
                >
                  <Link to={agent[0].path}>{agent[0].title}</Link>
                </Menu.Item>
                <Menu.Item
                  onClick={() => window.location.reload()}
                  key={agent[1].path}
                  icon={agent[1].icon}
                  style={styles.menuFontSize}
                >
                  <Link to={agent[1].path}>{agent[1].title}</Link>
                </Menu.Item>
                {/* <Menu.Item
                  onClick={() => window.location.reload()}
                  key={agent[3].path}
                  icon={agent[3].icon}
                  style={styles.menuFontSize}
                >
                  <Link to={agent[3].path}>{agent[3].title}</Link>
                </Menu.Item> */}
                {/* <Menu.Item
                  onClick={() => window.location.reload()}
                  key={agent[2].path}
                  icon={agent[2].icon}
                  style={styles.menuFontSize}
                >
                  <Link to={agent[2].path}>{agent[2].title}</Link>
                </Menu.Item> */}
              </Menu>
            </Sider>
            <Layout className="site-layout">
              {isMobile ? (
                <Header
                  className="site-layout-background"
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                    height: 68,
                  }}
                >
                  {React.createElement(
                    this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                    {
                      className: "trigger",
                      onClick: this.toggle,
                    }
                  )}

                  <div
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  ></div>

                  <div>
                    <div
                      style={{
                        fontSize: 18,
                      }}
                    >
                      <Dropdown overlay={this.menu} placement="bottomLeft">
                        <Button
                          style={{
                            border: "none",
                            width: 80,
                            height: 65,
                          }}
                          icon={<UserOutlined />}
                          size="large"
                        ></Button>
                      </Dropdown>
                    </div>
                  </div>
                </Header>
              ) : (
                <Header
                  className="site-layout-background"
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: this.state.collapsed ? 64 : 280,
                    height: 68,
                    alignItems: "center",
                  }}
                >
                  {React.createElement(
                    this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                    {
                      className: "trigger",
                      onClick: this.toggle,
                    }
                  )}

                  <div
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {this.state.title}
                  </div>

                  <div>
                    <div
                      style={{
                        fontSize: 18,
                      }}
                    >
                      <Dropdown overlay={this.menu} placement="bottomLeft">
                        <Button
                          style={{
                            border: "none",
                            width: 250,
                            height: 65,
                          }}
                          icon={<UserOutlined />}
                          size="large"
                        >
                          {this.props.role}_{this.props.username}
                        </Button>
                      </Dropdown>
                    </div>
                  </div>
                </Header>
              )}
              <Content
                className="site-layout-background"
                style={{
                  marginTop: "5px",
                  marginLeft: isMobile ? "" : this.state.collapsed ? 64 : 280,
                  backgroundColor: "#f0f2f5",
                }}
              >
                <div
                  className="site-layout-background"
                  style={{
                    marginRight: isMobile ? "" : this.state.collapsed ? 10 : "",
                    marginLeft: isMobile ? "" : this.state.collapsed ? 24 : "",
                    padding: isMobile ? "" : 24,
                    minHeight: isMobile ? 550 : 360,
                  }}
                >
                  <Switch>
                    <Route exact path={route.Logout} component={Logout} />
                    {agent.map((route, index) => (
                      <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                      />
                    ))}
                  </Switch>
                </div>
              </Content>
              {isMobile ? (
                <Footer style={{ textAlign: "center" }}>
                  <div>{copyright.fullText()}</div>
                  <div>V: {settings.version}</div>
                  {/* <div>V: {version}</div> */}
                </Footer>
              ) : (
                <Footer
                  style={{
                    textAlign: "center",
                    marginLeft: 280,
                  }}
                >
                  <div>{copyright.fullText()}</div>
                  <div>V: {settings.version}</div>
                  {/* <div>V: {version}</div> */}
                </Footer>
              )}
            </Layout>
          </Layout>
        // )}
      // </Layout>
    );
  }
}

const mapStateToProps = ({ login }) => ({
  signIn: login.signIn,
  username: login.username,
  role: login.role,
});

// const mapDispatchToProps = ({ login }) => ({
//   signIn: login.signIn,
// });

const mapDispatchToProps = (dispatch) => {
  return { signOut: () => dispatch({ type: "sign_out" }) };
};

// const signOut = ({ login }) => ({
//   signOut: login,
// });

export default connect(mapStateToProps, mapDispatchToProps)(App);
