import React, { useEffect  } from "react";
import {
  Form,
  Tabs,
  Select,
  Spin,
  Divider,
} from "antd";
import { useSelector } from "react-redux";
import { monitorBotVw } from "../../../library/permission";
import style from "../../../resource/style/style";
import { MonitorBankVerify } from "./components/ReportMonitor/CMonitorBankVerify";
import { MonitorDeposit } from "./components/ReportMonitor/CMonitorDeposit";
import { MonitorWithdraw } from "./components/ReportMonitor/CMonitorWithdraw";
import { MonitorWatchList } from "./components/ReportMonitor/CMonitorWatchList";
import { MonitorRemark } from "./components/ReportMonitor/CMonitorRemark";
import { MonitorBotList } from "./components/ReportMonitor/CBotList";
import { CNotAuthorized } from "../../../common/components/CNotAuthorized";

export default function ReportOperations() {
  const { role, username  } = useSelector((state) => state.login);

  //Preview


  
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  //#region Load List

  ////#endregion

  //#region Functions

 
  ////#endregion

  return (
    <div>
        {
      
          monitorBotVw(role)?
          <div style={style.tabelContainer} id="page-operation-report">
          <MonitorRemark/>
          <div style={style.displayEvenly}>
              <div style={{width:"30%"}}><MonitorBankVerify/></div>
              <div style={{width:"30%"}}><MonitorDeposit/></div>
              <div style={{width:"30%"}}><MonitorWithdraw/></div>
          </div>
          <Divider className="divider"/>
          <div>
              <MonitorBotList/>
          </div>
          <Divider className="divider"/>
            
          <MonitorWatchList/>
      </div>:
          <CNotAuthorized/>
        }
    </div>
  );
}
