import React, { useEffect, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Tooltip,
  Switch,
  message,
  Space,
  Select,
  Option,
  Spin,
} from "antd";
import { useSelector } from "react-redux";
import {
    getCommSettingProfile,
    updateCommSettingProfile
} from "../../config/networkConfig";
import { COMM_WIN_LOSE, COMM_TURNOVER } from "../../common/constants/constant";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";
import { Typography } from 'antd';

const { Title } = Typography;

export default function CompanySetting() {
  const { Option } = Select;
  const { userId, companyId } = useSelector((state) => state.login);
  const [form] = Form.useForm();
  const [positionTaking, setPositionTaking] = useState("");
  const [customFee, setCustomFee] = useState("");
  const [playerRebate, setPlayerRebate] = useState("");
  const [playerAffiliate, setPlayerAffiliate] = useState([]);
  const [marketAffiliate, setMarketAffiliate] = useState([]);
  const [crm, setCrm] = useState("");
  const [operationCost, setOperationCost] = useState("");
  const [spinLoading, setSpinLoading] = useState(true);

  const tierList = [
    {display: 'Tier 1', value: 'L1' },
    {display: 'Tier 2', value: 'L2' },
    {display: 'Tier 3', value: 'L3' },
    {display: 'Tier 4', value: 'L4' },
    {display: 'Tier 5', value: 'L5' },  
    {display: 'Tier 6', value: 'L6' },
    {display: 'Tier 7', value: 'L7' },
    {display: 'Tier 8', value: 'L8' },
    {display: 'Tier 9', value: 'L9' },
    {display: 'Tier 10', value: 'L10' },
  ];

  useEffect(() => {
    loadCompanyCommSettingData();
  }, []);

  const loadCompanyCommSettingData = async() => {
    let initialPlayerTier = '', playerTierArr = [], initialMarketTier = '', marketTierArr = [], finalPlayerRebate = '';
    let requestData = {
      companyID : userId,
      columnRequire : ['MaxShareholderPT as positionTaking', 'CustomFee as customFee', 'CommSetMember as playerRebate', 'CommSetAffiliate as playerTier', 'CommSetAgent as marketTier', 'OperationCost as operationCost']
    };

    const response = await getCommSettingProfile(requestData);
    let result = await response.json();
    if (result.status === "ok") {
      // if (result.data[0].playerRebate != '') {
      //   finalPlayerRebate = String(result.data[0].playerRebate).split(':');
      // }

      // if (result.data[0].playerTier != '') {
      //   initialPlayerTier = result.data[0].playerTier.replace(/\s+/g, ''); // clear space
      //   initialPlayerTier = initialPlayerTier.split('###');
      //   let arrangePlayerTier = initialPlayerTier.map((item, index) => (
      //     item = item.split(':'),
      //     playerTierArr.push({'playerTier_tier' : item[0] , 'playerTier_percentage' : item[1]})
      //   ))
      // }

      // if (result.data[0].marketTier != '') {
      //   initialMarketTier = result.data[0].marketTier.replace(/\s+/g, ''); // clear space
      //   initialMarketTier = initialMarketTier.split('###');
      //   let arrangeMarketTier = initialMarketTier.map((item, index) => (
      //     item = item.split(':'),
      //     marketTierArr.push({'marketTier_tier' : item[0] , 'marketTier_percentage' : item[1]})
      //   ))
      // }

      form.setFieldsValue({
        // positionTaking : result.data[0].positionTaking,
        // customFee : result.data[0].customFee,
        // playerRebate : finalPlayerRebate != '' ? Number(finalPlayerRebate[1]).toFixed(2) : 0,
        // playerTier : playerTierArr,
        // marketTier : marketTierArr,
        // crm : Number(result.data[0].crm).toFixed(2),
        operationCost : Number(result.data[0].operationCost).toFixed(2),
      });

      // setPlayerAffiliate(playerTierArr);
      setSpinLoading(false);
    }
    else {
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
      setSpinLoading(false);
    }
  }

  const onFinish = async (values) => {
    setSpinLoading(true);

    // let finalPlayerRebate = 0, playerAffiliate_total = 0, marketAffiliate_total = 0, finalPlayerAffiliate = '', finalMarketAffiliate = '';
    // console.log(`playerAffiliate`, values.playerTier)
    // // Checking for player rebate
    // if (values.playerRebate >= 0) {
    //   finalPlayerRebate = 'L0:' + Number(values.playerRebate).toFixed(2) + ':' + `${COMM_TURNOVER}`; 
    // }

    // // Checking for player affiliate
    // if (values.playerTier != undefined && values.playerTier.length > 0){
    //   for (let i = 0; i < values.playerTier.length; i++) {
    //     playerAffiliate_total += Number(values.playerTier[i].playerTier_percentage);
    //     finalPlayerAffiliate += values.playerTier[i].playerTier_tier + ':' + Number(values.playerTier[i].playerTier_percentage).toFixed(2) + `:${COMM_WIN_LOSE} ### `;
    //   }
    //     finalPlayerAffiliate = finalPlayerAffiliate.slice(0,-5);
    // }

    // // Checking for market affiliate
    // if (values.marketTier != undefined && values.marketTier.length > 0){
    //   for (let i = 0; i < values.marketTier.length; i++) {
    //     marketAffiliate_total += Number(values.marketTier[i].marketTier_percentage);
    //     finalMarketAffiliate += values.marketTier[i].marketTier_tier + ':' + Number(values.marketTier[i].marketTier_percentage).toFixed(2) + `:${COMM_WIN_LOSE} ### `;
    //   }
    //     finalMarketAffiliate = finalMarketAffiliate.slice(0,-5);
    // }

    // if (playerAffiliate_total > 100 || marketAffiliate_total > 100) {
    //   alertData.title = "Percentage Exceed";
    //   alertData.msg = playerAffiliate_total > 100 ? "Player Affiliate percentage cannot allocate more than 100% !" : "Market Affiliate percentage cannot allocate more than 100% !"  ;
    //   sharedErrorAlert(alertData);
    //   setSpinLoading(false);
    //   return;
    // }

    let requestData = {
      companyID : userId,
      // positionTaking : positionTaking || 100,
      // customFee : Number(values.customFee).toFixed(2),
      // playerRebate : finalPlayerRebate,
      // playerAffiliate : finalPlayerAffiliate,
      // marketAffiliate : finalMarketAffiliate,
      // crm : Number(values.crm).toFixed(2),
      operationCost : Number(values.operationCost).toFixed(2)
    }    
    const response = await updateCommSettingProfile(requestData);
    let result = await response.json();

    if (result.status == "ok") {
      alertData.title = "Success";
      alertData.msg = "Company Profile is updated.";
      sharedSuccessAlert(alertData);
      setSpinLoading(false);
      loadCompanyCommSettingData();
    }
    else {
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
      setSpinLoading(false);
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: {
      span: 6,
      },
      sm: {
      span: 6,
      },
    },
    wrapperCol: {
      xs: {
      span: 24,
      },
      sm: {
      span: 8,
      },
    },
  };

  return (
    <Spin 
      spinning={spinLoading}
      size="large"
    >
      <Form
        {...formItemLayout}
        onFinish={onFinish}
        form={form}
        initialValues={{
          playerTier: playerAffiliate,
        }}
      >
          <Form.Item
          >
              <Title level={4}>Commission Setting</Title>
          </Form.Item>
          {/* <Form.Item label="Max Position Taking">
              <Form.Item
                  name="positionTaking"
                  noStyle
              >
              <InputNumber
                  style={{width:"435px"}}
                  value={playerRebate}
                  min={0}
                  max={100}
                  disabled={true}
                  placeholder="E.g. 0.00% - 100.00%"
                  onChange={(e) => {
                      setPositionTaking(e);
                  }}
              />
              </Form.Item>
          </Form.Item> */}

          {/* <Form.Item label="Custom Fee">
              <Form.Item
                  name="customFee"
                  noStyle
              >
              <InputNumber
                  value={customFee}
                  min={0}
                  style={{width:"435px"}}
                  placeholder="E.g. 10.00%"
                  onChange={(e) => {
                      setCustomFee(e);
                  }}
              />
              </Form.Item>
          </Form.Item> */}

          {/* <Form.Item label="Player Rebate (%)">
              <Form.Item
                  name="playerRebate"
                  noStyle
              >
              <InputNumber
                  value={playerRebate}
                  min={0}
                  max={100}
                  style={{width:"435px"}}
                  placeholder="E.g. 0.00% - 100.00%"
                  onChange={(e) => {
                    setPlayerRebate(e);
                  }}
              />
              </Form.Item>
          </Form.Item> */}

          {/* <Form.Item
              name={["user", "playerAffiliate"]}
              label="Player Affiliate"
          >
              <Form.List name="playerTier">
                {(fields, { add, remove }) => (
                    <>
                    {fields.map((field) => (
                        <Space key={field.key} align="baseline">
                          <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, curValues) =>
                                  // prevValues.playerTier_percentage !== curValues.playerTier_percentage || prevValues.playerTier_percentage !== curValues.tier
                                  prevValues.area !== curValues.area || prevValues.tier !== curValues.tier
                              }
                              key={field.key}
                          >
                            {() => (
                            <Form.Item
                                {...field}
                                label="Tier"
                                name={[field.name, 'playerTier_tier']}
                                required
                                rules={[
                                {
                                    required: true,
                                    message: 'Missing tier !',
                                },
                                ]}
                            >
                                <Select
                                    style={{
                                        width: 130,
                                    }}
                                >
                                    {(tierList || []).map((item) => (
                                      <Option key={item.display} value={item.value}>
                                        {item.display}
                                      </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            )}
                            </Form.Item>
                            <Form.Item
                            {...field}
                            label="Percentage (%)"
                            name={[field.name, 'playerTier_percentage']}
                            // name='playerTier_percentage'
                            required
                            rules={[
                              {
                                  required: true,
                                  message: 'Missing percentage !',
                              },
                            ]}
                          >
                            <InputNumber
                                min={0}
                                max={100}
                                style={{
                                  width: 130,
                                }}
                            />
                          </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                        </Space>
                    ))}
                    
                    <Form.Item>
                        <Button type="dashed" 
                            style={{
                                width: 435,
                            }}
                            onClick={() => 
                                {
                                  if(fields.length < 10) {
                                    add();
                                  }
                                  else {
                                    alertData.title = "Limit Reached";
                                    alertData.msg = "Maximum 10 player tier only !";
                                    sharedErrorAlert(alertData);
                                    return;
                                  }
                                }
                        } block icon={<PlusOutlined />}>
                          Add tier
                        </Button>
                    </Form.Item>
                    </>
                )}
              </Form.List>
          </Form.Item> */}

          {/* <Form.Item
            name={["user", "marketAffiliate"]}
            label="Market Affiliate"
          >
            <Form.List name="marketTier">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Space key={field.key} align="baseline">
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.area !== curValues.area || prevValues.tier !== curValues.tier
                          
                        }
                      >
                        {() => (
                          <Form.Item
                            {...field}
                            label="Tier"
                            name={[field.name, 'marketTier_tier']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing tier !',
                              },
                            ]}
                          >
                            <Select
                              style={{
                                width: 130,
                              }}
                            >
                              {(tierList || []).map((item) => (
                                <Option key={item.display} value={item.value}>
                                  {item.display}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label="Percentage (%)"
                        name={[field.name, 'marketTier_percentage']}
                        rules={[
                          {
                            required: true,
                            message: 'Missing percentage !',
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          max={100}
                          style={{
                            width: 130,
                          }}/>
                      </Form.Item>

                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button 
                      style={{
                          width: 435,
                      }}
                      type="dashed" 
                      onClick={() => 
                          {
                          if(fields.length<10) {
                              add();
                          }
                          else{
                              alertData.title = "Limit Reached";
                              alertData.msg = "Maximum 10 market tier only !";
                              sharedErrorAlert(alertData);
                              return;
                          }
                          }
                      } block icon={<PlusOutlined />}>
                      Add tier
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item> */}

          {/* <Form.Item
            name="crm"
            label="CRM Agent (%)"
          >
            <InputNumber
              value={crm}
              min={0}
              max={100}
              style={{width:"435px"}}
              placeholder="E.g. 0.00% - 100.00%"
              onChange={(e) => {
                setCrm(e);
              }}
            />
          </Form.Item> */}

          <Form.Item
            name="operationCost"
            label="Operation Cost"
          >
            <InputNumber
              value={operationCost}
              min={0}
              style={{width:"300px"}}
              placeholder="E.g. 10.00"
              onChange={(e) => {
                setOperationCost(e);
              }}
            />
          </Form.Item>

    <Form.Item 
      label=" " 
      colon={false}
      >
        <Button 
          type="primary" 
          htmlType="submit"
          style={{
            marginLeft: 100,
          }}
        >
          Submit
        </Button>
    </Form.Item>
  </Form>
  </Spin>
)
}