import React, { useState, useEffect , useRef} from "react";
import {
  Table,
  message,
  Upload,
  Button,
  Modal,
  Form,
  Input,
  PageHeader,
  Tooltip,
  Badge,
  Radio,
  Row,
  Select,
  InputNumber,
  Dropdown,
  Menu,
  Spin,
  DatePicker,
} from "antd";
import { 
  UploadOutlined, 
  SearchOutlined, 
  InfoCircleFilled, 
  FileImageOutlined, 
  DownOutlined, 
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { isMobile } from "react-device-detect";
import {
  csDepositList,
  csDepositListV1,
  csDepositManualApprove,
  csDepositReject,
  puppetTrxnDetails,
  puppetTransactionList,
  puppetTrxnList,
  userProfileBank,
  getReqToken,
  csVerifyMobile,
  csBankList,
  uploadReceiptV2,
  receiptFormNewV2,
  receiptFormNewV3,
  userBankAll,
  getBankList
  
} from "../../config/networkConfig";
import { settings } from "../../config/appConfig";
import { fileDomain } from "../../config/config";
import { useSelector } from "react-redux";
import { CS_USER_TYPE } from "../../common/constants/csuserType";
import { PROCESS_TYPE } from "../../common/constants/processType";
import { FormatPt , FormatMask , ShowMobileNumber} from "../../library/numbers";
import { PPT_RM } from "../../common/constants/constant";
import moment from "moment";
import md5 from "md5";
import { depMgmtManCrDep, depMgmtManAppRejDep } from "../../library/permission";
import style from "../../resource/style/style";
import { sortBankData } from "../../library/utils";
import { bankList } from "../../common/constants/bankTransaction";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";
import { BANK_INFO_TYPE } from "../../common/constants/bankType";

export default function DepositManagementV2() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [originData, setOriginData] = useState([]);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(true);
  const [depositVisible, setDepositVisible] = useState(false);
  const [rejectVisible, setRejectVisible] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const [amount, setAmount] = useState("");
  const [confirmAmount, setConfirmAmount] = useState("");
  const [reason, setReason] = useState("");
  const [searchText, setSearchText] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [status, setStatus] = useState("Pending");
  const {Column } = Table;
  const { role, userId ,companyId, issuper} = useSelector((state) => state.login);

  
  //Puppet Modal
  const [puppetVisible, setPuppetVisible]= useState(false);
  const [csPptTrxnList, setCSPptTrxnList] = useState([]);
  const [filteredPptTrxn,setFilterPptTrxn]= useState([]);
  const [memberBankAcctNum,setMemberAcctNum]= useState("");
  const [memberBankCode,setMemberBankCode]  = useState("");
  const [trxnLoading , setTrxnLoading]      = useState(false);

  //const [pptTrxnId, setPPTTrxnId] = useState("");
  const [mtTrxnId, setMTTrnxId] = useState(""); //Member Transaction Id;
  const [memberNickname,setMemberNickname]= useState("");
  const [topupAmt,setTopupAmt]= useState("");
  const [editMode, setEditMode] = useState(false);
  const [processing, setProcessing] = useState(false);

  // Create Deposit
  const [bankcode, setBankCode] = useState("");
  const [acctnum, setAcctNum] = useState("");
  const [amt, setAmt] = useState(0);
  const [countryCode, setCountryCode] = useState("60");
  const [phoneNum, setPhoneNum] = useState("");
  //const [csBankListOptions, setCSBankListOptions] = useState([]);


  //Modal
  //const [manualDepositVisible, setManualDepositVisible] = useState(false);
  //const [createDepositDisable, setCreateDepositDisable] = useState(true);

  //Manual Deposit
  const [formDelayDep]                                       = Form.useForm();
  const [manualDepositVisible,     setManualDepositVisible] = useState(false);
  const [createDepositDisable,     setCreateDepositDisable] = useState(true);
  const [csBankListOptions,        setCSBankListOptions] = useState([]);
  const [delayDepPhoneNum,         setDelayDepPhoneNum] = useState("");
  const [delayDepCountryCode,      setDelayDepCountryCode] = useState("66");
  const [delayDepBankLisOptions,   setDelayDepBankLisOptions] = useState([]);

  const [delayDepFromBankData,     setDelayDepFromBankData] = useState("");
  const [delayDepFromBankcode,     setDelayDepFromBankcode] = useState("");
  const [delayDepFromAcctnum,      setDelayDepFromAcctnum] = useState("");
  const [delayDepFromAcctname,     setDelayDepFromAcctname] = useState("");

  const [delayDepToBankData,       setDelayDepToBankData] = useState("");
  const [delayDepToBankCode,       setDelayDepToBankcode] = useState("");
  const [delayDepToAcctnum,        setDelayDepToAcctnum] = useState("");
  const [delayDepToAcctname,       setDelayDepToAcctname] = useState("");


  const [delayDepAmt,              setDelayDepAmt] = useState(0);
  const refDelayDepDt              = useRef(null);
  const [delayDepSubmit,           setDelayDepSubmit] = useState(true);
  const [viewMemberDlgOK,          setViewMemberDlgOK] = useState("Save");

  //Form
  const [fileList, setFileList] = useState([]);
  const { Option } = Select;
  
  //Approved Button 
  const [btnApprovedLable,         setBtnApprovedLable] = useState("Approved");
 
  //To Prevent Multiple Create
  const [createSubmit,             setCreateSubmit] = useState(true);

  useEffect(() => {
    depositList();
    bankcodeList();

    //puppetTrxnList();
    // eslint-disable-next-line
  }, [status]);
  

  useEffect(() => {
    setDelayDepCountryCode(delayDepCountryCode);
  }, [delayDepCountryCode]);
  

  //#region "Components"


  //#endregion

  const depositList = () => {
    var count = 0;
    setTableLoading(true);
    csDepositListV1({ processtype: PROCESS_TYPE.MANUAL })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          const newData = Object.keys(json.data).length
            ? json.data.map((x) => {
                setTableLoading(false);
                return {
                  key: count++,
                  date: x.dateTime,
                  nickname: x.nickname,
                  mobile  : x.mobile, 
                  newNickname: ShowMobileNumber(role, x.nickname, x.mobile),
                  bankcode: x.bank,
                  bankNameIn: x.bankAccName,
                  bankAcctIn: x.bankAcc,
                  amount: x.amount,
                  actualAmount: x.actualamount,
                  trxnId: x.trxnId,
                  userId: x.userId,
                  pptTrxnId: x.pptTrxnid,
                  receipt: x.receipt,
                  remark: x.remark,
                  completeByCS : x.CompleteByCS,
                  actionStatus:
                    x.status === "0" || x.status === "8"
                      ? "Pending"
                      : x.status === "1"
                      ? "Approved"
                      : x.status === "9"
                      ? "Rejected"
                      : "-",
                };
              })
            : [];



          setOriginData(newData);


          if (Object.keys(json.data).length == 0) {
            setTableLoading(false);
          }

          const pending = newData.filter((val) => {
            return val.actionStatus === "Pending";
          });
          const approve = newData.filter((val) => {
            return val.actionStatus === "Approved";
          });
          const reject = newData.filter((val) => {
            return val.actionStatus === "Rejected";
          });
          const approveDelayDep = newData.filter((val) => {
            return val.actionStatus === "Approved" && val.remark.includes(PPT_RM.DELAY_DEP);
          });

          const sortData =
            status === "Pending"
              ? pending
              : status === "Approved"
              ? approve
              : status === "Rejected"
              ? reject
              : status ==="ApprovedDelayDep"
              ? approveDelayDep
              : [...newData];

          setData(sortData);

        } else {
          setTableLoading(true);
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
  };

  //#region ---- Manual Deposit Form (Copied from View Member)
  const dataInsert2New = async (data, path) => {

      let requestData = {
        mobile       : delayDepCountryCode + delayDepPhoneNum,
        depfrom      : 
        {
          frombankcode  : delayDepFromBankcode,
          fromacctnum   : delayDepFromAcctnum,
          fromacctname  : delayDepFromAcctname,
        },
        depto        : 
        {
          tobankcode : delayDepToBankCode,
          toacctnum  : delayDepToAcctnum,
          toacctname : delayDepToAcctname,
        },
        uploadref    : path,
        amt          : delayDepAmt,
        createby     : userId,
        reqToken     : md5(getReqToken('depoistManualCreate')),
      }

      return receiptFormNewV3(     
        requestData
      )
      .then((response) => response.json())
      .then((json) => {
        return { status:json.status , errcode:json.errcode, msg:json.msg};
      });
  };


  useEffect(()=> {
    if (delayDepFromBankData!=null) {
      let depBankData = delayDepFromBankData.split("||");
      setDelayDepFromBankcode((depBankData[0]||'').trim());
      setDelayDepFromAcctnum((depBankData[1]||'').trim());
      setDelayDepFromAcctname((depBankData[2]||'').trim());
    }
  },[delayDepFromBankData])

  useEffect(()=> {
    if (delayDepToBankData!=null) {
      let depToBankData = delayDepToBankData.split("||");
      console.log("depToBankData >>> ", depToBankData)
      setDelayDepToBankcode((depToBankData[0]||'').trim());
      setDelayDepToAcctnum((depToBankData[1]||'').trim());
      setDelayDepToAcctname((depToBankData[2]||'').trim());
    }
  },[delayDepToBankData])

  const onManualCreateForm = () => {
    return (
      <div>
        <div>
            <Form form={formDelayDep} layout="vertical" name="depositform">
              {/* Mobile Id*/}
                <Form.Item
                  label="Phone Number"
                  required={true}
                  >
                    <Input.Group compact>
                      <Select value={delayDepCountryCode} 
                        onChange={selectDelayDepCountryCode}>
                        <Option value="66">+66</Option>
                        <Option value="60">+60</Option>
                      </Select>
                      <Input style={{width:"66%"}} defaultValue="" placeholder="phone number" value={delayDepPhoneNum}
                        onChange={(e) => {
                          setDelayDepPhoneNum(e.target.value.replace(/\D|^0/g, ""));
                        }}
                        onBlur={(e) => {
                            validateUserPhoneV2(e.target.value.replace(/\D|^0/g, ""));
                            
                        }}
                      />
                    </Input.Group>
                  </Form.Item>  
              {/* Upload Receipt */}
              <Form.Item
                name="bankReceipt"
                label="Bank Receipt"
              >
                {receiptUploads()}
              </Form.Item>
              {/* Bank Acc Name */}
              <Form.Item
                name="depToBank"
                label="Deposit To Bank"
                rules={[
                  {
                    required: true,
                    message: "Please Select Deposit To bank!",
                  },
                ]}
              >
                <Select
                    style={styles.datePickerStyle2}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.children.includes(input);
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={(value, event) => {
                      setDelayDepToBankData(value);
                      //Search Bank Acc Name
                      for (var i = 0; i < csBankListOptions.length; i++) {
                        if (csBankListOptions[i].bankAcc === value) {
                        }
                      }
                    }}
                  >
                    {csBankListOptions.map((option) => {
                      return (
                        <Option key={option.AccountNum} value={`${option.BankCode} || ${option.AccountNum} || ${option.AccountName}`}>
                          {option.BankCode} ({option.AccountNum})
                        </Option>
                      );
                    })}
                  </Select>
              </Form.Item>
              <Form.Item
                name="bankaccnum"
                label="Customer Bank Account"
                rules={[
                  {
                    required: true,
                    message: "Please input your bank!",
                  },
                ]}
              >
                <Select
                  style={styles.datePickerStyle2}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.children.includes(input);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={(value, event) => {
                    setDelayDepFromBankData(value);
                    for (var i = 0; i < delayDepBankLisOptions.length; i++) {
                      if (delayDepBankLisOptions[i].bankAcc === value) {
                        formDelayDep.setFieldsValue({
                          bankcode: delayDepBankLisOptions[i].bankcode,
                          bankaccname: delayDepBankLisOptions[i].acctname,
                        });
                      }
                    }
                  }}
                >
                  {delayDepBankLisOptions.map((option) => {
                    return (
                      <Option key={option.acctnum} value={`${option.bankcode} || ${option.acctnum} || ${option.acctname}`}>
                        {option.bankcode} ({option.acctnum})
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item> 
              <Form.Item name="bankaccname" label="Bank Acc Name">
                {delayDepFromAcctname}
              </Form.Item>

              {/* Amount */}
              <Form.Item
                name="amt"
                label="Amount"
                rules={[
                  {
                    required: true,
                    message: "Please input the amount!",
                  },
                ]}
              >
                <InputNumber
                  onChange={handleChangeDelayDepAmt}
                  min={1}
                  type="number"
                  value={delayDepAmt}
                />
              </Form.Item>


              { createSubmit?
                <Button type="primary" onClick={onSubmitFormNew} disabled={createDepositDisable}>
                  Submit
                </Button>: <Spin style={{margin:7}}></Spin>
              } 
            </Form>
        </div>
      </div>
    );
  };

  const selectDelayDepCountryCode = (value) => {
    setDelayDepCountryCode(value);
  };

  const handleChangeDelayDepAmt = (value) => {
    setDelayDepAmt(value);
    setCreateDepositDisable(false);

    if (!delayDepFromBankcode || !delayDepFromAcctnum || !delayDepFromAcctname) {
      setCreateDepositDisable(true);     
    }
  }

  const getCustBankDetailsDelayTopup = async (memberUserId)=>{

    let respUserBank = await userBankAll({userid:memberUserId});
    let respBankCon = await respUserBank.json();

    if (respBankCon.status ==="ok" ) {
      if (Object.keys(respBankCon.data).length > 0 ) {      
        
        let count = 0;

        let newBankData = respBankCon.data.map((obj) => {
              return {
                key: count++,
                userid  : memberUserId,
                bankcode: obj.bankcode,
                bank : obj.bank,
                acctnum : obj.acctnum,
                acctname : obj.acctname,
                status : obj.status, 
                actionStatus : 
                  obj.status == 5 || obj.status == 7
                  ? "Pending"
                  : obj.status == 1
                  ? "Active"
                  : obj.status == 9
                  ? "Deleted"
                  : "-",
              };
            });

        newBankData = sortBankData(newBankData);

        setDelayDepBankLisOptions(newBankData);
      } 

    }
  }

  const onSubmitFormNew = async () => {
    formDelayDep.setFieldsValue({
      bankReceipt: fileList[0],
    });

    setCreateSubmit(false);

    if (!delayDepToBankCode || !delayDepToAcctnum || !delayDepToAcctname) {
      // message.error("Please select Deposit To Bank Details")
      alertData.title = "Field Required";
      alertData.msg = "Please select Deposit To Bank !";
      sharedErrorAlert(alertData);
      setCreateSubmit(true);
      return;
    }

    if (!delayDepFromBankcode || !delayDepFromAcctnum) {
      alertData.title = "Field Required";
      alertData.msg = "Please select Customer Bank Details !";
      sharedErrorAlert(alertData);
      setCreateSubmit(true);
      return;
    }

    if (!delayDepFromAcctname) {
      alertData.title = "Field Required";
      alertData.msg = "Please Add Bank Account Name for This User!";
      sharedErrorAlert(alertData);
      setCreateSubmit(true);
      return;
    }

    if (!delayDepAmt) {
      alertData.title = "Field Required";
      alertData.msg = "Please enter Deposit Amount !";
      sharedErrorAlert(alertData);
      setCreateSubmit(true);
      return;
    }

    const row = await formDelayDep.validateFields();
    //const row = await form.validateFields();

    const uploadResult = await uploadFileV2();
    
    if (uploadResult.status === "ok"){
      let filename = uploadResult.data !== undefined ? uploadResult.data.filename : '';
        const insertResult = await dataInsert2New(row, filename);    
        
        if (insertResult.status === "ok") {
          setManualDepositVisible(false);
          setFileList([]);
          onCancelDelayDep();
          depositList();
          //setPhoneNum("");
          alertData.title = "Success";
          alertData.msg = insertResult.msg;
          sharedSuccessAlert(alertData);
         }
         else {
          alertData.title = "Failed";
          alertData.msg = insertResult.msg;
          sharedFailAlert(alertData);
         }
    }
    else {
      // message.error(uploadFileV2.msg);
      alertData.title = "Failed";
      alertData.msg = uploadResult.msg;
      sharedFailAlert(alertData);
    }

    setCreateSubmit(true);
  };

  const onCancelDelayDep = ()=>{
    setFileList([]);
    setManualDepositVisible(false);
    setDelayDepFromBankData(null);
    setDelayDepFromAcctname("");
    setDelayDepFromAcctnum("");
    setDelayDepFromBankcode("")
    setDelayDepCountryCode("66");
    setDelayDepPhoneNum("");
    setDelayDepBankLisOptions([]);
    setDelayDepAmt("");

    setCreateDepositDisable(true);
    formDelayDep.resetFields();
  }
  //#endregion


   //#region ------- Receipt file Uploads --------
   const fileProps = {
    listType: "picture",
    onRemove: (file) => {
      setFileList([]);
    },
    fileList: fileList,
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        let newFileList = [];
        newFileList.push(file);
        setFileList(newFileList);
      });

      reader.readAsDataURL(file);
      return false;
    },
    onChange: (info) => {
      // 1. Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      let newFileList = [...info.fileList];
      newFileList = newFileList.slice(-1);

      // 2. Read from response and show file link
      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }

        return file;
      });

      setFileList(newFileList);
      return false;
    },
  };

  const receiptUploads = () => {
    return (
      <>
        <Upload {...fileProps} fileList={fileList}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
      </>
    );
  };

  const uploadFileV2 = async () => {
    const formData = new FormData();
    formData.append("fileReceipt", fileList[0]);
    if (fileList[0]!== undefined){
         const response = await uploadReceiptV2(formData);
        let result = await response.json();
        return result;
    }

    return { status:'ok'};
  };

  //#endregion

  ////#region  Functions
  const bankcodeList = async () => {
    let requestData = {
      bankType: BANK_INFO_TYPE.DEP,
      columnRequire : ['AccountNum', 'BankCode', 'AccountName'],
    }

    const response = await getBankList(requestData);
    let result = await response.json();

    if (result.status ==  'ok'){
      setCSBankListOptions(result.data)
    }
    else{
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
    }
  };

  //Data Insert
  const dataInsert2 = async (data, path) => {

    let requestData = {
      mobile : countryCode+phoneNum,
      bankcode: bankcode,
      bankaccnum: acctnum,
      bankaccname: data.bankaccname,
      uploadref: path,
      amt: amt,
      createby: userId,
      reqToken   : md5(getReqToken('depoistManualCreate')),
    }


    return receiptFormNewV2({
      mobile : countryCode+phoneNum,
      bankcode: bankcode,
      bankaccnum: acctnum,
      bankaccname: data.bankaccname,
      uploadref: path,
      amt: amt,
      createby: userId,
      reqToken   : md5(getReqToken('depoistManualCreate')),
    })
      .then((response) => response.json())
      .then((json) => {

        return { status:'ok' , errcode:'', msg:json.msg};
      });
  };
  
  const onSubmitForm = async () => {
    form.setFieldsValue({
      bankReceipt: fileList[0],
    });
    const row = await form.validateFields();

    const uploadResult = await uploadFileV2();
    if (uploadResult.status ==="ok"){

      let filename = uploadResult.data!==undefined? uploadResult.data.filename : '';
        
      setCreateSubmit(false);

      let requestData = {
        mobile : countryCode+phoneNum,
        bankcode: bankcode,
        bankaccnum: acctnum,
        bankaccname: row.bankaccname,
        uploadref: filename,
        amt: amt,
        createby: userId,
        reqToken   : md5(getReqToken('depositManualCreate')),
      }
  
      const insertResp = await receiptFormNewV2(requestData);
      let insertResult = await insertResp.json();
  
      if (insertResult.status === "ok") {
          setManualDepositVisible(false);
          setFileList([]);
          form.resetFields();
          depositList();
          setPhoneNum("");
          message.success(insertResult.msg);
      }else {
          message.error(insertResult.msg);
      }
    }else {
        message.error(uploadFileV2.msg);
    }

    setCreateSubmit(true);
  };

  const selectCountryCode = (value) => {
    setCountryCode(value);
  };

  const validateUserPhone = async(phoneNum)=> {
    const response = await csVerifyMobile({mobile: countryCode+phoneNum});
    let result = await response.json();

    if (result.status === "ok") {
      setCreateDepositDisable(false);
    }else{
      setCreateDepositDisable(true);
      message.error(result.msg);
    }
  }

  const validateUserPhoneV2 = async(phoneNum) => {
    const response = await csVerifyMobile({mobile: delayDepCountryCode+phoneNum});
    let result = await response.json();

    if (result.status === "ok") {
      setCreateDepositDisable(false);
      getCustBankDetailsDelayTopup(result.data.userid);
    }else{
      setCreateDepositDisable(true);

      alertData.title = "Error";
      alertData.msg = "Phone Number is Invalid !";
      sharedErrorAlert(alertData);
    }
  }

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  ////#endregion Functions

  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex=="newNickname"? "nickname":dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });

  const columns = [
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "date",
      width: "200px",
      ellipsis: true,
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      fixed:"left",
    },
    // {
    //   align: "center",
    //   title: "TrxnId",
    //   dataIndex: "trxnId",
    //   width: "200px",
    // },
    // {
    //   align: "center",
    //   title: "userId",
    //   dataIndex: "userId",
    //   width: "200px",
    // },
    {
      ellipsis: true,
      align: "center",
      title: "Bank Name(In)",
      dataIndex: "bankNameIn",
      width :"11.00%",
      // width: "200px",
      ...getColumnSearchProps("bankNameIn"),
      render : (text) => {
        return <Tooltip title={text}>
            <label>{text}</label>
        </Tooltip>
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Bank Account(In)",
      dataIndex: "bankAcctIn",
      width: "13.00%",
      render: (text) => { return <Tooltip placement="left" title={text}>{text}</Tooltip> } ,
      //width: "200px",
      ...getColumnSearchProps("bankAcctIn"),
    },
    {
      ellipsis: true,
      align: "center",
      title: "Member nickname",
      dataIndex: "newNickname",
      ...getColumnSearchProps("newNickname"),
      width: "200px",
      //dataIndex: "nickname",
      //...getColumnSearchProps("nickname"),
    },
    {
      ellipsis: true,
      align: "center",
      title: "Amount",
      dataIndex: "amount",
      width: "150px",
      render: (text) => {
        return FormatPt(text);
      },
      ...getColumnSearchProps("amount"),
    },
    {
      ellipsis: true,
      align: "center",
      title: "Actual Amount",
      dataIndex: "actualAmount",
      width: "150px",
      render :(text)=> {
          if (text===0) {
              return <>-</>;
          }else {
              return <>{FormatPt(text)}</>
          }
      },
      ...getColumnSearchProps("actualAmount"),
    },
    // {
    //    title :"receipt",
    //    dataIndex:"receipt",
    //    width:"200px",
    // },
    {
      ellipsis: true,
      align: "center",
      title: "Status",
      dataIndex: "action",
      width: "150px",
      render: (text, record) => {
        switch (record.actionStatus) {
          case "Pending":
            return (
              <Tooltip title="Press Me!">
                <Button
                  style={{ backgroundColor: "#F7B105", border: "none" }}
                  name={"Check"}
                  type="primary"
                  onClick={(event) => {
                    pendingBtnV2(record, event.target);
                  }}
                >
                  Pending
                </Button>
              </Tooltip>
            );

          case "Rejected":
            return <Badge status="error" text="Rejected" />;

          case "Approved":
            return <>
                  <Badge status="success" text="Approved" />&nbsp;&nbsp;
                    {
                      record.pptTrxnId!='00000000-0000-0000-0000-000000000000' ?
                      (                   
                        <Tooltip title="View Details">
                        <Button type="primary" shape="circle" icon={<InfoCircleFilled />} 
                          onClick={()=>{
                            setEditMode(false);
                            ViewPPTDetails(record);
                          }
                          }
                        />
                      </Tooltip>
                      ):
                     ""
                    }

                  </>
          default:
            return <></>;
        }
      },
    },
    {
       title :"Remark",
       dataIndex:"remark",
       width:"205px",
      //DELAY_DEPOSIT
       filters: [
        {
          text: 'Delayed Deposit',
          value: PPT_RM.DELAY_DEP,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.remark.toLowerCase().indexOf(value.toLowerCase()) === 0 ,
       //DELAY_DEPOSIT
      //...getColumnSearchProps("remark"),
       render:(text,record) => {
          let fulltext     = `{0} (${record.completeByCS})`;
          let actionStatus = record.actionStatus;
          switch (actionStatus) {
            case "Approved" :
              fulltext = fulltext.replace('{0}', 'Approved by');
              break;
            case "Rejected":
              fulltext = fulltext.replace('{0}', 'Rejected by');       
              break;
          }
          if (actionStatus == "Approved" ){
            if (record.remark.includes(PPT_RM.DELAY_DEP)) {
                let remark = record.remark;
                let newRemark = remark.indexOf('||') > 0 ? remark.split('||')[0]: remark;
                return <span><b>{fulltext}<br/>{newRemark}</b></span>;
            }
            return <span><b>{fulltext}</b></span>;
            
          } else if (actionStatus == "Rejected") {
            if (text =="") {
                return <span><b>{fulltext}</b></span>;
            }else{
              return <span>{text}<br/><b>{fulltext}</b></span>;
            }
          }
       }
    },
  ];

  //columns = editMode? pptColumns: pptColumns.slice(0,6);

  const pendingBtn = (record) => {
    form.resetFields();
    setDepositVisible(true);
    setSelected(true);
    setActiveKey(record.key);
    setConfirmAmount(record.amount);
  };

  //#region Puppet Transactions
  const onCancelDepReject = () => {
      if (selected)  { 
          setDepositVisible(false); 
      } else {
          setRejectVisible(false);
      }
  }


  let pptColumns = [
    {
      align: "center",
      title: "",
      dataIndex:"PptBankTransactionID",
      width: "10px",
      ellipsis: true,
      render: (text)=> {
        return "";
      }
    },
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "BankTrxTime",
      width: "200px",
      ellipsis: true,
      // render:(text) => {
      //   return moment(text).format('YYYY-MM-DD hh:mm:ss');
      // }
    },
    {
      align: "center",
      title: "Bank Name(In)",
      dataIndex: "BankCode",
      width: "150px",
      ellipsis: true,
    },    
    {
      align: "center",
      title: "Account Number",
      dataIndex: "BankAccNo",
      width: "150px",
      ellipsis: true,
    },
    {

      align: "center",
      title: "Bank Details",
      dataIndex: "BankDetails",
      width: "200px",
      ellipsis: true,
      ...getColumnSearchProps("BankDetails"),
    },
    {
      align: "center",
      title: "Amount",
      dataIndex: "BankAmount",
      width: "150px",
      ellipsis: true,
      render: (text) => {
        return FormatPt(text);
      }
    },
    {
      align: "center",
      title: "Action",
      dataIndex: "PptBankTransactionID",
      width: "200px",
      ellipsis: true,
      visible:false,
      render: (text, record) => {
        return (
          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
              display: "flex",
            }}
          >
              <Button
                type="primary"
                loading={loadings[1]}
                onClick={() => {
                  //handleLock(record);
                  approvePPTTrxn(record, 1);
                }}
              >
                { processing ? "Processing" : "Approve" }
              </Button>
          </div>
        );
      }
    }
  ];



  const pendingBtnV2 = (record) => {
    form.resetFields();
    setDepositVisible(true);
    setSelected(true);
    setActiveKey(record.key);
    setConfirmAmount(record.amount);
  };

const handleApproveV2 = async (index)=> {

  setTrxnLoading(true);

  var newData = [...originData];
  let matchBank = newData[activeKey].bankcode, matchAcctno = newData[activeKey].bankAcctIn, matchAmt = newData[activeKey].amount , matchTrxnId = newData[activeKey].trxnId, memberNick = newData[activeKey].nickname;
  let matchUserId = newData[activeKey].userId;

  setPuppetVisible(true);
  setMemberNickname(memberNick);
  setTopupAmt(matchAmt);

  //Get user Bank Details
  const respUser = await userProfileBank({ userid: matchUserId });
  let resUser = await respUser.json();

  if (resUser.status === "ok") {
    if (Object.keys(resUser.data).length > 0) {
      let userData = resUser.data;

      setMemberBankCode(userData.bankcode);
      setMemberAcctNum(FormatMask(userData.acctnum));
      }
  }
      
  let requestData = {
    bankCode : matchBank,
    bankAccNum : matchAcctno,
    amt : matchAmt
  }

  const response = await puppetTrxnList(requestData);
  let result = await response.json();

  if (result.status == "ok") {
    if (result.data && result.data.length) {
      const pending = result.data.filter((item) => item.Status === 0 || item.Status === 8 );
      setFilterPptTrxn(pending);
      setMTTrnxId(matchTrxnId);

      setEditMode(true);
    }
    else {
      setFilterPptTrxn([]);
    }
  }
  else {
    alertData.title = "Failed";
    alertData.msg = result.msg;
    sharedFailAlert(alertData);
    setFilterPptTrxn([]);
  }

  setTrxnLoading(false);
}

  const approvePPTTrxn = async (record, index) => {
  
      var newData = [...originData];

      let approveData = {
        trxnId       : mtTrxnId, 
        pptTrxnId    : record.PptBankTransactionID, 
        processtype  : PROCESS_TYPE.MANUAL, 
        csUserid     : userId,
        reqToken     : md5(getReqToken('depositManualApprove')),
        acctnum      : record.BankAccNo
      }

      //console.log(`requestData >> ${JSON.stringify(approveData)}`);
      if (index > -1) {
        loadingTrue(index);
        setProcessing(true);
        csDepositManualApprove(approveData)
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "ok") {
            setData(newData);
            newData[activeKey].actionStatus = "Approved";
            setSelected(false);
            setDepositVisible(false);
            form.resetFields();
            alertData.title = "Success";
            alertData.msg = json.msg;
            sharedSuccessAlert(alertData);
            depositList();
            setPuppetVisible(false);
            loadingFalse(index);
            setProcessing(false);
          }
          else {
            // message.error(json.msg);
            alertData.title = "Failed";
            alertData.msg = json.msg;
            sharedFailAlert(alertData);
            loadingTrue(false);
            setProcessing(false);
            
            if (json.errcode === "2072") {
              newData[activeKey].actionStatus = json.data.status;
              setSelected(false);
              setDepositVisible(false);
              form.resetFields();
            }
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          alertData.title = "Error";
          alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
          sharedErrorAlert(alertData);
          
        });
      }
      else{
        loadingTrue(index);
        setProcessing(false);
        // message.error("Connection not stable, please try again !");
        alertData.title = "Failed";
        alertData.msg = "Connection not stable, please try again !";
        sharedFailAlert(alertData);
      }
     
  }

  const onCancelApproveTrxn =() => {
    setPuppetVisible(false);
    setMemberNickname("")
    setTopupAmt("");
    setMemberAcctNum("");
    setMemberBankCode("");
  }  

  const ViewPPTDetails = async (record) => {

    //Get user Bank Details
    const respUser = await userProfileBank( {userid: record.userId} );
    let resUser = await respUser.json();
    if (resUser.status ==="ok") {
        if (Object.keys(resUser.data).length > 0) {
            let userData = resUser.data;

            setMemberBankCode(userData.bankcode);
            setMemberAcctNum(FormatMask(userData.acctnum));
        }
    }

    const response = await puppetTrxnDetails({trxnId:record.pptTrxnId});
    let result = await response.json();

    if (result.status === "ok") {
       if (result.data && result.data.length) {
           setFilterPptTrxn(result.data);  
           setMemberNickname(record.nickname);
           setTopupAmt(record.amount);
           setPuppetVisible(true);     
       }
    }else {
        alertData.title = "Failed";
        alertData.msg = "Transaction not found";
        sharedFailAlert(alertData);
    }
  }
  //#endregion

    //#region -------- Manual Deposit Form --------
    const onManualCreateFormOld = () => {
      return (
        <div>
          <div>
            <Form form={form} layout="vertical" name="depositform">
               {/* Mobile Id*/}
               <Form.Item
                  label="Phone Number"
                  required={true}
                  >
                    <Input.Group compact>
                      <Select value={countryCode}
                        onChange={selectCountryCode}>
                        <Option value="66">+66</Option>
                        <Option value="60">+60</Option>
                      </Select>
                      <Input style={{width:"66%"}} defaultValue="" placeholder="phone number" value={phoneNum}
                        onChange={(e) => {
                          setPhoneNum(e.target.value.replace(/\D|^0/g, ""));
                        }}
                        onBlur={(e) => {
                            console.log(`validate phone number`);
                            validateUserPhone(e.target.value.replace(/\D|^0/g, ""));
                        }}
                      />
                    </Input.Group>
                  </Form.Item>  
              {/* Upload Receipt */}
              <Form.Item
                name="bankReceipt"
                label="Bank Receipt"
              >
                {receiptUploads()}
              </Form.Item>
  
              {/* Bank Acc Name */}
              <Form.Item
                name="bankaccnum"
                label="Bank Account Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your bank!",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.children.includes(input);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={(value, event) => {
                    setAcctNum(value);
                    //Search Bank Acc Name
                    for (var i = 0; i < csBankListOptions.length; i++) {
                      if (csBankListOptions[i].bankAcc === value) {
                        setBankCode(csBankListOptions[i].bank);
                        form.setFieldsValue({
                          bankcode: csBankListOptions[i].bank,
                          bankaccname: csBankListOptions[i].bankAccName,
                        });
                      }
                    }
                  }}
                >
                  {csBankListOptions.map((option) => {
                    return (
                      <Option key={option.bankAcc} value={option.bankAcc}>
                        {option.bank} ({option.bankAcc})
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
  
              {/* Bank Acc Num */}
              {/* <Form.Item name="bankcode" label="Bank Code">
                <Input disabled />
              </Form.Item> */}
  
              {/* Bank Acc Name */}
              <Form.Item name="bankaccname" label="Bank Acc Name">
                <Input disabled/>
              </Form.Item>
  
              {/* Amount */}
              <Form.Item
                name="amt"
                label="Amount"
                rules={[
                  {
                    required: true,
                    message: "Please input the amount!",
                  },
                ]}
              >
                <InputNumber
                  onChange={(value) => {
                    setAmt(value);
                  }}
                  min={1}
                />
              </Form.Item>
  
              { createSubmit?
                <Button type="primary" onClick={onSubmitForm} disabled={createDepositDisable}>
                  Submit
                </Button>: <Spin style={{margin:7}}></Spin>
              }  
            </Form>
          </div>
        </div>
      );
    };
    //#endregion

  const deposit = () => {
    return (
      <Form form={form}>
        <Form.Item name={["member", "amount"]} label="Deposit Amount">
          <div>{FormatPt(confirmAmount)}</div>
        </Form.Item>
        <Form.Item
          id="amount"
          name={["member", "amount"]}
          label="Confirm Amount"
          style={{display:'none'}}
        >
          <Input
            type={"number"}
            onChange={(e) => {
              setAmount(Number(e.target.value));
            }}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit" onClick={depositCancel}>
            Cancel
          </Button>
          {
            depMgmtManAppRejDep(role) ?
            (
              <>
                <Button
                  loading={loadings[0]}
                  style={{
                    marginRight: 20,
                    marginLeft: 20,
                    backgroundColor: "#4190f7",
                    borderColor: "#4190f7",
                  }}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    handleApproveV2(0);
                  }}
                >
                  View Trxn & Approve
                </Button>
                <Button
                  danger
                  type="primary"
                  htmlType="submit"
                  onClick={(event) => {
                    handleReject(event.target);
                  }}
                >
                  Reject
                </Button>
              </>
            ):
            (<></>)
          }

        </Form.Item>
      </Form>
    );
  };

  const depositCancel = () => {
    setDepositVisible(false);
    // setSelected(false);
  };

  const rejectCancel = () => {
    setRejectVisible(false);
    setSelected(true);
  };

  const handleReject = () => {
    setRejectVisible(true);
    setSelected(false);
    return;
  };

  const rejectView = () => {
    return (
      <div>
        <Form form={form}>
          <Form.Item>
            <TextArea rows={4} onChange={(e) => setReason(e.target.value)} />
          </Form.Item>

          <Form.Item>
            <div style={{ textAlign: "center", marginTop: 30 }}>
            { //role === CS_USER_TYPE.Senior || role === CS_USER_TYPE.CS ?
                (<>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: 10 }}
                    onClick={rejectCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={loadings[1]}
                    danger
                    id="rejectbtn"
                    name="rejectbtn"
                    type="primary"
                    htmlType="submit"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      rejectBtn(1);
                    }}
                  >
                    Reject
                  </Button>
                </>
                )
                //:(<></>)             
            }
            </div>
          </Form.Item>
        </Form>
      </div>
    );
  };

  const rejectBtn = (index) => {
    loadingTrue(index);
    if (reason !== "") {
      var newData = [...originData];

      let rejectData =  {
        trxnId: newData[activeKey].trxnId,
        reason: reason,
        csUserid : userId,
        process : PROCESS_TYPE.MANUAL,
        reqToken   : md5(getReqToken('depositManualReject')),
      }

      csDepositReject(rejectData)
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "ok") {
            loadingFalse(index);
            newData[activeKey].actionStatus = "Reject";
            setRejectVisible(false);
            alertData.title = "Success";
            alertData.msg = json.msg;
            sharedSuccessAlert(alertData);
            depositList();
          } else {
            alertData.title = "Error";
            alertData.msg = json.msg;
            sharedFailAlert(alertData);
            loadingFalse(index);
            if (json.errcode === "2072") {
              newData[activeKey].actionStatus = json.data.status;
              setRejectVisible(false);
              form.resetFields();
            }
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          alertData.title = "Error";
          alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
          sharedErrorAlert(alertData);
        });
    } else {
      loadingFalse(index);
      alertData.title = "Field Required";
      alertData.msg = "Please fill in Reject Reason !";
      sharedErrorAlert(alertData);
    }
  };

  const styles = {
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
    },
    refreshBtn: {
      marginLeft: 10,
    },
    tabelContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    // dropdownButtonStyle:{
    //   marginLeft:"-16px",
    //   marginRight:"-16px",
    // },
    hide :{
      display:"none",
    }
  };

  pptColumns = editMode? pptColumns: pptColumns.slice(0,6);
  let pptPagination = editMode ? 
                      {
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ["5", "10", "20"],
                        style: styles.paginationStyle,
                      }: false ;
  return (
    <div className="manualDeposit">
      <PageHeader ghost={false}>
        <div style={styles.pageHeaderContainer}>
          <div>
            <Button
              style={styles.refreshBtn}
              type="primary"
              onClick={() => {
                depositList();
              }}
            >
              Refresh Page
            </Button>
              {
                  depMgmtManCrDep(role) ?
                    <Button
                      type="primary"
                      style={styles.refreshBtn}
                      onClick={() => {
                        setManualDepositVisible(true);
                      }}
                    >
                      Create Deposit
                    </Button>
                  :
                    <></>
              }
          </div>
          <Radio.Group
            buttonStyle={"solid"}
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <Radio.Button value="All">All</Radio.Button>
            <Radio.Button value="Pending">Pending</Radio.Button>
            <Radio.Button value="Approved">Approved</Radio.Button>
            <Radio.Button value="Rejected">Reject</Radio.Button>
          </Radio.Group>
        </div>
      </PageHeader>
      <Modal
        style={style.hide}
        title="Manual Deposit Form"
        visible={manualDepositVisible}
        footer={null}
        getContainer={false}
        onCancel={() => {
          form.resetFields();
          setFileList([]);
          setManualDepositVisible(false);
        }}
      >
        {onManualCreateForm()}
      </Modal>
      <Modal
        title="Manual Deposit Form"
        visible={manualDepositVisible}
        getContainer={false}
        
        onCancel= {() => {
          onCancelDelayDep();
        }}
        cancelButtonProps={{ type: "primary" }}   
        okButtonProps={({style:style.hide})} 
        okText={viewMemberDlgOK}
        cancelText="Close"
        //
      >

        {onManualCreateForm()}
      </Modal>
      <Modal
        title={selected ? "Deposit Confirmation" : "Reject Reason"}
        visible={selected ? depositVisible : rejectVisible}
        footer={null}
        getContainer={false}
        onCancel={onCancelDepReject}
      >
        {selected ? deposit() : rejectView()}
      </Modal>
      <div style={styles.tabelContainer}>
        <Table
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) => (
              record.receipt!=''?    
              <FileImageOutlined
              onClick={(e) => onExpand(record, e)}
              style={{ fontSize: 30 }}
              /> : null
            ),
            expandIconColumnIndex: 10,
            expandedRowRender: (record) => (
                <div style={{ textAlign: "center" }}>
                <img
                  src={`${fileDomain}${record.receipt}`}
                  style={{ height: 500 }}
                  alt="receipt"
                /> 
              </div>
              
            ),
            rowExpandable: (record) => record.receipt !== "",
          }}
          loading={tableLoading}
          columns={columns}
          dataSource={data}
          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: settings.pageSizeOptions,
            style: styles.paginationStyle,
            alignmentBottom: 'right',
          }}
        >
        </Table>
      </div>
      <Modal 
        title={editMode? "Transactions List" : "Transaction Details"}
        visible={puppetVisible}
        style={{ top : "35%" }}
        onCancel={()=>{
            setPuppetVisible(false);
            onCancelApproveTrxn();
        }}
        width={1200}
        footer={[
          <Button key="back"
          align="left"
          onClick={onCancelApproveTrxn}
          >
            {editMode? "Cancel": "Close"}
          </Button>
        ]}
      >
       <Row style={styles.refreshBtn}>
            Member Nickname : {memberNickname} &nbsp;&nbsp;&nbsp; Deposit Amount :  {FormatPt(topupAmt)}&nbsp;&nbsp;&nbsp;   Bank Code : {memberBankCode}   &nbsp;&nbsp;&nbsp; Acct No. : {memberBankAcctNum}  
       </Row>
       <div style={styles.tabelContainer}>
          <Table
            loading={trxnLoading}
            columns={pptColumns}
            dataSource={filteredPptTrxn}
            scroll={{ x: isMobile ? "110%" : "100%", y: null }}
            pagination={{pptPagination}}
          />
        </div>
      </Modal>
    </div>
  );
}
