import React, { useEffect, useState } from "react";
import {
  Table,
  PageHeader,
  DatePicker,
  Button,
  Statistic,
  Row,
  Col,
  Card,
  message,
} from "antd";
import { FormatData } from "../../library/numbers";
import { reportCommission } from "../../config/networkConfig";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FormatDate } from "../../library/dates";

import moment from "moment";

export default function AgentReportComm() {
  const { userId } = useSelector((state) => state.login);
  const [report, setReport] = useState([]);
  const [filter, setFilter] = useState("");
  const [date, setDate] = useState("");
  const [totalComm, setTotalComm] = useState(0);
  const [loadings, setLoadings] = useState(false);

  useEffect(() => {
    reportList();
    // eslint-disable-next-line
  }, []);

  const reportList = () => {
    var count = 0;
    setLoadings(true);

    reportCommission({ userId: userId , date: FormatDate(date)})
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          setLoadings(false);
          console.log("reportCommission json", json);
          const newReport = Object.keys(json.data).length
            ? json.data.map((data) => {
                return {
                  key: count++,
                  dateTime: data.dateTime,
                  gameCode: data.gameCode,
                  selfComm: data.selfComm,
                  selfTurnover: data.selfTurnover,
                  totalComm: data.totalComm,
                };
              })
            : [];
          const filter = newReport.filter((data) => {
            return data.dateTime === newReport[0].dateTime;
          });

          const getTotalComm = filter.map((data) => data.totalComm);
          var totalComm = getTotalComm.reduce(function (previous, current) {
            return previous + current;
          }, 0);
          setTotalComm(totalComm);
          setReport(newReport);
          setFilter(filter);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        message.error(
          "An unexpected error occurred. Contact your system administrator "
        );
      });
  };

  const columns = [
    {
      align: "center",
      title: "Date",
      dataIndex: "dateTime",
      width: "200px",
      ellipsis: true,
    },
    {
      align: "center",
      title: "Game Code",
      dataIndex: "gameCode",
      width: "200px",
      ellipsis: true,
    },
    {
      align: "center",
      title: "Self Commission",
      dataIndex: "selfComm",
      width: "200px",
      ellipsis: true,
      render:function(text) {
        return FormatData(text);
      }
    },
    {
      align: "center",
      title: "Self Turnover",
      dataIndex: "selfTurnover",
      width: "200px",
      ellipsis: true,
      render:function(text) {
        return FormatData(text);
      }
    },
    {
      align: "center",
      title: "Total Commission",
      dataIndex: "totalComm",
      width: "200px",
      ellipsis: true,
      render:function(text) {
        return FormatData(text);
      }
    },
  ];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const printTotalComm = totalComm;

    const title = `Commission Report\n\n\nTotal Commission =>  ${printTotalComm}`;
    const headers = [
      ["ID", "Date", "Game Code", "Profit", "Turnover", "Win Lose"],
    ];
    var filteredReport = [...filter];
    const data = filteredReport.map((data) => {
      return [
        data.key,
        data.dateTime,
        data.gameCode,
        data.profit,
        data.turnover,
        data.winLose,
      ];
    });

    let content = {
      startY: 150,
      head: headers,
      body: data,
      headStyles: { fillColor: "#1DA57A" },
    };

    // doc.text(title, doc.internal.pageSize.getWidth() / 2, 40, {
    //   align: "center",
    // });
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Commission Report.pdf");
  };

  const datePicker = (date, dateString) => {
    setDate(dateString);
  };

  const searchBar = () => {
    reportList();
    //console.log('date <<< >>>  ' + date);
    // old code
    // var newReport = [...report];
    // const filter = newReport.filter((data) => data.dateTime === date);
    // const getTotalComm = filter.map((data) => data.totalComm);
    // var totalComm = getTotalComm.reduce(function (previous, current) {
    //   return previous + current;
    // }, 0);
    // setTotalComm(totalComm);
    // setFilter(filter);
  };

  const resetBtn = () => {
    setDate('');
    reportList();
  };

  const styles = {
    mainContainter: {
      padding: 30,
    },
    paginationStyle: {
      marginTop: 50,
    },
    mobilePageHearderContainer: {
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
    },
    eachButtonContainer: {
      alignItems: "center",
      display: "flex",
      height: 50,
    },
    mobileSearchResetBtn: {
      display: "flex",
      width: "100vw",
      justifyContent: "center",
      height: 50,
      alignItems: "center",
    },
  };
  return (
    <div>
      {isMobile ? (
        <div style={styles.mainContainter}>
          <PageHeader ghost={false}>
            <div style={{ textAlign: "center" }}>
              <div style={{ fontSize: 25 }}>Commission Report</div>
            </div>
          </PageHeader>
          <PageHeader
            style={styles.mobilePageHearderContainer}
            ghost={false}
            extra={[
              <div>
                <div style={styles.eachButtonContainer}>
                  <Button type={"primary"} onClick={exportPDF}>
                    Download PDF
                  </Button>
                </div>
                <div style={styles.eachButtonContainer}>
                  <DatePicker
                    style={styles.datePickerStyle}
                    onChange={datePicker}
                    value={ date !== "" ? moment(date) : ""}
                  />
                  <Button
                    type={"primary"}
                    onClick={searchBar}
                    style={{ marginLeft: 10 }}
                  >
                    Search
                  </Button>
                  <Button onClick={resetBtn} style={{ marginLeft: 10 }}>
                    Reset
                  </Button>
                </div>
              </div>,
            ]}
          >
            <div
              style={{
                width: 351,
                textAlign: "center",
                paddingLeft: 28,
              }}
            >
              <Row>
                <Col span={80}>
                  <Card>
                    <Statistic
                      title="Total Commission"
                      value = {FormatData(totalComm)}
                      //value={totalComm.toFixed(4)}
                    />
                  </Card>
                </Col>
              </Row>
            </div>
          </PageHeader>
          <Table
            dataSource={filter}
            columns={columns}
            scroll={{ x: "110%" }}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20"],
              style: styles.paginationStyle,
            }}
          />
        </div>
      ) : (
        <div style={styles.mainContainter}>
          <PageHeader ghost={false}>
            {/* <div style={{ textAlign: "center" }}>
              <div style={{ fontSize: 25 }}>Commission Report</div>
            </div> */}
          </PageHeader>

          <PageHeader
            ghost={false}
            extra={[
              <Button
                key="5"
                type="primary"
                onClick={() => {
                  reportList();
                }}
              >
                Refresh Page
              </Button>,
              <DatePicker
                key="1"
                id="datePicker"
                style={styles.datePickerStyle}
                onChange={datePicker}
                value={ date !== "" ? moment(date) : ""}
              />,
              <Button
                key="2"
                type={"primary"}
                style={styles.searchBtn}
                onClick={searchBar}
              >
                Search
              </Button>,
              <Button key="3" style={styles.resetBtn} onClick={resetBtn}>
                Reset
              </Button>,
              <Button key="4" type={"primary"} onClick={exportPDF}>
                Download PDF
              </Button>,
            ]}
          >
            <Row>
              <Statistic
                title="Total Commission"
                value = {FormatData(totalComm)}
                //value={totalComm.toFixed(4)}
                style={{
                  margin: "0 32px",
                }}
              />
            </Row>
          </PageHeader>
          <Table
            loading={loadings}
            scroll={{ x: isMobile ? "110%" : 100, y: null }}
            dataSource={filter}
            columns={columns}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20"],
              style: styles.paginationStyle,
            }}
          />
        </div>
      )}
    </div>
  );
}
