import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  SearchOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { 
  reportGameStats,
 } from "../../../config/networkConfig";
import { Table } from "ant-table-extensions";
import {
  PageHeader,
  Button,
  Breadcrumb,
  DatePicker,
  Select,
} from "antd";
import { isMobile } from "react-device-detect";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { settings } from "../../../config/appConfig";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { GameStatsVw } from "../../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";


export default function GameStats() {

  const { Option } = Select;
  const { userId , role, username, compCode } = useSelector((state) => state.login);
  const [report, setReport] = useState("");
  const [allReport, setAllReport] = useState("");

  const [todayDate, setTodayDate] = useState("");

  const [tableLoading ,          setTableLoading] = useState(false);
  const [trxLoading, setTrxLoading] = useState(false);

  const [date, setDate] = useState(moment(new Date()));
    
  const reportDate = useRef(null);

  useEffect(() => {
    if (GameStatsVw(role, username)){
    reportallReportList();
    }
    //reportShareDetailList();
    // eslint-disable-next-line
  }, []);

  useEffect(()=> {
    setDate(date);
  },[date]);

  const onClickExportBtn = ()=> {

    if (allReport.length === 0 ) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }

    document.getElementById("export-button-1").click();
  }

  const resetBtn = () => {
    setAllReport([]);
  };

  const trxDatePicker = (date, dateString) => {
    // setType("custom");
    setDate(date);
  };

  const searchBar = () => {
    if (!date) {
      reportDate.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date From !";
      sharedErrorAlert(alertData);
      return;
    }

    setTrxLoading(true);
    reportallReportList();
  }

  const reportallReportList = () => {
    setTableLoading(true);
    var count = 1;

    let requestData = {
      date : date.format("YYYY-MM")
    }

    reportGameStats(requestData)
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
        const allReport = Object.keys(json.data).length
        ? json.data.map((data) => {
            setTableLoading(false);
            return {
              key                   : count++,
              AcctDate              : data.AcctDate,
              Game                  : data.Game,
              Ticket                : data.Ticket,
              BetAmt                : data.BetAmt,
              Turnover              : (data.Turnover).toFixed(2),
              SumTurnover           : data.SumTurnover,
              TurnoverPercent       : ((data.Turnover / data.SumTurnover) * 100).toFixed(2),
              Winloss               : (data.Winloss).toFixed(2),
              UniquePlayer          : data.UniquePlayer,
            };
            })
            : [];

            let summaryTurnover = 0, summarySumTurnover = 0, summaryTurnoverPercent = 0, summaryWinloss = 0;
            let reportGameStatsSummary = [...allReport];

            reportGameStatsSummary.forEach(({ Turnover, TurnoverPercent, Winloss
            }) => {
              summaryTurnover                 += Number(Turnover);
              summaryTurnoverPercent          += Number(TurnoverPercent);
              summaryWinloss                  += Number(Winloss);
            });

            reportGameStatsSummary= [
              {
                key: "Summary",
                AcctDate                : "",
                Game                    : "",
                Ticket                  : "",
                BetAmt                  : "",
                Turnover                : (summaryTurnover).toFixed(2),
                SumTurnover             : Number(summaryTurnover) / Number(summarySumTurnover) * 100,
                TurnoverPercent         : (Number(summaryTurnoverPercent)).toFixed(0),
                Winloss                 : (summaryWinloss).toFixed(2),
              },
              ...reportGameStatsSummary,
            ];

            let todayDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
            setTodayDate(todayDate);

            setAllReport(reportGameStatsSummary);

          if (reportGameStatsSummary.length == 0 ) {
            alertData.title = "Error";
            alertData.msg = "No data !";
            sharedErrorAlert(alertData);
            setTableLoading(false);
            return;
          }
        } 
        else {
          alertData.title = "Error";
          alertData.msg = json.msg;
          sharedErrorAlert(alertData);
          setTableLoading(false);
        }        
      });

      
  };

  const columns = [
    {
      align: "center",
      title: "No",
      dataIndex: "key",
      width: 80,
      ellipsis: true,
      fixed:"left",
      render:function(record) {
        return record == "Summary" ? <b>{record}</b> : <p style={{marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "By Month",
      dataIndex: "AcctDate",
      width: 80,
      ellipsis: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      },
    },
    {
      align: "center",
      title: "Game",
      dataIndex: "Game",
      width: 100,
      ellipsis: true,
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      },
    },
    {
      align: "center",
      title: "Ticket",
      dataIndex: "Ticket",
      width: 100,
      ellipsis: true,
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      },
    },
    {
      align: "center",
      title: "Bet Amount",
      dataIndex: "BetAmt",
      width: 100,
      ellipsis: true,
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      },
    },
    {
      align: "center",
      title: "Turnover",
      dataIndex: "Turnover",
      width: 130,
      ellipsis: true,
      render:function(record, record2) {
        return record2.key == "Summary" ? <b>{record}</b> : <p style={{marginTop: "10px"}}>{record}</p>
      },
    },
    {
      align: "center",
      title: "Turnover %",
      dataIndex: "TurnoverPercent",
      width: 100,
      ellipsis: true,
      render:function(record, record2) {
        return record2.key == "Summary" ? <b>{record}</b> : <p style={{color: "blue", marginTop: "10px"}}>{record}</p>
      },
    },
    {
      align: "center",
      title: "Winloss",
      dataIndex: "Winloss",
      width: 100,
      ellipsis: true,
      render:function(record, record2) {
        return record2.key == "Summary" ? <b>{record}</b> : <p style={{marginTop: "10px"}}>{record}</p>
      },
    },
    {
      align: "center",
      title: "Unique Player",
      dataIndex: "UniquePlayer",
      width: 100,
      ellipsis: true,
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      },
    },
  ];

  const routes = [
    {
      path: "/",
      breadcrumbName: "Game Stats Stats",
    },
    {
      //   path: "first",
      breadcrumbName: "Report",
    },
    {
      path: "commBalanceReport",
      breadcrumbName: "Game Stats Stats Report",
    },
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 2;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    inlineBlock: {
      display: "inline-block",
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      // display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
      display: "inline",
    },
    resetBtn: {
      marginLeft: 10,
    },
    inline: {
      display: "inline",
    },
    hide: {
      marginLeft: 10000,
    },
    datePickerStyle: {
      width: 280,
    },
    datePickerSelectionStyle: {
      marginLeft: 10,
      width: 120,
    },
    refreshBtn: {
      marginLeft: 10,
    },
    headStyles: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 20,
      height: 20,
    },
    bodyStyle: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 60,
    },
    tagStyle:{
      width:"100px",
    },
    memberBtn: {
      marginTop:"10px",
      width:"150px", 
      marginBottom:"8px"
    }, 
  };

  return (
    <div>
      {
        GameStatsVw(role,username,compCode) ?
        
        <div className="eachPageContent">
        {
          <div>
            <PageHeader
              ghost={false}
              // title="Game Stats Stats Report"
            >
              <div style={styles.pageHeaderContainer}>
                <DatePicker
                    key="1"
                    style={styles.datePickerStyle}
                    onChange={trxDatePicker}
                    value={date}
                    picker="month"
                    ref={reportDate}
                    placeholder="Select a Month"
                  />,
                <div style={styles.inlineBlock}>
                  <Button
                    type={"primary"}
                    icon={<SearchOutlined
                    onClick={searchBar}
                    />}
                  />
                  <Button style={{...styles.resetBtn,...styles.refreshBtn}} onClick={resetBtn}>
                    Reset
                  </Button>
                </div>
                <Button 
                      type={"primary"} 
                      style={styles.refreshBtn} 
                      icon={<FileExcelOutlined />}
                      onClick={() => {
                          onClickExportBtn();
                      }}
                      
                  >
                    Export to Excel
                </Button>
              </div>
            </PageHeader>
            <div style={styles.tableContainer}>
              <Table
                loading={tableLoading} 
                columns={columns}
                dataSource={allReport}
                className="cs_gameStat"
                scroll={{ x: 100, y: null }}
                exportable={true}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: settings.pageSizeOptionsSmall,
                  style: styles.paginationStyle,
                }}
                exportableProps={{
                  // feedFields,
                  fileName: "GameStatsReport_"+todayDate,
                  btnProps: {
                    type: "primary",
                    icon: <FileExcelOutlined />,
                    id:"export-button-1",
                    children: <span>Export to Excel</span>,
                    style: {display:"none"}
                  },
                }}
              />
              <br></br>
              <p><b>REMARK :</b></p>
              <p><b><span style={{color:"blue"}}>[1]</span></b> Listing sorted by Winloss</p>
              <p><b><span style={{color:"blue"}}>[2]</span></b> WinLoss = Players' Winloss</p>
              <p><b><span style={{color:"blue"}}>[3]</span></b> Highlighted with Turnover &#8250;= OR WinLoss &#8250;= 3000 (-3000) </p>            
              <p><b><span style={{color:"blue"}}>[4]</span></b> WinLoss = Players' Winloss <span style={{color:"blue"}}><b>(Players' Loss = Company Earning)</b></span></p>
              <p><b><span style={{color:"blue"}}>Agenda: AWCPG - PocketGame, AWCSPADE - SpadeGaming, AWCFC - FattChai, AWCSEXYBCRT - AE_Bacarrat, AWCKINGMAKER - KingMaker, AWCRT - RedTiger, AWCSABA - SABA, SA - SA Gaming, HABA - Habanero</span></b></p>

            </div>
          </div>
        }
        </div>:
        <div style={{textAlign:"center",color:"red",fontSize:"150%"}}><h1 style={{color:"red"}}>Not Allowed to Access This Page</h1></div>
      }
    </div>
      
  );
}