import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  message,
  PageHeader,
  Button,
  Input,
  DatePicker,
  Badge,
  Modal,
  Spin,
  Typography,
} from "antd";
import {
    csWithdrawResortList,
    csWithdrawResortUpdate,
    csUpdatePuppetJobStatus,
} from "../../config/networkConfig";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import moment from "moment";
import momentTz from "moment-timezone";
import { settings } from "../../config/appConfig";
import { FormatNumComm} from "../../library/numbers";
import { witResortVw } from "../../library/permission";
import { 
  SearchOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import style from "../../resource/style/style";
import { FormatPt } from "../../library/numbers";
import login from "../../redux/reducer/login";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";


export default function WithdrawResortList() {
    const { Paragraph } = Typography;
    const { confirm } = Modal;
    const { issuper, role, username, userId } = useSelector((state) => state.login);

    const [searchText, setSearchText] = useState("");
    const [searchMobile,setSearchMobile] = useState("");
    const [searchDate, setSearchDate]    = useState("");

    const [trxDateFrom, setTrxDateFrom] = useState("");
    const [trxDateTo, setTrxDateTo] = useState("");
    const rptDateFrom = useRef(null);
    const rptDateTo = useRef(null);


    //Withdraw Resort List
    const [statementData,         setStatementData] = useState("");
    const [statementPageSize, setStatementPageSize]  = useState(100);
    const [statementPageNum,  setStatementPageNum]  = useState(1);
    const [statementTotal,       setStatementTotal]  = useState(10);
    const [statTableLoading,   SetStatTableLoading] = useState(false);

    //Withdraw Resort Dialog
    const [updateWithdrawVisible,     setUpdateWithdrawVisible] = useState(false);
    const [updateWithdrawSubmit,       setUpdateWithdrawSubmit] = useState(true);
    const [updateWithdrawAmt,                setUpdateWitheAmt] = useState(0);
    const [updateWithdrawSysCreate, setUpdateWithdrawSysCreate]= useState("");
    const [updateWithdrawJobId,         setUpdateWithdrawJobId] = useState("");
    const [updateWithdrawJobStatus, setUpdateWithdrawJobStatus] = useState("");
    const [updateWithdrawAccNo,         setUpdateWithdrawAccno] = useState("");
    const [updateWithdrawBankCode,    setUpdateWithdrawBankCode] = useState("");

    const [submitButtonLoading,    setSubmitButtonLoading] = useState(false);
    

    //Withdraw Job Status 
    const [withdrawStatus,              setWithdrawStatus] = useState([]);

    useEffect(() => {
      if (witResortVw(role, username)){
        viewBankStatement();
        initStatus();
      }
      // eslint-disable-next-line
    }, []);

    useEffect(()=> {
      setStatementPageNum(statementPageNum);
      setStatementPageSize(statementPageSize);
      setStatementTotal(statementTotal);
    },[statementPageNum, statementPageSize, statementTotal])
    ////#region Components

  
    ////#endregion

    //#region Functions
    const initStatus = async () =>{
 
      let jobStatus  = [
        {
          text :'Pending',
          value :'pending',
        },
        {
          text :'Re-Sort',
          value :'conflict',
        },
        {
          text :'Reject',
          value :'reject',
        },
        {
          text :'Approved',
          value :'approved',
        }
      ]
  
      setWithdrawStatus(jobStatus);
    }
    const onUpdateWithdraw = async () => {
      setUpdateWithdrawSubmit(true);

      let requestData = {
          pptId :updateWithdrawJobId,
          jobStatus:updateWithdrawJobStatus,
          csUsername: username,
      }

      console.log(`wit >> ${JSON.stringify(requestData)}`);
  
      const result = await csWithdrawResortUpdate(requestData);
      let resp = await result.json();
      if (resp.status === "ok") {
          alertData.title = "Success";
          alertData.msg = resp.msg;
          sharedSuccessAlert(alertData);
          setUpdateWithdrawVisible(false);
          viewBankStatement();
      }else{
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);
      }

      setUpdateWithdrawSubmit(false);
    }

    const handleUpdateStatus = (record) => {
      setUpdateWithdrawVisible(true);
      setUpdateWitheAmt(record.amt);
      setUpdateWithdrawJobId(record.pptTrxnId);
      setUpdateWithdrawAccno(record.toBankAcc);
      setUpdateWithdrawBankCode(record.toBankCode);
      setUpdateWithdrawSysCreate(record.sysCreateTime);
      setUpdateWithdrawJobStatus(record.pptJobStatus);


      // setSelected(true);
      // setVisible(true);
      // setMemberUserId(record.userId);
      // setActiveKey(record.key);
      // setReason("");
    };

    const handleCloseUpdateWithdraw = () =>{
      setUpdateWithdrawVisible(false);
      setUpdateWitheAmt("");
      setUpdateWithdrawJobId("");
      setUpdateWithdrawAccno("");
      setUpdateWithdrawBankCode("");
      setUpdateWithdrawSysCreate("");
      setUpdateWithdrawJobStatus("");
    }

    const handleTableChange = async (pagination, filters, sorter)=> {
      console.log(`filters >> ${JSON.stringify(filters)}`);
      
      await getBankStatement('','', pagination.current, pagination.pageSize , filters);

      await setStatementPageNum(pagination.current);

      await setStatementPageSize(pagination.pageSize);

    }

    const trxDatePicker = (date, dateString) => {
      setTrxDateFrom(date);
    };
  
    const trxDatePicker2 = (date, dateString) => {
      setTrxDateTo(date);
    };

    const handleSearch = (selectedKeys, confirm) => {
      confirm();
      setSearchText(selectedKeys[0]);
    };
  
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText("");
    };
  
    let searchInput = null;
  
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : false,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) setTimeout(() => searchInput.select());
      },
      render: (text) =>
        text ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          false
        ),
    });

    const viewBankStatement = async () => {
      // if (!trxDateFrom) {
      //   rptDateFrom.current.focus();
      //   message.error("Please enter date from");
      //   return;
      // }
  
      // if (!trxDateTo) {
      //   rptDateTo.current.focus();
      //   message.error("Please enter date to");
      //   return;
      // }

      // if (!moment(trxDateTo).isSameOrAfter(trxDateFrom)){
      //   message.error("Please choose correct date range");
      //   return;
      // }

      // let startDate = trxDateFrom.format("YYYY-MM-DD HH:mm");
      // let endDate = trxDateTo.format("YYYY-MM-DD HH:mm");

      //getBankStatement(startDate, endDate, statementPageNum, statementPageSize);

      getBankStatement('', '', statementPageNum, statementPageSize, {"fromAccNo":null,"fromAccName":null,"toBankAcc":null,"amt":null});
    }
  
    const getBankStatement = async(datefrom, dateto, pagenum, pagesize, filters) => {

      let startDate = new moment(moment(),"YYYY-MM-DD HH:mm").subtract(3,"days");
      let endDate = new moment(moment(),"YYYY-MM-DD HH:mm");

      // let startDate = new moment(moment(),"YYYY-MM-DD HH:mm").subtract(3,"days");
      // let endDate = new moment(moment(),"YYYY-MM-DD HH:mm");
      startDate = startDate.format("YYYY-MM-DD HH:mm");
      endDate = endDate.format("YYYY-MM-DD HH:mm");

      let newStartDate = momentTz.tz(moment(), "Asia/Kuala_Lumpur").subtract(3,"days").format("YYYY-MM-DD");  
      let newEndDate = momentTz.tz(moment(), "Asia/Kuala_Lumpur").format("YYYY-MM-DD");

      let countDL = 0;

      let requestData = {
        datefrom  : newStartDate,
        dateto    : newEndDate,
        page      : pagenum,
        pagesize  : pagesize,
        filters   : filters, 
      }
      
      SetStatTableLoading(true);

      const responseDL = await csWithdrawResortList(requestData);
        let resultDL = await responseDL.json();
        if (resultDL.status === "ok") {
          const dlData = Object.keys(resultDL.data.detail).length
          ? resultDL.data.detail.map((obj) => {
              SetStatTableLoading(false);
              return {
                key           : ++countDL,
                pptTrxnId     : obj.pptTrxnId,
                pptJobType    : obj.pptJobType,
                pptUsername   : obj.pptUsername,
                pptJobParam   : obj.pptJobParam,
                pptJobStatus  : obj.pptJobStatus,
                actionStatus  : obj.pptJobStatus == "0" ? "pending" 
                                : obj.pptJobStatus == "7" || obj.pptJobStatus == "8" ? "conflict" 
                                : obj.pptJobStatus == "1" ? "approved" 
                                : obj.pptJobStatus == "9" ? "reject"
                                : obj.pptJobStatus == "6" ? "duplicate"
                                : "error",
                isDone        : obj.isDone,
                sysCreateTime : obj.sysCreateTime,
                sysProcessTime: obj.sysProcessTime == '1970-01-01 08:00:00'? '' : obj.sysProcessTime,
                toBankAcc     : obj.toBankAcc, 
                toBankCode    : obj.toBankCode,
                amt           : obj.amt,
                fromAccNo     : obj.fromAccNo,
                fromAccName   : obj.fromAccName,
                remark        : obj.remark
              };
            })
          : [];

          console.log(`dlData >> ${JSON.stringify(dlData)}`);

          setStatementData(dlData);

          const dlDataSummary = Object.keys(resultDL.data.summary).length
                        ? resultDL.data.summary.map((x) => {
                          return {
                              current : x.current,
                              pagesize: x.pagesize,
                              totalTrxn : x.totalTrxn
                          };
                        })
                        : [];
            
            setStatementTotal(dlDataSummary?.[0]?.totalTrxn ?? 10 );
            //console.log(`dlDataSummary?.[0]?.totalTrxn >> ${dlDataSummary?.[0]?.totalTrxn}`);    

            SetStatTableLoading(false);
        }else {
          alertData.title = "Failed";
          alertData.msg = resultDL.msg;
          sharedFailAlert(alertData);

        }

        SetStatTableLoading(false);
    }

    const handleUpdateRecord = async (record) => {
      confirm({
        className: "confirmationModalStyle",
        title: "Confirmation",
        centered: true,
        icon: <QuestionCircleOutlined />,
        content: 'Are you sure you want to update status to "Approved"?',
        onOk() {
            onApprovePuppetJob(record);
        },
        onCancel() {          
        },
      });
    }

    const onApprovePuppetJob = async (record) => {
      setSubmitButtonLoading(true);
      SetStatTableLoading(true);

      let requestData = {
        jobId : record.pptTrxnId,
        jobStatus : 1,
        done : 1,
        remark : "Manual clear by",
        csUserId : userId,
        version : 1
      }

      csUpdatePuppetJobStatus(requestData)
        .then((response) => response.json())
        .then((json) => {
            if (json.status === "ok") {
              viewBankStatement();
              setSubmitButtonLoading(false);
              SetStatTableLoading(false);
              alertData.title = "Success";
              alertData.msg = json.msg;
              sharedSuccessAlert(alertData);
            } 
            else {
              viewBankStatement();
              setSubmitButtonLoading(false);
              SetStatTableLoading(false);
              alertData.title = "Failed";
              alertData.msg = json.msg;
              sharedFailAlert(alertData);
            };
      });
    }

    ////#endregion
    


    ////#region  Columns
    const statementColumns = [
      {
        align: "center",
        title: "Date / Time",
        dataIndex: "sysCreateTime",
        width: "150px",
        render: function(text, record,row) {
          return (text);
        }
      },
      {
        align: "center",
        title: "To Bank Code",
        dataIndex: "toBankCode",
        width: "150px",
        //fixed:"left",
        render: function(text, record,row) {
          return (text);
        }
      },
      {
        align: "center",
        title: "To Bank Acc No.",
        dataIndex: "toBankAcc",
        width: "200px",
        render: function(text, record,row) {
          return (text);
        },
        ...getColumnSearchProps("toBankAcc")
      },
      {
        align: "center",
        title: "Amount",
        dataIndex: "amt",
        width: "150px",
        render: function(text, record,row) {
          return FormatPt(text);
        },
        ...getColumnSearchProps("amt")
      },
      {
        align: "center",
        title: "From Bank Acc No.",
        dataIndex: "fromAccNo",
        width: "200px",
        render: function(text,record) {
          return (text);
        },
        ...getColumnSearchProps("fromAccNo"),
      },
      {
        align: "center",
        title: "From Bank Acc Name",
        dataIndex: "fromAccName",
        width: "200px",
        render: function(text,record) {
          return (text);
        },
        ...getColumnSearchProps("fromAccName"),
      },
      {
        align: "center",
        title: <div>Status</div>,
        dataIndex: "actionStatus",
        width: "200px",
        filters : withdrawStatus,
        filterMultiple: false,
        onFilter: (value, record) => record.actionStatus?.toLowerCase().indexOf(value.toLowerCase()) === 0 ,
        render: (text, record) => {
          switch (record.actionStatus) {
            case "approved":
              return <Badge status="success" text="Approved" />;
  
            case "pending":
              return <Badge status="warning" text="Pending to Process" />;
  
            case "reject":
              return <Badge status="warning" text="Reject" />;
            
            case "conflict":
              return (
                        <>
                          <span>
                            <Button
                              style={{
                                backgroundColor: "green",
                                border: "none",
                                color: "#FFFFFF",
                                paddingLeft:"10px",
                                paddingRight:"10px",
                              }}
                              onClick={() => {
                                handleUpdateRecord(record);
                              }}
                              loading={submitButtonLoading}
                            >
                              Clear
                            </Button>
                          </span>

                          &nbsp;&nbsp;&nbsp;
                          
                          <span>
                            <Button
                              style={{
                                backgroundColor: "#F7B105",
                                border: "none",
                                color: "#FFFFFF",
                                paddingLeft:"10px",
                                paddingRight:"10px",
                              }}
                              onClick={() => {
                                handleUpdateStatus(record);
                              }}
                              loading={submitButtonLoading}
                            >
                              Re-sort
                            </Button>
                          </span>
                        </>
                      )
            case "duplicate":
              return <Badge status="warning" text="Duplicate" />;
  
            default:
              return <Badge status="error" text={record.actionStatus} />;
          }
        },
      },
      {
        // align: "center",
        title: <div>Remark</div>,
        dataIndex: "remark",
        width: "200px",
        // ellipsis: true,
        render: function(text,record) {
          return text;
        },
      },
    ]

    ////#endregion

    return (
      <div className="withdrawResortList">
        {
          witResortVw(role, username)?
          (<div style={style.authorized}>
            <PageHeader ghost={false}>
              <div style={style.pageHeaderContainer}>
                <div style={{float:"left"}}>
                  <DatePicker
                    key="1"
                    style={{...style.datePickerStyle, ...style.refreshBtn,...style.hide}}
                    format="YYYY-MM-DD HH:mm"
                    showTime={{
                      defaultValue: moment("00:00", "HH:mm"),
                      format: "HH:mm"
                    }}
                    onChange={trxDatePicker}
                    value={trxDateFrom}
                    ref={rptDateFrom}
                    allowClear={false}
                    showToday={false}
                    placeholder={"Select Date From"}
                    disabledDate={(current)=> {
                      return current > moment().endOf('day');
                    }}
                  />
                  <DatePicker
                    key="2"
                    style={{...style.datePickerStyle, ...style.refreshBtn,...style.hide}}
                    format="YYYY-MM-DD HH:mm"
                    showTime={{
                      defaultValue: moment("00:00", "HH:mm"),
                      format: "HH:mm"
                    }}
                    onChange={trxDatePicker2}
                    value={trxDateTo}
                    ref={rptDateTo}
                    allowClear={false}
                    showToday={false}
                    placeholder={"Select Date From"}
                    disabledDate={(current)=> {
                      let dateFr = trxDateFrom;
                      if (dateFr) {
                        return current < dateFr || current > moment().add(1, 'day').add(-1, 'minute');
                      }else {
                        return  current > moment().subtract(1, 'day');
                      }
                    }}
                    defaultPickerValue={()=>{
                        let dateFr = trxDateFrom;
                        if (dateFr) {
                            return dateFr;
                        }
                    }}
                  />
                  <Button 
                  style={{...style.refreshBtn,...style.buttonStyle, ...style.hide}}
                  type="primary"
                  onClick={()=>{
                      viewBankStatement();
                  }}
                  >
                      Reset
                  </Button>
                  <Button
                      style={{...style.refreshBtn,...style.buttonStyle,...style.hide}}
                    type="primary"
                    onClick={() => {
                      setSearchMobile(searchMobile);
  
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </PageHeader>
          </div>)
          :<></>
        }
        <div style={{...style.tabelContainer}} className="body">
        {
          witResortVw(role, username)?
            <>
              <Table
              style={{ overflow: "auto", marginLeft: 20 }}
              columns={statementColumns}
              dataSource={statementData}
              pagination={{
                defaultPageSize: 100,
                showSizeChanger: true,
                pageSizeOptions: settings.pageSizeOptionsLarge,
                style: style.paginationStyle,
                alignmentBottom: 'right',
                total: statementTotal,
                showTotal: total => `Total ${statementTotal}  Statement(s)`,
                // onChange:handlePaginateChange,
                // pagesize:statementPageSize,
              }}
              loading={statTableLoading}
              onChange={handleTableChange}
            />
            <Modal 
            visible={updateWithdrawVisible}
            okText={"Submit"}
            title= "Change Withdrwaw Status"
            maskClosable={false}
            footer={[
                <Button
                  type={"primary"}
                  onClick={()=> {
                    setUpdateWithdrawVisible(false);
                  }}
                >
                  Cancel
                </Button>
                ,
                updateWithdrawSubmit?
                  <Button 
                      type={"danger"} 
                      onClick={() => {
                        onUpdateWithdraw();
                      }}
                  >
                  Submit
                </Button>: <Spin tip="Processing..."></Spin>
              
            ]}
            >
              Re-sort this Transaction?
              <div>Amount     : {updateWithdrawAmt}</div>
              <div>Bank Code  : {updateWithdrawBankCode}</div>
              <div>Acc. No    : {updateWithdrawAccNo}</div>
              <div>Create Time: {updateWithdrawSysCreate}</div>
          </Modal> 
          </>
          : <div>
            <div style={{textAlign:"center",color:"red",fontSize:"150%"}}><h1 style={{color:"red"}}>Not Allowed to Access This Page</h1></div>
            </div>
        }
        </div>

      </div>
        
    );
  }


