import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  FileExcelOutlined,
} from "@ant-design/icons";
import { 
  reportFortuneWheel
 } from "../../../config/networkConfig";
import { Table } from "ant-table-extensions";
import {
  PageHeader,
  Button,
} from "antd";
import { isMobile } from "react-device-detect";
import "jspdf-autotable";
import { settings } from "../../../config/appConfig";
import { ReportFortuneWheelVw } from "../../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";


export default function ReportFortuneWheel() {

  const { userId , role, username, compCode } = useSelector((state) => state.login);
  const [allReport, setAllReport] = useState("");

  const [todayDate, setTodayDate] = useState("");

  const [tableLoading ,          setTableLoading] = useState(false);

  const [dailyPercent, setDailyPercent] = useState("");

  useEffect(() => {
    if (ReportFortuneWheelVw(role,username,compCode)){
      reportallReportList();
    }
  }, []);

  window.setTimeout( function() {
    window.location.reload();
  }, 1200000);

  const onClickExportBtn = ()=> {

    if (allReport.length === 0 ) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }

    document.getElementById("export-button-1").click();
  }

  const reportallReportList = () => {
    setTableLoading(true);
    var count = 1;

    reportFortuneWheel({data:null})
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
        const allReport = Object.keys(json.data).length
        ? json.data.map((data) => {
            setTableLoading(false);
            return {
              key                 : count++,
              DepDate             : data.DepDate,
              CntDep              : data.CntDep,
              NoBonusTier         : data.NoBonusTier,
              ResetTier           : data.ResetTier,
              LowTier             : data.LowTier,
              PenaltyTier         : data.PenaltyTier,
              HighTier            : data.HighTier,
              TotalDepAmt         : (data.TotalDepAmt).toFixed(2),
              MaxDep              : data.MaxDep,
              MaxBonus            : data.MaxBonus,
              DailyPercent        : data.DailyPercent,
              DailyBudget         : (data.DailyBudget).toFixed(2),
              BonusPercent        : (data.BonusPercent).toFixed(2),
              BonusGiveout        : (data.Bonus).toFixed(2),
              TotalBonus          : (data.Bonus).toFixed(2),
              TotalClaimBonusAmt  : (data.TotalClaimBonusAmt).toFixed(2),
              TotalClaimBonusCnt  : data.TotalClaimBonusCnt,
              TotalBonusCnt       : data.TotalBonusCnt,
              BonusClaimRate      : data.BonusClaimRate,
              LowBonus            : (data.LowBonus).toFixed(2),
              PenBonus            : (data.PenBonus).toFixed(2),
              HighBonus           : (data.HighBonus).toFixed(2),
              LowPercent          : ((data.LowBonus)/(data.Bonus)*100).toFixed(2),
              PenPercent          : ((data.PenBonus)/(data.Bonus)*100).toFixed(2),
              HighPercent         : ((data.HighBonus)/(data.Bonus)*100).toFixed(2)
            };
            })
            : [];

            setDailyPercent(allReport[0].DailyPercent)
            let summaryTotalClaimBonusAmt = 0, summaryTotalBonus = 0;
            let reportFortuneWheelSummary = [...allReport];

            reportFortuneWheelSummary.forEach(({ TotalClaimBonusAmt, TotalBonus
            }) => {
              summaryTotalClaimBonusAmt       += Number(TotalClaimBonusAmt);
              summaryTotalBonus               += Number(TotalBonus);
            });

            reportFortuneWheelSummary= [
              {
                key                 : "Summary",
                DepDate             : "",
                CntDep              : "",
                NoBonusTier         : "",
                ResetTier           : "",
                LowTier             : "",
                PenaltyTier         : "",
                HighTier            : "",
                TotalDepAmt         : "",
                MaxDep              : "",
                MaxBonus            : "",
                DailyBudget         : "",
                BonusPercent        : "",
                BonusGiveout        : "",
                TotalBonus          : (summaryTotalBonus).toFixed(2),
                TotalClaimBonusAmt  : (summaryTotalClaimBonusAmt).toFixed(2),
                TotalClaimBonusCnt  : "",
                TotalBonusCnt       : "",
                BonusClaimRate      : "",
                LowBonus            : "",
                PenBonus            : "",
                HighBonus           : "",
                LowPercent          : "",
                PenPercent          : "",
                HighPercent         : ""
              },
                ...reportFortuneWheelSummary,
            ];

            let todayDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
            setTodayDate(todayDate);
            setAllReport(reportFortuneWheelSummary);

          if (allReport.length == 0 ) {
            alertData.title = "Error";
            alertData.msg = "No data !";
            sharedErrorAlert(alertData);
            setTableLoading(false);
            return;
          }
        } 
        else {
          alertData.title = "Error";
          alertData.msg = "Connection not stable, please try again !";
          sharedErrorAlert(alertData);
        }        
      });

      
  };

  const columns = [
    {
      align: "center",
      title: "No",
      dataIndex: "key",
      width: 100,
      ellipsis: true,
      fixed:"left",
      render:function(record) {
        return record == "Summary" ? <p style={{marginTop: "10px"}}><b>{record}</b></p> :
               <p style={{marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "Date",
      dataIndex: "DepDate",
      width: 100,
      ellipsis: true,
      fixed:"left",
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "Deposit Count",
      dataIndex: "CntDep",
      width: 110,
      ellipsis: true,
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "Low Tier",
      dataIndex: "LowTier",
      width: 100,
      ellipsis: true,
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "Penalty Tier",
      dataIndex: "PenaltyTier",
      width: 100,
      ellipsis: true,
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "High Tier",
      dataIndex: "HighTier",
      width: 100,
      ellipsis: true,
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "Not Eligible (<100)",
      dataIndex: "NoBonusTier",
      width: 140,
      ellipsis: true,
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "Total Deposit THB",
      dataIndex: "TotalDepAmt",
      width: 130,
      ellipsis: true,
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "Daily Budget THB ("+dailyPercent+"%)",
      dataIndex: "DailyBudget",
      width: 170,
      ellipsis: true,
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "Bonus Giveout THB (%)",
      dataIndex: "BonusGiveout",
      width: 160,
      ellipsis: true,
      render:function(record, record2) {
        return  record2.key == "Summary" ? "" :
                <div style={{marginTop: "14px"}}>
                  <p><b>{record}</b>
                    <span style={{color:"blue", marginTop: "14px"}}> <b>({record2.BonusPercent}%)</b></span>
                  </p>
                </div>
      }
    },
    {
      align: "center",
      title: "(%)",
      dataIndex: "BonusPercent",
      width: 0,
      ellipsis: true,
      render:function(record) {
        return <p style={{color:"blue", marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "Claimed Bonus THB",
      dataIndex: "TotalClaimBonusAmt",
      width: 140,
      ellipsis: true,
      render:function(record, record2) {
        return record2.key == "Summary" ? <p style={{marginTop: "10px"}}><b>{record}</b></p> :
               <p style={{marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "Claimed Rate",
      dataIndex: "BonusClaimRate",
      width: 110,
      ellipsis: true,
      render:function(record, record2) {
        return record2.key == "Summary" ? "" :
               <p style={{marginTop: "10px"}}>{record}%</p>
      }
    },
    {
      align: "center",
      title: "Day Highest Bonus",
      dataIndex: "MaxBonus",
      width: 140,
      ellipsis: true,
      render:function(record) {
        return record > 500 ? <p style={{color:"red", marginTop: "10px"}}>{record}</p> : <p style={{marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "Low Bonus (%)",
      dataIndex: "LowBonus",
      width: 140,
      ellipsis: true,
      render:function(record, record2) {
        return  record2.key == "Summary" ? "" :
                <div style={{marginTop: "14px"}}>
                  <p><b>{record}</b>
                    <span style={{color:"blue", marginTop: "14px"}}> <b>({record2.LowPercent}%)</b></span>
                  </p>
                </div>
      }
    },
    {
      align: "center",
      title: "(%)",
      dataIndex: "LowPercent",
      width: 0,
      ellipsis: true,
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "Penalty Bonus (%)",
      dataIndex: "PenBonus",
      width: 140,
      ellipsis: true,
      render:function(record, record2) {
        return  record2.key == "Summary" ? "" :
                <div style={{marginTop: "14px"}}>
                  <p><b>{record}</b>
                    <span style={{color:"blue", marginTop: "14px"}}> <b>({record2.PenPercent}%)</b></span>
                  </p>
                </div>
      }
    },
    {
      align: "center",
      title: "(%)",
      dataIndex: "PenPercent",
      width: 0,
      ellipsis: true,
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "High Bonus (%)",
      dataIndex: "HighBonus",
      width: 140,
      ellipsis: true,
      render:function(record, record2) {
        return  record2.key == "Summary" ? "" :
                <div style={{marginTop: "14px"}}>
                  <p><b>{record}</b>
                    <span style={{color:"blue", marginTop: "14px"}}> <b>({record2.HighPercent}%)</b></span>
                  </p>
                </div>
      }
    },
    {
      align: "center",
      title: "(%)",
      dataIndex: "HighPercent",
      width: 0,
      ellipsis: true,
      render:function(record) {
        return <p style={{marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "Total Bonus THB",
      dataIndex: "TotalBonus",
      width: 130,
      ellipsis: true,
      render:function(record, record2) {
        return record2.key == "Summary" ? <p style={{marginTop: "10px"}}><b>{record}</b></p> :
               <p style={{marginTop: "10px"}}>{record}</p>
      }
    },
  ];

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    inlineBlock: {
      display: "inline-block",
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      // display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
      display: "inline",
    },
    resetBtn: {
      marginLeft: 10,
    },
    inline: {
      display: "inline",
    },
    hide: {
      marginLeft: 10000,
    },
    datePickerStyle: {
      width: 280,
    },
    datePickerSelectionStyle: {
      marginLeft: 10,
      width: 120,
    },
    refreshBtn: {
      marginLeft: 10,
    },
    mobilePageHearderContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    eachButtonContainer: {
      alignItems: "center",
      display: "flex",
      height: 50,
      marginLeft: 15,
      marginRight: 15,
      justifyContent: "center",
    },
    mobileSearchResetBtn: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      height: 50,
      alignItems: "center",
      paddingLeft: 60,
      paddingRight: 60,
    },
    btnContainer: {
      width: "100vw",
      padding: 10,
    },
    pdfStyle: {
      borderRadius: 10,
      width: 1200,
    },
    decStyle: {
      display: "flex",
      flexDirection: "row",
    },
    decLable: {
      width: 200,
    },
    decContainer: {
      paddingLeft: 75,
    },
    checkbox: {
      marginTop: -25,
      paddingBottom: 30,
      paddingLeft: 10,
    },
    webRow: {
      backgroundColor: "#FFFFFF",
      paddingTop: 50,
      paddingLeft: 30,
      paddingBottom: 50,
      fixed: "left",
    },
    mobileRow: {
      backgroundColor: "#FFFFFF",
      paddingLeft: 30,
    },
    cardStyle: {
      borderRadius: 10,
      height: 160,
      width: 200,
      border: "none",
      marginTop: -70,
      marginLeft: -30,
      marginRight: 40,
      marginBottom: -90,
      fixed: "left",
      display: "inline-block"
    },
    cardStyle2: {
      borderRadius: 0,
      height: 160,
      width: 1240,
      border: "none",
      marginTop: -70,
      marginLeft: -30,
      marginRight: 15,
      marginBottom: -90,
      fixed: "left"
    },
    cardStyle3: {
      borderRadius: 0,
      height: 160,
      width: 150,
      border: "none",
      marginTop: -70,
      marginLeft: -5,
      marginRight: 0,
      marginBottom: -90,
      fixed: "left"
    },
    statistic1: {
      width: 130,
    },
    statisticPercentage: {
      width: 100,
    },
    statistic2: {
      width: 150,
    },
    statistic3: {
      width: 150,
    },
    statistic4: {
      width: 150,
    },
    statistic5: {
      width: 150,
    },
    statistic6: {
      width: 150,
    },
    equal: {
      width: 25,
    },
    openBracket: {
      width: 15,
    },
    closeBracket: {
      width: 15,
    },
    plus: {
      width: 28,
    },
    minus1: {
      width: 20,
    },
    minus2: {
      width: 24,
    },
    
    headStyles: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 20,
      height: 20,
    },
    headStyles2: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 20,
      height: 20,
    },
    bodyStyle: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 60,
    },
    bodyStyle2: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 60,
    },
  };

  return (
    <div>
      {
        ReportFortuneWheelVw(role,username,compCode)?
        
        <div className="eachPageContent">
        {(
          <div>
            <PageHeader
              ghost={false}
            >
              <div style={styles.pageHeaderContainer}>
                <Button
                  style={styles.refreshBtn}
                  type="primary"
                  onClick={() => {
                    // reportList();
                    window.location.reload();
                  }}
                >
                  Refresh Page
                </Button>
                <Button 
                      type={"primary"} 
                      style={styles.refreshBtn} 
                      icon={<FileExcelOutlined />}
                      onClick={() => {
                          onClickExportBtn();
                      }}
                      
                  >
                    Export to Excel
                </Button>
              </div>
            </PageHeader>
            <div style={styles.tableContainer}>
              <Table
                loading={tableLoading} 
                columns={columns}
                dataSource={allReport}
                className="cs_dailyTrxnStat"
                scroll={{ x: 100, y: null }}
                exportable={true}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: isMobile ? ["5", "10", "20"] : settings.pageSizeOptions,
                  style: styles.paginationStyle,
                }}
                exportableProps={{
                  // feedFields,
                  fileName: "FortuneWheelByDateReport_"+todayDate,
                  btnProps: {
                    type: "primary",
                    icon: <FileExcelOutlined />,
                    id:"export-button-1",
                    children: <span>Export to Excel</span>,
                    style: {display:"none"}
                  },
                }}
              />
              <br></br>
              <p><b>REMARK :</b></p>
              <p>(1) <span style={{color:"red",}}>Daily Budget THB has adjusted and reset at 5am (GMT+7), instead of 12am daily. Thus, column 'Total Deposit THB' also adjusted start at 5am daily</span></p>
              <p>(2) <span style={{color:"red",}}>Penalty Tier reset daily at 5pm (GMT+7)</span></p>
              <p>(3) Fortune Wheel eligible for Deposit &#8250;= 100</p>
              <p>(4) Low Tier - Bonus up to 1%, 2%, 3%, 5%, or 7%</p>
              <p>(5) High Tier - Bonus up to 10%, 20%, 50%, or 100%</p>
              <p>(6) Penalty Tier - Bonus up to 0%, 1% or 2%</p>
              <p>(7) Bonus Allocated Budget is Daily Deposit of 5%</p>
              <div style={{...styles.inline, ...styles.hide}}>
              </div>
            </div>
          </div>
        )}
        </div>:
        <div style={{textAlign:"center",color:"red",fontSize:"150%"}}><h1 style={{color:"red"}}>Not Allowed to Access This Page</h1></div>
      }
    </div>
      
  );
}