import React, { useState, useEffect , useRef} from "react";
import {
    PageHeader,
    Button,
    Form,
    Input,
    message,
    Rate,
    DatePicker,
    Radio,
    Modal,
    Card,
    Checkbox
  } from "antd";
import { useSelector } from "react-redux";
import { Table } from "ant-table-extensions";
import { isMobile } from "react-device-detect";
import {
    EditOutlined,
    SearchOutlined,
    FileExcelOutlined
  } from "@ant-design/icons";
import {
    csMemberFeedbackList,
    csMemberFeedbackUpdate
  } from "../../config/networkConfig"
import moment from "moment";
import { settings } from "../../config/appConfig";
import md5 from "md5";
import { checkPropTypes } from "prop-types";
import { feedMgmtFeedEdit, feedMgmtFeedExport } from "../../library/permission";
import style from "../../resource/style/style";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";

export default function UserFeedbackManagement() {
    const {role} = useSelector((state) => state.login);
    const [form] = Form.useForm();;
    const modalForm = useRef();
    const [editingKey, setEditingKey] = useState("");
    const [loadings, setLoadings] = useState([]);
    const [trxLoading, setTrxLoading] = useState(false);
    const [feedData, setFeedData]     = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [cardTitle, setCardTitle] = useState("Feedback from");
    const [memberId, setMemberId]   = useState("");

    //Ratings
    const [_oneStar,SetOneStar] = useState(0);
    const [_twoStar,SetTwoStar] = useState(0);
    const [_threeStar,SetThreeStar] = useState(0);
    const [_fourStar,SetFourStar] = useState(0);
    const [_fiveStar,SetFiveStar] = useState(0);


    //Date Picker
    const [trxDateFrom, setTrxDateFrom] = useState("");
    const [trxDateTo, setTrxDateTo] = useState("");
    const reportDateFrom = useRef(null);
    const reportDateTo = useRef(null);

    //Status Filter
    const [status, setStatus] = useState("All");

    //Modal Dialog
    const [editVisible,setEditVisible] = useState(false);
    const [remarkENG, setRemarkENG]    = useState("");

    useEffect(() => {
      userFeedbackList();
    }, [status]);

    useEffect(()=> {
      setTrxDateFrom(trxDateFrom);
      setTrxDateTo(trxDateTo);
    },[trxDateFrom,trxDateTo]);

    ////#region Load Lists
    const userFeedbackList = async () => {
      let count = 0 ;

      let requestData = {
        datefrom : moment(trxDateFrom).format("YYYY-MM-DD"),
        dateto: moment(trxDateTo).format("YYYY-MM-DD"),
     }

     //console.log(`requestData >> ${JSON.stringify(requestData)}`);

     setTrxLoading(true);

      const result = await csMemberFeedbackList(requestData);
      let resp = await result.json();
      
      if (resp.status === "ok" ) {
    //if (resp.status ==="ok" && resp.msg !='No records found') {  
        if (Object.keys(resp.data).length > 0 ) {
          setTrxLoading(false);

          if (resp.data.summary && resp.data.summary.length) {
              let rating = resp.data.summary[0];
              SetOneStar(rating.oneStar);
              SetTwoStar(rating.twoStar);
              SetThreeStar(rating.threeStar);
              SetFourStar(rating.fourStar);
              SetFiveStar(rating.fiveStar);
          }

          const respData = Object.keys(resp.data.detail).length
          ? resp.data.detail.map((obj) => {
              setTableLoading(false);
              return {
                key: count++,
                userid: obj.userid,
                date: obj.completetime,
                mobile:obj.mobilenumber,
                rating: obj.rating,
                remark: obj.remark,
                remarkEN:obj.remarken,
                isRead: obj.isread,
                isAttn: obj.isattn,
              };
            })
          : [];

          setFeedData(respData);

          //console.log(`respData >> ${JSON.stringify(respData)}`);
          
          const unread = respData.filter((val) => {
            return val.isRead === "N";
          });
    
          const attn = respData.filter((val) => {
            return val.isAttn == "Y";
          });

          const sortData =
              status === "Unread"
              ? unread
              : status === "Attn"
                  ? attn
              : [...respData];

          setFeedData(sortData);




          if (trxDateFrom == "" || trxDateTo == "") {} else{
            setCardTitle(`Feedback from ${moment(trxDateFrom).format("DD-MM-YYYY")} to ${moment(trxDateTo).format("DD-MM-YYYY")}`);
          }
  
        } else {

          setTrxLoading(false);

        }

      }else {
        setTrxLoading(false);
      }

    }

    ////#endregion

    
    ////#region  Components

    // const fields: ITableExportFields = {
    //   firstName: "Name",
    //   fullName: {
    //     header: "Full Name",
    //     formatter: (_fieldValue, record) => {
    //       return record?.firstName + " " + record?.lastName;
    //     },
    //   },
    //   country: {
    //     header: "Your Country",
    //     formatter: fieldValue => {
    //       return "-->  " + fieldValue;
    //     },
    //   },
    // };

    const feedFields = {
        date   :"Completion Date",
        mobile :"mobile",
        rating: "rating",
        remark: "remark",
        remarkEN:"Remark (Translated)"
    }


    const CTable = (props) => {

        let fields = props.exportFields;

        return (
            <Table
            loading={trxLoading}
            columns ={props.columns}
            dataSource= {props.dataSource}
            scroll={{ x: isMobile ? "110%" : "100%", y: null }}
            exportable={props.exportable}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: settings.pageSizeOptions,
              style: style.paginationStyle,
              alignmentBottom: 'right',
            }}
            exportableProps={{
              fields,
              btnProps: {
                type: "primary",
                icon: <FileExcelOutlined />,
                id:"export-button-1",
                children: <span>Export to Excel</span>,
                style: {display:"none"}

              },
            }}
            onChange={
              (pagination, filters, sorter, extra) => {
                // console.log('DATASOURCE >>', extra.currentDataSource) //<--- can sure to get the right value
              } 
            } 
          />
        )
    }
    //#endregion

    //#region  Columns
    let ratingFilters = [
      {
        text: '5 Stars',
        value: 5,
      },
      {
        text: '4 Stars',
        value: 4,
      },
      {
        text: '3 Stars',
        value: 3,
      },
      {
        text: '2 Stars',
        value: 2,
      },
      {
        text: '1 Stars',
        value: 1,
      },
      {
        text: '0 Stars',
        value: 0,
      },
    ];

    let feedColumns = [
        {
          align: "center",
          title: "Date Time",
          dataIndex:"date",
          width: "200px",
        },
        {
          align: "center",
          title: "Mobile No.",
          dataIndex:"mobile",
          width: "200px",
        },
        {
          align: "center",
          title: "Rating",
          dataIndex:"rating",
          width: "200px",
          filters :ratingFilters,
          filterMultiple: false,
          onFilter: (value, record) => record.rating=== value  ,
          render: function(text) {
            return (<Rate value={text} disabled={true}/>)
          },
        },
        {
          align: "center",
          title: "Remark",
          dataIndex:"remark",
          width: "200px",
          render: function(text) {
            return (<div style={{textAlign:"left"}}>{text}</div>)
          } 
        },
        {
          align: "center",
          title: "Remark (Translated)",
          dataIndex:"remarkEN",
          width: "200px",
          render: (text,record) => {
              return (
                <div>
                <span style={{textAlign:"left",textOverflow: "ellipsis", width:"10px"}}>{text}</span>
                &nbsp;&nbsp;
                    {
                      feedMgmtFeedEdit(role)?
                      <Button type="primary" shape="circle" icon={<EditOutlined />} 
                        onClick={() => {
                          setMemberId(record.userid);
                          setRemarkENG(text);
                          setEditVisible(true);
                        }}
                      />:<></>
                    }

                </div>
              )
          }
        },
        {
          align: "center",
          title: "Status",
          dataIndex:"isRead",
          width: "200px",
          render: (text,record) => {
            let btnRead =   <Checkbox 
                                checked={record.isRead=='Y'? true: false} 
                                onClick={()=> {
                                      record.isRead=='Y' ?
                                      onUnreadFeedback(record.userid):
                                      onReadFeedback(record.userid)
                                }}
                            >
                              Read
                            </Checkbox>

            let btnAttn = <Checkbox 
                              checked={record.isAttn=='Y' ? true: false} 
                              onClick={()=> {
                                    record.isAttn=='Y'?
                                    onNoAttnFeedback(record.userid):
                                    onAttnFeedback(record.userid)
                              }}
                          >
                            Attn
                          </Checkbox>
              return (
                <div>
                   {btnRead}
                   &nbsp;&nbsp;
                   {btnAttn}
                </div>
              )
          }
        }
      ];
    //#endregion

    //#region Functions
    const onClickExportBtn = ()=> {
      document.getElementById("export-button-1").click();
    }
    const resetBtn = () => {
      setTrxDateFrom(null);
      setTrxDateTo(null);
      setFeedData([]);
    };

    const onUpdateRemark = async ()=> {
      
      if (!remarkENG){
        alertData.title = "Field Required";
        alertData.msg = "Please fill in Remark !";
        sharedErrorAlert(alertData);
        return;
      }

      let requestData = {
        userId : memberId, 
        remarkEn : remarkENG
      }

        const result =  await csMemberFeedbackUpdate(requestData);
        let resp = await result.json();
  
        if (resp.status === "ok") {
          alertData.title = "Success";
          alertData.msg = resp.msg;
          sharedSuccessAlert(alertData);
          setEditVisible(false);
          userFeedbackList();         
        }
        else{
          alertData.title = "Failed";
          alertData.msg = resp.msg;
          sharedFailAlert(alertData);
        }
    }

    const onAttnFeedback = async (userId) => {
      let requestData = {
        userId : userId, 
        isAttn : "Y",
      }

        const result =  await csMemberFeedbackUpdate(requestData);
        let resp = await result.json();
  
        if (resp.status === "ok") {
          userFeedbackList();
        }
        else{
          alertData.title = "Failed";
          alertData.msg = resp.msg;
          sharedFailAlert(alertData);
        }
    }

    const onNoAttnFeedback = async (userId) => {
      let requestData = {
        userId : userId, 
        isAttn : "N",
      }
  
        const result =  await csMemberFeedbackUpdate(requestData);
        let resp = await result.json();
  
        if (resp.status === "ok") {
          userFeedbackList();
        }
        else{
          alertData.title = "Failed";
          alertData.msg = resp.msg;
          sharedFailAlert(alertData);
        }
    }

    const onUnreadFeedback = async (userId) => {
      let requestData = {
        userId : userId, 
        isRead : "N",
      }

        const result =  await csMemberFeedbackUpdate(requestData);
        let resp = await result.json();
  
        if (resp.status === "ok") {
          userFeedbackList();
        }
        else{
          alertData.title = "Failed";
          alertData.msg = resp.msg;
          sharedFailAlert(alertData);
        }
    }

    const onReadFeedback = async (userId) => {
      let requestData = {
        userId : userId, 
        isRead : "Y",
      }
  
        const result =  await csMemberFeedbackUpdate(requestData);
        let resp = await result.json();
  
        if (resp.status === "ok") {
          userFeedbackList();
        }
        else{
          alertData.title = "Failed";
          alertData.msg = resp.msg;
          sharedFailAlert(alertData);
        }
    }

    const trxDatePicker = (date, dateString) => {
      setTrxDateFrom(date);
    };
  
    const trxDatePicker2 = (date, dateString) => {
      setTrxDateTo(date);
    };

    const searchBar = () => {
      if (!trxDateFrom) {
        reportDateFrom.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Date From !";
        sharedErrorAlert(alertData);
        return;
      }
  
      if (!trxDateTo) {
        reportDateTo.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Date To !";
        sharedErrorAlert(alertData);
        return;
      }

      if (trxDateTo < trxDateFrom || trxDateFrom > trxDateTo) {
        alertData.title = "Error";
        alertData.msg = "Please select a valid Date Range !";
        sharedErrorAlert(alertData);
        return;
      }
  
      setTrxLoading(true);
      userFeedbackList();
  
    }

    const onCancelEditFeedback =() => {
        setEditVisible(false);
    }

    //#endregion

    //#region Forms
    const onEditFeedbackForm = () => {
      return (
        <Form form={form} 
              ref={modalForm}
              layout="vertical" 
              name="editFeedback"
              initialValues={{ remember:false }}
        >
          <Form.Item
            id="remarkEN"
            label="Remark (Translated)"
          >
            <>
              <Input.TextArea
                  value={remarkENG}
                  onChange={(e) => {
                     setRemarkENG(e.target.value);
                  }}
                  maxLength={150}
                  rows={4}
                />
                <span>Maximum 150 characters</span>
            </>
          </Form.Item>
        </Form>
      );
    };
    ////#endregion

    return (
        <div>
            <Modal title= "Edit Remark" visible={editVisible} 
                onOk={onUpdateRemark}
                onCancel={() => {
                  onCancelEditFeedback();
                }}
                maskClosable={false}
                cancelButtonProps={{ type: "primary" }}
                okButtonProps={{ type: "danger", loading: loadings[0], title: "Save" }}
                okText="Save"
                >
                {onEditFeedbackForm()}
          </Modal>
          <PageHeader ghost={false}>
            <div style={style.pageHeaderContainer}>
              <div 
                style={{
                  justifyContent: "left",
                  textAlign: "center",
                  display: "flex"
                }}
                >
                <Button
                  style={style.refreshBtn}
                  type="primary"
                  onClick={() => {
                    userFeedbackList();
                  }}
                >
                  Refresh Page
                </Button>
                <DatePicker
                  key="1"
                  style={{...style.datePickerStyle, ...style.refreshBtn}}
                  format="YYYY-MM-DD"
                  onChange={trxDatePicker}
                  value={trxDateFrom}
                  ref={reportDateFrom}
                  refs
                  placeholder={"Select Date From"}
                  disabledDate={(current)=> {
                    return current > moment().subtract(1, 'day');
                  }}
                />
              <DatePicker
                key="2"
                style={{...style.datePickerStyle, ...style.refreshBtn}}
                format="YYYY-MM-DD"
                onChange={trxDatePicker2}
                value={trxDateTo}
                ref={reportDateTo}
                placeholder={"Select Date To"}
                disabledDate={(current)=> {
                  let dateFrom = trxDateFrom;
                  if (dateFrom) {
                    return current < dateFrom || current > moment().subtract(1, 'day');
                  }else {
                    return  current > moment().subtract(1, 'day');
                  }
                }}
              />
              <Button
                type={"primary"}
                style={style.searchResetStyle}
                icon={<SearchOutlined
                onClick={searchBar}
                />}
              />
              <Button style={{...style.resetBtn,...style.refreshBtn}} onClick={resetBtn}>
                Reset
              </Button>
              {
                feedMgmtFeedExport(role)?
                <Button 
                    type={"primary"} 
                    style={style.refreshBtn} 
                    icon={<FileExcelOutlined />}
                    onClick={() => {
                        onClickExportBtn();
                    }}
                    
                >
                  Export to Excel
                </Button>:<></>
              }

              </div>
              <Radio.Group
                  buttonStyle={"solid"}
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <Radio.Button value="All">All</Radio.Button>
                  <Radio.Button value="Unread">Unread</Radio.Button>
                  <Radio.Button value="Attn">Attention</Radio.Button>
                </Radio.Group>
            </div>
            <div>
              <div style={style.cardContainer}>
                <Card
                  title={cardTitle}
                  style={style.cardStyles}
                  headStyle={{...style.cardHeadStyles,fontSize:"12px"}}
                >

                  <p><Rate value={5} disabled={true}/><span style={style.ratingStyle}>{_fiveStar}</span></p>
                  <p><Rate value={4} disabled={true}/><span style={style.ratingStyle}>{_fourStar}</span></p>
                  <p><Rate value={3} disabled={true}/><span style={style.ratingStyle}>{_threeStar}</span></p>
                  <p><Rate value={2} disabled={true}/><span style={style.ratingStyle}>{_twoStar}</span></p>
                  <p><Rate value={1} disabled={true}/><span style={style.ratingStyle}>{_oneStar}</span></p>
                </Card>
              </div>
            </div>
          </PageHeader>
          <div style={style.tabelContainer}>
              <Table
                loading={trxLoading}
                columns ={feedColumns}
                dataSource= {feedData}
                scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                exportable={true}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: settings.pageSizeOptions,
                  style: style.paginationStyle,
                  alignmentBottom: 'right',
                }}
                exportableProps={{
                  feedFields,
                  btnProps: {
                    type: "primary",
                    icon: <FileExcelOutlined />,
                    id:"export-button-1",
                    children: <span>Export to Excel</span>,
                    style: {display:"none"}
                  },
                }}
                onChange={
                  (pagination, filters, sorter, extra) => {
                    // console.log('DATASOURCE >>', extra.currentDataSource) //<--- can sure to get the right value
                  } 
                } 
              />
               {/* <CTable columns={feedColumns} dataSource={feedData} loading={trxLoading} exportable={true} exportFields={feedFields}/> */}
          </div>
        </div>
    );
}