import React, {useState, useEffect} from 'react'
import ReactDOM from 'react-dom'
import {
  csMonitorWithdraw
} from '../../../../../config/networkConfig';
import {
  Table,
  message
} from "antd";
import style from '../../../../../resource/style/style';
import { settings } from '../../../../../config/appConfig';

const MonitorWithdraw = () => {
  const init = new Date()
  const [date, setDate] = useState(init)

  //Master Data
  const [statementData,         setStatementData] = useState("");
  const [statementPageSize, setStatementPageSize]  = useState(100);
  const [statementPageNum,  setStatementPageNum]  = useState(1);
  const [statementTotal,       setStatementTotal]  = useState(10);
  const [statTableLoading,   SetStatTableLoading] = useState(false);
  
  let statColumns =  [
    {
      align: "center",
      title: "",
      dataIndex:"Today_Pending",
      width: "20%",
      render:(text , record)=>{
        return (<div className='monitorHeader'>Bank Withdraw Queue -
                   <span className={record.Today_Pending > 5 ? "monitorAlert":"monitorNormal"}>{record.Today_Pending}</span>
               </div>)
      }
    },    
  ]

  const tick = async () => {
    setDate(new Date())
    
    SetStatTableLoading(true);

    let countDL = 0;
    const responseDL = await csMonitorWithdraw({});
      let resultDL = await responseDL.json();
      if (resultDL.status === "ok") {
        const dlData = Object.keys(resultDL.data).length
        ? resultDL.data.map((obj) => {
            SetStatTableLoading(false);
            return {
                key                : ++countDL,
                Today_Pending      : obj.todayPending,
                // UnclaimAmt         : obj.unclaimAmt,
                // LastHour_Pending   : obj.lastHourPending,
                // Last2Hour_Pending  : obj.last2HourPending
            };
          })
        : [];

        setStatementData(dlData);

        SetStatTableLoading(false);
      }else {
        message.error(resultDL.msg)
      }

      SetStatTableLoading(false);
  }

  const EmptyData = () =>{
    return {
        emptyText : <p className='monitorHeader'>Bank Withdraw Queue - <span className='monitorNormal'>0</span></p>
    }
  }

  useEffect(() => {
    const timerID = setTimeout(() => tick(), settings.monitorRefreshTime)
    return () => {
      clearTimeout(timerID)
    }
  }, [date])

  useEffect(()=> {
    tick();
  },[])

return   (<div className="cs-report-monitor">
          <Table
            columns={statColumns}
            dataSource={statementData}
            loading={statTableLoading}
            pagination={false}
            className="monitor-no-header"
            locale={EmptyData()}
          />
          </div>)
}

export {
  MonitorWithdraw
}