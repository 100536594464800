import React, { useEffect, useState ,useRef} from "react";
import { useSelector } from "react-redux";
import { reportOperation } from "../../../config/networkConfig";
import {
  message,
  Table,
  PageHeader,
  DatePicker,
  Button,
  Breadcrumb,
  Statistic,
  Row,
  Card,
} from "antd";
import { isMobile } from "react-device-detect";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import {settings} from "../../../config/appConfig";
import { FormatPt, FormatData} from "../../../library/numbers";
import moment from "moment";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";

export default function OperationReport() {
  const { userId, compCode } = useSelector((state) => state.login);
  const [tableLoading, setTableLoading] = useState(false);
  const [report, setReport] = useState("");
  const [filter, setFilter] = useState("");
  const [reportDetail,setReportDetail] = useState("");
  const [filterDetail, setFilterDetail] = useState("");
  const [date, setDate] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [share, setShare] = useState(0);
  const [totalProfitSharing, setTotalProfitSharing] = useState(0);
  const reportDateFrom = useRef(null);
  const reportDateTo = useRef(null);

  useEffect(() => {
    //reportList();
    //reportShareDetailList();
    // eslint-disable-next-line
  }, []);

  const reportList = () => {
    setTableLoading(true);
    var count = 1;
    
    reportOperation({ userId: userId , datefrom: date.format("YYYY-MM-DD"), dateto: dateTo.format("YYYY-MM-DD"), compCode: compCode},)
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
          const report = Object.keys(json.data).length
            ? json.data.map((data) => {
              setTableLoading(false);
                return {
                  key: count++,
                  dateTime             : data.TrxnDate,
                  totalUser            : data.TotalUser,
                  activeUser           : data.ActiveUser,
                  registerUser         : data.RegisterUser,
                  inviteUser           : data.InviteUser,
                  depositCount         : data.DepositCount,
                  depositCountUnique   : data.DepositCountUnique,
                  depositAmount        : data.DepositAmount,
                  depositAmountMax     : String(data.DepositAmountMax),
                  withdrawCount        : data.WithdrawCount,
                  withdrawCountUnique  : data.WithdrawCountUnique,
                  withdrawAmount       : data.WithdrawAmount,
                  withdrawAmountMax    : String(data.WithdrawAmountMax),
                  promotionCost        : data.PromotionCost,
                  profitSharing        : data.ProfitSharing,
                  // commToUser           : data.CommToUser,
                  // commToBank           : data.CommToBank,
                  // commToWallet         : data.CommToWallet,
                  // playerTurnover       : data.PlayerTurnover,
                  // playerWinLoss        : data.PlayerWinLoss,
                };
              })
            : [];

            let summaryTotalUser = 0, summaryActiveUser  = 0, summaryRegisterUser = 0;
            let summaryInviteUser = 0, summaryDepositCount = 0, summaryDepositCountUnique = 0, summaryDepositAmount = 0;
            let summaryWithdrawCount = 0, summaryWithdrawCountUnique = 0, summaryWithdrawAmount = 0, summaryPromotionCost = 0;
            let summaryProfitSharing = 0;
            let reportOperationSummary = [...report];

            reportOperationSummary.forEach(({ totalUser, activeUser, registerUser, inviteUser, depositCount, depositCountUnique,
                                           depositAmount, withdrawCount, withdrawCountUnique, withdrawAmount, promotionCost, profitSharing
            }) => {
              summaryTotalUser            += Number(totalUser);
              summaryActiveUser           += Number(activeUser);
              summaryRegisterUser         += Number(registerUser);
              summaryInviteUser           += Number(inviteUser);
              summaryDepositCount         += Number(depositCount);
              summaryDepositCountUnique   += Number(depositCountUnique);
              summaryDepositAmount        += Number(depositAmount.toFixed(2));
              summaryWithdrawCount        += Number(withdrawCount);
              summaryWithdrawCountUnique  += Number(withdrawCountUnique);
              summaryWithdrawAmount       += Number(withdrawAmount.toFixed(2));
              summaryPromotionCost        += Number(promotionCost.toFixed(2));
              summaryProfitSharing        += Number(profitSharing);
            });

            reportOperationSummary= [
              {
                  key: "Summary",
                  dateTime                : "Summary",
                  totalUser               : summaryTotalUser,
                  activeUser              : summaryActiveUser,
                  registerUser            : summaryRegisterUser,
                  inviteUser              : summaryInviteUser,
                  depositCount            : summaryDepositCount,
                  depositCountUnique      : summaryDepositCountUnique,
                  depositAmount           : summaryDepositAmount.toFixed(2),
                  depositAmountMax        : "N/A",
                  withdrawCount           : summaryWithdrawCount,
                  withdrawCountUnique     : summaryWithdrawCountUnique,
                  withdrawAmount          : summaryWithdrawAmount.toFixed(2),
                  withdrawAmountMax       : "N/A",
                  promotionCost           : summaryPromotionCost,
                  profitSharing           : summaryProfitSharing.toFixed(2),
                },
                ...reportOperationSummary,
              ];

              setTotalProfitSharing(Number(reportOperationSummary[0].profitSharing))
              setReport(reportOperationSummary);
              setTableLoading(false);

          // sort by date descending
          // var newReport = [...report];
          // const filter = newReport.sort((a,b) => b.dateTime > a.dateTime ? 1 : -1);
          // setReport(filter);

        }
        else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
          setTableLoading(false);
        }
      });
  };

  const columns = [
    {
      align: "center",
      title: "Date / Time",
      dataIndex: "dateTime",
      width:150,
      ellipsis: true,
      fixed : "left",
    },
    {
      align: "center",
      title: "Total Users",
      dataIndex: "totalUser",
      width: 130,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Active Users",
      dataIndex: "activeUser",
      width: 130,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Registered Users",
      dataIndex: "registerUser",
      width: 150,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Invited Users",
      dataIndex: "inviteUser",
      width: 130,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Total Deposits",
      dataIndex: "depositCount",
      width: 140,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Unique Deposits #",
      dataIndex: "depositCountUnique",
      width: 160,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Deposit Amount",
      dataIndex: "depositAmount",
      width: 150,
      ellipsis: true,
      render:function(text,record,index) {
        return FormatPt(text);
      }
    },
    {
      align: "center",
      title: "Highest Deposit Amt.",
      dataIndex: "depositAmountMax",
      width: 180,
      ellipsis: true,
      render:function(record) {
        return record
      },
    },
    {
      align: "center",
      title: "Total Withdrawals",
      dataIndex: "withdrawCount",
      width: 150,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Unique Withdrawals #",
      dataIndex: "withdrawCountUnique",
      width: 180,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Total Withdraw Amount",
      dataIndex: "withdrawAmount",
      width: 190,
      ellipsis: true,
      render:function(text,record,index) {
        return FormatPt(text);
      }
    },
    {
      align: "center",
      title: "Highest Withdraw Amt.",
      dataIndex: "withdrawAmountMax",
      width: 200,
      ellipsis: true,
      render:function(record) {
        return record
      }
    },
    {
      align: "center",
      title: "Promotion Cost",
      dataIndex: "promotionCost",
      width: 140,
      ellipsis: true,
      render:function(text,record,index) {
        return FormatPt(text);
      }
    },
    // compCode == 'TKH' || compCode == 'TK4' ?
    // {
    //   align: "center",
    //   title: "Profit Sharing",
    //   dataIndex: "profitSharing",
    //   width: 140,
    //   ellipsis: true,
    //   render:function(text,record,index) {
    //     return FormatPt(text);
    //   }
    // }
    // : {}
  ];


  const mobileColumns = [
    {
      align: "center",
      title: "Data / Time",
      dataIndex: "dateTime",
      width: "50%",
      ellipsis: true,
    },
    {
      title: "Game Type",
      dataIndex: "category",
      width: "50%",
      ellipsis: true,
    },
  ];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    if (report.length == 0 ) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = `Operations Report (${date.format('YYYY-MM-DD')} to ${dateTo.format('YYYY-MM-DD')})\n\n\n`;
    const titleCompany =`Company`;
    const titleDetail = `My Summary`;

    console.log(`OPereation Balance >> ${JSON.stringify(columns)}`);

    const headers = [
      [
        columns[0].title,
        columns[1].title,
        columns[2].title,
        columns[3].title,
        columns[4].title,
        columns[5].title,
        columns[6].title,
        columns[7].title,
        columns[8].title,
        columns[9].title,
        columns[10].title,
        columns[11].title,
        columns[12].title,
        columns[13].title,
        columns[14].title,
      ],
    ];   

    var filteredReport = [...report];
    const data = filteredReport.map((data) => {
        return [
          data[columns[0].dataIndex],    
          data[columns[1].dataIndex],
          data[columns[2].dataIndex],
          data[columns[3].dataIndex],               
          data[columns[4].dataIndex],
          data[columns[5].dataIndex],
          data[columns[6].dataIndex],
          FormatPt(data[columns[7].dataIndex]),
          FormatPt(data[columns[8].dataIndex]),
          data[columns[9].dataIndex],
          data[columns[10].dataIndex],
          FormatPt(data[columns[11].dataIndex]),
          FormatPt(data[columns[12].dataIndex]),
          FormatPt(data[columns[13].dataIndex]),
          FormatPt(data[columns[14].dataIndex]),
        ];
    });   

    let content = {
      startY: 80,
      head: headers,
      body: data,
      headStyles: { fillColor: "#1DA57A" },
    };


    doc.text(title, marginLeft, 40);
    doc.autoTable(content);    
    doc.save("Operation Report.pdf");
  };

  const datePicker = (date, dateString) => {
    setDate(date);
  };

  const datePicker2 = (date, dateString) => {
    setDateTo(date);
  };

  const searchBar = () => {
   
    if (!date) {
      reportDateFrom.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date From !";
      sharedErrorAlert(alertData);
      return;
    }

    if (!dateTo) {
      reportDateTo.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date To !";
      sharedErrorAlert(alertData);
      return;
    }
    
    reportList();
  };

  const resetBtn = () => {
    setDate(null);
    setDateTo(null);
    setTotalProfitSharing(0);
    setReport([]);
  };

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      //   path: "first",
      breadcrumbName: "Report",
    },
    {
      path: "operationsReport",
      breadcrumbName:"Operations Report",
    },
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 2;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHearderContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    searchBtn: {
      marginLeft: 30,
    },
    resetBtn: {
      marginLeft: 10,
    },
    datePickerStyle: {
      width: 300,
      borderRadius: 10,
    },
    mobilePageHearderContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    eachButtonContainer: {
      alignItems: "center",
      display: "flex",
      height: 50,
      marginLeft: 15,
      marginRight: 15,
      justifyContent: "center",
    },
    mobileSearchResetBtn: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      height: 50,
      alignItems: "center",
      paddingLeft: 60,
      paddingRight: 60,
    },
    btnContainer: {
      width: "100vw",
      padding: 10,
    },
    pdfStyle: {
      borderRadius: 10,
      width: 1200,
    },
    searchResetStyle: {
      borderRadius: 10,
      marginLeft: 10,
    },
    decStyle: {
      display: "flex",
      flexDirection: "row",
    },
    decLable: {
      width: 200,
    },
    decContainer: {
      paddingLeft: 75,
    },
    webRow: {
      backgroundColor: "#FFFFFF",
      paddingTop: 50,
      paddingLeft: 30,
      paddingBottom: 50,
    },
    mobileRow: {
      backgroundColor: "#FFFFFF",
      paddingLeft: 30,
    },
    cardStyle: {
      borderRadius: 20,
      height: 130,
      width: 280,
      border: "none",
    },
    headStyles: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 25,
      height: 30,
    },
    bodyStyle: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 90,
    },
  };

  return (
    <div className="eachPageContent">
      {isMobile ? (
        <div>
          <PageHeader ghost={false}>
            <div>
              <Breadcrumb
                separator=">"
                itemRender={itemRender}
                routes={routes}
              />
              <div style={styles.btnContainer}>
                <div style={styles.eachButtonContainer}>
                  <Button
                    type={"primary"}
                    onClick={exportPDF}
                    style={styles.pdfStyle}
                  >
                    Download PDF
                  </Button>
                </div>
                <div style={styles.eachButtonContainer}>
                  <DatePicker
                    style={styles.datePickerStyle}
                    onChange={datePicker}
                    value={date}
                    ref={reportDateFrom}
                    placeholder="Select Date From"
                    disabledDate={(current)=> {
                      return current > moment().subtract(1, 'day');
                    }}
                  />
                  <DatePicker
                    style={styles.datePickerStyle}
                    onChange={datePicker2}
                    value={dateTo}
                    ref={reportDateTo}
                    placeholder="Select Date To"
                    disabledDate={(current)=> {
                      let dateFrom = date;
                      if (dateFrom) {
                        return current < dateFrom || current > moment().subtract(1, 'day');
                      }else {
                        return  current > moment().subtract(1, 'day');
                      }
                    }}
                  />
                  <Button
                    type={"primary"}
                    onClick={searchBar}
                    style={styles.searchResetStyle}
                    icon={<SearchOutlined />}
                  />

                  <Button
                    onClick={resetBtn}
                    style={styles.searchResetStyle}
                    icon={<UndoOutlined />}
                  />
                </div>
              </div>
              <Row style={styles.mobileRow}>
                <Card
                  title="Total Share"
                  style={styles.cardStyle}
                  headStyle={styles.headStyles}
                  bodyStyle={styles.bodyStyle}
                >
                  <Statistic
                    // precision={settings.decimalScale}
                    value={FormatData(share)}
                    valueStyle={{ fontSize: 40 }}
                  />
                </Card>
              </Row>
              <div style={styles.tableContainer}>
                <Table
                  columns={mobileColumns}
                  dataSource={report}
                  expandable={{
                    expandedRowRender: (record) => (
                      <p style={{ margin: 0 }}>{record.description}</p>
                    ),
                    rowExpandable: (record) => record.name !== "Not Expandable",
                  }}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20"],
                    style: styles.paginationStyle,
                  }}
                />
              </div>
            </div>
          </PageHeader>
        </div>
      ) : (
        <div>
          <PageHeader
            ghost={false}
            title="Operations Report"
            extra={[
              <DatePicker
                key="1"
                style={styles.datePickerStyle}
                onChange={datePicker}
                value={date}
                ref={reportDateFrom}
                placeholder="Select Date From"
                disabledDate={(current)=> {
                  return current > moment().subtract(1, 'day');
                  //return current > moment().endOf('day');
                }}
              />,
              <DatePicker
              style={styles.datePickerStyle}
              onChange={datePicker2}
              value={dateTo}
              ref={reportDateTo}
              placeholder="Select Date To"
              disabledDate={(current)=> {
                let dateFrom = date;
                if (dateFrom) {
                  return current < dateFrom || current > moment().subtract(1, 'day');
                }else {
                  return  current > moment().subtract(1, 'day');
                }
              }}
              />,
              <Button
                key="2"
                type={"primary"}
                style={styles.searchBtn}
                onClick={searchBar}
              >
                Search
              </Button>,
              <Button key="3" style={styles.resetBtn} onClick={resetBtn}>
                Reset
              </Button>,
              <Button key="4" type={"primary"} onClick={exportPDF}>
                Download PDF
              </Button>,
            ]}
          />
          {/* {compCode == 'TKH' || compCode == 'TK4' ?
          <Card
            title="Profit Sharing"
            style={styles.cardStyle}
            headStyle={styles.headStyles}
            bodyStyle={styles.bodyStyle}
          >
            <Statistic
              // precision={settings.decimalScale}
              value={(totalProfitSharing).toFixed(2)}
              valueStyle={{ fontSize: 40 }}
            />
          </Card>
          : ""} */}
          <div style={styles.tableContainer}>
            <Table
              loading={tableLoading} 
              columns={columns}
              dataSource={report}
              //dataSource={filter}
              scroll={{ x: 100, y: null }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: settings.pageSizeOptions,
                style: styles.paginationStyle,
                alignmentBottom: 'right'
              }}
            />
          </div>
            <p><b>REMARK :</b></p>
            <p><b><span style={{color:"blue"}}>[1]</span></b> Winloss = Total Deposits Amount - Total Withdrawals Amount</p>
            <p><b><span style={{color:"blue"}}>[2]</span></b> Actual <b>Total Users</b> record start from 2023-05-05</p>
            <p><b><span style={{color:"blue"}}>[3]</span></b> Actual <b>Active Users</b> record start from 2023-05-09</p>
        </div>
      )}
    </div>
  );
}
