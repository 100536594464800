import React, { useState, useEffect } from "react";
import {
  Table,
  Form,
  Button,
  PageHeader,
  Modal,
  Input,
  InputNumber,
  Spin,
  Select,
  Radio,
  Divider,
  Tooltip,
  Badge,
  
} from "antd";
import { QuestionCircleOutlined, SearchOutlined, InfoCircleFilled } from "@ant-design/icons";
import { 
  csWithdrawList,
  csWithdrawCheck,
  csWithdrawManualApprove,
  csWithdrawRejectV3,
  getReqToken,
} from "../../config/networkConfig";
import Highlighter from "react-highlight-words";
import { isMobile } from "react-device-detect";
import { settings } from "../../config/appConfig";
import { PROCESS_TYPE } from "../../common/constants/processType";
import { FormatPt , ShowMobileNumber} from "../../library/numbers";
import style from "../../resource/style/style";
import { useSelector } from "react-redux";
import { witMgmtManCrWit , witMgmtManApprRej } from "../../library/permission";
import md5 from "md5";
import moment from "moment";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, sharedConfirmationAlert, } from "../../common/constants/sharedMsgBox";

export default function ManualWithdrawV3() {
const { TextArea } = Input;
const { confirm } = Modal;

const { userId, username, companyId, role} = useSelector((state) => state.login);

const [status, setStatus] = useState("Pending");
const [searchText, setSearchText] = useState("");

const [tableLoading, setTableLoading] = useState(false);
const [originData, setOriginData] = useState([]);
const [data, setData] = useState([]);

const [formWithdrawDetail] = Form.useForm();
const [viewWithdrawVisible, setViewWithdrawVisible] = useState(false);
const [withdrawTrxnID, setWithdrawTrxnID] = useState("");
const [nickname, setNickname] = useState("");
const [userBal, setUserBal] = useState("");
const [withdrawMemberID, setWithdrawMemberID] = useState("");
const [withdrawMemberBankName, setWithdrawMemberBankName] = useState("");
const [withdrawMemberBankAcc, setWithdrawMemberBankAcc] = useState("");
const [withdrawMemberBankAccName, setWithdrawMemberBankAccName] = useState("");
const [withdrawAmt, setWithdrawAmt] = useState(0);
const [formWithdrawDetailLoading, setFormWithdrawDetailLoading] = useState(false);

const [formRejectWithdraw] = Form.useForm();
const [rejectReason, setRejectReason] = useState("");
const [rejectWithdrawVisible, setRejectWithdrawVisible] = useState(false);
const [rejectButtonLoading, setRejectButtonLoading] = useState(false);

useEffect(() => {
  ManualWithdrawList();
}, [status]);

const ManualWithdrawList = () => {
  var count = 0;
  csWithdrawList({ userId: userId, processtype: 'A'})
    .then((response) => response.json())
    .then((json) => {
      if (json.status === "ok"  ) {
    
        const newData = Object.keys(json.data).length
          ? json.data.map((x) => {
              setTableLoading(false);
              return {
                key: count++,
                trxnId: x.trxnId,
                userId: x.userId,
                nickname: x.nickname,
                mobile  : x.mobile,
                newNickname: ShowMobileNumber(role, x.nickname, x.mobile),
                amount: x.amount * -1,
                mbbank: x.mbBank,
                date: x.dateTime,
                bankNameOut: x.bank,
                bankAcctOut: x.bankAcc,
                mbbankAcct: x.mbBankAcc,
                processBy: x.processBy,
                puppetRemark : x.puppetRemark,
                remark :x.remark,
                // new details
                memberBankCode : x.mbBank,
                memberBankName : x.mbBankName,
                memberBankAccName : x.mbBankAccName,
                memberBankAcc    : x.mbBankAcc,
                // new details
                checkStatus:
                x.status === "0"
                  ? "Pending"
                  : x.status === "1"
                  ? "Approved"
                  : x.status === "3"
                  ? "Completed"
                  : x.status === "9"
                  ? "Rejected"
                  : "-",
              };
            })
          : [];
        setOriginData(newData);

        if (Object.keys(json.data).length ==0) {
            setTableLoading(false);
        }

        const pending = newData.filter((val) => {
          return val.checkStatus === "Pending";
        });
        const approve = newData.filter((val) => {
          return val.checkStatus === "Approved";
        });
        const reject = newData.filter((val) => {
          return val.checkStatus === "Rejected";
        });

        const sortData =
          status === "Pending"
            ? pending
            : status === "Approved"
            ? approve
            : status === "Rejected"
            ? reject
            : [...newData];

        setData(sortData);

        setViewWithdrawVisible(false);
      } 
      else {
        setTableLoading(false);
        alertData.title = "Failed";
        alertData.msg = json.msg;
        sharedFailAlert(alertData);
      }
    })
    .catch((error) => {
      console.log("error: ", error);
      alertData.title = "Error";
      alertData.msg =  "An unexpected error occurred. Please contact your system administrator.";
      sharedErrorAlert(alertData);
    });
}

const showApproveModal = (record) => {
  setTableLoading(true);

  csWithdrawCheck({ userId: record.userId, witTrxnId: record.trxnId })
    .then((response) => response.json())
    .then((json) => {
        setUserBal(json.data.curBal)
    })
    .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = `An unexpected error occurred. Please contact your system administrator.`;
        sharedErrorAlert(alertData);
    });

  setWithdrawTrxnID(record.trxnId)
  setWithdrawAmt(record.amount);
  setNickname(record.mobile);
  setWithdrawMemberID(record.userId);
  setWithdrawMemberBankName(record.memberBankName);
  setWithdrawMemberBankAcc(record.memberBankAcc);
  setWithdrawMemberBankAccName(record.memberBankAccName);

  setViewWithdrawVisible(true);
}

const handleApproveManualWithdraw = async () => {
  confirm({
    className: "confirmationModalStyle",
    title: 'Confirmation',
    centered: true,
    icon: <QuestionCircleOutlined />,
    content: 'Are you sure you want to proceed?',
    onOk() {
      console.log('OK');
      setFormWithdrawDetailLoading(true);
      onSubmitApproveWithdraw();
    },
    onCancel() {
    },
  });
}

const onSubmitApproveWithdraw = async () => {
  let requestData = {
    trxnId : withdrawTrxnID,
    userid : userId,
    username : username,
    bankCode : withdrawMemberBankName,
    bankAcc : withdrawMemberBankAcc,
    bankAccName : withdrawMemberBankAccName,
    reqToken : md5(getReqToken('withdrawManualApprove')),
  };
  console.log(`requestDatas`, requestData)
  csWithdrawManualApprove(requestData)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === "ok") {
        onCancelApproveWithdraw();
        ManualWithdrawList();
        setFormWithdrawDetailLoading(false);
        alertData.title = "Success";
        alertData.msg = json.msg;
        sharedSuccessAlert(alertData);
      }
      else {
        setFormWithdrawDetailLoading(false);
        alertData.title = "Error";
        alertData.msg = json.msg;
        sharedErrorAlert(alertData);
      }
    })
    .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
    });
}

const onCancelApproveWithdraw = (e) => {
  formWithdrawDetail.resetFields();
  setNickname("");
  setUserBal("");
  setWithdrawAmt(0);
  setWithdrawMemberBankName("");
  setWithdrawMemberBankAcc("");
  setTableLoading(false);
  setViewWithdrawVisible(false);
};

const viewWithdrawDetail = () => {
  return (
    <Form {...layout} form={formWithdrawDetail}>
      <Form.Item name={["member", "mbusername"]} label="Member Nickname">
        <div>{nickname}</div>
      </Form.Item>
      <Form.Item name={["member", "curlDepAmt"]} label="Current Balance">
        <div>{userBal}</div>
      </Form.Item>
      <Form.Item name={["member", "amount"]} label="Withdraw Amount">
        <div>{withdrawAmt}</div>
      </Form.Item>
      <Divider />
      <Form.Item name={["member", "mbbank"]} label="Bank">
        <div>{withdrawMemberBankName}</div>
      </Form.Item>
      <Form.Item name={["member", "mbbankAcct"]} label="Bank Account">
          <div>{withdrawMemberBankAcc}</div>
      </Form.Item>
      <Divider />
      <Form.Item style={styles.statusBottomBtn}>
        <Button 
          type="primary" 
          htmlType="submit" 
          loading={formWithdrawDetailLoading}
          onClick={onCancelApproveWithdraw}>
          Cancel
        </Button>
        <>
          <Button
            id="approvebtn"
            name="approvebtn"
            style={styles.statusApproveBtn}
            type="primary"
            htmlType="submit"
            loading={formWithdrawDetailLoading}
            onClick={() => {
              handleApproveManualWithdraw();
            }}
          >
            Approve
          </Button>
          <Button
            danger
            id="rejectbtn"
            name="rejectbtn"
            type="primary"
            htmlType="submit"
            loading={formWithdrawDetailLoading}
            onClick={(event) => {
              showRejectModal(event.target);
            }}
          >
            Reject
          </Button>
        </>
      </Form.Item>
    </Form>
  );
};

const showRejectModal = () => {
  setViewWithdrawVisible(false);
  setTableLoading(false);
  setRejectWithdrawVisible(true);
};

const handleRejectManualWithdraw = () => {
  if (!rejectReason) {
    alertData.title = "Field Required";
    alertData.msg = "Please fill in Reject Reason !";
    sharedErrorAlert(alertData);
    return;
  }

  confirm({
    className: "confirmationModalStyle",
    title: 'Confirmation',
    centered: true,
    icon: <QuestionCircleOutlined />,
    content: 'Are you sure you want to proceed?',
    onOk() {
      console.log('OK');
      setRejectButtonLoading(true);
      onSubmitRejectWithdraw();
    },
    onCancel() {
    },
  });
}

const onSubmitRejectWithdraw = async ()=> {
  let requestData = {
    trxnId: withdrawTrxnID,
    reason: rejectReason,
    userId: withdrawMemberID,
    username: username,
    rejectUserId: userId,
    processtype: PROCESS_TYPE.MANUAL,
    reqToken   : md5(getReqToken('withdrawManualReject')),
  };
  console.log(`requestData`, requestData)
  csWithdrawRejectV3(requestData)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === "ok") {
        onCancelRejectWithdraw();
        ManualWithdrawList();
        setRejectButtonLoading(false);
        alertData.title = "Success";
        alertData.msg = json.msg;
        sharedSuccessAlert(alertData);
      }
      else {
        setRejectButtonLoading(false);
        alertData.title = "Error";
        alertData.msg = json.msg;
        sharedErrorAlert(alertData);
      }
  })
    .catch((error) => {
      console.log("error: ", error);
      alertData.title = "Error";
      alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
      sharedErrorAlert(alertData);
    });   
}

const onCancelRejectWithdraw = () => {
  setRejectWithdrawVisible(false);
  setRejectReason("");
};

const RadioGrpStatus = () => {
  return (
    <Radio.Group
    buttonStyle={"solid"}
    value={status}
    onChange={(e) => {
      setStatus(e.target.value);
    }}
    styles={styles.hide}
  >
    <Radio.Button value="All">All</Radio.Button>
    <Radio.Button value="Pending">Pending</Radio.Button>
    <Radio.Button value="Approved">Approved</Radio.Button>
    <Radio.Button value="Rejected">Reject</Radio.Button>
  </Radio.Group>
  )
}

const BtnRefresh = () => {
  return (
    <Button
      style={styles.refreshBtn}
      type="primary"
      onClick={() => {
        setTableLoading(true);
        ManualWithdrawList()
        setTableLoading(false);
      }}
      >
      Refresh Page
    </Button>
  );
}    

const styles = {
  pageHearderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
  },
  refreshBtn:      { marginLeft: 10 },
  tableContainer:  { backgroundColor: "#FFFFFF", padding: 24 },
  paginationStyle: { marginTop: 50 },
  memberTrxnStyle: { marginLeft:"280px"},
  actionCompleteBtn: {
      marginRight: 20,
      marginLeft: 20,
      backgroundColor: "#4190f7",
      borderColor: "#4190f7",
  },
  actionBottomBtn: {
      flexDirection: "row",
      display: "flex",
      textAlign: "center",
  },
  statusApproveBtn: {
      marginRight: 20,
      marginLeft: 20,
      backgroundColor: "#4190f7",
      borderColor: "#4190f7",
  },
  statusBottomBtn: {
      flexDirection: "row",
      display: "flex",
      textAlign: "center",
  },
  hide : {
      display:"none",
  },
  alertText : {
      color: "Red",
  }
};

const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 160,
  },
};

const handleSearch = (selectedKeys, confirm) => {
  confirm();
  setSearchText(selectedKeys[0]);
};

const handleReset = (clearFilters) => {
  clearFilters();
  setSearchText("");
};

let searchInput = null;

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
  }) => (
      <div style={{ padding: 8 }}>
      <Input
          ref={(node) => {
          searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
      >
          Search
      </Button>
      <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
      >
          Reset
      </Button>
      </div>
  ),
  filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
      record[dataIndex]
      ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      : false,
  onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
  },
  render: (text) =>
      text ? (
      <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
      />
      ) : (
      false
      ),
});  

const withdrawColumns = [
  {
    title: "Data / Time",
    dataIndex: "date",
    width: "200px",
    ellipsis: true,
  },
  {
    title: "MB Nickname",
    dataIndex: "newNickname",
    ...getColumnSearchProps("newNickname"),
    // dataIndex: "nickname",
    // ...getColumnSearchProps("nickname"),
    width: "200px",
    ellipsis: true,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    ...getColumnSearchProps("amount"),
    width: "200px",
    ellipsis: true,
  },

  {
    ellipsis: true,
    title: "Bank Name (Out)",
    dataIndex: "bankNameOut",
    width: "200px",
    ...getColumnSearchProps("bankNameOut"),
  },
  {
    ellipsis: true,
    title: "Bank Account (Out)",
    dataIndex: "bankAcctOut",
    width: "200px",
  },
  {
    fixed: "right",
    ellipsis: true,
    title: "Status",
    dataIndex: "status",
    width: "250px",
    render: (text, record) => {
      switch (record.checkStatus) {
        case "Pending":
          return (
            <>
            <Button
              style={{ backgroundColor: "#0000FF", border: "none" }}
              type="primary"
              onClick={(event) => {
                showApproveModal(record, event.target);
              }}
            >
              Process
            </Button>
            </>
          );

        case "Rejected":
          return <>
                    <Badge status="error" text="Rejected" />
                  </>

        case "Approved":
          return <>
                    <Badge text="Approved" color={"blue"} />
                  </>

        case "Processing":
          return (
            <>              
              <Badge
                status="processing"
                text={
                  record.processBy !== username
                    ? `Processing by ${record.processBy}`
                    : "Processing"
                }
              />
            </>
          );

        case "Completed":
          return <>
                <Badge status="success" text="Completed" />
              </>;

        default:
          return <>
                  <Badge status="default" text="ERROR" />
                  </>
      }
    },
  },
  {
    title: "Remark",
    dataIndex: "remark",
    width: "200px",
    style: [{"word-wrap":"break-word"}],
    render: (text, record) => {
      switch (record.checkStatus) {
        case "Rejected":
            return <>{text}</>;
            
        case "Completed":
          if (text.includes("wallet2bank")){
            return "";
          }
          return <>{text}</>;

        case "Approved":
          if (record.puppetRemark!='') {
              return <>{record.puppetRemark}</>
          }else {
              if (text.includes("wallet2bank")){
                return "";
              }
          }

        default:
          return <></>;
      }
    }
  },
];

return (
  // <div className="eachPageContent">
  <div className="manualWithdrawV3">
    <PageHeader ghost={false}>
      <div style={styles.pageHearderContainer}>
        <div>
            <BtnRefresh/>
            {/* {  witMgmtManCrWit(role) ?
              (<><BtnCreateWithdraw/></>):<></>
            } */}
        </div>
        <RadioGrpStatus/>
      </div>
    </PageHeader>
    <Modal
      title={"Withdraw Confirmation"}
      visible={viewWithdrawVisible}
      footer={null}
      closable={false}
    >
      {viewWithdrawDetail()}
    </Modal>
    <Modal
      title={"Reject reason"}
      visible={rejectWithdrawVisible}
      onOk={() => {
        handleRejectManualWithdraw();
      }}
      onCancel={onCancelRejectWithdraw}
      maskClosable={false}
      okText={"Reject"}
      okType="primary"
      cancelButtonProps={{ type: "primary", loading: rejectButtonLoading }}
      okButtonProps={{ type: "danger", loading: rejectButtonLoading 
      }}
    >
      <TextArea
        value={rejectReason}
        rows={4}
        onChange={(e) => {
          setRejectReason(e.target.value);
        }}
      />
    </Modal>

    {/* <Modal
      title={"Member Withdraw Transactions of Yesterday & Today"}
      visible={puppetVisible}
      width={1400}
      style={styles.memberTrxnStyle}
      onCancel={handleCancelMemberTrxn}
      centered
      cancelButtonProps={{ type: "primary" }}
      okButtonProps={{ style:{display: "none"} }}    
      cancelText="Close"  
    >
      <Row>
          <b>Member Nickname: </b>{csMemberNickname}
      </Row>
      <Row>
          <><b>Member Wallet As of :</b> {csMemberWalletDt} &nbsp;&nbsp;<b>Balance :</b> <span type="primary">{csMemberWalletBal}</span>&nbsp;&nbsp;<b>Comm:</b>{csMemberWalletComm}</>
      </Row>
      <Table
          loading={trxLoading}
          columns={pptColumns}
          dataSource= {filteredPptTrxn }
          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: settings.pageSizeOptions,
            style: styles.paginationStyle,
            alignmentBottom: 'right',
          }}
          />
    </Modal> */}
    <div style={styles.tableContainer}>
      <Table
        scroll={{ x: isMobile ? "110%" : "100%", y: null }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: settings.pageSizeOptions,
          style: styles.paginationStyle,
          alignmentBottom: 'right',
        }}
        columns={withdrawColumns}
        // dataSource={originData}
        dataSource={data}
        loading={tableLoading}
      />
    </div>
  </div>
);
}

