import React, { Component } from "react";
import "../../App.less";
import { Layout, Menu, Button, Dropdown} from "antd";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    LogoutOutlined,
    UserOutlined,
    LockOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { NavLink, Switch, Route , Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";

//Navigate
import Login from "../login/login";
import Logout from "../logout/logout";
import { externalFileNotFile } from "../error/error";
import { route, ext_finance, mainMenu, logout, changePassword } from "../../config/route";
import { copyright } from "../../library/texts";
import { Logo } from "../../common/components/Logo";
import { settings } from "../../config/appConfig";
import { unlockMember } from "../../library/permission"; //Permission
import style from "../../resource/style/style";


const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;





class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: isMobile ? true : false,
            openKeys: ["ReportingParent","ReportOperation"],
            title: "",
        };
    }

    static propTypes = {
        location: PropTypes.object.isRequired,
    };

    state = {
        visible: false,
        openKeys: [],
    };

    styles ={
        hide: {
            display:"none"
        }
    }

    rootSubmenuKeys = ["sub1", "sub2", "sub3"];

    location = ["/", ""];

componentDidMount() {
    var that = this;
    ext_finance.forEach(function (cs) {
        if (cs.path === window.location.pathname) {
            that.setState({ title: cs.title });
        }
    });

    if (
            ext_finance[0].path === window.location.pathname ||
            ext_finance[1].path === window.location.pathname ||
            ext_finance[2].path === window.location.pathname ||
            ext_finance[3].path === window.location.pathname ||
            ext_finance[4].path === window.location.pathname ||
            ext_finance[5].path === window.location.pathname
        )
        {
            that.setState({ openKeys: ["ReportingParent"] });

            if (
                    ext_finance[0].path === window.location.pathname ||
                    ext_finance[1].path === window.location.pathname ||
                    ext_finance[2].path === window.location.pathname ||
                    ext_finance[3].path === window.location.pathname 
                )
                {
                    that.setState({ openKeys: ["ReportingParent","ReportOperation"] });        
                }

                if (
                    ext_finance[4].path === window.location.pathname ||
                    ext_finance[5].path === window.location.pathname
                )
                {
                    that.setState({ openKeys: ["ReportingParent","ReportAccounting"] });        
                }
        }

    document.addEventListener("gesturestart", function (e) {
        e.preventDefault(); //disable iphone zooming
    });
  }

toggle = () => {
    if (isMobile) {
        document.getElementById("menuBackground").style.display = "flex";
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }
    this.setState({
        collapsed: !this.state.collapsed,
    });
};

menuBgOnclick = () => {
    if (isMobile) {
        this.setState({
            collapsed: !this.state.collapsed,
        });
        document.getElementById("menuBackground").style.display = "none";
        document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
};

subMenuClick = () => {
    var that = this;
    ext_finance.forEach(function (cs) {
        if (cs.path === window.location.pathname) {
            that.setState({ title: cs.title });
        }
    });
};

onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
        (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.setState({ openKeys });
    } 
    else {
        this.setState({
            openKeys: latestOpenKey ? [latestOpenKey] : [],
        });
    }
};

menu = () => {
    return (
      <Menu>
        {(
          <Menu
            style={{
              paddingTop: 4,
              marginTop: -4,
              marginBottom: -4,
              paddingBottom: 2,
            }}
          >
            <Menu.Item 
              onClick={() => {
                window.location.reload();
              }}
              key={changePassword[0].path}
              style={{
                paddingTop: -2,
                marginTop: -5,
                marginBottom: -4,
              }}
              
            >
              <NavLink to={changePassword[0].path}>
                <div 
                  className="menu-item"
                  style={{
                    justifyContent: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <LockOutlined className="menu-icon" />
                  <span className="menu-text">Change Password</span>
                </div>
              </NavLink>
            </Menu.Item>

            <Menu.Item key={logout[0].path} className="logoutBorder"
              style={{
                marginBottom: -2,
              }}
            >
              <NavLink to={logout[0].path}>
                <div 
                  className="menu-item"
                  style={{
                    justifyContent: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <LogoutOutlined className="menu-icon" />
                  <span className="menu-text">Logout</span>
                </div>
              </NavLink>
            </Menu.Item>
          </Menu>
        )}
      </Menu>
    );
};

render() {
//const success = false;
    return this.props.signIn === false ? (
        <Switch>
            <Route path={[route.Default, route.Login]} exact component={Login} />
            <Route path={route.All} component={externalFileNotFile} />
        </Switch>
    ) 
    : 
    (
        <Layout>
            <div
                id="menuBackground"
                className="menuBackground"
                onClick={() => this.menuBgOnclick()}
            />
            <Sider
                trigger={null}
                collapsed={this.state.collapsed}
                collapsedWidth={isMobile ? 0 : 80}
                width={"280px"}
                style={{
                overflow: "auto",
                height: "100vh",
                position: "fixed",
                left: 0,
                }}
            >
                <div
                    style={{
                        height: 64,
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                    }}
                >
                    <div>
                        {!this.state.collapsed ? (
                        <Logo logoType="ext_finance" />
                        ) : null}
                    </div>
                </div>

                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={[window.location.pathname]}
                    openKeys={this.state.openKeys}
                    onOpenChange={this.onOpenChange}
                >  
                    <SubMenu
                        key="ReportingParent"
                        style={{ fontSize: 18 }}
                        title="Reporting"
                        collapsed={false}
                        icon={mainMenu[3].icon}
                    >
                        <SubMenu
                            key="ReportOperation"
                            style={{ fontSize: 16 }}
                            title="Operation"
                            collapsed={false}
                        >

                            <Menu.Item
                                onClick={() => {
                                    window.location.reload();
                                }}
                                key={ext_finance[0].path}
                                style={style.menuFontSize}
                            >
                                <NavLink to={ext_finance[0].path}>
                                    {ext_finance[0].menutitle}
                                </NavLink>
                            </Menu.Item>

                            <Menu.Item
                                onClick={() => {
                                    window.location.reload();
                                }}
                                key={ext_finance[1].path}
                                style={style.menuFontSize}
                            >
                                <NavLink to={ext_finance[1].path}>
                                    {ext_finance[1].menutitle}
                                </NavLink>
                            </Menu.Item>

                            <Menu.Item
                                onClick={() => {
                                    window.location.reload();
                                }}
                                key={ext_finance[2].path}
                                style={style.menuFontSize}
                            >
                                <NavLink to={ext_finance[2].path}>
                                    {ext_finance[2].menutitle}
                                </NavLink>
                            </Menu.Item>

                            <Menu.Item
                                onClick={() => {
                                    window.location.reload();
                                }}
                                key={ext_finance[3].path}
                                style={style.menuFontSize}
                            >
                                <NavLink to={ext_finance[3].path}>
                                    {ext_finance[3].menutitle}
                                </NavLink>
                            </Menu.Item>

                        </SubMenu>
                        <SubMenu
                            key="ReportAccounting"
                            style={{ fontSize: 16 }}
                            title="Accounting"
                            collapsed={false}
                        >
                            <Menu.Item
                                onClick={() => {
                                    window.location.reload();
                                }}
                                key={ext_finance[4].path}
                                style={style.menuFontSize}
                            >
                                <NavLink to={ext_finance[4].path}>
                                    {ext_finance[4].menutitle}
                                </NavLink>
                            </Menu.Item>

                            <Menu.Item
                                onClick={() => {
                                    window.location.reload();
                                }}
                                key={ext_finance[5].path}
                                style={style.menuFontSize}
                            >
                                <NavLink to={ext_finance[5].path}>
                                    {ext_finance[5].menutitle}
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>
                    </SubMenu>
                </Menu>
            </Sider>

            <Layout className="site-layout">
                {isMobile ? (
                <Header
                    className="site-layout-background"
                    style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",
                        height: 68,
                    }}
                >
                    {React.createElement(
                        this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                    {
                        className: "trigger",
                        onClick: this.toggle,
                    }
                    )}

                    <div
                    style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        color: "black",
                    }}
                    >
                        {this.state.title}
                    </div>

                    <div>
                    <div
                        style={{
                        fontSize: 18,
                        }}
                    >
                        <Dropdown overlay={this.menu} placement="bottomLeft">
                        <Button
                            style={{
                            border: "none",
                            width: 80,
                            height: 65,
                            }}
                            icon={<UserOutlined />}
                            size="large"
                        ></Button>
                        </Dropdown>
                    </div>
                    </div>
                </Header>
                ) : (
                <Header
                    className="site-layout-background"
                    style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: this.state.collapsed ? 64 : 280,
                    height: 68,
                    alignItems: "center",
                    }}
                >
                    {React.createElement(
                        this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                    {
                        className: "trigger",
                        onClick: this.toggle,
                    }
                    )}

                    <div
                    style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        color: "black",
                    }}
                    >
                        {this.state.title}
                    </div>

                    <div>
                    <div
                        style={{
                        fontSize: 18,
                        marginRight:20,
                        }}
                    >
                        <Dropdown overlay={this.menu} placement="bottomLeft">
                        <Button
                            style={{
                            border: "none",
                            width: 200,
                            height: 65,
                            }}
                            icon={<UserOutlined />}
                            size="large"
                        >
                            {this.props.role}_{this.props.username}
                        </Button>
                        </Dropdown>
                    </div>
                    </div>
                </Header>
                )}
                <Content
                className="site-layout-background"
                style={{
                    marginTop: "5px ",
                    marginLeft: isMobile ? "" : this.state.collapsed ? 64 : 280,
                    backgroundColor: "#f0f2f5",
                }}
                >
                <div
                    style={{
                    minHeight: 280,
                    }}
                >
                    <Switch>
                    <Route exact path={logout[0].path} component={Logout} />
                    {ext_finance.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                        />
                    ))}
                    </Switch>
                </div>
                </Content>
                {isMobile ? (
                <Footer style={{ textAlign: "center" }}>
                    <div>{copyright.fullText()}</div>
                    <div>V: {settings.version}</div>
                </Footer>
                ) : (
                <Footer style={{ textAlign: "center", marginLeft: 280 }}>
                    <div>{copyright.fullText()}</div>
                    <div>V: {settings.version}</div>
                </Footer>
                )}
            </Layout>
        </Layout>
    );
}
}

const mapStateToProps = ({ login }) => ({
    signIn: login.signIn,
    username: login.username,
    role: login.role,
    compCode: login.compCode,
});

const mapDispatchToProps = (dispatch) => {
    return { signOut: () => dispatch({ type: "sign_out" }) };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
