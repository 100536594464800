import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  SearchOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { 
  reportDailyBankStats,
  reportDailyBankTrxnStats
 } from "../../../config/networkConfig";
import { Table } from "ant-table-extensions";
import {
  PageHeader,
  Button,
  Breadcrumb,
  DatePicker,
  Space,
  Select,
  Input,
  Tag,
} from "antd";
import { isMobile } from "react-device-detect";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { settings } from "../../../config/appConfig";
import Highlighter from "react-highlight-words";
import { FormatDataMobile } from "../../../library/numbers";
import moment from "moment";
import { DailyBankTrxnStatsVw } from "../../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";


export default function ReportDailyBankTrxnStats() {

  const { Option } = Select;
  const { userId , role, username, compCode } = useSelector((state) => state.login);
  const [report, setReport] = useState("");
  const [allReport, setAllReport] = useState("");

  const [todayDate, setTodayDate] = useState("");

  const [tableLoading ,          setTableLoading] = useState(false);
  const [trxLoading, setTrxLoading] = useState(false);

  const [type, setType] = useState("thismonth");
  const [trxDateFrom, setTrxDateFrom] = useState("");
  const [trxDateTo, setTrxDateTo] = useState("");
  const [searchText, setSearchText] = useState("");

    
  const reportDateFrom = useRef(null);
  const reportDateTo = useRef(null);

  useEffect(() => {
    // if (DailyBankTrxnStatsVw(role, username)){
    // reportallReportList();
    // }
    //reportShareDetailList();
    // eslint-disable-next-line
  }, []);

  useEffect(()=> {
    setTrxDateFrom(trxDateFrom);
    setTrxDateTo(trxDateTo);
  },[trxDateFrom,trxDateTo]);

  const onClickExportBtn = ()=> {

    if (allReport.length === 0 ) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }

    document.getElementById("export-button-1").click();
  }

  const resetBtn = () => {
    setAllReport([]);
    setType("thismonth");
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });

  const trxDatePicker = (date, dateString) => {
    setType("custom");
    setTrxDateFrom(date);
  };

  const trxDatePicker2 = (date, dateString) => {
    setType("custom");
    setTrxDateTo(date);
  };

  const PickerWithType = ({ type, onChange }) => {
    if (type === 'custom'){
        setTrxDateFrom(trxDateFrom);
        setTrxDateTo(trxDateTo);
    }
    if (type === 'today'){
      let today = new Date().toLocaleDateString("en-CA", {day: "numeric"});
      setTrxDateFrom(moment().set('date', today))
      setTrxDateTo(moment().set('date', today))
    } 
    if (type === 'yesterday'){
      let yesterday = new Date(new Date().getTime());
      yesterday.setDate(yesterday.getDate()-1);
      let yestDay = yesterday.toLocaleDateString("en-CA", {day: "numeric"});
      let yestMonth = yesterday.toLocaleDateString("en-CA", {month: "numeric"});
      let yestYear = yesterday.toLocaleDateString("en-CA", {year: "numeric"});
      yestMonth = yestMonth - 1

      setTrxDateFrom(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
      setTrxDateTo(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
    }
    if (type === 'thisweek'){
      let today = new Date();
      let firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));
      let firstDayOfWeek = firstDay.toLocaleDateString("en-CA", {day: "numeric"});
      let firstMonthOfWeek = firstDay.toLocaleDateString("en-CA", {month: "numeric"});
      let firstYearOfWeek = firstDay.toLocaleDateString("en-CA", {year: "numeric"});
          firstMonthOfWeek = firstMonthOfWeek - 1; 
      let lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));
      let lastDayOfWeek = lastDay.toLocaleDateString("en-CA", {day: "numeric"});
      let lastMonthOfWeek = lastDay.toLocaleDateString("en-CA", {month: "numeric"});
      let lastYearOfWeek = lastDay.toLocaleDateString("en-CA", {year: "numeric"});
          lastMonthOfWeek = lastMonthOfWeek - 1;
      setTrxDateFrom(moment().set({'year': firstYearOfWeek, 'month': firstMonthOfWeek, 'date': firstDayOfWeek}));
      setTrxDateTo(moment().set({'year': lastYearOfWeek, 'month': lastMonthOfWeek, 'date': lastDayOfWeek}));
    }
    if (type === 'thismonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m, 1);
      let lastDay = new Date(y, m + 1, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})

      let year = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom(moment().set({'year': year, 'month': month, 'date': 1}))
      setTrxDateTo(moment().set({'year': year, 'month': month, 'date': lastDay}))
    }
    if (type === 'lastmonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m-1, 1);
      let lastDay = new Date(y, m, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})
      let yearOfFirstDay = firstDay.toLocaleDateString("en-CA", {year: "numeric"})

      let yearOfLastDay = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom(moment().set({'year': yearOfFirstDay, 'month': month, 'date': 1}))
      setTrxDateTo(moment().set({'year': yearOfLastDay, 'month': month, 'date': lastDay}))
    }
    return <DatePicker picker={type} onChange={onChange} />;
  };

  const searchBar = () => {
    if (!trxDateFrom) {
      reportDateFrom.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date From !";
      sharedErrorAlert(alertData);
      return;
    }

    if (!trxDateTo) {
      reportDateTo.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date To !";
      sharedErrorAlert(alertData);
      return;
    }

    if (trxDateTo < trxDateFrom || trxDateFrom > trxDateTo) {
      alertData.title = "Error";
      alertData.msg = "Please select a valid Date Range !";
      sharedErrorAlert(alertData);
      return;
    }


    setTrxLoading(true);
    reportallReportList();
  }

  const reportallReportList = () => {
    setTableLoading(true);
    var count = 0;

    let requestData = {
      datefrom : moment(trxDateFrom).format("YYYY-MM-DD"),
      dateto: moment(trxDateTo).format("YYYY-MM-DD"),
    }

    reportDailyBankTrxnStats(requestData)
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
        const allReport = Object.keys(json.data).length
        ? json.data.map((data) => {
            setTableLoading(false);
            return {
              bankChannel           : data.BankChannel,
              bankType              : data.BankType,
              bankCode              : data.BankCode,
              bankTime              : data.BankTime,
              bankTime1             : data.BankTime1,
              bankAmount            : data.BankAmount,
              trxnType              : data.TrxnType,
              bankDetails           : data.BankDetails,
              reqBankAcc            : data.ReqBankAcc,
              reqBankCode           : data.ReqBankCode,
              reqUserName           : data.ReqUserName,
              reqTimestamp          : data.ReqTimestamp,
              status                : data.Status == "0"? "Un-Claim" :
                                      data.Status == "2"? "Manual Claim" :
                                      data.Status == "8"? "Bank Conflict" : "",             
              sysCreateTime         : data.SysCreateTime,
              remark                : data.Remark,
            };
            })
            : [];

            let todayDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
            setTodayDate(todayDate);

            setAllReport(allReport);
            console.log("allReport >>>", allReport)

          if (allReport.length == 0 ) {
            alertData.title = "Error";
            alertData.msg = "No data !";
            sharedErrorAlert(alertData);
            setTableLoading(false);
            return;
          }
        } 
        else {
          alertData.title = "Error";
          alertData.msg = "Connection not stable, please try again !";
          sharedErrorAlert(alertData);
        }        
      });

      
  };

  const columns = [
    {
        align: "center",
        title: "Date",
        dataIndex: "reqTimestamp",
        width: 100,
        ellipsis: true,
        fixed:"left",
        render:function(record) {
          return <p style={{marginTop: "10px"}}>{record}</p>
        }
    },
    {
        align: "center",
        title: "Bank Details",
        dataIndex: "bankDetails",
        width: 100,
        ellipsis: true,
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{marginTop: "10px"}}>{record}</p>
        },
        ...getColumnSearchProps("bankDetails"),
    },
    {
        align: "center",
        title: "Bank Amount",
        dataIndex: "bankAmount",
        width: 100,
        ellipsis: true,
        render:function(record) {
          return <p style={{marginTop: "10px"}}>{record}</p>
        },
        ...getColumnSearchProps("bankAmount"),
    },
    // {
    //   align: "center",
    //   title: "Status",
    //   dataIndex: "status",
    //   width: 100,
    //   ellipsis: true,
    //   render: (text)=> {
    //     if (text == "Un-Claim"){
    //       return <Tag color="#FFBF00" style={styles.tagStyle}><b>Un-claim</b></Tag>
    //     } else if (text == "Bank Conflict"){
    //       return <Tag color="red" style={styles.tagStyle}>Bank Conflict</Tag>
    //     } else{
    //       return <Tag color="pink" style={styles.tagStyle}>Manual Claim</Tag>
    //     }

    //   },
    //   filters: [
    //     {
    //       text: 'Un-Claimed',
    //       value: 'Un-Claim',
    //     },

    //     {
    //       text: 'Bank Conflict',
    //       value: 'Bank Conflict',
    //     },
    //     {
    //       text: 'Manual Claim',
    //       value: 'Manual Claim',
    //     }
    //   ],
    //   filterMultiple: false,
    //   onFilter: (value, record) => record.status.toLowerCase().indexOf(value.toLowerCase()) === 0 ,

    // },
    // {
    //   align: "center",
    //   title: "Remark",
    //   dataIndex: "remark",
    //   width: "150px",
    //   render: (text,record) => {
    //       if (text.length > 0 && text.indexOf(';') > -1) {
    //           let members = text.split(';');
    //           const listItems = members.map((item,index) => 
    //                    <Tag  color="geekblue" style={styles.tagStyle}
    //                       key={`${index}-${item}`}
    //                       // onClick={() => {
    //                       //   setMemberVisible(true);
    //                       //   setPptAmt(record.bankAmount);
    //                       //   setPptTrxnId(record.pptBankTrxnId);
    //                       //   setToAcctNum(record.bankAccNo);
    //                       //   setMemberPhoneNo(item);
    //                       //   setBtnApproveMemberText(`Approve to ${item}`);
    //                       //   ViewPPTDetails(item.trim(),record.bankTrxTime);
    //                       // }}
    //                       // style={{...styles.memberBtn,...styles.noHighlight}}
    //                     >
    //                      {`${item}`}
    //                     </Tag>
    //                   )
              
    //           return (
    //             <div>
    //             {listItems }
    //             </div>
    //           );

    //       }else {
    //         return <div></div>;
    //       }
          
    //   },
    // },
  ];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    if (allReport.length === 0 ) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);
    let curDate = new Date();

    const title = `Daily Bank Stats Report (${moment(curDate).format('YYYY-MM-DD')})\n\n\n`;

    const headers = [
      [
        columns[0].title,
        columns[1].title,
        columns[2].title,
        columns[3].title,
        columns[4].title,
        columns[5].title,
        columns[6].title,
        columns[7].title,
        columns[8].title,
        columns[9].title,
        columns[10].title,
        columns[11].title,
        columns[12].title,
        columns[13].title,
        columns[14].title,
        columns[15].title,
        columns[16].title,
        columns[17].title,
        columns[18].title,
        columns[19].title,
        columns[20].title,
        columns[21].title,
        columns[22].title,
        columns[23].title,
        columns[24].title,
      ],
    ]; 

    var filteredReport = [...allReport];
    const data = filteredReport.map((data) => {
      return [
        data[columns[0].dataIndex],
        data[columns[1].dataIndex],
        data[columns[2].dataIndex],
        data[columns[3].dataIndex],
        data[columns[4].dataIndex],
        data[columns[5].dataIndex],
        data[columns[6].dataIndex],
        data[columns[7].dataIndex],
        data[columns[8].dataIndex],
        FormatDataMobile(data[columns[9].dataIndex]),
        FormatDataMobile(data[columns[10].dataIndex]),
        data[columns[11].dataIndex],
        FormatDataMobile(data[columns[12].dataIndex]),
        data[columns[13].dataIndex],
        FormatDataMobile(data[columns[14].dataIndex]),
        FormatDataMobile(data[columns[15].dataIndex]),
        data[columns[16].dataIndex],
        data[columns[17].dataIndex],
        FormatDataMobile(data[columns[18].dataIndex]),
        FormatDataMobile(data[columns[19].dataIndex]),
        FormatDataMobile(data[columns[20].dataIndex]),
        FormatDataMobile(data[columns[21].dataIndex]),
        FormatDataMobile(data[columns[22].dataIndex]),
        FormatDataMobile(data[columns[23].dataIndex]),
        data[columns[24].dataIndex],

      ];
    });

    let content = {
      startY: 80,
      head: headers,
      body: data,
      headStyles: { fillColor: "#1DA57A"},
      styles:{fontSize:7}
    };


    doc.text(title, marginLeft, 40);

    doc.autoTable(content);   
    doc.save(`Daily Bank Stats Report.pdf`);
  };

  const routes = [
    {
      path: "/",
      breadcrumbName: "Daily Bank Stats",
    },
    {
      //   path: "first",
      breadcrumbName: "Report",
    },
    {
      path: "commBalanceReport",
      breadcrumbName: "Daily Bank Stats Report",
    },
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 2;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    inlineBlock: {
      display: "inline-block",
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      // display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
      display: "inline",
    },
    resetBtn: {
      marginLeft: 10,
    },
    inline: {
      display: "inline",
    },
    hide: {
      marginLeft: 10000,
    },
    datePickerStyle: {
      width: 280,
    },
    datePickerSelectionStyle: {
      marginLeft: 10,
      width: 120,
    },
    refreshBtn: {
      marginLeft: 10,
    },
    headStyles: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 20,
      height: 20,
    },
    bodyStyle: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 60,
    },
    tagStyle:{
      width:"100px",
    },
    memberBtn: {
      marginTop:"10px",
      width:"150px", 
      marginBottom:"8px"
    }, 
  };

  return (
    <div>
      {
        DailyBankTrxnStatsVw(role,username,compCode) ?
        
        <div className="eachPageContent">
        {isMobile ? (
          
          <div>
            <PageHeader ghost={false}>
              <div>
                <Breadcrumb
                  separator=">"
                  itemRender={itemRender}
                  routes={routes}
                />
                <div style={styles.tableContainer}>
                  <Table
                    columns={columns}
                    dataSource={report}
                    className="cs_dailyTrxnStat"
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["5", "10", "20"],
                      style: styles.paginationStyle,
                    }}
                  />
                </div>
              </div>
            </PageHeader>
          </div>
        ) : (
          <div>
            <PageHeader
              ghost={false}
              // title="Daily Bank Stats Report"
            >
              <div style={styles.pageHeaderContainer}>
                {/* <Button
                  style={styles.refreshBtn}
                  type="primary"
                  onClick={() => {
                    // reportList();
                    window.location.reload();
                  }}
                >
                  Refresh Page
                </Button> */}
              
            
                <DatePicker
                    key="1"
                    style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                    format="YYYY-MM-DD"
                    onChange={trxDatePicker}
                    value={trxDateFrom}
                    ref={reportDateFrom}
                    refs
                    placeholder={"Select Date From"}
                    disabledDate={(current)=> {
                      return current > moment();//.subtract(1, 'day');
                    }}
                />
                <DatePicker
                  key="2"
                  style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                  format="YYYY-MM-DD"
                  onChange={trxDatePicker2}
                  value={trxDateTo}
                  ref={reportDateTo}
                  placeholder={"Select Date To"}
                  disabledDate={(current)=> {
                    let dateFrom = trxDateFrom;
                    if (dateFrom) {
                      return current < dateFrom || current > moment();//.subtract(1, 'day');
                    }else {
                      return  current > moment();//.subtract(1, 'day');
                    }
                  }}
                />
                <div style={styles.inlineBlock}>
                  <Space>
                    <Select style={{...styles.datePickerSelectionStyle}} value={type} type={type} onChange={setType}>
                      <Option value="custom">Custom</Option>
                      <Option value="today">Today</Option>
                      <Option value="yesterday">Yesterday</Option>
                      <Option value="thisweek">This Week</Option>
                      <Option value="thismonth">This Month</Option>
                      <Option value="lastmonth">Last Month</Option>
                    </Select>
                  </Space>
                  <Button
                    type={"primary"}
                    icon={<SearchOutlined
                    onClick={searchBar}
                    />}
                  />
                  <Button style={{...styles.resetBtn,...styles.refreshBtn}} onClick={resetBtn}>
                    Reset
                  </Button>
                </div>
                <Button 
                      type={"primary"} 
                      style={styles.refreshBtn} 
                      icon={<FileExcelOutlined />}
                      onClick={() => {
                          onClickExportBtn();
                      }}
                      
                  >
                    Export to Excel
                </Button>
              </div>
            </PageHeader>
            <div style={styles.tableContainer}>
              <Table
                loading={tableLoading} 
                columns={columns}
                dataSource={allReport}
                className="cs_dailyTrxnStat"
                scroll={{ x: 100, y: null }}
                exportable={true}
                pagination={{
                  defaultPageSize: 100,
                  showSizeChanger: true,
                  pageSizeOptions: settings.pageSizeOptions,
                  style: styles.paginationStyle,
                }}
                exportableProps={{
                  // feedFields,
                  fileName: "DailyBankTrxnStatsReport_"+todayDate,
                  btnProps: {
                    type: "primary",
                    icon: <FileExcelOutlined />,
                    id:"export-button-1",
                    children: <span>Export to Excel</span>,
                    style: {display:"none"}
                  },
                }}
              />
              <br></br>
              <div style={{...styles.inline, ...styles.hide}}>
                <PickerWithType type={type} onChange={(value) => console.log(value)} />
              </div>
            </div>
          </div>
        )}
        </div>:
        <div style={{textAlign:"center",color:"red",fontSize:"150%"}}><h1 style={{color:"red"}}>Not Allowed to Access This Page</h1></div>
      }
    </div>
      
  );
}