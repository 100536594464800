import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  Form,
  Table,
  Select,
  Badge,
  PageHeader,
  Typography,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  getEnvayaConfigList,
  getBankMachList,
  csCreateEnvayaConfig,
  csRemoveEnvayaConfig,
  getServerIp,
} from "../../config/networkConfig";
import { useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";
import { bindSmsVw } from "../../library/permission";

export default function BindSMS() {
  const { Paragraph } = Typography;
  const { confirm } = Modal;
  const { Option } = Select;
  const [form] = Form.useForm();
  const modalForm = useRef();
  const [visible, setVisible] = useState(false);
  const { userId, role } = useSelector((state) => state.login);
  const [selectedAcctNo, setSelectedAcctNo] = useState("");
  const [selectedAcctName, setSelectedAcctName] = useState("");
  const [selectedBankCode, setSelectedBankCode] = useState("");
  const [editingKey, setEditingKey] = useState("");
  const [tempTableData, setTempTableData] = useState([]);
  const [tableData, setTableData] = useState([])
  const [tableLoading, setTableLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectionList, setSelectionList] = useState([]);
  const [tempSelectionList, setTempSelectionList] = useState([]);
  const [serverIp, setServerIp] = useState("");

  const layout = {
    labelCol: { span: 8 },
  };

  useEffect(() => {
    getEnvayaBankList();
    retrieveServerIp()
    // eslint-disable-next-line
  }, []);

  // Set selection List and set table data with bank code
  useEffect(() => {
    // Extract acctNo values from tempTableData
    const existingActiveAcctNo = tempTableData
    .filter(item => item.status !== 0)
    .map(item => item.acctNo);
    // get an array of acct numbers

    // Filter out items that already exist in tableData
    const newData = tempSelectionList
    .filter(data => !existingActiveAcctNo.includes(data.acctNo))
    .map((data) => ({
        key: data.count,
        acctNo: data.acctNo,
        acctName: data.acctName,
        bankCode: data.bankCode,
    }));
    setSelectionList(newData);

    // Get bank code from tempselectionlist
    const newData2 = tempTableData.map((data) => {
      const selectedItem = tempSelectionList.find((selection) => selection.acctNo === data.acctNo);
      const bankCode = selectedItem ? selectedItem.bankCode : ""; // Get bankCode from selectionList
      return {
        key: data.key,
        acctNo: bankCode +` `+ `(`+data.acctNo+`)`,
        acctNoOri: data.acctNo,
        acctName: data.acctName,
        bankCode: bankCode,
        createDate: data.createDate,
        status: data.status,
      };
    });
    setTableData(newData2);
  },[tempSelectionList])

  const columns = [
    {
      ellipsis: true,
      title: "Account No.",
      dataIndex: "acctNo",
      width: "100px",
    },
    {
      ellipsis: true,
      title: "Account Name",
      dataIndex: "acctName",
      width: "100px",
    },
    {
      ellipsis: true,
      title: "Date Created",
      dataIndex: "createDate",
      width: "100px",
    },
    {
      ellipsis: true,
      title: "Status",
      dataIndex: "status",
      editable: true,
      width: "60px",
      render: (text, record) => {
        switch (record.status) {
          case 0:
            return <Badge status="warning" text="Inactive" />;
          case 1:
            return <Badge status="success" text="Active" />;
          default:
            return <Badge status="error" text="Inactive" />;
        }
      },
    },
    { 
      ellipsis: true,
      title: 'Action',
      // align: "center",
      dataIndex: 'action',
      width: "60px",
      render: (text, record) => {
          return(
              <div>
                  <Button 
                    type="danger"
                    disabled={!bindSmsVw(role)}
                    onClick={() => {
                      handleRemove(record)
                    }}
                  >
                          <DeleteOutlined />
                  </Button>
              </div>
          )
      }
    }
  ];

  const handleConfirm = () => {
    let msg = `Are you sure you want to setup SMS for the following bank?`;

    confirm({
      className: "confirmationModalStyle",
      title: 'Confirmation',
      width: '500px',
      centered: true,
      icon: <QuestionCircleOutlined />,
      content: 
          <Paragraph>{msg}
              <ul>
                  <li>
                      Account No. :  {selectedAcctNo}
                  </li>
                  <li>
                      Account Name :  {selectedAcctName}
                  </li>
                  <li>
                      Bank :  {selectedBankCode}
                  </li>
              </ul>
          </Paragraph>,
      onOk() {
        handleAddEnvayaBank();
      },
      onCancel() {
      },
  });
  }

  const handleRemove = (record) => {
    let msg = `Are you sure you want to remove SMS for the following bank?`;

    confirm({
      className: "confirmationModalStyle",
      title: 'Confirmation',
      width: '500px',
      centered: true,
      icon: <QuestionCircleOutlined />,
      content: 
          <Paragraph>{msg}
              <ul>
                  <li>
                      Account No. :  {record.acctNoOri}
                  </li>
                  <li>
                      Account Name :  {record.acctName}
                  </li>
                  <li>
                      Bank :  {record.bankCode}
                  </li>
              </ul>
          </Paragraph>,
      onOk() {
        setTableLoading(true);
        handleRemoveEnvayaBank(record.acctNoOri, record.acctName, record.bankCode);
      },
      onCancel() {}
    })
  }

  const handleCancel = () => {
    setVisible(false);
    modalForm.current.resetFields();
  };

  // For adding envaya bank into the envaya_config and envayasms/config/.env
  const handleAddEnvayaBank = async () => {
    setModalLoading(true);
    const requestData = {
      bankAcc : selectedAcctNo,
      bankName : selectedAcctName,
      bankCode : selectedBankCode
    }

    const respAddEnvayaBank = await csCreateEnvayaConfig(requestData);
    let resp = await respAddEnvayaBank.json();
    if (resp.status === "ok") {
      alertData.title = "Success";
      alertData.msg = `SMS for ${selectedAcctName} ${selectedAcctNo} (${selectedBankCode}) is added successfully.`;
      sharedSuccessAlert(alertData);
      getEnvayaBankList();
    } else {
      alertData.title = "Failed";
      alertData.msg = `Failed to add SMS for ${selectedAcctName} ${selectedAcctNo} (${selectedBankCode}). Please try again later.`;
      sharedFailAlert(alertData);
    }
    setModalLoading(false);
    handleCancel();
  }

  // Fore removing envaya bank from envaya_config and envayasms/config/.env
  const handleRemoveEnvayaBank = async (bankAcc, bankName, bankCode) => {
    const requestData = {
      bankAcc : bankAcc,
      bankName : bankName,
    }

    const respRemoveEnvaya = await csRemoveEnvayaConfig(requestData);
    let resp = await respRemoveEnvaya.json();
    if (resp.status === 'ok') {
      setTableLoading(false);
      alertData.title = "Success";
      alertData.msg = `SMS for ${bankName} ${bankAcc} (${bankCode}) is removed successfully.`;
      sharedSuccessAlert(alertData);
    } else {
      setTableLoading(false);
      alertData.title = "Failed";
      alertData.msg = `Failed to remove SMS for ${bankName} ${bankAcc} (${bankCode}). Please try again later.`;
      sharedFailAlert(alertData);
    }
    getEnvayaBankList();
  }

  // Get envaya bank list from envaya_config
  const getEnvayaBankList = async () => {
    let count = 0;
    const requestData = {
      num : 1
    }

    setTableLoading(true);
    // List out the banks in envaya_config
    const respEnvayaBank = await getEnvayaConfigList(requestData);
    let resp = await respEnvayaBank.json();
    if (resp.status === "ok") {
      if (resp.data.length > 0) {
        const newData = resp.data.map((data) => {
          return {
            key: count++,
            acctNo: data.acctNo,
            acctName: data.acctName,
            createDate: data.createDate,
            status: data.status,
          };
        });
        setTempTableData(newData);
        // setTableLoading(false);
      } else {
          setTempTableData([]);
        }    
  }
  else {
      // setTableLoading(false);
      alertData.title = "Failed";
      alertData.msg = resp.msg;
      sharedFailAlert(alertData);
  }
    getEnvayaSelectionList()
  }

  const retrieveServerIp = async () => {
    let requestData = {}
    const result = await getServerIp(requestData);
    let resp = await result.json();

    if (resp.status == "ok") {
      setServerIp(resp.data);
    } else {
      alertData.title = "Failed";
      alertData.msg = "Failed to get server IP.";
      sharedFailAlert(alertData);
    }
  }

  // get the deposit banks that havent setup SMS from bank_machine
  const getEnvayaSelectionList = async () => {
    let count = 0;
    const requestData = {
        BankType: 'Deposit'
    };

    setTableLoading(true);
    const respEnvayaSelection = await getBankMachList(requestData);
    let resp = await respEnvayaSelection.json();
    
    if (resp.status === "ok") {
        // setSelectionList(newData);
        if (resp.data.length > 0) {
          const newData = resp.data.map((data) => ({
            key: count++,
            acctNo: data.acctnum,
            acctName: data.acctname,
            bankCode: data.bankcode,
          }))
          setTempSelectionList(newData);
        } else {
          setTempSelectionList([])
        }
    }
    setTableLoading(false);
  };

  return (
    <div style={{ backgroundColor: "#FFFFFF", paddingLeft:10 }}>
      <Modal
        title="Setup SMS"
        visible={visible}
        footer={null}
        confirmLoading={modalLoading}
      >
        <Form {...layout} ref={modalForm} layout="vertical">
          <Form.Item
            name={["bank", "selectBankType"]}
            label="Deposit Bank"
            rules={[
              {
                required: true,
                message: "Please select your Deposit Bank!",
                whitespace: true,
              },
            ]}
          >
            <Select
              placeholder="Please Select Bank"
              getPopupContainer={(trigger) => trigger.parentNode}
              onSelect={(value, option) => {
                setSelectedAcctNo(option.key);
                setSelectedAcctName(value);
                setSelectedBankCode(option.bankCode);
              }}
            >
              {selectionList.length > 0 ? (
                selectionList.map((selection) => (
                  <Option key={selection.acctNo} value={selection.acctName} bankCode={selection.bankCode}>
                    {`${selection.bankCode} ( ${selection.acctNo} ) - ${selection.acctName}`}
                  </Option>
                ))
              ) : (
                <Option value="None" disabled>None</Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            <Button
              loading={modalLoading}
              type="primary"
              htmlType="submit"
              style={{ marginRight: 10}}
              onClick={() => {
                if (selectedAcctName && selectedAcctNo) {
                  handleConfirm();
                }
              }}
            >
              Submit
            </Button>
            <Button
              htmlType="button"
              onClick={() => {
                handleCancel();
              }}
              loading={modalLoading}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <PageHeader ghost={false}>
        <div>
          <Button
            type="primary"
            onClick={() => {
              setVisible(true);
              setEditingKey("");
            }}
            disabled={!bindSmsVw(role) || tableLoading}
          >
            Add
          </Button>
        </div>
      </PageHeader>

      <Form form={form} component={false}>
        <Table
          style={{ overflow: "auto", marginLeft: 20 }}
          loading={tableLoading}
          scroll={{ x: "100%", y: null }}
          columns={columns}
          dataSource={tableData}
        />
      </Form>
      <div style={{padding: 24}}>
        <p>
            <b>REMARK :</b>
        </p>
        <p>
          <b><span style={{color:"blue"}}>[1]</span></b> Envaya URL = <b>http://{serverIp}/api/v2/rest/receiveSMS</b>
        </p>
        <br></br>
      </div>
    </div>
  );
}