import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useEffect,
} from "react";

const ExpandCollapseContext = createContext({
  level: 0,
  cumulativeHeaderInfo: [],
  addHeaderHeight: () => {},
  cumulativeHeaderHeight: 0,
  duringAnimationLevel: null,
});

export const ExpandCollapseProvider = ({ children, duringAnimationLevel }) => {
  const parentContext = useContext(ExpandCollapseContext);
  const [cumulativeHeaderInfo, setCumulativeHeaderInfo] = useState(
    parentContext.cumulativeHeaderInfo
  );
  const currentLevel = parentContext.level + 1;
  const latestDuringAnimationLevel = parentContext.duringAnimationLevel
    ? parentContext.duringAnimationLevel
    : duringAnimationLevel;

  useEffect(() => {
    setCumulativeHeaderInfo((prev) => {
      console.log(
        `inner ${JSON.stringify(prev)} useEffect get current level`,
        currentLevel
      );
      const currentLevelInfo = prev.find((item) => item.level == currentLevel);
      const newResult = [...parentContext.cumulativeHeaderInfo].filter(
        (item) => item.level != currentLevel
      );
      if (currentLevelInfo) {
        newResult.push(currentLevelInfo);
      }
      return newResult;
    });
  }, [parentContext.cumulativeHeaderInfo]);

  const addHeaderHeight = (height) => {
    setCumulativeHeaderInfo((prev) => {
      console.log(`added height ${currentLevel} and ${height}`);
      const index = prev.findIndex((item) => item.level === currentLevel);

      const newItem = { level: currentLevel, headerHeight: height };
      console.log(`start======`, currentLevel);
      console.log(JSON.stringify(prev));
      if (index !== -1) {
        // If the item exists, replace it
        prev.splice(index, 1, newItem);
      } else {
        // If the item doesn't exist, add it to the array
        prev.push(newItem);
      }

      console.log(JSON.stringify(prev));

      console.log(`end======`, currentLevel);
      return [...prev];
    });
  };

  const cumulativeHeaderHeight = () => {
    let cumHeight = 0;
    for (const item of cumulativeHeaderInfo) {
      if (item.level < currentLevel) cumHeight += item.headerHeight;
    }
    return cumHeight;
  };

  return (
    <ExpandCollapseContext.Provider
      value={{
        level: currentLevel,
        cumulativeHeaderInfo,
        addHeaderHeight,
        cumulativeHeaderHeight: cumulativeHeaderHeight(),
        duringAnimationLevel: latestDuringAnimationLevel,
      }}
    >
      {children}
    </ExpandCollapseContext.Provider>
  );
};
export const useExpandCollapseContext = () => {
  return useContext(ExpandCollapseContext);
};
