import React, { useState, useEffect, useRef } from "react";
import {
  // Table,
  PageHeader,
  Button,
  DatePicker,
  Modal,
  Form,
  Tabs,
  Tag,
} from "antd";
import {
  reportTopList,
  reportTopTransactionSummary,
  reportGameBettingSummary,
  reportDepWthList,
} from "../../../config/networkConfig";
import { Table } from "ant-table-extensions";
import {settings} from "../../../config/appConfig";
import { FormatDataMobile } from "../../../library/numbers";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { 
  SearchOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { topListVw } from "../../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";
import style from "../../../resource/style/style";


export default function ReportTopList() {
    const {TabPane} = Tabs;
    const { compCode , username, role, userId, issuper} = useSelector((state) => state.login);
    let today = new Date().toLocaleDateString("en-CA", {day: "numeric"});

    const [defaultKey,                 setDefaultKey] = useState("1");

    const [topDepositData,         setTopDepositData] = useState([]);
    const [topWithdrawData,       setTopWithdrawData] = useState([]);
    const [topCommData,               setTopCommData] = useState([]);
    const [topComm2BankData,     setTopComm2BankData] = useState([]);

    const [topTrxnSummary,         setTopTrxnSummary] = useState([]);
    const [userIdResult,             setUserIdResult] = useState([]);
    const [gameBetSummary,         setGameBetSummary] = useState([]);
    const [depList,                       setDepList] = useState([]);
    const [wthList,                       setWthList] = useState([]);

    const [trxnSummaryTitle,     setTrxnSummaryTitle] = useState([]);
    const [gameBettingTitle,     setGameBettingTitle] = useState([]);
    const [depListTitle,             setDepListTitle] = useState([]);
    const [wthListTitle,             setWthListTitle] = useState([]);

    const [mobile,                         setMobile] = useState([]);

    const [tab1Title,                   setTab1Title] = useState("");
    const [tab2Title,                   setTab2Title] = useState("");
    const [tab3Title,                   setTab3Title] = useState("");
    const [tab4Title,                   setTab4Title] = useState("");

    const [table1Loading ,          setTable1Loading] = useState(false);
    const [table2Loading ,          setTable2Loading] = useState(false);
    const [table3Loading ,          setTable3Loading] = useState(false);
    const [table4Loading ,          setTable4Loading] = useState(false);

    const [modal1Loading ,          setModal1Loading] = useState(false);
    const [modal2Loading ,          setModal2Loading] = useState(false);
    const [modal3Loading ,          setModal3Loading] = useState(false);
    const [modal4Loading ,          setModal4Loading] = useState(false);

    const [modalVisible,             setModalVisible] = useState(false);

    const [trxLoading,                 setTrxLoading] = useState(false);

    const [trxDate,                       settrxDate] = useState(moment(new Date()));

    const [todayDate,                   setTodayDate] = useState(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
    const [type,                             setType] = useState("today");
      
    const reportDateFrom = useRef(null);
    const reportDateTo = useRef(null);

    window.setTimeout( function() {
      window.location.reload();
    }, 300000);

    useEffect(() => {
        assignTabName();
        setTab1();
        setTab2();
        setTab3();
        setTab4();
      
    }, []);

    // useEffect(()=> {
    //   settrxDate(trxDate);
      
    // },[trxDate]);

    //#region Functions
    const onClickExportBtn = ()=> {

      if (topDepositData.length === 0 ) {
        alertData.title = "Error";
        alertData.msg = "No data to download !";
        sharedErrorAlert(alertData);
        return;
      }
  
      document.getElementById("export-button-1").click();
    }

    const resetBtn = () => {
      settrxDate("");
      setTopDepositData([]);
      setTopWithdrawData([]);
      setTopCommData([]);
      setTopComm2BankData([]);
      setDefaultKey("1");
    };
  
    const trxDatePicker = (date, dateString) => {   
      settrxDate(moment(dateString));
    };
  
    // const trxDatePicker2 = (date, dateString) => {
    //   setTrxDateTo(date);
    // };
  
    const PickerWithType = ({ type, onChange }) => {
      if (type === 'today'){
          settrxDate(trxDate);
      }
    }

    const searchBar = () => {
      setTodayDate(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))

      if (!trxDate) {
        reportDateFrom.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Date From !";
        sharedErrorAlert(alertData);
        return;
      }
  
      // if (!trxDateTo) {
      //   reportDateTo.current.focus();
      //   alertData.title = "Field Required";
      //   alertData.msg = "Please select Date To !";
      //   sharedErrorAlert(alertData);
      //   return;
      // }
  
      // if (trxDateTo < trxDate || trxDate > trxDateTo) {
      //   alertData.title = "Error";
      //   alertData.msg = "Please select a valid Date Range !";
      //   sharedErrorAlert(alertData);
      //   return;
      // }
  
  
      setTrxLoading(true);
      setTab1();
      setTab2();
      setTab3();
      setTab4();
    }

    const onChangeTab = (key)=> {
        if (key == 2) {
            setTab2();
        }

        if (key == 3) {
            setTab3();
        }

        if (key == 4) {
            setTab4();
      }
    }

    const assignTabName = () => {

        const tab1Tile    = `Top Deposit List `;
        const tab2Tile    = `Top Withdraw List`;
        const tab3Tile    = `Top Highest Comm List`;
        const tab4Tile    = `Top Comm2Bank List`;

        setTab1Title(tab1Tile);
        setTab2Title(tab2Tile);
        setTab3Title(tab3Tile);
        setTab4Title(tab4Tile);
    }

    const topListDetails = (record, record2) => {
      setMobile(record);
      setModalVisible(true);
      setTrxnSummaryTitle(`Transaction Summary`);
      setGameBettingTitle(`Game Betting Summary`);
      setDepListTitle(`Deposit List`);
      setWthListTitle(`Withdraw List`);

      topTransactionSummary(record);
      gameBettingSummary(record);
      depWthList(record);
    }

    const onChangeTransferTab = (key)=> {
      if (key === 1) {

      }

      if (key === 2) {
      }

    }

    const onCancelModal = ()=> {
      setModalVisible(false);
      
    }

    const topTransactionSummary = async (record) => {

      let requestData = {
        mobile : record,
      }

      let response = await reportTopTransactionSummary(requestData);
      let result = await response.json();

      if (result.status == 'ok'){
        // setAgentOptions(resTrxn.msg != 'No record.' ? resTrxn.data : []);
      }
      else{
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
      }

      setTopTrxnSummary(result.data)
    }

    const gameBettingSummary = (record) => {
  
      let requestData = {
        mobile : record,
      }
  
      reportGameBettingSummary(requestData)
        .then((response) => response.json())
        .then((json) => {
          console.log("json -----", json);
          if (json.status === "ok") {
          const userIDResult = Object.keys(json.data.userData).length
          ? json.data.userData.map((data) => {
              // setTableLoading(false);
              return {
                Mobile                : data.Mobile,
                AWC_UserID            : data.AWC_UserID,
                BankAccName           : data.BankAccName,
                GameUser              : data.GameUser,
                Joker_UserID          : data.Joker_UserID,
                RegisterDate          : data.RegisterDate,
                SA_UserID             : data.SA_UserID,
                UFA_UserID            : data.UFA_UserID,
              };
              })
              : [];            

              setUserIdResult(userIDResult);

            // if (userIDResult.length == 0 ) {
            //   alertData.title = "Error";
            //   alertData.msg = "No data1!";
            //   sharedErrorAlert(alertData);
            //   // setTableLoading(false);
            //   return;
            // }
          } 
          else {
            alertData.title = "Error";
            alertData.msg = "Connection not stable, please try again !";
            sharedErrorAlert(alertData);
          }        
        });

        reportGameBettingSummary(requestData)
        .then((response) => response.json())
        .then((json) => {
          console.log("json -----", json);
          if (json.status === "ok") {
          const gameBetResult = Object.keys(json.data.gameBetData).length
          ? json.data.gameBetData.map((data) => {
              // setTableLoading(false);
              return {
                TrxnDate              : data.TrxnDate,
                GameCode              : data.GameCode,
                GameID                : data.GameID,
                GameName              : data.GameName,
                TotalTicket           : data.TotalTicket,
                BetAmt                : data.BetAmt,
                WinLoss               : data.WinLoss,
                Turnover              : data.Turnover,
              };
              })
              : [];            
  
              setGameBetSummary(gameBetResult);
            // if (gameBetResult.length == 0 ) {
            //   alertData.title = "Error";
            //   alertData.msg = "No data2 !";
            //   sharedErrorAlert(alertData);
            //   // setTableLoading(false);
            //   return;
            // }
          } 
          else {
            alertData.title = "Error";
            alertData.msg = "Connection not stable, please try again !";
            sharedErrorAlert(alertData);
          }        
        });
        
    };

    const depWthList = (record) => {
  
      let requestData = {
        mobile : record,
      }
  
      reportDepWthList(requestData)
        .then((response) => response.json())
        .then((json) => {
          console.log("json -----", json);
          if (json.status === "ok") {
          const depListResult = Object.keys(json.data.depData).length
          ? json.data.depData.map((data) => {
              // setTableLoading(false);
              return {
                
                TrxnDate              : data.TrxnDate,
                TrxnAmt               : data.TrxnAmt,
              };
              })
              : [];            
  
              setDepList(depListResult);
  
            // if (depListResult.length == 0 ) {
            //   alertData.title = "Error";
            //   alertData.msg = "No data3 !";
            //   sharedErrorAlert(alertData);
            //   // setTableLoading(false);
            //   return;
            // }
          } 
          else {
            alertData.title = "Error";
            alertData.msg = "Connection not stable, please try again !";
            sharedErrorAlert(alertData);
          }        
        });
  
        reportDepWthList(requestData)
        .then((response) => response.json())
        .then((json) => {
          console.log("json -----", json);
          if (json.status === "ok") {
          const wthListResult = Object.keys(json.data.wthData).length
          ? json.data.wthData.map((data) => {
              // setTableLoading(false);
              return {
                
                TrxnDate              : data.TrxnDate,
                TrxnAmt               : data.TrxnAmt,
              };
              })
              : [];            
  
              setWthList(wthListResult);
  
            // if (wthListResult.length == 0 ) {
            //   alertData.title = "Error";
            //   alertData.msg = "No data4 !";
            //   sharedErrorAlert(alertData);
            //   // setTableLoading(false);
            //   return;
            // }
          } 
          else {
            alertData.title = "Error";
            alertData.msg = "Connection not stable, please try again !";
            sharedErrorAlert(alertData);
          }        
        });
        
    };

    const setTab1 = async () => {
        setTable1Loading(true);  

        let requestData = {
            date : trxDate.format("YYYY-MM-DD") ,
        }
        
        let respTab1 = await reportTopList(requestData);
        let respTopDeposit = await respTab1.json();
        let TotalAmt = 0;
        let ctr = 1;

        if (respTopDeposit.status === "ok" ) {

            const respTopDeposit1 = Object.keys(respTopDeposit.data.depositData).length ?
            respTopDeposit.data.depositData.map((obj)=> {
                    return{
                        key         : ctr++,
                        mobile      : obj.Mobile,
                        totalAmt    : obj.TotalAmt,
                        countTrxn   : obj.CountTrxn,
                        duplicate   : obj.Duplicate,
                    }
                })
            :[];

          /* Summary Row */
          let newTopDeposit = [...respTopDeposit1];
          newTopDeposit.forEach(({ totalAmt }) => {
            TotalAmt      += totalAmt;
          });
          let maxKey = parseInt(Math.max.apply(Math, newTopDeposit.map(function(o) { return o.key; })))+1;

          newTopDeposit= [
              ...newTopDeposit,
              {
                key: maxKey,
                key                 : "Summary",
                mobile              : "test",
                totalAmt            : TotalAmt,
              },
            ];

            setTopDepositData(newTopDeposit);            
        }

        setTable1Loading(false);
    }

    const setTab2 = async () => {
      setTable2Loading(true);

      let requestData = {
        date : trxDate.format("YYYY-MM-DD") ,
      }
    
      let respTab2 = await reportTopList(requestData);
      let respTopWithdraw = await respTab2.json();
      let TotalAmt = 0;
      let ctr = 1;

      if (respTopWithdraw.status === "ok" ) {

        const respTopWithdraw1 = Object.keys(respTopWithdraw.data.withdrawData).length ?
        respTopWithdraw.data.withdrawData.map((obj)=> {
                return{
                    key         : ctr++,
                    mobile      : obj.Mobile,
                    totalAmt    : obj.TotalAmt,
                    countTrxn   : obj.CountTrxn,
                    duplicate   : obj.Duplicate,
                }
            })
        :[];

        /* Summary Row */
        let newTopWithdraw = [...respTopWithdraw1];
        newTopWithdraw.forEach(({ totalAmt }) => {
          TotalAmt      += totalAmt;
        });

        let maxKey = parseInt(Math.max.apply(Math, newTopWithdraw.map(function(o) { return o.key; })))+1;

        newTopWithdraw= [
            ...newTopWithdraw,
            {
              key: maxKey,
              key                 : "Summary",
              totalAmt            : TotalAmt,
            },
          ];

          setTopWithdrawData(newTopWithdraw);
          
      }

        setTable2Loading(false);
    }

    const setTab3 = async () => {
      setTable3Loading(true);

      let requestData = {
        date : trxDate.format("YYYY-MM-DD") ,
      }

      let respTab3 = await reportTopList(requestData);
      let respTopComm = await respTab3.json();
      let TotalAmt = 0;
      let ctr = 1;

      if (respTopComm.status === "ok" ) {

          const respTopComm1 = Object.keys(respTopComm.data.commData).length ?
          respTopComm.data.commData.map((obj)=> {
                  return{
                      key         : ctr++,
                      createTime  : obj.CreateTime,
                      mobile      : obj.Mobile,
                      amount      : obj.Amount,
                      duplicate   : obj.Duplicate,
                  }
              })
          :[];

          /* Summary Row */
          let newTopComm = [...respTopComm1];
          newTopComm.forEach(({ totalAmt }) => {
            TotalAmt      += totalAmt;
          });

          let maxKey = parseInt(Math.max.apply(Math, newTopComm.map(function(o) { return o.key; })))+1;

          newTopComm= [
              ...newTopComm,
              {
                key: maxKey,
                key                 : "Summary",
                totalAmt            : TotalAmt,
              },
            ];

            setTopCommData(respTopComm1);

        }

          setTable3Loading(false);
      }

    const setTab4 = async () => {
      setTable4Loading(true);

      let requestData = {
        date : trxDate.format("YYYY-MM-DD") ,
        // dateto   : getTab1Date().dateto,
        //date : new Date().toLocaleDateString('en-GB').split('/').reverse().join('-'),
      }

      let respTab4 = await reportTopList(requestData);
      let respTopComm2Bank = await respTab4.json();
      let ctr = 1;

      if (respTopComm2Bank.status === "ok" ) {

          const respTopComm2Bank1 = Object.keys(respTopComm2Bank.data.comm2BankData).length ?
          respTopComm2Bank.data.comm2BankData.map((obj)=> {
                  return{
                      key         : ctr++,
                      createTime  : obj.CreateTime,
                      mobile      : obj.Mobile,
                      comm        : obj.Comm,
                      duplicate   : obj.Duplicate,
                  }
              })
          :[];

          setTopComm2BankData(respTopComm2Bank1);
        }

        setTable4Loading(false);
    }
    //#endregion

    const columnsDep = [
      {
        title: "No",
        dataIndex: "key",
        width: 130,
        // sorter: (a, b) => new Date(a.trxnDate) - new Date(b.trxnDate),
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(record))
        }
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        width: 200,
        render: (record, record2)=>{
            return  (record === "Summary" ? <b>{record}</b>:
                      record2.duplicate == 1 ? <Tag color="blue" onClick={() => topListDetails(record)}>{record}</Tag> : 
                      record2.duplicate == 0 ? <Tag onClick={() => topListDetails(record)}>{record}</Tag> :
                      ""
                    )
        }
      },
      {
        title: "Deposit Amount",
        dataIndex: "totalAmt",
        width: 200,
        render: (record)=>{
            return (record === "Summary" ? <b>{FormatDataMobile(record)}</b>:FormatDataMobile(record))
        }
      },
      {
        title:"Count Per Day",
        dataIndex:"countTrxn",
        width: 200,
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(record))
        }
      },
    ];

    const columnsWth = [
      {
        title: "No",
        dataIndex: "key",
        width: 130,
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(record))
        }
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        width: 200,
        render: (record, record2)=>{
          return  (record === "Summary" ? <b>{record}</b>:
                    record2.duplicate == 1 ? <Tag color="blue" onClick={() => topListDetails(record)}>{record}</Tag> : 
                    record2.duplicate == 0 ? <Tag onClick={() => topListDetails(record)}>{record}</Tag> :
                    ""
                  )
        }
      },
      {
        title: "Withdraw Amount",
        dataIndex: "totalAmt",
        width: 200,
        render: (record)=>{
            return (record === "Summary" ? <b>{FormatDataMobile(record)}</b>:FormatDataMobile(record))
        }
      },
      {
        title:"Count Per Day",
        dataIndex:"countTrxn",
        width: 200,
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(record))
        }
      },
    ];

    const columnsComm = [
      {
        title: "No",
        dataIndex: "key",
        width: 130,
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(record))
        }
      },
      {
        title: "Date",
        dataIndex: "createTime",
        width: 200,
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(record))
        }
        
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        width: 200,
        render: (record, record2)=>{
          return  (record === "Summary" ? <b>{record}</b>:
                    record2.duplicate == 1 ? <Tag color="blue" onClick={() => topListDetails(record)}>{record}</Tag> : 
                    record2.duplicate == 0 ? <Tag onClick={() => topListDetails(record)}>{record}</Tag> :
                    ""
                  )
        }
      },
      {
        title:"Comm",
        dataIndex:"amount",
        width: 200,
        render: (record)=>{
            return (record === "Summary" ? <b>{FormatDataMobile(record)}</b>:FormatDataMobile(record))
        }
      },
    ];

    const columnsComm2Bank = [
      {
        title: "No",
        dataIndex: "key",
        width: 130,
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(record))
        }
      },
      {
        title: "Withdraw Time",
        dataIndex: "createTime",
        width: 200,
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(record))
        }
        
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        width: 200,
        render: (record, record2)=>{
          return  (record === "Summary" ? <b>{record}</b>:
                    record2.duplicate == 1 ? <Tag color="blue" onClick={() => topListDetails(record)}>{record}</Tag> : 
                    record2.duplicate == 0 ? <Tag onClick={() => topListDetails(record)}>{record}</Tag> :
                    ""
                  )
        }
      },
      {
        title:"Comm To Bank",
        dataIndex:"comm",
        width: 200,
        render: (record)=>{
            return (record === "Summary" ? <b>{record}</b>:(record))
        }
      },
    ];

    let trxnSummaryColumns = [
      {
        align: "center",
        title: "Trxn Type",
        dataIndex:"TrxnType",
        width: "15%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "Total Trxn Amount",
        dataIndex:"TrxnAmt",
        width: "15%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "Trxn Today",
        dataIndex:"TrxnToday",
        width: "15%",
        render:function(record, record2) {
          return  <p>{record} <span style={{color:"blue", marginTop: "14px"}}>&#40;<b>{record2.TrxnTodayCnt}</b>&#41;</span></p>
        }
      },
      {
        align: "center",
        title: "Trxn Yesterday",
        dataIndex:"TrxnYester",
        width: "15%",
        render: (record, record2)=>{
          return  <p>{record} <span style={{color:"blue", marginTop: "14px"}}>&#40;<b>{record2.TrxnYesterCnt}</b>&#41;</span></p>
        }
      },
      {
        align: "center",
        title: "Trxn 2 Day Ago",
        dataIndex:"Trxn2dayAgo",
        width: "15%",
        render: (record, record2)=>{
          return  <p>{record} <span style={{color:"blue", marginTop: "14px"}}>&#40;<b>{record2.Trxn2dayAgoCnt}</b>&#41;</span></p>

        }
      },
    ]

    let userIDColumns = [
      {
        align: "center",
        title: "Mobile",
        dataIndex:"Mobile",
        width: "13.5%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "Register Date",
        dataIndex:"RegisterDate",
        width: "13.5%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "BankAccName",
        dataIndex:"BankAccName",
        width: "13.5%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "AWC UserID",
        dataIndex:"AWC_UserID",
        width: "13.5%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "Joker UserID",
        dataIndex:"Joker_UserID",
        width: "13.5%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "SA UserID",
        dataIndex:"SA_UserID",
        width: "13.5%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "UFA UserID",
        dataIndex:"UFA_UserID",
        width: "13.5%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "HABA UserID",
        dataIndex:"GameUser",
        width: "9.5%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "WSSPORT UserID",
        dataIndex:"GameUser",
        width: "9.5%",
        render: (record)=>{
          return record
        }
      },
    ]
    
    let gameBettingColumns = [
      {
        align: "center",
        title: "Trxn Date",
        dataIndex:"TrxnDate",
        width: "15%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "Provider",
        dataIndex:"GameCode",
        width: "15%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "Game Name",
        dataIndex:"GameName",
        width: "15%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "Total Ticket",
        dataIndex:"TotalTicket",
        width: "15%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "Bet Amount",
        dataIndex:"BetAmt",
        width: "15%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "Win Loss",
        dataIndex:"WinLoss",
        width: "15%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "Turnover",
        dataIndex:"Turnover",
        width: "15%",
        render: (record)=>{
          return record
        }
      },
    ]
    
    let depListColumns = [
      {
        align: "center",
        title: "Trxn Date",
        dataIndex:"TrxnDate",
        width: "15%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "Deposit Amount",
        dataIndex:"TrxnAmt",
        width: "15%",
        render: (record)=>{
          return record
        }
      },
    ]

    let wthListColumns = [
      {
        align: "center",
        title: "Trxn Date",
        dataIndex:"TrxnDate",
        width: "15%",
        render: (record)=>{
          return record
        }
      },
      {
        align: "center",
        title: "Withdraw Amount",
        dataIndex:"TrxnAmt",
        width: "15%",
        render: (record)=>{
          return record
        }
      },
    ]

    const styles = {
      modalWide : {
        top : "2%" ,
        marginLeft:"20.55%"
     },
    };

    const onEditTransferForm =(newTransferDtt) => {
      return (
        <Form
          layout="vertical" 
          name="editTransferForm"
          // form={form}
          //onFinish={handleFinishSubmitTransfer}
        >
          <Tabs defaultActiveKey="1" onChange={onChangeTransferTab}
          >
            <TabPane tab={trxnSummaryTitle} key="1">
              <Table
                loading={modal1Loading}
                columns ={trxnSummaryColumns}
                dataSource= {topTrxnSummary}
                scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                pagination={settings.pagination}
              />
            </TabPane>
            <TabPane tab={gameBettingTitle} key="2">
              {/* <Table
                loading={modal2Loading}
                columns ={userIDColumns}
                dataSource= {userIdResult}
                scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                pagination={settings.pagination}
              /> */}
              <Table
                loading={modal2Loading}
                columns ={gameBettingColumns}
                dataSource= {gameBetSummary}
                scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                pagination={settings.pagination}
              />
            </TabPane>
            <TabPane tab={depListTitle} key="3">
              <Table
                loading={modal3Loading}
                columns ={depListColumns}
                dataSource= {depList}
                scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                pagination={settings.pagination}
              />
            </TabPane>
            <TabPane tab={wthListTitle} key="4">
              <Table
                loading={modal4Loading}
                columns ={wthListColumns}
                dataSource= {wthList}
                scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                pagination={settings.pagination}
              />
            </TabPane>
          </Tabs>
        </Form>
      )
    }

    return (
      <div>
        {
          topListVw(role, username, compCode)?
            <div>
              <PageHeader ghost={false}>
                <Modal visible={modalVisible}
                  title={ <Table
                    loading={modal2Loading}
                    columns ={userIDColumns}
                    dataSource= {userIdResult}
                    // scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                    // pagination={settings.pagination}
                    pagination={false} 
                  />}
                  okText={"Save"}
                  maskClosable={false}
                  cancelButtonProps={{ type: "primary" }}
                  okButtonProps={{ style:{display: "none"} }}
                  cancelText={"Close"}
                  onCancel={onCancelModal}
                  style={styles.modalWide}
                  width={1300}
                >
                    {onEditTransferForm()}
                </Modal>
                <div style={style.pageHeaderContainer}>
                  <div style={{float:"left"}}>
                    <DatePicker
                        key="1"
                        // defaultValue={moment(new Date())}
                        style={{...style.datePickerStyle, ...style.refreshBtn}}
                        format="YYYY-MM-DD"
                        onChange={trxDatePicker}
                        value={trxDate}
                        //type={type}// onChange={setType}
                        ref={reportDateFrom}
                        refs
                        placeholder={"Select a Date"}
                        disabledDate={(current)=> {
                          return current > moment();//.subtract(1, 'day');
                        }}
                    />
                    {/* <DatePicker
                      key="2"
                      style={{...style.datePickerStyle, ...style.refreshBtn}}
                      format="YYYY-MM-DD"
                      onChange={trxDatePicker2}
                      value={trxDateTo}
                      ref={reportDateTo}
                      placeholder={"Select Date To"}
                      disabledDate={(current)=> {
                        let dateFrom = trxDate;
                        if (dateFrom) {
                          return current < dateFrom || current > moment();//.subtract(1, 'day');
                        }else {
                          return  current > moment();//.subtract(1, 'day');
                        }
                      }}
                    /> */}
                    <Button
                      type={"primary"}
                      style={style.searchResetStyle}
                      icon={<SearchOutlined
                      onClick={searchBar}
                      />}
                    />
                    <Button style={{...style.resetBtn,...style.refreshBtn}} onClick={resetBtn}>
                      Reset
                    </Button>
                    {/* <Button 
                          type={"primary"} 
                          style={style.refreshBtn} 
                          icon={<FileExcelOutlined />}
                          onClick={() => {
                              onClickExportBtn();
                          }}
                          
                      >
                        Export to Excel
                    </Button> */}
                  </div>
                </div>
              </PageHeader>
              <div style={style.tabelContainer}>      
              <Tabs defaultActiveKey="1" id="report-daily-sales-tab" className="report-daily-sales-tab">
                  <TabPane tab={tab1Title} key="1" style={style.tabHeader}>
                      <Table 
                          loading={table1Loading} 
                          columns={columnsDep}
                          dataSource={topDepositData}
                          className="reportDailySales"
                          scroll={{ x: 100, y: null }}
                          exportable={true}
                          pagination={{
                            defaultPageSize: 50,
                            showSizeChanger: true,
                            pageSizeOptions: settings.pageSizeOptions,
                            style: style.paginationStyle,
                            // alignmentBottom: 'right',
                          }}
                          exportableProps={{
                            btnProps: {
                              type: "primary",
                              icon: <FileExcelOutlined />,
                              id:"export-button-1",
                              children: <span>Export to Excel</span>,
                              style: {display:"none"}
                            },
                          }}
                      />
                      <p><b>Remark:</b></p>
                      <p>(1) Auto-Refresh every 5 minutes. (Data as at {todayDate})</p>
                      <p>(2) Mobile with a blue tag means there is the same phone in another tab</p>
                      <p>(3) Transaction keep for 1 month</p>
                  </TabPane>
                  <TabPane tab={tab2Title} key="2" style={style.tabHeader}>
                      <Table dataSource={topWithdrawData} 
                          loading={table2Loading} 
                          columns={columnsWth} 
                          pagination={{
                              defaultPageSize: 50,
                              showSizeChanger: true,
                              pageSizeOptions: ["50"],
                              style: style.paginationStyle,
                              alignmentBottom: 'right',
                            }}
                          />
                      <p><b>Remark:</b></p>
                      <p>(1) Auto-Refresh every 5 minutes. (Data as at {todayDate})</p>
                      <p>(2) Mobile with a blue tag means there is the same phone in another tab</p>
                      <p>(3) Transaction keep for 1 month</p>
                  </TabPane>
                  <TabPane tab={tab3Title} key="3" style={style.tabHeader}>
                      <Table dataSource={topCommData} 
                          loading={table3Loading} 
                          columns={columnsComm} 
                          pagination={{
                              defaultPageSize: 50,
                              showSizeChanger: true,
                              pageSizeOptions: ["50"],
                              style: style.paginationStyle,
                              alignmentBottom: 'right',
                            }}
                          />
                      <p><b>Remark:</b></p>
                      <p>(1) Auto-Refresh every 5 minutes. (Data as at {todayDate})</p>
                      <p>(2) Mobile with a blue tag means there is the same phone in another tab</p>
                      <p>(3) Transaction keep for 1 month</p>
                  </TabPane>
                  <TabPane tab={tab4Title} key="4" style={style.tabHeader}>
                      <Table dataSource={topComm2BankData} 
                          loading={table4Loading} 
                          columns={columnsComm2Bank} 
                          pagination={{
                              defaultPageSize: 50,
                              showSizeChanger: true,
                              pageSizeOptions: ["50"],
                              style: style.paginationStyle,
                              alignmentBottom: 'right',
                            }}
                          />
                      <p><b>Remark:</b></p>
                      <p>(1) Auto-Refresh every 5 minutes. (Data as at {todayDate})</p>
                      <p>(2) Mobile with a blue tag means there is the same phone in another tab</p>
                      <p>(3) Transaction keep for 1 month</p>
                  </TabPane>
              </Tabs>
              </div> 
            </div> 
          : <div style={{textAlign:"center",color:"red",fontSize:"150%"}}><h1 style={{color:"red"}}>Not Allowed to Access This Page</h1></div>}
      </div>        
    );
  }