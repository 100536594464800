import React from "react";
import { BANK_INFO_TYPE } from "../../../../common/constants/bankType"

export const CLegend = (props) => {
    if (props.banktype === BANK_INFO_TYPE.DEP) {
        return ( <>
            <h2>Legend:</h2>
            <p>Total Generated Deposit = Total Claimed Deposit + Total Unclaim Deposit</p>
            {/* <p>Bank Closing = Current Bank Balance  </p> */}
            <p>Diff. Total Generated Deposit / Bank Balance = (Total Generated Deposit - Transfer out ) - (Bank Closing - Bank Opening)</p>
            {/* <p>Diff. Total Generated Deposit / Bank Balance = Total Generated Deposit + Bank Opening - Bank Balance</p> */}
            <p><b>Negative</b>: Diff. Total Generated Deposit / Bank Balance = <b> System Generate less than Actual Received Amount</b></p>
            <p><b>Postive </b>: Diff. Total Generated Deposit / Bank Balance = <b>System Generate more than Actual Received Amount</b></p>
          </>);
    }else if (props.banktype === BANK_INFO_TYPE.WIT) {
        return (<>
            <h2>Legend:</h2>
            <p>Total Bank Transfer Out = Bank Opening - Bank Closing + Transfer In -  Transfer Out </p>
            <p>Diff. Total Generated Withdraw / Total Bank Transfer Out = Total Generated Withdraw - Total Bank Transfer Out</p>
            <p><b>Negative</b>: Diff. Total Generated Withdraw / Total Bank Transfer Out = <b>Customer Withdraw More than System Generate</b></p>
            <p><b>Postive </b>: Diff. Total Generated Withdraw / Total Bank Transfer Out = <b>Customer Withdraw Less than System Generate</b></p>
            </>);
    }else {
        return (<></>);
    }


              // Diff Total Generate Deposit / Bank balance = 
          // Total Generate Deposit - Transfer out - Bank Balance + Bank Opening
}