import React, { useState, useEffect } from "react";
import { csCommWitList, csWithdrawApprove,  getReqToken } from "../../config/networkConfig";
import { Badge, message, Table, Button, PageHeader, Radio } from "antd";
import { isMobile } from "react-device-detect";
import { settings } from "../../config/appConfig";
import { useSelector } from "react-redux";
import { wthModeText } from "../../library/texts";
import md5 from "md5";
import {witCommAppAppr} from "../../library/permission";
import { ShowMobileNumber } from "../../library/numbers";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";

export default function CommWithdrawList() {
  const [originData, setOriginData] = useState([]);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("Pending");
  const {userId , role } = useSelector((state) => state.login);


  useEffect(() => {
    commWithdrawList();
    // eslint-disable-next-line
  }, [status]);

  const commWithdrawList = () => {
    var count = 0;
    csCommWitList({})
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {

          const newData = Object.keys(json.data).length
            ? json.data.map((val) => {
                return {
                  key: count++,
                  trxnId: val.trxnId,
                  dateTime: val.dateTime,
                  comm: val.comm * -1,
                  nickname: ShowMobileNumber(role, val.nickname, val.mobile),
                  trxnMode: wthModeText(val.trxnmode),
                  status:
                    val.status == 0
                    ? "Pending"
                    : val.status == 1 || val.status == 3
                    ? "Approved"
                    : val.status == 9
                    ? "Rejected"
                    : "-",
                    // val.status === "3"
                    //   ? "Completed"
                    //   : val.status === "2"
                    //   ? "Processing"
                    //   : val.status === "1"
                    //   ? "Approved"
                    //   : val.status === "0"
                    //   ? "Pending"
                    //   : "Rejected",
                  loading: false,
                };
              })
            : [];
          setOriginData(newData);

          const pending = newData.filter((val) => val.status === "Pending");
          const approve = newData.filter((val) => val.status === "Approved");
          const reject = newData.filter((val) => val.status === "Rejected");

          const sortData =
                status === "Pending"
                  ? pending
                  : status === "Approved"
                  ? approve
                  : status === "Rejected"
                  ? reject
                  : [...newData];

          setData(sortData);
        } else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
  };

  const apiWitApprove = (trxnId, index, trxnMode) => {
    setLoading(index);

    let requestData = {
      trxnId : trxnId,
      userid : userId,
      reqToken   : md5(getReqToken(`${trxnMode}Approve`)),
    }
    
    csWithdrawApprove(requestData)
      .then((res) => res.json())
      .then((json) => {
        if (json.status === "ok") {
          const newData = [...originData];

          newData[index].status = "Approved";
          setOriginData(newData);

          alertData.title = "Success";
          alertData.msg = json.msg;
          sharedSuccessAlert(alertData);
          commWithdrawList();
        } else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
        setLoading(index);
      })
      .catch((e) => {
        console.log(e);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
        setLoading(index);
      });
  };

  const setLoading = (index) => {
    const newLoadings = [...originData];

    newLoadings[index].loading = !newLoadings[index].loading;

    setOriginData(newLoadings);
  };

  const columns = [
    {
      title: "Data / Time",
      dataIndex: "dateTime",
      width: "200px",
      ellipsis: true,
    },
    {
      title: "Nickname",
      dataIndex: "nickname",
      width: "200px",
      ellipsis: true,
    },
    {
      title: "Commission",
      dataIndex: "comm",
      width: "200px",
      ellipsis: true,
    },
    {
      title: "Withdrawal Types",
      dataIndex: "trxnMode",
      width: "200px",
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "200px",
      ellipsis: true,
      render: (text, record) => {
        switch (record.status) {
          case "Completed":
            return <Badge status="success" text="Completed" />;
          case "Processing":
            return <Badge status="processing" text="Processing" />;
          case "Approved":
            return <Badge status="success" text="Approved" />;
          case "Pending":
            
            return (
              witCommAppAppr(role)?
              (
                <Button
                  style={styles.approveBtn}
                  type="primary"
                  onClick={(event) => {
                    apiWitApprove(record.trxnId, record.key , record.trxnMode);
                  }}
                  loading={record.loading}
                >
                  Approve
                </Button>
              ):
              (<Badge color="#f7b105" text="Pending" />)
            );
          case "Rejected":
            return <Badge status="error" text="Rejected" />;
          default:
            return <Badge status="error" text="Rejected" />;
        }
      },
    },
  ];

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    approveBtn: {
      marginRight: 20,
      marginLeft: 20,
      backgroundColor: "#4190f7",
      borderColor: "#4190f7",
    },
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
    },
    refreshBtn: {
      marginLeft: 10,
    },
  };

  return (
    <div className="eachPageContent">
      <PageHeader ghost={false}>
        <div style={styles.pageHeaderContainer}>
          <Button
            style={styles.refreshBtn}
            type="primary"
            onClick={() => {
              commWithdrawList();
            }}
          >
            Refresh Page
          </Button>
          <Radio.Group
            buttonStyle={"solid"}
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <Radio.Button value="All">All</Radio.Button>
            <Radio.Button value="Pending">Pending</Radio.Button>
            <Radio.Button value="Approved">Approved</Radio.Button>
            <Radio.Button value="Rejected">Reject</Radio.Button>
          </Radio.Group>
          {/* <Radio.Group
            buttonStyle={"solid"}
            value={status}
            onChange={(e) => {
              console.log("e: ", e.target.value);
              setStatus(e.target.value);
            }}
          >
            <Radio.Button value="All">All</Radio.Button>
            <Radio.Button value="Pending">Pending</Radio.Button>
            <Radio.Button value="Processing">Processing</Radio.Button>
            <Radio.Button value="Completed">Completed</Radio.Button>
            <Radio.Button value="Approved">Approved</Radio.Button>
            <Radio.Button value="Rejected">Rejected</Radio.Button>
          </Radio.Group> */}
        </div>
      </PageHeader>
      <div style={styles.tableContainer}>
        <Table
          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: settings.pageSizeOptions,
            style: styles.paginationStyle,
            alignmentBottom: 'right',
          }}
          columns={columns}
          dataSource={data}
          // loading={tableLoading}
        />
      </div>
    </div>
  );
}
